import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import selector from 'modules/Auth/selectors'
import React, { useEffect, useState } from 'react'
import SuccessMessage from 'utils/SuccessMessage'
import { removeDuplicatesFromArray } from '../../.././.././utils/removeDuplicatesFromArray'
import { Spinner } from '../../Style/style'
import AddUserForm from './Partials/AddUserForm'
import { FlexWrap, Form } from './Partials/AddUserForm/styles'

const AddUserDrawer = ({
  form,
  roleList,
  addUserDrawer,
  setAddUserDrawer,
  userRole,
  createUser,
  updateUser,
  singleUserData,
  userId,
  getUserData,
  getFiltersRoleList,
  updateLoading,
  getListOfCountries,
  getUserMetrics,
  getSingleUser,
}) => {
  const [loading, setLoading] = useState(false)
  const [countryCodeSearch, setCountryCodeSearch] = useState('')
  const [countryCodeList, setCountryCodeList] = useState([])

  const authId = selector.getUserId()

  useEffect(() => {
    form.setFieldsValue({
      firstName: singleUserData?.firstName,
      lastName: singleUserData?.lastName,
      userType: singleUserData?.userType,
      adminRoleId: singleUserData?.adminRoleId,
      userEmail: singleUserData?.userEmail,
      userMobile: singleUserData?.userMobile,
      countryCode: singleUserData?.countryCode,
    })
  }, [singleUserData])

  const getCurrency = async ({ setState, searchValue }) => {
    const { data, status } = await getListOfCountries({
      phoneCodeSearch: searchValue,
      pageLimit: 500,
    })

    if (status >= 200 && status <= 300) {
      setState(removeDuplicatesFromArray(data?.data?.result, 'phone_code'))
    }
  }

  useEffect(() => {
    let getData
    if (countryCodeSearch) {
      getData = setTimeout(() => {
        getCurrency({
          setState: setCountryCodeList,
          searchValue: countryCodeSearch,
        })
      }, 500)
    } else {
      getCurrency({
        setState: setCountryCodeList,
        searchValue: countryCodeSearch,
      })
    }

    return () => clearTimeout(getData)
  }, [countryCodeSearch])

  const onCreateUser = async value => {
    let isEdit = !!singleUserData?.id
    setLoading(true)
    const roleIdfind = roleList?.filter(Item => Item?.id === value?.adminRoleId)
    const roleNamefind = roleList?.filter(
      Item => Item?.name === value?.userType
    )
    const updatepayload = {
      admin: {
        firstName: value?.firstName,
        lastName: value?.lastName,
        adminRoleId: value?.userType,
        authId: authId,
      },
      user: {
        userEmail: value?.userEmail?.trim(),
        userMobile: value?.userMobile,
        countryCode: value?.countryCode,
        userType: roleNamefind[0]?.name,
        journey: 'INTERNAL',
      },
    }

    const payload = {
      admin: {
        firstName: value?.firstName,
        lastName: value?.lastName,
        adminRoleId: value?.adminRoleId,
      },
      user: {
        userEmail: value?.email?.trim(),
        userMobile: value?.phoneNumber,
        countryCode: value?.countryCode,
        userType: roleIdfind[0]?.name,
        journey: 'INTERNAL',
      },
    }
    let response = !isEdit
      ? await createUser({ payload })
      : await updateUser(userId, updatepayload)
    if (response?.status >= 200 && response?.status) {
      SuccessMessage(
        isEdit ? 'User updated successfully!' : 'User created successfully!'
      )
      setLoading(false)
      // getUserData('')
      getFiltersRoleList()
      form.resetFields()
      setAddUserDrawer(false)
    } else {
      setLoading(false)
    }

    getUserData()
    // getUserMetrics({ page, setLoading })
  }

  const onClose = () => {
    setAddUserDrawer(false)
    form.resetFields()
  }

  const content = () => {
    return (
      <>
        {updateLoading && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          id={'addUserForm'}
          form={form}
          layout="vertical"
          onFinish={onCreateUser}
        >
          <AddUserForm
            userRole={userRole}
            roleList={roleList}
            form={form}
            setCountryCodeSearch={setCountryCodeSearch}
            countryCodeList={countryCodeList}
            singleUserData={singleUserData}
          />
        </Form>
      </>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" height="38px" onClick={onClose} />
        <Button.Primary
          text={singleUserData?.id ? 'Update' : 'Send Invite'}
          htmlType="submit"
          form={'addUserForm'}
          icon={<CheckIconWhite />}
          height="38px"
          loading={loading}
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={singleUserData?.id ? 'Edit User' : 'Create New User'}
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={addUserDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddUserDrawer
