import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Users/Partials/UsersFilter/Styles/style'

const StatusFilter = ({ userStatus, setUserStatus }) => {
  const options1 = [
    {
      label: 'Active',
      value: '0',
    },
    {
      label: 'Inactive',
      value: '1',
    },
  ]

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={userStatus}
        options={options1}
        onChange={value => setUserStatus([value[value?.length - 1]])}
      />
    </Wrapper>
  )
}

export default StatusFilter
