import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import Flex from 'components/Flex'
import SideBar from 'components/Sidebar'
import ExitIcon from 'modules/Nav/icons/ExitIcon'
import { navItems } from 'modules/Nav/navItems'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { signOut } from '../Auth/actions'
import {
  MeneTitle,
  MenuItem,
  MenuStyle,
  SpaceItem,
  SubLabel,
  SubMenuItem,
} from './style'
const BorderMenuItem = styled(Menu.Item)``

const SubMenuTitle = ({
  navTitle,
  subMenu,
  setExternalMenuOpen,
  parentPath,
  id,
  title,
  anotherMenu,
}) => {
  const [render, setRender] = useState(false)
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [label, setLabel] = useState('')
  const location = useLocation()
  useEffect(() => {
    const path = location?.pathname

    const parts = path.split('/') // Split the path using "/" as the delimiter
    const report = parts[1]
    if (report === 'reports') {
      const part1 = parts[2]
      const part2 = parts[3]
      setIsOpen(true)
      setRender(true)
      if (part1 == 'students') {
        if (part2 == 'studentplacedscoursewise') {
          setLabel('Stud. Placed-Course-Wise')
        } else if (part2 == 'listofstudentspalced') {
          setLabel(' List of Students Placed')
        } else if (part2 == 'studentskillwise') {
          setLabel('Stud. Placed-Skill-Wise')
        } else {
          setLabel('List of Students')
        }
      } else if (part1 == 'institute') {
        if (part2 == 'empanelledinfotable') {
          setLabel(' List of Inst. Empanelled')
        } else if (part2 == 'top10index') {
          setLabel('Top 10 Institutes')
        } else {
          setLabel('List of Institutes')
        }
      } else if (part2 == 'empanelledinfotable') {
        setLabel(' List of Corp. Empanelled')
      } else if (part2 == 'top10index') {
        setLabel('Top/Bottom Corporate')
      } else {
        setLabel(' List of Corporate ')
      }
    }
  }, [])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
    role(navTitle)
  }
  const role = data => {
    navigate(`${parentPath}`)
    setLabel('')
    setRender(!render)
    if (data == 'Student') {
      setLabel('List of Students')
    } else if (data == 'Institutes') {
      setLabel('List of Institutes')
    } else {
      setLabel(' List of Corporate ')
    }
  }
  useEffect(() => {
    if (title) {
      setIsOpen(true)
      setRender(true)
    }
  }, [title])
  return (
    <div>
      <SpaceItem onClick={toggleMenu}>
        <div
          style={{
            color: isOpen && title == navTitle ? 'white' : '',
          }}
        >
          {navTitle}
        </div>

        {isOpen && title == navTitle ? (
          <DownOutlined
            style={{ marginLeft: '30px', marginTop: '-6px', color: 'white' }}
          />
        ) : (
          <UpOutlined style={{ marginLeft: '30px', marginTop: '-6px' }} />
        )}
      </SpaceItem>
      {render &&
        title == navTitle &&
        subMenu?.map(item => {
          return (
            <SubLabel
              key={item?.label}
              onClick={() => {
                setLabel(item.label)
                navigate(`${parentPath}${item.path}`)
              }}
            >
              <div
                id={item?.label}
                style={{
                  color: item.label === label ? 'white' : '',
                }}
              >
                {' '}
                {item.label}
              </div>
            </SubLabel>
          )
        })}
    </div>
  )
}

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  height: 10vh;
  min-width: 225px;
  max-width: 200px;
  color: red;
  display: flex;
`

const Nav = ({ permissions }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [id, setId] = useState()
  const [menuType] = useState('GlobalMenu')
  const [externalMenuOpen, setExternalMenuOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [anotherMenu, setAnotherMenu] = useState(false)
  let pathName = location.pathname.split('/')
  useEffect(() => {
    const path = location?.pathname

    const parts = path.split('/') // Split the path using "/" as the delimiter
    const report = parts[1]
    if (report === 'reports') {
      const part1 = parts[2]
      if (part1 == 'institute') {
        setTitle('Institutes')
      } else if (part1 == 'corporate') {
        setTitle('Corporate')
      } else if (part1 == 'students') {
        setTitle('Student')
      }

      setExternalMenuOpen(true)
    }
  }, [])

  const getMenu = navItem =>
    navItem.map(item => {
      return (
        <>
          <MenuItem
            key={item.path}
            path={item.path === '/reports' ? '#' : item.path}
            onClick={() => {
              if (item.path === '/reports') {
                pathName = 'reports'
                setExternalMenuOpen(!externalMenuOpen)
                setTitle('')
              } else {
                setExternalMenuOpen(false)
                setAnotherMenu(true)
              }
            }}
          >
            <Flex centerVertically>
              <item.icon
                color={
                  externalMenuOpen && item.navTitle === 'Reports'
                    ? 'white'
                    : `/${pathName[1]}` === item.path && !externalMenuOpen
                    ? 'white'
                    : '#999FAC'
                }
              />
              <MeneTitle
                color={
                  externalMenuOpen && item.navTitle === 'Reports'
                    ? 'white'
                    : `/${pathName[1]}` === item.path && !externalMenuOpen
                    ? 'white'
                    : '#999FAC'
                }
              >
                {item?.navTitle}
                {externalMenuOpen && item.navTitle === 'Reports' ? (
                  <DownOutlined
                    style={{
                      marginLeft: '62px',
                      marginTop: '-6px',
                      color: 'white',
                    }}
                  />
                ) : !externalMenuOpen && item.navTitle === 'Reports' ? (
                  <UpOutlined
                    style={{ marginLeft: '62px', marginTop: '-6px' }}
                  />
                ) : (
                  ''
                )}
              </MeneTitle>
            </Flex>
          </MenuItem>
          {item.path === '/reports' && externalMenuOpen ? (
            <SubMenuItem>
              {reportsSubMenu?.externalMenu.map(
                ({ subMenu, path, navTitle }) => {
                  return (
                    <div
                      key={path}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex="0"
                      onClick={e => {
                        setId(e.target.id)
                        setTitle(navTitle)
                      }}
                    >
                      <SubMenuTitle
                        navTitle={navTitle}
                        subMenu={subMenu}
                        title={title}
                        anotherMenu={anotherMenu}
                        // setExternalMenuOpen={setExternalMenuOpen}
                        parentPath={path}
                        id={id}
                      />
                    </div>
                  )
                }
              )}
            </SubMenuItem>
          ) : null}
        </>
      )
    })

  const reportsSubMenu = [...navItems].find(f => f.path === '/reports')
  const onItemClick = ({ item: { props } }) => {
    const { path } = props
    if (path === 'reports') {
      navigate(path)
      setExternalMenuOpen(!externalMenuOpen)
      setTitle('')
      setId('')
    } else if (path === '/logout') {
      dispatch(signOut())
    } else {
      navigate(path)
    }
  }

  return (
    <SideBar>
      <MenuStyle
        selectedKeys={[!externalMenuOpen ? `/${pathName[1]}` : '/reports']}
        theme="dark"
        mode="inline"
        inlineIndent={24}
        onClick={onItemClick}
        inlineCollapsed={false}
        style={{
          height: '90vh',
          overflow: 'auto',
          paddingBottom: '6rem',
        }}
      >
        {getMenu(navItems.filter(e => e.menuType === menuType))}
      </MenuStyle>
      <Footer>
        <MenuStyle
          selectedKeys={[location.pathname]}
          theme="dark"
          mode="inline"
          inlineIndent={24}
          onClick={onItemClick}
          inlineCollapsed={false}
        >
          <Menu.Divider />
          <BorderMenuItem path="/logout">
            <Flex centerVertically>
              <ExitIcon />
              <MeneTitle>Logout</MeneTitle>
            </Flex>
          </BorderMenuItem>
        </MenuStyle>
      </Footer>
    </SideBar>
  )
}
export default Nav
