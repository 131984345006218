import CorporateInfo from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo'
import PricingContarctInfo from 'modules/Onboarding/Partials/Corporates/Register/PricingContract/index'
import TaxInfo from 'modules/Onboarding/Partials/Corporates/Register/TaxInfo'
export const menuData = [
  {
    key: 1,
    activeKey: 0,
    title: 'Add Corporate',
    description: ['Basic Details'],
    heading: "Let's Get Started",
    subHeading: 'Tell us bit about the organization',
    component: [CorporateInfo],
  },
  {
    key: 2,
    activeKey: 1,
    title: 'Tax Info',
    heading: 'Tax Information',
    subHeading:
      'Tax Information Network (TIN) is an initiative by Income Tax Department of India',
    component: TaxInfo,
  },
  // {
  //   key: 3,
  //   activeKey: 2,
  //   title: 'Add Partners',
  //   heading: 'Add Partners',
  //   subHeading: 'Add existing empanelled institutes',
  //   component: PartnerFeature,
  // },
  {
    key: 3,
    activeKey: 2,
    title: 'Pricing and Contract',
    heading: 'Pricing and Contract',
    subHeading: 'Choose the right prising for you to get started',
    component: PricingContarctInfo,
  },
]
