import ExportButton from 'components/ExportButton/ExportButton.js'

import Top10CorporateTable from 'modules/Reports/Partials/Corporates/Partials/Top10CorporatesTable/Top10CorTable/index.js'
import React, { useEffect, useState } from 'react'
import WarningMessage from 'utils/WarningMessage'
import { filterData } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import {
  NavWrapper,
  PaddedDiv,
  SelectStyle,
  TopHeaderCorp,
  TopHeading,
  SectionWrapperCorporate,
} from './Style/style'
import { setTopBottomCorporateDetails } from '../../../../Institutes/Partials/InstEmpanelledTable/actions'
import { useDispatch } from 'react-redux'
import {
  setIndustryList,
  setCityList,
  setSingleIndustryData,
  setSingleSectorData,
} from '../../../../../actions'

const Top10Index = ({
  getSectorList,
  sectorList,
  getCityList,
  getIndustryList,
  getStateList,
  industryList,
  cityList,
  stateList,
  getTopBottomCorporateDetails,
  topOrBottomCorporateList,
  excelDataForReport,
  IndustrySectorUrl,
  industrySectorData,

  getSingleSectorList,
  singleSectorData,
  singleIndustryData,
  getSingleIndustryList,

  getUserInfo,
  userData,
}) => {
  const dispatch = useDispatch()
  const [cityCount, setCityCount] = useState(0)
  const [cityPage, setCityPage] = useState(0)
  const [cityData, setCityData] = useState([])
  const [clearDateTop, setClearDateTop] = useState(false)
  const [tooltiptop, setToolTipTop] = useState(false)
  const [sectorLoading, setSectorLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [sortBy, setSortBy] = useState({})
  const [filterDatas, setFilterData] = useState({})
  const [keySector, setKeySector] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(() => searchValue)
  const [isExcel, setIsExcel] = useState('')
  const [excel, setExcel] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingTopCorp, setLoadingTopCorp] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)

  const [sectorValue, setSectorValue] = useState([])
  const [stateValue, setStateValue] = useState([])
  const [cityValue, setCityValue] = useState([])
  const [industryValue, setIndustryValue] = useState([])
  const [keyState, setKeyState] = useState('')
  const [keyCity, setKeyCity] = useState('')
  const [keyIndustry, setKeyIndustry] = useState('')
  const [createdAtStart, setCreatedAtStart] = useState('')
  const [createdAtEnd, setCreatedAtEnd] = useState('')
  const [top, isTop] = useState(true)
  const [dataCount, setDataCount] = useState(10)
  const [pageNum, setPageNum] = useState(1)
  const [debounced, setDebounced] = useState(() => keySector)
  const [debouncedIndustry, setDebouncedIndustry] = useState(() => keyIndustry)
  const [debouncedCity, setDebouncedCity] = useState(() => keyCity)
  const [debouncedState, setDebouncedState] = useState(() => keyState)
  const [keySingleSector, setKeySingleSector] = useState('')
  const [debouncedSingleSectorSearch, setDebouncedSingleSearch] = useState(
    () => keySingleSector
  )
  const [singleSectorLoading, setSingleSectorLoading] = useState(false)
  const [singleSectorValue, setSingleSectorValue] = useState([])
  const [singleSectorCount, setSingleSectorCount] = useState(0)
  const [singleSectorPage, setSingleSectorPage] = useState(0)
  const [singleSectorDataFinal, setSingleSectorDataFinal] = useState([])
  const [keySingleIndustry, setKeySingleIndustry] = useState('')
  const [debouncedSingleIndustrySearch, setDebouncedSingleIndustrySearch] =
    useState(() => keySingleIndustry)
  const [singleIndustryLoading, setSingleIndustryLoading] = useState(false)
  const [singleIndustryValue, setSingleIndustryValue] = useState([])
  const [singleIndustryCount, setSingleIndustryCount] = useState(0)
  const [singleIndustryPage, setSingleIndustryPage] = useState(0)
  const [singleIndustryDataFinal, setSingleIndustryDataFinal] = useState([])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSingleIndustrySearch(keySingleIndustry)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySingleIndustry])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSingleSearch(keySingleSector)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySingleSector])
  useEffect(() => {
    dispatch(setSingleIndustryData([]))
    let flag = 'TC'
    let page = 0
    setSingleIndustryPage(0)
    getSingleIndustryList({
      setSingleIndustryLoading,
      keySingleIndustry,
      flag,
      page,
    })
  }, [debouncedSingleIndustrySearch])
  useEffect(() => {
    dispatch(setSingleSectorData([]))
    let flag = 'TC'
    let page = 0
    setSingleSectorPage(0)
    getSingleSectorList({
      setSingleSectorLoading,
      keySingleSector,
      flag,
      page,
    })
  }, [debouncedSingleSectorSearch])
  const loadMoreSingleSectorData = async () => {
    let flag = 'TC'
    let page = singleSectorData?.page + 1
    getSingleSectorList({
      setSingleSectorLoading,
      keySingleSector,
      flag,
      page,
    })

    setSingleSectorPage(singleSectorPage + 1)
  }
  const loadMoreSingleIndustryData = async () => {
    let flag = 'TC'
    let page = singleIndustryData?.page + 1
    getSingleIndustryList({
      setSingleIndustryLoading,
      keySingleIndustry,
      flag,
      page,
    })

    setSingleIndustryPage(singleIndustryPage + 1)
  }

  //User Call
  useEffect(() => {
    getUserInfo()
  }, [])

  function hasData(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    dispatch(setTopBottomCorporateDetails())

    if (hasData(filterDatas)) {
      if (pageNum) {
        getTopBottomCorporateDetails({
          top: top,
          pageSize: dataCount,
          pageNumber: pageNum - 1,
          setLoadingTopCorp,
          searchValue,
          filterDatas,
          sortBy,
        })
      }
    }
  }, [pageNum])

  useEffect(() => {
    dispatch(setTopBottomCorporateDetails())

    if (hasData(filterDatas)) {
      getTopBottomCorporateDetails({
        top: top,
        pageSize: dataCount,
        pageNumber: 0,
        setLoadingTopCorp,
        searchValue,
        filterDatas,
        sortBy,
      })
      setPageNum(1)
    }
  }, [debouncedSearch, filterDatas, top, dataCount, sortBy])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(keySector)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySector])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(searchValue)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedState(keyState)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyState])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedCity(keyCity)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyCity])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedIndustry(keyIndustry)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyIndustry])
  useEffect(() => {
    let flag = 'TC'
    getSectorList({ setSectorLoading, keySector, flag })
  }, [debounced])

  useEffect(() => {
    dispatch(setCityList([]))
    let flag = 'TC'
    setCityPage(0)
    getCityList({ setLoadingCity, keyCity, flag })
  }, [debouncedCity])

  useEffect(() => {
    let flag = 'TC'
    getStateList({ setStateLoading, keyState, flag })
  }, [debouncedState])

  useEffect(() => {
    dispatch(setIndustryList([]))
    getIndustryList({ setLoading, keyIndustry })
  }, [debouncedIndustry])

  const title = [
    { title: 'ID' },
    {
      title: 'Corporate Name',
    },
    { title: 'Industry', width: { wch: 40 }, padding: { ppx: 100 } },
    { title: 'No. Of Candidates Hired', width: { wch: 30 } },
    { title: 'Location', width: { wch: 20 } },
  ]

  const tableData = excelDataForReport?.result?.map(item => [
    { value: item.prefix_id ? item.prefix_id : '-' },
    {
      value: item.name ? item.name : '-',
    },
    { value: item.industry ? item.industry : '-' },
    { value: item.studentcount ? item.studentcount : '-' },
    { value: item.city ? item.city : '-' },
  ])

  const excelData = [{ columns: title, data: tableData }]

  const onClick = value => {
    if (value == 'Top') {
      isTop(true)
    } else {
      isTop(false)
    }
  }
  const onChangeCount = value => {
    setDataCount(value)
  }

  const clickedExcel = data => {
    let excel = true
    getTopBottomCorporateDetails({
      pageSize: 5000,
      pageNumber: 0,
      setLoadingTopCorp,
      searchValue,
      filterDatas,
      sortBy,
      excel,
    })
    setIsExcel(data)
  }
  useEffect(() => {
    if (
      (excelDataForReport?.result && tableData && isExcel == 'excel') ||
      (excelDataForReport?.result && tableData && isExcel == 'pdf')
    ) {
      if (loadingTopCorp) {
        setExcel([])
      } else {
        setExcel(excelData)
      }
    }
  }, [excelDataForReport?.result, loadingTopCorp])
  const handleHover = () => {
    if (topOrBottomCorporateList?.count > 5000) {
      WarningMessage(
        'Note : The student count should not exceed 5,000 for data export.  Apply filters if necessary'
      )
    }
  }
  const loadMoreCityData = async () => {
    let flag = 'TC'
    let page = cityList?.page + 1
    getCityList({ setLoadingCity, keyCity, flag, page })

    setCityPage(page + 1)
  }

  useEffect(() => {
    if (cityList) {
      setCityCount(cityList?.count)
      let data = filterData(cityList?.result, 'city')
      setCityData(data)
    }
  }, [cityList])

  useEffect(() => {
    if (singleSectorData) {
      setSingleSectorCount(singleSectorData?.count)
      let data = filterData(singleSectorData?.result, 'sector')
      setSingleSectorDataFinal(data)
    }
    if (singleIndustryData) {
      setSingleIndustryCount(singleIndustryData?.count)
      let data = filterData(singleIndustryData?.result, 'industry')
      setSingleIndustryDataFinal(data)
    }
  }, [singleSectorData, singleIndustryData])

  const columnWidths = [27, 90, 82, 53, 35] // Array of column widths

  const HAlign = ['center', 'left', 'left', 'center', 'left']

  return (
    <SectionWrapperCorporate column>
      <PaddedDiv column>
        <TopHeaderCorp row spaceBetween alignCenter>
          <TopHeading flexStart>Top/Bottom Corporate Details</TopHeading>
          <TopHeading flexEnd style={{ display: 'flex', gap: 10 }}>
            <SelectStyle
              showSearch
              style={{
                width: 170,
              }}
              placeholder="Top"
              defaultValue={'Top'}
              onChange={onClick}
              options={[
                {
                  value: 'Top',
                  label: 'Top ',
                },
                {
                  value: 'Bottom',
                  label: 'Bottom ',
                },
              ]}
            />
            <SelectStyle
              showSearch
              style={{
                width: 170,
              }}
              defaultValue={10}
              onChange={onChangeCount}
              placeholder="Count"
              options={[
                {
                  value: '10',
                  label: '10',
                },
                {
                  value: '20',
                  label: '20',
                },
                {
                  value: '30',
                  label: '30',
                },
                {
                  value: '40',
                  label: '40',
                },
                {
                  value: '50',
                  label: '50',
                },

                {
                  value: '60',
                  label: '60',
                },
                {
                  value: '70',
                  label: '70',
                },
                {
                  value: '80',
                  label: '80',
                },
                {
                  value: '90',
                  label: '90',
                },
                {
                  value: '100',
                  label: '100',
                },
              ]}
            />{' '}
            <div role="button" tabIndex="0" onMouseEnter={handleHover}>
              <ExportButton
                clickedExcel={clickedExcel}
                data={isExcel}
                setIsExcel={setIsExcel}
                dataset={excel}
                loading={loading}
                count={topOrBottomCorporateList?.count || 0}
                title={
                  top
                    ? 'Top ' + dataCount + ' Corporate Details'
                    : 'Bottom ' + dataCount + ' Corporate Details'
                }
                columnWidth={columnWidths} // Array of column widths
                columnFontSize={10} // Font size for the column data
                horizontalAlignment={HAlign}
                userData={userData}
              />
            </div>
          </TopHeading>
        </TopHeaderCorp>
      </PaddedDiv>
      <NavWrapper column>
        <Top10CorporateTable
          singleIndustryPage={singleIndustryPage}
          setSingleIndustryPage={setSingleIndustryPage}
          singleIndustryDataFinal={singleIndustryDataFinal}
          singleIndustryCount={singleIndustryCount}
          loadMoreSingleIndustryData={loadMoreSingleIndustryData}
          singleIndustryData={filterData(
            singleIndustryData?.result,
            'industry'
          )}
          singleIndustryValue={singleIndustryValue}
          setSingleIndustryValue={setSingleIndustryValue}
          keySingleIndustry={keySingleIndustry}
          singleIndustryLoading={singleIndustryLoading}
          setKeySingleIndustry={setKeySingleIndustry}
          singleSectorPage={singleSectorPage}
          setSingleSectorPage={setSingleSectorPage}
          singleSectorDataFinal={singleSectorDataFinal}
          singleSectorCount={singleSectorCount}
          loadMoreSingleSectorData={loadMoreSingleSectorData}
          singleSectorData={filterData(singleSectorData?.result, 'sector')}
          singleSectorValue={singleSectorValue}
          setSingleSectorValue={setSingleSectorValue}
          keySingleSector={keySingleSector}
          singleSectorLoading={singleSectorLoading}
          setKeySingleSector={setKeySingleSector}
          cityCount={cityCount}
          cityPage={cityPage}
          loadMoreCityData={loadMoreCityData}
          setLoadingCity={setLoadingCity}
          loadingCity={loadingCity}
          loadingTopCorp={loadingTopCorp}
          stateLoading={stateLoading}
          sectorLoading={sectorLoading}
          setStateLoading={setStateLoading}
          setSectorLoading={setSectorLoading}
          loading={loading}
          setPageNum={setPageNum}
          currentPage={pageNum}
          pageNum={pageNum}
          setSortBy={setSortBy}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          open={open}
          sectorValue={sectorValue}
          setSectorValue={setSectorValue}
          setFilterData={setFilterData}
          hasProp={hasData(filterDatas)}
          filterData={filterDatas}
          isReport={true}
          industryList={filterData(industryList?.result, 'industry')}
          cityList={cityData}
          stateList={filterData(stateList?.result, 'state')}
          sectorList={filterData(sectorList?.result, 'sector')}
          industryValue={industryValue}
          setIndustryValue={setIndustryValue}
          cityValue={cityValue}
          stateValue={stateValue}
          setCityValue={setCityValue}
          setStateValue={setStateValue}
          tableData={topOrBottomCorporateList}
          setKeySector={setKeySector}
          keySector={keySector}
          setKeyCity={setKeyCity}
          setKeyIndustry={setKeyIndustry}
          setKeyState={setKeyState}
          keyState={keyState}
          keyIndustry={keyIndustry}
          keyCity={keyCity}
          createdAtStart={createdAtStart}
          createdAtEnd={createdAtEnd}
          setCreatedAtStart={setCreatedAtStart}
          setCreatedAtEnd={setCreatedAtEnd}
          setCurrentPage={setPageNum}
          isEmpanelled={true}
          setClearDateTop={setClearDateTop}
          clearDateTop={clearDateTop}
          setToolTipTop={setToolTipTop}
          tooltiptop={tooltiptop}
          IndustrySectorUrl={IndustrySectorUrl}
          industrySectorData={industrySectorData}
          getSingleSectorList={getSingleSectorList}
          setSingleSectorLoading={setSingleSectorLoading}
          getSingleIndustryList={getSingleIndustryList}
          setSingleIndustryLoading={setSingleIndustryLoading}
          getCityList={getCityList}
        />
      </NavWrapper>
    </SectionWrapperCorporate>
  )
}
export default Top10Index
