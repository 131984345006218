import { Divider, Drawer, Form } from 'antd'
import Space from 'antd/lib/space'
import Button from 'components/Button/index'
import { default as AntdInput, default as Input } from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import { getFunctionSysConfig } from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'
import {
  FlexWrapFooter,
  FormItem,
  RadioGroup,
  StyledRadio,
} from 'modules/Systemconfig/Styles/style'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import CONSTANTS from 'utils/constants'

const StyledDrawer = styled(Drawer)`
  .ant-drawer-close {
    display: inline-block;
    margin-right: 12px;
    color: #637487;
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
    position: absolute;
    right: 0;
  }
`
const CrudDrawer = ({
  tableData,
  open,
  onClose,
  content,
  form,
  onChangeCapture,
  handleSubmitCongif,
  setEditId,
  view,
  toEditId,
  setView,
  onSearch,
  // getFunctionSysConfig,
  menu,
}) => {
  const handleSubmit = values => {
    form.submit()
  }

  const [searchCollege, setSearchCollege] = useState('')
  const dispatch = useDispatch()

  const handleReset = () => {
    form.resetFields()
    onClose()
    setEditId('')
    setView(false)
  }

  const handleFinish = values => {
    handleSubmitCongif(values, 'post')
  }

  useEffect(() => {
    let dropDownUrl = menu === 'Roles' ? 'crud/functions' : 'crud/sector'
    let searchValue = searchCollege
    dispatch(
      getFunctionSysConfig({
        dropDownUrl,
        type: 'get',
        pageSize: 10,
        pageNumber: 0,
        searchValue,
      })
    )
  }, [searchCollege, menu])

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetch = useCallback(
    debounce(data => {
      setSearchCollege(data)
    }, 500),
    []
  )

  return (
    <StyledDrawer
      title={
        view
          ? 'Preview'
          : toEditId
          ? 'Edit ' + tableData.text
          : 'Create New ' + tableData.text
      }
      placement="right"
      onClose={onClose}
      open={open}
      width={tableData.text === 'Degree' ? '60%' : 635}
    >
      <div style={{ padding: '20px', pointerEvents: view ? 'none' : '' }}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          {content?.map(item => (
            <Form.Item
              key={item.id}
              name={item.name}
              label={item.label}
              defaultValue={item.value}
              rules={[
                {
                  required: item.required,
                  message: `Please ${
                    item.type === 'select' ? 'Select' : 'Enter'
                  } the ${item.label}`,
                },
                {
                  pattern: /^(?!\s).*$/,
                  message: `${item.label} cannot start with a space`,
                },

                //         validator: (_, value) =>
                //           isNaN(value)
                //             ? Promise.resolve()
                //             : Promise.reject('Value must not be a number'),
                ...(item.pattern
                  ? [
                      {
                        pattern: item.pattern,
                        message: `Please Enter the Valid ${item.label}`,
                      },
                    ]
                  : []),
              ]}
              onChange={event => {
                form.setFieldsValue({
                  ...(item.name === 'companyName' && {
                    companyName: event.target.value.replace(
                      /[^A-Za-z ]|^ /g,
                      ''
                    ),
                  }),

                  ...(item.name === 'emailAddr'
                    ? {
                        emailAddr: event.target.value.trim(),
                      }
                    : {}),
                })
              }}
            >
              {item.type === 'text' ? (
                <Input
                  placeholder={'Eg. ' + item.placeholder}
                  width="100%"
                  value={item.label}
                />
              ) : item.type === 'select' ? (
                <AntdSelect
                  OptionData={item.data}
                  valueParam={item.valueParam}
                  nameParam={item.nameParam}
                  placeholder={item.placeholder}
                  showSearch={true}
                  rules={[
                    {
                      required: true,
                      message: `Please select ${item.label}!`,
                    },
                  ]}
                  onChangeCapture={onChangeCapture}
                  onSearch={data => delayToFetch(data)}
                />
              ) : item.type === 'email' ? (
                <AntdInput
                  width="100%"
                  label="Email ID"
                  placeholder="Eg. example@gmail.com"
                  optional={'*'}
                  name={'emailAddr'}
                  rules={[
                    {
                      required: true,
                      message:
                        CONSTANTS.ERROR_MESSAGE.REQ_INPUT('email address'),
                    },
                    {
                      type: 'email',
                      message: CONSTANTS.ERROR_MESSAGE.VALID('email address'),
                    },
                  ]}
                />
              ) : (
                <div>
                  <FormItem name="type">
                    <RadioGroup onChange={onChangeCapture}>
                      <StyledRadio
                        boxed={true}
                        value={'UG'}
                        label="Under Graduate(UG)"
                      />
                      <StyledRadio
                        boxed={true}
                        value={'PG'}
                        label="Post Graduate(PG)"
                      />
                      <StyledRadio
                        boxed={true}
                        value={'PHD'}
                        label="Doctorate(Phd)"
                      />
                      <StyledRadio
                        boxed={true}
                        value={'DIPLOMA'}
                        label=" Diploma"
                      />
                      <StyledRadio
                        boxed={true}
                        value={'P_G_DIPLOMA'}
                        label="PG Diploma"
                      />
                    </RadioGroup>
                  </FormItem>
                </div>
              )}
            </Form.Item>
          ))}
          <Space>
            <FlexWrapFooter gap="20px" right>
              <Button.Secondary onClick={handleReset} text="Cancel" />
              <Button.Primary
                onClick={handleSubmit}
                type="primary"
                icon={<CheckIconWhite />}
                height="38px"
                text={toEditId ? 'Update' : 'Save'}
                disabled={view}
              />
            </FlexWrapFooter>
          </Space>
        </Form>
      </div>
      <div>
        <Divider
          style={{
            position: 'absolute',
            bottom: '50px',
          }}
        />
      </div>
    </StyledDrawer>
  )
}
export default CrudDrawer
