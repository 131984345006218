import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles';
import React from 'react';
import CONSTANTS from 'utils/constants';
import { toCamelCase } from '../../../../../utils/camelCase';

export const GeneralMenuData = [
  {
    id: 1,
    text: 'Functions',
  },
  {
    id: 2,
    text: 'Roles',
  },
  {
    id: 3,
    text: 'Sector',
  },
  {
    id: 4,
    text: 'Industry',
  },
  {
    id: 5,
    text: 'Skills',
  },
  // {
  //   id: 6,
  //   text: 'Sub-Skills',
  // },
  // {
  //   id: 7,
  //   text: 'Holidays',
  // },
  // {
  //   id: 8,
  //   text: 'Calendar',
  // },
]

export const FunctionsData = [
  {
    title: 'Functions',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
        {toCamelCase(rowData?.name) ||'-'}
      </RowNumberData>
    ),
  },
]

export const RolesData = [
  {
    title: 'Roles',
    dataIndex: 'name',
    key: 'Rolesname',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>{toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
]
export const IndustryData = [
  {
    title: 'Industry',
    dataIndex: 'name',
    key: 'Industryname',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        { toCamelCase(rowData?.name) || '-'}
      </RowNumberData>
    ),
  },
]

export const SectorData = [
  {
    title: 'Sector',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
        {toCamelCase(rowData?.name) || '-'}
      </RowNumberData>
    ),
  },
]
export const SkillsData = [
  {
    title: 'Skills',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
        { toCamelCase(rowData?.name) || '-'}
      </RowNumberData>
    ),
  },
]

export const SubSkillsData = [
  {
    title: 'Sub-Skills',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => <RowNumberData>Consultancy</RowNumberData>,
  },
]
export const HolidaysData = [
  {
    title: 'Holidays',
    dataIndex: 'name',
    sorter: true,
    key: 'name',
    render: (_, rowData) => <RowNumberData>Consultancy</RowNumberData>,
  },
]
export const CalendarData = [
  {
    title: 'Calendar',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => <RowNumberData>Consultancy</RowNumberData>,
  },
]
export const DummyData = [
  {
    degree: 'Bachelor of Technology',
    type: 'UG',
    shortForm: 'B.Tech',
    status: '3',
  },
  {
    degree: 'Master of Technology',
    type: 'PG',
    shortForm: 'M.Tech',
    status: '4',
  },
]

export const AddFunctionContent = [
  {
    id: '1',
    name: 'name',
    label: 'Function',
    placeholder: 'Developer',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  // {
  //   id: '2',
  //   name: 'shortForm',
  //   label: 'Short Form (Alias)',
  //   placeholder: '',
  //   required: true,
  //   type: 'text',
  //   pattern: CONSTANTS.REGEX.NAME,
  // },
]

export const AddRolesContent = [
  {
    id: '1',
    name: 'name',
    label: 'Roles',
    placeholder: 'Developer',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  // {
  //   id: '2',
  //   name: 'shortForm',
  //   label: 'Short Form (Alias)',
  //   placeholder: '',
  //   required: true,
  //   type: 'text',
  //   pattern: CONSTANTS.REGEX.NAME,
  // },

]

export const AddSectorContent = [
  {
    id: '1',
    name: 'name',
    label: 'Sector',
    placeholder: 'Product',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  // {
  //   id: '2',
  //   name: 'shortForm',
  //   label: 'Short Form (Alias)',
  //   placeholder: '',
  //   required: true,
  //   type: 'text',
  //   pattern: CONSTANTS.REGEX.NAME,
  // },
]

export const AddIndustryContent = [
  {
    id: '1',
    name: 'name',
    label: 'Industry',
    placeholder: 'Software',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  // {
  //   id: '2',
  //   name: 'shortForm',
  //   label: 'Short Form (Alias)',
  //   placeholder: '',
  //   required: true,
  //   type: 'text',
  //   pattern: CONSTANTS.REGEX.NAME,
  // },
]

export const AddSkillsContent = [
  {
    id: '1',
    name: 'name',
    label: 'Skills',
    placeholder: 'React js',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  // {
  //   id: '2',
  //   name: 'shortForm',
  //   label: 'Short Form (Alias)',
  //   placeholder: '',
  //   required: true,
  //   type: 'text',
  //   pattern: CONSTANTS.REGEX.NAME,
  // },
]

export const AddSubSkillsContent = [
  {
    id: '1',
    name: 'SubSkills',
    label: 'Sub Skills',
    placeholder: 'React js',
    required: true,
    type: 'text',
  },

]
export const AddHolidayContent = [
  {
    id: '1',
    name: 'Holiday',
    label: 'Holiday',
    placeholder: 'Republic Day',
    required: true,
    type: 'text',
  },
  {
    id: '2',
    name: 'SelectDate',
    label: 'Select Date',
    placeholder: '25/06/2022',
    required: true,
    type: 'date',
  },
]

export const AddCalenderContent = [
  {
    id: '1',
    name: 'Calendar',
    label: 'Calendar',
    placeholder: '25/06/2022',
    required: true,
    type: 'date',
  },
]

export const Status = [
  {
    value: 'Active',
    label: 'Active',
    checked: false,
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    checked: false,
  },
]
