import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { FooterFlex, Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import LinkGenerationDetails from 'modules/Onboarding/Partials/Corporates/Partials/LinkGenerateDrawer/Partials/LinkGenerationDetails'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const LinkGenerateDrawer = ({
  form,
  corporateClear,
  setNewLinkGenerateDrawer,
  newLinkGenerateDrawer,
  formId = 'linkForm',
  title = 'Corporate',
}) => {
  const navigate = useNavigate()

  const generatedLinkAndId = useSelector(
    state => state?.CorporateList?.generatedLinkAndId
  )

  const onClear = () => {
    form.resetFields()
    setNewLinkGenerateDrawer(false)
    corporateClear()
  }

  const submitHandler = () => {
    navigate(`/onboarding/corporate/${generatedLinkAndId.id}`)
  }

  const content = () => {
    return (
      <div>
        <Form
          scrollToFirstError
          id={formId}
          form={form}
          layout="vertical"
          onFinish={submitHandler}
        >
          <LinkGenerationDetails />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary form="linkForm" text={'Close'} onClick={onClear} />
      </FooterFlex>
    )
  }
  return (
    
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={onClear}
        visible={newLinkGenerateDrawer}
        content={content()}
        footer={footer()}
      />
    
  )
}

export default LinkGenerateDrawer
