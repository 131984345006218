import Button from 'components/Button'
import Control from 'components/Control/Control'
import { FormWrapper } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import TaxDetails from 'modules/Onboarding/Partials/Corporates/Register/TaxInfo/TaxDetails'
import React from 'react'

const TaxInfo = ({ setCurrentStep, setSubStep, onSubmit, form, ...props }) => {

  const handleSubStepReset = () => {
    setCurrentStep(0)
    setSubStep(0);
  };
  
  return (
    <FormWrapper
      scrollToFirstError
      onFinish={onSubmit}
      id={'taxDetails'}
      form={form}
      layout="vertical"
    >
      <TaxDetails form={form} {...props} />

      <Control width="100%" flexEnd gap="15px" top="35px">
        <Button.Secondary
          text="Back"
          onClick={handleSubStepReset}
        />
        <Button.Primary
          text="Save & Continue"
          htmlType={'submit'}
          form={'taxDetails'}
        />
      </Control>
    </FormWrapper>
  )
}

export default TaxInfo
