import React, { useState } from 'react'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import {
  StyledTab,
  SubmitFlex,
  MenuFlex,
} from 'modules/Dashboard/Partials/UsersFilter/Styles/style'
import Button from 'components/Button/index'
import LocationFilter from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/CorporateFilter/Partials/LocationFilter'
import StatusFilter from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/CorporateFilter/Partials/StatusFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'

const CorporateFilter = ({
  corporateList,
  currentPage,
  setCurrentPage,
  createCorporate,
  searchValue,
  setSearchValue,
  userRole,
  userRoleValue,
  setUserRoleValue,
  corporateStatus,
  setCorporateStatus,
  getUserData,
  setState,
  location,
  setLocation,
  clearInput,
  setFilterData,
  filterData,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const onApplyFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      role: userRoleValue,
      status: corporateStatus,
      location: location,
    })
    getUserData()
    setSearch('')
    setState(corporateStatus || 'all')
  }
  const onCancelFilter = open => {
    if (isMenuOpen === true) {
      setLocation(filterData.location)
      setSearch('')
      setCorporateStatus(filterData.status)
    }
    setSearch('')
    setIsMenuOpen(!isMenuOpen)
  }
  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'Location',
            key: '1',
            children: (
              <LocationFilter
                location={location}
                setLocation={setLocation}
                search={search}
                setSearch={setSearch}
                loading={loading}
                setLoading={setLoading}
              />
            ),
          },
          {
            label: 'Status',
            key: '2',
            children: (
              <StatusFilter
                corporateStatus={corporateStatus}
                setCorporateStatus={setCorporateStatus}
              />
            ),
          },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter()} />
        <Button.Primary
          onClick={onApplyFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )

  return (
    <TableSearchFilter
      placeholder="Search by Corporate Name"
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      setSearchValue={setSearchValue}
      currentCount={10 * currentPage + corporateList?.list?.length}
      totalCount={corporateList?.count}
      onOpenChange={() => onCancelFilter()}
      clearInput={clearInput}
    />
  )
}

export default CorporateFilter
