import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Dashboard/Partials/UsersFilter/Styles/style'

const StatusFilter = ({ setCorporateActiveStatus, corporateActiveStatus }) => {
  const options = [
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'InActive',
      value: false,
    },
    // {
    //   label: 'Expired',
    //   value: '2',
    // },
  ]

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={[corporateActiveStatus]}
        options={options}
        onChange={value => setCorporateActiveStatus(value[value?.length - 1])}
      />
    </Wrapper>
  )
}

export default StatusFilter
