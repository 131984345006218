import { ExclamationCircleFilled } from '@ant-design/icons'
import { Col, Empty, Form, Modal, Row } from 'antd'
import Button from 'components/Button/index'
import Pagination from 'components/Pagination'
import { Wrapper } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import PlusIcon from 'components/icons/PlusIcon'
import DeleteIcon from 'components/icons/delete.svg'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Avatar from '../../../.././components/Avatar'
import Divider from '../../../../components/Divider/Divider'
import { FlexWrap } from '../../../../components/TableStyles/TableContentStyles'
import { toCamelCase } from '../../../../utils/camelCase'
import AddInstituteDrawer from './AddInstituteDrawer/index'
import InstituteFilter from './InstituteFilter'
import {
  AlertText,
  RowNumberData,
  StyledAlert,
  TableHeadAlign,
} from './Style/style'
const { confirm } = Modal

const Institutes = ({
  loc,
  FunctionTableList,
  instituteViewData,
  userData,
  createInstitute,
  institutejourneyData,
  deleteUser,
  getInstitute,
  getRoles,
  getListOfState,
  getInstituteView,
  getListOfCity,
  singleUserData,
  getCorporateUniversityData,
  isInstAccess,
}) => {
  const [addUserDrawer, setAddUserDrawer] = useState(false)
  const [selectedRows, setSelectedRows] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [deleteUserId, setDeleteUserId] = useState([])
  const [deletedRows, setDeletedRows] = useState(false)
  const [creatId, setCreatId] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [pageNum, setPageNum] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState('')
  const [industry, setIndustry] = useState('')

  const [form] = Form.useForm()
  const params = useParams()
  let pathArray = loc?.pathname.split('/')
  let userId = pathArray[2]
  useEffect(() => {
    if (singleUserData?.userType) getInstituteData()
    // getSingleUser({ userId })
  }, [params.id, userId, searchValue, sortBy, pageNum, location])

  useEffect(() => {
    if (singleUserData?.userType) getInstituteData()
  }, [singleUserData])
  const getInstituteData = async () => {
    setLoading(true)
    const response = await getInstitute(
      params.id,
      searchValue,
      sortBy,
      pageNum,
      location,
      singleUserData?.userType
    )
    setLoading(response)
  }

  const onPageChange = key => {
    setPageNum(key - 1)
  }
  useEffect(() => {
    getInstituteData()
  }, [creatId])

  useEffect(() => {
    setCurrentPage()
  }, [pageNum, searchValue, sortBy, creatId])

  useEffect(() => {
    let searchValue = ''
    let userId = params?.id
    getInstituteView({ userId, searchValue })
    setCreatId(params.id)
    getRoles('INSTITUTE', false, false, true)
  }, [params.id])

  const onAddInstitute = () => {
    setAddUserDrawer(true)
    form.resetFields()
  }

  const onSorting = (pagination, filter, sorter) => {
    setSortBy(sorter)
  }
  const callback = () => {
    getInstituteData()
  }
  const showConfirm = async instCorpMap => {
    if (instCorpMap) {
      confirm({
        title: 'Do you want to Un-Link this Institute?',
        icon: <ExclamationCircleFilled />,
        content: '',
        async onOk() {
          const payload = {
            instCorpMapId: instCorpMap,
            journey: 'Institute',
          }
          const response = await deleteUser(payload, { callback })
          if (response) {
            setSelectedRows([])
            setSelectedRowKeys([])
            setDeletedRows([])
            setDeleteUserId([])
            await getInstituteData()
            setSelectedRows([])
            setSelectedRowKeys([])
            setDeletedRows([])
            setDeleteUserId([])
          }
        },
        onCancel() {},
      })
    }
  }

  const columns = [
    {
      title: 'College',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_, rowData) => (
        <FlexWrap>
          <Avatar
            background
            src={rowData?.logo}
            textTransform="capitalize"
            marginRight={'10px'}
            size={35}
            IconName={rowData?.name?.split('')[0]}
            font={18}
          />

          <RowNumberData style={{ alignSelf: 'center', paddingLeft: '5px' }}>
            {toCamelCase(rowData?.name) || '-'}
          </RowNumberData>
        </FlexWrap>
      ),
    },
    {
      title: 'University',
      dataIndex: 'university',
      key: 'university',
      sorter: true,
      render: rowData => toCamelCase(rowData ?? rowData?.university) || '-',
    },
    {
      title: 'Location',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      render: (_, rowData) => (
        <>
          {rowData?.instituteCampus?.map(step => (
            <RowNumberData
              key={step.id}
              style={{ alignSelf: 'center', paddingLeft: '5px' }}
            >
              {toCamelCase(step?.city) || '-'}
            </RowNumberData>
          ))}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <img
          src={DeleteIcon}
          alt="delete"
          onKeyDown={() => {}}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (rowData?.instCorpMapId) showConfirm([rowData?.instCorpMapId])
          }}
        />
      ),
    },
  ]

  const dataChange = (selectedRowKeys, selectedRows) => {
    const id = institutejourneyData?.list?.filter(item =>
      selectedRowKeys.includes(item.id)
    )
    const filterId = id.map(item => item.instCorpMapId)
    setDeleteUserId(filterId)
    setSelectedRows(selectedRows)
    setDeletedRows(selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: dataChange,
  }

  const handleClick = async data => {
    // const instCorpMapIds = institutejourneyData?.list?.map(
    //   item => item.instCorpMapId
    // )
    const instCorpMapIds = data.map(item => item.instCorpMapId)

    showConfirm(instCorpMapIds)
  }

  return (
    <>
      {singleUserData?.userType?.toUpperCase() != 'ADMIN' && (
        <>
          <TableHeadAlign>
            <FlexWrap gap={'20px'}>
              {!isInstAccess && (
                <StyledAlert
                  message={
                    <AlertText>
                      Note:-Update the User Role to Map the Institute
                    </AlertText>
                  }
                  type="warning"
                  showIcon
                />
              )}
            </FlexWrap>
            <buttonAlign>
              <Button.Primary
                text="Add"
                disabled={!isInstAccess}
                icon={isInstAccess ? <PlusIcon /> : null}
                onClick={onAddInstitute}
              />
            </buttonAlign>
          </TableHeadAlign>

          <div span={1}>
            {selectedRows?.length > 1 ? (
              <img
                src={DeleteIcon}
                alt="delete"
                onKeyDown={() => {}}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(selectedRows)
                }}
              />
            ) : null}
          </div>
          <br />
        </>
      )}
      <Wrapper>
        {institutejourneyData ? (
          <>
            <InstituteFilter
              institutejourneyData={institutejourneyData}
              currentPage={currentPage}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              getInstituteData={getInstituteData}
              location={location}
              industry={industry}
              setLocation={setLocation}
              setIndustry={setIndustry}
              pageNum={pageNum}
              setPageNum={setPageNum}
              getListOfCity={getListOfCity}
              setCurrentPage={setPageNum}
            />
            <Divider mt="0" />
            <StyledTable
              columns={columns}
              pagination={false}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              rowKey="id"
              dataSource={
                singleUserData?.roles?.journeys?.includes('INSTITUTE')
                  ? institutejourneyData?.list
                  : []
              }
              onChange={onSorting}
              locale={{
                emptyText: (
                  <Row align={'center'}>
                    <Col span={5}>
                      <Empty description={'No Institute found!'} />
                    </Col>
                  </Row>
                ),
              }}
            />
            <Pagination
              onChange={onPageChange}
              totalPages={institutejourneyData?.count}
              pageSize={institutejourneyData?.limit}
              current={pageNum + 1}
            />
          </>
        ) : (
          <Row align={'center'}>
            <Col span={5}>
              <Empty description={'No data found!'} />
            </Col>
          </Row>
        )}
      </Wrapper>

      <AddInstituteDrawer
        getCorporateUniversityData={getCorporateUniversityData}
        addUserDrawer={addUserDrawer}
        setAddUserDrawer={setAddUserDrawer}
        form={form}
        loc={loc}
        getListOfCity={getListOfCity}
        getInstituteView={getInstituteView}
        loading={loading}
        setLocation={setLocation}
        FunctionTableList={FunctionTableList}
        creatId={creatId}
        userData={userData}
        getInstituteData={getInstituteData}
        setLoading={setLoading}
        instituteViewData={instituteViewData}
        createInstitute={createInstitute}
        getListOfState={getListOfState}
      />
    </>
  )
}

export default Institutes
