import { Col, Form, Menu } from 'antd'
import Button from 'components/Button/index'
import Toggle from 'components/UIComponents/Toggle/index'
import 'draft-js/dist/Draft.css'
import { ACADEMIC_MENU, TIER_MENU } from 'modules/RankingAlgorithm/Data.js'
import Academic from 'modules/RankingAlgorithm/Partials/AcademicGrid/Academic/AcademicIndex.js'
import TierIndex from 'modules/RankingAlgorithm/Partials/AcademicGrid/Tier/TierIndex'
import {
  FooterDiv,
  FooterFlex,
  RowStyling,
  SectionWrapperToggle,
  StyledMenu,
  ToggleButtonAcademic,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../style.css'
export default function AcademicIndex({
  academicDataConfig,
  updateAcademictData,
  onClickAcademic,
  currentAcademic,
  handleChange,
}) {
  const [form] = Form.useForm()

  const [academicListSelection, setAcademicListSelection] = useState('10th')
  const [toggleAcademic, setToggleAcademic] = useState('scoreConfig')
  const [data, setData] = useState([])
  const [academicDataAlone, setAcademicDataAlone] = useState(data)
  const [academicToggle, setAcademicToggle] = useState(false)
  const [academicValidation, setAcademicValidation] = useState(false)
  const [menu, setMenu] = useState(ACADEMIC_MENU)

  const activeAcademicData = data?.find(item => item.academic === '10th')
  const activeAcademicTierData = data?.find(item => item.academic === 'UG')
  const [singleAcademicData, setSingleAcademicData] = useState(
    activeAcademicData?.scoreConfig || []
  )
  const [singleAcademicTierData, setSingleAcademicTierData] = useState(
    activeAcademicTierData?.tierConfig || []
  )
  const academicListOnClick = event => {
    setAcademicListSelection(event)
    setAcademicValidation(false)
    const Data = academicDataAlone.find(item => item.academic === event)
    setSingleAcademicData(Data?.scoreConfig || [])
    setSingleAcademicTierData(Data?.tierConfig || [])
    setAcademicToggle(
      currentAcademic ? Data?.isTierActive : Data?.isScoreActive || false
    )
  }

  // Function to update the scoreConfig for "UG" academic
  const updateScoreConfigForUG = () => {
    setData(prevData => {
      const newData = [...prevData]
      const ugAcademicIndex = newData.findIndex(
        item => item.academic === academicListSelection
      )
      if (ugAcademicIndex !== -1) {
        newData[ugAcademicIndex][toggleAcademic] = currentAcademic
          ? singleAcademicTierData
          : singleAcademicData
        newData[ugAcademicIndex][
          currentAcademic ? 'isTierActive' : 'isScoreActive'
        ] = academicToggle
      }
      return newData
    })
  }
  useEffect(() => {
    setSingleAcademicData(activeAcademicData?.scoreConfig || [])
    setSingleAcademicTierData(activeAcademicTierData?.tierConfig || [])
  }, [activeAcademicData, activeAcademicTierData])

  useEffect(() => {
    if (currentAcademic) {
      setMenu(TIER_MENU)
      setAcademicListSelection('UG')
      academicListOnClick('UG')
      setToggleAcademic('tierConfig')
    } else {
      setMenu(ACADEMIC_MENU)
      academicListOnClick('10th')
      setAcademicListSelection('10th')
      setToggleAcademic('scoreConfig')
    }
  }, [currentAcademic, activeAcademicData])

  useEffect(() => {
    updateScoreConfigForUG()
  }, [singleAcademicData, singleAcademicTierData, academicToggle])

  useEffect(() => {
    if (academicDataConfig?.length > 0) {
      setData(academicDataConfig)
      setAcademicDataAlone(academicDataConfig)
    }
  }, [academicDataConfig])
  const onSubmit = () => {
    let payload = data
    let mainbase = 'ACADEMIC'
    updateAcademictData({ mainbase, payload })
  }
  return (
    <Form form={form} onFinish={onSubmit}>
      <SectionWrapperToggle column>
        <ToggleButtonAcademic>
          <Toggle
            left="Academic"
            right="Tier"
            onClick={onClickAcademic}
            initialValue={currentAcademic ? 'right' : 'left'}
          />
        </ToggleButtonAcademic>
        <RowStyling>
          <Col span={6}>
            {menu.map(item => (
              <StyledMenu
                key={item.name}
                selectedKeys={academicListSelection}
                defaultSelectedKeys={academicListSelection}
              >
                <Menu.Item
                  key={item?.name}
                  onClick={() => academicListOnClick(item.name)}
                >
                  {item?.name}
                </Menu.Item>
              </StyledMenu>
            ))}
          </Col>
          <Col span={18}>
            <Col span={18} style={{ maxWidth: '100%' }}>
              {currentAcademic ? (
                <TierIndex
                  singleAcademicTierData={singleAcademicTierData}
                  academicListSelection={academicListSelection}
                  setSingleAcademicTierData={setSingleAcademicTierData}
                  setAcademicToggle={setAcademicToggle}
                  academicToggle={academicToggle}
                />
              ) : (
                <Academic
                  keys={academicValidation}
                  singleAcademicData={singleAcademicData}
                  setSingleAcademicData={setSingleAcademicData}
                  setAcademicDataAlone={setAcademicDataAlone}
                  academicDataAlone={academicDataAlone}
                  setAcademicValidation={setAcademicValidation}
                  academicValidation={academicValidation}
                  academicName={academicListSelection}
                  updateScoreConfigForUG={updateScoreConfigForUG}
                  academicListSelection={academicListSelection}
                  academicToggle={academicToggle}
                  setAcademicToggle={setAcademicToggle}
                  form={form}
                />
              )}
            </Col>
          </Col>
          <FooterDiv>
            <FooterFlex right>
              <Button.Secondary text="Cancel" onClick={handleChange} />
              <Button.Primary text={'Save and Update'} htmlType={'submit'} />
            </FooterFlex>
          </FooterDiv>
        </RowStyling>
      </SectionWrapperToggle>
    </Form>
  )
}
