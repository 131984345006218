import React from 'react'
import Flex from 'components/Flex'
import AntdAvatar from 'components/Avatar'
import {
  CollageNameFlex,
  CollegeNameDiv,
  CollegeCity,
  CollageDetailsFlex,
  SelectClgDiv,
} from './style'


import DeleteIcon from 'components/icons/DeleteIcon'

const ConstituencyList = ({
  id,
  logoUrl,
  collageName,
  collageLocation,
  onRemoveCollege,
}) => {
  return (
    
      <CollageDetailsFlex spaceBetween centerVertically>
        <Flex>
          <AntdAvatar
            src={logoUrl}
            size={36}
            IconName={collageName?.split(' ')[0]?.split('')[0]}
          />

          <CollageNameFlex>
            <CollegeNameDiv>{collageName}</CollegeNameDiv>
            <CollegeCity>{collageLocation}</CollegeCity>
          </CollageNameFlex>
        </Flex>


          <SelectClgDiv background="none" 
          onClick={() => onRemoveCollege(id)}
          >
            <DeleteIcon />
          </SelectClgDiv>
        
      </CollageDetailsFlex>
    
  )
}

export default ConstituencyList
