import React, { useEffect, useState } from 'react'
import { COLORS } from 'theme'
import InfoCards from '../../components/InfoCards'
import Active from '../../components/icons/Active'
import InActive from '../../components/icons/InActive'
import Institute from '../../components/icons/Institute'
import InstituteInfoTable from './InstituteTable'
import { CardRow, SectionWrapper, TopHeader, TopHeading } from './Style/style'

const Institutes = ({
  getUserInstitutePortalsList,
  instituteList,
  getListOfState,
  getListOfCity,
}) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [instituteStatus, setInstituteStatus] = useState('')
  const [instituteActiveStatus, setInstituteActiveStatus] = useState()
  const [filterData, setFilterData] = useState({})
  const [location, setLocation] = useState('')
  const [stateId, setStateId] = useState('')
  const [stateList, setStateList] = useState([])
  const [stateNames, setStateNames] = useState([])
  const [cityId, setPermCityId] = useState('')
  const [cityList, setCityList] = useState([])
  const [cityName, setCityName] = useState([])

  const [cardsData, setCardsData] = useState([])

  useEffect(() => {
    getUserInstitutePortalsList({
      page,
      search,
      sort,
      orderBy,
      instituteStatus,
      instituteActiveStatus,
      setLoading,
      ...(filterData || {}),
    })
  }, [
    instituteStatus,
    page,
    search,
    sort,
    orderBy,
    filterData,
    instituteActiveStatus,
  ])

  useEffect(() => {
    setPage(0)
  }, [
    search,
    instituteStatus,
    stateNames,
    instituteActiveStatus,
    cityName,
    location,
    orderBy,
  ])

  useEffect(() => {
    const cardData = [
      {
        key: 1,
        icon: <Institute />,
        text: 'Total Institutes',
        value: instituteList?.totalCount || 0,
        isSelected: false,
      },
      {
        key: 2,
        icon: <Active />,
        text: 'Active',
        value: instituteList?.activeCount || 0,
        filter: true,
        isSelected: false,
      },
      {
        key: 3,
        icon: <InActive />,
        text: 'Inactive',
        value: instituteList?.inActiveCount || 0,
        filter: false,
        isSelected: false,
      },
      // { *** For Future Use Case ***
      //   key: 4,
      //   icon: <Expired />,
      //   text: 'Expired',
      //   value: 0,
      //   isSelected: false,
      // },
    ]
    setCardsData(cardData)
  }, [instituteList])

  //handlers
  const applyCardFilter = query => {
    let cardsCopy = [...cardsData]
    cardsCopy = cardsCopy.map(val => {
      if (query.filter === val.filter) {
        if (val.isSelected) {
          val.isSelected = false
        } else {
          setInstituteActiveStatus(query.filter)
        }
      } else {
        val.isSelected = false
      }
      return val
    })
    setCardsData(cardsCopy)
  }

  const onPageChange = key => {
    setPage(key - 1)
  }
  return (
    <SectionWrapper column>
      <TopHeader row spaceBetween alignCenter>
        <TopHeading>Institutes</TopHeading>
      </TopHeader>
      <CardRow spaceBetween>
        {cardsData?.map(card => (
          <InfoCards
            key={card?.text}
            padding={'20px'}
            width={'25%'}
            icon={card?.icon}
            text={card?.text}
            value={card?.value}
            background={card?.isSelected ? COLORS.BLUE_T_50 : COLORS.BLUE_T_95}
            isSelected={card?.isSelected}
            onClick={() => {
              applyCardFilter(card)
            }}
          />
        ))}
      </CardRow>
      <InstituteInfoTable
        instituteActiveStatus={instituteActiveStatus}
        location={location}
        setLocation={setLocation}
        setInstituteActiveStatus={setInstituteActiveStatus}
        instituteList={instituteList}
        loading={loading}
        page={page + 1}
        stateId={stateId}
        filterData={filterData}
        setFilterData={setFilterData}
        setStateId={setStateId}
        stateList={stateList}
        setStateList={setStateList}
        stateNames={stateNames}
        setStateNames={setStateNames}
        getListOfState={getListOfState}
        cityList={cityList}
        setCityList={setCityList}
        cityName={cityName}
        setCityName={setCityName}
        getListOfCity={getListOfCity}
        cityId={cityId}
        setPermCityId={setPermCityId}
        onPageChange={onPageChange}
        setSearch={setSearch}
        setPage={setPage}
        setSort={setSort}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        sortBy={sort}
        setInstituteStatus={setInstituteStatus}
      />
    </SectionWrapper>
  )
}
export default Institutes
