import DurationIndex from 'modules/RankingAlgorithm/Partials/SkillBucket/Common/Duration/durationIndex.js'
import MandatorySkillIndex from 'modules/RankingAlgorithm/Partials/SkillBucket/Common/Skill/skillIndex.js'
import CertificateIndex from 'modules/RankingAlgorithm/Partials/TrainingCourse/Certificate/certificateIndex.js'
import { PaddedToggleDive } from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import DomainIndex from 'modules/RankingAlgorithm/Partials/WorkExperience/Domain/domainIndex.js'
import React from 'react'
export default function CommonIndex({
  keys,
  singleDurationData,
  academicDataAlone,
  setSingleDurationData,
  setAcademicDataAlone,
  setAcademicValidation,
  academicName,
  setCertificateToggle,
  certificateToggle,
  domainData,
  setDomainData,
  form,
}) {
  const content = () => {
    return (
      <div>
        {academicName === 'Mandatory Skills' ? (
          
            <MandatorySkillIndex />
          
        ) : academicName === 'Certificate' ? (
          
            <CertificateIndex
              setCertificateToggle={setCertificateToggle}
              certificateToggle={certificateToggle}
            />
          
        ) : academicName === 'Domain' ? (
          
            <DomainIndex
              singleDurationData={singleDurationData}
              setSingleDurationData={setSingleDurationData}
              setAcademicDataAlone={setAcademicDataAlone}
              academicDataAlone={academicDataAlone}
              setAcademicValidation={setAcademicValidation}
              setDomainData={setDomainData}
              domainData={domainData}
              form={form}
            />
          
        ) : (
          
            <DurationIndex
              keys={keys}
              isActiveNeeded={false}
              singleDurationData={singleDurationData}
              setSingleDurationData={setSingleDurationData}
              setAcademicDataAlone={setAcademicDataAlone}
              academicDataAlone={academicDataAlone}
              setAcademicValidation={setAcademicValidation}
              form={form}
            />
          
        )}
      </div>
    )
  }
  return (
    <div>
      <PaddedToggleDive>{content()}</PaddedToggleDive>
    </div>
  )
}
