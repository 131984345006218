import { Tooltip } from 'antd'
import SearchInput from 'components/SearchInput'
import FilterDiv, {
  FilterText,
} from 'components/UIComponents/TableSearchFilter/FilterDiv'
import { TableTop } from 'components/UIComponents/TableSearchFilter/Style/style'
import React from 'react'

const TableSearchFilter = ({
  defaultFirstPageNumber,
  menu,
  totalCount = 0,
  currentCount,
  searchValue,
  setSearchValue,
  open,
  setOpen,
  placeholder = 'Search by keyword',
  onOpenChange,
  isReport,
  useDebounce,
  setCurrentPage,
  setPageNum,
  menus,
  clearInput,
}) => {
  return (
    <TableTop centerVertically>
      {menus ? (
        <>
          <FilterDiv
            overlayMenu={menu}
            open={open}
            setOpen={setOpen}
            onOpenChange={onOpenChange}
            hideBorder={menus}
          />
          <Tooltip title={placeholder}>
            <SearchInput
              placeholder={placeholder}
              bordered={false}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isReport={isReport}
              useDebounce={useDebounce}
              setCurrentPage={setCurrentPage}
              defaultFirstPageNumber={defaultFirstPageNumber}
              hasBorderRight={menus}
              clearInput={clearInput}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={placeholder}>
            <SearchInput
              placeholder={placeholder}
              bordered={false}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isReport={isReport}
              useDebounce={useDebounce}
              setCurrentPage={setCurrentPage}
              defaultFirstPageNumber={defaultFirstPageNumber}
              clearInput={clearInput}
            />
          </Tooltip>
          <FilterDiv
            overlayMenu={menu}
            open={open}
            setOpen={setOpen}
            onOpenChange={onOpenChange}
          />
        </>
      )}

      <FilterText>
        Showing {currentCount || '0'} of {totalCount}
      </FilterText>
    </TableTop>
  )
}

export default TableSearchFilter
