import { Form } from 'antd'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import { deleteFile } from 'modules/Onboarding/actions'
import { FormWrapper } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import PricingContractDetails from 'modules/Onboarding/Partials/Corporates/Register/PricingContract/PricingContractDetails'
import React from 'react'
import { useDispatch } from 'react-redux'
import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'

const PricingContarctInfo = ({ setCurrentStep, form, onSubmit, ...props }) => {
  const dispatch = new useDispatch()
  const regDocs = Form.useWatch('regDocs', form)

  const onRemoveImage = async () => {
    let response = await dispatch(deleteFile(regDocs))
    if (response) {
      form.setFieldsValue({ regDocs: null })
      SuccessMessage('File deleted successfully!')
    } else {
      ErrorMessage('Failed to delete the contractDetails!')
    }
  }

  return (
    <FormWrapper
      scrollToFirstError
      onFinish={onSubmit}
      id={'taxDetails'}
      form={form}
      layout="vertical"
    >
      <PricingContractDetails
        form={form}
        onRemoveImage={onRemoveImage}
        regDocs={regDocs}
        {...props}
      />
      <Control width="100%" flexEnd gap="15px" top="35px">
        <Button.Secondary text="Back" onClick={() => setCurrentStep(1)} />
        <Button.Primary
          text="Complete"
          htmlType={'submit'}
          form={'taxDetails'}
        />
      </Control>
    </FormWrapper>
  )
}

export default PricingContarctInfo
