import React from 'react'

const Active = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 6H7.25C3.94 6 1.25 8.69 1.25 12C1.25 15.31 3.94 18 7.25 18H17.25C20.56 18 23.25 15.31 23.25 12C23.25 8.69 20.56 6 17.25 6ZM17.25 16H7.25C5.04 16 3.25 14.21 3.25 12C3.25 9.79 5.04 8 7.25 8H17.25C19.46 8 21.25 9.79 21.25 12C21.25 14.21 19.46 16 17.25 16ZM17.25 9C15.59 9 14.25 10.34 14.25 12C14.25 13.66 15.59 15 17.25 15C18.91 15 20.25 13.66 20.25 12C20.25 10.34 18.91 9 17.25 9Z"
        fill="#5B6DDF"
      />
    </svg>
  )
}

export default Active
