import request from 'utils/studentRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'components/UIComponents/MessagePopup/ErrorMessage'
import corporateRequest from 'utils/corporateRequest'
import {
  getUserCorporatePortalsList,
  getActiveCorporatePortalsList,
} from 'modules/Corporates/actions.js'
export const SET_TEMPLATE_LIST_STUDENT = `SET_TEMPLATE_LIST_STUDENT`
export const UPDATE_TEMPLATE_LIST_STUDENT = `UPDATE_TEMPLATE_LIST_STUDENT`
export const UPDATE_ACADEMIC_STUDENT = `UPDATE_ACADEMIC_STUDENT`
export const SET_ACADEMIC_STUDENT = `SET_ACADEMIC_STUDENT`
export const SET_SKILL_BUCKET_DATA = `SET_SKILL_BUCKET_DATA`
export const UPDATE_SKILL_BUCKET_DATA = `UPDATE_SKILL_BUCKET_DATA`
export const UPDATE_RANKING_CORPORATE_DATA = `UPDATE_RANKING_CORPORATE_DATA`

export const setTemplateListStudent = data => {
  return {
    type: SET_TEMPLATE_LIST_STUDENT,
    data,
  }
}
export const updateTemplateListStudent = data => {
  return {
    type: UPDATE_TEMPLATE_LIST_STUDENT,
    data,
  }
}
export const updateRankingEnabledCorporate = data => {
  return {
    type: UPDATE_RANKING_CORPORATE_DATA,
    data,
  }
}
export const setAcademicStudentData = data => {
  return {
    type: SET_ACADEMIC_STUDENT,
    data,
  }
}
export const updateAcademicStudentData = data => {
  return {
    type: UPDATE_ACADEMIC_STUDENT,
    data,
  }
}
export const setSkillBucketData = data => {
  return {
    type: SET_SKILL_BUCKET_DATA,
    data,
  }
}
export const updateSkillBucketData = data => {
  return {
    type: UPDATE_SKILL_BUCKET_DATA,
    data,
  }
}
export const getTemplateListStudent = () => async dispatch => {
  try {
    const response = await request.get(`students/rankingConfig`)
    dispatch(setTemplateListStudent(response?.data?.data?.result || []))
  } catch (error) {}
}

export const updateStudentTemplateList =
  ({ setLoading, payload }) =>
  async dispatch => {
    setLoading(true)
    try {
      const response = await request.put(
        `/students/rankingConfigOrderAndStatus`,
        payload
      )
      dispatch(updateTemplateListStudent(response?.data?.data || []))
      if (setLoading) setLoading(false)

      SuccessMessage('Updated Successfully!')
    } catch (error) {
      setLoading(false)
      ErrorMessage('Failed To Update!')
    }
  }
export const getAcademicStudentData =
  ({ id }) =>
  async dispatch => {
    dispatch(setAcademicStudentData([]))

    try {
      const response = await request.get(`/students/rankingConfig/${id}`)
      dispatch(setAcademicStudentData(response?.data?.data || []))
    } catch (error) {
      console.log(error)
    }
  }

export const updateAcademictData =
  ({ mainbase, setLoading, payload }) =>
  async dispatch => {
    setLoading(true)
    try {
      await request.put(`/students/rankingConfig/${mainbase}`, payload)
      if (setLoading) setLoading(false)
      SuccessMessage('Updated Successfully!')
    } catch (error) {
      setLoading(false)
      ErrorMessage('Failed To Update!')
    }
  }

//no need for this for now.............
export const getSkillBucketData =
  ({ setAcademicLoading, id }) =>
  async dispatch => {
    setAcademicLoading(true)
    try {
      const response = await request.get(`/students/rankingConfig/${id}`)
      dispatch(setSkillBucketData(response?.data?.data || []))
      if (setAcademicLoading) setAcademicLoading(false)
    } catch (error) {
      setAcademicLoading(false)
    }
  }

export const updateSkillBucketsData =
  ({ setLoading, payload }) =>
  async dispatch => {
    setLoading(true)
    try {
      await request.put(`/students/rankingConfig/SKILL_BUCKET`, payload)
      if (setLoading) setLoading(false)
      SuccessMessage('Updated Successfully!')
    } catch (error) {
      setLoading(false)
      ErrorMessage('Failed To Update!')
    }
  }
export const updateRankingCorporate =
  ({ setLoading, payload, setResponse }) =>
  async dispatch => {
    setLoading(true)
    try {
      let response = await corporateRequest.patch(
        `corporates/mapRankingToCorporate`,
        payload
      )
      setResponse(response?.status)
      if (setLoading) setLoading(false)
      dispatch(
        getUserCorporatePortalsList({
          pageLimit: '10',
          isRankingEnabled: true,
        })
      )
      dispatch(
        getActiveCorporatePortalsList({
          pageLimit: '1000',
        })
      )
      SuccessMessage('Updated Successfully!')
    } catch (error) {
      setLoading(false)
      ErrorMessage('Failed To Update!')
    }
  }
