import { Form } from 'antd'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import { FormWrapper } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import ConstituencyDetails from 'modules/Onboarding/Partials/Institutes/Register/InstituteConstituency/ConstituencyForm'
import React, { useState } from 'react'
import ConstituencyList from './ConstituencyList/index'

const InstituteConstituencyInfo = ({ setInstSubstep, setInstCurrent }) => {
  const [form] = Form.useForm()
  const [defaultCheckBoxList, setDefaultCheckBoxList] = useState([])
  const [selectedConstitutencyInstitute] = useState([])

  const onRemoveCollege = id => {
    let filteredArray = selectedConstitutencyInstitute.filter(
      item => item.instituteCampusId !== id
    )
    selectedConstitutencyInstitute(filteredArray)
  }

  const handleConstituencyInstituteDrawer = () => {
    setDefaultCheckBoxList(selectedConstitutencyInstitute)
  }

  const submitHandler = async values => {
    setInstSubstep(3)
    setInstCurrent(1)
  }

  const skip = () => {
    setInstSubstep(2)
  }

  const back = () => {
    setInstSubstep(0)
  }
  return (
    <FormWrapper
      scrollToFirstError
      onFinish={submitHandler}
      id={'taxDetails'}
      form={form}
      layout="vertical"
    >
      <ConstituencyDetails
        form={form}
        handleConstituencyInstituteDrawer={handleConstituencyInstituteDrawer}
        defaultCheckBoxList={defaultCheckBoxList}
        setDefaultCheckBoxList={setDefaultCheckBoxList}
      />

      {selectedConstitutencyInstitute?.length > 0 ? (
        <>
          {selectedConstitutencyInstitute.map(item => (
            <ConstituencyList
              id={item.id}
              key={item?.id}
              logoUrl={item?.campusLogoUrl}
              collageName={item?.campusName}
              collageLocation={item?.city}
              onRemoveCollege={onRemoveCollege}
            />
          ))}
        </>
      ) : null}

      <Control flexStart width="100%" spaceBetween gap="90px">
        <Control flexStart>
          <Button.Secondary text="Skip" onClick={skip} />
        </Control>
        <Control flexStart gap="25px">
          <Button.Secondary text="Back" onClick={back} />

          <Button.Primary
            text="Save & Continue"
            htmlType={'submit'}
            form={'taxDetails'}
          />
        </Control>
      </Control>
    </FormWrapper>
  )
}

export default InstituteConstituencyInfo
