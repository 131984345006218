/* eslint-disable */
import { Dropdown, Menu } from 'antd'
import Avatar from 'components/Avatar'
import Flex from 'components/Flex'
import ArrowDown from 'components/icons/ArrowDown'
import ManageIcon from 'components/icons/ManageIcon'
import PluginLiveLogo from 'components/icons/PluginLiveLogo'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getRoleName } from '../../../../utils/getRoleName'
import { toCamelCase } from '../../../../utils/camelCase'

const BREAKPOINT = '999px'

const StyledFlex = styled(Flex)`
  /* position: fixed; */
  width: 100%;
  display: flex;
  padding: 5px 48px 5px 20px;
  height: 82px;
  margin: 0px -20px;
  z-index: 22;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: ${BREAKPOINT}) {
    padding: 15px 20px 15px 0px !important;
    background: #ffffff;
    margin: 0px !important;
  }
`

const IconWrapper = styled(Flex)`
  background-color: white;
  min-width: 225px;
  max-width: 200px;
  justify-content: center;
`

const Divider = styled.div`
  border-left: 1px solid #e6e7ea;
  margin-right: 25px;
  height: 55px;
`
const Typography = styled.div`
  height: 55px;
  margin-top: 35px;
  font-size: 20px;
  font-weight: 700;
`

export const ManageFlex = styled(Flex)`
  height: 35px;
  padding-top: 8px;
  gap: 8px;
`
export const DropMenu = styled(Menu)`
  width: 300px;
  padding: 10px;
`
export const Outerdiv = styled(Flex)`
  padding: 5px;
  text-align: center;
`
export const NameDiv = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding: 8px;
`
export const EmailDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #808697;
`
export const AdminDiv = styled.div`
  padding: 3px 6px 4px;
  min-width: 44px;
  height: 17px;
  background: #dff0ea;
  border-radius: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #5db594;
  margin-top: 8px;
`

export const ManageText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 2px;
  color: #343e59;
`
export const UserFlex = styled(Flex)`
  cursor: pointer;
`

const Header = ({ userData, getUserInfo, roleList }) => {
  const CheckUserlogo = (logo, sizevalue) => {
    let userName = userData?.first_name
      ? userData?.first_name?.toUpperCase()
      : []
    if (!logo) {
      return (
        <Avatar
          background
          size={sizevalue}
          IconName={userName[0] ?? 'A'}
          font={18}
        />
      )
    } else {
      return <Avatar size={sizevalue} src={userData?.profile_picture} />
    }
  }
  const menu = (
    <DropMenu
      items={[
        {
          label: (
            <Outerdiv column centerVertically>
              {CheckUserlogo(userData?.profile_picture, 64)}

              <NameDiv>
                {toCamelCase(userData?.first_name)}{' '}
                {toCamelCase(userData?.last_name)}
              </NameDiv>
              <EmailDiv>{userData?.email}</EmailDiv>
              <AdminDiv>
                {getRoleName(roleList, userData?.admin_role_id)}
              </AdminDiv>
            </Outerdiv>
          ),
          key: '0',
        },
        {
          label: (
            <Link to="/manageprofile">
              <ManageFlex>
                <Avatar src={<ManageIcon />} />

                <ManageText>Manage Profile</ManageText>
              </ManageFlex>
            </Link>
          ),
          key: '1',
        },
        // {
        //   type: 'divider',
        // },
        // {
        //   label: (
        //     <ManageFlex onClick={onLogout}>
        //       <Avatar size={20} src={<AlertIcon />} />
        //       <ManageText>Logout</ManageText>
        //     </ManageFlex>
        //   ),
        //   key: '3',
        // },
      ]}
    />
  )

  return (
    <StyledFlex spaceBetween centerVertically isDisplay={true}>
      <Flex centerVertically>
        <IconWrapper>
          <PluginLiveLogo />
        </IconWrapper>
        <Divider />
      </Flex>

      <Flex centerVertically>
        <Dropdown
          overlayStyle={{ zIndex: '1001' }}
          overlay={menu}
          trigger={['click']}
        >
          <UserFlex>
            {CheckUserlogo(userData?.profile_picture ?? null, 36)}

            <Avatar
              style={{ marginTop: '10px' }}
              padding={'10px 0px 0px 0px'}
              size={36}
              src={<ArrowDown />}
            />
          </UserFlex>
        </Dropdown>
      </Flex>
    </StyledFlex>
  )
}
export default Header
