import styled, { css } from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'

export const TabsWrapper = styled(Flex)`
  width: 100%;
  gap: 15px;
  margin-left: 15px;
  border-bottom: 0.5px solid ${COLORS.GREY_T_90};
`
export const TabItem = styled.div(
  props => css`
    padding: 14px 20px;
    font-weight: 600;
    font-size: 16px;
    color: ${COLORS.GREY_T_50};
    display: flex;
    gap: 10px;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    ${props.isActive &&
    css`
      border-bottom: 3px solid #5265dd;
      color: #5265dd;
    `};
  `
)
export const TabCount = styled(Flex)`
  width: 2rem;
  height: 1.3rem;
  line-height: 1.3rem;
  border-radius: 0.5rem;
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  justify-content: center;
  font-size: 12px;
`
