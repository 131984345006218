import Button from 'components/Button'
import Input from 'components/Form/Input'
import Label from 'components/Form/Label'
import AntdSelect from 'components/Form/Select'
import RangeSliderInput from 'components/RangeSliderInput'
import CheckIcon from 'components/icons/CheckIcon'
import DoubleArrowIcon from 'components/icons/DoubleArrowIcon'
import DepartmentAndSpecialization from 'modules/Courses/Partials/AddCourseDrawer/DepartmentComponent/index'
import {
  DepartError,
  FooterFlex,
  Form,
  FormItem,
  RadioGroup,
  StyledDrawer,
  StyledRadio,
} from 'modules/Courses/Partials/AddCourseDrawer/Style/style'
import { getDegreeData } from 'modules/Courses/actions'
import { TwoFields } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { searchConfig } from 'utils/config'
import { getMasterSearchApi } from '../../../Onboarding/actions'
import { dropDownValues } from '../../../Systemconfig/Partials/InstituteSettings/actions'

const StyledFormItem = styled(Form.Item)`
  // &.ant-form-item-has-error .ant-form-item-explain {
  //   position: relative;
  //   top: 10px;
  // }
`
const AddCourseDrawer = ({
  addCourse,
  setAddCourse,
  createEditForm,
  formId = 'course_form',
  title = 'Add New Course',
  sendButtonText = 'Save Course',
  AddMultiCourses,
  specialisationId,
  setDegreeStreamMapId,
  submitClose,
  isediting,
  courseId,
  EditCourses,
  listOfStudentSkills,
  carryParam,
  cruds,
  collegeID,
  systemConfig,
  getDepartmentListForSysConfig,
  getSpecialisationListForSysConfig,
  instituteTableList,
  specialisationTableList,
  departmentTableList,
  AddCoursesInSysConfig,
  EditCoursesInSysConfig,
  getInstituteListForSysConfig,
  data,
  view,
  degreeStreamMapId,
  degreeId,
  delayToFetchSkill,
  readOnly = { readOnly },
}) => {
  const formRef = React.createRef()
  const dispatch = useDispatch()
  const [typeCourse, setTypeCourse] = useState('')
  const [degreeSearch] = useState('')
  const [degreeList, setDegreeList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [specialisationList, setSpecialisationList] = useState([])
  const [checkListCount, setCheckListCount] = useState(0)
  const [specialisationCount, setSpecialisationCount] = useState(0)
  const [departmentValue, setDepartmentValue] = useState([])
  const [specializationValue, setSpecializationValue] = useState([])
  const [isElementVisible, setIsElementVisible] = useState(false)
  const [depList, setDepList] = useState([])
  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const defaultCheckedList = departmentValue
  const [specialisationName, setSpecializationName] = useState('')
  const [departmentName, setDepartmentName] = useState('')
  const [depName, setDepName] = useState('')
  const [rangeValue, setRangeValue] = useState(0)
  const [special, setSpecial] = useState('')

  const [specialisationsName, setSpecialisationsName] = useState([])
  const [degree, setDegree] = useState('')

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  //for elastic search api

  const [degreeSearchData, setDegreeSearchData] = useState([])
  const [specialisationSearchData, setSpecialisationSearchData] = useState([])
  const [departmentSearchData, setDepartmentSearchData] = useState([])
  const [domainValues, setDomainValues] = useState([])
  const [domainCruds, setDomainCruds] = useState([])

  const sliderValue = value => {
    setRangeValue(value)
    createEditForm.setFieldsValue({ duration: value })
  }

  useEffect(() => {
    if (isediting) {
      setIsElementVisible(false)
      gettingData()
    }
  }, [isediting])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dropDownValues({ url: 'crud/domains?status=1' })
        setDomainCruds(response.data.result)
      } catch (error) {
        console.error('Error fetching domain values:', error)
      }
    }
    fetchData()
  }, [])
  //SystemConfig Editng Drawer

  useEffect(() => {
    if (data && systemConfig) {
      gettingDegreeData(data?.degree?.name, data?.degree?.type)
      gettingDepartmentData(data?.stream?.name)
      gettingSpecialisationData(data?.specialisation?.[0]?.name)
      createEditForm.setFieldsValue({ type: data?.degree?.type })
      createEditForm.setFieldsValue({ duration: data?.duration })
      createEditForm.setFieldsValue({ examtype: data?.examType })
      createEditForm.setFieldsValue({
        specialisation: data?.specialisation?.map(item => item?.id),
      })
      setSpecial(data?.specialisation?.map(item => item?.id))
      createEditForm.setFieldsValue({ degreeName: data?.degree?.name })
      createEditForm.setFieldsValue({ domainName: data?.degree?.domain?.name })
      createEditForm.setFieldsValue({
        department: data?.stream?.name,
      })
      onDepartmentChangeInSysConfig(data?.stream?.name)
      gettingSpecialisationData('')
    } else {
      createEditForm.setFieldsValue({
        domainName: data?.degreeStreamMap?.degree?.domain?.name,
      })
    }
  }, [data])

  const onDegreeChange = (data, option) => {
    setDepartmentList([])
    setCheckedList([])
    let firstDepartment = option?.option?.degreeStreamMap?.[0]

    createEditForm.setFieldsValue({ suffix: option?.option?.shortForm })
    createEditForm.setFieldsValue({ examtype: firstDepartment?.examType })
    createEditForm.setFieldsValue({ duration: firstDepartment?.duration })
    createEditForm.setFieldsValue({ departmentName: '' })
    createEditForm.setFieldsValue({ degreeName: data })
    let updatedList = option?.option?.degreeStreamMap?.map(
      ({ id, duration, examType, stream }) => {
        return { ...stream, duration, examType, id }
      }
    )
    setDepartmentList(updatedList)
    setSpecialisationCount(0)
    setSpecializationValue([])
    setDepartmentValue([])
    createEditForm.setFieldsValue({ degreeName: data })
    setFormErrors('')

    setIsElementVisible(true)
    setAction(false)
  }
  const onDegreeChangeInSysConfig = (data, option) => {
    createEditForm.setFieldsValue({ degreeName: data })
    createEditForm.setFieldsValue({ suffix: option?.option?.shortForm })
    setDegree(data)
    gettingSpecialisationData()
  }

  const onDepartmentChangeInSysConfig = data => {
    createEditForm.setFieldsValue({ department: data })
    gettingSpecialisationData()
  }
  useEffect(() => {
    if (checkListCount > 0) {
      formRef.current.setFields([
        {
          name: 'Department',
          errors: [''],
        },
      ])

      createEditForm.setFieldsValue({
        skills: [],
      })
    }
  }, [checkListCount])

  useEffect(() => {
    if (isediting) {
      gettingDepartmentData()
      gettingSpecialisationData()
      gettingDegreeData('', typeCourse)
    }
    gettingDegreeData('', typeCourse)
  }, [typeCourse])
  useEffect(() => {}, [
    instituteTableList,
    specialisationTableList,
    departmentTableList,
  ])

  useEffect(() => {
    let list = degreeList?.result?.filter(data => data?.id === degreeId)
    let updatedList =
      list &&
      list[0]?.degreeStreamMap?.map(({ id, duration, examType, stream }) => {
        return { ...stream, duration, examType, id }
      })
    setDepartmentList(updatedList || [])
  }, [degreeList, degreeId])
  useEffect(() => {
    if (departmentName) {
      createEditForm.setFieldsValue({
        departmentName: departmentName,
      })
    } else if (!isediting) createEditForm.setFieldsValue({ departmentName: '' })
  }, [departmentName, checkListCount, departmentList])
  useEffect(() => {
    if (specialisationCount) {
      createEditForm.setFieldsValue({
        specialisation: specialisationName + '(' + specialisationCount + ')',
      })
    } else if (!isediting) {
      createEditForm.setFieldsValue({ departmentName: '' })
    }
  }, [departmentName, specialisationCount, departmentList])
  useEffect(() => {
    if (specialisationCount) {
      createEditForm.setFieldsValue({
        specialisation: specialisationName + '(' + specialisationCount + ')',
      })
    }
  }, [departmentName, specialisationCount, departmentList])
  useEffect(() => {
    let deparmentName = createEditForm.getFieldValue(['departmentName'])
    let list = departmentList?.filter(data => data?.name === deparmentName)
    setSpecialisationList(list?.[0]?.specialisation || [])
  }, [departmentList])

  const gettingData = async (search, domainId) => {
    let payload = {
      ...searchConfig?.universities,
      q: data || '',
    }
    dispatch(getMasterSearchApi('universities', payload))
    let data = await dispatch(
      getDegreeData(search, createEditForm.getFieldValue(['type']), domainId)
    )
    if (!domainId) {
      let domainIds = await data?.result?.map(
        data => data?.domain && data?.domain?.id
      )

      if (domainIds && domainCruds?.length) {
        let domains = domainCruds.filter(
          domain => domain.id && domainIds.includes(domain.id)
        )
        setDomainValues(domains)
      }
    }
    setDegreeList(data)
  }
  const gettingSpecialisationData = async search => {
    let payload = {
      ...searchConfig?.specialisations,
      q: search || '',
    }
    const data = await dispatch(getMasterSearchApi('specialisations', payload))
    const upperCaseData = data.map(item => ({
      ...item,
      name: item.name?.toUpperCase(),
    }))
    setSpecialisationSearchData(upperCaseData)
  }

  const gettingDegreeData = async (search, value) => {
    let domainNameValue = createEditForm.getFieldValue(['domainName']);
    let filterType = [
        {
            field: 'type.keyword',
            value: value || 'UG',
        },
        ...(domainNameValue ? [{
            type: "terms",
            field: "domainId.keyword",
            value: [domainNameValue]
        }] : [])
    ];
    let payload = {
      ...searchConfig?.degrees,
      q: search || '',
    }
    payload.filters = searchConfig?.degrees?.filters
      ? [...searchConfig.degrees.filters, ...filterType]
      : filterType
    const data = await dispatch(getMasterSearchApi('degrees', payload))
    setDegreeSearchData(data)
  }
  const gettingDepartmentData = async search => {
    let payload = {
      ...searchConfig?.streams,
      q: search || '',
    }
    const data = await dispatch(getMasterSearchApi('streams', payload))
    setDepartmentSearchData(data)
  }

  const onDomainChange = (data, option) => {
    createEditForm.setFieldsValue({ domainName: data })
    createEditForm.setFieldsValue({ suffix: option?.option?.shortForm })
    let copyofDomainValues = [...domainValues]
    let domain = copyofDomainValues?.find(value => value.id == data)
    // dispatch(
    //   getDegreeData(
    //     '',
    //     createEditForm.getFieldValue(['type']),
    //     domain ? domain?.id : ''
    //   )
    // )
    gettingData('', domain ? domain?.id : '')
    gettingDegreeData('',createEditForm.getFieldValue(['type']))
    onDegreeChange(undefined, undefined)
    // gettingDegreeData()
  }
  const onChageType = e => {
    setCheckedList([])
    if (systemConfig) {
      createEditForm.setFieldsValue({
        type: e.target.value,
      })
      createEditForm.setFieldsValue({ degreeName: '' })
      // let status = 0
      // gettingDegreeData('', e.target.value)
      gettingData()
      //if we want to clear the department and specialisation
      // createEditForm.setFieldsValue({ department: '' })
      // setSpecial('')
      setTypeCourse(e.target.value)
    } else {
      gettingData()
      setDomainValues([])
      createEditForm.setFieldsValue({ domainName: '' })
      createEditForm.setFieldsValue({ departmentName: '' })
      createEditForm.setFieldsValue({ degreeName: '' })
      createEditForm.setFieldsValue({
        specialisation: '',
      })
      createEditForm.setFieldsValue({
        type: e.target.value,
      })
      setCheckListCount(0)
      setSpecialisationCount(0)
      setSpecializationValue([])
      setSpecializationName([])
      setSpecialisationCount(0)
      setIsElementVisible(false)
    }
  }
  const isSuccess = data => {
    if (data) {
      createEditForm.resetFields()
      setAddCourse(false)
      submitClose()
    }
  }
  useEffect(() => {
    if (depList?.length > 0) {
      let Filter = depList?.filter(item => checkedList?.includes(item.id))
      let checkSpecialisation = Filter?.map(item => {
        if (item && item.specialisation !== undefined) {
          return item.specialisation.filter(subItem => subItem.checked === true)
        } else {
          return []
        }
      })
      let specialisationList = checkSpecialisation?.flat()
      let countSpecilasiationList = specialisationList?.length
      createEditForm.setFieldsValue({
        specialisation: `Specialization (${countSpecilasiationList})`,
      })
    }
    formRef?.current?.setFields([
      {
        name: 'Department',
        errors: [''],
      },
    ])
  }, [depList])

  const submitHandler = async values => {
    let skillValue = ''
    if (Array.isArray(values.skills)) {
      skillValue = values?.skills?.filter(item => item.trim() !== '')
    }

    setAction(true)
    if (systemConfig) {
      let datas = {}
      let specialisationData = []
      if (values?.specialisation?.length) {
        values?.specialisation?.map(data => {
          specialisationData?.push({ specialisationMasterId: data })
        })
      }

      if (values.specialisation) {
        datas = {
          degreeStreamMap: {
            degreeId: values.degreeName,
            streamId: values.department,
            duration: values.duration,
            examType: values.examtype,
            domainId: values.domainName,
          },
          specialisation: specialisationData,
          // specialisation: {
          //   specialisationMasterId: values.specialisation,
          // },
        }
      } else {
        datas = {
          degreeStreamMap: {
            degreeId: values.degreeName,
            streamId: values.department,
            duration: values.duration,
            examType: values.examtype,
            domainId: values.domainName,
          },
        }
      }

      if (data?.id) {
        await EditCoursesInSysConfig(data?.id, datas)
      } else {
        await AddCoursesInSysConfig(datas, collegeID)
      }
      createEditForm.resetFields()
      setAddCourse(false)

      submitClose()
    } else {
      let Filter = depList?.filter(item => checkedList?.includes(item.id))
      const filteredA = Filter?.map(obj => {
        return {
          degreeStreamMapId: obj?.id,
          specialisation: obj?.specialisation?.filter(
            specObj => specObj?.checked === true
          ),
        }
      })
      const departmentAndSpecialisation = filteredA?.map(item => {
        return {
          degreeStreamMapId: item.degreeStreamMapId,
          ancillaryMainSkills: skillValue,
          specialisations: item?.specialisation?.map(data => {
            return {
              specialisationId: data?.id,
            }
          }),
        }
      })
      let data = [
        {
          degreeStreamMapId: degreeStreamMapId,
          specialisations: specialisationsName,
          // specialisations:item.specialisation.map(data => {
          //   return {
          //     specialisationId: data.id,
          //   }
          // }) ,
          ancillaryMainSkills: skillValue,
        },
      ]
      if (!specialisationId) {
        delete data?.specialisations
      }

      if (isediting) {
        await EditCourses(courseId, data[0], collegeID, isSuccess)
        // setEditCourse(true)
      }
      if (departmentAndSpecialisation?.length > 0) {
        if (isediting) {
          if (departmentAndSpecialisation[0])
            await EditCourses(
              courseId,
              departmentAndSpecialisation[0],
              collegeID
            )
        } else if (departmentAndSpecialisation?.length > 0) {
          // await AddCourses(data)
          await AddMultiCourses(departmentAndSpecialisation, collegeID)
        }

        createEditForm.resetFields()
        setAddCourse(false)
        submitClose()
      } else {
        formRef?.current?.setFields([
          {
            name: 'Department',
            errors: ['Please Select Department!'],
          },
        ])
      }
    }

    //
    const errors = {}
    if (!values.degreeName) {
      errors.degreeName = 'Please Select Degree!'
    }
    // Add more validation rules for other fields if needed
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      setFormSubmitted(true)
    }
  }

  const [action, setAction] = useState(false)

  const hideElement = data => {
    setIsElementVisible(false)
    setAction(true)
    setDepartmentValue(data)
    setSpecializationValue(data)
    setDepartmentName(data)
  }

  const onSpecialisationChange = (data, option) => {
    createEditForm.setFieldsValue({ specialisation: data })
    setSpecial(data)
    let id = option.map(item => {
      let specialisationId = {
        specialisationId: item.id,
      }
      return specialisationId
    })
    setSpecialisationsName(id)
  }

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetch = useCallback(
    debounce((data, value) => {
      searchDegree(data, value)
    }, 500),
    []
  )
  const delayToFetchDepartment = useCallback(
    debounce(data => {
      searchDepartment(data)
    }, 500),
    []
  )
  const delayToFetchSpecialsiation = useCallback(
    debounce(data => {
      searchSpecialsiation(data)
    }, 500),
    []
  )
  const searchDegree = (data, value) => {
    gettingDegreeData(data, value)
  }

  const searchDepartment = data => {
    gettingDepartmentData(data)
  }
  const searchSpecialsiation = data => {
    gettingSpecialisationData(data)
  }
  const delayToFetchWhileEditing = useCallback(
    debounce(data => {
      gettingData(data)
    }, 500)
  )
  const onDegreeChangeInEdititng = (data, option) => {
    setDepartmentList([])

    let firstDepartment = option?.option?.degreeStreamMap?.[0]

    createEditForm.setFieldsValue({ examtype: firstDepartment?.examType })
    createEditForm.setFieldsValue({ duration: firstDepartment?.duration })
    createEditForm.setFieldsValue({ suffix: option?.option?.shortForm })
    createEditForm.setFieldsValue({ departmentName: '' })

    createEditForm.setFieldsValue({ degreeName: data })
    let updatedList = option?.option?.degreeStreamMap?.map(
      ({ id, duration, examType, stream }) => {
        return { ...stream, duration, examType, id }
      }
    )
    setSpecialisationList([])
    createEditForm.setFieldsValue({ specialisation: '' })
    setSpecialisationCount(0)
    setSpecialisationsName([])
    setDepartmentList(updatedList)
    setSpecialisationCount(0)
    setSpecializationValue([])
    setDepartmentValue([])
  }

  const departmentChangeWhileEditing = (data, option) => {
    setSpecialisationList([])
    createEditForm.setFieldsValue({ specialisation: '' })
    setSpecialisationCount(0)
    setSpecialisationsName([])
    createEditForm.setFieldsValue({ examtype: option?.option?.examType })
    createEditForm.setFieldsValue({ duration: option?.option?.duration })

    createEditForm.setFieldsValue({ departmentName: data })
    setDegreeStreamMapId(option?.option?.id)
    let specialisation = option?.option?.specialisation
    setSpecialisationList(specialisation)
  }
  const onSpecialisationChangeWhileEditing = (data, option) => {
    createEditForm.setFieldsValue({ specialisation: data })
    let id = option.map(item => {
      let specialisationId = {
        specialisationId: item.id,
      }
      return specialisationId
    })
    setSpecialisationsName(id)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await dispatch(
          getDegreeData(degreeSearch, createEditForm.getFieldValue(['type']))
        )
        setDegreeList(data)
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching degree data:', error)
      }
    }

    fetchData()
  }, [degreeSearch])

  const content = () => {
    return (
      <div style={{ pointerEvents: view ? 'none' : '' }}>
        <Form
          scrollToFirstError
          onFinish={submitHandler}
          id={formId}
          form={createEditForm}
          ref={formRef}
          layout="vertical"
        >
          <div>
            <Label required={true}>
              Type <span>*</span>
            </Label>

            <FormItem
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please Choose any type!',
                },
              ]}
            >
              <RadioGroup
                onChange={onChageType}
                disabled={
                  (Object.keys(data).length > 0 && systemConfig) || isediting
                }
              >
                <StyledRadio
                  boxed={true}
                  value={'UG'}
                  label="Under Graduate(UG)"
                />
                <StyledRadio
                  boxed={true}
                  value={'PG'}
                  label="Post Graduate(PG)"
                />
                <StyledRadio
                  boxed={true}
                  value={'PHD'}
                  label="Doctorate(Phd)"
                />
                <StyledRadio boxed={true} value={'DIPLOMA'} label=" Diploma" />
                <StyledRadio
                  boxed={true}
                  value={'P_G_DIPLOMA'}
                  label="PG Diploma"
                />
              </RadioGroup>
            </FormItem>
          </div>
          {/* {systemConfig &&( */}
          <AntdSelect
            label="domain"
            placeholder="Arts"
            OptionData={domainValues}
            name={'domainName'}
            isTooltip={true}
            valueParam="id"
            nameParam="name"
            onChange={onDomainChange}
            disabled={
              (!domainValues?.length &&
                systemConfig &&
                (!typeCourse || Object.keys(data)?.length > 0)) ||
              isediting
            }
            value={createEditForm.getFieldValue(['domainName'])}
            showSearch={true}
            allowClear={true}
            listHeight={160}
          />

          {/* )} */}

          {systemConfig ? (
            <div>
              <TwoFields>
                <AntdSelect
                  label="Degree *"
                  placeholder="Eg.Master of Science.."
                  isTooltip={true}
                  name={'degreeName'}
                  OptionData={degreeSearchData}
                  filterOption={false}
                  valueParam="id"
                  nameParam="name"
                  onChange={onDegreeChangeInSysConfig}
                  value={createEditForm.getFieldValue(['degreeName'])}
                  showSearch={true}
                  onSearch={data => delayToFetch(data, typeCourse)}
                  disabled={!typeCourse || Object.keys(data)?.length > 0}
                />
              </TwoFields>
              <br></br>
              <AntdSelect
                label={'Department *'}
                placeholder="Eg.Maths"
                name={'department'}
                rules={[
                  {
                    required: true,
                    message: 'Please select department!',
                  },
                ]}
                OptionData={departmentSearchData}
                filterOption={false}
                valueParam="id"
                nameParam="name"
                onChange={onDepartmentChangeInSysConfig}
                value={createEditForm.getFieldValue(['department'])}
                showSearch={true}
                onSearch={data => delayToFetchDepartment(data)}
                disabled={!degree || Object.keys(data)?.length > 0}
              />
              <br></br>
              <div>
                <AntdSelect
                  label={'specialisation'}
                  placeholder="Eg.Computer"
                  name={'specialisation'}
                  OptionData={specialisationSearchData}
                  filterOption={false}
                  valueParam="id"
                  nameParam="name"
                  onChange={onSpecialisationChange}
                  value={special}
                  showSearch={true}
                  allowClear={true}
                  onSearch={data => delayToFetchSpecialsiation(data)}
                  disabled={!createEditForm.getFieldValue(['type'])}
                  mode="multiple"
                />
              </div>
            </div>
          ) : !systemConfig && !isediting ? (
            <div>
              <Label required={true}>
                Degree (or) Diploma / Department / Specialization <span>*</span>
              </Label>
              <div
                style={{
                  border: '1px solid #e8e8e8',
                  borderRadius: '10px',
                  width: '100%',
                  height: '50px',
                  display: 'flex',
                  margin: '3px',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <AntdSelect
                  allowClear={true}
                  placeholder="Degree"
                  isTooltip={true}
                  name={'degreeName'}
                  style={{ width: '30%' }}
                  OptionData={
                    degreeList?.result?.filter(
                      option => option?.status.toString() === '1'
                    ) || []
                  }
                  nameParam="name"
                  valueParam="name"
                  onChange={onDegreeChange}
                  value={createEditForm.getFieldValue(['degreeName'])}
                  isShortName={true}
                  showSearch={true}
                  listHeight={160}
                ></AntdSelect>
                <Button
                  style={{
                    width: '23px',
                    height: ' 23px',
                    border: 'none',
                    background: '#EBECEE',
                    borderRadius: '7px',
                  }}
                  icon={
                    <DoubleArrowIcon
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    />
                  }
                />
                <Input
                  name="Department"
                  placeholder={'Department(' + checkListCount + ')'}
                  style={{
                    background: ' #EBECEE',
                    borderRadius: '7px',
                  }}
                  width="30%"
                  // // onClick={inputClick}
                  // // OptionData={departmentList?.filter(
                  // //   option => option?.status.toString() === '1'
                  // // )}
                  // // valueParam="name"
                  // // nameParam="name"
                  // // onChange={onDepartmentChange}
                  disabled={!createEditForm.getFieldValue(['degreeName'])}
                  // // value={
                  // //   checkListCount && depName
                  // //     ? 'Department' + ' ' + '( ' + checkListCount + ' )'
                  // //     : ''
                  // // }
                  showSearch={true}
                  readOnly={true}
                />{' '}
                <Button
                  style={{
                    width: '23px',
                    height: ' 23px',
                    border: 'none',
                    background: '#EBECEE',
                    borderRadius: '7px',
                  }}
                  icon={
                    <DoubleArrowIcon
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    />
                  }
                />
                <Input
                  placeholder={`Specialization (${specialisationList?.length})`}
                  style={{ background: ' #EBECEE', borderRadius: '7px' }}
                  width="27%"
                  name={'specialisation'}
                  showSearch={true}
                  disabled={!createEditForm.getFieldValue(['degreeName'])}
                  readOnly={true} // Set the readOnly prop to true
                />
              </div>
            </div>
          ) : (
            ''
          )}

          {isediting && (
            <div>
              <TwoFields spaceBetween>
                <AntdSelect
                  allowClear={true}
                  label="Degree *"
                  placeholder="Eg. Bachelor Of Engineering"
                  name={'degreeName'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter degree!',
                    },
                  ]}
                  OptionData={
                    degreeList?.result?.filter(
                      option => option?.status.toString() === '1'
                    ) || []
                  }
                  valueParam="name"
                  nameParam="name"
                  onChange={onDegreeChangeInEdititng}
                  value={createEditForm.getFieldValue(['degreeName'])}
                  showSearch={true}
                  onSearch={data => delayToFetchWhileEditing(data)}
                  disabled={
                    !createEditForm.getFieldValue(['type']) ||
                    Object.keys(data)?.length > 0
                  }
                  filterOption={false}
                  isShortName={true}
                />
              </TwoFields>
              <br></br>
              <AntdSelect
                label={'Department *'}
                placeholder="Department"
                name={'departmentName'}
                rules={[
                  {
                    required: true,
                    message: 'Please select department!',
                  },
                ]}
                OptionData={departmentList?.filter(
                  option => option?.status.toString() === '1'
                )}
                filterOption={false}
                valueParam="name"
                nameParam="name"
                onChange={departmentChangeWhileEditing}
                value={createEditForm.getFieldValue(['departmentName'])}
                showSearch={true}
                disabled={
                  !createEditForm.getFieldValue(['degreeName']) ||
                  Object.keys(data)?.length > 0
                }
              />
              <br></br>
              <div>
                <AntdSelect
                  label={'Specialisation'}
                  placeholder="specialisation"
                  name={'specialisation'}
                  OptionData={specialisationList}
                  valueParam="name"
                  nameParam="name"
                  onChange={onSpecialisationChangeWhileEditing}
                  value={createEditForm.getFieldValue(['specialisation'])}
                  showSearch={true}
                  filterOption={false}
                  mode="multiple"
                />
              </div>
            </div>
          )}
          <div>
            {formSubmitted && formErrors.degreeName && (
              <DepartError>{formErrors.degreeName}</DepartError>
            )}
          </div>
          {isElementVisible && !systemConfig && (
            <div>
              <DepartmentAndSpecialization
                hideElement={hideElement}
                checkedList={checkedList}
                setCheckListCount={setCheckListCount}
                setSpecialisationCount={setSpecialisationCount}
                departmentList={departmentList}
                action={action}
                setAction={setAction}
                departmentValue={departmentValue}
                setDepartmentValue={setDepartmentValue}
                setSpecializationValue={setSpecializationValue}
                specializationValue={specializationValue}
                setDepartmentName={setDepartmentName}
                deparmentName={departmentName}
                setSpecializationName={setSpecializationName}
                specializationName={specialisationName}
                carryParam={carryParam}
                isediting={isediting}
                depList={depList}
                setDepList={setDepList}
                defaultCheckedList={defaultCheckedList}
                setCheckedList={setCheckedList}
                setDepName={setDepName}
                depName={depName}
                setSpecialisationList={setSpecialisationList}
                createEditForm={createEditForm}
              />
            </div>
          )}

          <div>
            <Label required={true}>
              Course Duration <span>*</span>
            </Label>
            <FormItem
              name="duration"
              rules={
                systemConfig && [
                  {
                    required: true,
                    message: 'Please select duration!',
                  },
                ]
              }
            >
              <RangeSliderInput
                name={'duration'}
                label={' Year'}
                min={0}
                max={6}
                setInputValue={sliderValue}
                inputValue={
                  systemConfig
                    ? rangeValue
                    : createEditForm.getFieldValue(['duration'])
                }
                rules={
                  systemConfig && [
                    {
                      required: true,
                      message: 'Please select duration!',
                    },
                  ]
                }
                disabled={!systemConfig}
              />
            </FormItem>
          </div>

          <div>
            <Label required={true}>
              Exam Type <span>*</span>
            </Label>

            <FormItem
              name="examtype"
              rules={
                systemConfig && [
                  {
                    required: true,
                    message: 'Please select exam type!',
                  },
                ]
              }
            >
              <RadioGroup disabled={!systemConfig} required={systemConfig}>
                <StyledRadio boxed={true} value={'SEMESTER'} label="Semester" />
                <StyledRadio
                  boxed={true}
                  value={'TRISEMESTER'}
                  label="Trimester"
                />
                <StyledRadio boxed={true} value={'ANNUAL'} label="Annual" />
              </RadioGroup>
            </FormItem>
          </div>
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary
          text="Cancel"
          onClick={() => {
            setAddCourse(false)
            createEditForm.resetFields()
          }}
        />

        <Button.Primary
          // disabled={( !systemConfig)}
          htmlType="submit"
          form={formId}
          text={sendButtonText}
          icon={<CheckIcon />}
        />
      </FooterFlex>
    )
  }
  return (
    <StyledDrawer
      width={'60%'}
      title={title}
      placement="right"
      closable={true}
      onClose={() => {
        setAddCourse(false)
        createEditForm.resetFields()
      }}
      visible={addCourse}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddCourseDrawer
