import { Empty, Spin } from 'antd'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { EmptyWrapper } from 'modules/Dashboard/Styles/style'
import {
  DateFormat,
  RefinedData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setStudentListForReport } from '../../../actions'
const StudentListTable = ({
  userRole,
  loading,
  setPageNum,
  pageNum,
  tableData,
  isEmpanelled,
  setSortBy,
  hasProp,
}) => {
  const dispatch = useDispatch()
  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }

  const onPageChange = num => {
    setPageNum(num)
  }
  useEffect(() => {
    dispatch(setStudentListForReport([]))
  }, [])
  const columns = [
    {
      title: 'ID',
      dataIndex: 'a.prefix_id',
      key: 'a.prefix_id',
      visible: true,
      sorter: true,
      align: 'center',
      width: '5%',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'STUDENT NAME',
      dataIndex: 'a.first_name',
      key: 'a.first_name',
      visible: true,
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(
            tableData?.first_name +
              ' ' +
              (tableData?.last_name ? tableData?.last_name : '')
          )}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'Degree',
      dataIndex: 'b.degree',
      key: 'b.degree',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {(tableData?.short_form
            ? RefinedData(tableData?.short_form) + ' - '
            : '') + tableData?.degree
            ? RefinedData(tableData?.degree)
            : '-'}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'Department',
      dataIndex: 'course',
      key: 'course',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {' '}
          {tableData?.course ? tableData?.course : '-'}{' '}
        </RowNumberDataReport>
      ),
      visible: false,
    },
    {
      title: 'SPECIALISATION',
      dataIndex: 'b.specialisation',
      key: 'b.specialisation',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.specialisation)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'COLLEGE NAME',
      dataIndex: 'a.institute_campus_name',
      key: 'a.institute_campus_name',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.institute_campus_name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'UNIVERSITY',
      dataIndex: 'c.university',
      key: 'c.university',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.university)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'EMPAELLD',
      dataIndex: 'a.created_at',
      key: 'a.created_at',
      sorter: true,
      visible: true,
      align: 'center',
      render: (_, tableData) => {
        if (tableData?.created_at) {
          const date = new Date(tableData.created_at);
          const day = String(date.getDate()).padStart(2, '0'); // Ensures 2 digits
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
          const year = date.getFullYear();
          return (
            <RowNumberDataReportAlignCenter>
              {`${day}-${month}-${year}`}
            </RowNumberDataReportAlignCenter>
          );
        }
        return '';
      }
    },
    {
      title: 'LOCATION',
      dataIndex: 'e.corr_city',
      key: 'e.corr_city',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.corr_city)}
        </RowNumberDataReport>
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        loading={loading}
        locale={{
          emptyText: (
            <EmptyWrapper center isSpin={loading}>
              {!loading ? (
                <Empty
                  description={
                    !hasProp
                      ? 'Kindly Select Filter Option to View the Data'
                      : 'No Students Found'
                  }
                />
              ) : (
                <Spin />
              )}
            </EmptyWrapper>
          ),
        }}
        scroll={{
          x: 2000,
          y: 500,
        }}
      />

      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={pageNum}
        currentCount={tableData?.result?.length}
      />
    </Wrapper>
  )
}

export default StudentListTable
