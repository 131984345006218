import { Checkbox, Col, Form } from 'antd'
import Button from 'components/Button/index'
import Control from 'components/Control/Control'
import Drawer from 'components/Drawer'
import Label from 'components/Label/Label'
import RichEditor from 'components/RichEditor/index'
import { PaddingDetails } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style'
import {
  BoldHeading,
  Heading,
} from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style.js'
import { FlexWrap } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/ViewCorporateDrawer/Style/style'
import { CorporateRequestforChange } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/actions'
import {
  FormItem,
  FormWrapper,
} from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CONSTANTS from 'utils/constants'

const ChangeCorporateDetails = ({
  changeCorporateDetails,
  setChangeCorporateDetails,
  setRequestSentDrawer,
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const singleCorporateData = useSelector(
    state => state?.CorporateOnboarding?.singleCorporateData
  )
  const initialFormValue = {
    corporateRequestToChange: [],
    reason: '',
  }
  const [formValue, setFormValue] = useState(initialFormValue)
  useEffect(() => {
    singleCorporateData?.addReason &&
      onChangeReason(singleCorporateData?.addReason)
  }, [singleCorporateData])
  const addReason = Form.useWatch('addReason', form)

  const onChangeReason = reason => {
    const result = reason
    if (!result) {
      form.setFieldsValue({ addReason: '' })
    } else {
      form.setFieldsValue({ addReason: reason })
    }
    setFormValue({ ...formValue, reason: reason })
  }

  const onChange = checkedValues => {
    if (checkedValues.length) {
      form.setFieldsValue({ needToChange: checkedValues })
    } else {
      form.setFieldsValue({ needToChange: '' })
    }
    setFormValue({ ...formValue, corporateRequestToChange: checkedValues })
  }
  const onSubmit = () => {
    const reqData = {
      reason: addReason?.replace(/<[^>]*>?/gm, ''),
      needToChange: formValue.corporateRequestToChange,
      journeyType: 'CORPORATE',
    }
    let payLoad = { ...reqData }
    const callBack = () => {
      setChangeCorporateDetails(false)
      setRequestSentDrawer(true)
      form.resetFields()
      setFormValue(initialFormValue)
    }
    dispatch(
      CorporateRequestforChange(singleCorporateData?.id, payLoad, callBack)
    )
  }

  const content = () => {
    return (
      <Fragment>
        <Heading>
          Request <BoldHeading>{singleCorporateData?.name}</BoldHeading> to do
          the changes in onboarding form{' '}
        </Heading>

        <PaddingDetails />

        <FormItem
          name={'needToChange'}
          rules={[
            {
              required: true,
              message: 'Please select a change fields!',
            },
          ]}
          id={'corporateRequestToChange'}
        >
          <Checkbox.Group
            style={{ width: '100%' }}
            value={formValue.corporateRequestToChange}
            onChange={onChange}
            name="needToChange"
          >
            <Col>
              <Col>
                <Checkbox value="Authorized Signatory">
                  {'Authorized Signatory'}
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="Contract">{'Contract'}</Checkbox>
              </Col>
              <Col>
                <Checkbox value="Corporate Details">
                  {'Corporate Details'}
                </Checkbox>
              </Col>
            </Col>
          </Checkbox.Group>
        </FormItem>
        <PaddingDetails />
        <Control top="20px" flexStart column width="500px">
          <Label>Add Reason</Label>
          <FormItem
            name={'addReason'}
            rules={[
              {
                required: true,
                message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('reason'),
              },
            ]}
          >
            <RichEditor
              value={formValue.reason}
              onChange={onChangeReason}
              placeholder={'Enter Your Reason here'}
            />
          </FormItem>
        </Control>
      </Fragment>
    )
  }

  const onClose = () => {
    setChangeCorporateDetails(false)
  }

  const back = () => {
    setChangeCorporateDetails(false)
    setFormValue(initialFormValue)
    form.setFieldsValue({ needToChange: [], addReason: '' })
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Back" onClick={back} height="38px" />
        <Button.Primary
          type="submit"
          htmlType="submit"
          form={'corporateRequestToChange'}
          text="Confirm"
          height="38px"
        />
      </FlexWrap>
    )
  }

  return (
    <FormWrapper
      scrollToFirstError
      onFinish={onSubmit}
      id={'corporateRequestToChange'}
      form={form}
      layout="vertical"
      initialValues={{ needToChange: [] }}
    >
      <Drawer
        width="40%"
        title={'Request to Change'}
        placement="right"
        padding="30px"
        headermargin="0 30px"
        headerpadding="16px 0"
        closable
        onClose={onClose}
        visible={changeCorporateDetails}
        content={content()}
        footer={footer()}
      />
    </FormWrapper>
  )
}

export default ChangeCorporateDetails
