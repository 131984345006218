import styled from 'styled-components'
import { Tree } from 'antd'
import { COLORS } from 'theme'

export const TreeDiv = styled.div`
  .ant-tree .ant-tree-treenode {
    padding: 0 0 15px 0;
  }
`
export const AntdTree = styled(Tree)`
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.GREY_T_20};
  .ant-tree-checkbox-inner {
    border: 1px solid black; 
   
  }
 
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    border-color: #3249D7;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
     border-color: #3249D7;
}
.ant-tree-switcher .ant-tree-switcher-icon, .ant-tree-switcher .ant-select-tree-switcher-icon {
    font-size: 15px;

}
 .ant-tree-treenode {

    gap: 8px;
}

`
