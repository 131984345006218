import authSelector from 'modules/Auth/selectors'
import { getListOfState, getListOfCity } from 'modules/Onboarding/actions'
import {
  getFunctionSysConfig,
  getCorporateMasterData,
} from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'
import GeneralSelectors from 'modules/Systemconfig/Partials/GeneralTableSettings/selectors'
import UsersView from 'modules/User/Partials/UsersView/index'
import selectors from 'modules/User/Partials/UsersView/selectors'
import selector from 'modules/User/selectors'
import { connect } from 'react-redux'
import {
  getRoles,
  getSingleRole,
  getSingleUser,
  updateUser,
  updateUsersRole,
} from '../../../actions'
import {
  createCorporate,
  createInstitute,
  deleteUser,
  getCorporate,
  getCorporatesView,
  getInstitute,
  getInstituteView,
  updateCorporate,
  getCorporateUniversityData
} from '../actions'
const mapStateToProps = state => ({
  corporateViewData: selectors?.getCorporateViewData(state),
  instituteViewData: selectors?.getInstituteViewData(state),
  corporatejourneyData: selectors?.getCorporateJourneyData(state),
  institutejourneyData: selectors?.getInstituteJourneyViewData(state),
  FunctionTableList: GeneralSelectors?.generalFunctionList(state),
  SectorTableList: GeneralSelectors?.generalFunctionList(state),
  roles: selector?.getRoleData(state),
  singleUserData: selector?.getSingleUserData(state),
  userData: authSelector.getUserData(state),
})

const mapDispatchToProps = {
  getCorporatesView,
  getInstituteView,
  getInstitute,
  updateCorporate,
  getCorporate,
  createCorporate,
  createInstitute,
  deleteUser,
  getRoles,
  getListOfState,
  getSingleUser,
  getFunctionSysConfig,
  getCorporateMasterData,
  getListOfCity,
  updateUser,
  getCorporateUniversityData,
  updateUsersRole,
  getSingleRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersView)
