import React from 'react'
import CorporateTable from 'modules/Reports/Partials/Corporates/Partials/CorporateInfoTable/CorporateTable'
import { NavWrapper } from 'modules/Reports/Partials/Corporates/Partials/CorporateInfoTable/Style/style'

const CorporateInfoTable = ({
  tableData,
  loading,
  loadingCorp,
  setLoadingCity,
  loadingCity,
  hasProp,
  setSearchValue,
  searchValue,
  menu,
  open,
  currentCount,
  totalCount,
  onOpenChange,
  sectorList,
  sectorValue,
  setSectorValue,
  setFilterData,
  filterData,
  industryList,
  stateList,
  cityList,
  industryValue,
  cityValue,
  stateValue,
  setIndustryValue,
  setCityValue,
  setStateValue,
  setKeySector,
  keySector,
  keyCity,
  setKeyCity,
  keyState,
  setKeyState,
  keyIndustry,
  setKeyIndustry,
  isEmpanelled,
  createdAtEnd,
  createdAtStart,
  setCreatedAtEnd,
  setCreatedAtStart,
  pageNum,
  setPageNum,
  onChangeHandler,
  form = { form },
  cancel,
  onCanceled,
  setSortBy,
  tooltip,
  setToolTip,
  setClearDate,
  clearDate,
  setPreviousPage,
  previousPage,
  sectorLoading,
  stateLoading,
  handleSearch,
  searchQuery,
  DegreeUrl,
  degreeData,
  degreeLoader,
  setDegreeLoader,
  setSearchQuery,
  degreeListData,
  setSearchDegree,
  degreeList,
  setDegreeList,
  setKeyDegree,
  loadingDep,
  page,
  count,
  loadMoreData,
  loadMoreCityData,
  loadMoreStateData,
  cityCount,
  stateCount,
  statePage,
  cityPage,
  first,
  singleSectorLoading,
  setKeySingleSector,
  singleSectorValue,
  setSingleSectorValue,
  keySingleSector,
  singleSectorData,
  singleSectorDataFinal,
  singleSectorCount,
  loadMoreSingleSectorData,
  singleSectorPage,
  setSingleSectorPage,
  singleIndustryPage,
  setSingleIndustryPage,
  singleIndustryDataFinal,
  singleIndustryCount,
  loadMoreSingleIndustryData,
  singleIndustryData,
  singleIndustryValue,
  setSingleIndustryValue,
  keySingleIndustry,
  singleIndustryLoading,
  setKeySingleIndustry,
  getStateList,
  setStateLoading,
  setMoreLoading,
  getCityList,
  getSectorList,
  setSectorLoading,
  getSingleIndustryList,
  setSingleIndustryLoading,
  getSingleSectorList,
}) => {
  return (
    <NavWrapper column>
      <CorporateTable
        singleIndustryPage={singleIndustryPage}
        setSingleIndustryPage={setSingleIndustryPage}
        singleIndustryDataFinal={singleIndustryDataFinal}
        singleIndustryCount={singleIndustryCount}
        loadMoreSingleIndustryData={loadMoreSingleIndustryData}
        singleIndustryData={singleIndustryData}
        singleIndustryValue={singleIndustryValue}
        setSingleIndustryValue={setSingleIndustryValue}
        keySingleIndustry={keySingleIndustry}
        singleIndustryLoading={singleIndustryLoading}
        setKeySingleIndustry={setKeySingleIndustry}
        ////
        singleSectorPage={singleSectorPage}
        setSingleSectorPage={setSingleSectorPage}
        singleSectorDataFinal={singleSectorDataFinal}
        singleSectorCount={singleSectorCount}
        loadMoreSingleSectorData={loadMoreSingleSectorData}
        singleSectorData={singleSectorData}
        keySingleSector={keySingleSector}
        singleSectorValue={singleSectorValue}
        setSingleSectorValue={setSingleSectorValue}
        singleSectorLoading={singleSectorLoading}
        setKeySingleSector={setKeySingleSector}
        first={first}
        cityPage={cityPage}
        statePage={statePage}
        cityCount={cityCount}
        stateCount={stateCount}
        loadMoreStateData={loadMoreStateData}
        loadMoreCityData={loadMoreCityData}
        setKeyDegree={setKeyDegree}
        setDegreeList={setDegreeList}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        degreeList={degreeList}
        setSearchDegree={setSearchDegree}
        degreeListData={degreeListData}
        setSearchQuery={setSearchQuery}
        setDegreeLoader={setDegreeLoader}
        degreeLoader={degreeLoader}
        degreeData={degreeData}
        DegreeUrl={DegreeUrl}
        stateLoading={stateLoading}
        sectorLoading={sectorLoading}
        setPreviousPage={setPreviousPage}
        previousPage={previousPage}
        loadingCorp={loadingCorp}
        isReport={true}
        tableData={tableData}
        loading={loading}
        isEmpanelled={isEmpanelled}
        hasProp={hasProp}
        setSortBy={setSortBy}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        menu={menu}
        open={open}
        setClearDate={setClearDate}
        clearDate={clearDate}
        tooltip={tooltip}
        setToolTip={setToolTip}
        currentCount={currentCount}
        totalCount={totalCount}
        onOpenChange={onOpenChange}
        sectorList={sectorList}
        sectorValue={sectorValue}
        setSectorValue={setSectorValue}
        setFilterData={setFilterData}
        filterData={filterData}
        industryList={industryList}
        cityList={cityList}
        stateList={stateList}
        industryValue={industryValue}
        setIndustryValue={setIndustryValue}
        cityValue={cityValue}
        stateValue={stateValue}
        setCityValue={setCityValue}
        setStateValue={setStateValue}
        setKeySector={setKeySector}
        keySector={keySector}
        setKeyCity={setKeyCity}
        setKeyIndustry={setKeyIndustry}
        setKeyState={setKeyState}
        keyState={keyState}
        keyIndustry={keyIndustry}
        keyCity={keyCity}
        setLoadingCity={setLoadingCity}
        loadingCity={loadingCity}
        createdAtStart={createdAtStart}
        createdAtEnd={createdAtEnd}
        setCreatedAtStart={setCreatedAtStart}
        setCreatedAtEnd={setCreatedAtEnd}
        setPageNum={setPageNum}
        pageNum={pageNum}
        form={form}
        onChangeHandler={onChangeHandler}
        cancel={cancel}
        onCanceled={onCanceled}
        loadingDep={loadingDep}
        page={page}
        count={count}
        loadMoreData={loadMoreData}
        getStateList={getStateList}
        setStateLoading={setStateLoading}
        setMoreLoading={setMoreLoading}
        getCityList={getCityList}
        getSectorList={getSectorList}
        setSectorLoading={setSectorLoading}
        getSingleIndustryList={getSingleIndustryList}
        setSingleIndustryLoading={setSingleIndustryLoading}
        getSingleSectorList={getSingleSectorList}
      />
    </NavWrapper>
  )
}

export default CorporateInfoTable
