const getUserInstitutesLists = state =>
  state?.UserInstituteList?.userInstitutesList
const getSingleInstituteData = state =>
  state?.UserInstituteList?.singleInstituteData

const selectors = {
  getUserInstitutesLists,
  getSingleInstituteData,
}

export default selectors
