import styled from 'styled-components'
import { COLORS } from 'theme'
import Flex from 'components/Flex'
import { FONT } from 'theme/font'
import { Divider } from 'antd'

export const PaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 25px;
`
export const SectionWrapper = styled(Flex)`
  width: 100%;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  border-radius: 10px;
  // overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }

  // box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.35);
  // border: 1px solid ${COLORS.PAGE_BORDER};
  margin-top: ${({ addMarginTop }) => (addMarginTop ? '50px' : '0')};
`

export const ContentWrapper = styled(Flex)`
  overflow-y: auto;
  border-radius: 10px;
  margin-bottom: 0%;
  // margin-top: 7%;
  // box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.35);
  border: 1px solid ${COLORS.PAGE_BORDER};
  &::-webkit-scrollbar {
    display: '';
  }
`

export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: ${FONT.F_XL};
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const CardRow = styled(Flex)`
  padding: 0 25px 25px 25px;
  gap: 20px;
`
export const EmptyWrapper = styled(Flex)`
  margin: 50px 0px;
  margin: ${props => props?.isSpin && '102px 0px'};
`
export const VerticalDivider = styled(Divider)`
  height: 30px;
  color: #cccfd6;

  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-left: 5px solid #cccfd6;
  }
`
export const Span = styled.span`
  color: #000;
`

export const Count = styled.div`
  padding-top: 8px;
  font-size: 14px;
  color: ${COLORS.GREY_T_35};
`
