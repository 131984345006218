import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import InstitutesDetails from './Partials/InstituteDetails'
import ContactInstituteDetails from './Partials/ContactInstituteDetails'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import Button from 'components/Button'
import { Form as AntdForm } from 'antd'
import { FlexWrap } from 'components/UIComponents/FormStyles'
import Drawer from 'components/Drawer'
import {
  getListOfCounties,
  getListOfState,
  getListOfCity,
} from 'modules/Onboarding/actions'

const AddNewInstituteDrawer = ({
  newInstituteDrawer,
  setNewInstituteDrawer,

  formId = 'institute_form',
  title = 'Add Institutes',
  sendButtonText = 'Add',
  dateFormat,
  isSystemConfig = false,
  universityValue,
  handleSubmitCongif,
  view = false,
  setView,
  addContent,
  onKeyPress,
}) => {
  const [searchCountry, setSearchCountry] = useState('')
  const dispatch = new useDispatch()

  useEffect(() => {
    dispatch(getListOfCounties())
  }, [])

  const countryList = useSelector(state => state?.CorporateData?.countryList)

  const [countryCode, setCountryCode] = useState('+91')
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [countryId, setCountryId] = useState('')
  const [stateId, setStateId] = useState('')

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await dispatch(getListOfState(countryId, search))
      return  setStateList(list || []) 
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    if (countryId && stateId) {
      let list = await dispatch(getListOfCity(countryId, stateId, search))
      return setCityList(list || []) 
    }
  }

  const [createEditForm] = AntdForm.useForm()

  useEffect(() => {
    let getData
    getData = setTimeout(() => {
      dispatch(getListOfCounties(searchCountry))
    }, 500)
    return () => clearTimeout(getData)
  }, [searchCountry])

  const onStateChange = (data, option) => {
    createEditForm.setFieldsValue({
      state: data,
      city: '',
      stateId: option?.option?._id,
      cityId: '',
    })
    setStateName(data)
    setStateId(option?.option?._id)
    setCityName('')
    getStateData(countryId, true)
    getCityData(countryId, option?.option?._id, true)
  }

  const onCityChange = (data, option) => {
    createEditForm.setFieldsValue({
      city: data,
      cityId: option?.option?._id,
    })
    setCityName(data)
    // setPermCityId(option?.option?._id)
    getCityData(countryId, stateId, true)
  }

  const onCountryChange = (data, option) => {
    createEditForm.setFieldsValue({
      country: data,
      state: '',
      city: '',
      countryId: option?.option?._id,
      stateId: '',
      cityId: '',
    })

    setCountryName(data)
    setCountryId(option?.option?._id)
    setStateName('')
    setStateId('')
    setCityName('')
    setSearchCountry('')
    getStateData(option?.option?._id, true)
  }

  const onClear = () => {
    setNewInstituteDrawer(false)
    if (isSystemConfig) {
      createEditForm.resetFields()
      setView(false)
    } else {
      createEditForm.resetFields()
    }
  }

  const onSubmitFormData = async formData => {
    if (isSystemConfig) {
      handleSubmitCongif({ ...formData, stateId: stateId }, 'post')
    }
    setNewInstituteDrawer(false)
  }
  useEffect(() => {
    if (addContent) {
      createEditForm.resetFields()
      getCityData('', '', true)
      createEditForm.setFieldsValue({
        name: '',
        website: '',
        country: '',
        countryId: '',
        state: '',
        stateId: '',
        cityId: '',
        city: '',
      })
      setStateName('')
      setStateId('')
      getStateData('', true)
    } else if (universityValue) {
      createEditForm.setFieldsValue({
        name: universityValue?.name,
        website: universityValue?.website,
        country: universityValue?.country,
        countryId: universityValue?.countryId,
        state: universityValue?.state,
        stateId: universityValue?.stateId,
        cityId: universityValue?.cityId,
        city: universityValue?.city,
      })
      setCountryName(universityValue?.country)
      setCountryId(universityValue?.countryId)
      setStateName(universityValue?.state)
      setStateId(universityValue?.stateId)
      setCityName(universityValue?.city)
      // setPermCityId(universityValue?.cityId)
      getStateData(universityValue?.countryId, true)
      getCityData(universityValue?.countryId, universityValue?.stateId, true)
    }
  }, [universityValue, newInstituteDrawer, addContent])

  const content = () => {
    return (
      <div style={{ pointerEvents: view ? 'none' : '' }}>
        <Form
          scrollToFirstError
          onFinish={onSubmitFormData}
          id={formId}
          form={createEditForm}
          layout="vertical"
        >
          <InstitutesDetails
            cityList={cityList}
            countryList={countryList}
            stateList={stateList}
            dateFormat={dateFormat}
            onCountryChange={onCountryChange}
            countryValue={countryName}
            stateValue={stateName}
            cityValue={cityName}
            countryId={countryId}
            stateId={stateId}
            getStateData={getStateData}
            getCityData={getCityData}
            setSearchCountry={setSearchCountry}
            onStateChange={onStateChange}
            onCityChange={onCityChange}
            isSystemConfig={true}
            createEditForm={createEditForm}
            onKeyPress={onKeyPress}
          />
          {!isSystemConfig ? (
            <ContactInstituteDetails
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              form={createEditForm}
            />
          ) : (
            ''
          )}
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary
          text="Cancel"
          onClick={() => {
            onClear()
          }}
        />
        <Button.Primary
          htmlType="submit"
          form={formId}
          text={sendButtonText}
          disabled={view}
          icon={isSystemConfig ? <CheckIconWhite /> : ''}
        />
      </FlexWrap>
    )
  }
  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClear}
      visible={newInstituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddNewInstituteDrawer
