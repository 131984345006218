import Button from 'components/Button/index'
import Input from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import Select from 'components/Select'
import AddIcon from 'components/icons/AddIcon'
import React, { Fragment, useState } from 'react'
import { journeyDropdown } from '../../../../../../../utils/constants'
import { FormItem } from '../../../../../../User/Partials/AddUserDrawer/Partials/AddUserForm/styles'
import { toCamelCase } from '../../../../../../../utils/camelCase'

const DrawerForm = ({
  form,
  setJourney,
  toEditId,
  setShow,
  show,
  inputs,
  getScreenData,
  screenData,
  setInputs,
  view,
}) => {
  const [screens, setScreens] = useState([])
  const handleAddInput = () => {
    setInputs([...inputs, { name: '', label: 'Events', value: '' }])
  }
  const handleCloseField = () => {
    const updatedFields = inputs.slice(0, -1)
    setInputs(updatedFields)
    let data = { ...form.getFieldsValue(true) }
    const keyToDelete = `events.${inputs.length - 1}`
    delete data[keyToDelete]

    form.setFieldsValue(data)
    form.resetFields([keyToDelete])
  }

  const handleInputChange = (index, event) => {
    const values = [...inputs]
    values[index].value = event?.target?.value
    setInputs(values)
  }

  const handleScreensChange = value => {
    setScreens(value)
  }
  const handleJourneyChange = async value => {
    setJourney(value)
    setScreens('') // Clear the screens
    setShow(true)
    await getScreenData(value) // Make API call with new journey value
  }

  return (
    <Fragment>
      <div>
        <FormItem
          name={'journey'}
          rules={[
            {
              required: true,
              message: 'Please select Any One Journey',
            },
          ]}
        >
          <AntdSelect
            name={'journey'}
            OptionData={journeyDropdown}
            label="Journey *"
            onChange={value => {
              handleJourneyChange(value)
              form.setFieldsValue({ journey: value })
              form.setFieldsValue({ screenId: '' })
              form.setFieldsValue({ eventName: '' })
            }}
            nameParam="value"
            placeholder="Select"
            valueParam="id"
          />
        </FormItem>
      </div>

      <div>
        <FormItem
          name={'screenId'}
          rules={[
            {
              required: true,
              message: 'Please select Any One Screen',
            },
          ]}
        >
          <Select
            OptionData={screenData?.screens?.map(event => ({
              value: event?.id,
              label: toCamelCase(event?.screenName),
            }))}
            onChange={value => {
              handleScreensChange(value)
              form.setFieldsValue({ screenId: value })
              form.setFieldsValue({ eventName: '' })
            }}
            label="Screen *"
            // defaultValue={screens}
            disabled={!show}
            valueParam="value"
            nameParam="label"
            placeholder="Select"
          />
        </FormItem>
      </div>

      <div>
        {toEditId
          ? inputs.map((field, index) => (
              <FormItem key={index} name={'eventName'}>
                <div width="49%">
                  <Input
                    type="text"
                    width="100%"
                    name={'eventName'}
                    label={`Events`}
                    optional={'*'}
                    placeholder={`Enter Event Name`}
                    onChange={event => {
                      handleInputChange(index, event)
                      form.setFieldsValue({ eventName: event })
                    }}
                    rules={[
                      {
                        required: true,
                        message: `Please Enter Event Name`,
                      },
                      {
                        pattern: /^(?!\s).*$/,
                        message: 'Event Name cannot start with a space',
                      },
                    ]}
                  />
                </div>
              </FormItem>
            ))
          : inputs.map((field, index) => (
              <FormItem key={index} name={`events.${index}`}>
                <div width="49%">
                  <Input
                    type="text"
                    width="100%"
                    name={`events.${index}`}
                    label={`Events`}
                    optional={'*'}
                    placeholder={`Enter Event Name`}
                    value={field.value}
                    onChange={event => handleInputChange(index, event)}
                    rules={[
                      {
                        required: true,
                        message: `Please Enter Event Name`,
                      },
                      {
                        pattern: /^(?!\s).*$/,
                        message: 'Event Name cannot start with a space',
                      },
                    ]}
                  />
                </div>
              </FormItem>
            ))}
      </div>
      <div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
        {inputs.length > 1 && !toEditId && (
          <Button.Secondary
            text="Delete"
            height="38px"
            onClick={handleCloseField}
          />
        )}
        {!toEditId && (
          <Button.Secondary
            text={'Add'}
            onClick={handleAddInput}
            disabled={view}
            icon={<AddIcon />}
            height="38px"
          />
        )}
      </div>
    </Fragment>
  )
}

export default DrawerForm
