import { Col, Row } from 'antd'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import AntdInput from 'components/Form/Input'
import ProgressCircle from 'components/ProgressCircle'
import FileReaderViewOnly from 'components/Uploader/FileReaderViewOnly'
import CopyAltIcon from 'components/icons/CopyAltIcon'
import { TwoFields } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import { PaddingDetail } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style'
import {
  DetailHeader,
  HorizontalDivider,
  LeftText,
  RightText,
  SubDetailHeader,
} from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Style/style'
import { FlexWrap } from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/ViewInstituteDrawer/Style/style'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

const { ADMIN_URL } = process.env

import Avatar from 'components/Avatar'
import SuccessMessage from 'utils/SuccessMessage'

const ViewInstituteDrawer = ({
  setVerifyInstituteDrawer,
  viewInstituteDrawer,
  setViewInstituteDrawer,
}) => {
  const singleInstituteData = useSelector(
    state => state?.InstituteOnboarding?.singleInstituteData
  )

  const onCancel = () => {
    setViewInstituteDrawer(false)
  }

  const copyLink = () => {
    let copyText = document.getElementById('InstituteURLforonboarding')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const onSubmit = () => {
    setViewInstituteDrawer(false)
    setVerifyInstituteDrawer(true)
  }

  const info = [
    {
      heading: 'Country',
      value: singleInstituteData?.instituteCampus?.[0]?.country,
    },
    {
      heading: 'State',
      value: singleInstituteData?.instituteCampus?.[0]?.state,
    },
    {
      heading: 'City',
      value: singleInstituteData?.instituteCampus?.[0]?.city,
    },
    {
      heading: 'Website',
      value: singleInstituteData?.instituteCampus?.[0]?.website,
    },
    {
      heading: 'Contact Name',
      value: singleInstituteData?.instituteCampus?.[0]?.contactPerson,
    },
    {
      heading: 'Email id',
      value: singleInstituteData?.instituteCampus?.[0]?.contactEmail,
    },
  ]

  const content = () => {
    return (
      <Fragment>
        <TwoFields spaceBetween>
          <div>
            <DetailHeader>{singleInstituteData?.name}</DetailHeader>
            <SubDetailHeader>{singleInstituteData?.university}</SubDetailHeader>
          </div>

          <ProgressCircle
            progressRate={singleInstituteData?.profileCompletionPerc}
            width="30px"
          />
        </TwoFields>
        {info?.map(item => (
          <Row key={item?.id} style={{ width: '100%' }}>
            <Col span={12}>
              <LeftText>{item.heading}</LeftText>
            </Col>
            <Col span={12}>
              <RightText>{item.value}</RightText>
            </Col>
          </Row>
        ))}

        {singleInstituteData?.regDocs && (
          <>
            <HorizontalDivider />

            <Row style={{ width: '100%' }}>
              <Col span={12}>
                <LeftText>{'Contract'}</LeftText>
              </Col>
              <Col span={12}>
                <RightText>
                  {singleInstituteData?.regDocs && (
                    <FileReaderViewOnly file={singleInstituteData?.regDocs} />
                  )}
                </RightText>
              </Col>
            </Row>
          </>
        )}

        <HorizontalDivider />
        <LeftText>URL for onboarding flow</LeftText>
        <PaddingDetail />

        <AntdInput
          column
          id={'InstituteURLforonboarding'}
          width="100%"
          suffix={<Avatar onClick={copyLink} src={<CopyAltIcon />} />}
          value={`${ADMIN_URL}onboarding/institute/${singleInstituteData?.id}`}
        />
      </Fragment>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onCancel} height="38px" />
        <Button.Primary
          htmlType="submit"
          onClick={onSubmit}
          text="Verify"
          height="38px"
        />
      </FlexWrap>
    )
  }

  const onClear = () => {
    setViewInstituteDrawer(false)
  }

  return (
    <Drawer
      width="50%"
      title={'Details'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClear}
      visible={viewInstituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ViewInstituteDrawer
