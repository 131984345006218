import { connect } from 'react-redux'
import ListOfStudentsPlacedTable from 'modules/Reports/Partials/Students/Partials/StudentPlacedTable/index'
import {
  getStudentListForReport,
  getCollegeList,
  getCourseList,
  getDegreeList,
  getSpecialisationList,
  getPlacedStudentListForReport,
  getCorporateNameList,
  UniversityUrl,
  CollegeUrl,
  DegreeUrl,
  SpecialisationUrl,
  CorporateUrl,
} from 'modules/Reports/Partials/Students/actions'

import selectors from 'modules/Reports/Partials/Students/selectors'
import {getUserInfo } from '../../../../Auth/actions'
import selector from '../../../../Auth/selectors'



const mapStateToProps = state => ({
  studentList: selectors?.placedStudentListReportData(state),
  collegeList: selectors?.CollegeListData(state),
  courseList: selectors?.courseListData(state),
  degreeList: selectors?.degreeListData(state),
  specialisationList: selectors?.specialisationListData(state),
  corporateNameList: selectors?.corporateNameList(state),
  excelDataForReport: selectors?.excelData(state),
  universityData: selectors?.universityData(state),
  collegeData: selectors?.collegeData(state),
  degreeData: selectors?.degreeData(state),
  specialisationData: selectors?.specialisationData(state),
  corporateData: selectors?.corporateData(state),
  userData: selector?.getUserData(state),

})
const mapDispatchToProps = {
  getStudentListForReport,
  getCollegeList,
  getCourseList,
  getDegreeList,
  getSpecialisationList,
  getPlacedStudentListForReport,
  getCorporateNameList,
  UniversityUrl,
  CollegeUrl,
  DegreeUrl,
  SpecialisationUrl,
  CorporateUrl,
  getUserInfo
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfStudentsPlacedTable)
