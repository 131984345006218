import {
  SET_INSTITUTE_LIST,
  SET_DEPARTMENT_LIST,
  SET_SPECIALISATION_LIST,
} from 'modules/Systemconfig/Partials/InstituteSettings/actions'
import {
  SET_EVENT_DATA,
  SET_SCREEN_DATA,
} from 'modules/Systemconfig/Partials/PermissionSettings/action'

const initialState = {
  getInstituteListForSysConfig: {},
  getDepartmentListForSysConfig: {},
  getSpecialisationListForSysConfig: {},
  screenData: {},
  eventData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTITUTE_LIST:
      return {
        ...state,
        getInstituteListForSysConfig: action.data,
      }
    case SET_DEPARTMENT_LIST:
      return {
        ...state,
        getDepartmentListForSysConfig: action.data,
      }
    case SET_SPECIALISATION_LIST:
      return {
        ...state,
        getSpecialisationListForSysConfig: action.data,
      }
    case SET_SCREEN_DATA:
      return {
        ...state,
        screenData: action.data,
      }
    case SET_EVENT_DATA:
      return {
        ...state,
        eventData: action.data,
      }
    default:
      return state
  }
}
