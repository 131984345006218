import { Avatar, Col } from 'antd'
import Pagination from 'components/Pagination'
import SearchInput from 'components/SearchInput'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import AntdAvatar from '../../../../../../components/Avatar'
import TuneSetting from '../../../../../../components/icons/TuneSetting'
import {
  CandidatesSpan,
  CircleCheckbox,
  Count,
  ListDivider,
  ListName,
} from '../../Style/style'
import { getCorporatesView } from '../../actions'
import CorporateFilter from '../Corporates/index'
const { COUNTRY_ID } = process.env

const AddUserForm = ({
  searchValue,
  pageNumber,
  setPageNumber,
  FunctionTableList,
  getListOfState,
  corporateViewData,
  setChecked,
  checked,
  journeyId,
  stateId,
  setStateId,
  setFilterData,
  id,
  setIsMenuOpen,
  isMenuOpen,
  getListOfCity,
  getId,
  setJourneyId,
  cityId,
  setPermCityId,
  addUserDrawer,
  industry,
  setIndustry,
  sector,
  setSector,
  setCheckedCount,
  fetchSectorData,
  sectorList,
  filterData,
}) => {
  const [citiesData, setCitiesData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [location, setLocation] = useState('')
  const [value, setValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [degreeSearch, setDegreeSearch] = useState('')
  const [stateList, setStateList] = useState([])
  const [stateNames, setStateNames] = useState([])
  const [searchCollege, setSearchCollege] = useState('')
  const [industryName, setIndustryName] = useState([])
  const [sectorName, setSectorName] = useState([])
  const [debounced, setDebounced] = useState(() => search)
  const [uuid, setuuid] = useState('')
  const [cityList, setCityList] = useState([])
  const [cityName, setCityName] = useState([])
  const [check, setCheck] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const citiesList = useSelector(
    state => state?.CorporateData?.citiesWithPagination
  )
  const userIdLocation = useLocation()
  useEffect(() => {
    if (userIdLocation) {
      const parts = userIdLocation?.pathname?.split('/')
      const data = parts?.[parts?.length - 1]
      setuuid(data)
    }
  }, [userIdLocation])

  // const queryParams = new URLSearchParams(userIdLocation.search)
  React.useEffect(() => {
    let pageNo = pageNumber
    let searchValue = search || ''
    let city = cityName
    let userId = params.id
    let state = stateNames != '' ? stateNames : undefined
    let industry = industryName != '' ? industryName : undefined
    if (uuid)
      dispatch(
        getCorporatesView({
          userId,
          searchValue,
          pageNo,
          city,
          state,
          industry,
        })
      )
  }, [pageNumber, params.id])
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(search)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])
  const getCities = (search = '', pageNum = '') => {
    let searchValue = search
    let pageNo = pageNum
    let userId = params.id
    let city = cityName
    let state = stateNames != '' ? stateNames : undefined
    let industry = industryName != '' ? industryName : undefined
    dispatch(
      getCorporatesView({ userId, searchValue, pageNo, city, state, industry })
    )
  }
  useEffect(() => {
    if (uuid) {
      let userId = uuid
      let pageNo = pageNum
      dispatch(getCorporatesView({ userId, searchValue, pageNo }))
    }
  }, [uuid, addUserDrawer])
  useEffect(() => {
    setCitiesData([])
    getCities(search)
    setPageNum(1)
  }, [debounced])

  useEffect(() => {
    if (citiesList?.cities?.length) {
      let newList = [...citiesData, ...(citiesList?.cities ?? [])]
      setCitiesData(newList)
      setPageNum(prev => prev + 1)
    }
  }, [citiesList?.cities])

  const handleCheckboxChange = e => {
    if (Array.isArray(checked)) {
      if (checked.includes(e.target.value)) {
        setChecked(checked.filter(item => item !== e.target.value))
        setCheckedCount(prevCount => prevCount - 1) // Step 2: Decrease count when deselected
      } else {
        setChecked([...checked, e.target.value])
        setCheckedCount(prevCount => prevCount + 1) // Step 2: Increase count when selected
      }
    } else {
      setChecked([e.target.value])
      setCheckedCount(1) // Step 2: Initialize count to 1 when first selected
    }
    let data = e.target.value
    if (e.target.checked) {
      let a = [...journeyId, ...[data]]
      setJourneyId(a)
    } else {
      let removeData = journeyId?.filter(item => item !== e.target.value)
      setJourneyId(removeData)
    }
  }

  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      location: cityId,
      state: stateId,
      industry: industry,
      sector: sector,
    })
    let pageNo = '1'
    let city = cityId != '' ? cityId : undefined
    let state = stateId != '' ? stateId : undefined
    let industryData = industry != '' ? industry : undefined
    let sectorData = sector != '' ? sector : undefined
    let userId = uuid
    dispatch(
      getCorporatesView({
        userId,
        pageNo,
        city,
        state,
        industry: industryData,
        searchValue,
        sector: sectorData,
      })
    )
    setValue(false)
  }

  const onPageChange = key => {
    setPageNumber(key)
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await getListOfCity(countryId, stateId, search, 0, pageLimit)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))(
        updatedArray
      )
      return setCityList(updatedArray || [])
    }
  }

  useEffect(() => {
    setPermCityId('')
    getCityData(COUNTRY_ID, stateNames, true)
  }, [stateId])

  const onCancelFilter = value => {
    setValue(!value)
  }

  useEffect(() => {
    setCheck(prev => !prev)
    setIndustry(filterData?.industry || [])
    setStateId(filterData?.state || [])
    setPermCityId(filterData?.location || [])
    setIndustry(filterData?.industry || [])
    fetchSectorData('')
    setSearchCollege('')
  }, [value])
  return (
    <>
      <Fragment>
        <SearchInput
          id={'search'}
          placeholder="Search"
          width="100%"
          name={'website'}
          rules={[
            {
              required: false,
              message: 'Please enter Search!',
            },
          ]}
          setSearchValue={e => {
            setSearch(e)
          }}
          iconPosition="suffix"
          suffix={
            <Avatar
              style={{ cursor: 'pointer' }}
              onClick={() => setValue(!value)}
              src={<TuneSetting />}
            />
          }
        />

        {value ? (
          <CorporateFilter
            setValue={setValue}
            getListOfState={getListOfState}
            FunctionTableList={FunctionTableList}
            sectorList={sectorList}
            setLocation={setLocation}
            location={location}
            value={value}
            submitFilter={submitFilter}
            setIsMenuOpen={setIsMenuOpen}
            loading={loading}
            setDegreeSearch={setDegreeSearch}
            setLoading={setLoading}
            stateId={stateId}
            setStateId={setStateId}
            degreeSearch={degreeSearch}
            stateList={stateList}
            setStateList={setStateList}
            setStateNames={setStateNames}
            searchCollege={searchCollege}
            setSearchCollege={setSearchCollege}
            industry={industry}
            industryName={industryName}
            setIndustry={setIndustry}
            setIndustryName={setIndustryName}
            sector={sector}
            setSector={setSector}
            setSectorName={setSectorName}
            sectorName={sectorName}
            stateNames={stateNames}
            cityList={cityList}
            cityId={cityId}
            setPermCityId={setPermCityId}
            cityName={cityName}
            onCancelFilter={onCancelFilter}
            setCityName={setCityName}
            setCityList={setCityList}
            fetchSectorData={fetchSectorData}
            check={check}
          />
        ) : null}
        <Count>
          Total Corporates
          <CandidatesSpan>{corporateViewData?.count}</CandidatesSpan>
        </Count>

        <div>
          {corporateViewData?.list?.map(item => (
            <>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <AntdAvatar
                    size={65}
                    IconName={item?.name.charAt(0)}
                    font={18}
                    src={item?.logo}
                  />
                  <Col column>
                    <ListName>{item?.name}</ListName>
                    <Count>{item?.city}</Count>
                  </Col>
                </div>
                <div>
                  <CircleCheckbox
                    key={item?.id}
                    value={item?.id}
                    checked={
                      Array.isArray(checked) && checked.includes(item?.id)
                    }
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <ListDivider />
            </>
          ))}
        </div>
      </Fragment>
      <Pagination
        onChange={onPageChange}
        totalPages={corporateViewData?.count}
        pageSize={corporateViewData?.limit}
        current={pageNumber}
      />
    </>
  )
}

export default AddUserForm
