const getCorporateViewData = state => state?.userView?.corporateViewData
const getInstituteViewData = state => state?.userView?.instituteViewData
const getCorporateJourneyData = state => state?.userView?.corporatejourneyData
const getInstituteJourneyViewData = state =>
  state?.userView?.institutejourneyData
const getUserData = state => state?.auth?.userData

const selectors = {
  getCorporateViewData,
  getInstituteViewData,
  getCorporateJourneyData,
  getInstituteJourneyViewData,
  getUserData,
}

export default selectors
