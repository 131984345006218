import { Checkbox, Col, Divider, Menu, Row } from 'antd'
import Button from 'components/Button/index'
import Flex from 'components/Flex'
import { Editor } from 'react-draft-wysiwyg'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const SectionWrapper = styled(Flex)`
  width: ${props => (props?.width ? props?.width : '100%')};
  // width: 100%;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  border-radius: 10px;
  overflow: scroll;
  background: ${COLORS.WHITE};
`
export const SectionWrapper1 = styled(Flex)`
  width: ${props => (props?.width ? props?.width : '100%')};
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  border-radius: 10px;
  background: ${COLORS.WHITE};
`

export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;
`
export const TopHeader1 = styled(Flex)`
  padding: 15px;
  position: relative;
  width: 100%;
  font-size: 2em;
  border-bottom: 1px solid #e6e7ea;
`
export const TopHeader2 = styled(Flex)`
  padding: 10px;
  position: relative;
  width: 100%;
  font-size: 2em;
  border-bottom: 1px solid #e6e7ea;
`

export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`

export const TopRightFlex = styled(Flex)`
  gap: 15px;
`
export const SmallButtons = styled(Button.Default)`
  background: ${COLORS.WHITE};
  padding: 9px;
  > div {
    margin-left: 0;
  }
`
export const CardRow = styled(Flex)`
  padding: 0 25px 25px 25px;
  gap: 20px;
`
export const SkillRow = styled(Flex)`
  gap: px;
`
export const ContentCenterDiv = styled.div`
  padding-top: 10px;
  text-align: center;
`
export const PaddedToggleDive = styled.div`
  padding: 15px;
`
export const PaddedToggleDive1 = styled.div`
  padding: 0 15px;
`
export const SpanPaddedHeader = styled.span`
  padding-left: 15px;
  margin-bottom: 5px;
  text-transform: capitalize;
`

export const StyledMenu = styled(Menu)`
  // padding: 10px;
  text-transform: capitalize;

  .ant-menu-item-selected {
    background-color: #eaedfb !important;
    font-weight: 600;
    border-radius: 10px;
  }
`
export const EventHeader = styled.div`
  width: 100%;
  padding: 8px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #343e59;
  text-transform: capitalize;
`

export const EventHeader1 = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-top: 20px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #343e59;
  text-transform: capitalize;
`

export const RowStyling = styled(Row)`
  border-left: 1px solid #e6e7ea !important;
  border-right: 1px solid #e6e7ea !important;
  border-bottom: 1px solid #e6e7ea !important;
  border-radius: 10px;
`
export const StyledCol = styled(Col)`
  border-left: 1px solid #e6e7ea !important;
`

export const StyledDividerTab = styled(Divider)`
  margin: 0px !important;
`
export const StyledDividerertical = styled(Divider)`
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
`
export const ActiveContent = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #343e59;
  padding-right: 12px;
`

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    margin-right: 8px;
    width: 80%;
    padding: 10px;
    padding-left: 20px;
    padding-bottom: 16px;
    border-bottom: 1px #e6e7ea solid;
  }
`

export const SideArrowItem = styled.div`
  width: 30px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : ' #FAFAFB'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  color: ${props => (props.color ? props.color : '#ffffff')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  onclick: ${props => (props.onClick ? props.onClick : '')};
  id: ${props => (props.id ? props.id : '')};
  pointer-events: ${props => (props.pointerEvents ? props.pointerEvents : '')};
`

export const StyledCheckboxGroup1 = styled(Checkbox.Group)`
  .ant-checkbox {
    display: none !important;
  }
`

export const FooterDiv = styled.div`
  width: 100%;
  position: relative;
  float: right;
  border-top: 1px #e6e7ea solid;
  margin: 10px;
  padding-top: 10px;
`

export const CircleCheckbox = styled(Checkbox)`
  width: 100% !important;
  .ant-checkbox-group {
    width: 100% !important;
  }
  .ant-checkbox {
    border-radius: 50%;
    border: 1px solid #d6dbf7;
    background: #f9fafe;
  }
  .ant-checkbox-input:hover {
    border: 1px solid #d6dbf7;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #d6dbf7;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #d6dbf7;
    /* background: #5db594; */
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-input {
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  /* .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner:hover,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    background: #5db594;
    border-radius: 50%;
  } */
`

export const TemplateOuterDiv = styled.div`
  alignitems: 'center';
  display: 'flex';
  width: '100%';
`

export const TemplateHeading = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  padding-left: 10px;
  color: #4d566e;
`
export const WrittenDrawer = styled.div`
position: fixed;
top: 0;
right: 0;
width: 80%;
height: 100%;
background-color: white;
display: flex;
// justify-content: center;
// align-items: center;
overflow: scroll;
padding:10px;
`
export const WrittenDrawerContent = styled.div`
// background-color: white;
//   padding: 20px;
//   border-radius: 4px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`

export const TextEditorwithimage = styled(Editor)``
