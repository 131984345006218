import Control from 'components/Control/Control'
import AntdInput from 'components/Form/Input'
import Label from 'components/Form/Label'
import AntdSelect from 'components/Form/Select'
import { StyledSelectDiv } from 'modules/ManageProfile/Style/style'
import {
  SectionHeading,
  StyledInput,
  StyledInputDiv,
  TwoFields,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import React from 'react'
import CONSTANTS from 'utils/constants'
import Flex from 'components/Flex'

const ContactInstituteDetails = ({ countryCode, setCountryCode, form }) => {
  const onCountryCodeChange = (id, value) => {
    setCountryCode(value?.option?.value)
    form?.setFieldValue('countryCode', value?.option?.value)
  }
  const CountryCode = [
    {
      id: '+91',
      value: '+91',
    },
    {
      id: '+93',
      value: '+93',
    },
    {
      id: '+92',
      value: '+92',
    },
  ]

  return (
    <>
      <SectionHeading>Contact Info</SectionHeading>
      <AntdInput
        width="100%"
        label="Contact Person Name"
        placeholder="Eg. Harsha Saggi"
        optional={'*'}
        name={'contactPerson'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('contact person'),
          },
          {
            pattern: /^(?!\s).*$/,
            message: 'Contact cannot start with a space',
          },
          {
            message: CONSTANTS.ERROR_MESSAGE.VALID('contact person'),
          },
        ]}
      />
      <AntdInput
        width="100%"
        label="Role"
        placeholder="Eg. TPO"
        optional={'*'}
        name={'contactPersonRole'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('contact person'),
          },
          {
            pattern: /^(?!\s).*$/,
            message: 'Role cannot start with a space',
          },
          {
            message: CONSTANTS.ERROR_MESSAGE.VALID('contact person'),
          },
        ]}
      />{' '}
      <TwoFields spaceBetween align={'end'}>
        <Control direction={'column'} gap={'12px'}>
          <Label>College Number *</Label>
          <Flex>
            <StyledSelectDiv>
              <AntdSelect
                width="100%"
                OptionData={CountryCode}
                nameParam="value"
                bordered={false}
                placeholder="+91"
                onChange={onCountryCodeChange}
                value={countryCode}
              />
            </StyledSelectDiv>
            <StyledInputDiv>
              <StyledInput
                width="100%"
                type="tel"
                placeholder="Eg: 1010101010"
                rules={[
                  {
                    required: true,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('phone number'),
                  },
                  {
                    message: CONSTANTS.ERROR_MESSAGE.VALID('phone number'),
                    pattern: CONSTANTS.REGEX.MOBILE_NUMBER,
                  },
                ]}
                name={'contactNumber'}
              />
            </StyledInputDiv>
          </Flex>
        </Control>
        <Control direction={'column'} gap={'12px'}>
          <Label>Secondary College Number *</Label>
          <Flex>
            <StyledSelectDiv>
              <AntdSelect
                width="100%"
                OptionData={CountryCode}
                nameParam="value"
                bordered={false}
                placeholder="+91"
                onChange={onCountryCodeChange}
                value={countryCode}
              />
            </StyledSelectDiv>
            <StyledInputDiv>
              <StyledInput
                width="100%"
                type="tel"
                placeholder="Eg: 1010101010"
                rules={[
                  {
                    required: true,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('phone number'),
                  },
                  {
                    message: CONSTANTS.ERROR_MESSAGE.VALID('phone number'),
                    pattern: CONSTANTS.REGEX.MOBILE_NUMBER,
                  },
                ]}
                name={'secondaryContactNumber'}
              />
            </StyledInputDiv>
          </Flex>
        </Control>
      </TwoFields>
      <TwoFields spaceBetween>
        <AntdInput
          width="100%"
          label="Email ID"
          placeholder="Eg. example@gmail.com"
          optional={'*'}
          name={'contactEmail'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('email address'),
            },
            {
              type: 'email',
              message: CONSTANTS.ERROR_MESSAGE.VALID('email address'),
            },
          ]}
        />
        <AntdInput
          width="100%"
          label="Secondary Email ID"
          placeholder="Eg. example@gmail.com"
          optional={'*'}
          name={'secondaryContactEmail'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('email address'),
            },
            {
              type: 'email',
              message: CONSTANTS.ERROR_MESSAGE.VALID('email address'),
            },
          ]}
        />
      </TwoFields>
    </>
  )
}

export default ContactInstituteDetails
