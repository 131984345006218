import { Col, Row, Tabs } from 'antd'
import Avatar from 'components/Avatar'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import FileReaderVisible from 'components/Uploader/FileReaderVisible'
import {
  PaddingDetails,
  StyledTabs,
} from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style'
import { FlexWrap } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/ViewCorporateDrawer/Style/style'
import {
  DetailHeader,
  LeftText,
  RightText,
} from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Style/style'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editCorporate } from '../../../Register/actions'

const { TabPane } = Tabs

const VerifyCorporateDrawer = ({
  verifyCorporateDrawer,
  setVerifyCorporateDrawer,
  setChangeCorporateDetails,
  setClearInput
}) => {
  const dispatch = useDispatch()
  const singleCorporateData = useSelector(
    state => state?.CorporateOnboarding?.singleCorporateData
  )

  const onChange = () => {}

  const basicDetails = [
    {
      heading: 'Company Name',
      value: singleCorporateData?.name,
    },
    {
      heading: 'Industry',
      value: singleCorporateData?.industry,
    },
    {
      heading: 'Sector',
      value: singleCorporateData?.sector,
    },
    {
      heading: 'No. of Employees',
      value: singleCorporateData?.employeesNumber,
    },
    {
      heading: 'Address 1',
      value: singleCorporateData?.addressLine1,
    },
    {
      heading: 'Address 2',
      value: singleCorporateData?.addressLine2,
    },
    {
      heading: 'Country',
      value: singleCorporateData?.country,
    },
    {
      heading: 'State',
      value: singleCorporateData?.state,
    },
    {
      heading: 'City',
      value: singleCorporateData?.city,
    },
    {
      heading: 'Zipcode',
      value: singleCorporateData?.postalCode,
    },
    {
      heading: 'Website',
      value: singleCorporateData?.website,
    },
    {
      heading: 'One liner',
      value: singleCorporateData?.shortDescription,
    },
    {
      heading: 'About Company',
      value: singleCorporateData?.longDescription,
    },
    {
      heading: 'Corporate Presentation',
      value: singleCorporateData?.corporatePresentationLink,
    },
    {
      heading: 'Corporate Video Link',
      value: singleCorporateData?.corporateVideoLink,
    },
  ]

  const taxInformation = [
    {
      heading: 'PAN Number',
      value: singleCorporateData?.pan,
    },
    {
      heading: 'GST Number',
      value: singleCorporateData?.gst,
    },
  ]

  const onRequestToChange = () => {
    setVerifyCorporateDrawer(false)
    setChangeCorporateDetails(true)
  }

  const content = () => {
    return (
      <StyledTabs onChange={onChange}>
        <TabPane tab={<>Corporate Details &nbsp;&nbsp;</>} key="ACTIVE">
          <DetailHeader>Basic Details</DetailHeader>

          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <LeftText>{'Logo'}</LeftText>
            </Col>
            <Col span={12}>
              <RightText>
                <Avatar
                  size={52}
                  src={singleCorporateData?.logo}
                  boxShadow={true}
                  padding={'3px'}
                />
              </RightText>
            </Col>
          </Row>

          {basicDetails?.map((item, id) => (
            <Row key={item?.id} style={{ width: '100%' }}>
              <Col span={12}>
                <LeftText>{item.heading}</LeftText>
              </Col>
              <Col span={12}>
                <RightText
                  dangerouslySetInnerHTML={{
                    __html: item.value ? item.value : '-',
                  }}
                />
              </Col>
            </Row>
          ))}

          <PaddingDetails />

          <DetailHeader>Tax Information</DetailHeader>
          {taxInformation?.map((item, id) => (
            <Row key={item?.id} style={{ width: '100%' }}>
              <Col span={12}>
                <LeftText>{item?.heading}</LeftText>
              </Col>
              <Col span={12}>
                <RightText>{item?.value ? item?.value : '-'}</RightText>
              </Col>
            </Row>
          ))}

          <PaddingDetails />
        </TabPane>
        <TabPane tab={<>Attached Documents &nbsp;&nbsp;</>} key="DEACTIVE">
          {singleCorporateData?.authorisedSignatory && (
            <FileReaderVisible
              file={singleCorporateData?.authorisedSignatory}
            />
          )}

          <PaddingDetails />

          {singleCorporateData?.regDocs && (
            <FileReaderVisible file={singleCorporateData?.regDocs} />
          )}
        </TabPane>
      </StyledTabs>
    )
  }

  const onConfirm = () => {
    const callBack = () => {
      setClearInput(true)
      setTimeout(()=>{
        setClearInput(false)
      },[1000])
      setVerifyCorporateDrawer(false)
    }
    dispatch(
      editCorporate(
        singleCorporateData.id,
        { companyInfo: { status: 1 } },
        callBack,
        'Verified Successfully',
        true
      )
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        {singleCorporateData?.status == 2 ? null : (
          <Button.Secondary
            text="Request to change"
            onClick={onRequestToChange}
            height="38px"
          />
        )}

        <Button.Primary
          htmlType="submit"
          onClick={onConfirm}
          text="Confirm Verification"
          height="38px"
        />
      </FlexWrap>
    )
  }

  const onClose = () => {
    setVerifyCorporateDrawer(false)
  }

  return (
    <Drawer
      width="60%"
      title={'Verification'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={verifyCorporateDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default VerifyCorporateDrawer
