import { Col, Divider, Form, Row } from 'antd'
import RoutePath from 'components/RoutePath/index'
import LeftArrow from 'components/icons/LeftArrow'
import {
  LeftSection,
  PaddedDiv,
  RoleNameDiv,
  RoleNameFlex,
  SectionName,
  TopHeader,
} from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import { SystemPaddedDiv } from 'modules/Systemconfig/Styles/style'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MasterItem from '../../../../components/UIComponents/SideMenuMaster'
import SystemconfigTable from '../../../../components/UIComponents/SystemConfigTable/index'
import CrudDrawer from '../../Components/Drawer/Drawer'
import {
  AddBankContent,
  AddRateCardContent,
  BankMasterData,
  BillingMenuData,
  DummyData,
  RateCardMasterData,
} from './Partials/TableColumnData'
const BillingSysConfig = ({ dataItem }) => {
  const [tableData, setTableData] = React.useState({
    id: 1,
    text: 'Bank',
  })
  const [id, setId] = React.useState('1')
  const [drawer, setDrawer] = useState(false)
  const [view, setView] = useState(false)
  const [form] = Form.useForm()
  const [toEditId, setToEditId] = useState('')
  const [sortBy, setSortBy] = useState({})
  const [content, setContent] = useState(AddBankContent)
  const [searchValue, setSearchValue] = useState('')
  const showDrawer = () => {
    setDrawer(true)
  }
  const onClose = () => {
    setDrawer(false)
    form.resetFields()
    setToEditId('')
    setView(false)
  }
  const [dataToAdd, setDataToAdd] = useState(BankMasterData)
  const onTableClick = data => {
    setTableData(data)
    setId(data.id)
    switch (data.text) {
      case 'Bank':
        setDataToAdd(BankMasterData)
        setContent(AddBankContent)
        break
      case 'Rate Card':
        setDataToAdd(RateCardMasterData)
        setContent(AddRateCardContent)
        break
      default:
        break
    }
  }
  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/systemConfig">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="System Config"
                on={'Billing/Payment Settings'}
                path="/systemConfig"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>Billing/Payment Settings</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>
      <Row>
        <Col span={5}>
          <SystemPaddedDiv>
            <MasterItem
              onTableClick={onTableClick}
              id={id}
              MasterTableData={BillingMenuData}
            />
          </SystemPaddedDiv>
        </Col>
        <Col span={0.5}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={18}>
          <SystemconfigTable
            tableData={tableData}
            dataToAdd={dataToAdd}
            data={DummyData}
            showDrawer={showDrawer}
            content={content}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            setSortBy={setSortBy}
            sortBy={sortBy}
          />
        </Col>
      </Row>
      <CrudDrawer
        open={drawer}
        onClose={onClose}
        tableData={tableData}
        content={content}
        form={form}
        // handleSubmitCongif={handleSubmitCongif}
        toEditId={toEditId}
        // setEditId={setEditId}
        view={view}
        // setView={setView}
      />
    </>
  )
}
export default BillingSysConfig
