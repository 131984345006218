import { SET_COMPANY_LIST } from 'modules/Systemconfig/Partials/CorporateSettings/action'

const initialState = {
  getCompanyListForSysConfig: {},
}

export default (state = initialState, action) => {
  if (action.type === SET_COMPANY_LIST) {
    return {
      ...state,
      getCompanyListForSysConfig: action.data,
    }
  } else {
    return state
  }
}
