import { Col, Row, Tabs } from 'antd'
import Avatar from 'components/Avatar'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import FileReaderVisible from 'components/Uploader/FileReaderVisible'
import {
  PaddingDetails,
  StyledTabs,
} from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style'
import { FlexWrap } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/ViewCorporateDrawer/Style/style'
import {
  DetailHeader,
  LeftText,
  RightText,
} from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Style/style'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateInstituteStatus } from '../../../Register/actions'

const { TabPane } = Tabs

const VerifyInstituteDrawer = ({
  verifyInstituteDrawer,
  setVerifyInstituteDrawer,
  setChangeInstituteDetails,
  setClearInput
}) => {
  const dispatch = useDispatch()
  const singleInstituteData = useSelector(
    state => state?.InstituteOnboarding?.singleInstituteData
  )

  const onChange = () => {}

  const basicDetails = [
    {
      heading: 'Institute Name',
      value: singleInstituteData?.name,
    },
    {
      heading: 'Affiliated with',
      value: singleInstituteData?.industry,
    },
    {
      heading: 'Constituency',
      value: singleInstituteData?.sector,
    },
    {
      heading: 'Address 1',
      value: singleInstituteData?.instituteCampus?.[0]?.addressLine1,
    },
    {
      heading: 'Address 2',
      value: singleInstituteData?.instituteCampus?.[0]?.addressLine2,
    },
    {
      heading: 'Country',
      value: singleInstituteData?.instituteCampus?.[0]?.country,
    },
    {
      heading: 'State',
      value: singleInstituteData?.instituteCampus?.[0]?.state,
    },
    {
      heading: 'City',
      value: singleInstituteData?.instituteCampus?.[0]?.city,
    },
    {
      heading: 'Zipcode',
      value: singleInstituteData?.instituteCampus?.[0]?.postalCode,
    },
    {
      heading: 'Website',
      value: singleInstituteData?.instituteCampus?.[0]?.website,
    },
    {
      heading: 'One liner',
      value: singleInstituteData?.instituteCampus?.[0]?.shortDescription,
    },
    {
      heading: 'About Institute',
      value: singleInstituteData?.instituteCampus?.[0]?.longDescription,
    },
    {
      heading: 'Institute Presentation',
      value:
        singleInstituteData?.instituteCampus?.[0]?.institutePresentationLink,
    },
    {
      heading: 'Institute Video Link',
      value: singleInstituteData?.instituteCampus?.[0]?.instituteVideoLink,
    },
  ]

  const taxInformation = [
    {
      heading: 'PAN Number',
      value: singleInstituteData?.instituteCampus?.[0]?.pan,
    },
    {
      heading: 'GST Number',
      value: singleInstituteData?.instituteCampus?.[0]?.gst,
    },
  ]

  const onClick = () => {
    setVerifyInstituteDrawer(false)
    setChangeInstituteDetails(true)
  }

  const onSubmit = () => {
    const callBack = () => {
      setClearInput(true)
      setTimeout(()=>{
        setClearInput(false)
      },[1000])
      setVerifyInstituteDrawer(false)
    }
    dispatch(
      updateInstituteStatus({
        instituteCampusId: singleInstituteData?.campusId,
        data: { institute: { status: 1 } },
        callBack: callBack,
        message: 'Verified Successfully',
      })
    )
  }

  const onClose = () => {
    setVerifyInstituteDrawer(false)
  }

  const content = () => {
    return (
      <StyledTabs onChange={onChange}>
        <TabPane tab={<>Institute Details &nbsp;&nbsp;</>} key="ACTIVE">
          <DetailHeader>Basic Details</DetailHeader>
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <LeftText>{'Logo'}</LeftText>
            </Col>
            <Col span={12}>
              <RightText>
                <Avatar
                  size={52}
                  src={singleInstituteData?.instituteCampus?.[0]?.campusLogoUrl}
                  boxShadow={true}
                  padding={'3px'}
                />
              </RightText>
            </Col>
          </Row>

          {basicDetails?.map(item => (
            <Row key={item?.id} style={{ width: '100%' }}>
              <Col span={12}>
                <LeftText>{item.heading}</LeftText>
              </Col>
              <Col span={12}>
                <RightText
                  dangerouslySetInnerHTML={{
                    __html: item.value ? item.value : '-',
                  }}
                />
              </Col>
            </Row>
          ))}

          <PaddingDetails />

          <DetailHeader>Tax Information</DetailHeader>
          {taxInformation?.map(item => (
            <Row key={item?.id} style={{ width: '100%' }}>
              <Col span={12}>
                <LeftText>{item?.heading}</LeftText>
              </Col>
              <Col span={12}>
                <RightText>{item?.value ? item?.value : '-'}</RightText>
              </Col>
            </Row>
          ))}

          <PaddingDetails />
        </TabPane>
        <TabPane tab={<>Attached Documents &nbsp;&nbsp;</>} key="DEACTIVE">
          {singleInstituteData?.authorisedSignatory && (
            <FileReaderVisible
              file={singleInstituteData?.authorisedSignatory}
            />
          )}

          <PaddingDetails />

          {singleInstituteData?.regDocs && (
            <FileReaderVisible file={singleInstituteData?.regDocs} />
          )}
        </TabPane>
      </StyledTabs>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        {singleInstituteData?.status == 2 ? null : (
          <Button.Secondary
            text="Request to change"
            onClick={onClick}
            height="38px"
          />
        )}

        <Button.Primary
          htmlType="submit"
          onClick={onSubmit}
          text="Confirm Verification"
          height="38px"
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="60%"
      title={'Verification'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={verifyInstituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default VerifyInstituteDrawer
