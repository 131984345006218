const instituteSysConfigList = state =>
  state?.InstituteSystemConfig?.getInstituteListForSysConfig
const departmentSysConfigList = state =>
  state?.InstituteSystemConfig?.getDepartmentListForSysConfig
const specialisationSysConfigList = state =>
  state?.InstituteSystemConfig?.getSpecialisationListForSysConfig
const getscreensData = state => state?.permissionSettings?.screenData
const geteventsData = state => state?.permissionSettings?.eventData
const Instituteselectors = {
  instituteSysConfigList,
  departmentSysConfigList,
  specialisationSysConfigList,
  getscreensData,
  geteventsData,
}

export default Instituteselectors
