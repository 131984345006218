import Button from 'components/Button/index'
import Input from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import Select from 'components/Select'
import AddIcon from 'components/icons/AddIcon'
import React, { Fragment, useState } from 'react'
import { journeyDropdown } from '../../../../../../../utils/constants'
import { FormItem } from '../../../../../../User/Partials/AddUserDrawer/Partials/AddUserForm/styles'
import { toCamelCase } from '../../../../../../../utils/camelCase'

const DrawerForm = ({
  form,
  getEventData,
  eventData,
  setShow,
  show,
  setEventShow,
  eventShow,
  getScreenData,
  screenData,
  setJourney,
  inputs,
  setInputs,
  toEditId,
  instituteTableList,
  view,
}) => {
  const [eventId, setEventId] = useState()

  const handleAddInput = () => {
    setInputs([...inputs, { name: '', label: 'Screen Name' }])
  }

  const handleCloseField = () => {
    const updatedFields = inputs.slice(0, -1)
    setInputs(updatedFields)
  }

  const handleInputChange = (index, event) => {
    const values = [...inputs]
    values[index].value = event?.target.value
    setInputs(values)
  }
  const handleJourneyChange = value => {
    setJourney(value)
    getScreenData()
    setShow(value)
  }

  const handleScreenChange = value => {
    getEventData(value)
    setEventShow(value)
    setEventId('')
  }

  const handleEventChange = value => {
    setEventId(value)
  }

  return (
    <Fragment>
      <div>
        <FormItem
          name={'journey'}
          rules={[
            {
              required: true,
              message: 'Please select Any One Journey',
            },
          ]}
        >
          <AntdSelect
            name={'journey'}
            OptionData={journeyDropdown}
            label="Journey *"
            onChange={value => {
              handleJourneyChange(value)
              form.setFieldsValue({ journey: value })
              form.setFieldsValue({ screenName: '' })
              form.setFieldsValue({ eventId: '' })
              form.setFieldsValue({ screenId: '' })
              form.setFieldsValue({ subEventName: '' })
            }}
            nameParam="value"
            placeholder="Select"
            valueParam="id"
          />
        </FormItem>
      </div>
      <div>
        <FormItem
          name={!toEditId ? 'screenName' : 'screenId'}
          rules={[
            {
              required: true,
              message: 'Please select Any One Screen',
            },
          ]}
        >
          <Select
            OptionData={screenData?.screens?.map(event => ({
              label: toCamelCase(event?.screenName),
              value: event?.id,
            }))}
            label="Screen *"
            nameParam="label"
            disabled={!show}
            onChange={value => {
              handleScreenChange(value)
              form.setFieldsValue({ screenName: value })
              form.setFieldsValue({ screenId: value })
              form.setFieldsValue({ eventId: '' })
            }}
            placeholder="Select"
            valueParam="value"
          />
        </FormItem>
      </div>
      <div>
        <FormItem
          name={'eventId'}
          rules={[
            {
              required: true,
              message: 'Please select Any One Event',
            },
          ]}
        >
          <Select
            OptionData={eventData?.events?.map(event => ({
              label: toCamelCase(event?.eventName),
              value: event?.id,
            }))}
            label="Events *"
            onChange={value => {
              handleEventChange(value)
              form.setFieldsValue({ eventId: value })
              form.setFieldsValue({ subEventName: '' })
            }}
            defaultValue={eventId}
            disabled={!eventShow}
            valueParam="value"
            nameParam="label"
            placeholder="Select"
          />
        </FormItem>
      </div>
      <div>
        {toEditId
          ? inputs.map((field, index) => (
              <FormItem key={field.id} name={'subEventName'}>
                <Input
                  type="text"
                  width="100%"
                  name={'subEventName'}
                  label={`SubEvent`}
                  optional={'*'}
                  placeholder={`Enter SubEventName`}
                  onChange={event => {
                    handleInputChange(index, event)
                    form.setFieldsValue({ subEventName: event.target.value })
                  }}
                  rules={[
                    {
                      required: true,
                      message: `Please Enter SubEventName`,
                    },
                    {
                      pattern: /^(?!\s).*$/,
                      message: 'SubEventName cannot start with a space',
                    },
                  ]}
                />
              </FormItem>
            ))
          : inputs.map((field, index) => (
              <FormItem key={field.id} name={'subEvents'}>
                <Input
                  type="text"
                  width="100%"
                  name={index}
                  label={`SubEvent${index + 1}`}
                  optional={'*'}
                  placeholder={`Enter SubEventName`}
                  value={field.value}
                  onChange={event => handleInputChange(index, event)}
                  rules={[
                    {
                      required: true,
                      message: `Please Enter SubEventName`,
                    },
                    {
                      pattern: /^(?!\s).*$/,
                      message: 'SubEventName cannot start with a space',
                    },
                  ]}
                />
              </FormItem>
            ))}
      </div>
      <div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
        {inputs.length > 1 && !toEditId && (
          <Button.Secondary
            text="Delete"
            height="38px"
            onClick={handleCloseField}
          />
        )}
        {!toEditId && (
          <Button.Secondary
            text={'Add'}
            onClick={handleAddInput}
            disabled={view}
            icon={<AddIcon />}
            height="38px"
          />
        )}
      </div>
    </Fragment>
  )
}

export default DrawerForm
