import Button from 'components/Button/index'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import UserRoleFilter from 'modules/User/Partials/RolesFilter/Partials/UserRoleFilter'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/User/Partials/RolesFilter/Styles/style'
import React, { useState } from 'react'
import { journeyDropdown } from '../../../../utils/constants'
const { TabPane } = StyledTab

const RolesFilter = ({
  currentPage,
  setRoleJourney,
  setSearchValue,
  roleList,
  setCurrentPage,
  filterData,
  setFilterData,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [roleJourneyFilter, setroleJourneyFilter] = useState([])
  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      role: roleJourneyFilter,
      // status: userStatus,
    })
    setRoleJourney(roleJourneyFilter)
    // setState(userStatus ? userStatus : 'all')
  }

  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setRoleJourney(filterData?.role)
    setroleJourneyFilter(filterData?.role)
    // setUserStatus(filterData?.status)
  }

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="10px"
        popupClassName="filter"
      >
        <TabPane tab="Journey" key="1">
          <UserRoleFilter
            roleFilter={journeyDropdown}
            setRoleJourney={setroleJourneyFilter}
            roleJourney={roleJourneyFilter}
          />
        </TabPane>
        {/* <TabPane tab="Status" key="2">
          <StatusFilter userStatus={userStatus} setUserStatus={setUserStatus} />
        </TabPane> */}
      </StyledTab>

      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )

  return (
    <TableSearchFilter
      placeholder={'Search by Role Name'}
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      setSearchValue={setSearchValue}
      currentCount={
        roleList?.data?.count < 10
          ? roleList?.data?.count
          : (roleList?.data?.page + 1) * 10 - 10 + roleList?.data?.roles?.length
      }
      totalCount={roleList?.data?.count}
      onOpenChange={onCancelFilter}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default RolesFilter
