import { Form } from 'antd'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import Label from 'components/Label/Label'
import RichEditor from 'components/RichEditor/index'
import FileReader from 'components/Uploader/FileReader'
import moment from 'moment'

import {
  default as Uploader,
  default as Uploadloader,
} from 'components/Uploader/Uploader'
import Camera from 'components/icons/Camera'
import LinkIcon from 'components/icons/LinkIcon'
import {
  ButtonWrap,
  FormItem,
  Heading,
  ImageTag,
  ImageUploadWrapper,
} from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import { getMasterSearchApi } from 'modules/Onboarding/actions'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SuccessMessage from 'utils/SuccessMessage'
import CONSTANTS from 'utils/constants'
import instituteRequest from 'utils/instituteRequest'
import { searchConfig } from 'utils/config'

const InstituteInfoForm = ({
  form,
  file,
  setFile,
  onRemoveImage,
  institutePresentation,
  setInstitutePresentation,
  singleInstituteData,
  universityList,
  universityValue,
  setUniversityName,
  longDescription,
  setLongDescription,
}) => {
  const establish = Form.useWatch('establish', form)
  const instituteVideoLink = Form.useWatch('instituteVideoLink', form)
  const dispatch = useDispatch()
  const [listOfCollege, setListOfCollege] = useState('')
  const [searchCollege, setSearchCollege] = useState('')
  const [setCollegeID] = useState('')
  const [collegeName, setCollegeName] = useState('')

  useEffect(() => {
    getListOfAllColleges()
  }, [searchCollege])

  const delayToFetch = useCallback(
    debounce(data => {
      setSearchCollege(data)
    }, 500),
    []
  )

  const onCollegeNameChange = (value, option) => {
    setCollegeID(option?.option?.campusId)
    form.setFieldsValue({ college: value })
    setCollegeName(value)
  }

  const onChangePresentationLink = file => {
    setInstitutePresentation(file)
    form.setFieldsValue({ institutePresentationLink: file?.url })
  }
  const onChangeProfile = file => {
    setFile(file)
    form.setFieldsValue({ profileUrl: file })
  }

  const copyLink = () => {
    navigator.clipboard.writeText(instituteVideoLink)
    if (instituteVideoLink.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const delayUniversityToFetch = useCallback(
    debounce(data => {
      let payload = {
        ...searchConfig?.universities,
        q: data || '',
      }
      dispatch(getMasterSearchApi('universities', payload))
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const onUniversityChange = data => {
    setUniversityName(data)
    form.setFieldsValue({ university: data })
  }

  const onYearChange = (data, option) => {
    form.setFieldsValue({ establish: data })
  }

  const onChangeDescription = description => {
    form.setFieldsValue({ longDescription: description })
  }

  const getListOfAllColleges = () => {
    let search = searchCollege ? `&search=${searchCollege}` : ''
    try {
      instituteRequest
        .get(
          `/institutes/crud/college?isActive=true&pageLimit=40&pageNo=0${search}`
        )
        .then(response => {
          setListOfCollege(response.data.data)
        })
    } catch (error) {
      console.log(error)
    }
  }
  const currentYear = new Date().getFullYear()
  const YearList = []
  for (let year = 1800; year <= currentYear; year++) {
    YearList.push({
      value: year,
      name: year,
    })
  }

  return (
    <>
      {/* Future use case */}
      <FormItem name={'profileUrl'}>
        <div></div>
      </FormItem>

      <Control flexStart row width="100%" spaceBetween>
        <Control flexStart width="67%" gap="20px">
          <Control flexStart column gap="30px">
            <Control row flexStart width="700px" gap="40px">
              <Control column flexStart gap="30px">
                <Control column flexStart>
                  <Label>Institute Name</Label>

                  <FormItem
                    name={'institute'}
                    padding={true}
                    rules={[
                      {
                        required: true,
                        message:
                          CONSTANTS.ERROR_MESSAGE.REQ_SELECT('institute'),
                      },
                    ]}
                  >
                    <AntdSelect
                      width="300px"
                      placeholder="Eg. Madras Institute of Technology"
                      name={'institute'}
                      rules={[
                        {
                          required: false,
                          message: 'Please select institute!',
                        },
                      ]}
                      OptionData={listOfCollege?.result}
                      nameParam="campusName"
                      valueParam="campusName"
                      bordered={false}
                      onChange={onCollegeNameChange}
                      showSearch={true}
                      onSearch={delayToFetch}
                      value={collegeName}
                      disabled={true}
                    />
                  </FormItem>
                </Control>

                <Control column flexStart>
                  <Label>Affiliated with</Label>
                  <FormItem
                    name={'university'}
                    padding={true}
                    rules={[
                      {
                        required: false,
                        message:
                          CONSTANTS.ERROR_MESSAGE.REQ_SELECT('university'),
                      },
                    ]}
                  >
                    <AntdSelect
                      width="300px"
                      placeholder="Eg. University of Delhi"
                      name={'university'}
                      allowClear={true}
                      rules={[
                        {
                          required: false,
                          message: 'Please select university!',
                        },
                      ]}
                      OptionData={universityList ?? []}
                      valueParam="name"
                      nameParam="name"
                      showSearch={true}
                      onSearch={delayUniversityToFetch}
                      onChange={onUniversityChange}
                      value={universityValue}
                    />
                  </FormItem>
                </Control>
              </Control>
              <Control>
                <Control column alignCenter width="200px" center>
                  <ImageUploadWrapper column alignCenter center boarder={!file}>
                    {!file && (
                      <Fragment>
                        <Control column alignCenter>
                          <Camera />
                        </Control>
                        <Control column alignCenter top={'3px'}>
                          <Control column alignCenter>
                            Max file size: 2MB
                          </Control>
                          <Control column alignCenter>
                            Dimension: 130*130
                          </Control>
                          <Control column alignCenter>
                            File format: .png, .jpeg
                          </Control>
                        </Control>
                      </Fragment>
                    )}
                    {file && <ImageTag src={file} />}
                  </ImageUploadWrapper>

                  {!file && (
                    <Uploader
                      setFile={onChangeProfile}
                      showTextUploder={!file}
                      fileName={
                        singleInstituteData?.name
                          ?.toLowerCase()
                          .replace(/\s/g, '') + '_profile'
                      }
                    />
                  )}
                  <FormItem
                    name={'profileUrl'}
                    padding={true}
                    rules={[
                      {
                        required: true,
                        message: 'Please upload a image!',
                      },
                    ]}
                  />
                  {file ? (
                    <ButtonWrap>
                      <Control
                        width="100%"
                        flexEnd
                        gap="10px"
                        top="15px"
                        column
                        alignCenter
                      >
                        <Button.Secondary
                          text="Remove"
                          onClick={onRemoveImage}
                          height={'32px'}
                        />
                        <Uploader
                          isDelete={true}
                          file={file}
                          setFile={onChangeProfile}
                          showButtonUploder={!!file}
                          fileName={
                            singleInstituteData?.name
                              ?.toLowerCase()
                              .replace(/\s/g, '') + '_profile'
                          }
                        />
                      </Control>
                    </ButtonWrap>
                  ) : null}
                </Control>
              </Control>
            </Control>
            <Control column flexStart>
              <Label>Group Name</Label>
              <AntdInput
                placeholder="IIT Group"
                name={'groupName'}
                maxLength={300}
                rules={[
                  {
                    required: false,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('groupName'),
                  },
                ]}
              />
            </Control>
            <FormItem
              name={'establish'}
              rules={[
                {
                  required: false,
                },
                {
                  validator: (_, value) => {
                    const selectedYear = moment(value, 'YYYY').year()
                    if (selectedYear > currentYear) {
                      return Promise.reject(
                        new Error('The selected year must be in the past')
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Control flexStart column width="250px">
                <Label> Year of Establishment</Label>
                <AntdSelect
                  allowClear
                  placeholder="Eg.1960"
                  name={'establish'}
                  rules={[
                    {
                      required: false,
                      message: 'Please select  Year!',
                    },
                  ]}
                  OptionData={YearList}
                  valueParam="value"
                  nameParam="name"
                  onChange={onYearChange}
                  value={establish}
                  filterOption={(inputValue, option) => {
                    const optionName = option['name']?.toString()?.toLowerCase()
                    const inputValueLower = inputValue?.toLowerCase()
                    return optionName?.indexOf(inputValueLower) >= 0
                  }}
                />
              </Control>
            </FormItem>

            <Control flexStart column>
              <Label>Website</Label>

              <AntdInput
                id={'WebsiteLink'}
                placeholder="Eg. www.google.com"
                name={'website'}
                // type="url"
                rules={[
                  {
                    required: false,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('website'),
                  },
                  // {
                  //   pattern: CONSTANTS.REGEX.WEBSITE,
                  //   message: CONSTANTS.ERROR_MESSAGE.VALID('website'),
                  // },
                ]}
                suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
              />
            </Control>

            <Control column flexStart>
              <Label>Address line 1</Label>
              <AntdInput
                placeholder="Enter your address"
                name={'addressLine1'}
                rules={[
                  {
                    required: false,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('address1'),
                  },
                  {
                    pattern: CONSTANTS.REGEX.ADDRESS1,
                    message: CONSTANTS.ERROR_MESSAGE.VALID('address1'),
                  },
                ]}
              />
            </Control>
            <Control column flexStart>
              <Label>Address line 2</Label>
              <AntdInput
                placeholder="Enter your address"
                name={'addressLine2'}
                rules={[
                  {
                    required: false,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('address 2'),
                  },
                  {
                    pattern: CONSTANTS.REGEX.ADDRESS2,
                    message: CONSTANTS.ERROR_MESSAGE.VALID('address 2'),
                  },
                ]}
              />
            </Control>

            <Control flexStart row width="552px" gap="52px">
              <Control flexStart column>
                <Label>Country *</Label>
                <AntdInput name={'country'} disabled={true} width="250px" />
              </Control>
              <Control flexStart column>
                <Label>State *</Label>
                <AntdInput name={'state'} disabled={true} width="250px" />
              </Control>
            </Control>
            <Control flexStart row width="552px" gap="52px">
              <Control flexStart column>
                <Label>City *</Label>
                <AntdInput name={'city'} disabled={true} width="250px" />
              </Control>

              <Control flexStart column>
                <Label>Pincode *</Label>
                <AntdInput
                  width="250px"
                  name={'postalCode'}
                  type={'number'}
                  disabled={true}
                />
              </Control>
            </Control>

            <Control top="35px">
              <Heading>Institute Additional Details</Heading>
            </Control>

            <Control column flexStart>
              <Label>One Liner</Label>
              <AntdInput
                placeholder="Add short description about company"
                name={'shortDescription'}
                maxLength={300}
                rules={[
                  {
                    required: false,
                    message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('description'),
                  },
                ]}
              />
            </Control>

            <Control top="20px" flexStart column width="552px">
              <Label>About Institute</Label>
              <FormItem name={'longDescription'}>
                <RichEditor
                  value={longDescription}
                  onChange={onChangeDescription}
                />
              </FormItem>
            </Control>

            <Control top="20px" flexStart column width="552px">
              <FormItem
                name={'institutePresentationLink'}
                rules={[
                  {
                    required: false,
                    message: 'Please upload Contract!',
                  },
                ]}
              >
                <Label>Institute Presentation (If Any)</Label>
                {institutePresentation ? (
                  <Control column top="10px" center>
                    <FileReader
                      file={institutePresentation}
                      setFile={onChangePresentationLink}
                    />
                  </Control>
                ) : (
                  <Control column top="10px" center>
                    <Uploadloader
                      setFile={onChangePresentationLink}
                      isDefault={true}
                      fileName={'document'}
                    />
                  </Control>
                )}
              </FormItem>
            </Control>
            <Control flexStart column>
              <Label>Institute Video Link (If Any)</Label>
              <AntdInput
                id={'VideoLink'}
                placeholder="Eg. www.youtube.com/result?search_query=about+lim"
                name={'instituteVideoLink'}
                rules={[
                  {
                    pattern: CONSTANTS.REGEX.WEB_URL,
                    message: CONSTANTS.ERROR_MESSAGE.VALID('VideoLink'),
                  },
                ]}
                suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
              />
            </Control>
          </Control>
        </Control>
      </Control>
    </>
  )
}

export default InstituteInfoForm
