import { Divider, Empty } from 'antd'
import Button from 'components/Button/index'
import CloseFilter from 'components/icons/CloseFilter'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { Form } from 'components/UIComponents/FormStyles'
import ReportSearchFilter from 'components/UIComponents/ReportSearchFilter'
import {
  Content,
  Footer,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import {
  arrayIntoString,
  RefinedData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessageReport from 'utils/WarningMessageReport'
import { setTopBottomCorporateDetails } from '../../../../../actions'
import TopCityFilter from '../CorporateFilter/Partials/TopCityFilter'
import TopDateRangeFilter from '../CorporateFilter/Partials/TopDateRangeFilter'
import { industrySectorPart } from 'modules/Reports/Partials/Students/Partials/Function/Function.js'
import StateFilter from '../../CorporateInfoTable/CorporateFilter/Partials/StateFilter'

const Top10CorporateTable = ({
  setLoadingCity,
  loadingCity,
  loading,
  loadingTopCorp,
  setPageNum,
  tableData,
  setSortBy,
  searchValue,
  setSearchValue,
  sectorValue,
  cityValue,
  setCityValue,
  industryValue,
  setIndustryValue,
  setFilterData,
  filterData,
  isReport,
  industryList,
  stateValue,
  cityList,
  setKeyIndustry,
  keyIndustry,
  keyCity,

  setKeyCity,
  createdAtEnd,
  createdAtStart,
  setCreatedAtEnd,
  setCreatedAtStart,
  isEmpanelled,
  pageNum,
  setClearDateTop,
  clearDateTop,
  tooltiptop,
  setToolTipTop,
  hasProp,
  industrySectorData,
  IndustrySectorUrl,
  loadMoreCityData,
  cityCount,
  stateCount,
  loadMoreStateData,
  statePage,
  cityPage,
  singleSectorLoading,
  setKeySingleSector,
  singleSectorValue,
  setSingleSectorValue,
  keySingleSector,
  singleSectorData,
  singleSectorDataFinal,
  singleSectorCount,
  loadMoreSingleSectorData,
  setSingleSectorPage,
  singleSectorPage,
  singleIndustryPage,
  setSingleIndustryPage,
  singleIndustryDataFinal,
  singleIndustryCount,
  loadMoreSingleIndustryData,
  singleIndustryData,
  singleIndustryValue,
  setSingleIndustryValue,
  keySingleIndustry,
  singleIndustryLoading,
  setKeySingleIndustry,
  getSingleSectorList,
  setSingleSectorLoading,
  getSingleIndustryList,
  setSingleIndustryLoading,
  getCityList,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const [flag, setFlag] = useState(false)
  const [form] = Form.useForm()
  const [searchDegree] = useState('')
  const [keyDegree] = useState('')
  const [debouncedDegree, setDebouncedDegree] = useState(() => keyDegree)
  const submitFilter = () => {
    let filterDatas = {
      sector: arrayIntoString(singleSectorValue),
      city: arrayIntoString(cityValue),
      industry: arrayIntoString(singleIndustryValue),
      createdAtStart: createdAtStart,
      createdAtEnd: createdAtEnd,
    }

    if (createdAtStart) {
      if (!createdAtEnd) {
        WarningMessageReport(
          <>
            Kindly select <q>To Date</q> from Date Range
          </>
        )
      }
    }
    if (createdAtEnd) {
      if (!createdAtStart) {
        WarningMessageReport(
          <>
            Kindly select <q>From date</q> from Date Range
          </>
        )
      }
    }

    if (createdAtEnd && createdAtStart) {
      setFilterData(filterDatas)
    }
    if (!createdAtEnd && !createdAtStart) {
      setFilterData(filterDatas)
    }

    setToolTipTop(!hasProp)
  }

  const onCancelFilter = () => {
    setFlag(true)
    setFilterData({
      industry: '',
      city: '',
      createdAtEnd: '',
      createdAtStart: '',
    })

    dispatch(setTopBottomCorporateDetails())
    setCityValue([])
    setIndustryValue([])
    setClearDateTop(true)
    setSingleSectorValue([])
    setSingleIndustryValue([])
    getSingleSectorList({
      setSingleSectorLoading,
      keySingleSector: '',
      flag: 'TC',
      page: 0,
    })
    getSingleIndustryList({
      setSingleIndustryLoading,
      keySingleIndustry: '',
      flag: 'TC',
      page: 0,
    })
    getCityList({ setLoadingCity, keyCity: '', flag: 'TC' })
  }

  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }

  const onPageChange = num => {
    setPageNum(num)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'prefix_id',
      key: 'prefix_id',
      visible: true,
      align: 'center',
      //width: '3%',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'CORPORATES NAME',
      dataIndex: 'b.name',
      //width: '5%',
      key: 'b.name',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'b.industry',
      //width: '5%',
      sorter: true,
      key: 'b.industry',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.industry)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'NO.OF CANDIDATES HIRED',
      dataIndex: 'studentCount',
      //width: '5%',
      sorter: true,
      align: 'center',

      key: 'studentCount',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.studentcount)}
        </RowNumberDataReportAlignCenter>
      ),
      visible: true,
    },
    {
      title: 'LOCATION',
      dataIndex: 'b.city',
      //width: '5%',
      sorter: true,
      key: 'b.city',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.city)}
        </RowNumberDataReport>
      ),
    },
  ]

  useEffect(() => {
    let flag = 'TB'

    IndustrySectorUrl(flag, searchDegree, 0)
  }, [debouncedDegree])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDegree(searchDegree)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchDegree])
  useEffect(() => {
    industrySectorPart({
      listOfDegree: industrySectorData?.result,
      selectedIds: [],
      isEditActiveRole: [],
      childValueParam: 'sectorindustryid',
      childNameParam: 'industry',
      parentNameParam: 'sector',
      parentValueParam: 'sector_id',
      pathName: 'industry',
    })
  }, [industrySectorData])

  return (
    <Wrapper>
      <ReportSearchFilter
        setOpen={setOpen}
        open={open}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={isReport}
        totalCount={tableData?.count}
        currentCount={10 * (pageNum - 1) + tableData?.result?.length}
        setPageNum={setPageNum}
      />
      <Divider style={{ margin: '0' }} />

      <Content isOpen={open}>
        <div style={{ width: '100%', display: 'flex' }}>
          <Wrapper style={{ marginLeft: '1rem' }}>
            <StateFilter
              setFlag={setFlag}
              flag={flag}
              stateList={singleSectorDataFinal || []}
              stateValue={singleSectorValue}
              setStateValue={setSingleSectorValue}
              loading={singleSectorLoading}
              setKeyState={setKeySingleSector}
              keyState={keySingleSector}
              isReport={true}
              stateCount={singleSectorCount}
              loadMoreStateData={loadMoreSingleSectorData}
              statePage={singleSectorPage}
              name="Sector"
            />
          </Wrapper>

          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper style={{ marginLeft: '1rem' }}>
            <StateFilter
              setFlag={setFlag}
              flag={flag}
              stateList={singleIndustryDataFinal || []}
              stateValue={singleIndustryValue}
              setStateValue={setSingleIndustryValue}
              loading={singleIndustryLoading}
              setKeyState={setKeySingleIndustry}
              keyState={keySingleIndustry}
              isReport={true}
              stateCount={singleIndustryCount}
              loadMoreStateData={loadMoreSingleIndustryData}
              statePage={singleIndustryPage}
              name="Industry"
            />
          </Wrapper>

          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />

          <TopCityFilter
            setFlag={setFlag}
            flag={flag}
            cityList={cityList}
            cityValue={cityValue}
            setCityValue={setCityValue}
            loading={loading}
            search={searchValue}
            keyCity={keyCity}
            setKeyCity={setKeyCity}
            isReport={isReport}
            setLoadingCity={setLoadingCity}
            loadingCity={loadingCity}
            cityPage={cityPage}
            loadMoreCityData={loadMoreCityData}
            cityCount={cityCount}
          />
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <TopDateRangeFilter
            isEmpanelled={isEmpanelled}
            setClearDateTop={setClearDateTop}
            clearDateTop={clearDateTop}
            createdAtStart={createdAtStart}
            createdAtEnd={createdAtEnd}
            setCreatedAtStart={setCreatedAtStart}
            setCreatedAtEnd={setCreatedAtEnd}
            form={form}
          />

          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
        </div>
      </Content>
      <Divider style={{ margin: '0' }} />

      <Footer style={{ display: open ? '' : 'none' }}>
        <div
          style={{ marginRight: '300px' }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpen(false)
          }}
          onKeyDown={() => {}}
        >
          <CloseFilter style={{ marginBottom: '-40px' }} />
        </div>
        <div style={{ marginRight: '30px' }}>
          <Button.Secondary
            height="35px"
            htmlType="Clear"
            text={'Clear Filter'}
            width={'144px'}
            onClick={() => onCancelFilter()}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          {/* <MarginLeft>
              <Tooltip
                placement="topLeft"
                title={
                  !tooltiptop
                    ? 'Kindly Select Filter Option Before Submitting the Data'
                    : ''
                }
              > */}
          <Button.Primary
            height="35px"
            htmlType="Save Filter"
            text={'Apply'}
            width={'144px'}
            onClick={() => submitFilter()}
          />
          {/* </Tooltip>
                </MarginLeft> */}
        </div>
      </Footer>

      <Divider style={{ margin: '0' }} />
      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        locale={{
          emptyText: (
            <Empty
              description={
                !hasProp
                  ? 'Kindly Select Filter Option to View the Data'
                  : 'No Corporate Found'
              }
            />
          ),
        }}
        loading={loadingTopCorp}
        scroll={{
          x: 2000,
          y: 500,
        }}
      />

      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={pageNum}
      />
    </Wrapper>
  )
}

export default Top10CorporateTable
