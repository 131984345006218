import { Form as AntdForm, Col, Divider, Input, Menu, Row, Tabs } from 'antd'
import Control from 'components/Control/Control'
import Flex from 'components/Flex'
import AntdInput from 'components/Form/Input'
import styled from 'styled-components'
import { FONT } from 'theme/font'
import { COLORS } from 'theme/index'

export const StyledTabs = styled(Tabs)`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 21px;
  .ant-tabs-nav-list {
    border-right: ${props =>
    props.border ? `1px solid ${COLORS.GREY_T_90}` : 'none'};
  }
  .ant-tabs-nav {
    width: 100% !important;
  }
  .ant-tabs-tab {
    padding: 15px 20px !important;
    margin-top: 0 !important;
    color: ${COLORS.GREY_T_60};
    background: ${COLORS.GREY_T_98} !important;
    border-radius: 0 !important;
    border-bottom: 1px solid ${COLORS.GREY_T_90} !important;
  }
  .ant-tabs-tab-active {
    background: ${COLORS.WHITE} !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => (props.textColor ? props.textColor : COLORS.GREY_T_30)};
  }
`

export const SplitFlex = styled(Flex)`
  padding: 20px 5px;
  position: relative;
  width: 100%;
`

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  width: 100%;
  .ant-form-item-explain-error {
    padding-left: ${props => (props.padding ? '15%' : '0')};
  }
`
export const SectionWrapper = styled(Flex)`
  width: 100%;
  height: 650px;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  border-radius: 10px;
  overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
`

export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const TabDiv = styled.div`
  padding: 0px 20px 20px 20px;
`

export const ImageUploadWrapper = styled(Flex)`
  background: ${COLORS.BLUE_T_97};
  border: 1px dashed #98a4eb;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  ${ImageUploadWrapper} > ${Control} > div {
    font-weight: ${FONT.FW_500};
    font-size: 8px;
    line-height: 8px;
    color: ${COLORS.GREY_T_80};
  }
`
export const UploadDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.PRIMARY};
`
export const ImageTag = styled.img`
  border-radius: 50%;
  height: 110px;
  width: 110px;
`

export const UploadText = styled.div`
  font-weight: ${FONT.FW_600};
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
`
export const FileInput = styled.input`
  opacity: 0;
  display: block;
  width: 100px;
  position: relative;
  top: -30px;
`
export const StyledDivider = styled(Divider)`
  margin: 10px !important;
`

export const AddIcon = styled.div`
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
`
export const InputLabel = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.PRIMARY_GREY_T_30};
`

export const ButtonWrap = styled(Flex)`
  margin-left: 45px;
`

export const ImageColPadding = styled(Col)`
  padding: 10px;
`

export const ButtonCenterPadding = styled(Col)`
  padding-top: 50px;
`
export const RowPadding = styled(Row)`
  padding: 15px;
`
export const LabelColPadding = styled(Col)`
  padding-top: 7px;
`
export const StyledSelectDiv = styled(Flex)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0;
  }
`

export const StyledInput = styled(AntdInput)`
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
`
export const StyledInputDiv = styled(Flex)`
  margin-bottom: 20px !important;
  align-items: baseline;
  width: 100%;
  border-radius: 8px;
  .ant-inputselect:not(.ant-select-customize-input) .ant-select-selector {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`
export const MessageDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: 14px;
  line-height: 18px;
  padding: 20px;
  color: ${COLORS.GREY_T_60};
`
export const PasswordInput = styled(Input)`
  :placeholder-shown {
    background: ${COLORS.PRIMARY_GREY_T_98} !important;
    font-size: ${FONT.F_BASE};
    font-weight: ${FONT.FW_500};
    line-height: 24px;
    color: ${COLORS.GREY_P_100};
  }
  height: 48px
  border-radius: 10px;
  box-shadow: none;
  border-color: ${props => (props.error ? 'red' : '#e9e9e9')};
  :focus {
    box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
    border-radius: 10px;
    border: 1px solid ${COLORS.BLUE_T_80};
  }
  :hover {
    border-color: ${COLORS.BLUE_T_80};
  }
  :not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${COLORS.BLUE_T_80} !important;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
    border-right-width: 0px !important;
  }
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_BASE};
  line-height: 24px;
  color:${COLORS.GREY_P_100};
`
export const ChangeSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3249d7;
  cursor: pointer;
`
export const ImageFlex = styled(Flex)`
  gap: 10px;
`

export const StyledDividerTab = styled(Divider)`
  margin: 0px !important;
`
export const StyledMenu = styled(Menu)`
  padding: 10px;
  .ant-menu-item-selected {
    background-color: #eaedfb !important;
    font-weight: 600;
    border-radius: 10px;
  }
`
export const StyledSpan = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #3249d7;
  cursor: pointer;
`

export const Content = styled(Flex)`
  height: auto;
  width: 100%;
  gap: 20px;
  border-radius: 10px;
  padding-bottom: 10px;
`

export const MailHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #010e30;
`

export const MailSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4d566e;
`

export const PaddedContainer = styled.div`
  padding: 20px 10px;
`

export const DisplayPad = styled.div`
  width: 100%;
  padding: 20px 0px;
`

export const Learn = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.PRIMARY};
`

export const Span = styled.span`
  color: red;
`

export const OTP = styled(Flex)`
  margin-top: 10px;
  gap: 10px;
  width: 100%;
`

export const OTPHeading = styled.div`
  font-weight: 500;
  font-size: 1em;
  line-height: 18px;
  color: #4d566e;
`
export const InputGroup = styled(Flex)`
  gap: 10px;
`

export const OTPButton = styled.div`
  font-weight: 400;
  font-size: 1.0714285714285714em;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #1b1f22;
  word-spacing: 1px;
  cursor: pointer;
`

export const OTPMessage = styled(Flex)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  gap: 10px;
  border-radius: 10px;
  padding: 8px;
  background: #eff8f4;
`
export const SpanBold = styled.span`
  font-weight: 500;
`
export const StyledB = styled.b`
  margin-left: 4px;
`
