import React from 'react'

const ActiveUsers = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="m16.12 18.57 3.172-3.172-.63-.63-2.542 2.542-1.283-1.282-.63.63 1.913 1.912Zm.63 2.43a4.38 4.38 0 0 1-1.755-.355 4.543 4.543 0 0 1-1.429-.961 4.542 4.542 0 0 1-.961-1.429 4.38 4.38 0 0 1-.355-1.755c0-.623.118-1.207.355-1.755a4.541 4.541 0 0 1 .961-1.429 4.548 4.548 0 0 1 1.429-.962A4.384 4.384 0 0 1 16.75 12a4.39 4.39 0 0 1 1.755.354 4.548 4.548 0 0 1 1.429.962c.405.405.725.881.961 1.429a4.38 4.38 0 0 1 .355 1.755 4.38 4.38 0 0 1-.355 1.755 4.543 4.543 0 0 1-.961 1.429 4.543 4.543 0 0 1-1.429.961A4.38 4.38 0 0 1 16.75 21Zm0-.9c1.005 0 1.856-.349 2.554-1.046.697-.698 1.046-1.549 1.046-2.554s-.349-1.856-1.046-2.554c-.698-.697-1.549-1.046-2.554-1.046s-1.856.349-2.554 1.046c-.697.698-1.046 1.549-1.046 2.554s.349 1.856 1.046 2.554c.698.697 1.549 1.046 2.554 1.046Zm-6.5-8.1c-1.1 0-2.042-.392-2.825-1.175C6.642 10.042 6.25 9.1 6.25 8s.392-2.042 1.175-2.825C8.208 4.392 9.15 4 10.25 4s2.042.392 2.825 1.175C13.858 5.958 14.25 6.9 14.25 8s-.392 2.042-1.175 2.825C12.292 11.608 11.35 12 10.25 12Zm-7 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2.25 19v-1.8c0-.55.142-1.067.425-1.55.283-.483.675-.85 1.175-1.1.85-.433 1.808-.8 2.875-1.1 1.067-.3 2.242-.45 3.525-.45h.35c.1 0 .2.017.3.05-.133.3-.246.613-.338.938A7.82 7.82 0 0 0 10.35 15c-.083.6-.108 1.104-.075 1.512.033.409.125.905.275 1.488.1.35.233.696.4 1.038.167.341.35.662.55.962H3.25Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default ActiveUsers
//mask="url(#mask0_207_364)"
