import React from 'react'
import { TabItem, TabsWrapper } from './Style/style'
const UserTabs = ({ selected, selectedType, count = 0 }) => {
  const selectiontype = selected

  return (
    <TabsWrapper>
      <TabItem
        isActive={selectiontype === 'user'}
        onClick={() => {
          selectedType('user')
        }}
      >
        Users
      </TabItem>
      <TabItem
        isActive={selectiontype === 'role'}
        onClick={() => {
          selectedType('role')
        }}
      >
        Roles and Permissions
      </TabItem>
    </TabsWrapper>
  )
}

export default UserTabs
