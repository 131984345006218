import { ExclamationCircleFilled } from '@ant-design/icons'
import { Col, Empty, Form, Modal, Row } from 'antd'
import Button from 'components/Button/index'
import Pagination from 'components/Pagination'
import { Wrapper } from 'components/TableStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import PlusIcon from 'components/icons/PlusIcon'
import DeleteIcon from 'components/icons/delete.svg'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Avatar from '../../../.././components/Avatar'
import Divider from '../../../../components/Divider/Divider'
import { FlexWrap } from '../../../../components/TableStyles/TableContentStyles'
import { toCamelCase } from '../../../../utils/camelCase'
import AddCorporatesDrawer from './AddCorporatesDrawer'
import CorporateFilter from './CorporateFilter'
import {
  AlertText,
  RowNumberData,
  StyledAlert,
  TableHeadAlign,
} from './Style/style'

const { confirm } = Modal

const Corporate = ({
  loc,
  userData,
  getListOfState,
  corporateViewData,
  FunctionTableList,
  updateCorporate,
  createCorporate,
  getCorporate,
  getCorporatesView,
  corporatejourneyData,
  deleteUser,
  getRoles,
  getListOfCity,
  isCorpAccess,
  getCorporateMasterData,
  singleUserData,
}) => {
  const [addUserDrawer, setAddUserDrawer] = useState(false)
  const [selectedRows, setSelectedRows] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [deletedRows, setDeletedRows] = useState(false)
  const [creatId, setCreatId] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [pageNum, setPageNum] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState('')
  const [industry, setIndustry] = useState('')
  const [deleteUserId, setDeleteUserId] = useState([])

  const [form] = Form.useForm()
  const params = useParams()
  let pathArray = loc?.pathname.split('/')
  let userId = pathArray[2]
  useEffect(() => {
    if (singleUserData?.userType) getcoporateData()

    setCreatId(params.id)
    // getSingleUser({ userId })
  }, [params.id, userId, searchValue, sortBy, pageNum, location])
  useEffect(() => {
    if (singleUserData?.userType) getcoporateData()
  }, [singleUserData])
  const getcoporateData = async () => {
    setLoading(true)
    const response = await getCorporate(
      params.id,
      searchValue,
      sortBy,
      pageNum,
      location,
      singleUserData?.userType
    )

    setLoading(response)
  }

  useEffect(() => {
    setCurrentPage()
  }, [pageNum, searchValue, sortBy, creatId])

  useEffect(() => {
    let searchValue = ''
    let userId = params.id
    getCorporatesView({ userId, searchValue })
    setCreatId(params.id)
    getRoles('CORPORATE', false, false, true)
  }, [userId])
  const onAddCorporate = () => {
    setAddUserDrawer(true)
    form.resetFields()
  }

  const onSorting = (pagination, filter, sorter) => {
    setSortBy(sorter)
  }

  const callback = () => {
    getcoporateData()
  }

  const showConfirm = async instCorpMap => {
    if (instCorpMap) {
      confirm({
        title: 'Do you want to Un-Link this Corporate ?',
        icon: <ExclamationCircleFilled />,
        content: '',
        async onOk() {
          const payload = {
            instCorpMapId: instCorpMap,
            journey: 'Corporate',
          }
          const response = await deleteUser(payload, { callback })
          if (response) {
            setSelectedRows([])
            setSelectedRowKeys([])
            setDeletedRows([])
            setDeleteUserId([])
            await getcoporateData()
            setSelectedRows([])
            setSelectedRowKeys([])
            setDeletedRows([])
            setDeleteUserId([])
          }
        },
        onCancel() {},
      })
    }
  }

  const columns = [
    {
      title: 'Companys Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_, rowData) => (
        <FlexWrap>
          <Avatar
            background
            src={rowData?.logo}
            textTransform="capitalize"
            marginRight={'10px'}
            size={35}
            IconName={rowData?.name?.split('')[0]}
            font={18}
          />

          <RowNumberData style={{ alignSelf: 'center', paddingLeft: '5px' }}>
            {toCamelCase(rowData?.name) || '-'}
          </RowNumberData>
        </FlexWrap>
      ),
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      sorter: true,
      render: rowData => toCamelCase(rowData ?? rowData?.industry) || '-',
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      sorter: true,
      render: rowData => toCamelCase(rowData ?? rowData?.sector) || '-',
    },
    {
      title: 'Location',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      render: rowData => toCamelCase(rowData ?? rowData?.city) || '-',
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <img
          src={DeleteIcon}
          alt="delete"
          onKeyDown={() => {}}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (rowData?.instCorpMapId) showConfirm([rowData?.instCorpMapId])
          }}
        />
      ),
    },
  ]
  const dataChange = (selectedRowKeys, selectedRows) => {
    const id = corporatejourneyData?.list?.filter(item =>
      selectedRowKeys.includes(item.id)
    )
    const filterId = id.map(item => item.instCorpMapId)
    setDeleteUserId(filterId)
    setSelectedRows(selectedRows)
    setDeletedRows(selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: dataChange,
  }
  const onPageChange = key => {
    setPageNum(key - 1)
  }

  const handleClick = async data => {
    // const instCorpMapIds = corporatejourneyData?.list?.map(
    //   item => item?.instCorpMapId
    // )
    const instCorpMapIds = data.map(item => item.instCorpMapId)
    showConfirm(instCorpMapIds)
  }
  return (
    <>
      {singleUserData?.userType?.toUpperCase() != 'ADMIN' && (
        <>
          <TableHeadAlign>
            <FlexWrap gap={'20px'}>
              {!isCorpAccess && (
                <StyledAlert
                  message={
                    <AlertText>
                      Note:-Update the User Role to Map the Corporate
                    </AlertText>
                  }
                  type="warning"
                  showIcon
                />
              )}
            </FlexWrap>
            <buttonAlign>
              <Button.Primary
                text="Add"
                disabled={!isCorpAccess}
                icon={isCorpAccess ? <PlusIcon /> : null}
                onClick={onAddCorporate}
              />
            </buttonAlign>
          </TableHeadAlign>

          <div span={1}>
            {selectedRows?.length > 1 ? (
              <img
                src={DeleteIcon}
                alt="delete"
                onKeyDown={() => {}}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClick(selectedRows)
                }}
              />
            ) : null}
          </div>
          <br />
        </>
      )}
      <Wrapper>
        {corporatejourneyData ? (
          <>
            <CorporateFilter
              searchValue={searchValue}
              currentPage={currentPage}
              setSearchValue={setSearchValue}
              location={location}
              getCorporatesView={getCorporatesView}
              industry={industry}
              setLocation={setLocation}
              getListOfState={getListOfState}
              setIndustry={setIndustry}
              getcoporateData={getcoporateData}
              corporatejourneyData={corporatejourneyData}
              pageNum={pageNum}
              setPageNum={setPageNum}
              setCurrentPage={setPageNum}
            />
            <Divider mt="0" />
            <StyledTable
              columns={columns}
              pagination={false}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              rowKey="id"
              dataSource={
                singleUserData?.roles?.journeys?.includes('CORPORATE')
                  ? corporatejourneyData?.list
                  : []
              }
              onChange={onSorting}
              locale={{
                emptyText: (
                  <Row align={'center'}>
                    <Col span={5}>
                      <Empty description={'No Corporate Found !'} />
                    </Col>
                  </Row>
                ),
              }}
            />
            {corporatejourneyData?.list && (
              <Pagination
                onChange={onPageChange}
                totalPages={corporatejourneyData?.count}
                pageSize={corporatejourneyData?.limit}
                current={pageNum + 1}
              />
            )}
          </>
        ) : (
          <Row align={'center'}>
            <Col span={5}>
              <Empty description={'No Data Found !'} />
            </Col>
          </Row>
        )}
      </Wrapper>

      <AddCorporatesDrawer
        addUserDrawer={addUserDrawer}
        deleteUser={deleteUser}
        loc={loc}
        updateCorporate={updateCorporate}
        getCorporatesView={getCorporatesView}
        createCorporate={createCorporate}
        getListOfState={getListOfState}
        FunctionTableList={FunctionTableList}
        getCorporateMasterData={getCorporateMasterData}
        creatId={creatId}
        userData={userData}
        setAddUserDrawer={setAddUserDrawer}
        getcoporateData={getcoporateData}
        loading={loading}
        setLoading={setLoading}
        setLocation={setLocation}
        corporateViewData={corporateViewData}
        form={form}
        getListOfCity={getListOfCity}
      />
    </>
  )
}

export default Corporate
