import Button from 'components/Button/index'
import VirtualizedCheckboxGroup from 'components/UIComponents/Filter/index'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Dashboard/Partials/UsersFilter/Styles/style'
import { StateCityMessage } from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import React, { useEffect, useState } from 'react'
import { getCorporateBasedPlaces } from '../../actions.js'
import StatusFilter from './StatusFilter.js/index.js'
import OnboardingStatusFilter from './StatusFilter.js/onboardingStatus.js'
const { COUNTRY_ID } = process.env
const CorporateFilter = ({
  corporateList,
  currentPage,
  searchValue,
  setSearchValue,

  setCorporateActiveStatus,
  stateId,
  setStateId,
  stateList,
  setFilterData,
  filterData,
  setStateList,
  stateNames,
  setStateNames,
  getListOfState,
  cityList,
  setCityList,
  cityName,
  setCityName,
  getListOfCity,
  cityId,
  setPermCityId,

  setCorporateStatus,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [degreeSearch, setDegreeSearch] = useState('')
  const [corporateActiveStatusFilter, setCorporateActiveStatusFilter] =
    useState([])
  const [corporateStatusFilter, setCorporateStatusFilter] = useState([])
  const [loading, setLoading] = useState(false)
  const [check, setCheck] = useState(false)

  const fetchCorporateBasedPlaces = async (
    groupBy = 'state',
    search = '',
    stateId = '',
    loading = true
  ) => {
    try {
      setLoading(loading)
      const getCorporateData = getCorporateBasedPlaces({
        pageLimit: 50,
        groupBy: groupBy,
        search: search,
        stateId: stateId,
      })
      const response = await getCorporateData()
      if (groupBy === 'state') {
        setStateList(response?.result || [])
      } else {
        setCityList(response?.result || [])
      }
    } catch (err) {
      console.error('Failed to fetch corporate data:', err)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  useEffect(() => {
    fetchCorporateBasedPlaces('state', '')
    fetchCorporateBasedPlaces('city', '')
  }, [])

  useEffect(() => {
    setPermCityId('')
    setCityName('')
    let list = stateList?.filter(item => stateId?.includes(item.state))
    let stateIdList = list?.map(item => item.stateId)?.join(',')
    fetchCorporateBasedPlaces('city', '', stateIdList, false)
  }, [stateId])

  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      status: corporateActiveStatusFilter,
      stateNames: stateNames || [],
      cityName: cityName,
      stateId: stateId,
      cityId: cityId,
      onboardingStatus: corporateStatusFilter,
    })

    setCorporateActiveStatus(corporateActiveStatusFilter)
    setCorporateStatus(corporateStatusFilter)
    fetchCorporateBasedPlaces('state', '')
    fetchCorporateBasedPlaces('city', '')
  }

  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setCorporateActiveStatus(filterData?.status)
    setCorporateActiveStatusFilter(filterData?.status)
    setCorporateStatus(filterData?.onboardingStatus)
    setCorporateStatusFilter(filterData?.onboardingStatus)
  }

  useEffect(() => {
    setCheck(prev => !prev)
    setStateId(filterData?.stateId || [])
    setStateNames(filterData?.stateNames || [])
    setPermCityId(filterData?.cityId || [])
    setCityName(filterData?.cityName || [])
  }, [isMenuOpen])

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'Status',
            key: '1',
            children: (
              <StatusFilter
                corporateActiveStatus={corporateActiveStatusFilter}
                setCorporateActiveStatus={setCorporateActiveStatusFilter}
              />
            ),
          },
          {
            label: 'Onboarding Status',
            key: '2',
            children: (
              <OnboardingStatusFilter
                corporateStatusFilter={corporateStatusFilter}
                setCorporateStatusFilter={setCorporateStatusFilter}
              />
            ),
          },
          {
            label: 'State',
            key: '3',
            children: (
              <VirtualizedCheckboxGroup
                options={stateList}
                value={stateId}
                setValue={setStateId}
                valueParam="state"
                labelParam="state"
                setStateName={setStateNames}
                filterId={true}
                stateName={stateNames}
                loading={loading}
                setLoading={setLoading}
                degreeSearch={degreeSearch}
                setDegreeSearch={setDegreeSearch}
                check={check}
                getStateData={fetchCorporateBasedPlaces}
                labelType={'state'}
              />
            ),
          },
          {
            label: 'City',
            key: '4',
            children: (
              <>
                {stateId?.length ? (
                  <VirtualizedCheckboxGroup
                    options={cityList}
                    value={cityId}
                    setValue={setPermCityId}
                    valueParam="city"
                    labelParam="city"
                    filterId={true}
                    setStateName={setCityName}
                    stateName={cityName}
                    loading={loading}
                    setLoading={setLoading}
                    degreeSearch={degreeSearch}
                    setDegreeSearch={setDegreeSearch}
                    check={check}
                    labelType={'city'}
                    getStateData={fetchCorporateBasedPlaces}
                  />
                ) : (
                  <StateCityMessage>
                    Kindly select a state before choosing a city.
                  </StateCityMessage>
                )}
              </>
            ),
          },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )

  return (
    <TableSearchFilter
      placeholder={'Search by Company'}
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      currentCount={10 * (currentPage - 1) + corporateList?.list?.length}
      totalCount={corporateList?.count}
      onOpenChange={onCancelFilter}
    />
  )
}

export default CorporateFilter
