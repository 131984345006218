import styled from 'styled-components'
import { COLORS } from 'theme'
import Flex from 'components/Flex'
import { FONT } from 'theme/font'
import { Dropdown, Button } from 'antd'
export const DropDown = styled(Dropdown)``
export const DropBtn = styled(Button)`
  .ant-btn {
    background: ${COLORS.PRIMARY_GREY_T_98} !important;
  }
`
export const PaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 25px;
`
export const SectionWrapper = styled(Flex)`
  width: 100%;
  overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
`
export const TopHeader = styled(Flex)`
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const RoleNameFlex = styled(Flex)``
export const TopEndFlex = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`
export const LeftSection = styled(Flex)`
  gap: 20px;
`
export const SectionName = styled(Flex)`
  gap: 10px;
`
export const RoleNameDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: ${FONT.F_XL};
  color: ${COLORS.GREY_T_20};
  margin: 10px 0 10px;
`
export const InterviewDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 18px;
  color: ${COLORS.GREY_T_30};
  margin: 0 10px 0 0;
`
export const ClockDiv = styled.div`
  margin: 0 20px;
  border: 1px solid ${COLORS.GREY_T_90};
  padding: 9px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
`
export const ButtonDiv = styled.div`
  padding: 0 20px 0 0;
  border-right: 1px solid ${COLORS.GREY_T_90};
`
export const ThreeDotsDiv = styled.div`
  cursor: pointer;

  .ant-btn {
    background: ${COLORS.PRIMARY_GREY_T_98} !important;
    border-radius: 10px !important;
    border: 1px solid ${COLORS.GREY_T_90} !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
`
export const SectinFlex = styled(Flex)``
export const DriveDetailDiv = styled.div`
  width: 68%;
  margin-right: 70px;
`
export const DrivInfoDiv = styled.div`
  width: 40%;
`
export const AntdSpan = styled.span`
  margin-right: 0.5px;
`
export const VerticalTableContent = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #999fac;
  border-bottom: 1px solid ${COLORS.GREY_T_90};
  width: 210px;
  padding: 20px;
  background: ${props => (props.backgroundColor ? props.backgroundColor : ' ')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  color: ${props => (props.color ? props.color : '#3249d7')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  width: 'max-content';

  // &:hover {
  //   background: #eaedfb;
  //   border-radius: 10px;
  //   color: #3249d7;
  //   font-size: 16px;
  //   font-weight: 600;
  // }
`
export const CorporateVerticalContent = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #999fac;
  border-bottom: ${props => props.borderBottom} solid ${COLORS.GREY_T_90};
  // width: 210px;
  padding: 20px;
  background: ${props => (props.backgroundColor ? props.backgroundColor : ' ')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  color: ${props => (props.color ? props.color : '#3249d7')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  width: 'max-content';
  // &:hover {
  //   background: #eaedfb;
  //   border-radius: 10px;
  //   color: #3249d7;
  //   font-size: 16px;
  //   font-weight: 600;
  // }
`
export const StateCityMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 106px;
`
