import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'

export const SysSingleCard = styled(Flex)`
  padding: 15px 15px;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background-color: #3249d7;
  }

  &:hover {
    path {
      // stroke: white;
      fill: white;
    }
  }
`

export const SysCardText = styled.p`
  margin-bottom: 4px;
  font-style: normal;
  font-weight: ${FONT?.FW_500};
  font-size: ${FONT?.F_XS};
  line-height: 15px;
  color: ${COLORS.GREY_T_60};
`
export const SysCardValue = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: ${FONT?.FW_700};
  font-size: ${FONT.F_XL};
  line-height: 28px;
  color: ${COLORS.GREY_T_20};
`
