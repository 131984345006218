import React from 'react'

const StateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      style={{ margin: 'auto' }}
    >
      <path
        d="M1.71664 10.464H3.38944V21.216H5.89937V12H8.40929V21.216H10.9187V12H13.4291V21.216H15.939V10.464H17.6114C18.0736 10.464 18.4485 10.1203 18.4485 9.69598C18.4485 9.43966 18.3102 9.21406 18.0995 9.07486L18.101 9.07342L18.0885 9.06622L18.0669 9.05326L10.0821 4.92094V3.71038C11.5566 4.38814 12.7706 3.22078 14.6829 3.92254V1.24846C12.7696 0.5467 11.5562 1.7131 10.0821 1.03582V0.86398C10.0821 0.65134 9.89536 0.47998 9.664 0.47998C9.43264 0.47998 9.2464 0.65134 9.2464 0.86398V4.92094L1.2616 9.0523L1.24 9.06526L1.228 9.07342V9.07486C1.01824 9.21406 0.880485 9.44014 0.880485 9.69598C0.880005 10.1203 1.25488 10.464 1.71664 10.464V10.464ZM2.5528 21.9845L0.880005 23.52H18.449L16.7752 21.9845H2.5528Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default StateIcon
