const instituteListReportData = state =>
  state?.InstituteReports?.getInstituteListForReport
const universityListData = state =>
  state?.InstituteReports?.getUniversityListForReport
const cityListData = state => state?.InstituteReports?.getCityListForReport
const tierListData = state => state?.InstituteReports?.getTierListForReport
const topInstituteData = state => state?.InstituteReports?.getTopInstituteList
const excelData = state => state?.InstituteReports?.excelData
const selectors = {
  instituteListReportData,
  universityListData,
  cityListData,
  tierListData,
  topInstituteData,
  excelData,
}

export default selectors
