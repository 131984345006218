import React from 'react'

import { SingleCard, CardText, CardValue } from 'components/InfoCards/style'
import { COLORS } from 'theme'

const InfoCards = ({
  background = COLORS.BLUE_T_95,
  padding = '15px 15px',
  width = 'auto',
  icon,
  text,
  value,
  onClick,
  isSelected = false,
  current,
  state,
}) => {
  return (
    <SingleCard
      background={current && state == current ? COLORS.BLUE_T_80 : background}
      padding={padding}
      width={width}
      column
      flexStart
      onClick={onClick}
    >
      {icon}
      <CardText isSelected={isSelected}>{text}</CardText>
      <CardValue isSelected={isSelected}>{value}</CardValue>
    </SingleCard>
  )
}

export default InfoCards
