import React, { useEffect, useState } from 'react'
import RichTextEditor from 'react-rte'
import styled from 'styled-components'

const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'header-button' },
    { label: 'Italic', style: 'ITALIC', className: 'header-button' },
    { label: 'Underline', style: 'UNDERLINE', className: 'header-button' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal text', style: 'unstyled' },
    { label: 'Heading 1', style: 'header-one' },
    { label: 'Heading 2', style: 'header-two' },
    { label: 'Heading 3', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    {
      label: 'UL',
      style: 'unordered-list-item',
      className: 'header-button',
    },
    { label: 'OL', style: 'ordered-list-item', className: 'header-button' },
  ],
}
const StyledEditor = styled(RichTextEditor)`
  width: 100%;
  font-family: 'Plus Jakarta Sans';
  margin-top: 10px;
  border-radius: 10px;
  > div {
    background: #f9fafa;
    border-radius: 10px;
  }
  .header-button {
    border: none;
  }
  .MuiIconButton-root {
    color: red;
  }
  .public-DraftEditor-content {
    min-height: 250px;
    padding: 0px 9px 10px 9px;
    min-height: 170px;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .IconButton__icon___3YgOS {
    background-size: 22px;
    border-radius: 15px;
  }
  .DraftEditor-editorContainer {
    padding: 10px 0;
    min-height: 170px;
  }
  .EditorToolbar__root___3_Aqz {
    margin: 0;
    padding: 10px 5px 5px 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .DraftEditor-root {
    background: #ffffff;
    border-radius: 10px;
    min-height: 170px;
  }
`

const RichEditor = ({
  onChange,
  value = '',
  placeholder = 'Enter Your Description here...',
}) => {
  const [state, setState] = useState({
    description: value
      ? RichTextEditor.createValueFromString(value, 'html')
      : RichTextEditor.createEmptyValue(),
  })
  const handleOnchange = (val, key) => {
    setState({ [key]: val })
    if (onChange) {
      onChange(val.toString('html'))
    }
  }

  useEffect(() => {
    if (value === '') {
      setState({
        description: value
          ? RichTextEditor.createValueFromString(value, 'html')
          : RichTextEditor.createEmptyValue(),
      })
    }
  }, [value])

  return (
    <div>
      <StyledEditor
        value={state.description}
        toolbarConfig={toolbarConfig}
        onChange={val => handleOnchange(val, 'description')}
        placeholder={placeholder}
      />
    </div>
  )
}

export default RichEditor
