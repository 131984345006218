import { Divider, Empty } from 'antd'
import Button from 'components/Button/index'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import ReportSearchFilter from 'components/UIComponents/ReportSearchFilter'
import {
  Content,
  Footer,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import CloseFilter from 'components/icons/CloseFilter'
import {
  RefinedData,
  arrayIntoString,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setTopInstituteList } from '../../InstEmpanelledTable/actions'
import TopCityFilter from 'modules/Reports/Partials/Corporates/Partials/CorporateInfoTable/CorporateFilter/Partials/CityFilter/index.js'
import TopTierFilter from '../InstituteFilter/Partials/TopTierFilter'
import TopUniversityFilter from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/InstituteFilter/Partials/UniversityFilter/index.js'
const Top10InstTable = ({
  loading,
  loadingTopInst,
  currentCount,
  setCurrentPage,
  searchValue,
  setSearchValue,
  sectorList,
  sectorValue,
  setSectorValue,
  cityValue,
  setCityValue,
  industryValue,
  setIndustryValue,
  setFilterData,
  filterData,
  isReport,
  industryList,
  stateValue,
  cityList,
  tableData,
  setKeySector,
  keySector,
  setKeyIndustry,
  keyIndustry,
  keyCity,
  setKeyCity,
  createdAtEnd,
  createdAtStart,
  setCreatedAtEnd,
  setCreatedAtStart,
  isEmpanelled,
  currentPage,
  setSortBy,
  setToolTip,
  tooltip,
  hasProp,
  loadMoreCityData,
  cityCount,
  stateCount,
  loadMoreStateData,
  statePage,
  cityPage,
  setLoadingUniversity,
  loadingUniversity,
  getUniversityList,
  setLoading,
  getCityList,
  getTierList,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const [flag, setFlag] = useState(false)

  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }
  const onPageChange = num => {
    setCurrentPage(num)
  }

  const submitFilter = () => {
    const modifiedNames = sectorValue.map(name => name?.replace(',', '$'))
    let sector = arrayIntoString(modifiedNames)
    let state = arrayIntoString(stateValue)
    let city = arrayIntoString(cityValue)
    let industry = arrayIntoString(industryValue)
    let startDate = createdAtStart
    let endDate = createdAtEnd
    setFilterData({
      university: sector,
      state: state,
      city: city,
      tier: industry,
      createdAtStart: startDate,
      createdAtEnd: endDate,
    })
    //setToolTip(!hasProp)
  }

  const onCancelFilter = () => {
    setFlag(true)
    setFilterData({
      tier: '',
      city: '',
      university: '',
      state: '',
      createdAtEnd: '',
      createdAtStart: '',
    })

    dispatch(setTopInstituteList())
    setCityValue([])
    setIndustryValue([])
    setSectorValue([])
    getUniversityList({ setLoading, keySector: '', flag: 'TI' })
    getCityList({ setLoading, keyCity: '', flag: 'TI', page: 0 })
    getTierList({ setLoading, keyIndustry: '', flag: 'TI' })
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'a.prefix_id',
      key: 'a.prefix_id',
      visible: true,
      align: 'center',
      sorter: true,
      width: '5%',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'INSTITUTE NAME',
      dataIndex: 'b.name',
      key: 'b.name',
      visible: true,
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.campus_name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'UNIVERSITY',
      dataIndex: 'b.university',
      key: 'b.university',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.university)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'TIER',
      dataIndex: 'tier',
      key: 'tier',
      sorter: true,
      align: 'center',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.tier)}
        </RowNumberDataReportAlignCenter>
      ),
    },

    {
      title: 'NO OF CANDIDATES APPLIED',
      dataIndex: 'studentApplied',
      key: 'studentApplied',
      sorter: true,
      align: 'center',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.studentApplied)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'NO OF CANDIDATES HIRED',
      dataIndex: 'studentCount',
      key: 'studentCount',
      align: 'center',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.studentOffered)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'a.city',
      key: 'a.city',
      align: 'center',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.city)}
        </RowNumberDataReportAlignCenter>
      ),
    },
  ]
  return (
    <Wrapper>
      <ReportSearchFilter
        setOpen={setOpen}
        open={open}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={isReport}
        totalCount={tableData?.count}
        currentCount={10 * (currentPage - 1) + tableData?.result?.length}
      />
      <Divider style={{ margin: '0' }} />
      <Content isOpen={open}>
        <div style={{ width: '100%', display: 'flex' }}>
          <TopUniversityFilter
            flag={flag}
            setFlag={setFlag}
            industryList={industryList}
            sectorValue={sectorValue}
            setSectorValue={setSectorValue}
            setKeySector={setKeySector}
            keySector={keySector}
            loading={loadingUniversity}
            isReport={isReport}
            stateCount={stateCount}
            loadMoreStateData={loadMoreStateData}
            statePage={statePage}
          />
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <TopTierFilter
            flag={flag}
            setFlag={setFlag}
            industryValue={industryValue}
            setIndustryValue={setIndustryValue}
            sectorList={sectorList}
            setKeyIndustry={setKeyIndustry}
            keyIndustry={keyIndustry}
            isReport={isReport}
            loading={loading}
          />
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <TopCityFilter
            flag={flag}
            setFlag={setFlag}
            cityList={cityList}
            cityValue={cityValue}
            setCityValue={setCityValue}
            loading={loading}
            loadingCity={loading}
            keyCity={keyCity}
            setKeyCity={setKeyCity}
            isReport={isReport}
            cityPage={cityPage}
            loadMoreCityData={loadMoreCityData}
            cityCount={cityCount}
          />
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
        </div>
      </Content>
      <Divider style={{ margin: '0' }} />
      <Footer style={{ display: open ? '' : 'none' }}>
        <div
          style={{ marginRight: '180px' }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpen(false)
          }}
          onKeyDown={() => {}}
        >
          <CloseFilter style={{ marginBottom: '-20px', cursor: 'pointer' }} />
        </div>
        <div style={{ marginRight: '30px' }}>
          <Button.Secondary
            height="35px"
            htmlType="Clear"
            text={'Clear Filter'}
            width={'144px'}
            onClick={() => onCancelFilter()}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          {/* <MarginLeft>
              <Tooltip
                placement="topLeft"
                title={
                  !tooltip
                    ? 'Kindly Select Filter Option Before Submitting the Data'
                    : ''
                }
              > */}
          <Button.Primary
            height="35px"
            htmlType="Save Filter"
            text={'Apply'}
            width={'144px'}
            onClick={() => submitFilter()}
          />
          {/* </Tooltip>
            </MarginLeft> */}
        </div>
      </Footer>
      <Divider style={{ margin: '0' }} />

      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        locale={{
          emptyText: (
            <Empty
              description={
                !hasProp
                  ? 'Kindly Select Filter Option to View the Data'
                  : 'No Institute Found'
              }
            />
          ),
        }}
        loading={loadingTopInst}
        scroll={{
          x: 2000,
          y: 500,
        }}
      />
      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={currentPage}
        currentCount={currentCount}
      />
    </Wrapper>
  )
}

export default Top10InstTable
