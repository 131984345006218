import { Col, Row } from 'antd'
import FileReaderViewOnly from 'components/Uploader/FileReaderViewOnly'
import { TwoFields } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import {
  DetailHeader,
  HorizontalDivider,
  LeftText,
  RightText,
  SubDetailHeader,
} from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Style/style'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const InstitutePreviewDetails = () => {
  const InstituteFormDetail = useSelector(
    state => state?.InstituteFormData?.InstituteFormData
  )
  const [instituteFormData, setInstituteFormData] = useState([])
  const [contractFile, setContractFile] = useState(
    InstituteFormDetail?.contractURL
  )

  useEffect(() => {
    let institutePreviewData = [
      {
        id: 1,
        name: 'Country',
        value: InstituteFormDetail.country,
      },
      {
        id: 2,
        name: 'State',
        value: InstituteFormDetail.state,
      },
      {
        id: 3,
        name: 'City',
        value: InstituteFormDetail.city,
      },
      {
        id: 4,
        name: 'Zipcode',
        value: InstituteFormDetail.zipcode,
      },
      {
        id: 5,
        name: 'Website',
        value: InstituteFormDetail.website,
      },
      {
        id: 6,
        name: 'Contact Name',
        value: InstituteFormDetail.contactPerson,
      },
      {
        id: 7,
        name: 'Email Id',
        value: InstituteFormDetail.email,
      },
      {
        id: 8,
        name: 'Contact Number',
        value: InstituteFormDetail.phoneNumber,
      },
    ]
    setInstituteFormData(institutePreviewData)
  }, [InstituteFormDetail])

  return (
    <>
      <TwoFields spaceBetween>
        <div>
          <DetailHeader>{InstituteFormDetail.instituteName}</DetailHeader>
          <SubDetailHeader>{InstituteFormDetail.groupName}</SubDetailHeader>
        </div>
      </TwoFields>
      {instituteFormData?.map(item => (
        <Row key={item?.id} style={{ width: '100%' }}>
          <Col span={12}>
            <LeftText>{item.name}</LeftText>
          </Col>
          <Col span={12}>
            <RightText style={{ wordBreak: 'break-all' }}>
              {item.value}
            </RightText>
          </Col>
        </Row>
      ))}
      {contractFile && (
        <>
          <HorizontalDivider />
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <LeftText>
                <DetailHeader>Contract</DetailHeader>
              </LeftText>
            </Col>
            <Col span={12}>
              {' '}
              <FileReaderViewOnly
                file={contractFile}
                setFile={setContractFile}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default InstitutePreviewDetails
