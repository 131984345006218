import { Divider, Empty } from 'antd'
import Button from 'components/Button/index'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { Form } from 'components/UIComponents/FormStyles'
import ReportSearchFilter from 'components/UIComponents/ReportSearchFilter'
import {
  Content,
  Footer,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import CloseFilter from 'components/icons/CloseFilter'
import {
  DateFormat,
  RefinedData,
  arrayIntoString,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessageReport from 'utils/WarningMessageReport'
import CityFilter from 'modules/Reports/Partials/Corporates/Partials/CorporateInfoTable/CorporateFilter/Partials/CityFilter/index.js'
import DateRangeFilter from '../InstituteFilter/Partials/DateRangeFilter'
import TierFilter from '../InstituteFilter/Partials/TierFilter'
import UniversityFilter from '../InstituteFilter/Partials/UniversityFilter'
import { setInstituteListForReport } from '../actions'

const InstEmpTable = ({
  loading,
  currentCount,
  setCurrentPage,
  searchValue,
  setSearchValue,
  sectorList,
  sectorValue,
  setSectorValue,
  cityValue,
  setCityValue,
  industryValue,
  setIndustryValue,
  setFilterData,
  filterData,
  isReport,
  industryList,
  stateValue,
  cityList,
  tableData,
  setKeySector,
  keySector,
  setKeyIndustry,
  keyIndustry,
  keyCity,
  setKeyCity,
  createdAtEnd,
  createdAtStart,
  setCreatedAtEnd,
  setCreatedAtStart,
  isEmpanelled,
  currentPage,
  setSortBy,
  setClearDate,
  clearDate,
  setToolTip,
  tooltip,
  hasProp,
  loadingInst,
  loadMoreCityData,
  cityCount,
  stateCount,
  loadMoreStateData,
  statePage,
  cityPage,
  getCityList,
  setLoading,
  loadingUniversity,
  getUniversityList,
  getTierList,
  setTierList,
}) => {
  const [open, setOpen] = useState(true)
  const [flag, setFlag] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }
  const onPageChange = num => {
    setCurrentPage(num)
  }

  useEffect(() => {
    dispatch(setInstituteListForReport())
  }, [])

  const submitFilter = () => {
    const modifiedNames = sectorValue.map(name => name?.replace(',', '$'))
    let filterDatas = {
      university: arrayIntoString(modifiedNames),
      state: arrayIntoString(stateValue),
      city: arrayIntoString(cityValue),
      tier: arrayIntoString(industryValue),
      createdAtStart: createdAtStart,
      createdAtEnd: createdAtEnd,
    }

    if (createdAtStart) {
      if (!createdAtEnd) {
        WarningMessageReport(
          <>
            Kindly select <q>To Date</q> from Date Range
          </>
        )
      }
    }
    if (createdAtEnd) {
      if (!createdAtStart) {
        WarningMessageReport(
          <>
            Kindly select <q>From date</q> from Date Range
          </>
        )
      }
    }

    if (createdAtEnd && createdAtStart) {
      setFilterData(filterDatas)
    }
    if (!createdAtEnd && !createdAtStart) {
      setFilterData(filterDatas)
    }

    setToolTip(!hasProp)
  }

  const onCancelFilter = () => {
    setFlag(true)
    setFilterData({
      tier: '',
      city: '',
      university: '',
      state: '',
      createdAtEnd: '',
      createdAtStart: '',
    })

    dispatch(setInstituteListForReport())
    setCityValue([])
    setIndustryValue([])
    setSectorValue([])
    // setClearDate(true)

    getUniversityList({ setLoading, keySector: '', flag: 'LI', page: 0 })
    getCityList({ setLoading, keyCity: '', flag: 'LI' })
    getTierList({ setLoading, keyIndustry: '', flag: 'LI' })
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'b.prefix_id',
      key: 'b.prefix_id',
      width: '10%',
      align: 'center',
      visible: true,
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'INSTITUTE NAME',
      dataIndex: 'a.name',
      key: 'a.name',
      width: '15%',
      visible: true,
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'UNIVERSITY',
      dataIndex: 'a.university',
      key: 'a.university',
      width: '15%',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.university)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'TIER',
      dataIndex: 'a.tier',
      key: 'a.tier',
      sorter: true,
      align: 'center',
      width: '7%',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.tier)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: isEmpanelled ? 'EMPANELLED' : 'ONBOARD',
      dataIndex: 'createdat',
      key: 'onboard',
      width: '10%',
      align: 'center',
      sorter: true,
      render: (_, tableData) => {
        const createdAt = tableData?.created_at;
        const formattedDate = createdAt
          ? new Date(createdAt).toLocaleDateString('en-GB') // 'en-GB' formats as DD/MM/YYYY
          : '-';
        return (
          <RowNumberDataReportAlignCenter>
            {formattedDate.split('/').join('-')} {/* Convert to DD-MM-YYYY */}
          </RowNumberDataReportAlignCenter>
        );
      },
      visible: false,
    },
    {
      title: 'TPO PERSON',
      dataIndex: 'b.contact_person',
      key: 'b.contact_person',
      width: '12%',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.contact_person)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'CONTACT NO',
      dataIndex: 'b.contact_number',
      key: 'b.contact_number',
      align: 'center',
      width: '10%',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.contact_number)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'EMAIL ID',
      dataIndex: 'b.contact_email',
      key: 'b.contact_email',
      width: '25%',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.contact_email)}
        </RowNumberDataReport>
      ),
    },

    {
      title: 'CITY',
      dataIndex: 'b.city',
      key: 'b.city',
      width: '10%',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.city)}
        </RowNumberDataReport>
      ),
    },
  ]

  return (
    <Wrapper>
      <ReportSearchFilter
        setOpen={setOpen}
        open={open}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={isReport}
        totalCount={tableData?.count}
        currentCount={10 * (currentPage - 1) + tableData?.result?.length}
      />
      <Divider style={{ margin: '0' }} />
      <Content isOpen={open}>
        {isEmpanelled && (
          <div style={{ width: '100%', display: 'flex' }}>
            <UniversityFilter
              flag={flag}
              setFlag={setFlag}
              industryList={industryList}
              sectorValue={sectorValue}
              setSectorValue={setSectorValue}
              setKeySector={setKeySector}
              keySector={keySector}
              loading={loadingUniversity}
              isReport={isReport}
              stateCount={stateCount}
              loadMoreStateData={loadMoreStateData}
              statePage={statePage}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <TierFilter
              flag={flag}
              setFlag={setFlag}
              industryValue={industryValue}
              setIndustryValue={setIndustryValue}
              sectorList={sectorList}
              setKeyIndustry={setKeyIndustry}
              keyIndustry={keyIndustry}
              isReport={isReport}
              loading={loading}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <CityFilter
              flag={flag}
              setFlag={setFlag}
              cityList={cityList}
              cityValue={cityValue}
              setCityValue={setCityValue}
              loadingCity={loading}
              keyCity={keyCity}
              setKeyCity={setKeyCity}
              isReport={isReport}
              cityPage={cityPage}
              loadMoreCityData={loadMoreCityData}
              cityCount={cityCount}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />

            <DateRangeFilter
              isEmpanelled={isEmpanelled}
              setClearDate={setClearDate}
              clearDate={clearDate}
              createdAtStart={createdAtStart}
              createdAtEnd={createdAtEnd}
              setCreatedAtStart={setCreatedAtStart}
              setCreatedAtEnd={setCreatedAtEnd}
              form={form}
              isReport={isReport}
            />
            {/* <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          /> */}
          </div>
        )}
        {!isEmpanelled && (
          <div style={{ width: '100%', display: 'flex' }}>
            <UniversityFilter
              flag={flag}
              setFlag={setFlag}
              industryList={industryList}
              sectorValue={sectorValue}
              setSectorValue={setSectorValue}
              setKeySector={setKeySector}
              keySector={keySector}
              loading={loadingUniversity}
              isReport={isReport}
              stateCount={stateCount}
              loadMoreStateData={loadMoreStateData}
              statePage={statePage}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <TierFilter
              flag={flag}
              setFlag={setFlag}
              industryValue={industryValue}
              setIndustryValue={setIndustryValue}
              sectorList={sectorList}
              setKeyIndustry={setKeyIndustry}
              keyIndustry={keyIndustry}
              isReport={isReport}
              loading={loading}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <CityFilter
              flag={flag}
              setFlag={setFlag}
              cityList={cityList}
              cityValue={cityValue}
              setCityValue={setCityValue}
              loadingCity={loading}
              keyCity={keyCity}
              setKeyCity={setKeyCity}
              isReport={isReport}
              cityPage={cityPage}
              loadMoreCityData={loadMoreCityData}
              cityCount={cityCount}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
          </div>
        )}
      </Content>
      <Divider style={{ margin: '0' }} />
      <Footer style={{ display: open ? '' : 'none' }}>
        <div
          style={{ marginRight: '180px' }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpen(false)
          }}
          onKeyDown={() => {}}
        >
          <CloseFilter style={{ marginBottom: '-20px', cursor: 'pointer' }} />
        </div>
        <div style={{ marginRight: '30px' }}>
          <Button.Secondary
            height="35px"
            htmlType="Clear"
            text={'Clear Filter'}
            width={'144px'}
            onClick={() => onCancelFilter()}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          {/* <MarginLeft>
              <Tooltip
                placement="topLeft"
                title={
                  !tooltip
                    ? 'Kindly Select Filter Option Before Submitting the Data'
                    : ''
                }
              > */}
          <Button.Primary
            height="35px"
            htmlType="Save Filter"
            text={'Apply'}
            width={'144px'}
            onClick={() => submitFilter()}
          />
          {/* </Tooltip>
            </MarginLeft> */}
        </div>
      </Footer>
      {/* <Divider style={{ margin: '0' }} /> */}

      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        locale={{
          emptyText: (
            <Empty
              description={
                !hasProp
                  ? 'Kindly Select Filter Option to View the Data'
                  : 'No Institute Found'
              }
            />
          ),
        }}
        loading={loadingInst}
        scroll={{
          x: 2000,
          y: 500,
        }}
      />
      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={currentPage}
        currentCount={currentCount}
      />
    </Wrapper>
  )
}

export default InstEmpTable
