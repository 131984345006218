import authRequest from 'utils/authRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'
import { getUserInfo } from '../Auth/actions'
import authSelector from 'modules/Auth/selectors'

export const setUserPreference = data => {
  return {
    type: 'SET_USER_PREFERENCE',
    data,
  }
}

export const setUserData = payload => {
  return {
    type: 'SET_USER_DATA',
    payload,
  }
}

export const getUserPreference = () => async (dispatch, getState) => {
  const state = getState()
  const userId = authSelector.getUserId(state)
  try {
    const res = await authRequest.get(`/user/${userId}`)
    dispatch(setUserPreference(res?.data?.data))
    // SuccessMessage(' User Successfully')
  } catch (error) {
    ErrorMessage('User Error')
  }
}

export const SendOtp = (id, payLoad) => async (dispatch, getState) => {
  try {
    let response = await authRequest.post(`/users/${id}/sendotp`, payLoad)

    SuccessMessage('OTP Sent Successfully')
    return response?.data?.message === 'Success'
  } catch (error) {
    return error
  }
}

export const checkEmailOtp = (id, payLoad) => async (dispatch, getState) => {
  try {
    const data = await authRequest.patch(`/users/${id}/email`, payLoad)
    return data
  } catch (error) {
    return error?.message
  }
}

export const checkPhoneOtp = (id, payLoad) => async (dispatch, getState) => {
  try {
    const data = await authRequest.patch(`/users/${id}/phone`, payLoad)
    return data
  } catch (error) {
    return error?.message
  }
}

export const updateUserData = payload => async (dispatch, getState) => {
  const state = getState()
  const userId = authSelector.getUserId(state)
  try {
    let upd = await authRequest.put(`/user/${userId}`, payload)

    if (upd?.status >= 200 && upd?.status <= 300) {
      SuccessMessage('Personal info saved successfully!')
      getUserInfo()
    } else {
      ErrorMessage(upd?.upd?.data?.message)
    }
  } catch (error) {
    ErrorMessage('Error in publishing role', error)
  }
}

export const changePassword = payload => async (dispatch, getState) => {
  const state = getState()
  const userId = authSelector.getUserId(state)

  try {
    await authRequest.patch(`/user/${userId}/password`, payload)
    return true
  } catch (error) {
    return error
  }
}
