import Control from 'components/Control/Control'
import Input from 'components/Form/Input'
import Label from 'components/Form/Label'
import AntdSelect from 'components/Form/Select'
import { StyledSelectDiv } from 'modules/ManageProfile/Style/style'
import {
  SectionHeading,
  StyledInput,
  StyledInputDiv,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import { getListOfCountryCode } from 'modules/Onboarding/actions'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CONSTANTS from 'utils/constants'

const ContactDetails = ({ form, countryCode }) => {
  const dispatch = useDispatch()

  const [countrycodeList, setCountrycodeList] = useState([])
  const [searchCountryCode, setSearchCountryCode] = useState('')

  const fetchCountryList = async () => {
    try {
      const response = await dispatch(
        getListOfCountryCode(50, searchCountryCode)
      )
      if (response?.data?.message === 'Success') {
        let data = response?.data?.data?.result
        setCountrycodeList(response?.data?.data?.result)
        if (searchCountryCode) {
          const v = data?.some(item => item?.phone_code == searchCountryCode)
          if (!v) {
            form.setFieldsValue({ countryCode: '' })
          }
        }
        return response?.data?.data?.result
      }
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    fetchCountryList()
  }, [searchCountryCode])

  const onCountryCodeChange = (id, options) => {
    form.setFieldValue('countryCode', options?.name)
  }

  const delaySearch = useCallback(
    debounce(value => {
      setSearchCountryCode(value)
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  return (
    <>
      <SectionHeading>Contact Details</SectionHeading>
      <Input
        width="100%"
        label="Contact Name"
        placeholder="Eg. Harsha Saggi"
        optional={'*'}
        name={'contactPerson'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('contact name'),
          },
          {
            pattern: CONSTANTS.REGEX.NAME,
            message: CONSTANTS.ERROR_MESSAGE.VALID('contact name'),
          },
        ]}
        onChange={event => {
          form.setFieldsValue({
            contactPerson: event.target.value.replace(/[^A-Za-z ]|^ /g, ''),
          })
        }}
      />
      <Control flexStart column>
        <Label>Contact Number *</Label>
        <Control>
          <StyledSelectDiv>
            <AntdSelect
              width={'77px'}
              placeholder="91"
              OptionData={countrycodeList}
              nameParam="phone_code"
              bordered={true}
              onChange={onCountryCodeChange}
              value={countryCode}
              name="countryCode"
              onSearch={delaySearch}
              onSelect={() => setSearchCountryCode('')}
              filterOption={false}
              showSearch
              rules={[
                {
                  required: true,
                  message: 'Select code',
                },
              ]}
              disabled={false}
              iskey={true}
            />
          </StyledSelectDiv>
          <StyledInputDiv>
            <StyledInput
              width="100%"
              type="tel"
              placeholder="Eg: 1010101010"
              rules={[
                {
                  required: true,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('contact number'),
                },
                {
                  message: CONSTANTS.ERROR_MESSAGE.VALID('contact number'),
                  pattern: CONSTANTS.REGEX.MOBILE_NUMBER,
                },
              ]}
              onChange={event => {
                form.setFieldsValue({
                  phoneNumber: event.target.value.trim(),
                })
              }}
              name={'phoneNumber'}
            />
          </StyledInputDiv>
        </Control>
      </Control>
      <Input
        width="100%"
        label="Email ID"
        placeholder="Eg. example@gmail.com"
        optional={'*'}
        name={'email'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('email address'),
          },
          {
            type: 'email',
            message: CONSTANTS.ERROR_MESSAGE.VALID('email address'),
          },
        ]}
      />
    </>
  )
}

export default ContactDetails
