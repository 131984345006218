import React, { Fragment } from 'react'
import { LoginOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import AntdAvatar from '../../../components/Avatar'
import { EmptyWrapper } from '../Style/style'
import { setSingleInstituteData } from '../actions'
import {
  BorderedDiv,
  FlexWrap,
  GreyText,
  MediumText,
  StyledDivider,
  UserName
} from './Style/style'

const ViewInstituteDrawer = ({
  viewInstituteDrawer,
  setViewInstituteDrawer,
}) => {
  const singleInstituteData = useSelector(
    state => state?.UserInstituteList?.singleInstituteData
  )

  const dispatch = useDispatch()

  let size =
    (singleInstituteData?.regDocs?.size / 1024 / 1024).toFixed(2) + ' MB'
  const singleInstituteCampus = singleInstituteData?.instituteCampus?.[0] ?? []

  const content = () => {
    return (
      <Fragment>
        {singleInstituteData?.name ? (
          <>
            <BorderedDiv>
              <FlexWrap gap="10px" alignCenter>
                <div>
                  <AntdAvatar
                    size={39}
                    src={singleInstituteCampus?.campusLogoUrl}
                    IconName={singleInstituteData?.name?.charAt(0)}
                    font={18}
                  />
                </div>
                <FlexWrap column>
                  <UserName>{singleInstituteData?.name}</UserName>
                  <GreyText>{singleInstituteCampus?.website ?? ''}</GreyText>
                </FlexWrap>
              </FlexWrap>
              <StyledDivider />
              <FlexWrap>
                <GreyText width={'50%'}>Plan </GreyText>
                <FlexWrap column>
                  <MediumText>Free Plan</MediumText>
                  {/* <FlexWrap gap={'4px'}>
                    <MediumGreyText color={COLORS.GREY_T_60}>
                      Next Due
                    </MediumGreyText>
                    <GreyText>20/10/2022</GreyText>
                  </FlexWrap> */}
                </FlexWrap>
              </FlexWrap>
              {singleInstituteData?.regDocs && (
                <>
                  <StyledDivider />
                  <FlexWrap>
                    <GreyText width={'50%'}>Contract</GreyText>
                    <FlexWrap column>
                      <MediumText>
                        {singleInstituteData?.regDocs?.name}
                      </MediumText>

                      <GreyText>{size}</GreyText>
                    </FlexWrap>
                  </FlexWrap>
                </>
              )}
            </BorderedDiv>
            <EmptyWrapper center>
              {singleInstituteData?.redirectLink && (
                <Button.IconButton
                  text-align={'center'}
                  icon={<LoginOutlined />}
                  onClick={() => hanlder(singleInstituteData?.redirectLink)}
                  text="Checkin"
                  height="38px"
                />
              )}
            </EmptyWrapper>
          </>
        ) : (
          <EmptyWrapper center isSpin={!singleInstituteData}>
            <Spin />
          </EmptyWrapper>
        )}
      </Fragment>
    )
  }
  const onClose = () => {
    setViewInstituteDrawer(false)
    dispatch(setSingleInstituteData({}))
  }

  const hanlder = redirectLink => {
    window.open(redirectLink, '_self')
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClose} height="38px" />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={'Preview'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={viewInstituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ViewInstituteDrawer
