import React from 'react'

const UploadIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.50033 11.3337C1.13366 11.3337 0.819881 11.2032 0.558992 10.9423C0.297659 10.681 0.166992 10.367 0.166992 10.0003V8.00033H1.50033V10.0003H9.50033V8.00033H10.8337V10.0003C10.8337 10.367 10.7032 10.681 10.4423 10.9423C10.181 11.2032 9.86699 11.3337 9.50033 11.3337H1.50033ZM4.83366 8.66699V3.23366L3.10033 4.96699L2.16699 4.00033L5.50033 0.666992L8.83366 4.00033L7.90033 4.96699L6.16699 3.23366V8.66699H4.83366Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default UploadIcon
