import { Avatar, Col, Collapse, Empty, Menu, Modal, Row } from 'antd'
import Button from 'components/Button/index'
import Flex from 'components/Flex'
import AntdInput from 'components/Form/Input'
import Label from 'components/Form/Label'
import {
  CustomCollapse,
  CustomPanel,
} from 'components/UIComponents/CollapseUI/Collapse'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import {
  StyledCheckbox1,
  StyledGroup,
  StyledOutline,
} from 'components/UIComponents/FilterCheckBoxComp/style'
import Toggle from 'components/UIComponents/Toggle/MultipleToggleOptions'
import Uploadloader from 'components/Uploader/Uploader'
import ArrowSideIcon from 'components/icons/ArrowSideIcon'
import CheckIcon from 'components/icons/CheckIcon'
import CopyAltIcon from 'components/icons/CopyAltIcon'
import CrossIcon from 'components/icons/CrossIcon'
import DraggerIcon from 'components/icons/DraggerIcon'
import PlusIcon from 'components/icons/PlusIcon'
import DeleteIcon from 'components/icons/delete.svg'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import 'draft-js/dist/Draft.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import {
  FooterFlex,
  Form,
  FormItem,
} from 'modules/EventCatalogue/Partials/Style/style'
import {
  EventHeader,
  EventHeader1,
  FooterDiv,
  PaddedToggleDive,
  PaddedToggleDive1,
  RowStyling,
  SectionWrapper,
  SectionWrapper1,
  SideArrowItem,
  SpanPaddedHeader,
  StyledCheckboxGroup1,
  StyledCol,
  StyledDividerTab,
  StyledMenu,
  TopHeader,
  TopHeader1,
  TopHeader2,
  TopHeading,
  TopRightFlex,
  WrittenDrawer,
  WrittenDrawerContent,
} from 'modules/EventCatalogue/Style/style'
import EventDrawer from 'modules/Systemconfig/Partials/PermissionSettings/Partials/EventDrawer'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useDispatch } from 'react-redux'
import SuccessMessage from 'utils/SuccessMessage'
import './style.css'
const { confirm } = Modal

const { Panel } = Collapse
const EventCatalogue = ({
  getEventList,
  eventList,
  getNotificationType,
  notificationType,
  createInstituteSysConfig,
  getNotificationCatalogues,
  notificationCatalogue,
  getNotificationTypeByID,
  AddNewNotificationTemplate,
  EditNewNotificationTemplate,
  DeleteNewNotificationTemplate,
  DeleteEvent,
  DeleteSubEvent,
  ManageNotificationCatalogue,
  setEventList,
  reorderEvent,
  getScreenData,
  screenData,
  setNotificationTypeByID,
  setAllEventTemplate,
  GetAllNotificationTemplate,
  allEventTemplate,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [form] = Form.useForm()
  const [createEditForm] = Form.useForm()
  const dispatch = new useDispatch()
  const [current, setCurrent] = useState('CORPORATE')
  const [selectedList, setSelectedList] = useState([])
  const [addNewEvent, setAddNewEvent] = useState(false)
  const [addNewTemplate, setAddNewTemplate] = useState(false)
  const [activeKey, setActiveKey] = useState(null)
  const [activeTabKey, setActiveTabeKey] = useState(null)
  const [activeTabName, setActiveTabName] = useState(null)
  const [inputs, setInputs] = useState([
    {
      value: '',
      name: '',
    },
  ])
  const [toEditId, setEditId] = useState('')
  const [success, isSuccess] = useState(false)
  const [view, setView] = useState(false)
  const [journey, setJourney] = useState([])
  const [url] = useState('events')
  const [eventId, setEventID] = useState('')
  const [selectedId, setSelectedID] = useState('')
  const [currentTemplateID, setCurrentTemplateID] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [fullEventID, setFullEventID] = useState('')
  const [isSubEventSelected, setIsSubEventSelected] = useState(false)
  const [payloadSubEventId, setPayloadFullSubEventId] = useState('')
  const [payloadEventID, setPayloadEventID] = useState('')
  const [image, setImage] = useState()
  const [show, setShow] = useState(false)

  const handleCollapseChange = (event, param) => {
    setIsSubEventSelected(false)
    setPayloadFullSubEventId('')
    if (activeKey === param?.screenName) {
      setActiveKey(null)
      setSelectedID('')
      dispatch(setAllEventTemplate(null))
    } else {
      setEventID(`&eventId=${param?.events?.[0]?.id}`)
      setFullEventID(param?.events?.[0]?.id)
      setPayloadEventID(param?.events?.[0]?.id)
      setActiveKey(param?.screenName)
      setActiveTabeKey(null)
      setActiveTabName(null)
      setSelectedID('')
      dispatch(setAllEventTemplate(null))
    }
  }

  const onSelectEvent = (name, params) => {
    if (name === 'event') {
      setEventID(`&eventId=${params?.id}`)
      setFullEventID(params?.id)
      setPayloadEventID(params?.id)
      setIsSubEventSelected(false)
      setActiveTabeKey(params?.id)
      setActiveTabName(params?.eventName)
      setSelectedID('')
      dispatch(setAllEventTemplate(null))
      dispatch(setNotificationTypeByID(null))
    } else {
      setEventID(`&subEventId=${params?.id}`)
      setFullEventID(params?.id)
      setPayloadFullSubEventId(params?.id)
      setIsSubEventSelected(true)
      setActiveTabeKey(params?.id)
      setActiveTabName(params?.subEventName)
      setSelectedID('')
      dispatch(setAllEventTemplate(null))
      dispatch(setNotificationTypeByID(null))
    }
  }
  const onClick = target => {
    setSelectedID('')
    if (target === 'CORPORATE') {
      setCurrent('CORPORATE')
      setActiveKey(null)
    }
    if (target === 'INSTITUTE') {
      setCurrent('INSTITUTE')
      setActiveKey(null)
    }
    if (target === 'STUDENT') {
      setCurrent('STUDENT')
      setActiveKey(null)
    }
    if (target === 'INTERNAL') {
      setCurrent('INTERNAL')
      setActiveKey(null)
    }
  }

  useEffect(() => {
    getEventList(current)
  }, [current])
  useEffect(() => {
    getNotificationType()
    getNotificationCatalogues()
  }, [])
  useEffect(() => {
    getNotificationCatalogues(eventId)
  }, [eventId])

  useEffect(() => {
    if (selectedId) {
      getNotificationTypeByID(selectedId)
      GetAllNotificationTemplate(selectedId, eventId)
    }
  }, [selectedId])

  const onDelete = async (event, params) => {
    confirm({
      title: 'Delete Template?',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk: async () => {
        let response = await DeleteNewNotificationTemplate(params?.id)
        if (response?.status >= 200 && response?.status <= 300) {
          getNotificationCatalogues(eventId)
          getNotificationTypeByID(selectedId)
          GetAllNotificationTemplate(selectedId, eventId)
        }
      },
    })
  }

  const onDeleteEvent = async () => {
    confirm({
      title: 'Delete Event?',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk: async () => {
        if (isSubEventSelected) {
          let response = await DeleteSubEvent(fullEventID)
          if (response?.status >= 200 && response?.status <= 300) {
            setActiveKey(null)
            getEventList(current)
          }
        } else {
          let response = await DeleteEvent(fullEventID)
          if (response?.status >= 200 && response?.status <= 300) {
            setActiveKey(null)
            getEventList(current)
          }
        }
      },
    })
  }
  useEffect(() => {
    let checkboxOptions = notificationCatalogue?.CatalogueData?.map(
      option => option?.eventNotificationTemplate?.eventNotificationType?.id
    )
    let uniqueArray = []

    checkboxOptions?.forEach(value => {
      if (!uniqueArray.includes(value)) {
        uniqueArray.push(value)
      }
    })
    setSelectedList(uniqueArray)
  }, [notificationCatalogue])

  useEffect(() => {
    let checkboxOptions = notificationCatalogue?.CatalogueData?.map(
      option => option?.eventNotificationTemplate?.id
    )
    let uniqueArray = []

    checkboxOptions?.forEach(value => {
      if (!uniqueArray.includes(value)) {
        uniqueArray.push(value)
      }
    })
    setCurrentTemplateID(uniqueArray)
  }, [notificationCatalogue])
  const onCheckboxSelect = list => {
    setSelectedList(list)
  }
  const onTemplateChange = list => {
    setCurrentTemplateID(list)
  }

  const convertedArray1 = notificationType?.NotificationData?.map(item => {
    return {
      label: (
        <div
          style={{
            display: 'flex',
            gap: '10px',
            paddingTop: '11px',
          }}
          key={item?.id}
        >
          <div>
            <SideArrowItem
              backgroundColor={
                selectedList?.includes(item?.id) && selectedId === item?.id
                  ? '#3249D7'
                  : '#EBECEE'
              }
              borderRadius={
                selectedList?.includes(item?.id) && selectedId === item?.id
                  ? '10px'
                  : '12px'
              }
              color={
                selectedList?.includes(item?.id) && selectedId === item?.id
                  ? '#FFFFFF'
                  : '#999FAC'
              }
              fontSize="16px"
              fontWeight={
                selectedList?.includes(item?.id) && selectedId === item?.id
                  ? '500'
                  : '400'
              }
              pointerEvents={selectedList?.includes(item?.id) ? '' : 'none'}
            >
              <div
                tabIndex="0"
                role="button"
                onClick={e => iconClicked(e, item?.id)}
                onKeyDown={() => {}}
              >
                <ArrowSideIcon />
              </div>
            </SideArrowItem>
          </div>
        </div>
      ),
      value: item?.id,
    }
  })
  const AddNewEvent = () => {
    setEditorState(EditorState.createEmpty())
    setAddNewEvent(true)
  }
  const AddNewTemplateData = () => {
    setEditorState(EditorState.createEmpty())
    createEditForm.setFieldsValue({
      name: '',
      template: '',
      curd: '',
      id: '',
      subject: '',
    })
    setAddNewTemplate(true)
  }
  const handleJourneyChange = value => {
    // setJourney(value)
  }
  const handleSubmitCongif = (data, call) => {
    if (toEditId) {
      let value = data
      createInstituteSysConfig({
        url,
        value,
        type: 'put',
        id: toEditId,
        isSuccess,
        success,
      })
      setActiveKey(null)
      getEventList(current)
      setAddNewEvent(false)
    } else {
      const eventsData = inputs.map(input => ({
        name: input.value,
      }))
      const payload = {
        screenId: data.screenId,
        events: eventsData,
      }
      createInstituteSysConfig({
        url,
        value: payload,
        type: 'post',
        isSuccess,
        success,
      })
      setActiveKey(null)
      getEventList(current)
      setAddNewEvent(false)
    }
  }

  const onClose = () => {
    setEditorState(EditorState.createEmpty())
    setAddNewEvent(false)
    form.resetFields()
    setEditId('')
    setShow(false)
    setView(false)
  }
  const iconClicked = (event, value) => {
    setSelectedID(value)
  }
  const onEditClicked = (event, param) => {
    setIsEditing(true)
    const contentBlock = htmlToDraft(param?.templateText)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )

    setEditorState(EditorState.createWithContent(contentState))
    createEditForm.setFieldsValue({
      name: param?.templateName,
      template: param?.templateText,
      curd: param?.crud,
      id: param?.id,
      subject: param?.subject,
    })
    setAddNewTemplate(true)
  }

  const MapEventWithTemplate = async () => {
    const updatedData = notificationCatalogue?.CatalogueData
      ? notificationCatalogue?.CatalogueData?.filter(
          obj =>
            obj?.eventNotificationTemplate?.eventNotificationType?.id !==
            selectedId
        )
      : []

    let alreadyPresentID = updatedData?.map(
      option => option?.eventNotificationTemplate?.id
    )
    let Template = [...alreadyPresentID, ...currentTemplateID]

    let payload = {
      eventId: payloadEventID,
      subEventId: payloadSubEventId,
      crud: 'string',
      templates: Template?.map(templateId => ({ templateId })),
    }

    if (!isSubEventSelected) {
      delete payload?.subEventId
    }

    ManageNotificationCatalogue(payload)
  }

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    const screens = Array.from(eventList?.screens)
    const [movedScreen] = screens.splice(result.source.index, 1)
    screens.splice(result.destination.index, 0, movedScreen)
    const output = screens?.map((item, index) => ({
      id: item.id,
      orderNo: index + 1,
    }))
    reorderEvent(output)
    dispatch(setEventList({ ...eventList, screens }))
  }
  const submitHandler = async values => {
    let Template = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    let payload = {
      templateCatalogue: {
        name: values?.name,
        eventId: payloadEventID,
        subEventId: payloadSubEventId,
        subject: values?.subject,
        template: Template,
        crud: values?.curd,
        notificationTypeId: selectedId,
      },
    }
    if (!isSubEventSelected) {
      delete payload?.subEventId
    }
    if (isEditing) {
      await EditNewNotificationTemplate(payload, values?.id)
    } else {
      await AddNewNotificationTemplate(payload)
    }
    createEditForm.resetFields()
    getNotificationTypeByID(selectedId)
    GetAllNotificationTemplate(selectedId, eventId)
    setIsEditing(false)
    setAddNewTemplate(false)
  }

  const OnCloseTemplate = () => {
    setEditorState(EditorState.createEmpty())
    setAddNewTemplate(false)
  }
  const onChangeEditorState = newEditorState => {
    setEditorState(newEditorState)
    const result = newEditorState
    if (!result) {
      createEditForm.setFieldsValue({ template: '' })
    } else {
      createEditForm.setFieldsValue({ template: newEditorState })
    }
  }

  const copyLink = () => {
    let copyText = document.getElementById('socilamedialink')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value?.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }
  return (
    <>
      <SectionWrapper1 column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>Event Catalogue</TopHeading>
        </TopHeader>
        <PaddedToggleDive>
          <Toggle
            left="CORPORATE"
            right="INSTITUTE"
            bottom="STUDENT"
            top="INTERNAL"
            initialValue={current}
            onClick={e => {
              onClick(e)
            }}
          />
        </PaddedToggleDive>
      </SectionWrapper1>
      <br></br>
      <SectionWrapper column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>{current}</TopHeading>
          <TopRightFlex>
            <Button.Primary
              height={'38px'}
              text={'Add New Event'}
              icon={<PlusIcon />}
              onClick={AddNewEvent}
            />
          </TopRightFlex>
        </TopHeader>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="event-list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {eventList?.screens?.map((item, index) => (
                  <Draggable
                    key={item?.screenName}
                    draggableId={item?.screenName}
                    index={index}
                    isDragDisabled={activeKey != null}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* Your existing code here */}

                        <PaddedToggleDive1>
                          <CustomCollapse
                            activeKey={activeKey}
                            onChange={event =>
                              handleCollapseChange(event, item)
                            }
                            accordion
                            bordered={false}
                            expandIconPosition="end"
                          >
                            <CustomPanel
                              header={
                                <span>
                                  <DraggerIcon />
                                  <SpanPaddedHeader>
                                    {item?.screenName?.toLowerCase()}
                                  </SpanPaddedHeader>
                                </span>
                              }
                              key={item?.screenName}
                            >
                              <RowStyling>
                                {item?.events?.length != 0 ? (
                                  <>
                                    <EventHeader>Events</EventHeader>
                                    <Col span={6}>
                                      {item?.events?.map((event, index1) => (
                                        <StyledMenu
                                          key={event?.id} // Adding key prop here
                                          selectedKeys={
                                            activeTabKey || item?.events[0]?.id
                                          }
                                          defaultSelectedKeys={
                                            item?.events[0]?.id
                                          }
                                        >
                                          <Menu.Item
                                            key={event?.id}
                                            onClick={() =>
                                              onSelectEvent('event', event)
                                            }
                                          >
                                            {event?.eventName?.toLowerCase()}
                                          </Menu.Item>
                                          <StyledDividerTab />
                                          {event?.subEvents?.map(
                                            (subEvent, index2) => (
                                              <React.Fragment
                                                key={subEvent?.id}
                                              >
                                                {' '}
                                                {/* Adding key prop here */}
                                                <Menu.Item
                                                  key={subEvent?.id}
                                                  onClick={() =>
                                                    onSelectEvent(
                                                      'subEvent',
                                                      subEvent
                                                    )
                                                  }
                                                >
                                                  {subEvent?.subEventName}
                                                </Menu.Item>
                                                <StyledDividerTab />
                                              </React.Fragment>
                                            )
                                          )}
                                        </StyledMenu>
                                      ))}
                                    </Col>

                                    <Col span={18}>
                                      <Row>
                                        <TopHeader2
                                          row
                                          spaceBetween
                                          alignCenter
                                        >
                                          <TopHeading flexStart>
                                            <EventHeader>
                                              {activeTabName?.toLowerCase() ||
                                                item?.events[0]?.eventName?.toLowerCase()}
                                            </EventHeader>
                                          </TopHeading>
                                          <TopRightFlex>
                                            <div
                                              role="button"
                                              tabIndex="0"
                                              onClick={onDeleteEvent}
                                              onKeyDown={e => {
                                                if (
                                                  e.key === 'Enter' ||
                                                  e.key === ' '
                                                ) {
                                                  onDeleteEvent()
                                                }
                                              }}
                                              style={{ cursor: 'pointer' }}
                                              aria-label="delete"
                                            >
                                              <img
                                                src={DeleteIcon}
                                                alt="delete"
                                              />
                                            </div>
                                          </TopRightFlex>
                                        </TopHeader2>
                                        <Col span={12}>
                                          <EventHeader1>Channel</EventHeader1>
                                          <div
                                            style={{
                                              width: '100%',
                                              display: 'flex',
                                              gap: '5%',
                                              paddingTop: '10px',
                                            }}
                                          >
                                            <div style={{ width: '60%' }}>
                                              <FilterCheckBoxComp
                                                value={selectedList}
                                                onChange={onCheckboxSelect}
                                                options={
                                                  notificationType?.NotificationData
                                                }
                                                valueParam="id"
                                                labelParam="name"
                                              />
                                            </div>

                                            <div style={{ width: '15%' }}>
                                              <StyledCheckboxGroup1
                                                options={convertedArray1}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                        <StyledCol span={12}>
                                          {selectedId && (
                                            <TopHeader1
                                              row
                                              spaceBetween
                                              alignCenter
                                            >
                                              <TopHeading flexStart>
                                                <EventHeader>
                                                  Template
                                                </EventHeader>
                                              </TopHeading>
                                              <Button.Primary
                                                height={'38px'}
                                                text={'Add New Template'}
                                                icon={<PlusIcon />}
                                                onClick={AddNewTemplateData}
                                              />
                                            </TopHeader1>
                                          )}
                                          <StyledGroup
                                            value={currentTemplateID}
                                            onChange={onTemplateChange}
                                          >
                                            {allEventTemplate?.map(
                                              (item, id) => (
                                                <StyledOutline
                                                  row
                                                  spaceBetween
                                                  centerVertically
                                                  key={item?.id}
                                                >
                                                  <Flex
                                                    flexStart
                                                    key={item?.id}
                                                  >
                                                    <StyledCheckbox1
                                                      value={item?.id}
                                                      label={item?.templateName}
                                                    />
                                                  </Flex>
                                                  <Flex
                                                    flexEnd
                                                    spaceBetween
                                                    centerVertically
                                                  >
                                                    <Button.Secondary
                                                      onClick={event =>
                                                        onEditClicked(
                                                          event,
                                                          item
                                                        )
                                                      }
                                                      text="Preview & Edit"
                                                    />
                                                    <img
                                                      style={{
                                                        paddingLeft: '10px',
                                                      }}
                                                      src={DeleteIcon}
                                                      alt="Delete"
                                                      onClick={event =>
                                                        onDelete(event, item)
                                                      }
                                                      onKeyDown={event => {
                                                        if (
                                                          event.key ===
                                                            'Enter' ||
                                                          event.key === ' '
                                                        ) {
                                                          onDelete(event, item)
                                                        }
                                                      }}
                                                    />
                                                  </Flex>
                                                </StyledOutline>
                                              )
                                            )}
                                          </StyledGroup>
                                        </StyledCol>
                                      </Row>
                                    </Col>
                                    <FooterDiv>
                                      <FooterFlex right>
                                        <Button.Secondary text="Cancel" />
                                        <Button.Primary
                                          onClick={MapEventWithTemplate}
                                          text={'Apply'}
                                        />
                                      </FooterFlex>
                                    </FooterDiv>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      position: 'relative',
                                      left: '50%',
                                    }}
                                  >
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  </div>
                                )}
                              </RowStyling>
                            </CustomPanel>
                          </CustomCollapse>
                        </PaddedToggleDive1>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {addNewTemplate && (
          <WrittenDrawer>
            <WrittenDrawerContent>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Flex flexEnd>
                <Avatar
                  onClick={OnCloseTemplate}
                  size={20}
                  src={<CrossIcon />}
                />
              </Flex>
              <Form
                scrollToFirstError
                onFinish={submitHandler}
                id="template_form"
                form={createEditForm}
                layout="vertical"
              >
                <AntdInput
                  width="100%"
                  label="Template Name"
                  placeholder="Eg. Student Creation"
                  optional={'*'}
                  name={'name'}
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Template Name',
                    },
                  ]}
                  maxLength={300}
                />
                <AntdInput
                  width="100%"
                  label="subject"
                  placeholder="Account Activation"
                  optional={'*'}
                  name={'subject'}
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Subject',
                    },
                  ]}
                  maxLength={300}
                />
                <FormItem name={'id'} />

                <AntdInput
                  width="100%"
                  label="Parameters"
                  placeholder="Eg. Name,Value"
                  optional={'*'}
                  name={'curd'}
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Parameters',
                    },
                  ]}
                  maxLength={500}
                />

                <div>
                  <Label>Upload Image to insert</Label>
                  <Uploadloader
                    setFile={setImage}
                    isDefault={true}
                    fileName={'template_image'}
                    accpect={'.jpeg, .jpg , .png'}
                  />
                  <AntdInput
                    id={'socilamedialink'}
                    width="100%"
                    disabled={true}
                    value={image?.url}
                    suffix={<Avatar onClick={copyLink} src={<CopyAltIcon />} />}
                  />

                  <br></br>
                  <Label>Template </Label>
                  <FormItem
                    name={'template'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Template',
                      },
                    ]}
                  >
                    <div
                      style={{
                        paddingTop: '10px',
                        border: '1px solid #e9e9e9',
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onChangeEditorState}
                        editorClassName="editor-class"
                      />
                    </div>
                  </FormItem>
                </div>
                <br></br>
              </Form>
              <Flex flexEnd spaceBetween gap="10px">
                <Button.Secondary text="Cancel" onClick={OnCloseTemplate} />{' '}
                &nbsp;&nbsp;
                <Button.Primary
                  htmlType="submit"
                  form={'template_form'}
                  text={isEditing ? 'Save' : 'Add'}
                  icon={<CheckIcon />}
                />
              </Flex>
            </WrittenDrawerContent>
          </WrittenDrawer>
        )}

        {addNewEvent && (
          <EventDrawer
            drawer={addNewEvent}
            setDrawer={setAddNewEvent}
            onClose={onClose}
            inputs={inputs}
            setInputs={setInputs}
            // roleList={roles}
            getScreenData={getScreenData}
            screenData={screenData}
            setShow={setShow}
            show={show}
            instituteTableList={eventList}
            formId="form"
            form={form}
            journey={journey}
            handleJourneyChange={handleJourneyChange}
            setJourney={setJourney}
            handleSubmitCongif={handleSubmitCongif}
            toEditId={toEditId}
            view={view}
          />
        )}
      </SectionWrapper>
    </>
  )
}
export default EventCatalogue
