export const degreeListConvertionPart = ({
  listOfDegree,
  parentNameParam = 'degree',
  parentValueParam = 'degree_id',
  childValueParam = 'degreedepartmentid',
  childNameParam = 'department',
  pathName = 'department',
  selectedIds = [],
  isEditActiveRole = false,
}) => {
  return (
    listOfDegree?.length &&
    listOfDegree?.map(item => {
      return {
        key: item[parentValueParam],
        title:
          (item['short_form'] ? item['short_form'] : '') +
          ' - ' +
          item[parentNameParam],
        value: item[parentValueParam],
        disabled: isEditActiveRole
          ? selectedIds?.includes(item[parentValueParam])
          : false,
        children: item[pathName]?.map(data => {
          return {
            key: data[childValueParam],
            checked: false,
            title: data[childNameParam],

            value: data[childValueParam],
            streamData: {
              id: data[childValueParam],
              streamId: data[childValueParam],
            },
            disabled: isEditActiveRole
              ? selectedIds?.includes(data[parentValueParam])
              : false,
          }
        }),
      }
    })
  )
}

export const industrySectorPart = ({
  listOfDegree,
  parentNameParam = 'degree',
  parentValueParam = 'degree_id',
  childValueParam = 'degreedepartmentid',
  childNameParam = 'department',
  pathName = 'department',
  selectedIds = [],
  isEditActiveRole = false,
}) => {
  return (
    listOfDegree?.length &&
    listOfDegree?.map(item => {
      return {
        key: item[parentValueParam],
        title: item[parentNameParam],
        value: item[parentValueParam],
        disabled: isEditActiveRole
          ? selectedIds?.includes(item[parentValueParam])
          : false,
        children: item[pathName]?.map(data => {
          return {
            key: data[childValueParam],
            checked: false,
            title: data[childNameParam],

            value: data[childValueParam],
            streamData: {
              id: data[childValueParam],
              streamId: data[childValueParam],
            },
            disabled: isEditActiveRole
              ? selectedIds?.includes(data[parentValueParam])
              : false,
          }
        }),
      }
    })
  )
}
