import { connect } from 'react-redux'
import RankingAlgorithm from 'modules/RankingAlgorithm/index.js'
import { getListOfState, getListOfCity } from 'modules/Onboarding/actions'
import {
  getUserCorporatePortalsList,
  getActiveCorporatePortalsList,
} from 'modules/Corporates/actions.js'
import {
  getTemplateListStudent,
  updateStudentTemplateList,
  setTemplateListStudent,
  getAcademicStudentData,
  updateAcademictData,
  getSkillBucketData,
  updateSkillBucketsData,
  updateRankingCorporate,
} from '../actions'
import corporateSelector from 'modules/Corporates/selectors.js'
import selectors from 'modules/RankingAlgorithm/selectors.js'

const mapStateToProps = state => ({
  templateListData: selectors?.getTemplateListData(state),
  academicData: selectors?.getAcademicTierData(state),
  skillBucketData: selectors?.getSkillBucketData(state),
  corporateList: corporateSelector.getUserCorporatesLists(state),
  activeCorporateList: corporateSelector.getActiveCorporatesLists(state),
})

const mapDispatchToProps = {
  getTemplateListStudent,
  updateStudentTemplateList,
  setTemplateListStudent,
  getAcademicStudentData,
  updateAcademictData,
  getSkillBucketData,
  updateSkillBucketsData,
  updateRankingCorporate,
  getUserCorporatePortalsList,
  getListOfState,
  getListOfCity,
  getActiveCorporatePortalsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingAlgorithm)
