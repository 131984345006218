import { Tabs } from 'antd'
import Button from 'components/Button/index'
import { StyledTabs } from 'components/TabsPaneStyles/index.js'
import { Form } from 'components/UIComponents/FormStyles'
import PlusIcon from 'components/icons/PlusIcon'
import AddUserDrawer from 'modules/Users/Partials/AddUserDrawer'
import UsersFilter from 'modules/Users/Partials/UsersFilter'
import UsersTable from 'modules/Users/Partials/UsersTable'
import {
  HeadTitle,
  PaddedDiv,
  PageTitle,
  SectionWrapper,
  TabDiv,
  TopHeader,
  TopHeading,
} from 'modules/Users/Style/style'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const { TabPane } = Tabs
const Roles = () => {
  const [form] = Form.useForm()
  const [addUserDrawer, setAddUserDrawer] = useState(false)

  const onClickAdd = () => {
    setAddUserDrawer(true)
    setSingleUser({})
    form.resetFields()
  }

  return (
    <>
      <SectionWrapper column>
        <PageTitle>
          <HeadTitle>User Management</HeadTitle>
        </PageTitle>{' '}
        <TabDiv>
          {' '}
          <StyledTabs defaultActiveKey={'User'}>
            {' '}
            <TabPane tab="User" key="User">
              <Link to="/roles" />{' '}
            </TabPane>{' '}
            <TabPane tab="Roles and Permissions" key="roles" onchange={Roles}>
              {' '}
            </TabPane>{' '}
          </StyledTabs>
        </TabDiv>
        <PaddedDiv column>
          <TopHeader row spaceBetween alignCenter>
            <TopHeading flexStart>Roles and Permissions</TopHeading>

            <Button.Primary
              height={'38px'}
              text={'New Role'}
              icon={<PlusIcon />}
              onClick={onClickAdd}
            />
          </TopHeader>
        </PaddedDiv>
        <UsersFilter />
        <UsersTable />
      </SectionWrapper>

      <AddUserDrawer
        addUserDrawer={addUserDrawer}
        setAddUserDrawer={setAddUserDrawer}
      />
    </>
  )
}

export default Roles
