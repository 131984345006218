import { Collapse } from 'antd'
import styled from 'styled-components'

const { Panel } = Collapse

export const CustomCollapse = styled(Collapse)`
background-color: #FFFFFF; !important;


.ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 10px !important;
  font-weight: 600;
  border-radius: 10px !important;
 color: #343E59 !important;
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  border: 1px solid #E6E7EA !important;
}

 .ant-collapse-item {
    border-bottom: 0px solid #d9d9d9 !important;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding:0px;
}
.ant-collapse-content-box{
  padding-top:0px !important;
}
`

export const CustomPanel = styled(Panel)`
  margin-bottom: 20px !important;
  background-color: #ffffff !important;
  // border: 1px solid #E6E7EA;
  // border-radius: 10px;
`
