import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/User/Partials/RolesFilter/Styles/style'

const UserRoleFilter = ({ roleFilter, setRoleJourney, roleJourney }) => {
  const handleChange = selectedValues => {
    setRoleJourney(selectedValues)
  }

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={roleJourney}
        options={roleFilter}
        valueParam={'id'}
        labelParam={'value'}
        padding={'10px !important'}
        onChange={value => {
          handleChange(value)
        }}
      />
    </Wrapper>
  )
}

export default UserRoleFilter
