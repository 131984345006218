import React from 'react'
import { Modal } from 'antd'
import {
  ActionMenu,
  ButtonText,
  ItemDeleteText,
  ItemText,
  Main,
  StyledDropDownButton,
} from 'components/ActionStyles/ActionStyles'
import DownArrow from 'components/icons/DownArrow'

const { confirm } = Modal

const ActionDropdown = ({
  rowData,
  setViewUserDrawer,
  getSingleUserInfo,
  setAddUserDrawer,
  setUserId,
  updateUserStatus,
  deleteUser,
  getUserData,
}) => {
  const onClickView = rowId => {
    setViewUserDrawer(true)
    getSingleUserInfo(rowId)
  }

  const onClickEdit = rowData => {
    setAddUserDrawer(true);
    setUserId(rowData?._id);
    getSingleUserInfo(rowData?._id);
  }

  const onDelete = async () => {
    confirm({
      title: 'Delete User?',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk: async () => {
        let response = await deleteUser({ userId: rowData?._id })
        if (response?.status >= 200 && response?.status <= 300) getUserData()
      },
    })
  }

  const onUpdateStatus = async status => {
    let response = await updateUserStatus({
      userId: rowData?._id,
      status: status,
    })
    if (response?.status >= 200 && response?.status <= 300) getUserData()
  }

  const menu = (
    <ActionMenu
      width={'165px'}
      items={[
        {
          key: 'edit',
          label: <ItemText onClick={() => onClickEdit(rowData)}>Edit</ItemText>,
        },
        rowData?.is_active && {
          key: 'inactive',
          label: (
            <ItemText onClick={() => onUpdateStatus(false)}>
              Set to Inactive
            </ItemText>
          ),
        },
        !rowData?.is_active && {
          key: 'active',
          label: (
            <ItemText onClick={() => onUpdateStatus(true)}>
              Set to Active
            </ItemText>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: (
            <ItemDeleteText onClick={() => onDelete()}>
              Delete User
            </ItemDeleteText>
          ),
        },
      ]}
    />
  )

  return (
    <Main>
      <StyledDropDownButton
        overlay={menu}
        shape="round"
        trigger={['click']}
        icon={<DownArrow />}
      >
        <ButtonText onClick={() => onClickView(rowData?._id)}>View</ButtonText>
      </StyledDropDownButton>
    </Main>
  )
}

export default ActionDropdown
