import React from 'react'
import Input from 'components/Form/Input'
import Control from 'components/Control/Control'
import Label from 'components/Form/Label'
import AntdSelect from 'components/Form/Select'

import { StyledSelectDiv } from 'modules/ManageProfile/Style/style'

import {
  SectionHeading,
  StyledInputDiv,
  StyledInput,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import CONSTANTS from 'utils/constants'

const ContactInstituteDetails = ({ countryCode, setCountryCode, form }) => {
  const onCountryCodeChange = (id, value) => {
    setCountryCode(value?.option?.value)
    form?.setFieldValue('countryCode', value?.option?.value)
  }
  const CountryCode = [
    {
      id: '+91',
      value: '+91',
    },
    {
      id: '+93',
      value: '+93',
    },
    {
      id: '+92',
      value: '+92',
    },
  ]

  return (
    <>
      <SectionHeading>Contact Info</SectionHeading>

      <Input
        width="100%"
        label="Contact Person/Placement Officer"
        placeholder="Eg. Harsha Saggi"
        optional={'*'}
        name={'contactPerson'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('contact person'),
          },
          {
            pattern: CONSTANTS.REGEX.NAME,
            message: CONSTANTS.ERROR_MESSAGE.VALID('contact person'),
          },
        ]}
      />

      <Control flexStart column>
        <Label>Contact Number *</Label>
        <Control>
          <StyledSelectDiv>
            <AntdSelect
              width="100%"
              OptionData={CountryCode}
              nameParam="value"
              bordered={false}
              placeholder="+91"
              onChange={onCountryCodeChange}
              value={countryCode}
            />
          </StyledSelectDiv>
          <StyledInputDiv>
            <StyledInput
              width="100%"
              type="tel"
              placeholder="Eg: 1010101010"
              rules={[
                {
                  required: true,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('phone number'),
                },
                {
                  message: CONSTANTS.ERROR_MESSAGE.VALID('phone number'),
                  pattern: CONSTANTS.REGEX.MOBILE_NUMBER,
                },
              ]}
              name={'phoneNumber'}
            />
          </StyledInputDiv>
        </Control>
      </Control>

      <Input
        width="100%"
        label="Email ID"
        placeholder="Eg. example@gmail.com"
        optional={'*'}
        name={'email'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('email address'),
          },
          {
            type: 'email',
            message: CONSTANTS.ERROR_MESSAGE.VALID('email address'),
          },
        ]}
      />
    </>
  )
}

export default ContactInstituteDetails
