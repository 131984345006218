import { connect } from 'react-redux'
import EventCatalogue from 'modules/EventCatalogue/index'
import {
  getEventList,
  getNotificationType,
  getNotificationCatalogues,
  getNotificationTypeByID,
  AddNewNotificationTemplate,
  EditNewNotificationTemplate,
  DeleteNewNotificationTemplate,
  DeleteEvent,
  DeleteSubEvent,
  ManageNotificationCatalogue,
  setEventList,
  reorderEvent,
  setNotificationTypeByID,
  GetAllNotificationTemplate,
  setAllEventTemplate
}  from 'modules/EventCatalogue/actions'
import {
  createInstituteSysConfig,
  getScreenData
} from 'modules/Systemconfig/Partials/PermissionSettings/action'
import Selector from 'modules/EventCatalogue/selectors'
import Instituteselectors from 'modules/Systemconfig/Partials/PermissionSettings/selectors'

const mapStateToProps = state => ({
  eventList: Selector.getEventListData(state), 
  notificationType: Selector.getNotificationTypeData(state),
  notificationTypeById: Selector.getNotificationTypeByIdData(state),
  notificationCatalogue: Selector.getNotificationCatalogueData(state),
  screenData: Instituteselectors?.getscreensData(state),
  allEventTemplate: Selector?.getAllEventTemplate(state)
})

const mapDispatchToProps = {
  getEventList,
  getNotificationType,
  createInstituteSysConfig,
  getNotificationCatalogues,
  getNotificationTypeByID,
  AddNewNotificationTemplate,
  EditNewNotificationTemplate,
  DeleteNewNotificationTemplate,
  DeleteEvent,
  DeleteSubEvent,
  ManageNotificationCatalogue,
  setEventList,
  reorderEvent,
  getScreenData,
  setNotificationTypeByID,
  GetAllNotificationTemplate,
  setAllEventTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCatalogue)
