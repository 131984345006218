import { NavWrapper } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/Style/style'
import InstituteFilter from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/InstituteFilter'
import InstituteTable from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/InstituteTable'
import { getInstitutesListData } from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/actions'
import { getSingleInstituteData } from 'modules/Onboarding/Partials/Institutes/Register/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const InstituteInfoTable = ({ createInstitute, scrollToAppContainer,clearInput,setClearInput }) => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const [searchBy, setSearchBy] = useState('')
  const [instituteStatus, setInstituteStatus] = useState('')
  const [location, setLocation] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [filterData, setFilterData] = useState({})
  

  useEffect(() => {
    dispatch(
      getInstitutesListData({
        currentPage,
        searchBy,
        filterData,
        sortBy,
      })
    )
  }, [currentPage, searchBy, filterData, sortBy])

  useEffect(() => {
    setCurrentPage(0)
  }, [searchBy, instituteStatus, location, sortBy])

  const instituteList = useSelector(
    state => state?.InstituteList?.institutesList
  )

  return (
    <NavWrapper column>
      <InstituteFilter
        instituteList={instituteList}
        createInstitute={createInstitute}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        searchValue={searchBy}
        setSearchValue={setSearchBy}
        instituteStatus={instituteStatus}
        setInstituteStatus={setInstituteStatus}
        location={location}
        setLocation={setLocation}
        setFilterData={setFilterData}
        filterData={filterData}
        clearInput={clearInput}
      />
      <div>
        <InstituteTable
          getSingleInstituteData={getSingleInstituteData}
          instituteList={instituteList}
          createInstitute={createInstitute}
          currentPage={currentPage + 1}
          setCurrentPage={setCurrentPage}
          setSortBy={setSortBy}
          setClearInput={setClearInput}
          getInstitutesListData={getInstitutesListData}
          sortBy={sortBy}
        />
      </div>
    </NavWrapper>
  )
}

export default InstituteInfoTable
