import React, { useEffect, useState } from 'react'
import { Dropdown, Spin } from 'antd'
import ExportIcon from 'components/icons/ExportIcon'
import Excel from '../..//modules/Reports/Components/Images/ExcelImg.svg'
import PdfImg from '../..//modules/Reports/Components/Images/PdfImg.svg'
import Button from 'components/Button/index'
import ReactExport from 'react-data-export'
import PluginLive from 'components/Images/PluginLive.png'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const ExcelFile = ReactExport.ExcelFile
import {
  DropDownContent,
  DropdownButton,
  DropDownOption,
} from 'components/ExportButton/style.js'

export default function ExportButton({
  dataset,
  title,
  loading,
  clickedExcel,
  data,
  setIsExcel,
  count,
  columnWidth,
  columnFontSize,
  horizontalAlignment,
  userData,
}) {
  const [currentDate, setCurrentDate] = useState(new Date())
  useEffect(() => {
    setCurrentDate(new Date())
  }, [])
  // Define the options for formatting the date
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }
  const formattedDate = currentDate.toLocaleDateString('en-GB', options)
  const CorpTittle = title.toLowerCase().includes('list of corp. empanelled')
  const instTittle = title.toLowerCase().includes('list of inst. empanelled')

  // Function to check if the data contains "college" and generate the header accordingly
  function generateHeader(columns, title) {
    if (
      columns.some(column =>
        column.title.toLowerCase().includes('contact number')
      ) &&
      columns.some(column =>
        column.title.toLowerCase().includes('corporate name')
      )
    ) {
      return [
        [
          {
            content: 'ID',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Corporate Name',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Industry',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            // content: 'Onboard',
            content: CorpTittle ? 'Empanelled' : 'Onboard',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Location',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Address',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Contact Info',
            colSpan: 3,
            styles: { halign: 'center', lineWidth: 0, cellPadding: 0 },
          },
        ],

        [
          '',
          '',
          '',
          '',
          '',
          '',
          {
            content: 'Name',
            styles: { halign: 'center', lineWidth: 0.1, cellPadding: 1 },
          },
          {
            content: 'Email Id',
            styles: { halign: 'center', lineWidth: 0.1, cellPadding: 1 },
          },
          {
            content: 'Mobile #',
            styles: { halign: 'center', lineWidth: 0.1, cellPadding: 1 },
          },
        ],
      ]
    } else if (
      columns.some(column =>
        column.title.toLowerCase().includes('contact number')
      )
    ) {
      return [
        [
          {
            content: 'ID',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Institute Name',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'University',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Tier',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: instTittle ? 'Empanelled' : 'Onboard',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'City',
            styles: {
              halign: 'center',
              lineWidth: 0,
              cellPadding: { top: 6, bottom: 0 },
            },
          },
          {
            content: 'Contact Info',
            colSpan: 3,
            styles: { halign: 'center', lineWidth: 0, cellPadding: 0 },
          },
        ],

        [
          '',
          '',
          '',
          '',
          '',
          '',
          {
            content: 'Name',
            styles: { halign: 'center', lineWidth: 0.1, cellPadding: 1 },
          },
          {
            content: 'Email Id',
            styles: { halign: 'center', lineWidth: 0.1, cellPadding: 1 },
          },
          {
            content: 'Mobile #',
            styles: { halign: 'center', lineWidth: 0.1, cellPadding: 1 },
          },
        ],
      ]
    } else {
      return [columns.map(column => column.title)]
    }
  }

  const generatePdf = () => {
    const doc = new jsPDF({
      format: 'a4',
      orientation: 'landscape',
    })
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    const tableWidth = 100
    const tableHeight = 50
    const marginLeft = (pageWidth - tableWidth) / 2
    const marginTop = (pageHeight - tableHeight) / 2
    const headerHeight = 24
    doc.setProperties({
      marginLeft: marginLeft,
      marginTop: marginTop,
    })
    const headerPartition = 'Generated By    :'
    const headerPartition1 = ` ${userData?.first_name} ${userData?.last_name}`
    const headerPartition13 = 'Generated Date :'
    const headerPartition3 = formattedDate
    const headerPartition2 = title

    doc.setFont('', 'bold')
    doc.setFontSize(10)

    doc.setFont('arial', 'normal')
    doc.setFontSize(10)

    doc.setFont('', 'bold')
    doc.setFontSize(12)

    const localImagePath = PluginLive
    fetch(localImagePath)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64Image = reader.result

          // doc.addImage(base64Image, 'JPEG', 254, 6, 38, 20)
          const columnWidths = columnWidth
          const columnFontSize = 10
          const HAlign = horizontalAlignment
          dataset.forEach(table => {
            const tableData = table?.data?.map(row =>
              row.map((cell, columnIndex) => {
                const cellStyles = {
                  cellWidth: columnWidths?.[columnIndex],
                  fontSize: columnFontSize,
                  halign: HAlign?.[columnIndex],
                  valign: 'middle',
                }

                if (
                  typeof cell?.value === 'string' &&
                  cell?.value.includes(' (')
                ) {
                  const lines = cell?.value?.split(/\s(?=\()/)
                  cell.content = lines?.join('\n')
                } else {
                  cell.content = cell?.value
                }
                return {
                  content: cell.content,
                  styles: cellStyles,
                }
              })
            )

            const header = generateHeader(table?.columns)
            doc.autoTable({
              head: header,
              body: tableData,
              startY: headerHeight,
              margin: { top: headerHeight, left: 5 },
              headStyles: {
                valign: 'middle',
                halign: 'center',
                lineWidth: 0, // Border width for the headers
                lineColor: [251, 250, 255], // Border color for the headers// Set horizontal alignment to center for the table headers
              },
              columnStyles: {
                ...columnWidths?.map((width, index) => ({
                  [index]: {
                    cellWidth: width,
                    halign: 'center',
                    valign: 'middle',
                  },
                })),
              },
              didDrawCell: data => {
                if (data.section === 'head') {
                  doc.setDrawColor(251, 250, 255)
                  doc.setLineWidth(0.0)

                  doc.line(
                    data.cell.x,
                    data.cell.y,
                    data.cell.x,
                    data.cell.y + data.cell.height
                  ) // Left border
                }
                doc.addImage(base64Image, 'JPEG', 254, 6, 38, 18)
              },

              didDrawPage: data => {
                const totalPages = doc.internal.getNumberOfPages()
                const currentPage = data.pageNumber
                if (currentPage < totalPages) {
                  doc.addPage() // add a new page if not the last page
                }
                const pageWidth = doc.internal.pageSize.getWidth()
                const pageHeight = doc.internal.pageSize.getHeight()

                doc.setFont('', 'bold')
                doc.setFontSize(10)
                doc.text(headerPartition, data.settings.margin.left + 1, 17)
                doc.setFont('', 'bold')
                doc.text(headerPartition13, data.settings.margin.left + 1, 22)
                doc.setFont('', 'normal')

                doc.text(headerPartition1, data.settings.margin.left + 32, 17)
                doc.setFont('', 'normal')

                doc.text(headerPartition3, data.settings.margin.left + 33, 22)
                doc.setFont('', 'bold')
                doc.setFontSize(11)

                doc.text(headerPartition2, data.settings.margin.left + 120, 12)
                doc.setFont('', 'normal')

                // Update the footer with total pages
                for (let i = 1; i <= totalPages; i++) {
                  const footerText = `Page - ${i} `
                  const textWidth =
                    (doc.getStringUnitWidth(footerText) *
                      doc.internal.getFontSize()) /
                    doc.internal.scaleFactor
                  const textX = pageWidth - textWidth - 10
                  const textY = pageHeight - 10
                  doc.setPage(i)

                  doc.text(footerText, textX, textY)
                }
                // doc.text(
                //   10,
                //   pageHeight - 10,
                //   '© PluginLive Technologies pvt ltd'
                // )
                // Align to the left
                doc.text(
                  '© PluginLive Technologies pvt ltd',
                  5,
                  pageHeight - 10
                )
              },
            })
          })

          doc.save(title + '.pdf')
          setIsExcel('')
        }
      })
  }

  const downloadExcel2 = excelData => {
    const { columns, data } = excelData[0]
    const worksheetData = data.map(row => row.map(cell => cell.value))
    const worksheet = XLSX.utils.aoa_to_sheet([
      columns.map(column => column.title),
      ...worksheetData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, title)
    columns.forEach((column, index) => {
      if (column.width) {
        worksheet['!cols'] = worksheet['!cols'] || []
        worksheet['!cols'][index] = column.width
      }
      if (column.padding) {
        worksheet['!cols'] = worksheet['!cols'] || []
        worksheet['!cols'][index] = {
          ...worksheet['!cols'][index],
          ...column.padding,
        }
      }
      if (column.height) {
        worksheet['!rows'] = worksheet['!rows'] || []
        worksheet['!rows'][0] = column.height
      }
    })
    XLSX.writeFile(workbook, title + '.xlsx')
    setIsExcel('')
  }

  useEffect(() => {
    if (!loading && dataset?.length > 0) {
      if (data == 'excel') {
        setIsExcel('')
        downloadExcel2(dataset)
      } else if (data == 'pdf') {
        setIsExcel('')
        generatePdf()
      }
    }
  }, [loading, dataset])

  const items = [
    {
      key: '1',
      label: (
        <DropDownOption>
          <ExcelFile
            filename={title}
            name={title}
            element={
              <DropdownButton onClick={() => clickedExcel('excel')}>
                <img src={Excel} alt="Excel" />
                <DropDownContent>Excel</DropDownContent>
              </DropdownButton>
            }
          ></ExcelFile>
        </DropDownOption>
      ),
    },
    {
      key: '2',
      label: (
        <DropDownOption>
          {' '}
          <DropdownButton onClick={() => clickedExcel('pdf')}>
            <img src={PdfImg} alt="pdf" />
            <DropDownContent>Pdf</DropDownContent>
          </DropdownButton>
        </DropDownOption>
      ),
    },
  ]

  return (
    <>
      {loading && data !== '' ? (
        <>
          {' '}
          <Spin />
          Loading...
        </>
      ) : (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottom"
          spin={loading}
          disabled={count < 1 || count >= 5000}
        >
          <Button.Secondary
            height={'38px'}
            text={'Export'}
            icon={<ExportIcon />}
          />
        </Dropdown>
      )}
    </>
  )
}
