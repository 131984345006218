const getCountryList = state => state?.CorporateData?.countryList
const getStateList = state => state?.CorporateData?.stateList
const getCityList = state => state?.CorporateData?.cityList
const getCountrycodeList = state => state?.CorporateData?.countrycodeList
const getIndustryList = state => state?.CorporateData?.industryList
const getUniversityList = state => state?.CorporateData?.universityList
const getMasterSearchList = state => state?.CorporateData?.masterSearchApi

const selectors = {
  getCountryList,
  getStateList,
  getCityList,
  getIndustryList,
  getUniversityList,
  getCountrycodeList,
  getMasterSearchList,
}

export default selectors
