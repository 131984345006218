import { Divider, Empty, Tooltip } from 'antd'
import Button from 'components/Button/index'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  SpanTag,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { Form } from 'components/UIComponents/FormStyles'
import ReportSearchFilter from 'components/UIComponents/ReportSearchFilter'
import {
  Content,
  Footer,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import CloseFilter from 'components/icons/CloseFilter'
import {
  DateFormat,
  RefinedData,
  arrayIntoString,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessageReport from 'utils/WarningMessageReport'
import { setCorporateListForReport } from '../../../../../actions'
import CityFilter from '../CorporateFilter/Partials/CityFilter'
import DateRangeFilter from '../CorporateFilter/Partials/DateRangeFilter'
import StateFilter from '../CorporateFilter/Partials/StateFilter'
const CorporateTable = ({
  stateLoading,
  hasProp,
  setPageNum,
  setSortBy,
  searchValue,
  setSearchValue,
  setSectorValue,
  cityValue,
  setCityValue,
  industryValue,
  setIndustryValue,
  stateList,
  setFilterData,
  setKeyState,
  keyState,
  stateValue,
  setStateValue,
  cityList,
  tableData,
  keyCity,
  setKeyCity,
  createdAtEnd,
  createdAtStart,
  setCreatedAtStart,
  setCreatedAtEnd,
  isEmpanelled,
  pageNum,
  setToolTip,
  setClearDate,
  clearDate,
  loading,
  loadingCorp,
  isReport,
  setLoadingCity,
  loadingCity,
  searchQuery,
  degreeLoader,
  setSearchQuery,
  degreeListData,
  setSearchDegree,
  degreeList,
  setDegreeList,
  loadingDep,
  page,
  count,
  loadMoreData,
  loadMoreCityData,
  cityCount,
  stateCount,
  loadMoreStateData,
  statePage,
  cityPage,
  singleSectorLoading,
  setKeySingleSector,
  singleSectorValue,
  setSingleSectorValue,
  keySingleSector,
  singleSectorData,
  singleSectorDataFinal,
  singleSectorCount,
  loadMoreSingleSectorData,
  setSingleSectorPage,
  singleSectorPage,
  singleIndustryPage,
  setSingleIndustryPage,
  singleIndustryDataFinal,
  singleIndustryCount,
  loadMoreSingleIndustryData,
  singleIndustryData,
  singleIndustryValue,
  setSingleIndustryValue,
  keySingleIndustry,
  singleIndustryLoading,
  setKeySingleIndustry,
  getStateList,
  setStateLoading,
  setMoreLoading,
  getCityList,
  getSectorList,
  setSectorLoading,
  keySector,
  getSingleIndustryList,
  setSingleIndustryLoading,
  getSingleSectorList,
  setSingleSectorLoading,
}) => {
  const [open, setOpen] = useState(true)

  const [flag, setFlag] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }

  const onPageChange = num => {
    setPageNum(num)
  }

  useEffect(() => {
    dispatch(setCorporateListForReport([]))
  }, [])

  const submitFilter = () => {
    let filterDatas = {
      sector: arrayIntoString(singleSectorValue),
      state: arrayIntoString(stateValue),
      city: arrayIntoString(cityValue),
      industry: arrayIntoString(singleIndustryValue),
      createdAtStart: createdAtStart,
      createdAtEnd: createdAtEnd,
    }

    if (createdAtStart) {
      if (!createdAtEnd) {
        WarningMessageReport(
          <>
            Kindly select <q>To Date</q> from Date Range
          </>
        )
      }
    }
    if (createdAtEnd) {
      if (!createdAtStart) {
        WarningMessageReport(
          <>
            Kindly select <q>From date</q> from Date Range
          </>
        )
      }
    }

    if (createdAtEnd && createdAtStart) {
      setFilterData(filterDatas)
    }
    if (!createdAtEnd && !createdAtStart) {
      setFilterData(filterDatas)
    }

    setToolTip(!hasProp)
  }

  const onCancelFilter = () => {
    const keyState = '' // Fetch all states after clearing the search
    const flag = 'LC'
    const page = 0
    setFlag(true)
    setFilterData({
      industry: '',
      city: '',
      sector: '',
      state: '',
      createdAtEnd: '',
      createdAtStart: '',
    })
    setFilterData({})
    setDegreeList([])
    dispatch(setCorporateListForReport([]))
    setCityValue([])
    setStateValue([])
    setIndustryValue([])
    setSectorValue([])
    setSingleSectorValue([])
    setSingleIndustryValue([])
    setClearDate(true)
    getStateList({
      setStateLoading,
      keyState, // Fetch all states after clearing the search
      flag,
      page,
      setMoreLoading,
    })
    getCityList({ setLoadingCity, keyCity: '', flag: 'LC', page: 0 })
    getSectorList({ setSectorLoading, keySector: '', flag, page })
    getSingleIndustryList({
      setSingleIndustryLoading,
      keySingleIndustry: '',
      flag: 'LC',
      page: 0,
    })
    getSingleSectorList({
      setSingleSectorLoading,
      keySingleSector: '',
      flag: 'LC',
      page: 0,
    })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'prefix_id',
      key: 'prefix_id',
      align: 'center',
      visible: true,
      sorter: true,

      width: '5%',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'CORPORATES NAME',
      dataIndex: 'name',
      key: 'name',
      visible: true,
      width: '10%',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          <Tooltip title={tableData?.name}>
            <SpanTag>{RefinedData(tableData?.name)}</SpanTag>
          </Tooltip>
        </RowNumberDataReport>
      ),
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',

      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.industry)}
        </RowNumberDataReport>
      ),
    },
    {
      title: isEmpanelled ? 'EMPANELLED' : 'ONBOARD',
      dataIndex: 'created_at',
      key: 'onboard',
      align: 'center',
      sorter: true,
           render: (_, tableData) => {
             const createdAt = tableData?.created_at;
             const formattedDate = createdAt
               ? new Date(createdAt).toLocaleDateString('en-GB') // 'en-GB' formats as DD/MM/YYYY
               : '-';
             return (
               <RowNumberDataReportAlignCenter>
                 {formattedDate.split('/').join('-')} {/* Convert to DD-MM-YYYY */}
               </RowNumberDataReportAlignCenter>
             );
           },
      visible: false,
    },
    {
      title: 'CONTACT PERSON',
      dataIndex: 'contactPerson',
      key: 'contactPerson',
      sorter: true,

      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.contactperson)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'EMAIL ID',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      width: '15%',

      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          <Tooltip title={tableData?.contactemail}>
            <SpanTag>{RefinedData(tableData?.contactemail)}</SpanTag>
          </Tooltip>
        </RowNumberDataReport>
      ),
    },
    {
      title: 'CONTACT NO',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      sorter: true,
      align: 'center',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.contactnumber)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'city',
      key: 'city',
      sorter: true,

      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.city)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address',

      key: 'address',
      width: '25%',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {' '}
          {(tableData?.addressLine1 ? tableData?.addressLine1 + ',' : ``) +
            (tableData?.addressLine2 ? tableData?.addressLine2 + ',' : ``) +
            (tableData?.city ? tableData?.city + ',' : ``) +
            (tableData?.state ? tableData?.state + ',' : ``) +
            (tableData?.country ? tableData?.country : ``)}{' '}
        </RowNumberDataReport>
      ),
    },
  ]
  return (
    <Wrapper>
      <ReportSearchFilter
        setOpen={setOpen}
        open={open}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={isReport}
        totalCount={tableData?.count}
        currentCount={10 * (pageNum - 1) + tableData?.result?.length}
        setPageNum={setPageNum}
      />
      <Divider style={{ margin: '0' }} />
      <Content isOpen={open}>
        {isEmpanelled && (
          <div style={{ width: '100%', display: 'flex' }}>
            <Wrapper style={{ marginLeft: '1rem' }}>
              <StateFilter
                flag={flag}
                setFlag={setFlag}
                stateList={singleSectorDataFinal || []}
                stateValue={singleSectorValue}
                setStateValue={setSingleSectorValue}
                loading={singleSectorLoading}
                setKeyState={setKeySingleSector}
                keyState={keySingleSector}
                isReport={true}
                stateCount={singleSectorCount}
                loadMoreStateData={loadMoreSingleSectorData}
                statePage={singleSectorPage}
                name="Sector"
              />
            </Wrapper>

            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <Wrapper style={{ marginLeft: '1rem' }}>
              <StateFilter
                flag={flag}
                setFlag={setFlag}
                stateList={singleIndustryDataFinal || []}
                stateValue={singleIndustryValue}
                setStateValue={setSingleIndustryValue}
                loading={singleIndustryLoading}
                setKeyState={setKeySingleIndustry}
                keyState={keySingleIndustry}
                isReport={true}
                stateCount={singleIndustryCount}
                loadMoreStateData={loadMoreSingleIndustryData}
                statePage={singleIndustryPage}
                name="Industry"
              />
            </Wrapper>

            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <StateFilter
              flag={flag}
              setFlag={setFlag}
              stateList={stateList || []}
              stateValue={stateValue}
              setStateValue={setStateValue}
              loading={stateLoading}
              setKeyState={setKeyState}
              keyState={keyState}
              isReport={isReport}
              stateCount={stateCount}
              loadMoreStateData={loadMoreStateData}
              statePage={statePage}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />

            <CityFilter
              flag={flag}
              setFlag={setFlag}
              cityList={cityList}
              cityValue={cityValue}
              setCityValue={setCityValue}
              loading={loading}
              search={searchValue}
              keyCity={keyCity}
              setKeyCity={setKeyCity}
              isReport={isReport}
              setLoadingCity={setLoadingCity}
              loadingCity={loadingCity}
              cityPage={cityPage}
              loadMoreCityData={loadMoreCityData}
              cityCount={cityCount}
            />

            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />

            <DateRangeFilter
              isEmpanelled={isEmpanelled}
              setClearDate={setClearDate}
              clearDate={clearDate}
              createdAtStart={createdAtStart}
              createdAtEnd={createdAtEnd}
              setCreatedAtStart={setCreatedAtStart}
              setCreatedAtEnd={setCreatedAtEnd}
              form={form}
              isReport={isReport}
            />
          </div>
        )}

        {!isEmpanelled && (
          <div style={{ width: '100%', display: 'flex' }}>
            <Wrapper style={{ marginLeft: '1rem' }}>
              <StateFilter
                stateList={singleSectorDataFinal || []}
                stateValue={singleSectorValue}
                setStateValue={setSingleSectorValue}
                loading={singleSectorLoading}
                setKeyState={setKeySingleSector}
                flag={flag}
                setFlag={setFlag}
                keyState={keySingleSector}
                isReport={true}
                stateCount={singleSectorCount}
                loadMoreStateData={loadMoreSingleSectorData}
                statePage={singleSectorPage}
                name="Sector"
              />
            </Wrapper>

            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <Wrapper style={{ marginLeft: '1rem' }}>
              <StateFilter
                stateList={singleIndustryDataFinal || []}
                stateValue={singleIndustryValue}
                setStateValue={setSingleIndustryValue}
                loading={singleIndustryLoading}
                setKeyState={setKeySingleIndustry}
                keyState={keySingleIndustry}
                isReport={true}
                flag={flag}
                setFlag={setFlag}
                stateCount={singleIndustryCount}
                loadMoreStateData={loadMoreSingleIndustryData}
                statePage={singleIndustryPage}
                name="Industry"
              />
            </Wrapper>
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
            <StateFilter
              flag={flag}
              setFlag={setFlag}
              stateList={stateList || []}
              stateValue={stateValue}
              setStateValue={setStateValue}
              loading={stateLoading}
              setKeyState={setKeyState}
              keyState={keyState}
              isReport={isReport}
              stateCount={stateCount}
              loadMoreStateData={loadMoreStateData}
              statePage={statePage}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />

            <CityFilter
              flag={flag}
              setFlag={setFlag}
              cityList={cityList}
              cityValue={cityValue}
              setCityValue={setCityValue}
              loading={loading}
              search={searchValue}
              keyCity={keyCity}
              setKeyCity={setKeyCity}
              isReport={isReport}
              setLoadingCity={setLoadingCity}
              loadingCity={loadingCity}
              cityPage={cityPage}
              loadMoreCityData={loadMoreCityData}
              cityCount={cityCount}
            />

            <Divider
              type="vertical"
              style={{
                height: '100%',
                width: '2px',
                borderTop: '18rem solid #e8e8e8',
              }}
            />
          </div>
        )}
      </Content>
      <Divider style={{ margin: '0' }} />
      <Footer style={{ display: open ? '' : 'none' }}>
        <div
          style={{ marginRight: '180px' }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpen(false)
          }}
          onKeyDown={() => {}}
        >
          <CloseFilter style={{ marginBottom: '-20px', cursor: 'pointer' }} />
        </div>
        <div style={{ marginRight: '30px' }}>
          <Button.Secondary
            height="35px"
            htmlType="Clear"
            text={'Clear Filter'}
            width={'144px'}
            onClick={() => onCancelFilter()}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <Button.Primary
            height="35px"
            htmlType="Save Filter"
            text={'Apply'}
            width={'144px'}
            onClick={() => submitFilter()}
          />
          {/* </Tooltip>
            </MarginLeft> */}
        </div>
      </Footer>

      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        locale={{
          emptyText: (
            <Empty
              description={
                !hasProp
                  ? 'Kindly Select Filter Option to View the Data'
                  : 'No Corporate Found'
              }
            />
          ),
        }}
        scroll={{
          x: 2000,
          y: 500,
        }}
        loading={loadingCorp}
      />

      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={pageNum}
        currentCount={tableData?.result?.length}
      />
    </Wrapper>
  )
}

export default CorporateTable
