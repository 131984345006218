import { Empty, Spin } from 'antd'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { EmptyWrapper } from 'modules/Dashboard/Styles/style'
import { RefinedData } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React from 'react'
const StudentSkillWiseTable = ({
  loading,
  setPageNum,
  pageNum,
  tableData,
  setSortBy,
}) => {
  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }

  const onPageChange = num => {
    setPageNum(num)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'd.prefix_id',
      key: 'd.prefix_id',
      visible: true,
      sorter: true,
      width: '5%',
      align: 'center',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'COLLEGE NAME',
      dataIndex: 'd.campus_name',
      key: 'd.campus_name',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.campus_name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'UNIVERSITY',
      dataIndex: 'e.university',
      key: 'e.university',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.university)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'TIER',
      dataIndex: 'e.tier',
      key: 'e.tier',
      sorter: true,
      align: 'center',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.tier)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'DEGREE',
      dataIndex: 'c.degree',
      key: 'c.degree',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {(tableData?.short_form
            ? RefinedData(tableData?.short_form) + ' - '
            : '') + RefinedData(tableData?.degree)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'c.department',
      key: 'c.department',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.department)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'SKILLS',
      dataIndex: ' c.skills',
      key: ' c.skills',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.skills)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'NO.OF STUDENTS PLACED',
      dataIndex: 'studentCount',
      key: 'studentCount',
      sorter: true,
      align: 'center',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.studentCount)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'p.corr_city',
      key: 'p.corr_city',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.corr_city)}
        </RowNumberDataReport>
      ),
    },
  ]
  return (
    <Wrapper>
      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        locale={{
          emptyText: (
            <EmptyWrapper center isSpin={loading}>
              {!loading ? (
                <Empty description={'No students found!'} />
              ) : (
                <Spin />
              )}
            </EmptyWrapper>
          ),
        }}
      />

      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={pageNum}
        currentCount={tableData?.result?.length}
      />
    </Wrapper>
  )
}

export default StudentSkillWiseTable
