import { connect } from 'react-redux'
import InstituteOnboardingForm from 'modules/Onboarding/Partials/Institutes/Register'

import instituteSelector from 'modules/Onboarding/Partials/Institutes/Register/selectors'

import {
  getSingleInstituteData,
  editInstitute,
  setInstituteFormInfo,
} from 'modules/Onboarding/Partials/Institutes/Register/actions'

const mapStateToProps = state => ({
  singleInstituteData: instituteSelector.getSingleInstituteData(state),
  instituteFormInfo: instituteSelector.getInstituteFormInfo(state),
})

const mapDispatchToProps = {
    getSingleInstituteData,
    editInstitute,
    setInstituteFormInfo,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstituteOnboardingForm)
