import CheckIconGreen from 'components/icons/CheckIconGreen'
import React from 'react'

import {
  RadioGroup,
  TwoFields,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import {
  ColorSpan,
  Div,
  DivPadding,
  DivPaddingExtra,
  IconAlign,
  PlanStyle,
  SpanPadding,
  SpanWord,
  StyledRadioPlan,
} from '../../Style/style'

const PlanDetails = () => {
  return (
    
      <PlanStyle>
        <TwoFields spaceBetween>
          <Div>
            <RadioGroup value="pilot" onChange={''}>
              <StyledRadioPlan boxed={true} value="pilot" label="Pilot" />
            </RadioGroup>
            <DivPadding>Unlimited time</DivPadding>
          </Div>
          <div>
            <SpanWord>Free</SpanWord>
            <ColorSpan>/month</ColorSpan>
          </div>
        </TwoFields>
        <DivPaddingExtra>
          <div>
            <IconAlign>
              <CheckIconGreen />
            </IconAlign>
            <SpanPadding> Access to basic features</SpanPadding>
          </div>
          <div>
            <IconAlign>
              <CheckIconGreen />
            </IconAlign>
            <SpanPadding> Basic reporting and analytics</SpanPadding>
          </div>
          <div>
            <IconAlign>
              <CheckIconGreen />
            </IconAlign>
            <SpanPadding> Unlimited collaborators</SpanPadding>
          </div>
          <div>
            <IconAlign>
              <CheckIconGreen />
            </IconAlign>
            <SpanPadding> Plugins,widgets and templates</SpanPadding>
          </div>
          <div>
            <IconAlign>
              <CheckIconGreen />
            </IconAlign>
            <SpanPadding> Mobile app</SpanPadding>
          </div>
        </DivPaddingExtra>
      </PlanStyle>
    
  )
}

export default PlanDetails
