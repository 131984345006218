import { connect } from 'react-redux'
// import CorporateSysConfig from 'modules/Systemconfig/Partials/CorporateSettings/index'
import BillingSysConfig from 'modules/Systemconfig/Partials/BillingSettings/index'
// import CorporateSysConfig from '../../../.././/Systemconfig/Partials/CorporateSettings/index'
import {
  getStudentListForReport,
  getCollegeList,
  getCourseList,
  getDegreeList,
  getSpecialisationList,
  getCorporateNameList,
} from 'modules/Reports/Partials/Students/actions'

import selectors from 'modules/Reports/Partials/Students/selectors'

const mapStateToProps = state => ({
  studentListDataForReport: selectors?.studentListReportData(state),
  collegeList: selectors?.CollegeListData(state),
  courseList: selectors?.courseListData(state),
  degreeList: selectors?.degreeListData(state),
  specialisationList: selectors?.specialisationListData(state),
  corporateNameList: selectors?.corporateNameList(state),
})

const mapDispatchToProps = {
  getStudentListForReport,
  getCollegeList,
  getCourseList,
  getDegreeList,
  getSpecialisationList,
  getCorporateNameList,
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSysConfig)
