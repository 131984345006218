import React from 'react'
import { VerticalDivider, Span, Count } from '../../Styles/style'

function CountDetails({ onboardingCount = 0, verifyBendingCount = 0 }) {
  return (
    <Count>
      Onboarded Clients <Span> {onboardingCount} </Span>
      <VerticalDivider type="vertical" />
      Verification Pending <Span> {verifyBendingCount} </Span>
    </Count>
  )
}

export default CountDetails
