import { Divider } from 'antd'
import Flex from 'components/Flex'
import BlackFilter from 'components/icons/BlackFilter'
import SearchInput from 'components/SearchInput'
import { TableTop } from 'components/UIComponents/ReportSearchFilter/Style/style.js'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'
import OpenFilter from '../../icons/OpenFilter'
export const FilterFlex = styled(Flex)`
  width: 40%;
`
export const FilterOpenClose = styled.div`
  display: flex;
  cursor: pointer;
`

export const FilterFlexInput = styled(Flex)`
  width: 20%;
`
export const FilterFlexButton = styled(Flex)`
  width: 30%;
  margin-bottom: -30px;
`
export const FilterText = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.BLACK};
`
export const FilterTextLabel = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_60};
`

const ReportSearchFilter = ({
  totalCount = 0,
  currentCount = 0,
  searchValue,
  setSearchValue,
  open,
  setOpen,
  placeholder = 'Search by keyword',
  isReport,
  setPageNum,
}) => {
  return (
    <TableTop centerVertically>
      <FilterFlex centerVertically>
        <FilterOpenClose
          onClick={() => {
            setOpen(!open)
          }}
        >
          <BlackFilter />
          <FilterText>{'Filters'}</FilterText>
        </FilterOpenClose>
      </FilterFlex>
      <FilterFlexButton centerVertically onClick={() => setOpen(true)}>
        {open ? '' : <OpenFilter />}
      </FilterFlexButton>

      <SearchInput
        background="#F8F8F8"
        width={'20%'}
        placeholder={placeholder}
        bordered={false}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={isReport}
        setCurrentPage={setPageNum}
      />
      <Divider type="vertical" style={{ height: '2rem', width: '2px' }} />
      <FilterTextLabel>
        Showing {!currentCount ? 0 : currentCount} of {totalCount}
      </FilterTextLabel>
    </TableTop>
  )
}

export default ReportSearchFilter
