import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Col, Form, Menu, Row, Tabs } from 'antd'
import Button from 'components/Button/index'
import Flex from 'components/Flex'
import Password from 'components/Form/Password'
import {
  ImageColPadding,
  InputLabel,
  LabelColPadding,
  MessageDiv,
  RowPadding,
  SectionWrapper,
  StyledDivider,
  StyledDividerTab,
  StyledMenu,
  TopHeader,
  TopHeading,
} from 'modules/ManageProfile/Style/style.js'
import {
  changePassword,
  getUserPreference,
} from 'modules/ManageProfile/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import { encrypt } from 'utils/encrypt'
import CONSTANTS from '../../utils/constants'

const { TabPane } = Tabs

const ManageProfile = () => {
  const dispatch = useDispatch()
  const formRef = React.createRef()

  useEffect(() => {
    dispatch(getUserPreference())
  }, [])

  const [formPwd] = Form.useForm()
  const [tab, setTab] = useState('password')

  const submitHandler2 = async values => {
    let payload = {
      currentPassword: await encrypt(values.currentPwd),
      newPassword: await encrypt(values.newPwd),
    }

    if (values.confirmPwd === values.newPwd) {
      if (values.currentPwd === values.newPwd) {
        formRef.current.setFields([
          {
            name: 'newPwd',
            errors: ['Your New password and Current password  are Same match.'],
          },
        ])
      } else {
        let response = await dispatch(changePassword(payload))
        if (response === true) {
          formPwd.resetFields()
          SuccessMessage('Password Changed Successfully')
        } else {
          ErrorMessage(response?.response?.data?.message?.message)
        }
      }
    } else {
      formRef.current.setFields([
        {
          name: 'confirmPwd',
          errors: ['Your New password and Confirm password do not match.'],
        },
      ])
    }
  }

  const changeTab = e => {
    setTab(e.key)
  }

  return (
    <SectionWrapper column>
      <TopHeader row spaceBetween alignCenter>
        <TopHeading flexStart>Manage Profile</TopHeading>
      </TopHeader>
      <StyledDivider />
      <Row>
        <Col span={5}>
          <StyledMenu defaultSelectedKeys="password" onClick={changeTab}>
            <Menu.Item key="password">Change Password</Menu.Item>
            <StyledDividerTab />
          </StyledMenu>
        </Col>
        <ImageColPadding span={19}>
          {tab === 'password' && (
            <Form
              scrollToFirstError
              onFinish={submitHandler2}
              id={'ChangePasswordForm'}
              form={formPwd}
              ref={formRef}
              layout="vertical"
            >
              <RowPadding>
                <LabelColPadding span={6}>
                  <InputLabel>Current Password *</InputLabel>
                </LabelColPadding>
                <Col span={11}>
                  <Password
                    placeholder="Enter Current Password"
                    name={'currentPwd'}
                    type={'password'}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error('Please Enter Current Password.')
                            )
                          }
                          if (value.includes(' ')) {
                            return Promise.reject(
                              new Error('Spaces are not allowed')
                            )
                          }

                          if (value.length < 8) {
                            return Promise.reject(
                              new Error(
                                'Password must be at least 8 characters long.'
                              )
                            )
                          }

                          const letterRegex = /[a-zA-Z]/
                          const numberRegex = /[0-9]/
                          const symbolRegex =
                            /[!@#$%^&*()_+[\]{};:'",.<>?/\\|`~\-]/

                          if (
                            !letterRegex.test(value) ||
                            !numberRegex.test(value) ||
                            !symbolRegex.test(value)
                          ) {
                            return Promise.reject(
                              new Error(
                                'Password must contain at least 1 letter, 1 number, and 1 symbol.'
                              )
                            )
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Col>
              </RowPadding>
              <MessageDiv>
                Password should be alpha numeric with special characters.
              </MessageDiv>
              <RowPadding>
                <LabelColPadding span={6}>
                  <InputLabel>New Password *</InputLabel>
                </LabelColPadding>
                <Col span={11}>
                  <Password
                    placeholder="Enter New Password"
                    name={'newPwd'}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error('Please Enter New Password.')
                            )
                          }
                          if (value.includes(' ')) {
                            return Promise.reject(
                              new Error('Spaces are not allowed')
                            )
                          }

                          if (value.length < 8) {
                            return Promise.reject(
                              new Error(
                                'Password must be at least 8 characters long.'
                              )
                            )
                          }

                          const letterRegex = /[a-zA-Z]/
                          const numberRegex = /[0-9]/
                          const symbolRegex =
                            /[!@#$%^&*()_+[\]{};:'",.<>?/\\|`~\-]/

                          if (
                            !letterRegex.test(value) ||
                            !numberRegex.test(value) ||
                            !symbolRegex.test(value)
                          ) {
                            return Promise.reject(
                              new Error(
                                'Password must contain at least 1 letter, 1 number, and 1 symbol.'
                              )
                            )
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Col>
              </RowPadding>
              <RowPadding>
                <LabelColPadding span={6}>
                  <InputLabel>Confirm Password *</InputLabel>
                </LabelColPadding>
                <Col span={11}>
                  <Password
                    placeholder="Confirm Password"
                    name={'confirmPwd'}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error('Please Enter Confirm Password.')
                            )
                          }
                          if (value.includes(' ')) {
                            return Promise.reject(
                              new Error('Spaces are not allowed')
                            )
                          }

                          if (value.length < 8) {
                            return Promise.reject(
                              new Error(
                                'Password must be at least 8 characters long.'
                              )
                            )
                          }

                          const letterRegex = /[a-zA-Z]/
                          const numberRegex = /[0-9]/
                          const symbolRegex =
                            /[!@#$%^&*()_+[\]{};:'",.<>?/\\|`~\-]/

                          if (
                            !letterRegex.test(value) ||
                            !numberRegex.test(value) ||
                            !symbolRegex.test(value)
                          ) {
                            return Promise.reject(
                              new Error(
                                'Password must contain at least 1 letter, 1 number, and 1 symbol.'
                              )
                            )
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Col>
              </RowPadding>
              <RowPadding>
                <LabelColPadding span={6}></LabelColPadding>
                <Col span={10}>
                  <Flex end>
                    <Button.Primary
                      htmlType="submit"
                      text={'Save Changes'}
                      width={'120px'}
                      padding={'10px'}
                    />
                  </Flex>
                </Col>
              </RowPadding>
            </Form>
          )}
        </ImageColPadding>
      </Row>
    </SectionWrapper>
  )
}
export default ManageProfile
