import React from 'react'

const SkillsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      {/* <mask
        id="mask0_288_29271"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask> */}
      <g mask="url(#mask0_288_29271)">
        <path
          d="M17 19.085L14.6529 20.8659C14.5366 20.9605 14.4098 21.005 14.2723 20.9996C14.1349 20.9945 14.0133 20.9552 13.9076 20.8817C13.8018 20.8081 13.72 20.7083 13.6621 20.5822C13.6037 20.4561 13.601 20.3196 13.6538 20.1725L14.5578 17.2568L12.2583 15.6335C12.1314 15.5495 12.0521 15.4391 12.0204 15.3025C11.9887 15.166 11.994 15.0399 12.0362 14.9243C12.0785 14.8087 12.1525 14.7062 12.2583 14.6167C12.364 14.5276 12.4909 14.483 12.6389 14.483H15.4776L16.3974 11.457C16.4502 11.31 16.5323 11.1969 16.6435 11.1179C16.7543 11.0393 16.8731 11 17 11C17.1269 11 17.2457 11.0393 17.3565 11.1179C17.4677 11.1969 17.5498 11.31 17.6026 11.457L18.5224 14.483H21.3611C21.5091 14.483 21.636 14.5276 21.7417 14.6167C21.8475 14.7062 21.9215 14.8087 21.9638 14.9243C22.006 15.0399 22.0113 15.166 21.9796 15.3025C21.9479 15.4391 21.8686 15.5495 21.7417 15.6335L19.4422 17.2568L20.3462 20.1725C20.399 20.3196 20.3965 20.4561 20.3386 20.5822C20.2802 20.7083 20.1982 20.8081 20.0924 20.8817C19.9867 20.9552 19.8651 20.9945 19.7277 20.9996C19.5902 21.005 19.4634 20.9605 19.3471 20.8659L17 19.085Z"
          fill="#3249D7"
        />
        <path
          d="M10 12C8.9 12 7.95833 11.6083 7.175 10.825C6.39167 10.0417 6 9.1 6 8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4C11.1 4 12.0417 4.39167 12.825 5.175C13.6083 5.95833 14 6.9 14 8C14 9.1 13.6083 10.0417 12.825 10.825C12.0417 11.6083 11.1 12 10 12ZM3 20C2.71667 20 2.47933 19.904 2.288 19.712C2.096 19.5207 2 19.2833 2 19V17.2C2 16.65 2.14167 16.1333 2.425 15.65C2.70833 15.1667 3.1 14.8 3.6 14.55C4.45 14.1167 5.40833 13.75 6.475 13.45C7.54167 13.15 8.71667 13 10 13H10.35C10.45 13 10.55 13.0167 10.65 13.05C10.5167 13.35 10.404 13.6627 10.312 13.988C10.2207 14.3127 10.15 14.65 10.1 15C10.0167 15.6 9.99167 16.104 10.025 16.512C10.0583 16.9207 10.15 17.4167 10.3 18C10.4 18.35 10.5333 18.696 10.7 19.038C10.8667 19.3793 11.05 19.7 11.25 20H3Z"
          fill="#3249D7"
        />
      </g>
    </svg>
  )
}

export default SkillsIcon
