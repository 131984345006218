import styled from 'styled-components'
import { COLORS } from 'theme'
import Flex from 'components/Flex'
import { FONT } from 'theme/font'
import { Spin } from 'antd'

export const PageTitle = styled(Flex)`
  padding: 15px 25px 0px;
  width: 1190px;
  height: 53px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
export const HeadTitle = styled(Flex)`
  width: 177px;
  height: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #010e30;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const PaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 25px;
`
export const SectionWrapper = styled(Flex)`
  width: 100%;
  overflow: scroll;
  background: ${COLORS.WHITE};
  line-height: 100%;
  padding: 0px;
  position: absolute;
  width: 1190px;
  height: 642px;
  left: 230px;
  top: 95px;
  background: #ffffff;
  border: 1px solid #f2f3f5;
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.1);
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const TopHeader = styled(Flex)`
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: ${FONT.F_XL};
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const CardRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 25px;
  gap: 25px;

  width: 266.25px;
  height: 127px;

  /* Primary / Blue / T - 95 */

  background: #f5f6fd;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`

export const Spinner = styled(Spin)`
  padding-top: 30%;
  position: absolute;
`
export const TabDiv = styled.div`
  padding: 0px 0px 0px 25px;
  width: 1190px;
  height: 642px;
  background: #ffffff;
  border: 1px solid #f2f3f5;
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.1);
  border-radius: 10px;
`

export const EmptyWrapper = styled(Flex)`
  margin: 50px 0px;
  margin: ${props => props?.isSpin && '102px 0px'};
`

export const CollageNameFlex = styled(Flex)`
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
`
export const CollegeNameDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  color: ${COLORS.GREY_T_20};
  text-transform: capitalize;
`
export const CollegeCity = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_XS};
  color: ${COLORS.GREY_T_60};
`
export const CollageDetailsFlex = styled(Flex)`
  line-height: normal;
  width: 100%;
  align-items: center;
  padding: 17px 0px;
  border-bottom: ${props =>
    !props?.isBoader && `1px solid ${COLORS.GREY_T_90}`};
`

export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
`
