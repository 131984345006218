import { Form as AntdForm } from 'antd'
import AntdAvatar from 'components/Avatar'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import Checkbox from 'components/Form/Checkbox'
import SearchInput from 'components/SearchInput'
import { FlexWrap } from 'components/UIComponents/FormStyles'
import PlusIcon from 'components/icons/PlusIcon'
import { Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import AddNewInstituteDrawer from 'modules/Onboarding/Partials/Corporates/Partials/AddNewInstituteDrawer/index'
import React, { useState } from 'react'
import { Spinner } from '../../../../../User/Style/style'
import {
  CollageNameFlex,
  CollegeCity,
  CollegeDetailsFlex,
  CollegeNameDiv,
} from '../../../Institutes/Register/InstituteConstituency/ConstituencyList/style'

const AddInstituteDrawer = ({
  listOfCollege,
  addInstitute,
  addInstituteDrawer,
  setAddInstituteDrawer,
  formId = 'institute_drawer',
  title = 'Add Institute',
  sendButtonText = 'Confirm',
}) => {
  const [createEditForm] = AntdForm.useForm()

  const [newInstituteDrawer, setNewInstituteDrawer] = useState(false)

  const CheckClick = () => {
    setAddInstituteDrawer(false)
    setNewInstituteDrawer(true)
  }

  const onClear = () => {
    createEditForm.resetFields()
    setAddInstituteDrawer(false)
  }

  const onSubmitFormData = value => {
    setAddInstituteDrawer(false)
  }

  const content = () => {
    return (
      <div>
        {addInstitute ? (
          <div>
            {!addInstitute?.result && (
              <Flex center>
                <Spinner />
              </Flex>
            )}
          </div>
        ) : (
          <div>
            {!listOfCollege?.result && (
              <Flex center>
                <Spinner />
              </Flex>
            )}
          </div>
        )}

        <Form
          scrollToFirstError
          onFinish={onSubmitFormData}
          id={formId}
          form={createEditForm}
          layout="vertical"
        >
          <SearchInput width={'500px'} placeholder="Search" />

          {addInstitute ? (
            <>
              {addInstitute?.result?.map(item => (
                <Control key={item?.id} row>
                  <Checkbox />
                  <CollegeDetailsFlex
                    spaceBetween
                    centerVertically
                    style={{ width: '100%' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Flex alignCenter>
                        <AntdAvatar
                          src={item?.campusLogoUrl}
                          size={36}
                          IconName={
                            item?.campusName?.split(' ')[0]?.split('')[0]
                          }
                        />

                        <CollageNameFlex>
                          <CollegeNameDiv>{item?.campusName}</CollegeNameDiv>
                          <CollegeCity>{item?.campusLocation}</CollegeCity>
                        </CollageNameFlex>
                      </Flex>
                    </div>
                  </CollegeDetailsFlex>
                </Control>
              ))}
            </>
          ) : (
            <>
              {listOfCollege?.result?.map(item => (
                <Control key={item?.id} row>
                  <Checkbox />
                  <CollegeDetailsFlex
                    spaceBetween
                    centerVertically
                    style={{ width: '100%' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Flex alignCenter>
                        <AntdAvatar
                          src={item?.campusLogoUrl}
                          size={36}
                          IconName={
                            item?.campusName?.split(' ')[0]?.split('')[0]
                          }
                        />

                        <CollageNameFlex>
                          <CollegeNameDiv>{item?.campusName}</CollegeNameDiv>
                          <CollegeCity>{item?.campusLocation}</CollegeCity>
                        </CollageNameFlex>
                      </Flex>
                    </div>
                  </CollegeDetailsFlex>
                </Control>
              ))}
            </>
          )}

          <Flex center>
            <Button.Primary
              height={'38px'}
              width="150px"
              text={'Add Institute'}
              icon={<PlusIcon />}
              onClick={CheckClick}
            />
          </Flex>
        </Form>

        <AddNewInstituteDrawer
          newInstituteDrawer={newInstituteDrawer}
          setNewInstituteDrawer={setNewInstituteDrawer}
        />
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClear} />
        <Button.Primary htmlType="submit" form={formId} text={sendButtonText} />
      </FlexWrap>
    )
  }
  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClear}
      visible={addInstituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddInstituteDrawer
