import React from 'react'

const ExportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        d="M15.2288 2.28223L7.74606 9.85478"
        stroke="#3249D7"
        strokeWidth="2.12457"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.852 2.02686H15.3679V6.5126"
        stroke="#3249D7"
        strokeWidth="2.12457"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.83685 4.02893H1.63214V15.9731H13.5763L13.576 9.76836"
        stroke="#3249D7"
        strokeWidth="2.12457"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExportIcon
