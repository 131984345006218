import AntdAvatar from 'components/Avatar'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import {
  BorderedDiv,
  FlexWrap,
  GreyText,
  MediumText,
  StyledDivider,
  UserName,
} from 'modules/Users/Partials/ViewUserRoleDrawer/Style/style'
import { Spinner } from 'modules/Users/Style/style'
import React, { Fragment } from 'react'
import { COLORS } from 'theme'
import { RadioButton } from './Style/style'

const ViewUserRoleDrawer = ({
  viewUserDrawer,
  eventList,
  setViewRoleDrawer,
  setFormattedData,
  formattedData,
  viewRoleDrawer,
  singleRoleData,
  userMetrics,
  updateLoading,
}) => {
  const info = [
    {
      value: singleRoleData?.permissions?.journey?.toUpperCase(),
    },
  ]
  const onClose = () => {
    setViewRoleDrawer(false)
    setFormattedData([])
  }

  const content = () => {
    return (
      <Fragment>
        {updateLoading ? (
          <Flex center>
            <Spinner />
          </Flex>
        ) : (
          <BorderedDiv>
            <FlexWrap gap="10px" alignCenter>
              <AntdAvatar
                size={39}
                IconName={singleRoleData?.permissions?.name?.charAt(0)}
                font={18}
              />
              <FlexWrap column>
                <UserName>{singleRoleData?.permissions?.name}</UserName>
                <GreyText>
                  {singleRoleData?.role_id ?? singleRoleData?.admin_role_id}
                </GreyText>
              </FlexWrap>
            </FlexWrap>

            <StyledDivider />
            <UserName color={COLORS.GREY_T_20} marginbottom={'15px'}>
              Journey
            </UserName>

            <FlexWrap gap="15px" column>
              {info?.map((item, id) => (
                <FlexWrap alignCenter key={item?.id}>
                  <div color={COLORS.GREY_T_30}>{item?.value}</div>
                </FlexWrap>
              ))}
            </FlexWrap>
            <FlexWrap gap="15px" style={{ marginTop: '15px' }} column>
              <UserName color={COLORS.GREY_T_20}>Permissions</UserName>
              {eventList?.permissions?.map((item, id) => (
                <div key={item?.id}>
                  <MediumText> {item?.screenName}</MediumText>

                  {item?.events?.map(event => (
                    <RadioButton
                      checked={true}
                      disabled
                      style={{ gap: '20px' }}
                      key={event.id}
                    >
                      {event.eventName}
                      {event.subEvents?.map(subEvent => (
                        <div key={subEvent.id}>{subEvent.subEventName}</div>
                      ))}
                    </RadioButton>
                  ))}
                </div>
              ))}
            </FlexWrap>
          </BorderedDiv>
        )}
      </Fragment>
    )
  }

  const footer = () => {
    return (
      <FlexWrap right>
        <Button.Primary
          onClick={() => setViewRoleDrawer(false)}
          text="Done"
          icon={<CheckIconWhite />}
          height="38px"
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={'View Details'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={viewRoleDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ViewUserRoleDrawer
