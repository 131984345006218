const getUserMetricsData = state => state?.user?.userMetrics
const getUserListData = state => state?.user?.userList
const getRoleListData = state => state?.role?.roleList
const getEventData = state => state?.event?.eventList
const getRoleData = state => state?.role?.roles?.data
const getSingleUserData = state => state?.user?.singleUserData
const getSingleRoleData = state => state?.role?.singleRoleData

const selectors = {
  getUserMetricsData,
  getUserListData,
  getRoleListData,
  getEventData,
  getRoleData,
  getSingleUserData,
  getSingleRoleData,
}

export default selectors
