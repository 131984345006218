import { Col, Divider, Form, Row } from 'antd'
import LeftArrow from 'components/icons/LeftArrow'
import RoutePath from 'components/RoutePath/index'
import VirtualizedCheckboxGroup from 'components/UIComponents/Filter/index'
import MasterItem from 'components/UIComponents/SideMenuMaster'
import Course from 'modules/Courses/Container/index.js'
import AddNewInstituteDrawer from 'modules/Onboarding/Partials/Corporates/Partials/AddNewInstituteDrawer/index'
import { arrayStatus } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import {
  LeftSection,
  PaddedDiv,
  RoleNameDiv,
  RoleNameFlex,
  SectionName,
  StateCityMessage,
  TopHeader,
} from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import { SystemPaddedDiv } from 'modules/Systemconfig/Styles/style'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VirtualizedCheckboxGroupForStatus from '../../../../components/UIComponents/StatusFilter'
import SystemconfigTable from '../../../../components/UIComponents/SystemConfigTable/index'
import CrudDrawer from '../../Components/Drawer/Drawer'
import { dropDownValues } from './actions'
import AddNewCollegeDrawer from './Partials/CollegeDrawer/index'
import {
  AddCategoryContent,
  AddDegreeContent,
  AddDegreeLevel,
  AddDegreeType,
  AddDepartmentContent,
  AddSpecialisationContent,
  AddUniversityContent,
  CategoryData,
  CollegeData,
  DegreeData,
  DegreeLevelData,
  DegreeTypeData,
  DepartmentData,
  InstituteMenuData,
  SpecialisationData,
  Status,
  Type,
  UniversityData,
} from './Partials/TableColumnData'
const InstituteSettings = ({
  getInstituteListForSysConfig,
  instituteTableList,
  getListOfState,
  createInstituteSysConfig,
  getListOfCity,
}) => {
  const [form] = Form.useForm()
  const [universityId, setUniversityId] = useState('')
  const [id, setId] = useState('1')
  const [pageNum, setPageNum] = useState(1)
  const [url, setUrl] = useState()
  const [tableData, setTableData] = useState({
    id: 0,
    text: '',
  })
  const [stateId, setStateId] = useState('')
  const [cityId, setPermCityId] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [menu, setMenu] = useState('')
  const [toEditId, setEditId] = useState('')
  const [dataToAdd, setDataToAdd] = useState(UniversityData)
  const [content, setContent] = useState(AddUniversityContent)
  const [itemsInFilter, setItemsInFilter] = useState(FilterItems)
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [stateName, setStateName] = useState([])
  const [cityName, setCityName] = useState([])
  const [domainName, setDomainName] = useState([])
  const [statusValue, setStatusValues] = useState([])
  const [typeValue, setTypeValue] = useState([])
  const [sortBy, setSortBy] = useState({})
  const [filterDatas, setFilterData] = useState({})
  const [universityValue, setUniversityValue] = useState({})
  const [success, isSuccess] = useState(false)
  const [view, setView] = useState(false)
  const [addContent, setAddContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [isUniversity, setUniversity] = useState(false)
  const [locationList, setLocationList] = useState([])
  const [locationId, setLocationId] = useState('')
  const [locationName, setLocationName] = useState('')
  const [isCityVisible, setCityVisible] = useState(false) // Track city visibility
  const [isCityCollegeVisible, setCityCollegeVisible] = useState(false) // Track city visibility

  const currentUrl = window.location.href
  const parts = currentUrl.split('/')
  const cardName = parts[parts.length - 2]
  const cardId = parts[parts.length - 1]
  const [domainId, setDomainId] = useState([])
  const [clearInput, setClearInput] = useState(false)
  const [domainValues, setDomainValues] = useState([])
  const [check, setCheck] = useState(false)
  const [degreeSearch, setDegreeSearch] = useState('')

  const { COUNTRY_ID } = process.env

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dropDownValues({ url: 'crud/domains?status=1' })
        setDomainValues(response.data.result)
      } catch (error) {
        console.error('Error fetching domain values:', error)
      }
    }
    fetchData()
  }, [])
  const handleSelectedIdsChange = selectedIds => {
    const names = domainValues
      .filter(item => selectedIds.includes(item.id))
      .map(item => item.id)
    setDomainId(names)
  }
  console.log(domainId, 'domainId')
  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await getListOfState(countryId, search)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setStateList(updatedArray || [])
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await getListOfCity(countryId, stateId, search, 0, pageLimit)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      setLocationList(updatedArray)
      return setCityList(updatedArray || [])
    }
  }
  const showDrawer = e => {
    if (Object.prototype.toString.call(e) === '[object Object]') {
      setDrawer(true)
      setAddContent(true)
    } else {
      setDrawer(true)
      setAddContent(false)
    }
    setDrawer(true)
  }
  const onClose = () => {
    setDrawer(false)
    form.resetFields()
    setEditId('')
    setView(false)
    setUniversityValue({})
  }
  useEffect(() => {
    // Combine all the conditions that lead to the same action
    if (
      [
        'Department',
        'Specialisation',
        'Degree',
        'University',
        'College',
      ].includes(menu) ||
      domainValues ||
      stateList ||
      cityList ||
      locationList
    ) {
      setItemsInFilter(FilterItems)
    }
  }, [
    statusValue,
    typeValue,
    stateList,
    stateName,
    cityId,
    cityList,
    menu,
    locationList,
    locationName,
    domainValues,
    domainId,
  ])

  useEffect(() => {
    setPermCityId('')
    getCityData(COUNTRY_ID, stateName, true)
  }, [stateId])
  useEffect(() => {
    if (cardName) {
      let data = {
        id: cardId,
        text: decodeURIComponent(cardName),
      }
      onTableClick(data)
    }
  }, [cardName])

  const getDegreeDrawerDropdownData = async () => {
    const Domain = await dropDownValues({ url: 'crud/domains?status=1' })
    const DegreeType = await dropDownValues({ url: 'crud/degreeType' })
    const DegreeLevel = await dropDownValues({ url: 'crud/degreeLevel' })
    const copyOfDegreeData = [...AddDegreeContent]

    //set Domain dropdown data
    copyOfDegreeData[1].data = Domain?.data?.result

    //set Degree Type dropdown data
    copyOfDegreeData[2].data = DegreeType?.data?.result

    //set Degree Level dropdown data
    copyOfDegreeData[3].data = DegreeLevel?.data?.result
    setContent(copyOfDegreeData)
  }

  const onTableClick = data => {
    setTableData(data)
    setId(data.id)
    setFilterData({})
    //  setSearchValue('')
    // setClearSearch('')
    setSortBy({})
    setEditId('')
    setStatusValue([])
    setTypeValue([])
    setDomainId([])
    setCityList([])
    setCityName([])
    setDomainName([])
    setStateName([])
    setPermCityId([])
    setSearchValue('')
    setLocationId([])
    setLocationName([])
    setStateId('')
    setPageNum(0)
    if (data.text == 'University') {
      setUniversity(true)
    } else {
      setUniversity(false)
    }
    setClearInput(!clearInput)
    setMenu(data.text)
    switch (data.text) {
      case 'University':
        setDataToAdd(UniversityData)
        setUniversity(true)
        setItemsInFilter(FilterItems)
        setUrl('crud/universities')
        setSearchValue('')
        break
      case 'College':
        setDataToAdd(CollegeData)
        setContent(AddDegreeContent)
        setUrl('crud/college')
        setSearchValue('')
        setItemsInFilter(FilterItems)
        break
      case 'Domain':
        setDataToAdd(CategoryData)
        setContent(AddCategoryContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/domains')
        setSearchValue('')
        break
      case 'Degree Type':
        setDataToAdd(DegreeTypeData)
        setContent(AddDegreeType)
        setUrl('crud/degreeType')
        setSearchValue('')
        break
      case 'Degree Level':
        setDataToAdd(DegreeLevelData)
        setContent(AddDegreeLevel)
        setUrl('crud/degreeLevel')
        setSearchValue('')
        break
      case 'Degree':
        setDataToAdd(DegreeData)
        getDegreeDrawerDropdownData()
        setUrl('crud/degree')
        setSearchValue('')
        break
      case 'Department':
        setDataToAdd(DepartmentData)
        setContent(AddDepartmentContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/streams')
        setSearchValue('')
        break
      case 'Specialisation':
        setDataToAdd(SpecialisationData)
        setContent(AddSpecialisationContent)
        setUrl('crud/specialisation')
        setItemsInFilter(FilterItems)
        setSearchValue('')
        break
      default:
        break
    }
    window.history.replaceState(
      null,
      '',
      `/systemConfig/instituteSettings/${data.text}/${data.id}`
    )
  }

  useEffect(() => {
    setUniversity(true)
    getStateData(COUNTRY_ID, true)
    setItemsInFilter(FilterItems)
  }, [])

  useEffect(() => {
    if (menu !== 'Degree, Department & Specialisation Mapping' && url) {
      getInstituteListForSysConfig({
        url,
        type: 'get',
        pageSize: 10,
        pageNumber: pageNum == 0 ? 0 : pageNum - 1,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
    }
  }, [url, pageNum, submit, success, sortBy])

  useEffect(() => {
    if (url) {
      getInstituteListForSysConfig({
        url,
        type: 'get',
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
    }
    setPageNum(1)
  }, [searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [tableData])

  const setStatusValue = value => {
    setStatusValues(value)
  }

  useEffect(() => {
    setCityVisible(tableData?.text === 'University' && stateId !== '')
  }, [tableData, stateId])

  useEffect(() => {
    setCityCollegeVisible(tableData?.text === 'College' && stateId !== '')
  }, [tableData, stateId])

  console.log(stateId, 'State')
  const FilterItems = [
    {
      label: 'Type',
      key: '1',
      visible: menu === 'Degree',
      children: (
        <VirtualizedCheckboxGroup
          options={Type}
          value={typeValue}
          valueParam="value"
          labelParam="label"
          setValue={setTypeValue}
          searchNotNeeded={true}
          loading={loading}
          setLoading={setLoading}
          check={check}
          degreeSearch={degreeSearch}
          setDegreeSearch={setDegreeSearch}
        />
      ),
    },
    {
      label: 'Domain',
      key: '6',
      visible: menu === 'Degree',
      children: (
        <VirtualizedCheckboxGroup
          options={domainValues}
          value={domainId}
          valueParam="id"
          labelParam="name"
          setValue={handleSelectedIdsChange}
          stateName={domainName}
          setStateName={setDomainName}
          filterId={true}
          saveById={true}
          check={check}
          degreeSearch={degreeSearch}
          setDegreeSearch={setDegreeSearch}
        />
      ),
    },
    {
      label: 'State',
      key: '2',
      visible:
        tableData?.text === 'College' || tableData?.text === 'University',

      children: (
        <VirtualizedCheckboxGroup
          options={stateList}
          value={stateId}
          setValue={setStateId}
          valueParam="_id"
          labelParam="name"
          setStateName={setStateName}
          filterId={true}
          stateName={stateName}
          parentId={tableData?.text}
          loading={loading}
          setLoading={setLoading}
          content={true}
          check={check}
          // degreeSearch={degreeSearch}
          // setDegreeSearch={setDegreeSearch}
          getStateData={getStateData}
          labelType={'state'}
        />
      ),
    },
    {
      label: 'City',
      key: '3',
      visible: tableData?.text === 'University',
      children: (
        <>
          {isCityVisible && stateId.length !== 0 && (
            <VirtualizedCheckboxGroup
              options={cityList}
              value={cityId}
              setValue={setPermCityId}
              valueParam="_id"
              labelParam="name"
              filterId={true}
              setStateName={setCityName}
              stateName={cityName}
              loading={loading}
              setLoading={setLoading}
              check={check}
              degreeSearch={degreeSearch}
              setDegreeSearch={setDegreeSearch}
            />
          )}
          {((!stateId && cityId.length === 0) || stateId.length === 0) && (
            <StateCityMessage>
              Kindly select a state before choosing a city.
            </StateCityMessage>
          )}
        </>
      ),
    },
    {
      label: 'City',
      key: '5',
      visible: tableData?.text === 'College',
      children: (
        <>
          {isCityCollegeVisible && stateId.length !== 0 && (
            <VirtualizedCheckboxGroup
              options={locationList}
              value={locationId}
              setValue={setLocationId}
              valueParam="name"
              labelParam="name"
              setStateName={setLocationName}
              setCityName={setLocationName}
              filterId={true}
              stateName={locationName}
              loading={loading}
              setLoading={setLoading}
              check={check}
              degreeSearch={degreeSearch}
              setDegreeSearch={setDegreeSearch}
            />
          )}
          {((!stateId && cityId.length === 0) || stateId.length === 0) && (
            <StateCityMessage>
              Kindly select a state before choosing a city.
            </StateCityMessage>
          )}
        </>
      ),
    },
    {
      label: 'Status',
      key: '4',
      visible: true,
      children: (
        <VirtualizedCheckboxGroupForStatus
          options={Status}
          value={statusValue}
          setValue={setStatusValue}
          searchNotNeeded={true}
        />
      ),
    },
  ]

  const submitSystemConfigFilter = value => {
    let status = arrayStatus(statusValue)
    let type = typeValue || []
    let domain = domainId || []
    let state = stateId || []
    let city = cityId || []
    let location = locationId || []
    let statusName = statusValue

    if (value == 'submit') {
      setFilterData({
        status: status,
        isActive: status,
        type: type,
        state: state,
        domain: domain,
        city: tableData?.text == 'College' ? location : city,
        location: location,
        menu: menu,
        statusName: statusName,
      })
      setSubmit(!submit)
      setPageNum(1)
    }
    if (value == 'reset') {
      setSubmit(!submit)
      setItemsInFilter(FilterItems)
      onTableClick(tableData)
      setFilterData({})
    }
    setLocationName('')
  }

  const handleFilterCall = () => {
    setCheck(!check)

    // setCheck(
    //   Math.floor((1 + Math.random()) * 0x10000)
    //     .toString(16)
    //     .substring(1)
    // )
    setItemsInFilter(FilterItems)
    setStatusValues(filterDatas?.statusName || [])
    setStateId(filterDatas?.state || [])
    setTypeValue(filterDatas?.type || [])
    setDomainId(filterDatas?.domain || [])
    setPermCityId(filterDatas?.city || [])
    setLocationId(filterDatas?.location || [])
  }

  const handleSubmitCongif = (value, call) => {
    if (menu == 'Degree') {
      if (!value?.domainId) delete value?.domainId
      if (!value?.degreesTypeId) delete value?.degreesTypeId
      if (!value?.degreesLevelId) delete value?.degreesLevelId
    }
    if (!value?.shortForm) {
      delete value?.shortForm
    }
    let newValue
    if (value.name) {
      newValue = {
        ...value,
        name: value?.name?.trim(),
      }
    } else {
      newValue = value
    }

    if (toEditId) {
      createInstituteSysConfig({
        url,
        value: newValue,
        type: 'put',
        id: toEditId,
        isSuccess,
        success,
        setLoading: setLoading,
      })
    } else {
      createInstituteSysConfig({
        url,
        value: newValue,
        type: 'post',
        isSuccess,
        success,
        setLoading: setLoading,
      })
    }
  }
  const onClickEdit = value => {
    setUniversityId(value?.universities?.id)
    form.setFieldsValue({
      universityName: value?.universities?.name,
      tier: value.tier,
      isActive: value.isActive,
      status: value.status,
      campusName: value?.instituteCampus?.[0]?.campusName,
      campusLocation: value?.instituteCampus?.[0]?.campusLocation,
      addressLine1: value?.instituteCampus?.[0]?.addressLine1,
      postalCode: value?.instituteCampus?.[0]?.postalCode,
      website: value?.instituteCampus?.[0]?.website,
      contactPerson: value?.instituteCampus?.[0]?.contactPerson,
      contactPersonRole: value?.instituteCampus?.[0]?.contactPersonRole,
      contactNumber: value?.instituteCampus?.[0]?.contactNumber,
      secondaryContactNumber:
        value?.instituteCampus?.[0]?.secondaryContactNumber,
      contactEmail: value?.instituteCampus?.[0]?.contactEmail,
      secondaryContactEmail: value?.instituteCampus?.[0]?.secondaryContactEmail,
      city: value?.instituteCampus?.[0]?.city,
      state: value?.instituteCampus?.[0]?.state,
      cityId: value?.instituteCampus?.[0]?.cityId,
      stateId: value?.instituteCampus?.[0]?.stateId,
      country: value?.instituteCampus?.[0]?.country,
      countryId: value?.instituteCampus?.[0]?.countryId,
      countryCode: value?.instituteCampus?.[0]?.countryCode,
      shortName: value?.instituteCampus?.[0]?.shortName,
      ranking: value.ranking,
      university: value.university,
      shortForm: value.shortForm,
      categoryId: value.categoryId,
      degreesLevelId: value?.degreesLevelId,
      degreesTypeId: value.degreesTypeId,
      domainId: value?.domain?.id,
      name: value?.name,
      type: value.type,
      id: value.id,
    })
    if (menu == 'College') {
      setEditId(value?.instituteCampus?.[0]?.id)
    } else {
      setEditId(value?.id)
    }
    setUniversityValue(value)
  }
  useEffect(() => {
    form.resetFields()
    onClose()
    setEditId('')
    setView(false)
  }, [success])

  const onUpdateStatus = (data, rowData) => {
    if (menu == 'College') {
      let value = {
        institute: {
          name: rowData.name,
          tier: rowData.tier,
          ranking: rowData.ranking,
          isActive: !rowData.isActive,
        },
        instituteCampus: {
          campusName: rowData?.instituteCampus[0].campusName,
          campusLocation: rowData?.instituteCampus[0].campusLocation,
          addressLine1: rowData?.instituteCampus[0].addressLine1,
          postalCode: rowData?.instituteCampus[0].postalCode,
          website: rowData?.instituteCampus[0].website,
          contactPerson: rowData?.instituteCampus[0].contactPerson,
          contactPersonRole: rowData?.instituteCampus[0].contactPersonRole,
          contactNumber: rowData?.instituteCampus[0].contactNumber,
          secondaryContactNumber:
            rowData?.instituteCampus[0].secondaryContactNumber,
          contactEmail: rowData?.instituteCampus[0].contactEmail,
          secondaryContactEmail:
            rowData?.instituteCampus[0].secondaryContactEmail,
          city: rowData?.instituteCampus[0].city,
          state: rowData?.instituteCampus[0].state,
          cityId: rowData?.instituteCampus[0].cityId,
          stateId: rowData?.instituteCampus[0].stateId,
          country: rowData?.instituteCampus[0].country,
          countryId: rowData?.instituteCampus[0].countryId,
          countryCode: rowData?.instituteCampus[0].countryCode,
        },
      }
      createInstituteSysConfig({
        url,
        value,
        type: 'put',
        id: rowData?.instituteCampus?.[0]?.id,
        isSuccess,
        success,
        setLoading: setLoading,
      })
    } else {
      if (menu == 'Degree') {
        if (!rowData?.domainId) delete rowData?.domainId
        if (!rowData?.degreesTypeId) delete rowData?.degreesTypeId
        if (!rowData?.degreesLevelId) delete rowData?.degreesLevelId
        if (!rowData?.categoryId) delete rowData?.categoryId
      }
      if (!rowData?.shortForm) {
        delete rowData.shortForm
      }
      let value = {
        ...rowData,
        status: data ? 1 : 0,
        isActive: !data,
      }
      createInstituteSysConfig({
        url,
        value,
        type: 'put',
        id: rowData?.id,
        isSuccess,
        success,
        setLoading: setLoading,
      })
    }
  }
  const confirmDelete = data => {
    createInstituteSysConfig({
      url,
      type: 'delete',
      id: data?.id,
      isSuccess,
      success,
      setLoading: setLoading,
    })
  }
  const toView = data => {
    onClickEdit(data)
    setView(true)
  }

  function handleKeyPress(e) {
    const charCode = e.which || e.keyCode
    const charStr = String.fromCharCode(charCode)
    if (charStr === charStr.toUpperCase()) {
      e.preventDefault()
    }
  }

  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/systemconfig">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="System Config"
                on={'Institute Settings'}
                path="/systemconfig"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>Institute Settings</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>

      <Row>
        <Col span={5}>
          <SystemPaddedDiv>
            <MasterItem
              onTableClick={onTableClick}
              id={id}
              MasterTableData={InstituteMenuData}
            />
          </SystemPaddedDiv>
        </Col>
        <Col span={0.5}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={18}>
          {menu == 'Degree, Department & Specialisation Mapping' ? (
            <Course systemConfig={true} sendButtonText={'Save'} />
          ) : (
            <SystemconfigTable
              form={form}
              loading={loading}
              tableData={tableData}
              dataToAdd={dataToAdd}
              data={instituteTableList?.result}
              showDrawer={showDrawer}
              content={content}
              statusValue={statusValue}
              setStatusValue={setStatusValue}
              typeValue={typeValue}
              setTypeValue={setTypeValue}
              items={itemsInFilter}
              setPageNum={setPageNum}
              pageNum={pageNum}
              list={instituteTableList}
              setSearchValue={setSearchValue}
              submitSystemConfigFilter={submitSystemConfigFilter}
              onEdit={onClickEdit}
              onUpdateStatus={onUpdateStatus}
              confirmDelete={confirmDelete}
              setSortBy={setSortBy}
              toView={toView}
              menu={menu}
              clearInput={clearInput}
              searchValue={searchValue}
              locationId={locationId}
              setLocationId={setLocationId}
              handleFilterCall={handleFilterCall}
            />
          )}
        </Col>
      </Row>
      {menu == 'University' ? (
        <AddNewInstituteDrawer
          newInstituteDrawer={drawer}
          setNewInstituteDrawer={setDrawer}
          title={
            view ? 'Preview' : toEditId ? 'Edit University' : 'Add University'
          }
          formId="form"
          sendButtonText={toEditId ? 'Update' : 'Save'}
          isSystemConfig={true}
          form={form}
          handleSubmitCongif={handleSubmitCongif}
          toEditId={toEditId}
          setEditId={setEditId}
          view={view}
          setView={setView}
          universityValue={universityValue}
          addContent={addContent}
          setAddContent={setAddContent}
          onKeyPress={handleKeyPress}
        />
      ) : menu == 'College' ? (
        <AddNewCollegeDrawer
          universityId={universityId}
          setUniversityId={setUniversityId}
          newInstituteDrawer={drawer}
          setNewInstituteDrawer={setDrawer}
          title={view ? 'Preview' : toEditId ? 'Edit College' : 'Add College'}
          formId="form"
          sendButtonText={toEditId ? 'Update' : 'Save'}
          isSystemConfig={true}
          form={form}
          handleSubmitCongif={handleSubmitCongif}
          toEditId={toEditId}
          setEditId={setEditId}
          view={view}
          setView={setView}
          universityValue={universityValue}
          addContent={addContent}
          setAddContent={setAddContent}
          menu={menu}
        />
      ) : (
        <CrudDrawer
          open={drawer}
          onClose={onClose}
          tableData={tableData}
          content={content}
          form={form}
          handleSubmitCongif={handleSubmitCongif}
          toEditId={toEditId}
          setEditId={setEditId}
          view={view}
          setView={setView}
          addContent={addContent}
        />
      )}
    </>
  )
}
export default InstituteSettings
