import React from 'react'

const VerticalThreeDots = ({ color = '#B3B7C1' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16.6663C9.54171 16.6663 9.14949 16.5033 8.82337 16.1772C8.49671 15.8505 8.33337 15.458 8.33337 14.9997C8.33337 14.5413 8.49671 14.1488 8.82337 13.8222C9.14949 13.4961 9.54171 13.333 10 13.333C10.4584 13.333 10.8509 13.4961 11.1775 13.8222C11.5037 14.1488 11.6667 14.5413 11.6667 14.9997C11.6667 15.458 11.5037 15.8505 11.1775 16.1772C10.8509 16.5033 10.4584 16.6663 10 16.6663ZM10 11.6663C9.54171 11.6663 9.14949 11.503 8.82337 11.1763C8.49671 10.8502 8.33337 10.458 8.33337 9.99967C8.33337 9.54134 8.49671 9.14884 8.82337 8.82217C9.14949 8.49606 9.54171 8.33301 10 8.33301C10.4584 8.33301 10.8509 8.49606 11.1775 8.82217C11.5037 9.14884 11.6667 9.54134 11.6667 9.99967C11.6667 10.458 11.5037 10.8502 11.1775 11.1763C10.8509 11.503 10.4584 11.6663 10 11.6663ZM10 6.66634C9.54171 6.66634 9.14949 6.50301 8.82337 6.17634C8.49671 5.85023 8.33337 5.45801 8.33337 4.99967C8.33337 4.54134 8.49671 4.14912 8.82337 3.82301C9.14949 3.49634 9.54171 3.33301 10 3.33301C10.4584 3.33301 10.8509 3.49634 11.1775 3.82301C11.5037 4.14912 11.6667 4.54134 11.6667 4.99967C11.6667 5.45801 11.5037 5.85023 11.1775 6.17634C10.8509 6.50301 10.4584 6.66634 10 6.66634Z"
        fill={color}
      />
    </svg>
  )
}

export default VerticalThreeDots
