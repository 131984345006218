import React from 'react'
import styled from 'styled-components'

const IndustryIcon = () => {
  const IndustrySysIcons = styled.svg`
    path {
      stroke: #3249d7;
    }
    &:hover {
      path {
        stroke: white;
      }
    }
  `
  return (
    <IndustrySysIcons
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      stroke="white"
      style={{ margin: 'auto' }}
    >
      <path
        d="M22.271 6.67723L15.75 10.827V7.62636C15.75 6.73967 14.7689 6.20141 14.021 6.67723L7.5 10.827V1.625C7.5 1.00367 6.99633 0.5 6.375 0.5H1.125C0.503672 0.5 0 1.00367 0 1.625V20.375C0 20.9963 0.503672 21.5 1.125 21.5H22.875C23.4963 21.5 24 20.9963 24 20.375V7.62636C24 6.73963 23.0189 6.20141 22.271 6.67723Z"
        fill="#3249D7"
        stroke="white"
      />
    </IndustrySysIcons>
  )
}
export default IndustryIcon
