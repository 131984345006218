import React, { useEffect, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Checkbox } from 'antd'
import SearchInput from 'components/FilterSearchInput'

const VirtualizedCheckboxGroupForStatus = ({
  options,
  value,
  setValue,
  valueParam = 'value',
  labelParam = 'label',
  setStateName,
  stateName,
  countryName,
  setCountryName,
  filterId,
  searchNotNeeded = false,
  setDegreeSearch,
  setCityName,
  parentId,
  saveById = false,
  data,
  content,
}) => {
  const [filter, setFilter] = useState('')

  useEffect(() => {
    setFilter('')
  }, [parentId])

  const handleCheckboxChange = (optionValue, id) => {
    const index = value?.indexOf(saveById ? id : optionValue)
    const newValue = [index]
    if (index === -1) {
      if (saveById) newValue?.push(id)
      else newValue?.push(optionValue)
    } else {
      newValue?.splice(index, 1)
    }
    handleChange(newValue)
    if (filterId) {
      if (stateName == id) {
        setStateName('')
      } else {
        setStateName(id)
      }
    }
    setFilter('')
  }

  const filteredOptions = options?.filter(
    option =>
      option?.[labelParam]?.toLowerCase()?.includes(filter?.toLowerCase()) ||
      option?.[valueParam]?.toLowerCase()?.includes(filter?.toLowerCase())
  )
  const handleChange = newValue => {
    setValue(newValue)
  }
  const search = e => {
    setFilter(e.target.value)
    setDegreeSearch(e.target.value)
  }
  return (
    <div>
      {!searchNotNeeded && (
        <SearchInput
          placeholder="Search by keyword"
          value={filter}
          onChange={search}
        />
      )}
      <FixedSizeList
        height={200}
        itemCount={filteredOptions?.length}
        itemSize={50}
        style={{ marginTop: searchNotNeeded ? 20 : 0 }}
      >
        {({ index, style }) => (
          <div style={style}>
            <Checkbox
              checked={
                value?.indexOf(
                  filteredOptions[index][saveById ? valueParam : labelParam]
                ) !== -1
              }
              onChange={() =>
                handleCheckboxChange(
                  filteredOptions[index][labelParam],
                  filteredOptions[index][valueParam]
                )
              }
            >
              {filteredOptions[index][labelParam]}
            </Checkbox>
          </div>
        )}
      </FixedSizeList>
    </div>
  )
}

export default VirtualizedCheckboxGroupForStatus
