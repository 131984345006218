import React from 'react'
import { Switch as AntdSwitch, Form } from 'antd'
import styled from 'styled-components'
import Flex from 'components/Flex'

const FlexWrapper = styled(Flex)`
  display: flex !important;
  float: left !important;

  gap: ${props => (props.gap ? props.gap : '1rem')} !important;
`

const StyledSwitch = styled(AntdSwitch)`
  box-shadow: none !important;
  display: flex !important;
  float: left !important;
  gap: 1rem !important;
`
const LabelWrapper = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
`
export const FormItem = styled(Form.Item)`
  margin-bottom: 0px !important;

  .ant-form-item-control-input {
    min-height: 0px;
  }
`
const Switch = ({ label, name, onChange, valuePropName, gap, ...rest }) => {
  return (
    <FormItem name={name} valuePropName={valuePropName} {...rest}>
      <FlexWrapper spaceBetween gap={gap}>
        <LabelWrapper>{label}</LabelWrapper>
        <StyledSwitch onChange={onChange} {...rest} />
      </FlexWrapper>
    </FormItem>
  )
}

export default Switch
