import ReportDatePickerDropdown from 'components/DatePicker/ReportDatePickerDropdown'
import {
  FilterHeading,
  FilterText,
  FilterTextWithUnderLine,
} from 'components/UIComponents/ReportSearchFilter/Style/style.js'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  EndMargin,
  ErrorMessage,
  StartMargin,
  Wrappers,
} from '../../../../CorporateInfoTable/CorporateFilter/Styles/style'

const TopDateRangeFilter = ({
  setCreatedAtEnd,
  setCreatedAtStart,
  isEmpanelled,
  clearDateTop,
  setClearDateTop,
}) => {
  const [error, setError] = useState('')
  const [clear, setClear] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const handleStartDateChange = date => {
    setStartDate(date)
    if (date) {
      setCreatedAtStart(moment(date)?.format('DD-MM-YYYY'))
    }
  }
  const handleEndDateChange = date => {
    setEndDate(date)
    if (date) {
      setCreatedAtEnd(moment(date)?.format('DD-MM-YYYY'))
    }
  }

  useEffect(() => {
    if (endDate && moment(startDate).isAfter(endDate)) {
      setError('Start Date Cannot Be Greater Than End Date')
      return
    } else {
      setError('')
    }
    if (startDate && moment(endDate).isBefore(startDate)) {
      setError('End Date Cannot Be Less Than Start Date')
    } else {
      setError('')
    }
  }, [endDate, startDate])
  const onClick = () => {
    setClear(true)
    setCreatedAtEnd('')
    setCreatedAtStart('')
    setError('')
    setStartDate(null)
    setEndDate(null)
  }
  useEffect(() => {
    if (clearDateTop) {
      onClick()
      setClearDateTop(false)
    }
  }, [clearDateTop])
  return (
    <Wrappers column style={{ display: isEmpanelled ? '' : 'none' }}>
      <FilterHeading>
        <FilterText>Date Range</FilterText>
        <FilterTextWithUnderLine onClick={() => onClick()}>
          {'Clear All'}
        </FilterTextWithUnderLine>
      </FilterHeading>
      <br />
      <StartMargin>
        <FilterText>From</FilterText>
      </StartMargin>
      <StartMargin>
        <ReportDatePickerDropdown
          label="Start"
          onChange={handleStartDateChange}
          clear={clear}
          setClear={setClear}
          setError={setError}
          error={error}
        />
      </StartMargin>

      <StartMargin>
        <FilterText>To </FilterText>
      </StartMargin>
      <EndMargin>
        <ReportDatePickerDropdown
          label="End"
          startDate={startDate}
          disabledYear={startDate === null}
          onChange={handleEndDateChange}
          clear={clear}
          setClear={setClear}
          setError={setError}
          error={error}
        />
      </EndMargin>

      <ErrorMessage>{error ? 'Note: ' + error : ''}</ErrorMessage>
    </Wrappers>
  )
}

export default TopDateRangeFilter
