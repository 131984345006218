import { connect } from 'react-redux'
import Courses from 'modules/Courses/index'
import {
  getCoursesList,
  AddCourses,
  getSkillList,
  DeleteCourses,
  EditCourses,
  ActiveIsCourses,
  DeleteSpecialization,
  AddMultiCourses,
  getDegreeDataValue,
  AddCoursesInSysConfig,
  EditCoursesInSysConfig,
} from 'modules/Courses/actions'
import {
  getInstituteListForSysConfig,
  getDepartmentListForSysConfigElastic,
  getSpecialisationListForSysConfigElastic,
} from 'modules/Systemconfig/Partials/InstituteSettings/actions'
import Instituteselectors from 'modules/Systemconfig/Partials/InstituteSettings/selectors'
import Selector from 'modules/Courses/selectors'
// import { getDegreeData } from 'modules/Students/actions'

const mapStateToProps = state => ({
  degreeList: Selector.getCourseList(state),
  degreeFilterList: Selector.getStudentDegreeList(state),
  instituteTableList: Instituteselectors.instituteSysConfigList(state),
  specialisationTableList:
    Instituteselectors.specialisationSysConfigList(state),
  departmentTableList: Instituteselectors.departmentSysConfigList(state),

})

const mapDispatchToProps = {
  getCoursesList,
  getSkillList,
  AddCourses,
  AddMultiCourses,
  getDegreeDataValue,
  DeleteCourses,
  EditCourses,
  ActiveIsCourses,
  DeleteSpecialization,
  getInstituteListForSysConfig,
  getDepartmentListForSysConfigElastic,
  getSpecialisationListForSysConfigElastic,
  AddCoursesInSysConfig,
  EditCoursesInSysConfig,
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
