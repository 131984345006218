import { Col, Row } from 'antd'
import AntdInput from 'components/Form/Input'
import {
  ContentHeight,
  EventHeader1,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React from 'react'
export default function MandatorySkillIndex() {
  return (
    <ContentHeight>
      {' '}
      <Row>
        <Col span={10}>
          <EventHeader1>Mandatory Skill Match Score</EventHeader1>
        </Col>
        <Col span={4}>
          <AntdInput
            ismargin
            placeholder={' Eg: 5.00'}
            rules={[
              {
                required: true,
                message: 'Kindly enter the data',
              },
            ]}
            value="5"
            disabled
            height="50px"
            width="80%"
            borderRadius="10px"
            backgroundColor="#FAFAFB"
            background="#FAFAFB"
          />
        </Col>
      </Row>
    </ContentHeight>
  )
}
