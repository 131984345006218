import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Dashboard/Partials/UsersFilter/Styles/style'

const OnboardingStatusFilter = ({
  corporateStatusFilter,
  setCorporateStatusFilter,
}) => {
  const options = [
    {
      label: 'Veri.Pending',
      value: '0',
    },
    {
      label: 'Onboarding',
      value: '1',
    },
    {
      label: 'Request To Change',
      value: '2',
    },
  ]

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={corporateStatusFilter}
        options={options}
        // onChange={value => setCorporateStatusFilter(value[value?.length - 1])}
        onChange={value => setCorporateStatusFilter(value)}
      />
    </Wrapper>
  )
}

export default OnboardingStatusFilter
