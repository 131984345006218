import Button from 'components/Button/index'
import VirtualizedCheckboxGroup from 'components/UIComponents/Filter/index'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import { getFunctionSysConfig } from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MenuFlex, StyledTab, SubmitFlex } from './Style/style'

const { TabPane } = StyledTab
const { COUNTRY_ID } = process.env

const CorporateFilter = ({
  FunctionTableList,
  setValue,
  value,
  submitFilter,
  userRole,
  getListOfState,
  setLocation,
  location,
  loading,
  setLoading,
  degreeSearch,
  setDegreeSearch,
  stateId,
  onCancelFilter,
  setStateId,
  stateList,
  setStateList,
  stateNames,
  setStateNames,
  searchCollege,
  setSearchCollege,
  industry,
  setIndustry,
  industryName,
  setIndustryName,
  cityList,
  cityId,
  setPermCityId,
  setCityName,
  cityName,
  setCityList,
  sector,
  setSector,
  sectorName,
  setSectorName,
  sectorList,
  fetchSectorData,
  check,
}) => {
  const dispatch = useDispatch()

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await getListOfState(countryId, search)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setStateList(updatedArray || [])
    }
  }

  useEffect(() => {
    getStateData(COUNTRY_ID, true)
  }, [])

  useEffect(() => {
    let dropDownUrl = 'crud/industry'
    let searchValue = searchCollege
    dispatch(
      getFunctionSysConfig({
        dropDownUrl,
        type: 'get',
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
      })
    )
  }, [searchCollege])

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetchSectorData = useCallback(
    debounce(data => {
      fetchSectorData(data)
    }, 500),
    []
  )

  const delayToFetch = useCallback(
    debounce(data => {
      setSearchCollege(data)
    }, 500),
    []
  )

  return (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="10px"
        popupClassName="filter"
      >
        {/* <div>
          Selected State: {selectedStateName}
        </div> */}

        <TabPane tab="Industry" key="1">
          <VirtualizedCheckboxGroup
            options={FunctionTableList?.result}
            value={industry}
            setValue={setIndustry}
            valueParam="name"
            labelParam="name"
            setStateName={setIndustryName}
            filterId={true}
            stateName={industryName}
            setDegreeSearch={delayToFetch}
            check={check}
            // onSelectState={handleStateSelection} // Pass the function here
          />
        </TabPane>
        <TabPane tab="Sector" key="2">
          <VirtualizedCheckboxGroup
            options={sectorList}
            value={sector}
            setValue={setSector}
            valueParam="name"
            labelParam="name"
            setStateName={setSectorName}
            filterId={true}
            stateName={sectorName}
            setDegreeSearch={delayToFetchSectorData}
            check={check}
          />
        </TabPane>
        <TabPane tab="State" key="3">
          <VirtualizedCheckboxGroup
            options={stateList}
            value={stateId}
            setValue={setStateId}
            valueParam="name"
            labelParam="name"
            setStateName={setStateNames}
            filterId={true}
            stateName={stateNames}
            setDegreeSearch={delayToFetch}
            check={check}
            getStateData={getStateData}
            labelType={'state'}
          />
        </TabPane>
        <TabPane tab="City" key="4">
          <VirtualizedCheckboxGroup
            options={cityList}
            value={cityId}
            setValue={setPermCityId}
            valueParam="name"
            labelParam="name"
            filterId={true}
            setStateName={setCityName}
            stateName={cityName}
            loading={loading}
            setLoading={setLoading}
            degreeSearch={degreeSearch}
            setDegreeSearch={delayToFetch}
            check={check}
          />
        </TabPane>
      </StyledTab>

      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={onCancelFilter} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )
}

export default CorporateFilter
