import React from 'react'

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      style={{ margin: 'auto' }}
    >
      <path
        d="M14 7C14 3.134 10.866 0 7 0C3.134 0 0 3.134 0 7C0 8.387 0.41 9.677 1.105 10.765H1.097C3.457 14.46 7 20 7 20L12.903 10.765H12.896C13.59 9.677 14 8.387 14 7ZM7 10C5.343 10 4 8.657 4 7C4 5.343 5.343 4 7 4C8.657 4 10 5.343 10 7C10 8.657 8.657 10 7 10Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default LocationIcon
