import { Empty, Tabs } from 'antd'
import Input from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import React from 'react'
import CONSTANTS, { journeyDropdown } from '../../../../../../utils/constants'
import Permission from '../../Permission'
import { FormItem } from './styles'

const AddRoleForm = ({
  eventList,
  setSubEventsId,
  setEventsId,
  setFormattedData,
  formattedData,
  setFormattedInternalData,
  formattedInternalData,
  setFormattedCorporateData,
  formattedCorporateData,
  setFormattedInstituteData,
  formattedInstituteData,
  form,
  view,
  journeyValue,
  singleRoleData,
  internalEventsList,
  corporateEventsList,
  instituteEventsList,
  setIntPermissions,
  setInstPermissions,
  setCorpPermissions,
  setInValid,
  validateEventsBasedOnJourney,
  selectionType,
  setSelectionType,
  journeyArray,
  setJourneyArray,
}) => {
  const updateJourneyEvents = removedJourney => {
    if (removedJourney == 'CORPORATE') {
      setCorpPermissions(prevValue => ({
        ...prevValue,
        events: [],
      }))
    } else if (removedJourney == 'INSTITUTE') {
      setInstPermissions(prevValue => ({
        ...prevValue,
        events: [],
      }))
    } else if (removedJourney == 'INTERNAL') {
      setIntPermissions(prevValue => ({
        ...prevValue,
        events: [],
      }))
    }
  }

  const handleJourneyChange = value => {
    setJourneyArray(value)

    if (value?.length < journeyArray?.length) {
      const removedValue = journeyArray.filter(
        journey => !value.includes(journey)
      )[0]

      updateJourneyEvents(removedValue)
    }
    validateEventsBasedOnJourney(value)

    if (value?.length) {
      let selectedKeysValue = value[value?.length - 1]
      if (selectedKeysValue == 'STUDENT') {
        selectedKeysValue = value[value?.length - 2]
      }
      setSelectionType(selectedKeysValue)
    }
  }

  const getJourneyMenus = journeyArray => {
    const journeys = [
      {
        value: 'Internal',
        key: 'INTERNAL',
        label: 'Internal Permissions *',
        isHidden: !journeyArray?.includes('INTERNAL'),
        events: internalEventsList,
        formattedData: formattedInternalData,
        setFormattedData: setFormattedInternalData,
        setPermissions: setIntPermissions,
        journeys: journeyArray,
      },
      {
        value: 'Corporate',
        key: 'CORPORATE',
        label: 'Corporate Permissions *',
        isHidden: !journeyArray?.includes('CORPORATE'),
        events: corporateEventsList,
        formattedData: formattedCorporateData,
        setPermissions: setCorpPermissions,
        setFormattedData: setFormattedCorporateData,
        journeys: journeyArray,
      },
      {
        value: 'Institute',
        key: 'INSTITUTE',
        label: 'Institute Permissions *',
        isHidden: !journeyArray?.includes('INSTITUTE'),
        events: instituteEventsList,
        formattedData: formattedInstituteData,
        setFormattedData: setFormattedInstituteData,
        setPermissions: setInstPermissions,
        journeys: journeyArray,
      },
      {
        value: 'Student',
        label: 'Student Permissions *',
        isHidden: true,
        key: 'STUDENT',
        journeys: journeyArray,
      },
    ]?.filter(item => !item.isHidden)
    return journeys
  }

  const onTabChange = type => {
    setSelectionType(type)
    validateEventsBasedOnJourney(journeyArray)
  }
  return (
    <>
      <Input
        type="text"
        width="100%"
        name={'name'}
        label="Role "
        optional={'*'}
        placeholder="Role Name"
        disabled={view}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('Role Name'),
          },
        ]}
        onChange={event => {
          form.setFieldsValue({
            name: event.target.value.replace(/[^A-Za-z ]|^ /g, ''),
          })
        }}
      />
      <FormItem
        name={'journeys'}
        rules={[
          {
            required: true,
            message: 'Please select Any One Role',
          },
        ]}
      >
        <AntdSelect
          OptionData={journeyDropdown}
          label="Journey *"
          onChange={value => {
            handleJourneyChange(value)
            form.setFieldsValue({ journey: value })
          }}
          nameParam="value"
          placeholder="Select Any Jouney"
          valueParam="id"
          showSearch={true}
          mode="multiple"
          disabled={view}
        />
      </FormItem>

      {journeyArray?.length
        ? ((journeyArray.length == 1 && journeyArray[0] !== 'STUDENT') ||
            journeyArray.length) && (
            <Tabs
              activeKey={selectionType}
              items={getJourneyMenus(journeyArray)}
              onChange={onTabChange}
            />
          )
        : null}

      {getJourneyMenus(journeyArray)?.map(journey => {
        return (
          <>
            {journey?.events?.permissions?.length ? (
              <Permission
                eventList={journey?.events?.permissions}
                view={view}
                setEventsId={setEventsId}
                setSubEventsId={setSubEventsId}
                setFormattedData={journey.setFormattedData}
                formattedData={journey?.formattedData}
                journeyValue={journey?.key}
                singleRoleData={singleRoleData}
                setPermissions={journey?.setPermissions}
                selectionType={selectionType}
                lable={journey?.value}
                setInValid={setInValid}
                validateEventsBasedOnJourney={validateEventsBasedOnJourney}
                journeyArray={journeyArray}
              />
            ) : (
              <Empty description="No Permissions Found" />
            )}
          </>
        )
      })}
    </>
  )
}

export default AddRoleForm
