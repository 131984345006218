import { connect } from 'react-redux'

import LayoutWithNav from 'modules/Layouts/Components/LayoutWithNav/index'

import { getRoles, getUserPermissions, getUserInfo } from '../../Auth/actions'

import selectors from '../../Auth/selectors'

const mapStateToProps = state => ({
  userData: selectors?.getUserData(state),
  roleList: selectors?.getRoleList(state),
})
const mapDispatchToProps = {
  getUserInfo,
  getRoles,
  getUserPermissions,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWithNav)
