import { Divider, Drawer, Form, Spin } from 'antd'
import Space from 'antd/lib/space'
import Button from 'components/Button/index'
import { default as AntdInput } from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'

import CheckIconWhite from 'components/icons/CheckIconWhite'
import { FlexWrapFooter } from 'modules/Systemconfig/Styles/style'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
const StyledDrawer = styled(Drawer)`
  .ant-drawer-close {
    display: inline-block;
    margin-right: 12px;
    color: #637487;
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
    position: absolute;
    right: 0;
  }
`
const CrudDrawer = ({
  tableData,
  open,
  onClose,
  content,
  form,
  onChangeCapture,
  handleSubmitCongif,
  setEditId,
  view,
  toEditId,
  setView,
  onSearch,
  // getFunctionSysConfig,
  menu,
  countryList,
  getCountryData,
  getStateData,
  getCityData,
  existedvalue,
  countryId,
  stateList,
  cityList,
  stateId,
  handleReset,
  drawerLoading,
}) => {
  const handleSubmit = values => {
    form.submit()
  }
  const [searchCountry, setSearchCountry] = useState('')
  const [searchState, setSearchState] = useState('')
  const [searchCity, setSearchCity] = useState('')

  const handleFinish = values => {
    handleSubmitCongif(values, 'post')
  }

  const handleClose = () => {
    setSearchCountry('')
    setSearchState('')
    setSearchCity('')
    onClose()
    handleReset()
  }

  // Initial load for country data
  useEffect(() => {
    getCountryData('')
  }, [])

  // Initial load for state data when country is selected
  useEffect(() => {
    if (countryId) {
      getStateData(countryId, '')
    }
  }, [countryId])

  // Initial load for city data when state is selected
  useEffect(() => {
    if (countryId && stateId) {
      getCityData(countryId, stateId, '')
    }
  }, [countryId, stateId])

  // Handle search changes
  useEffect(() => {
    let searchValue = searchCountry
    if (searchValue !== undefined) {
      getCountryData(searchValue)
    }
  }, [searchCountry])

  useEffect(() => {
    let searchStateValue = searchState
    if (searchStateValue !== undefined && countryId) {
      getStateData(countryId, searchStateValue)
    }
  }, [searchState])

  useEffect(() => {
    let searchValue = searchCity
    if (searchValue !== undefined && countryId && stateId) {
      getCityData(countryId, stateId, searchValue)
    }
  }, [searchCity])

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetch = useCallback(
    debounce((data, key) => {
      if (key == 'country') {
        setSearchCountry(data)
      }
      if (key == 'state') {
        setSearchState(data)
      }
      if (key == 'city') {
        setSearchCity(data)
      }
    }, 500),
    []
  )
  const item = { label: 'My Label', required: true, hideAsterisk: false }
  return (
    <StyledDrawer
      title={
        view
          ? 'Preview'
          : toEditId
          ? 'Edit ' + tableData.text
          : 'Create New ' + tableData.text
      }
      placement="right"
      onClose={handleClose}
      open={open}
      width={tableData.text === 'Degree' ? '60%' : 635}
    >
      <div style={{ padding: '20px', pointerEvents: view ? 'none' : '' }}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          {tableData.text === 'Country' && (
            <>
              <Form.Item
                name={'name'}
                label={'Country'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a Country',
                  },
                  {
                    pattern: /^(?!\s).*$/,
                    message: 'Country name cannot start with a space',
                  },
                ]}
              >
                <AntdInput
                  placeholder={'Eg.India '}
                  width="100%"
                  value={'name'}
                />
              </Form.Item>
              <Form.Item name={'alias'} label={'Short Form'}>
                <AntdInput
                  placeholder={'Eg.IND '}
                  width="100%"
                  value={'alias'}
                  onChange={event => {
                    form.setFieldsValue({
                      alias: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name={'capital'} label={'Capital'}>
                <AntdInput
                  placeholder={'Eg.New Delhi '}
                  width="100%"
                  value={'capital'}
                  onChange={event => {
                    form.setFieldsValue({
                      capital: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name={'currency'} label={'Currency'}>
                <AntdInput
                  placeholder={'Eg.INR '}
                  width="100%"
                  value={'currency'}
                  onChange={event => {
                    form.setFieldsValue({
                      currency: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>{' '}
              <Form.Item name={'phone_code'} label={'Phone Code'}>
                <AntdInput
                  placeholder={'Eg.91 '}
                  width="100%"
                  value={'phone_code'}
                  onChange={event => {
                    form.setFieldsValue({
                      phone_code: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>
            </>
          )}
          {tableData.text === 'State' && (
            <>
              <Form.Item
                name={'country_id'}
                label={'Country'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a Country',
                  },
                ]}
              >
                <AntdSelect
                  OptionData={countryList}
                  valueParam={'_id'}
                  nameParam={'name'}
                  placeholder={'Eg.India'}
                  showSearch={true}
                  rules={[
                    {
                      required: true,
                      message: `Please select ${item.label}!`,
                    },
                  ]}
                  onChange={value => {
                    form.setFieldsValue({ country_id: value })
                  }}
                  value={form.getFieldValue(['country_id'])}
                  onSearch={data => delayToFetch(data, 'country')}
                  filterOption={false}
                />
                
              </Form.Item>
              <Form.Item
                name={'name'}
                label={'State'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a State',
                  },
                  {
                    pattern: /^(?!\s).*$/,
                    message: 'State name cannot start with a space',
                  },
                ]}
              >
                <AntdInput
                  placeholder={'Eg.Tamil Nadu '}
                  width="100%"
                  value={'name'}
                />
              </Form.Item>
              <Form.Item name={'alias'} label={'Short Form'}>
                <AntdInput
                  placeholder={'Eg.TN '}
                  width="100%"
                  value={'alias'}
                  onChange={event => {
                    form.setFieldsValue({
                      alias: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>
            </>
          )}
          {tableData.text === 'City' && (
            <>
              <Form.Item
                name={'country_id'}
                label={'Country'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a Country',
                  },
                ]}
              >
                <AntdSelect
                  OptionData={countryList}
                  valueParam={'_id'}
                  nameParam={'name'}
                  placeholder={'Eg.India'}
                  showSearch={true}
                  onChange={value => {
                    onChangeCapture(value, 'country')
                    form.setFieldsValue({ country_id: value })
                    form.setFieldsValue({ state_id: '' })
                  }}
                  value={form.getFieldValue(['country_id'])}
                  onSearch={data => delayToFetch(data, 'country')}
                  filterOption={false}
                />
              </Form.Item>

              <Form.Item
                name={'state_id'}
                label={'State'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a State!',
                  },
                ]}
              >
                <AntdSelect
                  OptionData={stateList}
                  valueParam={'_id'}
                  nameParam={'name'}
                  placeholder={'Eg.Tamil Nadu'}
                  showSearch={true}
                  value={form.getFieldValue(['state_id'])}
                  disabled={!countryId && !form.getFieldValue(['country_id'])}
                  onChange={value => {
                    form.setFieldsValue({ state_id: value })
                  }}
                  rules={[
                    {
                      required: true,
                      message: `Please Select State!`,
                    },
                  ]}
                  onSearch={data => delayToFetch(data, 'state')}
                  filterOption={false}
                />
              </Form.Item>

              <Form.Item
                name={'name'}
                label={'City'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a City',
                  },
                  {
                    pattern: /^(?!\s).*$/,
                    message: 'City name cannot start with a space',
                  },
                ]}
              >
                <AntdInput
                  placeholder={'Eg.Chennai'}
                  width="100%"
                  value={'name'}
                />
              </Form.Item>
              <Form.Item name={'alias'} label={'Short Form'}>
                <AntdInput
                  placeholder={'Eg.CHN '}
                  width="100%"
                  value={'alias'}
                  onChange={event => {
                    form.setFieldsValue({
                      alias: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>
            </>
          )}
          {tableData.text === 'Location' && (
            <>
              <Form.Item
                name={'country_id'}
                label={'Country'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a Country',
                  },
                ]}
              >
                <AntdSelect
                  OptionData={countryList}
                  valueParam={'_id'}
                  nameParam={'name'}
                  placeholder={'Eg.India'}
                  showSearch={true}
                  onChange={value => {
                    onChangeCapture(value, 'country')
                    form.setFieldsValue({ country_id: value })
                    form.setFieldsValue({ state_id: '' })
                  }}
                  value={form.getFieldValue(['country_id'])}
                  onSearch={data => delayToFetch(data, 'country')}
                  filterOption={false}
                />
              </Form.Item>

              <Form.Item
                name={'state_id'}
                label={'State'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a State!',
                  },
                ]}
              >
                <AntdSelect
                  OptionData={stateList}
                  valueParam={'_id'}
                  nameParam={'name'}
                  placeholder={'Eg.Tamil Nadu'}
                  showSearch={true}
                  value={form.getFieldValue(['state_id'])}
                  disabled={!countryId && !form.getFieldValue(['country_id'])}
                  onChange={value => {
                    onChangeCapture(value, 'state', countryId)
                    form.setFieldsValue({ state_id: value })
                    form.setFieldsValue({ city_id: '' })
                  }}
                  rules={[
                    {
                      required: true,
                      message: `Please Select State!`,
                    },
                  ]}
                  onSearch={data => delayToFetch(data, 'state')}
                  filterOption={false}
                />
              </Form.Item>
              <Form.Item
                name={'city_id'}
                label={'City'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a City!',
                  },
                ]}
              >
                <AntdSelect
                  OptionData={cityList}
                  valueParam={'_id'}
                  nameParam={'name'}
                  placeholder={'Eg.Chennai'}
                  showSearch={true}
                  value={form.getFieldValue(['city_id'])}
                  disabled={!stateId && !form.getFieldValue(['state_id'])}
                  onChange={value => {
                    onChangeCapture(value, 'city')

                    form.setFieldsValue({ city_id: value })
                  }}
                  rules={[
                    {
                      required: true,
                      message: `Please Select City!`,
                    },
                  ]}
                  onSearch={data => delayToFetch(data, 'city')}
                  filterOption={false}
                />
              </Form.Item>

              <Form.Item
                name={'name'}
                label={'Location'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a Location',
                  },
                  {
                    pattern: /^(?!\s).*$/,
                    message: 'Location name cannot start with a space',
                  },
                ]}
              >
                <AntdInput
                  placeholder={'Eg.Chennai'}
                  width="100%"
                  value={'name'}
                />
              </Form.Item>
              <Form.Item
                name={'pin_code'}
                label={'Pin Code'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a  Pin Code',
                  },
                ]}
              >
                <AntdInput
                  placeholder={'Eg.627002'}
                  width="100%"
                  value={'pin_code'}
                  onChange={event => {
                    form.setFieldsValue({
                      pin_code: event.target.value.trim(),
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name={'alias'} label={'Short Form'}>
                <AntdInput
                  placeholder={'Eg.CHN '}
                  width="100%"
                  value={'alias'}
                  onChange={event => {
                    form.setFieldsValue({
                      alias: event.target.value.replace(/^ /, ''),
                    })
                  }}
                />
              </Form.Item>
            </>
          )}
          <Space>
            <FlexWrapFooter gap="20px" right>
              <Button.Secondary onClick={handleClose} text="Cancel" />
              {!view && (
                <Button.Primary
                  onClick={handleSubmit}
                  type="primary"
                  icon={drawerLoading ? <Spin /> : <CheckIconWhite />}
                  disabled={drawerLoading}
                  height="38px"
                  text={toEditId ? 'Update' : 'Save'}
                />
              )}
            </FlexWrapFooter>
          </Space>
        </Form>
      </div>
      <div>
        <Divider
          style={{
            position: 'absolute',
            bottom: '50px',
          }}
        />
      </div>
    </StyledDrawer>
  )
}
export default CrudDrawer
