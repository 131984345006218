import { Col, Row, Switch } from 'antd'
import {
  ContentHeight,
  EventHeader1,
  Gap
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React from 'react'
export default function CertificateIndex({
  setCertificateToggle,
  certificateToggle,
}) {
  return (
    <ContentHeight>
      {' '}
      <Row>
        <Gap>
          <Col span={3}>
            <EventHeader1>Certificate</EventHeader1>
          </Col>

          <Col span={1}>
            <EventHeader1>
              <Switch
                checked={certificateToggle}
                onChange={checked => setCertificateToggle(checked)}
              />
            </EventHeader1>
          </Col>
        </Gap>
      </Row>
    </ContentHeight>
  )
}
