import {
  SET_SINGLE_INSTITUTES_DATA,
  SET_INSTITUTE_INFO,
} from 'modules/Onboarding/Partials/Institutes/Register/actions'

const initialSingleInstituteInfo = {
  id: '',
  autoIncrementId: '',
  prefixId: '',
  createdAt: '',
  updatedUp: '',
  name: '',
  logo: '',
  campusName: '',
  campusLocation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  cityId: '',
  state: '',
  stateId: '',
  country: '',
  countryId: '',
  postalCode: '',
  website: '',
  contactPerson: '',
  contactNumber: '',
  contactPersonRole: '',
  secondaryContactNumber: '',
  secondaryContactEmail: '',
  isActive: true,
  pan: '',
  gst: '',
  deleted: '',
  authorisedSignatory: '',
  regDocs: '',
  campusId: '',
}

const initialState = {
  singleInstituteData: initialSingleInstituteInfo,
  instituteFormInfo: {
    addInstitute: {},
    contactInfo: {},
    taxInfo: {},
    addPartner: {},
    pricingAndContract: {},
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_INSTITUTES_DATA:
      return {
        ...state,
        singleInstituteData: action.data,
      }
    case SET_INSTITUTE_INFO:
      return {
        ...state,
        instituteFormInfo: { ...state.instituteFormInfo, ...action.data },
      }

    default:
      return state
  }
}
