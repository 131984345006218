import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Users/Partials/UsersFilter/Styles/style'

const UserRoleFilter = ({ userRole, userRoleValue, setUserRoleValue }) => {
  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={userRoleValue}
        options={userRole}
        valueParam={'name'}
        labelParam={'name'}
        onChange={value => setUserRoleValue([value[value?.length - 1]])}
      />
    </Wrapper>
  )
}

export default UserRoleFilter
