import Button from 'components/Button'
import Drawer from 'components/Drawer'
import {
  FooterFlex,
  Form,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import PlanDetails from 'modules/Onboarding/Partials/Corporates/Partials/PlanDrawer/Partials/PlanDetails'
import React from 'react'

const PlanDrawer = ({
  form,
  corporateClear,
  newPlanDrawer,
  setNewPlanDrawer,
  setNewUploadDocumentDrawer,
  title = 'Choose Plan',
  sendButtonText = 'Next',
  setNewCorporateDrawer,
}) => {
  const onClearAll = () => {
    form.resetFields()
    setNewPlanDrawer(false)
    corporateClear()
  }

  const onClear = () => {
    form.resetFields()
    setNewPlanDrawer(false)
    setNewCorporateDrawer(true)
  }

  const onSubmitFormData = async formData => {
    setNewPlanDrawer(false)
    setNewUploadDocumentDrawer(true)
  }
  const content = () => {
    return (
      <div>
        <Form
          scrollToFirstError
          onFinish={onSubmitFormData}
          id={'planForm'}
          form={form}
          layout="vertical"
        >
          <PlanDetails />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary text="Back" onClick={onClear} />
        <Button.Primary
          htmlType="submit"
          form="planForm"
          text={sendButtonText}
        />
      </FooterFlex>
    )
  }
  return (
    
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={onClearAll}
        visible={newPlanDrawer}
        content={content()}
        footer={footer()}
      />
    
  )
}

export default PlanDrawer
