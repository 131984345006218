import Button from 'components/Button'
import React from 'react'
import Flex from 'components/Flex'
import Group from 'components/Images/Group.png'
import {
  Content,
  Heading,
  Para,
} from 'modules/Onboarding/Components/RegisterSuccessful/RegisterSuccessfulPage/style'

const LinkGenerationDetails = () => {
  return (
    
      <Content alignCenter column center>
        <Flex>
          <img src={Group} alt='group'/>
        </Flex>
        <Flex column>
          <Heading center>Corporate added Successfully.</Heading>
          <Para>
            Corporate added and credential send successfully to the respective
            corporate mail.
          </Para>
        </Flex>
        <Button.Primary center htmlType="submit" text="Continue Onboarding" />
      </Content>
    
  )
}

export default LinkGenerationDetails
