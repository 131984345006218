/* eslint-disable react/prop-types */
import React from 'react'
import Flex from 'components/Flex'
import {
  AdjustIcon,
  SmallCirlcle,
  SpanText,
  StyledCardDiv,
  StyledH2,
  StyledImg,
  StyledP
} from 'components/Uploader/Styles'
import JPGIcon from 'components/icons/JPGIcon'
import PDFIcon from 'components/icons/PDFIcon'
import moment from 'moment'

const FileReaderViewOnly = ({ file, setFile }) => {
  let size = (file.size / 1024 / 1024).toFixed(2) + ' MB'

  return (
    <StyledCardDiv spaceBetween center alignCenter>
      <StyledImg>
        <AdjustIcon margin>
          {file?.type === 'pdf' ? <PDFIcon /> : <JPGIcon />}
        </AdjustIcon>
        <Flex column>
          <StyledH2>{file?.name}</StyledH2>
          <StyledP alignCenter>
            <SpanText>
              {moment(file?.date)?.format('Do MMM, YYYY') +
                ' at ' +
                moment(file?.date)?.format('h:mm')}
            </SpanText>
            <SmallCirlcle />
            <SpanText>{size}</SpanText>
          </StyledP>
        </Flex>
      </StyledImg>


    </StyledCardDiv>
  )
}

export default FileReaderViewOnly
