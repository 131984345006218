import React, { useState, useEffect } from 'react'

import { Container, Left, Right } from 'components/UIComponents/Toggle/style'
import { COLORS } from 'theme'

const Toggle = (
  { left, right,top,bottom, width, onClick, initialValue, disabled = false },
  props
) => {
  const [current, setCurrent] = useState(initialValue)

  useEffect(() => {
    setCurrent(initialValue)
  }, [initialValue])

  const handleClick = curr => {
    if (curr === 'UG') {
      setCurrent('UG')
      onClick('UG')
    } else if (curr === 'PG') {
      setCurrent('PG')
      onClick('PG')
    } else if (curr === 'Diploma') {
      setCurrent('Diploma')
      onClick('Diploma')
    } else if (curr === 'PGDM') {
      setCurrent('PGDM')
      onClick('PGDM')
    }
    else if (curr === 'Phd') {
      setCurrent('Phd')
      onClick('Phd')
    }
  }
  return (
    <Container disabled={disabled}>
      <Left
        color={current == 'UG' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'UG' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('UG')}
        disabled={disabled}
      >
        UG
      </Left>
      <Right
        color={current == 'PG' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'PG' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('PG')}
        disabled={disabled}
      >
       PG
      </Right>
      <Right
        color={current == 'Diploma' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'Diploma' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('Diploma')}
        disabled={disabled}
      >
        Diploma
      </Right>
      <Right
        color={current == 'PGDM' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'PGDM' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('PGDM')}
        disabled={disabled}
      >
        PGDM
      </Right>
      <Right
        color={current == 'Phd' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'Phd' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('Phd')}
        disabled={disabled}
      >
        Phd
      </Right>
    </Container>
  )
}

export default Toggle
