import {
  SET_INSTITUTE_LIST,
  SET_DEPARTMENT_LIST,
  SET_SPECIALISATION_LIST,
} from 'modules/Systemconfig/Partials/InstituteSettings/actions'

const initialState = {
  getInstituteListForSysConfig: {},
  getDepartmentListForSysConfig: {},
  getSpecialisationListForSysConfig: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTITUTE_LIST:
      return {
        ...state,
        getInstituteListForSysConfig: action.data,
      }
    case SET_DEPARTMENT_LIST:
      return {
        ...state,
        getDepartmentListForSysConfig: action.data,
      }
    case SET_SPECIALISATION_LIST:
      return {
        ...state,
        getSpecialisationListForSysConfig: action.data,
      }
    default:
      return state
  }
}
