import {
  SET_SINGLE_CORPORATES_DATA,
  SET_CORPORATE_INFO,
  SET_INSTITUTE_DATA,
  SET_INDUSTRY_DATA,
} from 'modules/Onboarding/Partials/Corporates/Register/actions'

export const initialSingleCorporateInfo = {
  id: '',
  auto_increment_id: '',
  prefix_id: '',
  created_at: '',
  updated_up: '',
  name: '',
  logo: '',
  industry: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  city_id: '',
  state: '',
  state_id: '',
  country: '',
  country_id: '',
  employeesNumber: '',
  establishedYear: '',
  postalCode: '',
  website: '',
  contactNumber: '',
  country_code: '',
  shortDescription: '',
  longDescription: '',
  contactEmail: '',
  contactPerson: '',
  contactPerson_role: '',
  corporateType: '',
  corporatePresentationLink: '',
  corporateVideoLink: '',
  pan: '',
  gst: '',
  authorisedSignatory: '',
  sector: '',
  regDocs: {},
  socialMedia: [],
  is_active: true,
  deleted_at: '',
  is_empanelled: false,
  sectorIndustryMapId: '',
}
export const initialCorporateFormInfo = {
  addCorporate: {},
  contactInfo: {},
  taxInfo: {},
  addPartner: {},
  pricingAndContract: {},
}
const initialState = {
  singleCorporateData: initialSingleCorporateInfo,
  corporateFormInfo: initialCorporateFormInfo,
  addInstitute: {},
  sectorBasedIndustryList: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_CORPORATES_DATA:
      return {
        ...state,
        singleCorporateData: action.data,
      }
    case SET_CORPORATE_INFO:
      return {
        ...state,
        corporateFormInfo: { ...state.corporateFormInfo, ...action.data },
      }
    case SET_INSTITUTE_DATA:
      return {
        ...state,
        addInstitute: action.data,
      }

    case SET_INDUSTRY_DATA:
      return {
        ...state,
        sectorBasedIndustryList: action.data,
      }

    default:
      return state
  }
}
