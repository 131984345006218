import { connect } from 'react-redux'
import CorporateSysConfig from 'modules/Systemconfig/Partials/CorporateSettings/index'
import selectors from 'modules/Onboarding/selectors'
import CompanySelectors from 'modules/Systemconfig/Partials/CorporateSettings/selectors'
import {
  getListOfCounties,
  getListOfState,
  getListOfCity,
  getIndustryList,
} from 'modules/Onboarding/actions'

import {getCompanyListForSysConfig,createCompanySysConfig} from 'modules/Systemconfig/Partials/CorporateSettings/action'
const mapStateToProps = state => ({
  countryList: selectors.getCountryList(state),
  CompanyTableList: CompanySelectors?.companySysConfigList(state)
})

const mapDispatchToProps = {
  getListOfCounties,
  getListOfState,
  getListOfCity,
  getIndustryList,
  getCompanyListForSysConfig,
  createCompanySysConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateSysConfig)
