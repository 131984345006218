import { Col, Icon, Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/Button/index'
import AntdInput from 'components/Form/Input'
import Switch from 'components/Form/Switch'
import DeleteIcon from 'components/icons/DeleteIcon'
import PlusIcon from 'components/icons/PlusIcon'
import 'draft-js/dist/Draft.css'
import {
  Form,
  InnerContextScrollForAcademic,
  PaddingContextScrollForAcademic,
} from 'modules/RankingAlgorithm/Styles/FormStyle.js'
import {
  ActiveHeader,
  EventHeader,
  EventHeader1,
  IconWrapper,
  Note,
  TopHeader2,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../../style.css'
export default function Index({
  singleAcademicData,
  academicDataAlone,
  setSingleAcademicData,
  setAcademicDataAlone,
  setAcademicValidation,
  academicName,
  academicListSelection,
  setAcademicToggle,
  academicToggle,
  form,
}) {
  const [addFieldDisabled, setAddFieldDisabled] = useState(false)
  const [keysList, setKeysList] = useState(singleAcademicData)
  const updateFromValues = list => {
    return list.map((entry, index) => {
      if (index === 0) {
        entry.from = '100'
      } else {
        const prevToValue = parseFloat(list[index - 1].to)
        entry.from = (prevToValue - 0.01).toFixed(2)
        entry.needToUpdate = false
      }
      return entry
    })
  }

  const addToForm = useCallback(() => {
    const lastEntry = keysList[keysList.length - 1]

    if (lastEntry && lastEntry.to === '') {
      setKeysList(prevList => {
        const updatedList = [...prevList]
        updatedList[prevList.length - 1] = {
          ...lastEntry,
        }
        return updatedList
      })
      setAcademicValidation(true)
    } else {
      const newEntry = {
        from: '',
        to: '',
        score: '',
      }

      if (keysList.length === 0) {
        newEntry.from = '100'
      } else {
        const lastToValue = parseFloat(keysList[keysList.length - 1].to)
        newEntry.from = (lastToValue - 0.01).toFixed(2)
      }

      setKeysList(prevList => [...prevList, newEntry])
    }
  }, [keysList])
  const onDelete = name => {
    let updatedList = keysList?.filter((data, index) => index !== name)
    const newListWithUpdatedFrom = updateFromValues(updatedList)
    setKeysList(newListWithUpdatedFrom)
  }
  useEffect(() => {
    if (singleAcademicData) {
      setKeysList(singleAcademicData)
    }
  }, [singleAcademicData])
  const onClickField = (value, index, type) => {
    let academic = keysList.map((data, indx) => {
      if (index === indx) {
        return {
          ...data,
          [type]: value,
        }
      }
      return data
    })
    const newListWithUpdatedFrom = updateFromValues(academic)
    setKeysList(newListWithUpdatedFrom)
    // setKeysList(academic)

    form.setFieldsValue({
      academic: academic,
    })
  }
  const updateAcademicData = () => {
    const updatedData = academicDataAlone.map(item => {
      if (item.academic === academicName) {
        return {
          ...item,
          scoreConfig: keysList,
        }
      }
      return item
    })
    const newListWithUpdatedFrom = updateFromValues(updatedData)
    setAcademicDataAlone(newListWithUpdatedFrom)
  }
  useEffect(() => {
    form.setFieldsValue({
      academic: keysList,
    })
    updateAcademicData()
    const toData = keysList.map(item => item.to)
    if (toData.includes(0) || toData.includes('0')) {
      setAddFieldDisabled(true)
    } else {
      setAddFieldDisabled(false)
    }
    setSingleAcademicData(keysList)
  }, [keysList])
  const onChangeSwitch = checked => {
    setAcademicToggle(checked)
    const updatedData = academicDataAlone.map(item => {
      if (item.academic === academicName) {
        return {
          ...item,
          isScoreActive: checked,
        }
      }
      return item
    })
    const newListWithUpdatedFrom = updateFromValues(updatedData)
    setAcademicDataAlone(newListWithUpdatedFrom)
  }
  useEffect(() => {
    form.resetFields()
  }, [academicName])
  const validateInput = (value, name) => {
    const regex = name === 'score' ? /^\d*\.?\d*$/ : /^\d*$/ // Only allow numbers and the period (.)
    return regex.test(value)
  }

  const handleKeyPress = (event, name) => {
    // Perform custom validation based on keypress
    const data = event.target.value + event.key
    const number = name === 'score' ? 5 : name === 'toMonth' ? 11 : 1000
    if (Number(data) > number || !validateInput(event?.key, name)) {
      event.preventDefault()
    }
  }
  return (
    <div id={'uploadForm'} key={keysList}>
      <Form.List name="academic">
        {(fields, { add, remove }) => {
          return (
            <>
              <Row>
                <TopHeader2 row spaceBetween alignCenter>
                  <Col span={15}>
                    <EventHeader>{academicListSelection}</EventHeader>
                  </Col>
                  <Col span={6}>
                    <ActiveHeader>
                      {academicToggle ? 'Active' : 'Inactive'}
                    </ActiveHeader>
                  </Col>

                  <Col span={3}>
                    <Switch
                      checked={academicToggle}
                      onChange={onChangeSwitch}
                    />
                  </Col>
                </TopHeader2>
              </Row>
              <Row>
                <Col span={4}>
                  <EventHeader1>From</EventHeader1>
                </Col>
                <Col span={4}>
                  <EventHeader1>To</EventHeader1>
                </Col>
                <Col span={4}>
                  <EventHeader1>Score</EventHeader1>
                </Col>
              </Row>
              <InnerContextScrollForAcademic>
                {fields.map(({ key, name }, index) => (
                  <PaddingContextScrollForAcademic key={name}>
                    <Row>
                      <Col span={4}>
                        <AntdInput
                          ismargin
                          key={name}
                          placeholder={' Eg: 50.00'}
                          name={[name, 'to']}
                          onChange={e =>
                            onClickField(e.target.value, name, 'to')
                          }
                          rules={[
                            {
                              required: true,
                              message: 'Kindly enter the data',
                            },

                            {
                              validator: (_, value) => {
                                if (value)
                                  if (
                                    !/^(100(\.00?)?|\d{1,2}(\.\d{0,2})?)$/.test(
                                      value
                                    )
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        'Kindly enter valid Number. Eg: 50.00'
                                      )
                                    )
                                  }

                                if (
                                  Number(value) >= Number(keysList[index]?.from)
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'The value must be lesser than ' +
                                        keysList[name]?.from
                                    )
                                  )
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                          suffix={
                            <div
                              style={{
                                color: '#B7BAC4',
                                fontSize: '1rem',
                              }}
                            >
                              %
                            </div>
                          }
                          value={keysList[name]?.to}
                          height="50px"
                          width="80%"
                          borderRadius="10px"
                          backgroundColor="#FAFAFB"
                        />
                      </Col>
                      <Col span={4}>
                        <AntdInput
                          ismargin
                          placeholder={' Eg: 50.00'}
                          key={name}
                          disabled
                          name={[name, 'from']}
                          rules={[
                            {
                              required: true,
                              message: 'Kindly enter the data',
                            },

                            {
                              validator: (_, value) => {
                                if (value)
                                  if (
                                    !/^(\d{1,2}(\.\d{1,2})?|100(\.00?)?)$/.test(
                                      value
                                    )
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        'Kindly enter valid Number. Eg: 50.00'
                                      )
                                    )
                                  }
                                if (keysList[name] === 0) {
                                  if (Number(value) > 5) {
                                    return Promise.reject(
                                      new Error(
                                        'The value must be lesser than or equal to ' +
                                          5
                                      )
                                    )
                                  }
                                } else if (
                                  Number(value) >=
                                  Number(keysList[name - 1]?.to)
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'The value must be lesser than ' +
                                        keysList[name - 1]?.to
                                    )
                                  )
                                }

                                return Promise.resolve()
                              },
                            },
                          ]}
                          onChange={e =>
                            onClickField(e.target.value, name, 'from')
                          }
                          suffix={
                            <div
                              style={{
                                color: '#B7BAC4',
                                fontSize: '1rem',
                              }}
                            >
                              %
                            </div>
                          }
                          value={
                            name == 0
                              ? keysList[name]?.from
                              : keysList[name]?.to
                          }
                          height="50px"
                          width="80%"
                          borderRadius="10px"
                          backgroundColor="#FAFAFB"
                          background="#FAFAFB"
                          prefix={<Icon type="percentage" />}
                        />
                      </Col>
                      <Col span={4}>
                        <AntdInput
                          ismargin
                          key={name}
                          name={[name, 'score']}
                          placeholder={' Eg: 5.00'}
                          onKeyPress={e => handleKeyPress(e, 'score')}
                          onChange={e =>
                            onClickField(e.target.value, name, 'score')
                          }
                          value={keysList[name]?.score}
                          rules={[
                            {
                              required: true,
                              message: 'Kindly enter the data',
                            },

                            {
                              validator: (_, value) => {
                                if (value) {
                                  if (
                                    !/^(?:[0-5](?:\.\d{1,2})?)$/.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        'Kindly enter valid Number. Eg: 5.00'
                                      )
                                    )
                                  }
                                }
                                if (name === 0) {
                                  if (Number(value) > 5) {
                                    return Promise.reject(
                                      new Error('The Max score is 5')
                                    )
                                  }
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                          height="50px"
                          width="80%"
                          borderRadius="10px"
                          backgroundColor="#FAFAFB"
                        />
                      </Col>
                      <IconWrapper
                        center
                        onClick={() => {
                          remove(name)
                          onDelete(name)
                        }}
                        disable={keysList?.length === 1}
                      >
                        <DeleteIcon />
                      </IconWrapper>
                    </Row>
                  </PaddingContextScrollForAcademic>
                ))}
                <Row>
                  <PaddingContextScrollForAcademic>
                    <Note>NOTE: Maximum Weightage Value is 5</Note>
                  </PaddingContextScrollForAcademic>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item>
                      <Button.Secondary
                        text={'Add More field'}
                        width="100%"
                        icon={<PlusIcon color="#3249D7" />}
                        dashed="true"
                        htmlType="submit"
                        form={'uploadForm'}
                        disabled={addFieldDisabled}
                        onClick={() => {
                          // add()
                          addToForm()
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </InnerContextScrollForAcademic>
            </>
          )
        }}
      </Form.List>
    </div>
  )
}
