import { Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
display:flex,
align-items:center,
`
const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #1890ff;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #1890ff;
    box-shadow: none;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 2.6rem;
    align-items: center;
    border-radius: 0.6rem;
    width: 6rem;
  }
`

const { Option } = Select

const ReportDatePickerDropdown = ({
  label,
  disabledYear,
  onChange,
  setClear,
  clear,
  setError,
}) => {
  const [year, setYear] = useState(null)
  const [month, setMonth] = useState(null)
  const [day, setDay] = useState(null)

  useEffect(() => {
    setClear(false)
    setYear(null)
    setMonth(null)
    setDay(null)
    setError('')
  }, [clear])

  const handleYearChange = year => {
    setClear(false)
    setYear(year)
  }
  const handleMonthChange = month => {
    setClear(false)
    const monthIndex = moment.months().indexOf(month)
    setMonth(monthIndex)
    // Check if the selected month has more days than the selected day
    if (
      day &&
      day > moment(year + '-' + (monthIndex + 1), 'YYYY-M').daysInMonth()
    ) {
      setDay(null)
    }
  }
  const handleDayChange = day => {
    setClear(false)
    setDay(day)
    // Check if the selected month has more days than the selected day
    if (
      month !== null &&
      day > moment(year + '-' + (month + 1), 'YYYY-M').daysInMonth()
    ) {
      setDay(null)
    }
  }
  useEffect(() => {
    if (!day && !year && !month) {
      setError('')
      return
    }

    if (!year || !month?.toString() || !day) {
      let errorMsg = `Please Select ${label}`

      if (!year) errorMsg += ` Year`
      if (!month?.toString()) errorMsg += ` Month`
      if (!day) errorMsg += ` Day`

      setError(errorMsg)
      return
    }

    if (day > moment(year + '-' + (month + 1), 'YYYY-M').daysInMonth()) {
      setError(
        `Invalid ${moment()
          .month(month)
          .format('MMMM')} day. Please select a valid day.`
      )
      return
    }

    setError('')
    const date = moment({ year, month, day })
    onChange(date)
  }, [day, month, year])

  const StyleForSelect = {
    marginRight: '10px',
    height: '2.5rem',
    alignItems: 'center',
  }

  const MonthStyle = {
    marginRight: '10px',
  }
  const DayStyle = {
    marginRight: '10px',
  }
  const currentYear = new Date().getFullYear()
  const previousYears = Array.from(
    { length: 11 },
    (_, index) => currentYear - index
  )
  const dropdownStyle = {
    minWidth: 'auto',
    width: 'auto',
    top: '100%',
    marginTop: '4px',
  }
  const StyledSelectContainer = styled.div`
    position: relative;
  `
  return (
    <Wrapper>
      <StyledSelectContainer>
        <StyledSelect
          placeholder={` Day`}
          value={day}
          showSearch={true}
          allowClear={true}
          onChange={handleDayChange}
          style={StyleForSelect}
          dropdownStyle={dropdownStyle}
        >
          {Array.from(
            {
              length: moment(year + '-' + (month + 1), 'YYYY-M').daysInMonth(),
            },
            (_, index) => (
              <Option key={index + 1} value={index + 1}>
                {index + 1}
              </Option>
            )
          )}
        </StyledSelect>
      </StyledSelectContainer>
      <StyledSelect
        showSearch={true}
        allowClear={true}
        placeholder={` Month`}
        value={moment.months()[month]}
        onChange={handleMonthChange}
        style={MonthStyle}
      >
        {moment.months().map((month, index) => (
          <Option key={month} value={month}>
            {month}
          </Option>
        ))}
      </StyledSelect>

      <StyledSelect
        showSearch={true}
        allowClear={true}
        placeholder={` Year`}
        value={year}
        onChange={handleYearChange}
        style={DayStyle}
      >
        {previousYears.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </StyledSelect>
    </Wrapper>
  )
}
export default ReportDatePickerDropdown
