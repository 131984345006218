import React, { useEffect, useState } from 'react'
import Control from 'components/Control/Control'
import Button from 'components/Button'
import {
  FormWrapper,
  SpinWrapper,
} from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import InstituteInfoForm from 'modules/Onboarding/Partials/Institutes/Register/InstituteInfo/InstituteInfoForm'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'

import {
  getListOfCounties,
  deleteFile,
  getMasterSearchApi,
} from 'modules/Onboarding/actions'
import { searchConfig } from '../../../../../../utils/config'
const InstituteInfo = ({
  singleInstituteData,
  form,
  onSubmit,
  setProfileFile,
  profileFile,
  institutePresentation,
  setInstitutePresentation,
  longDescription,
  setLongDescription,
}) => {
  const dispatch = new useDispatch()
  const [universityName, setUniversityName] = useState('')
  const [loading, setLoading] = useState(true)
  const universityList = useSelector(
    state => state?.CorporateData?.masterSearchApi
  )

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  useEffect(() => {
    let payLoad = { ...searchConfig.universities, q: '' }
    dispatch(getMasterSearchApi('universities', payLoad))
  }, [])

  useEffect(() => {
    dispatch(getListOfCounties())
  }, [])

  useEffect(() => {
    form.setFieldsValue({ profileUrl: profileFile })
  }, [profileFile])

  const onRemoveImage = async () => {
    let response = await dispatch(deleteFile(profileFile))
    if (response) {
      setProfileFile(null)
      form.setFieldsValue({ profileUrl: '' })
      SuccessMessage('File deleted successfully!')
    } else {
      ErrorMessage('Failed to delete the file!')
    }
  }

  return (
    <>
      {singleInstituteData && !loading ? (
        <FormWrapper
          scrollToFirstError
          onFinish={onSubmit}
          id={'instituteForm'}
          form={form}
          layout="vertical"
        >
          <InstituteInfoForm
            form={form}
            file={profileFile}
            setFile={setProfileFile}
            onRemoveImage={onRemoveImage}
            singleInstituteData={singleInstituteData}
            institutePresentation={institutePresentation}
            setInstitutePresentation={setInstitutePresentation}
            universityList={universityList}
            universityValue={universityName}
            setUniversityName={setUniversityName}
            longDescription={longDescription}
            setLongDescription={setLongDescription}
          />

          <Control top="25px" flexEnd>
            <Button.Primary text="Save and Continue" htmlType={'submit'} />
          </Control>
        </FormWrapper>
      ) : (
        <SpinWrapper center>
          <Spin />
        </SpinWrapper>
      )}
    </>
  )
}

export default InstituteInfo
