import { Tooltip } from 'antd'
import CustomTag from 'components/CustomTag'
import { FilterText } from 'components/FilterDiv'
import Pagination from 'components/Pagination'
import SearchInput from 'components/SearchInput'
import { RowNumberData } from 'components/TableStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import CourseFilter from 'modules/Courses/Partials/CourseFilter'
import CoursesStatus from 'modules/Courses/Partials/CoursesStatus'
import ActionMenu from 'modules/Courses/Partials/CoursesTable/ActionMenu'
import { ContentCenterDiv, SkillRow } from 'modules/Courses/Style/style'
import {
  NavWrapper,
  TableTop,
} from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/Style/style'
import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toCamelCase } from '../../../../utils/camelCase'

const CoursesInfoTable = ({
  degreeList,
  onEdit,
  onDelete,
  loading,
  setPage,
  page,
  degreeFilterList,
  onPageChange,
  setSearch,
  onActivate,
  duration,
  setDuration,
  setExamType,
  setDegreeFilter,
  listOfStudentSkills,
  setSkillList,
  setStatusFilter,
  setSort,
  sortBy,
  setOrderBy,
  orderBy,
  cruds,
  systemConfig,
  getDepartmentListForSysConfig,
  departmentTableList,
  setDepartmentFilter,
  departmentFilter,
  domainFilter,
  setDomainFilter,
  degreeMapId,
  setDegreeMapId,
  addCourse,
  setAddCourse,
  setEditData,
  setView,
  getInstituteListForSysConfig,
  instituteTableList,
  getSpecialisationListForSysConfig,
  specialisationFilter,
  setSpecialisationFilter,
  specialisationTableList,
}) => {
  console.log(page,degreeList,'page')
  const [selectEdit, setSelectEdit] = useState()
  const location = useLocation()
  const editCourse = (event, param) => {
    setSelectEdit(param)
  }
  const editClicked = (event, item, view) => {
    setView(false)
    setSelectEdit(item)
    setAddCourse(true)
    setEditData(item)
    onEdit(item)
  }
  const deletedClicked = () => {
    onDelete(selectEdit)
  }
  const setStatusIsActive = (event, item) => {
    setView(false)
    onActivate(item)
    setEditData(item)
  }

  const delaySaveToDb = useCallback(
    debounce(text => {
      setPage(0)
      setSearch(text)
    }, 1000),
    []
  )
  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const showText = () => {
    const currentPage = page - 1
    const currentCount =
      currentPage === degreeList?.totalPage
        ? degreeList?.count
        : currentPage * 10 +
          (degreeList?.result?.length || degreeList?.courses?.length)

    if (degreeList?.data?.count > 0) {
      return (
        'Showing ' +
        (degreeList?.count ? degreeList?.count : '0') +
        ' of ' +
        (degreeList?.count ? degreeList?.count : '0')
      )
    } else if (currentPage === degreeList?.totalPage) {
      return 'Showing ' + degreeList?.count + ' of ' + degreeList?.count
    } else if (!degreeList?.count) {
      return 'Showing ' + 0 + ' of ' + 0
    } else {
      return (
        'Showing ' +
        currentCount +
        ' of ' +
        (degreeList?.count ? degreeList?.count : '0')
      )
    }
  }

  const handleSorting = (pagination, filter, sorter) => {
    const sortKey = sorter?.order
      ? sorter?.columnKey
        ? sorter?.columnKey === 'specialization'
          ? 'stream'
          : sorter?.columnKey
        : ''
      : ''

    const sortOrder = sorter?.order
      ? sorter?.order === 'ascend'
        ? 'asc'
        : 'desc'
      : ''
    setSort(sortKey)
    setOrderBy(sortOrder)
  }

  const onViewClick = (event, item) => {
    setView(true)
    setSelectEdit(item)
    setAddCourse(true)
    setEditData(item)
    onEdit(item)
  }

  const columns = [
    {
      title: 'DEGREE',
      dataIndex: 'name',
      key: 'degree',
      sorter: true,
      sortDirections: ['ascend', 'descend'],

      render: (text, item) => (
        <RowNumberData>
          {systemConfig ? (
            toCamelCase(item?.degree?.name)
          ) : (
            <>
              {toCamelCase(item?.degreeStreamMap?.degree?.name)}
              {' - '}
              {toCamelCase(item?.degreeStreamMap?.degree?.shortForm)}
            </>
          )}
        </RowNumberData>
      ),
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'department',
      key: 'stream',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text, item) => (
        <RowNumberData>
          {systemConfig
            ? toCamelCase(item?.stream?.name)
            : toCamelCase(item?.degreeStreamMap?.stream?.name)}
        </RowNumberData>
      ),
    },
    {
      title: 'Specialization',
      dataIndex: 'specialization',
      key: 'specialization',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text, item) => (
        <>
          {systemConfig ? (
            <Tooltip
              title={item?.specialisation
                ?.map(spec => toCamelCase(spec?.name))
                ?.join(', ')}
            >
              <SkillRow>
                {item?.specialisation?.length > 1 ? (
                  <>
                    <CustomTag
                      message={toCamelCase(item?.specialisation?.[0]?.name)}
                      bordered={true}
                      background="#F2F3F5"
                      colorType="#808697"
                      border="#F2F3F5"
                    />
                    <CustomTag
                      message={`+${item?.specialisation?.length - 1}`}
                      bordered={true}
                      background="#F2F3F5"
                      colorType="#808697"
                      border="#F2F3F5"
                    />
                  </>
                ) : item?.specialisation?.length === 1 ? (
                  <CustomTag
                    message={toCamelCase(item?.specialisation?.[0]?.name)}
                    bordered={true}
                    background="#F2F3F5"
                    colorType="#808697"
                    border="#F2F3F5"
                  />
                ) : (
                  <RowNumberData>-</RowNumberData>
                )}
              </SkillRow>
            </Tooltip>
          ) : (
            <Tooltip
              title={item?.courseSpecialisationMap
                ?.map(spec => toCamelCase(spec?.specialisation?.name))
                ?.join(', ')}
            >
              <SkillRow>
                {item?.courseSpecialisationMap?.length > 1 ? (
                  <>
                    <CustomTag
                      message={toCamelCase(
                        item?.courseSpecialisationMap[0]?.specialisation?.name
                      )}
                      bordered={true}
                      background="#F2F3F5"
                      colorType="#808697"
                      border="#F2F3F5"
                    />
                    <CustomTag
                      message={`+${item?.courseSpecialisationMap?.length - 1}`}
                      bordered={true}
                      background="#F2F3F5"
                      colorType="#808697"
                      border="#F2F3F5"
                    />
                  </>
                ) : item?.courseSpecialisationMap?.length === 1 ? (
                  <CustomTag
                    message={toCamelCase(
                      item?.courseSpecialisationMap[0]?.specialisation?.name
                    )}
                    bordered={true}
                    background="#F2F3F5"
                    colorType="#808697"
                    border="#F2F3F5"
                  />
                ) : (
                  <RowNumberData>-</RowNumberData>
                )}
              </SkillRow>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'DOMAIN',
      dataIndex: 'domain',
      key: 'domain',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_, item) => (
        <RowNumberData>
          {systemConfig ? (
            toCamelCase(item?.degree?.domain?.name)
          ) : (
            <>{toCamelCase(item?.degreeStreamMap?.degree?.domain?.name)}</>
          )}
        </RowNumberData>
      ),
    },
    {
      title: 'DURATION',
      dataIndex: 'duration',
      key: 'duration',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text, item) => (
        <RowNumberData>
          {systemConfig
            ? item?.duration
            : item?.degreeStreamMap?.duration || '-'}
        </RowNumberData>
      ),
    },

    {
      title: 'EXAM TYPE',
      dataIndex: 'examType',
      key: 'examType',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text, item) => (
        <RowNumberData>
          {systemConfig
            ? toCamelCase(item.examType)
            : toCamelCase(item?.degreeStreamMap?.examType) || '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'SKILLS',
      dataIndex: 'skills',
      key: 'skills',
      hidden: systemConfig || location?.pathname.includes('coursemapping'),

      render: (text, item) => (
        <SkillRow>
          {item?.ancillaryMainSkills?.length > 1 ? (
            <>
              <CustomTag
                message={toCamelCase(item?.ancillaryMainSkills[0])}
                bordered={true}
                background="#F2F3F5"
                colorType="#808697"
                border="#F2F3F5"
              />
              <CustomTag
                message={`+${item?.ancillaryMainSkills?.length - 1}`}
                bordered={true}
                background="#F2F3F5"
                colorType="#808697"
                border="#F2F3F5"
              />
            </>
          ) : item?.ancillaryMainSkills?.length === 1 ? (
            <CustomTag
              message={toCamelCase(item?.ancillaryMainSkills[0])}
              bordered={true}
              background="#F2F3F5"
              colorType="#808697"
              border="#F2F3F5"
            />
          ) : (
            <RowNumberData>-</RowNumberData>
          )}
        </SkillRow>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text, item) => <CoursesStatus status={item?.status} />,
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 100,
      render: (id, item) => (
        <ActionMenu
          item={item}
          editCourse={editCourse}
          editClicked={editClicked}
          deletedClicked={deletedClicked}
          setStatusIsActive={setStatusIsActive}
          cruds={cruds}
          systemConfig={systemConfig}
          addCourse={addCourse}
          setAddCourse={setAddCourse}
          onViewClick={onViewClick}
          isStudentLinked={item?.isStudentLinked}
        />
      ),
    },
  ].filter(item => !item.hidden)

  const searchBy = 'Search by Domain / Degree / Department / Specialization'

  return (
    <NavWrapper column>
      <TableTop centerVertically>
        <Tooltip title={searchBy}>
          <SearchInput
            width={'25%'}
            placeholder={searchBy}
            bordered={false}
            setSearchValue={text => {
              delaySaveToDb(text)
            }}
          />
        </Tooltip>
        <CourseFilter
          degreeList={degreeFilterList}
          duration={duration}
          setDuration={setDuration}
          setExamType={setExamType}
          setDomainFilter={setDomainFilter}
          setDegreeFilter={setDegreeFilter}
          setSkillList={setSkillList}
          listOfStudentSkills={listOfStudentSkills}
          setStatusFilter={setStatusFilter}
          systemConfig={systemConfig}
          getDepartmentListForSysConfig={getDepartmentListForSysConfig}
          departmentTableList={departmentTableList}
          setDepartmentFilter={setDepartmentFilter}
          departmentFilter={departmentFilter}
          domainFilter={domainFilter}
          setDegreeMapId={setDegreeMapId}
          degreeMapId={degreeMapId}
          getInstituteListForSysConfig={getInstituteListForSysConfig}
          instituteTableList={instituteTableList}
          getSpecialisationListForSysConfig={getSpecialisationListForSysConfig}
          setSpecialisationFilter={setSpecialisationFilter}
          specialisationFilter={specialisationFilter}
          specialisationTableList={specialisationTableList}
        />
        <FilterText>{showText()}</FilterText>
      </TableTop>
      <StyledTable
        pagination={false}
        columns={columns}
        dataSource={systemConfig ? degreeList?.result : degreeList?.courses}
        loading={loading}
        onChange={handleSorting}
      />
      {!loading && (
        <ContentCenterDiv>
          <Pagination
            current={page}
            total={degreeList?.count}
            onChange={onPageChange}
            defaultCurrent={1}
          />
        </ContentCenterDiv>
      )}
    </NavWrapper>
  )
}

export default CoursesInfoTable
