import {
  RowNumberData,
  RowNumberEmailData,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import React from 'react'
import CONSTANTS from 'utils/constants'
import { toCamelCase } from '../../../../../utils/camelCase'

export const CorporateMenuData = [
  {
    id: 1,
    text: 'Company',
  },
  // {
  //   id: 2,
  //   text: 'Corporate Entity',
  // },
  // {
  //   id: 3,
  //   text: 'Corporate Sub Master',
  // },
  // {
  //   id: 4,
  //   text: 'Corporate Classification',
  // },
]

export const CorporateMasterData = [
  {
    title: 'CIN',
    dataIndex: 'corporateIdentificationNumber',
    key: 'corporateIdentificationNumber',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {rowData?.corporateIdentificationNumber || '-'}
      </RowNumberData>
    ),
  },
  {
    title: 'Company',
    dataIndex: 'companyName',
    key: 'companyName',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
       
        {toCamelCase(rowData?.companyName) || '-'}
      </RowNumberData>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'emailAddr',
    key: 'emailAddr',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberEmailData>{rowData?.emailAddr || '-'}</RowNumberEmailData>
    ),
  },
  {
    title: 'Company Status',
    dataIndex: 'companyStatus',
    key: 'companyStatus',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {toCamelCase(rowData?.companyStatus) || '-'}
      </RowNumberData>
    ),
  },
  {
    title: 'Address',
    dataIndex: 'registeredOfficeAddress',
    key: 'registeredOfficeAddress',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {toCamelCase(rowData?.registeredOfficeAddress) || '-'}
      </RowNumberData>
    ),
  },
]

export const CorporateEntityData = [
  {
    title: 'Corporate Entity',
    dataIndex: 'entity',
    key: 'entity',
    render: (_, rowData) => (
      <RowNumberData>Infosys Limited India</RowNumberData>
    ),
  },
  {
    title: 'Corporate',
    dataIndex: 'corporate',
    key: 'corporate',
    render: (_, rowData) => <RowNumberData>{rowData?.data}TCS</RowNumberData>,
  },
]

export const CorporateSubMasterData = [
  {
    title: 'Corporate Sub Master',
    dataIndex: 'degree',
    key: 'degree',
    render: (_, rowData) => <RowNumberData>Fortune 500</RowNumberData>,
  },
  {
    title: 'Corporate',
    dataIndex: 'corporate',
    key: 'corporate',
    render: (_, rowData) => <RowNumberData>{rowData?.data} TCS</RowNumberData>,
  },
]

export const CorporateClassification = [
  {
    title: 'Corporate Classification',
    dataIndex: 'degree',
    key: 'degree',
    render: (_, rowData) => <RowNumberData>Consultancy</RowNumberData>,
  },
  {
    title: 'Corporate',
    dataIndex: 'corporate',
    key: 'corporate',
    render: (_, rowData) => <RowNumberData>TCS</RowNumberData>,
  },
]

export const AddCorporateMasterContent = [
  {
    id: '1',
    name: 'companyName',
    label: 'Company',
    placeholder: 'Company',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '2',
    name: 'corporateIdentificationNumber',
    label: 'Identification Number',
    placeholder: 'Identification Number',
    pattern: CONSTANTS.REGEX.CIN_NUMBER,

    required: false,
    type: 'text',
  },

  {
    id: '3',
    name: 'companyStatus',
    label: 'Company Status',
    placeholder: 'Company Status',
    required: false,
    type: 'text',
  },
  {
    id: '4',
    name: 'registeredOfficeAddress',
    label: 'Address',
    placeholder: 'Address',
    required: false,
    type: 'text',
  },
  {
    id: '5',
    name: 'emailAddr',
    placeholder: 'example@gmail.com',
    type: 'email',
  },
]

export const AddCorporateSubMasterContent = [
  {
    id: '1',
    name: 'CorporateSubMaster',
    label: 'Corporate Sub Master',
    placeholder: 'Corporate Sub Master',
    required: true,
    type: 'text',
  },
  {
    id: '2',
    name: 'Corporate ',
    label: 'Corporate',
    placeholder: 'Corporate',
    required: true,
    type: 'text',
  },
]

export const AddCorporateClassificationContent = [
  {
    id: '1',
    name: 'CorporateClassification',
    label: 'Corporate Classification',
    placeholder: 'Corporate Classification',
    required: true,
    type: 'text',
  },
  {
    id: '2',
    name: 'Corporate ',
    label: 'Corporate',
    placeholder: 'Corporate',
    required: true,
    type: 'text',
  },
]
export const AddCorporateEntityContent = [
  {
    id: '1',
    name: 'CorporateEntity',
    label: 'Corporate Entity',
    placeholder: 'Corporate Entity',
    required: true,
    type: 'text',
  },
  {
    id: '2',
    name: 'Corporate ',
    label: 'Corporate',
    placeholder: 'Corporate',
    required: true,
    type: 'text',
  },
]
export const Status = [
  {
    value: 'Active',
    label: 'Active',
    checked: false,
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    checked: false,
  },
]
