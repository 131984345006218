import ActiveUsers from 'components/icons/ActiveUsers'
import InActiveUsers from 'components/icons/InactiveUsers'
import TotalUsers from 'components/icons/TotalUsers'
import React from 'react'

export const getCardData = userMetrics => {
  return [
    {
      key: 1,
      icon: <TotalUsers />,
      text: 'Total Users',
      value: userMetrics?.data?.totalUser || 0,
      state: 'all',
      current: 'Total Users',
      isSelected: false,
    },
    {
      key: 2,
      icon: <ActiveUsers />,
      text: 'Active',
      value: userMetrics?.data?.activeUser || 0,
      state: 'active',
      current: 'Active',
      isSelected: false,
    },
    {
      key: 3,
      icon: <InActiveUsers />,
      text: 'Inactive',
      value: userMetrics?.data?.inActiveUser || 0,
      state: 'inactive',
      current: 'Inactive',
      isSelected: false,
    },
    // {
    //   key: 4,
    //   icon: <MailInvite color={'#5B6DDF'} />,
    //   text: 'Pending Invites',
    //   value: userMetrics?.inActiveUsers || 0,
    //   state: 'false',
    //   current: 'Inactive',
    //   isSelected: false,

    // },
  ]
}
