import styled from 'styled-components'

export const DropDownContent = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0a0f2b;
`
export const DropDownOption = styled.div`
  display: flex;
  gap: 10px;
`

export const DropdownButton = styled.button`
  border: none;
  background: unset;
  display: flex;
  gap: 10px;
`
