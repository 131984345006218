import Flex from 'components/Flex'
import styled from 'styled-components'

export const Container = styled(Flex)`
  width: 100%;
  height: 100vh;
  background-color: #fafafb;
  height: auto;
  max-height: calc(100vh - 82px);
  // overflow-y: scroll;
  overflow-x: fixed;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const SubContainer = styled(Flex)`
  width: 80%;
  margin-top: 100px;
  margin-left: 8%;
  position: inherit;
  //  @media only screen and (max-device-width: 767px) {
  //    position: none;
  //    width:100%;
  //  }
`
export const ProgressContainer = styled(Flex)`
  width: 25%;
  justify-content: end;
  align-items: center;
  height: 80vh;
`

export const Wrapper = styled(Flex)`
  width: ${props => (props?.customWidth ? props?.customWidth : '50%')};
  overflow: auto;
  margin-bottom: ${props => props?.mBottom};
`

export const Left = styled.div`
  margin-top: 75px;
  width: 40%;
  height: auto;
  position: absolute;
  background-color: #fafafb;
`

export const Right = styled.div`
  margin-top: 20px;
  width: 60%;
  height: auto;
  padding-top: 15px;
  right: 300px;
  position: absolute;
`

export const H2 = styled.div`
  font-weight: 700;
  font-size: 1.2857142857142858em;
  line-height: 24px;
  color: #4d566e;
  margin-top: ${props => (props.marginTop ? props.marginTop : null)};
`

export const Heading = styled.div`
  font-weight: 700;
  font-size: 1.7em;
  line-height: 30px;
  color: #1b1f22;
  margin-top: 20px;
`
export const SubHeading = styled.div`
  //font-weight: 700;
  font-size: 1.1em;
  line-height: 30px;
  color: #4d566e;
`

export const Content = styled.div`
  margin-top: 33px;
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.1);
  border-radius: 10px;
  width: 700px;
  background-color: #ffffff;
  padding: 50px 75px;
  margin-bottom: ${props => props?.mBottom || 'unset'};
`

export const ValidateMessage = styled.div`
  font-weight: 500;
  font-size: 0.8em;
  color: red;
`

export const ProgroressBar = styled.div`
  width: 10%;
  position: fixed;
`
