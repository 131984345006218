import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import { Spinner } from 'modules/Dashboard/Styles/style'
import React, { useState, useLayoutEffect } from 'react'
import ErrorMessage from '../../../../../components/UIComponents/MessagePopup/ErrorMessage'
import { Form } from '../../AddRoleDrawer/Partials/AddRoleForm/styles'
import { FlexWrap } from '../Style/style'
import AddUserForm from './Partials'

const AddInstituteDrawer = ({
  addUserDrawer,
  setAddUserDrawer,
  instituteViewData,
  FunctionTableList,
  createInstitute,
  getInstituteData,
  setLocation,
  loc,
  setLoading,
  userData,
  getListOfCity,
  creatId,
  form,
  userRole,
  getListOfState,
  getInstituteView,
  getCorporateUniversityData,
}) => {
  const [id, getId] = useState(id)
  const [journeyId, setJourneyId] = useState([])
  const [checked, setChecked] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [cityId, setPermCityId] = useState('')
  const [stateId, setStateId] = useState('')
  const [tierId, setTierId] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [university, setUniversity] = useState('')

  const [degreeList, setDegreeList] = useState([])
  const [degree, setDegree] = useState('')

  const [departmentList, setDepartmentList] = useState([])

  const [department, setDepartment] = useState('')

  const [checkedCount, setCheckedCount] = useState(0) // Step 1: Initialize count state

  const callback = data => {
    getInstituteData([])
  }
  const onLinkInstitute = async value => {
    if (!checked) {
      ErrorMessage('Please Select one Institute')
      return
    }
    const payload = {
      roleId: userData?.admin_role_id,
      journeyId: journeyId,
      journey: 'INSTITUTE',
    }
    let response = await createInstitute(creatId, payload, callback)
    if (response?.status >= 200 && response?.status) {
      getInstituteData()
      form.resetFields()
      setLoading(false)
      setAddUserDrawer(false)
      setChecked(false)
      setJourneyId([])
      setPermCityId([])
      setStateId([])
      setTierId([])
      setCheckedCount(0)
    } else {
      setLoading(false)
    }
  }

  const onClose = value => {
    setAddUserDrawer(false)
    setChecked(false)
    setJourneyId([])
    getInstituteData([])
    setCheckedCount(0)
    setPageNumber(1)
    setPermCityId([])
    setStateId([])
    setTierId([])
    form.resetFields()
    setUniversity([])
    setDegree([])
    setDepartment([])
  }
  const fetchUniversityData = async (searchValue = '') => {
    let dropDownUrl = 'crud/universities'
    const response = await getCorporateUniversityData({
      dropDownUrl,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,

      searchValue,
    })
    if (response?.result) {
      setUniversityList(response.result)
    }
  }

  useLayoutEffect(() => {
    fetchUniversityData()
  }, [])
  const fetchDegreeData = async (searchValue = '') => {
    let dropDownUrl = 'crud/degree'
    const response = await getCorporateUniversityData({
      dropDownUrl,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,

      searchValue,
    })
    if (response?.result) {
      setDegreeList(response.result)
    }
  }

  useLayoutEffect(() => {
    fetchDegreeData()
  }, [])
  const fetchDepartmentData = async (searchValue = '') => {
    let dropDownUrl = 'crud/streams'
    const response = await getCorporateUniversityData({
      dropDownUrl,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,

      searchValue,
    })
    if (response?.result) {
      setDepartmentList(response.result)
    }
  }

  useLayoutEffect(() => {
    fetchDepartmentData()
  }, [])

  const content = () => {
    return (
      <>
        {!instituteViewData && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          text={'Save'}
          id={'linkInstituteForm'}
          form={form}
          layout="vertical"
          onFinish={onLinkInstitute}
        >
          <AddUserForm
            userRole={userRole}
            fetchUniversityData={fetchUniversityData}
            universityList={universityList}
            instituteViewData={instituteViewData}
            createInstitute={createInstitute}
            setLocation={setLocation}
            FunctionTableList={FunctionTableList}
            id={id}
            loc={loc}
            getListOfCity={getListOfCity}
            getInstituteView={getInstituteView}
            journeyId={journeyId}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            getId={getId}
            setChecked={setChecked}
            setTierId={setTierId}
            checked={checked}
            setPermCityId={setPermCityId}
            cityId={cityId}
            tierId={tierId}
            stateId={stateId}
            university={university}
            setUniversity={setUniversity}
            setStateId={setStateId}
            setJourneyId={setJourneyId}
            getInstituteData={getInstituteData}
            getListOfState={getListOfState}
            setCheckedCount={setCheckedCount}
            checkedCount={checkedCount}
            fetchDegreeData={fetchDegreeData}
            degreeList={degreeList}
            degree={degree}
            setDegree={setDegree}
            fetchDepartmentData={fetchDepartmentData}
            departmentList={departmentList}
            department={department}
            setDepartment={setDepartment}
          />
        </Form>
      </>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" height="38px" onClick={onClose} />
        <Button.Primary
          disabled={!checkedCount}
          text={
            <div
              style={{ fontWeight: '600' }}
            >{`${checkedCount} Selected`}</div>
          }
          htmlType="submit"
          form={'linkInstituteForm'}
          height="38px"
          loading={!instituteViewData}
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={'Select  Institute'}
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={addUserDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddInstituteDrawer
