import { Col, Row } from 'antd'
import Avatar from 'components/Avatar'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import AntdInput from 'components/Form/Input'
import ProgressCircle from 'components/ProgressCircle'
import FileReaderViewOnly from 'components/Uploader/FileReaderViewOnly'
import CopyAltIcon from 'components/icons/CopyAltIcon'
import { TwoFields } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import { PaddingDetail } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style'
import { FlexWrap } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/ViewCorporateDrawer/Style/style'
import {
  DetailHeader,
  HorizontalDivider,
  LeftText,
  RightText,
} from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Style/style'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import SuccessMessage from 'utils/SuccessMessage'

const { ADMIN_URL } = process.env

const ViewCorporateDrawer = ({
  viewCorporateDrawer,
  setViewCorporateDrawer,
  setVerifyCorporateDrawer,
}) => {
  const singleCorporateData = useSelector(
    state => state?.CorporateOnboarding?.singleCorporateData
  )
  const info = [
    {
      heading: 'Country',
      value: singleCorporateData?.country,
    },
    {
      heading: 'State',
      value: singleCorporateData?.state,
    },
    {
      heading: 'City',
      value: singleCorporateData?.city,
    },
    {
      heading: 'Website',
      value: singleCorporateData?.website,
    },
    {
      heading: 'Contact Name',
      value: singleCorporateData?.contactPerson,
    },
    {
      heading: 'Email id',
      value: singleCorporateData?.contactEmail,
    },
  ]

  const copyLink = () => {
    let copyText = document.getElementById('URLforonboarding')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const content = () => {
    return (
      <Fragment>
        <TwoFields spaceBetween>
          <div>
            <DetailHeader>{singleCorporateData?.name}</DetailHeader>
          </div>

          <ProgressCircle
            progressRate={singleCorporateData?.profileCompletionPerc}
            width="30px"
          />
        </TwoFields>
        {info?.map(item => (
          <Row key={item?.id} style={{ width: '100%' }}>
            <Col span={12}>
              <LeftText>{item.heading}</LeftText>
            </Col>
            <Col span={12}>
              <RightText>{item.value}</RightText>
            </Col>
          </Row>
        ))}

        {singleCorporateData?.regDocs && (
          <>
            <HorizontalDivider />
            <Row style={{ width: '100%' }}>
              <Col span={12}>
                <LeftText>{'Contract'}</LeftText>
              </Col>
              <Col span={12}>
                <RightText>
                  {singleCorporateData?.regDocs && (
                    <FileReaderViewOnly file={singleCorporateData?.regDocs} />
                  )}
                </RightText>
              </Col>
            </Row>
          </>
        )}
        <HorizontalDivider />
        <LeftText>URL for onboarding flow</LeftText>
        <PaddingDetail />
        <AntdInput
          column
          id={'URLforonboarding'}
          width="100%"
          suffix={<Avatar onClick={copyLink} src={<CopyAltIcon />} />}
          value={`${ADMIN_URL}onboarding/corporate/${singleCorporateData?.id}`}
        />
      </Fragment>
    )
  }
  const onClose = () => {
    setViewCorporateDrawer(false)
  }
  const onVerify = () => {
    setViewCorporateDrawer(false)
    setVerifyCorporateDrawer(true)
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClose} height="38px" />
        <Button.Primary
          htmlType="submit"
          onClick={onVerify}
          text="Verify"
          height="38px"
        />
      </FlexWrap>
    )
  }
  return (
    <Drawer
      width="40%"
      title={'Details'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={viewCorporateDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ViewCorporateDrawer
