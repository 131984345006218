import { Empty, Spin } from 'antd'
import Pagination from 'components/Pagination'
import {
  RowNumberDataReport,
  RowNumberDataReportAlignCenter,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { EmptyWrapper } from 'modules/Dashboard/Styles/style'
import {
  EmpanelledDateFormat,
  IndianCurrencyConvertor,
  RefinedData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setStudentPlacedList } from '../../../actions'
const StudentListTable = ({
  loading,
  setPageNum,
  pageNum,
  tableData,
  setSortBy,
  hasProp,
}) => {
  const dispatch = useDispatch()
  const onSorting = (pagination, filter, sorter) => {
    let order = ''
    let sort = sorter.field

    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)
  }
  const onPageChange = num => {
    setPageNum(num)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'a.prefix_id',
      key: 'a.prefix_id',
      visible: true,
      sorter: true,
      width: '5%',
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {RefinedData(tableData?.prefix_id)}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'STUDENT NAME',
      dataIndex: 'first_name',
      key: 'first_name',
      visible: true,
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {tableData.first_name +
            (tableData.last_name
              ? ' ' + String(tableData.last_name).trim()
              : '')}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'Degree',
      dataIndex: 'degree',
      key: 'degree',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {(tableData?.short_form
            ? RefinedData(tableData?.short_form) + ' - '
            : '') + RefinedData(tableData?.degree)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'b.department',
      key: 'b.department',
      sorter: true,
      render: (_, tableData) => (
        <RowNumberDataReport> {tableData?.course} </RowNumberDataReport>
      ),
      visible: false,
    },
    {
      title: 'SPECIALISATION',
      dataIndex: 'specialisation',
      key: 'specialisation',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.specialisation)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'CORPORATE NAME',
      dataIndex: 'institute_campus_name',
      key: 'institute_campus_name',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.corporate_name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.role)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'COMPENSATIONS',
      dataIndex: 'f.salary',
      key: 'f.salary',
      sorter: true,
      align: 'center',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {tableData.compensation
            ? IndianCurrencyConvertor(tableData?.compensation)
            : '-'}
        </RowNumberDataReportAlignCenter>
      ),
    },
    {
      title: 'COLLEGE NAME',
      dataIndex: 'corr_city',
      key: 'corr_city',
      sorter: true,
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReport>
          {RefinedData(tableData?.institute_campus_name)}
        </RowNumberDataReport>
      ),
    },
    {
      title: 'EMPANELLED',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      align: 'center',
      visible: true,
      render: (_, tableData) => (
        <RowNumberDataReportAlignCenter>
          {tableData?.createdAt
            ? EmpanelledDateFormat(tableData?.createdAt)
            : '-'}
        </RowNumberDataReportAlignCenter>
      ),
    },
  ]
  useEffect(() => {
    dispatch(setStudentPlacedList([]))
  }, [])
  return (
    <Wrapper>
      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={tableData?.result}
        onChange={onSorting}
        loading={loading}
        locale={{
          emptyText: (
            <EmptyWrapper center isSpin={loading}>
              {!loading ? (
                <Empty
                  description={
                    !hasProp
                      ? 'Kindly Select Filter Option to View the Data'
                      : 'No Students Found'
                  }
                />
              ) : (
                <Spin />
              )}
            </EmptyWrapper>
          ),
        }}
        scroll={{
          x: 2000,
          y: 500,
        }}
      />

      <Pagination
        onChange={onPageChange}
        totalPages={tableData?.count}
        pageSize={10}
        current={pageNum}
        currentCount={tableData?.result?.length}
      />
    </Wrapper>
  )
}

export default StudentListTable
