import styled from 'styled-components'
import Flex from 'components/Flex'
import Drawer from 'components/Drawer'
import { Form as AntdForm } from 'antd'


export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const FooterFlex = styled(Flex)`
  gap: 20px;
`
export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0px !important;
  .ant-form-item-control-input {
    min-height: 0px;
  }
`
export const Form = styled(AntdForm)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
`