import { connect } from 'react-redux'
import { saveFormData}  from 'modules/Onboarding/Partials/Corporates/actions'
import Onboarding from 'modules/Onboarding/index'
import selector from 'modules/Onboarding/Partials/Corporates/selectors'

const mapStateToProps = (state) => {
  return {
    formData: selector.getFormData(state)
  }
}

const mapDispatchToProps = {
      saveFormData
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)