import Flex from 'components/Flex'
import {
  StyledCheckbox,
  StyledGroupWithTop,
} from 'components/UIComponents/FilterCheckBoxComp/style'
import React from 'react'

const FilterCheckBoxComp = ({
  value,
  options,
  onChange,
  valueParam = 'value',
  labelParam = 'label',
  onChangeCheck,
  top = '0',
}) => {
  return (
    <StyledGroupWithTop value={value} onChange={onChange} top={top}>
      {options?.map((item, id) => (
        <Flex spaceBetween centerVertically key={item?.id}>
          <StyledCheckbox
            value={item[valueParam]}
            label={item[labelParam]}
            onChange={onChangeCheck}
          />
        </Flex>
      ))}
    </StyledGroupWithTop>
  )
}

export default FilterCheckBoxComp
