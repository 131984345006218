import React from 'react'
import { TreeDiv, AntdTree } from 'components/Tree/style'

const Tree = ({
  treeData,
  checkable = true,

  checkedKeys,
  onCheck,
  ...props
}) => {
  return (
    <TreeDiv id={'customTree'}>
      <AntdTree
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        treeData={treeData}
        checkable={checkable}
        selectable={false}
        getPopupContainer={() => document.getElementById('customTree')}
        {...props}
      />
    </TreeDiv>
  )
}

export default Tree
