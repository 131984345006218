import React from 'react'

const RepeatIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 
    <g mask="url(#mask0_1540_15607)">
    <path d="M12.5004 22.0008C10.167 22.0008 8.1547 21.2341 6.46337 19.7008C4.77137 18.1674 3.80037 16.2674 3.55037 14.0008C3.51703 13.7341 3.59637 13.5008 3.78837 13.3008C3.9797 13.1008 4.21703 13.0008 4.50037 13.0008C4.76703 13.0008 5.0047 13.0964 5.21337 13.2878C5.42137 13.4798 5.54203 13.7174 5.57537 14.0008C5.8087 15.7174 6.57537 17.1468 7.87537 18.2888C9.17537 19.4301 10.717 20.0008 12.5004 20.0008C14.4504 20.0008 16.1044 19.3214 17.4624 17.9628C18.821 16.6048 19.5004 14.9508 19.5004 13.0008C19.5004 11.0508 18.821 9.39645 17.4624 8.03778C16.1044 6.67978 14.4504 6.00078 12.5004 6.00078H12.3504L13.2254 6.87578C13.4087 7.05911 13.5004 7.29245 13.5004 7.57578C13.5004 7.85911 13.4087 8.09245 13.2254 8.27578C13.0254 8.47578 12.7837 8.57578 12.5004 8.57578C12.217 8.57578 11.9837 8.47578 11.8004 8.27578L9.20037 5.70078C9.10037 5.60078 9.0297 5.49245 8.98837 5.37578C8.94637 5.25911 8.92537 5.13411 8.92537 5.00078C8.92537 4.86745 8.94637 4.74245 8.98837 4.62578C9.0297 4.50911 9.10037 4.40078 9.20037 4.30078L11.8004 1.70078C11.9837 1.51745 12.217 1.42578 12.5004 1.42578C12.7837 1.42578 13.0254 1.51745 13.2254 1.70078C13.4087 1.90078 13.5004 2.14245 13.5004 2.42578C13.5004 2.70911 13.4087 2.94245 13.2254 3.12578L12.3504 4.00078H12.5004C13.7504 4.00078 14.9214 4.23845 16.0134 4.71378C17.1047 5.18845 18.0547 5.82978 18.8634 6.63778C19.6714 7.44645 20.313 8.39645 20.7884 9.48778C21.263 10.5798 21.5004 11.7508 21.5004 13.0008C21.5004 14.2508 21.263 15.4214 20.7884 16.5128C20.313 17.6048 19.6714 18.5548 18.8634 19.3628C18.0547 20.1714 17.1047 20.8134 16.0134 21.2888C14.9214 21.7634 13.7504 22.0008 12.5004 22.0008Z" fill="#CCCFD6"/>
    </g>
    </svg>
    
  )
}

export default RepeatIcon
