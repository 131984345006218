import { Divider, Spin } from 'antd'
import Flex from 'components/Flex'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const FlexWrap = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`
export const BorderedDiv = styled.div`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_T_90};
  border-radius: 10px;
  padding: 15px;
`
export const UserName = styled.h1`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.GREY_T_20};
`

export const GreyText = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: ${props => (props?.width ? props?.width : 'auto')};
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
`
export const MediumText = styled.p`
  margin-bottom: ${props => (props?.marginbottom ? props?.marginbottom : 0)};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
`
export const StyledDivider = styled(Divider)`
  margin: ${props => (props?.margin ? props.margin : '15px 0')};
`
export const Spinner = styled(Spin)`
  padding-top: 30%;
  position: absolute;
`
