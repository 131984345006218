import { Form as AntdForm, Radio, Spin } from 'antd'
import Flex from 'components/Flex'
import RadioButton from 'components/Form/RadioButton'
import styled from 'styled-components'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'

export const Form = styled(AntdForm)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
`
export const RadioGroup = styled(Radio.Group)`
  display: flex;
`
export const StyledRadio = styled(RadioButton)`
  margin-top: 10px;
`
export const ParagraphHeader = styled(Flex)`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: ${props => (props.isWidth ? 'left' : `center`)};
  color: #999fac;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: ${props => (props.isWidth ? '100px' : `max-content`)};
`
export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0px !important;
`
export const PageTitle = styled(Flex)`
  padding: 35px 25px 0px;
  // width: 1190px;
  // height: 53px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 20px;
`
export const SysHeadingTitle = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #010e30;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const SystemPaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 6rem;
  display: flex;
  flex-wrap: wrap;
`
export const SystemConfigSubHeading = styled(Flex)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
`

export const SystemConfigHeadTitle = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #010e30;
  flex: none;
  order: 0;
  flex-grow: 0;
`
export const VerticalContent = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #999fac;
`
export const SectionWrapper = styled(Flex)`
  width: 100% !important;
  // overflow: scroll;
  background: ${COLORS.WHITE} !important;
  line-height: 100%;
  padding: 0px;
  // position: absolute;
  // width: 1190px;
  // height: 642px;
  // left: 230px;
  // top: 95px;
  background: #ffffff;
  border: 1px solid #f2f3f5;
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.1);
  border-radius: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const TopHeader = styled(Flex)`
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: ${FONT.F_XL};
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const CardRow = styled(Flex)`
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  left: 9px;
  top: 0px;
  background: #f2f3f5;
  border: 1px solid #e6e7ea;
  border-radius: 10px;
`

export const Spinner = styled(Spin)`
  padding-top: 30%;
  position: absolute;
`
export const TabDiv = styled.div`
  padding: 0px 0px 0px 25px;
  width: 1190px;
  height: 642px;
  background: #ffffff;
  border: 1px solid #f2f3f5;
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.1);
  border-radius: 10px;
`

export const EmptyWrapper = styled(Flex)`
  margin: 50px 0px;
  margin: ${props => props?.isSpin && '102px 0px'};
`

export const CollageNameFlex = styled(Flex)`
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
`
export const CollegeNameDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  color: ${COLORS.GREY_T_20};
  text-transform: capitalize;
`
export const CollegeCity = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_XS};
  color: ${COLORS.GREY_T_60};
`
export const CollageDetailsFlex = styled(Flex)`
  line-height: normal;
  width: 100%;
  align-items: center;
  padding: 17px 0px;
  border-bottom: ${props =>
    !props?.isBoader && `1px solid ${COLORS.GREY_T_90}`};
`
export const TableSysWrapper = styled.div`
  padding-bottom: 100px;
  width: 100%;
  margin-top: -35px;
`

export const FlexWrapFooter = styled(Flex)`
  width: ${props => (props?.width ? props?.width : '100%')};
  // position: relative;
  padding: 20px;
  gap: 15px;

  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
`
export const BottomDivider = styled.div`
  // position: absolute !important;
  // bottom: 20px !important;
`

export const FlexWrap = styled(Flex)`
  width: ${props => (props.width ? props.width : '100%')};
  gap: ${props => (props.gap ? props.gap : '0')};
  padding: 20px;
`
export const Divider = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 1px;
  background-color: #e6e7ea;
`
