import React from 'react'
import FilterIcon from 'components/icons/FilterIcon'
import styled from 'styled-components'
import { COLORS } from 'theme'
import Flex from 'components/Flex'
import { Dropdown } from 'antd'
import { FONT } from 'theme/font'

export const FilterFlex = styled(Flex)`
  width: 50%;
`
export const FilterText = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_60};
`
export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  cursor: pointer;
  height: 50px;
  padding-left: 10px;
  border-left: 1px solid ${COLORS.GREY_T_90};
  border-right: 1px solid ${COLORS.GREY_T_90};
`
export const FlexWrap = styled(Flex)`
  width: ${props => (props?.width ? props?.width : '67%')};
  position: relative;
`

const FilterDiv = ({
  overlayMenu,
  label = 'Filter',
  trigger = 'click',
  open = false,
  setOpen,
  onOpenChange,
  width,
}) => {
  return (
    <FlexWrap width={width} id="filterDropdown">
      <StyledDropdown
        overlay={overlayMenu}
        trigger={[trigger]}
        open={open}
        onClick={() => setOpen(true)}
        onOpenChange={onOpenChange}
        getPopupContainer={() => document.getElementById('filterDropdown')}
      >
        <FilterFlex centerVertically onClick={e => e.preventDefault()}>
          <FilterIcon />
          <FilterText>{label}</FilterText>
        </FilterFlex>
      </StyledDropdown>
    </FlexWrap>
  )
}

export default FilterDiv
