import Button from 'components/Button/index'
import DraggerIcon from 'components/icons/DraggerIcon'
import Toggle from 'components/UIComponents/Toggle/index'
import 'draft-js/dist/Draft.css'
import {
  ActiveHeaderTop,
  FooterDivCommon,
  FooterFlex,
  PaddedToggleDive,
  PaddedToggleDive1,
  SectionWrapper,
  SectionWrapper1,
  SpanPaddedHeader,
  TopHeader,
  TopHeading,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Switch from '../../components/Form/Switch'
import './style.css'
import {
  setUserCorporatesList,
  setActiveCorporateData,
} from 'modules/Corporates/actions.js'
import {
  CustomCollapse,
  CustomPanel,
} from 'components/UIComponents/CollapseUI/Collapse'
import { useDispatch } from 'react-redux'
import AcademicIndex from 'modules/RankingAlgorithm/Partials/AcademicGrid/AcademicIndex'
import InternshipIndex from 'modules/RankingAlgorithm/Partials/InternshipGrid/InternshipIndex'
import SkillBucketIndex from 'modules/RankingAlgorithm/Partials/SkillBucket/skillBucketIndex.js'
import WorkExperienceIndex from 'modules/RankingAlgorithm/Partials/WorkExperience/workExperienceIndex.js'
import TrainingCourse from 'modules/RankingAlgorithm/Partials/TrainingCourse/trainingCourseIndex.js'
import ProjectIndex from 'modules/RankingAlgorithm/Partials/Project/ProjectIndex.js'
import CorporateMappingDrawer from './Partials/Drawer/CorporateMapping/CorporateMapping'
import AssessmentIndex from 'modules/RankingAlgorithm/Partials/Assessment/AssessmentIndex.js'
export default function RankingAlgorithmIndex({
  templateListData,
  getTemplateListStudent,
  updateStudentTemplateList,
  getAcademicStudentData,
  updateAcademictData,
  academicData,
  skillBucketData,
  getUserCorporatePortalsList,
  corporateList,
  getListOfState,
  getListOfCity,
  getActiveCorporatePortalsList,
  activeCorporateList,
  updateRankingCorporate,
}) {
  const dispatch = new useDispatch()
  const [templateData, setTemplateData] = useState([])
  const [loadCorporate, setLoadCorporate] = useState(false)
  const [mappingCorporate, setMappingCorporate] = useState({
    open: false,
  })
  const setLoading = false
  const [currentSkill, setCurrentSkill] = useState(false)
  const [current, setCurrent] = useState('left')
  const [activeKey, setActiveKey] = useState(null)
  const [fullEventID, setFullEventID] = useState('')
  const [currentAcademic, onClickAcademic] = useState(false)

  const onClick = target => {
    if (target === 'Student') {
      setCurrent('left')
    }
    if (target === 'Experience Candidate') {
      setCurrent('right')
    }
  }

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }
    const screens = Array.from(templateData)
    const [movedScreen] = screens.splice(result.source.index, 1)
    screens.splice(result.destination.index, 0, movedScreen)
    const payload = screens?.map((item, index) => ({
      id: item?.id,
      orderNo: index + 1,
      config: item.config,
      isActive: item?.isActive,
      createdAt: item.createdAt,
      menuName: item.menuName,
      menuType: item.menuName,
      updatedAt: item.updatedAt,
    }))
    setTemplateData(payload)
    updateStudentTemplateList({ setLoading, payload })
  }

  const handleCollapseChange = (event, param) => {
    if (activeKey === param?.menuName) {
      setActiveKey(null)
    } else {
      setFullEventID(param?.id)
      setActiveKey(param?.menuName)
      setCurrentSkill('left')
      onClickAcademic(false)
    }
  }

  useEffect(() => {
    let id = fullEventID
    if (activeKey) {
      getAcademicStudentData({ id })
    }
  }, [activeKey])
  const handleSwitchToggle = (checked, e, item) => {
    e.stopPropagation()
    let data = item
    const updatedActiveState = templateData.map(item => {
      if (item.menuName === data.menuName) {
        return { ...item, isActive: checked }
      } else {
        return item
      }
    })

    setTemplateData(updatedActiveState)
  }
  const onSubmit = () => {
    let mainbase = 'ACADEMIC'
    const payload = templateData?.map((item, index) => ({
      id: item?.id,
      orderNo: item?.orderNo,
      config: item.config,
      isActive: item?.isActive,
    }))
    updateStudentTemplateList({ mainbase, setLoading, payload })
  }

  useEffect(() => {
    getTemplateListStudent({})
  }, [])

  useEffect(() => {
    if (templateListData) {
      setTemplateData(templateListData)
    }
  }, [templateListData])
  const handleChange = () => {
    setActiveKey(null)
  }
  const handleSwitchCancel = () => {
    getTemplateListStudent({ setLoading })
  }
  useEffect(() => {}, [])
  useEffect(() => {
    if (mappingCorporate?.open) {
      dispatch(setUserCorporatesList([]))
      dispatch(setActiveCorporateData([]))
    }
  }, [mappingCorporate])
  return (
    <>
      <SectionWrapper1 column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>Candidate Rating Algorithm</TopHeading>
          <TopHeading flexEnd>
            <Button.Primary
              text={'Mapping to Corporate'}
              style={{ fontWeight: '600' }}
              onClick={() =>
                setMappingCorporate({ ...mappingCorporate, open: true })
              }
            />
          </TopHeading>
        </TopHeader>
        <PaddedToggleDive>
          <div style={{ pointerEvents: 'none' }}>
            <Toggle
              left="Student"
              right="Experience Candidate"
              initialValue={current}
              onClick={e => {
                onClick(e)
              }}
              disableRightAlone={true}
            />
          </div>
        </PaddedToggleDive>
      </SectionWrapper1>
      <br></br>
      <SectionWrapper column>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="event-list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {templateData?.map((item, index) => (
                  <Draggable
                    key={item?.menuName}
                    draggableId={item?.menuName}
                    index={index}
                    isDragDisabled={activeKey != null}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* Your existing code here */}

                        <PaddedToggleDive1>
                          <CustomCollapse
                            id={'main'}
                            activeKey={activeKey}
                            accordion
                            bordered={false}
                            expandIconPosition="end"
                            onChange={event =>
                              handleCollapseChange(event, item)
                            }
                          >
                            <CustomPanel
                              id="submain"
                              header={
                                <span
                                  style={{
                                    width: '97%',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <DraggerIcon />
                                  <SpanPaddedHeader>
                                    {item?.menuName?.toLowerCase()}
                                  </SpanPaddedHeader>
                                  <ActiveHeaderTop
                                    width={item.isActive ? '44.5%' : '45.5%'}
                                  ></ActiveHeaderTop>
                                  <ActiveHeaderTop width={'0%'}>
                                    {item.isActive ? 'Active' : 'Inactive'}
                                  </ActiveHeaderTop>
                                  <Switch
                                    gap={item.isActive ? '1.5rem' : '0.9rem'}
                                    checked={item?.isActive}
                                    onChange={(checked, e) =>
                                      handleSwitchToggle(checked, e, item)
                                    }
                                  />
                                </span>
                              }
                              key={item?.menuName}
                            >
                              {item.menuName === 'Academic' && (
                                <AcademicIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  currentAcademic={currentAcademic}
                                  onClickAcademic={onClickAcademic}
                                  handleChange={handleChange}
                                />
                              )}
                              {item.menuName === 'Internship' && (
                                <InternshipIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                />
                              )}
                              {item.menuName ===
                                'Work Experience (Post Undergrad)' && (
                                <WorkExperienceIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                />
                              )}
                              {item.menuName === 'Skill Bucket' && (
                                <SkillBucketIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  setCurrent={setCurrentSkill}
                                  current={currentSkill}
                                  handleChange={handleChange}
                                />
                              )}
                              {item.menuName === 'Training Course' && (
                                <TrainingCourse
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                />
                              )}
                              {item.menuName === 'Project' && (
                                <ProjectIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                />
                              )}
                              {item.menuName === 'Corporate Assessment' && (
                                <AssessmentIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                  name={'CORPORATE_ASSESSMENT'}
                                />
                              )}
                              {item.menuName ===
                                'Corporate Group Discussion' && (
                                <AssessmentIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                  name={'CORPORATE_GROUP_DISCUSSION'}
                                />
                              )}
                              {item.menuName === 'Corporate Interview' && (
                                <AssessmentIndex
                                  academicDataConfig={academicData?.config}
                                  updateAcademictData={updateAcademictData}
                                  handleChange={handleChange}
                                  name={'CORPORATE_INTERVIEW'}
                                />
                              )}
                            </CustomPanel>
                          </CustomCollapse>
                        </PaddedToggleDive1>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <FooterDivCommon>
          <FooterFlex right>
            <Button.Secondary text="Cancel" onClick={handleSwitchCancel} />
            <Button.Primary text={'Save and Update'} onClick={onSubmit} />
          </FooterFlex>
        </FooterDivCommon>
        <CorporateMappingDrawer
          updateRankingCorporate={updateRankingCorporate}
          setLoadCorporate={setLoadCorporate}
          loadCorporate={loadCorporate}
          activeCorporateList={activeCorporateList}
          getUserCorporatePortalsList={getUserCorporatePortalsList}
          getActiveCorporatePortalsList={getActiveCorporatePortalsList}
          corporateList={corporateList}
          getListOfState={getListOfState}
          getListOfCity={getListOfCity}
          mappingCorporate={mappingCorporate}
          setMappingCorporate={setMappingCorporate}
        />
      </SectionWrapper>
    </>
  )
}
