import { Button as AntdButton, Divider, Dropdown, Menu } from 'antd'
import Button from 'components/Button/index'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const Main = styled.div`
  .ant-dropdown-menu-item-disabled {
    opacity: 0.5;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: ${COLORS.BLUE_T_97};
    border: 1px solid ${COLORS.BLUE_T_80};
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: ${COLORS.BLUE_T_97};
    border: 1px solid ${COLORS.BLUE_T_80};
  }
`

export const StyledDropDownButton = styled(Dropdown.Button)`
  padding: 0;
  background: ${COLORS.BLUE_T_97};
  border: 1px solid ${COLORS.BLUE_T_80};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;

  button:first-child {
    padding: 0;
    border-right: 1px solid ${COLORS.BLUE_T_80} !important;
    border-radius: 0px !important;
  }
  button {
    border: none;
    background: none;
    :hover {
      border: none;
      background: none;
    }
  }
  display: flex;
`

export const StyledButton = styled(AntdButton)`
  min-width: 80px;
  background: ${COLORS.BLUE_T_97};
  color: ${COLORS.PRIMARY};
  border: 1px solid ${COLORS.BLUE_T_80};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  :hover {
    background: none;
  }
`

export const ButtonText = styled(AntdButton)`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${COLORS.PRIMARY};
  border: none !important;
  height: 100%;
  padding: 4px 15px !important;
  background-color: none !important;
  box-shadow: none;
`
export const ActionMenu = styled(Menu)`
  padding: 6px;
  width: ${props => (props.width ? props.width : 'auto')};
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const ActionMenuItem = styled(Menu.Item)`
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
`
export const ItemText = styled.p`
  margin-bottom: 0px;
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : 500)};
  font-size: 14px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_20)};
`
export const ItemDeleteText = styled.p`
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.RED_S2_100};
`
export const SmallButtons = styled(Button.Default)`
  background: ${COLORS.WHITE};
  padding: 9px;
  > div {
    margin-left: 0;
  }
`
export const StyledDivider = styled(Divider)`
  margin: 10px 0px;
`
export const SmallStyleDivider = styled(Divider)`
  margin: 5px 0px;
`
export const ViewButton = styled(Button.Secondary)`
  font-weight: 600;
`
