const generalSysConfigList = state =>
  state?.GeneralSystemConfig?.getGeneralListForSysConfig

const generalFunctionList = state =>
  state?.GeneralSystemConfig?.getFunctionSysConfig


const GeneralSelectors = {
  generalSysConfigList,
  generalFunctionList,
}
export default GeneralSelectors
