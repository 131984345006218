import Button from 'components/Button'
import Drawer from 'components/Drawer'
import {
  FooterFlex,
  Form,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import InstDocumentDetails from 'modules/Onboarding/Partials/Institutes/Partials/InstituteDocumentDrawer/Partials/InstDocumentDetails'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveInstituteFormData } from '../../actions'

const InstituteDocumentDrawer = ({
  form,
  instituteClear,
  title = 'Upload Document',
  sendButtonText = 'Preview',
  newInstituteDocumentDrawer,
  setNewInstituteDocumentDrawer,
  setInstitutePreviewDrawer,
  setInstitutePlanDrawer,
}) => {
  const InstituteFormDetail = useSelector(
    state => state?.InstituteFormData?.InstituteFormData
  )
  const dispatch = useDispatch()

  const onClear = () => {
    form.resetFields()
    setNewInstituteDocumentDrawer(false)
    instituteClear()
  }

  const onBack = () => {
    setInstitutePlanDrawer(true)
    setNewInstituteDocumentDrawer(false)
  }

  const onSubmitFormData = async values => {
    const previewData = { ...InstituteFormDetail, ...values }
    dispatch(saveInstituteFormData(previewData))
    setNewInstituteDocumentDrawer(false)
    setInstitutePreviewDrawer(true)
  }

  const content = () => {
    return (
      <div>
        <InstDocumentDetails form={form} />
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary text="Back" onClick={onBack} />
        <Button.Primary
          htmlType="submit"
          form={'documentInstForm'}
          text={sendButtonText}
        />
      </FooterFlex>
    )
  }
  return (
    <Form
      scrollToFirstError
      onFinish={onSubmitFormData}
      id={'documentInstForm'}
      form={form}
    >
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={onClear}
        visible={newInstituteDocumentDrawer}
        content={content()}
        footer={footer()}
      />
    </Form>
  )
}

export default InstituteDocumentDrawer
