import { Divider, Radio } from 'antd'
import Flex from 'components/Flex'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const FlexWrap = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`
export const BorderedDiv = styled.div`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_T_90};
  border-radius: 10px;
  padding: 15px;
`

export const RadioButton = styled(Radio)`
  display: flex;
  flex-direction: row;
  padding: 10px;
`

export const UserName = styled.h1`
  margin-bottom: 0;
  font-style: normal;
  padding-left: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.GREY_T_20};
`

export const GreyText = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  padding-left: 15px;
  font-size: 14px;
  line-height: 18px;
  width: ${props => (props?.width ? props?.width : 'auto')};
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
`

export const GreyTextEmail = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  padding-left: 15px;
  display:flex;
  gap:5px;
  font-size: 14px;
  line-height: 18px;
  width: ${props => (props?.width ? props?.width : 'auto')};
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
`

export const EditText = styled.p`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  width: 47px;
  height: 32px;
  /* Primary/Purple/T - 98 */
  background: #fbfaff;
  /* Primary/Purple/T - 80 */
  border: 1px solid #e3deff;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: #3249d7;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`

export const StatusText = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`

export const MediumText = styled.p`
  margin-bottom: ${props => (props?.marginbottom ? props?.marginbottom : 0)};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
`

export const MediumTextMobile = styled.p`
  margin-bottom: ${props => (props?.marginbottom ? props?.marginbottom : 0)};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display:flex;
  gap:5px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
  /* margin-left:150px */
`
export const StyledDivider = styled(Divider)`
  margin: ${props => (props?.margin ? props.margin : '15px 0')};
`
