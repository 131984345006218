import { Alert, Form as AntdForm, Radio as AntdRadio, Divider } from 'antd'
import Flex from 'components/Flex'
import Input from 'components/Form/Input'
import styled from 'styled-components'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  ${props => props.width && `width: ${props.width}`}
`
export const Form = styled(AntdForm)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
`
export const StyledDivider = styled(Divider)`
  margin: ${props => (props.margin ? props.margin : '20px 0')};
`
export const FormHeading = styled.h2`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: ${FONT.F_LG};
  line-height: 24px;
  color: ${COLORS.GREY_T_30};
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '0')};
`
export const SecondaryHeading = styled.h2`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_BASE};
  line-height: 24px;
  color: ${COLORS.GREY_T_30};
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '0')};
`
export const FlexWrap = styled(Flex)`
  width: ${props => (props.width ? props.width : '100%')};
  gap: ${props => (props.gap ? props.gap : '0')};
`
export const RadioGroup = styled(AntdRadio.Group)`
  display: flex;
  gap: 15px;
  margin-top: 8px;
  ${props => props?.spaceBetween && 'justify-content: space-between;'}
`
// -----------div for both select and input field----------
export const SelectInputDiv = styled(Flex)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '20px'} !important;
  margin-top: ${props =>
    props.margintop ? props.margintop : '0px'} !important;
  align-items: flex-end;
  position: sticky;
  align-items: stretch;
  width: ${props => (props.width ? props.width : '100%')};
  border-radius: 8px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-top-right-radius: ${props =>
      props?.borderright ? '8px' : 0} !important;
    border-bottom-right-radius: ${props =>
      props?.borderright ? '8px' : 0} !important;
    border-right: ${props =>
      props?.borderright
        ? `${props?.borderright} solid  #e9e9e9`
        : 0} !important;

    border-top-left-radius: ${props =>
      props?.borderleft ? '8px' : 0} !important;
    border-bottom-left-radius: ${props =>
      props?.borderleft ? '8px' : 0} !important;
  }
`
export const StyledInput = styled(Input)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`
export const StyledInputLeft = styled(Input)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`
// -----------div for both select and input field----------

export const RedText = styled.p`
  font-size: ${FONT.F_SM};
  line-height: 1.5715;
  color: ${COLORS.ERROR};
`
export const BorderedDiv = styled(Flex)`
  width: ${props => (props.width ? props.width : '100%')};
  background: ${COLORS.PRIMARY_GREY_T_98};
  opacity: 0.67;
  padding: 10px 20px;
  border: 1px solid ${COLORS.GREY_T_90};
  border-radius: 10px;
  height: ${props => (props.height ? props.height : '38px')};
`

export const SuffixText = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_BASE};
  line-height: 24px;
  color: ${COLORS.GREY_P_100};
`
export const AlertText = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 18px;
  color: ${COLORS.GREY_T_20};
  margin-left: 5px;
`
export const StyledAlert = styled(Alert)`
  border: none;
  border-radius: 10px;
  margin: ${props => (props?.margin ? props?.margin : 0)};
  ${props =>
    props?.backgroundColor && `background-color: ${props?.backgroundColor};`}
`
export const CapitalizeText = styled.span`
  text-transform: capitalize;
`
