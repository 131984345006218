import Button from 'components/Button'
import Control from 'components/Control/Control'
import AddInstituteDrawer from 'modules/Onboarding/Partials/Corporates/Partials/AddInstituteDrawer'
import { HorizontalDivider } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import React, { Fragment, useState } from 'react'
import instituteRequest from 'utils/instituteRequest'

const ConstituencyDetails = ({ setDefaultCheckBoxList, defaultCheckBoxList }) => {
  const [addInstituteDrawer, setAddInstituteDrawer] = useState(false)
  const [listOfCollege, setListOfCollege] = useState("");

  const getListOfAllColleges = () => {
    let search = searchCollege ? `&search=${searchCollege}` : ''
    try {
      instituteRequest
        .get(
          `/institutes/crud/college?isActive=true&pageLimit=40&pageNo=0${search}`
        )
        .then(response => {
          setListOfCollege(response.data.data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const onClick = () => {
    setAddInstituteDrawer(true)
    getListOfAllColleges()
  }

  return (
    <Fragment>
      <Control flexStart row width="100%" spaceBetween>
        <Control flexStart width="67%" gap="15px" column>
          <Control flexStart width="552px" spaceBetween>
            
              Constituency Institute
            

            <Button.Secondary
              text="Add Institute"
              onClick={onClick}
            />
          </Control>
        </Control>
      </Control>
      <HorizontalDivider />
      <AddInstituteDrawer
        listOfCollege={listOfCollege}
        addInstituteDrawer={addInstituteDrawer}
        setAddInstituteDrawer={setAddInstituteDrawer}
        defaultCheckBoxList={defaultCheckBoxList}
        setDefaultCheckBoxList={setDefaultCheckBoxList}
      />
    </Fragment>
  )
}

export default ConstituencyDetails
