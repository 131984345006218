import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import React from 'react'

export const LocationMenuData = [
  {
    id: 1,
    text: 'Country',
  },
  // {
  //   id: 2,
  //   text: 'Currency',
  // },
  {
    id: 2,
    text: 'State',
  },
  {
    id: 3,
    text: 'City',
  },
  {
    id: 4,
    text: 'Location',
  },
  // {
  //   id: 6,
  //   text: 'Pincode',
  // },
]
export const CountryMasterData = [
  {
    title: 'Country',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {rowData?.name} {rowData?.alias ? `(${rowData?.alias})` : ''}
      </RowNumberData>
    ),
  },
  {
    title: 'Capital',
    dataIndex: 'capital',
    key: 'capital',
    sorter: true,
    render: (_, rowData) => <RowNumberData>{rowData?.capital}</RowNumberData>,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    sorter: true,
    render: (_, rowData) => <RowNumberData>{rowData?.currency}</RowNumberData>,
  },
  {
    title: 'Phone Code',
    dataIndex: 'phone_code',
    key: 'phone_code',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.phone_code}</RowNumberData>
    ),
  },
]
export const CurrencyMasterData = [
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, rowData) => <RowNumberData>Ola Tech</RowNumberData>,
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
    render: (_, rowData) => <RowNumberData>Ola Tech</RowNumberData>,
  },
]

export const StateMasterData = [
  {
    title: 'State',
    dataIndex: 'name',
    key: 'state',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {rowData?.name} {rowData?.alias ? `(${rowData?.alias})` : ''}
      </RowNumberData>
    ),
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.country_id?.name}</RowNumberData>
    ),
  },
]

export const CityMasterData = [
  {
    title: 'City',
    dataIndex: 'name',
    key: 'city',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {rowData?.name} {rowData?.alias ? `(${rowData?.alias})` : ''}
      </RowNumberData>
    ),
  },
  {
    title: 'State & Country',
    dataIndex: 'country',
    key: 'country',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{`${rowData?.state_id?.name} & ${rowData?.country_id?.name}`}</RowNumberData>
    ),
  },
]

export const LocationMasterData = [
  {
    title: 'Location',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>
        {' '}
        {rowData?.name} {rowData?.alias ? `(${rowData?.alias})` : ''}
      </RowNumberData>
    ),
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.city_id?.name}</RowNumberData>
    ),
  },
  {
    title: 'State & Country',
    dataIndex: 'country',
    key: 'country',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{`${rowData?.state_id?.name} & ${rowData?.country_id?.name}`}</RowNumberData>
    ),
  },
  {
    title: 'Pincode',
    dataIndex: 'pin_code',
    key: 'pin_code',
    sorter: true,
    render: (_, rowData) => <RowNumberData> {rowData?.pin_code}</RowNumberData>,
  },
]

export const pinCodeMasterData = [
  {
    title: 'Pincode',
    dataIndex: 'pincode',
    key: 'pincode',
    render: (_, rowData) => <RowNumberData>Ola Tech</RowNumberData>,
  },
  {
    title: 'State & Country',
    dataIndex: 'country',
    key: 'country',
    render: (_, rowData) => <RowNumberData>Ola Tech</RowNumberData>,
  },
]

export const DummyData = [
  {
    degree: 'Bachelor of Technology',
    type: 'UG',
    shortForm: 'B.Tech',
    status: '3',
  },
  {
    degree: 'Master of Technology',
    type: 'PG',
    shortForm: 'M.Tech',
    status: '4',
  },
]

export const AddCountryContent = [
  {
    id: '1',
    name: 'name',
    label: 'Country',
    placeholder: 'Country',
    required: true,
    type: 'text',
  },
]

export const AddCurrencyContent = [
  {
    id: '1',
    name: 'currency',
    label: 'Currency',
    placeholder: 'Currency',
    required: true,
    type: 'text',
  },
  {
    id: '2',
    name: 'country',
    label: 'Country',
    placeholder: 'Eg.India',
    required: true,
    type: 'select',
    valueParam: '_id',
    nameParam: 'name',
    data: [],
  },
]

export const AddStateContent = [
  {
    id: '1',
    name: 'country_id',
    label: 'Country',
    placeholder: 'Eg.India',
    required: true,
    type: 'select',
    valueParam: '_id',
    nameParam: 'name',
    data: [],
  },
  {
    id: '2',
    name: 'name',
    label: 'State',
    placeholder: 'State',
    required: true,
    type: 'text',
  },
]

export const AddCityContent = [
  {
    id: '1',
    name: 'country',
    label: 'Country',
    placeholder: 'Eg.India',
    required: true,
    type: 'select',
    valueParam: '_id',
    nameParam: 'name',
    data: [],
  },
  {
    id: '2',
    name: 'state',
    label: 'State',
    placeholder: 'Eg.Karnataka',
    required: true,
    type: 'select',
    valueParam: '_id',
    nameParam: 'name',
    data: [],
  },

  {
    id: '3',
    name: 'city',
    label: 'City',
    placeholder: 'Eg.Chennai',
    required: true,
    type: 'text',
  },
]

export const AddLocationContent = [
  {
    id: '1',
    name: 'location',
    label: 'Location',
    placeholder: 'Location',
    required: true,
    type: 'text',
  },

  {
    id: '2',
    name: 'city',
    label: 'City',
    placeholder: 'Eg.Chennai',
    required: true,
    type: 'select',
    valueParam: 'label',
    nameParam: 'label',
    data: [],
  },
  {
    id: '3',
    name: 'state',
    label: 'State',
    placeholder: 'Eg.Karnataka',
    required: true,
    type: 'select',
    valueParam: 'label',
    nameParam: 'label',
    data: [],
  },
  {
    id: '4',
    name: 'country',
    label: 'Country',
    placeholder: 'Eg.India',
    required: true,
    type: 'select',
    valueParam: 'label',
    nameParam: 'label',
    data: [],
  },
]

export const AddPinCodeContent = [
  {
    id: '1',
    name: 'pincode',
    label: 'Pincode',
    placeholder: 'Pincode',
    required: true,
    type: 'text',
  },
  {
    id: '2',
    name: 'location',
    label: 'Location',
    placeholder: 'Location',
    required: true,
    type: 'text',
  },

  {
    id: '3',
    name: 'city',
    label: 'City',
    placeholder: 'Eg.Chennai',
    required: true,
    type: 'select',
    valueParam: 'label',
    nameParam: 'label',
    data: [],
  },
  {
    id: '4',
    name: 'state',
    label: 'State',
    placeholder: 'Eg.Karnataka',
    required: true,
    type: 'select',
    valueParam: 'label',
    nameParam: 'label',
    data: [],
  },
  {
    id: '5',
    name: 'country',
    label: 'Country',
    placeholder: 'Eg.India',
    required: true,
    type: 'select',
    valueParam: 'label',
    nameParam: 'label',
    data: [],
  },
]

//table Filters
export const Status = [
  {
    value: 'Active',
    label: 'Active',
    checked: false,
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    checked: false,
  },
]
