import Input from 'components/Form/Input'
import Label from 'components/Form/Label'
import Select from 'components/Form/Select'
import { SelectInputDiv } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import React, { Fragment, useState } from 'react'
import CONSTANTS from 'utils/constants'
import { FlexWrap, FormItem, StyledInput } from './styles'


const AddUserForm = ({
  roleList,
  singleUserData,
  setCountryCodeSearch,
  countryCodeList,
  form,
}) => {
  const [countryCode, setCountryCode] = useState('91')
  const [filteredCountryCodeList, setFilteredCountryCodeList] =
    useState(countryCodeList)

  const onCountryCodeChange = data => {
    setCountryCode(data)
  }

  const handleCountryCodeSearch = value => {
    setCountryCodeSearch(value)

    const filteredCodes = countryCodeList?.filter(
      code =>
        code?.phone_code?.includes(value) ||
        code?.name?.toLowerCase()?.includes(value?.toLowerCase())
    )

    setFilteredCountryCodeList(filteredCodes)
  }

  return (
    <Fragment>
      <FlexWrap spaceBetween gap="25px">
        <FlexWrap width="49%">
          <Input
            type="text"
            width="100%"
            name={'firstName'}
            label="First Name"
            optional={'*'}
            placeholder="Eg: Vikram"
            maxLength={120}
            rules={[
              {
                required: true,
                message: 'Please Enter First Name',
              },
            ]}
            onChange={event => {
              form.setFieldsValue({
                firstName: event.target.value.replace(/[^A-Za-z ]|^ /g, ''),
              })
            }}
          />
        </FlexWrap>
        <FlexWrap width="49%">
          <Input
            type="text"
            width="100%"
            name={'lastName'}
            label="Last Name"
            optional={'*'}
            placeholder="Eg: Sharma"
            maxLength={120}
            rules={[
              {
                required: true,
                message: 'Please Enter Last Name',
              },
            ]}
            onChange={event => {
              form.setFieldsValue({
                lastName: event.target.value.replace(/[^A-Za-z ]|^ /g, ''),
              })
            }}
          />
        </FlexWrap>
      </FlexWrap>
      <Input
        width="100%"
        disabled={singleUserData?.status == 1}
        name={!singleUserData?.id ? 'email' : 'userEmail'}
        label="Email ID"
        optional={'*'}
        placeholder="Eg. vikram.sharma@plugin.com"
        rules={[
          {
            required: true,
            message: 'Please Enter Email',
          },
          {
            type: 'email',
            message: 'Please Enter Valid Email',
          },
        ]}
        onChange={event => {
          form.setFieldsValue({
            email: event.target.value.trim(),
          })
        }}
      />

      <FormItem
        name={!singleUserData?.id ? 'adminRoleId' : 'userType'}
        rules={[
          {
            required: true,
            message: 'Please select Any One Role',
          },
        ]}
      >
        <Select
          OptionData={roleList}
          label="Role *"
          valueParam="id"
          nameParam="name"
          placeholder="Select Any Role"
          showSearch={true}
          disabled={singleUserData?.status == 1}
        />
      </FormItem>
      <div>
        <Label>Phone No *</Label>

        <SelectInputDiv borderleft={true} margintop="8px">
          <FormItem
            name={'countryCode'}
            rules={[
              {
                required: true,
                message: 'Please select Country Code',
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              OptionData={filteredCountryCodeList}
              nameParam="phone_code"
              valueParam="phone_code"
              bordered={true}
              optionFilterProp="children"
              filterOption={false}
              onSearch={handleCountryCodeSearch}
              onSelect={() => setCountryCodeSearch('')}
              onChange={onCountryCodeChange}
              value={countryCode}
              placeholder="+91"
            />
          </FormItem>
          <StyledInput
            width="100%"
            type="number"
            disabled={singleUserData?.status == 1}
            name={!singleUserData?.id ? 'phoneNumber' : 'userMobile'}
            placeholder="Eg: 9999999999"
            value={''}
            rules={[
              {
                required: true,
                message: 'Please enter phone number!',
              },
              {
                pattern: CONSTANTS.REGEX.MOBILE_NUMBER,
                message: 'Please Enter Valid Phone Number',
              },
            ]}
            onChange={event => {
              form.setFieldsValue({
                phoneNumber: event.target.value.trim(),
              })
            }}
          />
        </SelectInputDiv>
      </div>
    </Fragment>
  )
}

export default AddUserForm
