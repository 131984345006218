import AntdSteps from 'components/AntdSteps'
import Control from 'components/Control/Control'
import { menuData } from 'modules/Onboarding/Partials/Corporates/Register/Menu'
import {
  Container,
  Content,
  Heading,
  SubContainer,
  SubHeading,
  Wrapper,
} from 'modules/Onboarding/Partials/Corporates/Register/style'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorMessage from 'utils/ErrorMessage'

const { AUTH_URL } = process.env

/* eslint-disable */

import { Form } from 'antd'
import Avatar from 'components/Avatar'
import Flex from 'components/Flex'
import PluginLiveLogo from 'components/icons/PluginLiveLogo'
import CorporateInfo from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo'
import styled from 'styled-components'
import {
  editCorporate,
  getSingleCorporateData,
  getaddInstituteDrawer,
  setCorporateFormInfo,
  setSingleCorporateData,
} from './actions'
import {
  initialCorporateFormInfo,
  initialSingleCorporateInfo,
} from './reducers'

const BREAKPOINT = '999px'

const StyledFlex = styled(Flex)`
  position: static;
  padding: 5px 48px 5px 20px;
  height: 82px;
  margin: 0px -20px;
  background: #ffffff;
  z-index: 20 !important;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: ${BREAKPOINT}) {
    padding: 15px 20px 15px 0px !important;
    background: #ffffff;
    margin: 0px !important;
  }
`

const IconWrapper = styled(Flex)`
  background-color: white;
  min-width: 200px;
  max-width: 200px;
  justify-content: center;
`

const Divider = styled.div`
  border-left: 1px solid #e6e7ea;
  margin-right: 25px;
  height: 55px;
`
const Header = () => {
  return (
    <StyledFlex spaceBetween centerVertically isDisplay={true}>
      <Flex centerVertically>
        <IconWrapper>
          <PluginLiveLogo />
        </IconWrapper>
        <Divider />
        <div></div>
      </Flex>

      <Flex centerVertically>
        <Avatar background size={40} IconName="A" font={18} />
      </Flex>
    </StyledFlex>
  )
}

const Corporate = ({
  countryList = [],
  corporateFormInfo,
  addInstitute,
  singleCorporateData,
  ...props
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [addCorporateForm] = Form.useForm()
  const [contactDetailsForm] = Form.useForm()
  const [taxInfoForm] = Form.useForm()
  const [pricingAndContract] = Form.useForm()
  const { corporateId } = useParams()
  const [description, setDescription] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const [subStep, setSubStep] = useState(0)

  //Add Corporate

  const [profileFile, setProfileFile] = useState(null)

  const [corporatePresentation, setCorporatePresentation] = useState('')

  //Add Tax

  const [authorisedSignatory, setAuthorizedSignatory] = useState(null)

  //Add Contract Details

  const [contractDetails, setContractDetails] = useState(null)

  const onChangeAuthorizedSignatory = file => {
    setAuthorizedSignatory(file)
    taxInfoForm.setFieldsValue({ authorisedSignatory: file })
  }

  const onSubmitAddCorporate = values => {
    const data = {
      companyInfo: {
        ...values,
        logo: values?.profileUrl,
        profileFile,
        corporatePresentation,
      },
      taxInfo: {
        ...corporateFormInfo.taxInfo,
      },
      additionalInfo: {
        corporatePresentationLink: values?.corporatePresentationLink ?? null,
        corporateVideoLink: values?.corporateVideoLink ?? '',
      },
    }
    let payload = { ...data }
    const callBack = () => {}
    dispatch(
      editCorporate(
        corporateId,
        payload,
        callBack,
        'Corporate Details Saved Successfully'
      )
    )

    dispatch(setCorporateFormInfo({ addCorporate: data }))
    setCurrentStep(1)
    setSubStep(3)
  }
  const onSubmitTaxInfo = values => {
    const data = {
      ...corporateFormInfo.addCorporate,
      ...corporateFormInfo.contactInfo,

      taxInfo: {
        authorisedSignatory: authorisedSignatory,
        gst: values.gst,
        pan: values.pan,
      },

      // ...values,
      // authorisedSignatory,
    }
    let payload = { ...data }

    dispatch(
      editCorporate(
        corporateId,
        payload,
        'Corporate Details Saved Successfully'
      )
    )

    dispatch(setCorporateFormInfo({ taxInfo: data }))
    setCurrentStep(2)
  }

  const onSubmitPricingAndContract = values => {
    const data = {
      ...values,
      contractDetails,
    }
    dispatch(setCorporateFormInfo({ pricingAndContract: data }))
    let Payload = {
      // companyInfo: {
      //   ...corporateFormInfo.addCorporate,
      //   ...corporateFormInfo.contactInfo,
      //   ...corporateFormInfo.pricingAndContract,
      //   logo: corporateFormInfo.addCorporate.profileUrl
      //     ? corporateFormInfo.addCorporate.profileUrl
      //     : null,
      // },
      // additionalInfo: {
      //   corporatePresentationLink:
      //     corporateFormInfo.addCorporate.corporatePresentationLink ?? null,
      //   corporateVideoLink:
      //     corporateFormInfo.addCorporate.corporateVideoLink ?? '',
      // },
      // taxInfo: {
      ...corporateFormInfo.taxInfo,
      logo: corporateFormInfo.addCorporate.profileUrl
        ? corporateFormInfo.addCorporate.profileUrl
        : null,
      // },
    }

    if (contractDetails) {
      Payload.companyInfo.regDocs = contractDetails
    }

    delete Payload.companyInfo.corporatePresentation
    delete Payload.companyInfo.city
    delete Payload.companyInfo.state
    delete Payload.companyInfo.country
    delete Payload.companyInfo.profileFile
    delete Payload.companyInfo.contractDetails
    delete Payload.companyInfo.profileUrl
    delete Payload.companyInfo.profileFile
    Payload.companyInfo.establishedYear = Payload.companyInfo.establishedYear
      ? Number(Payload.companyInfo.establishedYear)
      : null

    const callBack = () => {
      navigate('/onboarding/registeredSuccessfully')
      dispatch(setCorporateFormInfo(initialCorporateFormInfo))
      dispatch(setSingleCorporateData(initialSingleCorporateInfo))
    }
    dispatch(
      editCorporate(
        corporateId,
        Payload,
        callBack,
        'Corporate created successfully.',
        true
      )
    )
  }

  const Configs = [
    {
      form: taxInfoForm,
      submitHandler: onSubmitTaxInfo,
      props: {
        setAuthorizedSignatory,
        onChangeAuthorizedSignatory,
        authorisedSignatory,
      },
    },
    // {
    //   form: addPartners,
    //   submitHandler: onSubmitAddPartner,
    //   props: {},
    // }, for fututre use case
    {
      form: pricingAndContract,
      submitHandler: onSubmitPricingAndContract,
      props: { setContractDetails, contractDetails },
    },
  ]
  useEffect(() => {
    setContractDetails(singleCorporateData?.regDocs)
    addCorporateForm.setFieldsValue({
      name: singleCorporateData?.name,
      brandName: singleCorporateData?.brandName,
      website: singleCorporateData?.website,
      country: singleCorporateData?.country,
      state: singleCorporateData?.state,
      city: singleCorporateData?.city,
      postalCode: singleCorporateData?.postalCode,
      sector: singleCorporateData?.sector ?? '',
      industry: singleCorporateData?.industry ?? '',
      profileUrl: singleCorporateData?.logo ?? '',
      employeesNumber: singleCorporateData?.employeesNumber ?? null,
      addressLine1: singleCorporateData?.addressLine1 ?? '',
      addressLine2: singleCorporateData?.addressLine2 ?? '',
      establishedYear: singleCorporateData?.establishedYear ?? '',
      shortDescription: singleCorporateData?.shortDescription ?? '',
      longDescription: singleCorporateData?.longDescription ?? '',
      corporatePresentationLink:
        singleCorporateData?.corporatePresentationLink ?? '',
      corporateVideoLink: singleCorporateData?.corporateVideoLink ?? '',
      sectorIndustryMapId: singleCorporateData?.sectorIndustryMapId ?? '',
    })
    if (singleCorporateData?.longDescription) {
      setDescription(singleCorporateData?.longDescription)
    }
    if (singleCorporateData?.logo) {
      setProfileFile(singleCorporateData?.logo ?? '')
    }
    if (singleCorporateData?.corporatePresentationLink) {
      setCorporatePresentation(
        corporateFormInfo.addCorporate?.corporatePresentation ?? null
      )
      setCorporatePresentation({
        url: singleCorporateData?.corporatePresentationLink ?? '',
      })
    }

    contactDetailsForm.setFieldsValue({
      contactPerson: singleCorporateData?.contactPerson ?? '',
      contactEmail: singleCorporateData?.contactEmail ?? '',
    })

    taxInfoForm.setFieldsValue({
      pan: singleCorporateData?.pan ?? '',
      gst: singleCorporateData?.gst ?? '',
      authorisedSignatory: singleCorporateData?.authorisedSignatory ?? null,
    })
    setAuthorizedSignatory(singleCorporateData?.authorisedSignatory ?? null)
    pricingAndContract.setFieldValue('regDocs', singleCorporateData?.regDocs)
  }, [singleCorporateData])

  const callBackError = msg => {
    ErrorMessage(msg || 'No Corporate Found')
    navigate('/onboarding')
  }
  useEffect(() => {
    dispatch(getSingleCorporateData(corporateId, callBackError))
  }, [currentStep, subStep])
  return (
    <>
      {' '}
      <Container>
        <Control flexStart width="33%" gap="15px" column>
          <SubContainer column>
            <AntdSteps
              steps={menuData}
              current={currentStep}
              substep={subStep}
            />
          </SubContainer>
        </Control>
        {menuData?.map((item, index) => {
          return (
            item?.activeKey === currentStep && (
              <Wrapper column customWidth={'67%'} mBottom={'0px'}>
                <Heading>{item?.heading}</Heading>
                <SubHeading>{item?.subHeading}</SubHeading>
                {item?.title === 'Add Corporate' ? (
                  <Content mBottom={'7%'}>
                    {subStep === 0 ? (
                      <CorporateInfo
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setSubStep={setSubStep}
                        singleCorporateData={singleCorporateData}
                        form={addCorporateForm}
                        onSubmit={onSubmitAddCorporate}
                        setProfileFile={setProfileFile}
                        profileFile={profileFile}
                        corporatePresentation={corporatePresentation}
                        setCorporatePresentation={setCorporatePresentation}
                        descriptiona={description}
                      />
                    ) : (
                      ''
                    )}
                  </Content>
                ) : (
                  <Content>
                    <item.component
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      setSubStep={setSubStep}
                      addInstitute={addInstitute}
                      countryList={countryList}
                      getaddInstituteDrawer={getaddInstituteDrawer}
                      singleCorporateData={singleCorporateData}
                      form={Configs[index - 1]['form']}
                      onSubmit={Configs[index - 1]['submitHandler']}
                      {...Configs[index - 1]['props']}
                    />
                  </Content>
                )}
              </Wrapper>
            )
          )
        })}
      </Container>
    </>
  )
}

export default Corporate
