import { connect } from 'react-redux'

import LayoutWithoutNav from 'modules/Layouts/Components/LayoutWithoutNav'
import selectors from '../../Auth/selectors'

const mapStateToProps = state => ({
  userData: selectors?.getUserData(state),
  roleList: selectors?.getRoleList(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWithoutNav)
