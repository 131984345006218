import { RowNumberDataReportAlignCenter } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import React from 'react'
export const DateFormat = data => {
  let refinedData = data / 1000
  let date = new Date(parseInt(refinedData))
  return date.toLocaleDateString()
}
export const EmpanelledDateFormat = data => {
  let refinedData = data
  let date = new Date(parseInt(refinedData))
  return date.toLocaleDateString()
}
export const RefinedData = data => {
  if (Array.isArray(data)) {
    let name = data.map(item => item.name)
    return name.length ? name.join(', ') : '-'
  } else if (typeof data === 'string' || typeof data === 'number') {
    return data.toString() || '-'
  } else {
    return <RowNumberDataReportAlignCenter>-</RowNumberDataReportAlignCenter>
  }
}
export const createTableData = (data, getCellValues) => {
  return data?.result?.map(item => {
    const cellValues = getCellValues(item)
    return cellValues.map(value => ({
      value,
      style: { fontFamily: 'Times New Roman', fontSize: '12' },
    }))
  })
}

export const filterData = (data, name) =>
  data?.map(item => ({
    label: item?.[name],
    value: item?.[name],
    checked: false,
  }))

export const arrayIntoString = data => {
  let dataString = data ? data?.join(',') : data
  return dataString
}

export const renderItems = items => {
  const names = items.map(item => item.name)
  return names.join(', ')
}

export const arrayIntoNumber = data => {
  let dataString = data ? Number(data)?.join(',') : Number(data)
  return dataString
}

export const IndianCurrencyConvertor = data => {
  const rupees = Number(data).toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
  })
  return rupees
}

export const arrayStatus = data => {
  let status = data?.map(item => {
    if (item === 'Active') {
      return 1
    } else {
      return 0
    }
  })
  return status[1]
}

export const serialNo = (index, previousPage, currentPage, loadingRole) => {
  if (loadingRole)
    return previousPage > 0
      ? index + 1 + previousPage * 10 - 10
      : index + 1 + 1 * 10 - 10
  else return index + 1 + currentPage * 10 - 10
}
