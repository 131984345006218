import React from 'react'

const DownloadIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.99967 13.6666C1.54134 13.6666 1.14912 13.5035 0.823008 13.1774C0.496341 12.8508 0.333008 12.4583 0.333008 11.9999V9.49992H1.99967V11.9999H11.9997V9.49992H13.6663V11.9999C13.6663 12.4583 13.5033 12.8508 13.1772 13.1774C12.8505 13.5035 12.458 13.6666 11.9997 13.6666H1.99967ZM6.99967 10.3333L2.83301 6.16658L3.99967 4.95825L6.16634 7.12492V0.333252H7.83301V7.12492L9.99967 4.95825L11.1663 6.16658L6.99967 10.3333Z" fill="#999FAC"/>
</svg>
    
  )
}

export default DownloadIcon
