/* eslint-disable react/prop-types */
import React from 'react'
import Flex from 'components/Flex'
import {
  AdjustIcon,
  DownloadIconDiv,
  SmallCirlcle,
  SpanText,
  StyledCardDiv,
  StyledH2,
  StyledImg,
  StyledP,
  VisibleDiv,
} from 'components/Uploader/Styles'
import DownloadIcon from 'components/icons/DownloadIcon'
import JPGIcon from 'components/icons/JPGIcon'
import PDFIcon from 'components/icons/PDFIcon'
import Visibility from 'components/icons/Visibility'
import * as FileSaver from 'file-saver'
import moment from 'moment'

const FileReaderVisible = ({ file, setFile }) => {
  let size = (file.size / 1024 / 1024).toFixed(2) + ' MB'

  const viewFile = () => {
    const newWindow = window.open(file?.url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const downloadPDF = () => {
    const fileUrl = file?.url
    const xhr = new XMLHttpRequest()
    xhr.open('GET', fileUrl, true)
    xhr.responseType = 'blob'

    xhr.onload = function (e) {
      if (e.target.status === 200) {
        const blob = new Blob([e.target.response], { type: file?.type })
        FileSaver.saveAs(blob, file?.name)
      }
    }

    xhr.send()
  }

  return (
    <StyledCardDiv spaceBetween center alignCenter>
      <StyledImg>
        <AdjustIcon margin>
          {file?.type === 'pdf' ? <PDFIcon /> : <JPGIcon />}
        </AdjustIcon>
        <Flex column>
          <StyledH2>{file?.name}</StyledH2>
          <StyledP alignCenter>
            <SpanText>
              {moment(file?.date)?.format('Do MMM, YYYY') +
                ' at ' +
                moment(file?.date)?.format('h:mm')}
            </SpanText>
            <SmallCirlcle />
            <SpanText>{size}</SpanText>
          </StyledP>
        </Flex>
      </StyledImg>

      <Flex alignCenter>
        <DownloadIconDiv onClick={() => downloadPDF()}>
          <DownloadIcon />
        </DownloadIconDiv>
        <VisibleDiv onClick={() => viewFile()}>
          <Visibility />
        </VisibleDiv>
      </Flex>
    </StyledCardDiv>
  )
}

export default FileReaderVisible
