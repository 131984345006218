import {
  SET_INSTITUTE_LIST_FOR_REPORT,
  SET_CITY_LIST,
  SET_TIER_LIST,
  SET_UNIVERSITY_LIST,
  SET_TOP_INSTITUTE_LIST,
  SET_EXCEL_DATA,
} from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/actions'

const initialState = {
  getInstituteListForReport: {},
  getUniversityListForReport: {},
  getTierListForReport: {},
  getCityListForReport: {},
  getTopInstituteList: {},
  excelData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTITUTE_LIST_FOR_REPORT:
      return {
        ...state,
        getInstituteListForReport: action.data,
      }
    case SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.data,
      }
    case SET_UNIVERSITY_LIST:
      return {
        ...state,
        getUniversityListForReport: action.data,
      }
    case SET_TIER_LIST:
      return {
        ...state,
        getTierListForReport: action.data,
      }
    case SET_CITY_LIST:
      return {
        ...state,
        getCityListForReport: action.data,
      }

    case SET_TOP_INSTITUTE_LIST:
      return {
        ...state,
        getTopInstituteList: action.data,
      }
    default:
      return state
  }
}
