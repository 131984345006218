export const PermittedNavItems = (permissions, navItems) =>
  permissions?.length
    ? navItems
    : navItems?.filter(oldData => {
        return permissions?.find(newData => {
          if (newData?.screens != null) {
            return (
              newData?.screens?.screenName?.toLocaleUpperCase() ===
                oldData?.navTitle?.toUpperCase() ||
              newData?.screens?.screenName?.toLocaleUpperCase() ===
                oldData?.pageTitle?.toUpperCase() ||
              oldData?.navTitle === 'Global' ||
              !oldData?.navTitle
            )
          }
        })
      })

const Eventcruds = [
  'CORPORATE',
  'INSTITUTE',
  'VIEW',
  'STUDENT',
  'Reschedule Drive',
  'ADD USER',
  'ADD ROLE',
  'EDIT',
  'UPDATE',
  'ADD COURSES',
  'EDIT',
  'DELETE',
]

export const permittedEventCruds = (permissions, screenName) => {
  const foundPermissions = permissions?.filter(permission => {
    return Eventcruds?.find(cruds => {
      if (
        permission?.screens?.screenName?.toLocaleUpperCase() ===
        screenName?.toUpperCase()
      ) {
        return (
          permission?.events?.eventName?.toLocaleUpperCase() ===
          cruds.toUpperCase()
        )
      }
    })
  })
  return foundPermissions
}

/// future UseCse
export const permittedsCruds = (permissions, screenName) =>
  !permissions?.length
    ? Eventcruds
    : Eventcruds?.filter(cruds => {
        return permissions.find(permission => {
          if (
            permission?.subEvents != null &&
            permission?.subEvents?.events?.screens?.screenName?.toLocaleUpperCase() ===
              screenName?.toUpperCase()
          ) {
            return (
              permission?.subEvents?.events?.eventName?.toLocaleUpperCase() ===
              cruds.toUpperCase()
            )
          } else if (
            permission?.events?.screens?.screenName.toLocaleUpperCase() ===
            screenName?.toUpperCase()
          ) {
            return (
              permission?.events?.eventName?.toLocaleUpperCase() ===
              cruds?.toUpperCase()
            )
          }
        })
      })
