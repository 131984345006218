const getSingleInstituteData = state =>
  state?.InstituteOnboarding?.singleInstituteData

const getInstituteFormInfo = state =>
  state?.InstituteOnboarding?.instituteFormInfo

const selectors = {
    getSingleInstituteData,
    getInstituteFormInfo,
}

export default selectors
