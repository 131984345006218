import { connect } from 'react-redux'
import MyLocationSettings from 'modules/Systemconfig/Partials/MyLocationSettings/index'
import selectors from 'modules/Onboarding/selectors'
import {
  getListOfCounties,
  getListOfState,
  getListOfCity,
  getIndustryList,
} from 'modules/Onboarding/actions'
import {
  getInstituteListForSysConfig,
  createInstituteSysConfig,
} from 'modules/Systemconfig/Partials/MyLocationSettings/actions'
import Instituteselectors from 'modules/Systemconfig/Partials/MyLocationSettings/selectors'
const mapStateToProps = state => ({
  countryList: selectors.getCountryList(state),
  instituteTableList: Instituteselectors.instituteSysConfigList(state),
})

const mapDispatchToProps = {
  getListOfCounties,
  getListOfState,
  getListOfCity,
  getIndustryList,
  getInstituteListForSysConfig,
  createInstituteSysConfig,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyLocationSettings)
