import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import adminRequest from '../../../../utils/adminRequest'
export const SET_INSTITUTE_LIST = 'institute/SET_INSTITUTE_LIST'
export const SET_DEPARTMENT_LIST = 'institute/SET_DEPARTMENT_LIST'
export const SET_SPECIALISATION_LIST = 'institute/SET_SPECIALISATION_LIST'
export const SET_SCREEN_DATA = 'institute/SET_SCREEN_DATA'
export const SET_EVENT_DATA = 'institute/SET_EVENT_DATA'
export const setInstituteList = data => {
  return {
    type: SET_INSTITUTE_LIST,
    data,
  }
}
export const setDepartmentList = data => {
  return {
    type: SET_DEPARTMENT_LIST,
    data,
  }
}
export const setSpecialisationList = data => {
  return {
    type: SET_SPECIALISATION_LIST,
    data,
  }
}

export const setscreenData = data => {
  return {
    type: SET_SCREEN_DATA,
    data,
  }
}

export const setEventData = data => {
  return {
    type: SET_EVENT_DATA,
    data,
  }
}

export const getInstituteListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = filterDatas?.type || ''
      let state = encodeURIComponent(filterDatas?.state || '')
      let journey = encodeURIComponent(filterDatas?.journey || '')
      let location = encodeURIComponent(filterDatas?.location || '')

      let status = filterDatas?.status?.toString()

      const Journey = journey ? `&journey=${journey}` : ``
      const DegreeType = degreeType
        ? `&degreeType=${
            degreeType === 'UG Diploma'
              ? 'DIPLOMA'
              : degreeType === 'PG Diploma'
              ? 'P_G_DIPLOMA'
              : degreeType
          }`
        : ``
      const Search = searchValue
        ? `&searchBy=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${Journey}`,
      }
      const response = await adminRequest(options)
      dispatch(setInstituteList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setInstituteList([]))
        setLoading(false)
      }
    }
  }
export const getDepartmentListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      const Active =
        status?.toString() && filterDatas?.menu == 'Events'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await adminRequest(options)
      dispatch(setDepartmentList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setDepartmentList([]))
        setLoading(false)
      }
    }
  }
export const getSpecialisationListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      const Active =
        status?.toString() && filterDatas?.menu == 'Events'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await adminRequest(options)
      dispatch(setSpecialisationList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setSpecialisationList([]))
        setLoading(false)
      }
    }
  }
export const createInstituteSysConfig =
  ({ url, type = 'post', value, id, isSuccess, success }) =>
  async () => {
    try {
      const Id = id ? `/${id}` : ''
      const response = await adminRequest({
        method: type,
        url: `/${url}${Id}`,
        data: value,
      })
      SuccessMessage(response?.data?.message)
      isSuccess(!success)
    } catch (error) {
      if (
        type === 'delete' &&
        url === 'events' &&
        error?.response?.status === 404
      ) {
        const errorMessage =
          "You can't delete this Event. It's linked with another role."
        ErrorMessage(errorMessage)
      } else if (
        type === 'delete' &&
        url === 'screens' &&
        error?.response?.status === 404
      ) {
        const errorMessage =
          "You can't delete this Screen. It's linked with another role."
        ErrorMessage(errorMessage)
      } else if (
        type === 'delete' &&
        url === 'subEvents' &&
        error?.response?.status === 404
      ) {
        const errorMessage =
          "You can't delete this Sub-Event. It's linked with another role."
        ErrorMessage(errorMessage)
      } else if (error?.response?.status === 400) {
        const errorMessage =
          'Screen Names must be unique, Event Names must be unique, Sub Event Names must be unique'
        ErrorMessage(errorMessage)
      } else {
        ErrorMessage(error?.response?.data?.message)
      }
    }
  }

export const getScreenData = journey => async (dispatch, getState) => {
  try {
    const Journey = journey ? `journey=${journey}` : ``
    let response = await adminRequest.get(`/screens?${Journey}`)
    dispatch(setscreenData(response?.data?.data || {}))

    return response?.data?.data
  } catch (error) {
    return error
  }
}

export const getEventData = journey => async (dispatch, getState) => {
  try {
    const Journey = journey ? `?screenId=${journey}` : ``
    let response = await adminRequest.get(`/events${Journey}`)
    dispatch(setEventData(response?.data?.data || {}))

    return response?.data?.data
  } catch (error) {
    return error
  }
}
