import { Form } from 'antd'
import Button from 'components/Button/index'
import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import AddIcon from 'components/icons/AddIcon'
import React, { Fragment, useEffect } from 'react'
import { journeyDropdown } from '../../../../../../../utils/constants'
import { FormItem } from '../../../../../../User/Partials/AddUserDrawer/Partials/AddUserForm/styles'
const DrawerForm = ({
  form,
  handleJourneyChange,
  toEditId,
  view,
  inputs,
  setInputs,
}) => {
  const handleAddInput = () => {
    setInputs([...inputs, { value: '', name: '' }])
  }

  const handleCloseField = () => {
    const updatedFields = inputs.slice(0, -1)
    setInputs(updatedFields)
    let data = { ...form.getFieldsValue(true) }
    const keyToDelete = `screens'.${inputs.length - 1}`
    delete data[keyToDelete]

    form.setFieldsValue(data)
    form.resetFields([keyToDelete])
  }

  const handleInputChange = (index, event) => {
    const { value } = event?.target || event.currentTarget
    const updatedInputs = [...inputs]
    updatedInputs[index].value = value
    setInputs(updatedInputs)
  }

  useEffect(() => {
    const names = ['Name 1']
    const updatedInputs = names.map((name, index) => ({
      value: name,
      name: index,
    }))

    setInputs(updatedInputs)
  }, [])

  return (
    <Fragment>
      <div>
        <FormItem
          name={'journey'}
          rules={[
            {
              required: true,
              message: 'Please select Any One Journey',
            },
          ]}
        >
          <AntdSelect
            name={'journey'}
            OptionData={journeyDropdown}
            label="Journey *"
            onChange={value => {
              handleJourneyChange(value)
              form.setFieldsValue({ journey: value })
              form.setFieldsValue({ screenName: '' })
            }}
            nameParam="value"
            placeholder="Select"
            valueParam="id"
          />
        </FormItem>
      </div>
      <div>
        {toEditId
          ? inputs.map((input, index) => (
              <FormItem key={index} name={'screenName'}>
                <AntdInput
                  type="text"
                  width="100%"
                  name={'screenName'}
                  optional={'*'}
                  placeholder={`Enter Screen Name`}
                  label={`Screens`}
                  onChange={event => {
                    handleInputChange(index, event)
                    form.setFieldsValue({ screenName: event.target.value })
                  }}
                  rules={[
                    {
                      required: true,
                      message: `Please Enter Screen Name`,
                    },
                    {
                      pattern: /^(?!\s).*$/,
                      message: 'Screen Name cannot start with a space',
                    },
                  ]}
                />
              </FormItem>
            ))
          : inputs.map((input, index) => (
              <FormItem key={index} name={`screens'.${index}`}>
                <AntdInput
                  type="text"
                  width="100%"
                  value={input.value}
                  name={`screens.${index}`}
                  optional={'*'}
                  placeholder={`Enter Screen Name`}
                  label={`Screens`}
                  onChange={event => handleInputChange(index, event)}
                  rules={[
                    {
                      required: true,
                      message: `Please Enter Screen Name`,
                    },
                    {
                      pattern: /^(?!\s).*$/,
                      message: 'Screen Name cannot start with a space',
                    },
                  ]}
                />
              </FormItem>
            ))}
      </div>
      <div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
        {inputs.length > 1 && !toEditId && (
          <Button.Secondary
            text="Delete"
            height="38px"
            onClick={handleCloseField}
          />
        )}
        {!toEditId && (
          <Button.Secondary
            text={'Add'}
            onClick={handleAddInput}
            disabled={view}
            icon={<AddIcon />}
            height="38px"
          />
        )}
      </div>
    </Fragment>
  )
}

export default DrawerForm
