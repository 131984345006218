import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { ItemDeleteText } from 'components/ActionStyles/ActionStyles'
import React, { useEffect, useState } from 'react'
import AntdTree from '../../../../components/Tree/index'
import { FlexWrap } from '../UsersView/Style/style'

const Permission = ({
  eventList,
  setSubEventsId,
  setEventsId,
  setFormattedData,
  view,
  formattedData,
  journeyValue,
  singleRoleData,
  setPermissions,
  selectionType,
  lable,
  setInValid,
  journeyArray,
  validateEventsBasedOnJourney,
}) => {
  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const onExpand = expandedKeysValue => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }
  const onCheck = checkedKeysValue => {
    setCheckedKeys(checkedKeysValue)
    if (!checkedKeysValue.length) {
      setInValid(true)
    }
    const response = validateEventsBasedOnJourney(journeyArray)

    if (response) {
      setInValid(true)
    } else {
      setInValid(false)
    }
  }
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue)
  }
  useEffect(() => {
    let userPermissions = []
    singleRoleData?.roleEventMap?.map(el => {
      if (el?.journey?.toUpperCase() == journeyValue)
        userPermissions?.push(el?.eventsId ?? el?.subEventId)
    })
    setCheckedKeys(...checkedKeys, userPermissions)
  }, [singleRoleData])

  useEffect(() => {
    let tempFormattedData = eventList?.map((el, ind) => {
      return {
        title: !el.events?.length ? (
          <FlexWrap gap="5px">
            {el.screenName.toLowerCase()}
            <Tooltip title="Please add some Events" color={'#3249D7'}>
              {}
              <InfoCircleOutlined />{' '}
            </Tooltip>{' '}
          </FlexWrap>
        ) : (
          el.screenName.toLowerCase()
        ),
        key: el.id,
        disabled: !el.events?.length,
        children: el.events.map((el, ind) => {
          return {
            title: el.eventName.toLowerCase(),
            key: el.id,
            children: el.subEvents.map((elem, index) => {
              return {
                title: elem.subEventName.toLowerCase(),
                key: elem.id,
              }
            }),
          }
        }),
      }
    })
    setFormattedData(tempFormattedData ?? [])
  }, [eventList])

  const subEvents = () => {
    let subEventIds = []
    let eventIds = []
    let copyOfCheckedKeys = [...checkedKeys]
    let copyOfFormattedValues = [...formattedData]
    copyOfCheckedKeys?.forEach(key => {
      copyOfFormattedValues?.map((permissions, index) =>
        permissions?.children?.forEach(events => {
          const subEventData = events?.children?.find(
            (subEvents, index) => subEvents.key === key
          )
          if (subEventData) {
            const existingIndex = subEventIds.findIndex(
              item => item.subEventId === subEventData.key
            )
            if (existingIndex !== -1) {
              subEventIds.splice(existingIndex, 1)
            } else {
              subEventIds.push({
                subEventId: subEventData.key,
                journey: journeyValue,
              })
            }
          }
          return null
        })
      )
      copyOfFormattedValues?.map((permissions, index) => {
        const eventData = permissions?.children?.find(
          (event, index) => event.key === key
        )
        if (eventData && eventData?.children?.length === 0) {
          const existingIndex = eventIds?.findIndex(
            item => item?.eventsId == eventData?.key
          )
          if (existingIndex !== -1) {
            eventIds?.splice(existingIndex, 1)
          } else {
            eventIds.push({ eventsId: eventData.key, journey: journeyValue })
          }
        }
        return null
      })
    })
    setEventsId(eventIds)
    setSubEventsId(subEventIds)
    let permissions = {
      journey: journeyValue,
      events: [...eventIds, ...subEventIds],
    }
    setPermissions(permissions)
  }
  useEffect(() => {
    subEvents()
  }, [checkedKeys, singleRoleData])
  return (
    <>
      {selectionType == journeyValue && (
        <>
          <AntdTree
            checkable
            onExpand={onExpand}
            disabled={view}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={formattedData}
          />
          {!checkedKeys?.length ? (
            <ItemDeleteText>
              Atleast One Permissions Must Be Added for the {lable} Journey
            </ItemDeleteText>
          ) : null}
        </>
      )}
    </>
  )
}
export default Permission
