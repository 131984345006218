import {
  SET_SINGLE_CORPORATES_DATA,
  SET_USER_CORPORATES_LIST,
  SET_ACTIVE_CORPORATE_DATA,
} from './actions'

const INITIAL_STATE = {
  citiesWithPAgination: {
    cities: [],
  },
  userCorporatesList: {
    list: [],
  },
  singleCorporateData: {},
  activeCorporateData: {},
}

export const Corporates = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_CORPORATES_LIST:
      return {
        ...state,
        userCorporatesList: action.data,
      }
    case SET_ACTIVE_CORPORATE_DATA:
      return {
        ...state,
        activeCorporateData: action.data,
      }
    case SET_SINGLE_CORPORATES_DATA:
      return {
        ...state,
        singleCorporateData: action.data,
      }
    case 'SET_CITY_WITH_PAGINATION':
      return {
        ...state,
        citiesWithPagination: action.data,
      }

    default:
      return state
  }
}
