import React from 'react'

const InActive = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6H7.5C4.19 6 1.5 8.69 1.5 12C1.5 15.31 4.19 18 7.5 18H17.5C20.81 18 23.5 15.31 23.5 12C23.5 8.69 20.81 6 17.5 6ZM17.5 16H7.5C5.29 16 3.5 14.21 3.5 12C3.5 9.79 5.29 8 7.5 8H17.5C19.71 8 21.5 9.79 21.5 12C21.5 14.21 19.71 16 17.5 16ZM7.5 9C5.84 9 4.5 10.34 4.5 12C4.5 13.66 5.84 15 7.5 15C9.16 15 10.5 13.66 10.5 12C10.5 10.34 9.16 9 7.5 9Z"
        fill="#5B6DDF"
      />
    </svg>
  )
}

export default InActive
