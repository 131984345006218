import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import Button from 'components/Button/index'
import { Alert,Select} from 'antd'
import { FONT } from 'theme/font'


export const SectionWrapper = styled(Flex)`
  width: ${props => (props?.width ? props?.width : '100%')};
  // width: 100%;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  border-radius: 10px;
  // overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
`
export const StyledSelect = styled(Select)`
  width: 250px;
  height: 35px;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 47px !important;
    // background-color: #fafafb !important;
    border-radius: 8px !important;
    border-color: #e9e9e9 !important;
    box-shadow: none !important;
  }
  && {
    .ant-select-selector .ant-select-selection-item {
      flex: 1;
      overflow: hidden;
      // color: #bfbfbf;
      font-size: 14.5px;
      white-space: nowrap;
      margin-top: 5px;
      text-overflow: ellipsis;
    }
  }
  .ant-select-selector .ant-select-selection-placeholder {
    padding-top: ${props => props.paddingTop || '0px'} !important;
    flex: 1;
    overflow: hidden;
    font-size: 14.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  && {
    .ant-select-arrow {
      margin-top: 0 !important;
    }
  }
`

export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`

export const TopRightFlex = styled(Flex)`
  gap: 15px;
`
export const SmallButtons = styled(Button.Default)`
  background: ${COLORS.WHITE};
  padding: 9px;
  > div {
    margin-left: 0;
  }
`
export const CardRow = styled(Flex)`
  padding: 0 25px 25px 25px;
  gap: 20px;
`
export const SkillRow = styled(Flex)`
  gap: px;
`
export const ContentCenterDiv = styled.div`
  padding-top: 10px;
  text-align: center;
`
export const dropDownItem = styled.div`
  padding: 20px !important;
  display: flex;
  gap: 20px;
`
export const dropDownInside = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 6px;
`

export const StyledAlert = styled(Alert)`
  border: none;
  border-radius: 10px;
  margin: ${props => (props?.margin ? props?.margin : 0)};
  ${props =>
    props?.backgroundColor && `background-color: ${props?.backgroundColor};`}
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '0')};
  margin-top: ${props => (props.margintop ? props.margintop : '0')};
`
export const AlertText = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 18px;
  color: ${COLORS.GREY_T_20};
  margin-left: 5px;
`

export const NoContentStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
`
