import CorporateOnboardingForm from 'modules/Onboarding/Partials/Corporates/Register'
import { connect } from 'react-redux'

import corporateSelector from 'modules/Onboarding/Partials/Corporates/Register/selectors'

import {
  editCorporate,
  getSingleCorporateData,
  setCorporateFormInfo,
  getaddInstituteDrawer,
} from 'modules/Onboarding/Partials/Corporates/Register/actions'

const mapStateToProps = state => ({
  singleCorporateData: corporateSelector.getSingleCorporateData(state),
  corporateFormInfo: corporateSelector.getCorporateFormInfo(state),
  addInstitute: corporateSelector.getaddInstituteDrawer(state),
})

const mapDispatchToProps = {
  getSingleCorporateData,
  editCorporate,
  setCorporateFormInfo,
  getaddInstituteDrawer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateOnboardingForm)
