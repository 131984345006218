const initialState = {
  templateList: [],
  academicData: [],
  skillBucketData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATE_LIST_STUDENT':
      return {
        ...state,
        templateList: action.data,
      }
    case 'SET_ACADEMIC_STUDENT':
      return {
        ...state,
        academicData: action.data,
      }
    case 'SET_SKILL_BUCKET_DATA':
      return {
        ...state,
        skillBucketData: action.data,
      }
    default:
      return state
  }
}
