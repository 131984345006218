import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Dashboard/Partials/UsersFilter/Styles/style'

const StatusFilter = ({ corporateStatus, setCorporateStatus }) => {
  const options = [
    {
      label: 'Veri.Pending',
      value: '0',
    },
    {
      label: 'Onboarding',
      value: '1',
    },
    {
      label: 'Request To Change',
      value: '2',
    },
  ]

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={corporateStatus}
        options={options}
        onChange={value => setCorporateStatus(value)}
      />
    </Wrapper>
  )
}

export default StatusFilter
