import request from 'utils/adminRequest'
export const SET_CORPORATE_LIST_FOR_REPORT =
  'corporate/SET_CORPORATE_LIST_FOR_REPORT'
export const SET_SECTOR_LIST = `corporate/SET_SECTOR_LIST`
export const SET_STATE_LIST = `corporate/SET_STATE_LIST`
export const SET_CITY_LIST = `corporate/SET_CITY_LIST`
export const SET_INDUSTRY_LIST = `corporate/SET_INDUSTRY_LIST`
export const SET_CORP_EMPANELLED_LIST = `corporate/SET_EMPANELLED_LIST`
export const SET_TOP_BOTTOM_CORPORATE_DETAILS = `corporate/SET_TOP_BOTTOM_CORPORATE_DETAILS`
export const SET_EXCEL_DATA = `corp/EXCEL`
export const SET_INDUSTRY_SECTOR_DATA = `corp/SET_INDUSTRY_SECTOR_DATA`
export const SET_SINGLE_SECTOR_DATA = `corp/SET_SINGLE_SECTOR_DATA`
export const SET_SINGLE_INDUSTRY_DATA = `corp/SET_SINGLE_INDUSTRY_DATA`
export const setIndustrySectorData = data => {
  return {
    type: SET_INDUSTRY_SECTOR_DATA,
    data,
  }
}
export const setSingleSectorData = data => {
  return {
    type: SET_SINGLE_SECTOR_DATA,
    data,
  }
}
export const setSingleIndustryData = data => {
  return {
    type: SET_SINGLE_INDUSTRY_DATA,
    data,
  }
}
export const setCorporateListForReport = data => {
  return {
    type: SET_CORPORATE_LIST_FOR_REPORT,
    data,
  }
}
export const setSectorList = data => {
  return {
    type: SET_SECTOR_LIST,
    data,
  }
}
export const setStateList = data => {
  return {
    type: SET_STATE_LIST,
    data,
  }
}
export const setCityList = data => {
  return {
    type: SET_CITY_LIST,
    data,
  }
}
export const setIndustryList = data => {
  return {
    type: SET_INDUSTRY_LIST,
    data,
  }
}
export const setCorporateEmpanelledList = data => {
  return {
    type: SET_CORP_EMPANELLED_LIST,
    data,
  }
}
export const setTopBottomCorporateDetails = data => {
  return {
    type: SET_TOP_BOTTOM_CORPORATE_DETAILS,
    data,
  }
}
export const setExcelData = data => {
  return {
    type: SET_EXCEL_DATA,
    data,
  }
}
export const getCorporateListForReport =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoadingCorp,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async dispatch => {
    if (setLoadingCorp) {
      setLoadingCorp(true)
    }
    try {
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let sector = encodeURIComponent(filterDatas?.sector || '')
      let industry = encodeURIComponent(filterDatas?.industry || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''

      const State = state ? `&state=${state}` : ``
      const City = city ? `&city=${city}` : ``
      const Sector = sector ? `&sector=${sector}` : ``
      const Industry = industry ? `&industry=${industry}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/corporates-report/lists?size=${pageSize}&page=${pageNumber}${Search}${State}${Sector}${Industry}${City}${CreatedAtStart}${CreatedAtEnd}${Sort}${OrderBy} `
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setCorporateListForReport(response?.data?.data || []))
      }

      if (setLoadingCorp) setLoadingCorp(false)
    } catch (error) {
      if (setLoadingCorp) {
        setLoadingCorp(false)
      }
    }
  }

export const getSectorList =
  ({ setSectorLoading, keySector = '', flag }) =>
  async dispatch => {
    if (setSectorLoading) {
      setSectorLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keySector.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/corporate-filter/sectorindustrycorpmaster/lists?name=${myEncodedString}&page=${0}&size=10${Flag} `
      )
      dispatch(setSectorList(response?.data?.data || []))
      setSectorLoading(false)
    } catch (error) {
      if (setSectorLoading) {
        setSectorLoading(false)
      }
    }
  }
export const getSingleIndustryList =
  ({ setSingleIndustryLoading, keySingleIndustry = '', flag, page = 0 }) =>
  async dispatch => {
    if (setSingleIndustryLoading) {
      setSingleIndustryLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(
        keySingleIndustry.toLowerCase()
      )
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/industrycorporatesmaster/lists?industry=${myEncodedString}&page=${page}&size=10${Flag} `
      )
      dispatch(setSingleIndustryData(response?.data?.data || []))
      setSingleIndustryLoading(false)
    } catch (error) {
      if (setSingleIndustryLoading) {
        setSingleIndustryLoading(false)
      }
    }
  }
export const getSingleSectorList =
  ({ setSingleSectorLoading, keySingleSector = '', flag, page = 0 }) =>
  async dispatch => {
    if (setSingleSectorLoading) {
      setSingleSectorLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keySingleSector.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/sectorcorporatesmaster/lists?sector=${myEncodedString}&page=${page}&size=10${Flag} `
      )
      dispatch(setSingleSectorData(response?.data?.data || []))
      setSingleSectorLoading(false)
    } catch (error) {
      if (setSingleSectorLoading) {
        setSingleSectorLoading(false)
      }
    }
  }
export const getStateList =
  ({ setStateLoading, keyState = '', flag, page = 0, setMoreLoading }) =>
  async dispatch => {
    if (setStateLoading) {
      setStateLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keyState.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/corporate-filter/statecorpsmaster/lists?name=${myEncodedString}&page=${page}&size=50${Flag}`
      )
      dispatch(setStateList(response?.data?.data || []))
      if (setStateLoading) setStateLoading(false)
    } catch (error) {
      if (setStateLoading) {
        setStateLoading(false)
      }
    }
  }
export const getCityList =
  ({ setLoadingCity, keyCity = '', flag, page = 0 }) =>
  async dispatch => {
    setLoadingCity(true)
    try {
      const myEncodedString = encodeURIComponent(keyCity.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/corporate-filter/citycorpmaster/lists?name=${myEncodedString}&page=${page}&size=50${Flag}`
      )
      dispatch(setCityList(response?.data?.data))
      setLoadingCity(false)
    } catch (error) {
      setLoadingCity(false)
    }
  }

export const getIndustryList =
  ({ setLoading, keyIndustry = '' }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keyIndustry.toLowerCase())
      const response = await request.get(
        `/industrycorporatesmaster/lists?industry=${myEncodedString}`
      )
      dispatch(setIndustryList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getEmpanelledCorporateListForReport =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let sector = encodeURIComponent(filterDatas?.sector || '')
      let industry = encodeURIComponent(filterDatas?.industry || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''

      const State = state ? `&state=${state}` : ``
      const City = city ? `&city=${city}` : ``
      const Sector = sector ? `&university=${sector}` : ``
      const Industry = industry ? `&tier=${industry}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/institute-report/lists?size=${pageSize}&page=${pageNumber}${Search}${State}${Sector}${Industry}${City}${CreatedAtStart}${CreatedAtEnd}${Sort}${OrderBy}`
      )
      dispatch(setCorporateEmpanelledList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getTopBottomCorporateDetails =
  ({
    top = true,
    pageSize = 10,
    pageNumber = 0,
    setLoadingTopCorp,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async dispatch => {
    let BASE_URL = ''
    if (setLoadingTopCorp) {
      setLoadingTopCorp(true)
      dispatch(setExcelData([]))

      if (top) {
        BASE_URL = '/topcorporatesreport/toplists'
      } else {
        BASE_URL = 'bottomcorporatesreport/bottomlists'
      }
    }
    try {
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let sector = encodeURIComponent(filterDatas?.sector || '')
      let industry = encodeURIComponent(filterDatas?.industry || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''

      const State = state ? `&state=${state}` : ``
      const City = city ? `&city=${city}` : ``
      const Sector = sector ? `&sector=${sector}` : ``
      const Industry = industry ? `&industry=${industry}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `${BASE_URL}?size=${pageSize}&page=${pageNumber}${Search}${State}${Sector}${Industry}${City}${CreatedAtStart}${CreatedAtEnd}${Sort}${OrderBy} `
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setTopBottomCorporateDetails(response?.data?.data || []))
      }

      if (setLoadingTopCorp) setLoadingTopCorp(false)
    } catch (error) {
      if (setLoadingTopCorp) {
        setLoadingTopCorp(false)
      }
    }
  }
export const IndustrySectorUrl =
  (flag, setLoading, name, page = 0, setDegreeLoader) =>
  async dispatch => {
    try {
      if (page == 0) {
        setDegreeLoader(true)
        setIndustrySectorData([])
      }
      setLoading(true)
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name
        ? `&name=${name?.toLocaleLowerCase()?.replace(/\./g, '')?.trim()}`
        : ``

      let response = await request.get(
        `/corporate-filter/sectorindustrycorpmaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setIndustrySectorData(response?.data?.data))
      setLoading(false)
      setDegreeLoader(false)
    } catch (error) {
      setLoading(false)
      setDegreeLoader(false)
    }
  }
