const corporateListReportData = state =>
  state?.report?.getCorporateListForReport
const sectorListData = state => state?.report?.getSectorListForReport
const cityListData = state => state?.report?.getCityListForReport
const stateListData = state => state?.report?.getStateListForReport
const industryListData = state => state?.report?.getIndustryListForReport
const corporateEmpanelledListReportData = state =>
  state?.report?.getEmpanelledCorporateListForReport
const topBottomCorporateDetails = state =>
  state?.report?.getTopBottomCoraporateDetails
const excelData = state => state?.report?.excelData
const industrySectorData = state => state?.report?.getIndustrySectorData
const singleSectorData = state => state?.report?.getSingleSectorData
const singleIndustryData = state => state?.report?.getSingleIndustryData

const selectors = {
  corporateListReportData,
  sectorListData,
  cityListData,
  stateListData,
  industryListData,
  corporateEmpanelledListReportData,
  topBottomCorporateDetails,
  excelData,
  industrySectorData,
  singleSectorData,
  singleIndustryData,
}

export default selectors
