import {
  SET_INSTITUTES_LIST,
  SET_SINGLE_INSTITUTES_DATA,
  SET_INSTITUTES_METRICS_DATA,
  SET_INSTITUTE_GENERATED_LINK,
} from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/actions'

const initialSingleInstituteInfo = {
  id: '',
  autoIncrementId: '',
  prefixId: '',
  createdAt: '',
  updatedUp: '',
  name: '',
  logo: '',
  campusName: '',
  campusLocation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  cityId: '',
  state: '',
  stateId: '',
  country: '',
  countryId: '',
  postalCode: '',
  website: '',
  contactPerson: '',
  contactNumber: '',
  contactPersonRole: '',
  secondaryContactNumber: '',
  secondaryContactEmail: '',
  isActive: true,
  pan: '',
  gst: '',
  deleted: '',
  authorisedSignatory: '',
  regDocs: '',
}

const initialState = {
  institutesList: { result: [] },
  singleInstituteData: initialSingleInstituteInfo,
  institutesMetrics: {},
  instituteGeneratedLinkAndID: { generatedLink: '', id: '' },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTITUTES_LIST:
      return {
        ...state,
        institutesList: action.data,
      }
    case SET_SINGLE_INSTITUTES_DATA:
      return {
        ...state,
        singleInstituteData: action.data,
      }
    case SET_INSTITUTES_METRICS_DATA:
      return {
        ...state,
        institutesMetrics: action.data,
      }
    case SET_INSTITUTE_GENERATED_LINK:
      return {
        ...state,
        instituteGeneratedLinkAndID: action.data,
      }
    default:
      return state
  }
}
