/* eslint-disable react/prop-types */
import { Upload } from 'antd'
import Flex from 'components/Flex'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  background: #fafafb;
  border: 1px dashed #cccfd6;
  border-radius: 10px;
  height: 96px;
`
export const StyledCardDiv = styled(Flex)`
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  background: #ffffff;
  border: 1px solid #e6e7ea;
  border-radius: 10px;
  width: 100%;
`

export const StyledImg = styled(Flex)`
  padding: 10px 10px 10px 0px;
`

export const StyledH2 = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  margin-top: 8px;
  color: #4d566e;JPGIcon
`

export const StyledP = styled(Flex)``

export const StyledDelete = styled.div`
  cursor: pointer;
  z-index: 1;
  margin-left: 23px;
`
export const VisibleDiv = styled.div`
cursor: pointer;
z-index: 1;
margin-left: 10px;
`

export const DownloadIconDiv = styled.div`
cursor: pointer;
z-index: 1;
margin-left: 10px;
`


export const StyledUpload = styled(Upload)`
  display: block;
`

export const AdjustIcon = styled.div`
  margin-top: ${props => (props?.margin ? '7px' : '2px')};
  margin-right: ${props => props?.margin && '7px'};
`
export const Cirlcle = styled.div`
  width: 6px;
  height: 6px;
  background: #cccfd6;
  border-radius: 50%;
  margin: 0px 13px;
`
export const SmallCirlcle = styled.div`
  width: 3px;
  height: 3px;
  background: #cccfd6;
  border-radius: 50%;
  margin: 0px 5px;
`

export const SpanText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #999fac;
  white-space: nowrap;
`
export const TextWrapper = styled(Flex)`
  padding: 9px 20px 0px 20px;
`
