import ExportButton from 'components/ExportButton/ExportButton.js'
import {
  EmpanelledDateFormat,
  arrayIntoString,
  filterData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import ListOfStudentPlacedFilter from 'modules/Reports/Partials/Students/Partials/StudentPlacedTable/StudentFilter/index.js'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessage from 'utils/WarningMessage'
import {
  PaddedDiv,
  SectionWrapper,
  TopHeader,
  TopHeading,
} from '../../../../Styles/style'
import { setStudentPlacedList } from '../../actions'
import { degreeListConvertionPart } from '../Function/Function'
import StudentPlacedTable from '../StudentPlacedTable/StudentListPlacedTable/index'

const ListOfStudentsPlacedTable = ({
  getPlacedStudentListForReport,
  studentList,
  collegeList,
  specialisationList,
  courseList,
  corporateNameList,
  excelDataForReport,
  UniversityUrl,
  universityData,
  CollegeUrl,
  collegeData,
  DegreeUrl,
  degreeData,
  SpecialisationUrl,
  specialisationData,
  CorporateUrl,
  corporateData,
  userData,
}) => {
  const dispatch = useDispatch()
  const [sortBy, setSortBy] = useState('')
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loadingDep, setLoadingDep] = useState(false)
  const [searchDegree, setSearchDegree] = useState('')
  const [degreeList, setDegreeList] = useState([])
  const [corporateLoader, setCorporateLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [degreeListData, setDegreeListData] = useState([])
  const [universityLoader, setUniversityLoader] = useState(false)
  const [degreeLoader, setDegreeLoader] = useState(false)
  const [collegeLoader, setCollegeLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [filterDatas, setFilterData] = useState({})
  const [keyCourse, setKeyCourse] = useState('')
  const [keySpecialisation, setKeySpecialisation] = useState('')
  const [keyDegree, setKeyDegree] = useState('')
  const [keyCollege, setKeyCollege] = useState('')
  const [keyName, setKeyName] = useState('')
  const [nameValue, setNameValue] = useState([])
  const [courseValue, setCourseValue] = useState([])
  const [specialisationValue, setSpecialisationValue] = useState([])
  const [degreeValue, setDegreeValue] = useState([])
  const [collegeValue, setCollegeValue] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [debounced, setDebounced] = useState(() => searchValue)
  const [debouncedDegree, setDebouncedDegree] = useState(() => keyDegree)
  function toCamelCase(str) {
    const words = str.split(' ')
    const camelCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    return camelCaseWords.join(' ')
  }
  const [tooltip, setToolTip] = useState(false)
  function hasData(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (hasData(filterDatas)) {
      if (pageNum) {
        if (hasData(filterDatas)) {
          getPlacedStudentListForReport({
            pageSize: 10,
            pageNumber: pageNum - 1,
            setLoading,
            searchValue,
            filterDatas,
            sortBy,
          })
        }
      }
    }
  }, [pageNum])

  useEffect(() => {
    if (hasData(filterDatas)) {
      getPlacedStudentListForReport({
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
      setPageNum(1)
    }
  }, [debounced, filterDatas, sortBy])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(searchValue)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDegree(searchDegree)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchDegree])
  useEffect(() => {
    let college = arrayIntoString(collegeValue)
    let course = arrayIntoString(courseValue)
    let specialisation = arrayIntoString(specialisationValue)
    let degree = arrayIntoString(degreeList)
    let corporate = arrayIntoString(nameValue)

    let filterDatas = {
      college: college,
      course: course,
      specialisation: specialisation,
      degree: degree,
      corporate: corporate,
    }
    setToolTip(hasData(filterDatas))
  }, [collegeValue, courseValue, specialisationValue, degreeList, nameValue])

  const title = [
    { title: 'ID' },
    {
      title: 'Student Name',
    },
    { title: 'Deg-Dept  (Spec.)', width: { wch: 30 } },
    { title: 'Corporate Name', width: { wch: 20 } },
    { title: 'Role', width: { wch: 20 } },
    { title: 'Compensations', width: { wch: 20 } },
    { title: 'College Name', width: { wch: 20 } },
    { title: 'Empanalled', width: { wch: 20 } },
  ]
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const tableData = excelDataForReport?.result?.map(item => [
    { value: item.prefix_id ? item.prefix_id : '-' },
    {
      value: item.first_name
        ? capitalizeFirstLetter(item.first_name) +
          (item.last_name ? ' ' + String(item.last_name).trim() : '')
        : '-',
    },
    {
      value:
        item.course && item.specialisation && item.short_form
          ? item.short_form +
            '-' +
            toCamelCase(item.course) +
            ' (' +
            toCamelCase(item.specialisation) +
            ')'
          : item.short_form && item.course && !item.specialisation
          ? item.short_form + '-' + toCamelCase(item.course)
          : '-',
    },

    { value: item.corporate_name ? item.corporate_name : '-' },
    { value: item.role ? item.role : '-' },
    {
      value: item.compensation
        ? Number(item.compensation).toLocaleString()
        : '-',
    },
    { value: item.institute_campus_name ? item.institute_campus_name : '-' },

    { value: EmpanelledDateFormat(item.createdAt) },
  ])
  const excelData = [{ columns: title, data: tableData }]
  const [isExcel, setIsExcel] = useState('')
  const [excel, setExcel] = useState([])
  const clickedExcel = data => {
    let excel = true
    getPlacedStudentListForReport({
      pageSize: 5000,
      pageNumber: 0,
      setLoading,
      searchValue,
      filterDatas,
      sortBy,
      excel,
    })
    setIsExcel(data)
  }
  useEffect(() => {
    if (
      (excelDataForReport?.result && tableData && isExcel == 'excel') ||
      (excelDataForReport?.result && tableData && isExcel == 'pdf')
    ) {
      if (loading) {
        setExcel([])
      } else {
        setExcel(excelData)
      }
    }
  }, [excelDataForReport?.result, loading])

  const handleHover = () => {
    if (studentList?.count > 5000) {
      WarningMessage(
        'Note : The student count should not exceed 5,000 for data export.  Apply filters if necessary'
      )
    }
  }

  useEffect(() => {
    let flag = 'LSP'
    DegreeUrl(flag, setLoadingDep, searchDegree, 0, setDegreeLoader)
  }, [debouncedDegree])
  const loadMoreData = async () => {
    let flag = 'LSP'
    DegreeUrl(
      flag,
      setLoadingDep,
      searchDegree,
      degreeData?.page + 1,
      setDegreeLoader
    )
    setPage(page + 1)
  }
  const handleSearch = e => {
    const query = e.target.value
    setSearchQuery(query)
  }
  useEffect(() => {
    const updatedData = degreeListConvertionPart({
      listOfDegree: degreeData?.result,
      selectedIds: [],
      isEditActiveRole: [],
    })
    setCount(degreeData?.count)
    setPage(degreeData?.page)
    setDegreeListData(updatedData)
  }, [degreeData])
  useEffect(() => {
    dispatch(setStudentPlacedList([]))
  }, [])
  const columnWidths = [17, 40, 44, 40, 40, 35, 45, 25, 25] // Array of column widths
  const HAlign = [
    'center',
    'left',
    'left',
    'left',
    'left',
    'center',
    'left',
    'center',
    'left',
  ]
  return (
    <SectionWrapper column>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>List of Students Placed</TopHeading>
          <TopHeading flexEnd style={{ display: 'flex', gap: 10 }}>
            <div role="button" tabIndex="0" onMouseEnter={handleHover}>
              <ExportButton
                title={'List of Students Placed'}
                dataset={excel}
                loading={loading}
                clickedExcel={clickedExcel}
                data={isExcel}
                setIsExcel={setIsExcel}
                count={studentList?.count || 0}
                userData={userData}
                columnWidth={columnWidths} // Array of column widths
                columnFontSize={10} // Font size for the column data
                horizontalAlignment={HAlign}
              />
            </div>
          </TopHeading>
        </TopHeader>
      </PaddedDiv>
      <div>
        <ListOfStudentPlacedFilter
          corporateData={corporateData}
          CorporateUrl={CorporateUrl}
          SpecialisationUrl={SpecialisationUrl}
          tooltip={tooltip}
          setCorporateLoader={setCorporateLoader}
          corporateLoader={corporateLoader}
          handleSearch={handleSearch}
          searchQuery={searchQuery}
          degreeList={degreeList}
          UniversityUrl={UniversityUrl}
          CollegeUrl={CollegeUrl}
          degreeLoader={degreeLoader}
          degreeData={degreeData}
          DegreeUrl={DegreeUrl}
          universityData={universityData}
          specialisationData={specialisationData}
          collegeData={collegeData}
          setCollegeLoader={setCollegeLoader}
          collegeLoader={collegeLoader}
          setUniversityLoader={setUniversityLoader}
          universityLoader={universityLoader}
          setSearchDegree={setSearchDegree}
          degreeListData={degreeListData}
          setSearchQuery={setSearchQuery}
          setDegreeLoader={setDegreeLoader}
          setDegreeList={setDegreeList}
          setLoading={setLoading}
          loading={loading}
          tableData={studentList}
          setPageNum={setPageNum}
          pageNum={pageNum}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          collegeList={filterData(collegeList?.result, 'campus_name')}
          courseList={filterData(courseList?.result, 'name')}
          // degreeList={filterData(degreeList?.result, 'name')}
          specialisationList={filterData(specialisationList?.result, 'name')}
          degreeValue={degreeValue}
          nameValue={nameValue}
          courseValue={courseValue}
          specialisationValue={specialisationValue}
          collegeValue={collegeValue}
          keyCollege={keyCollege}
          keyCourse={keyCourse}
          keySpecialisation={keySpecialisation}
          keyDegree={keyDegree}
          keyName={keyName}
          setDegreeValue={setDegreeValue}
          setNameValue={setNameValue}
          setCollegeValue={setCollegeValue}
          setCourseValue={setCourseValue}
          setSpecialisationValue={setSpecialisationValue}
          setKeyCollege={setKeyCollege}
          setKeyCourse={setKeyCourse}
          setKeyDegree={setKeyDegree}
          setKeyName={setKeyName}
          setKeySpecialisation={setKeySpecialisation}
          isList={false}
          filterData={filterDatas}
          setFilterData={setFilterData}
          isStudentPlaced={true}
          corporateNameList={filterData(corporateNameList?.result, 'name')}
          page={page}
          count={count}
          setLoadingDep={setLoadingDep}
          loadMoreData={loadMoreData}
          loadingDep={loadingDep}
        />

        <StudentPlacedTable
          tooltip={tooltip}
          hasProp={hasData(filterDatas)}
          setSortBy={setSortBy}
          tableData={studentList}
          loading={loading}
          isEmpanelled={false}
          setPageNum={setPageNum}
          pageNum={pageNum}
        />
      </div>
    </SectionWrapper>
  )
}
export default ListOfStudentsPlacedTable
