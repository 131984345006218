import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Dashboard/Partials/UsersFilter/Styles/style'

const StatusFilter = ({ instituteActiveStatus, setInstituteActiveStatus }) => {
  const options = [
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'InActive',
      value: false,
    },
    // {
    //   label: 'Expired',
    //   // value: '2',
    // },
  ]

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={[instituteActiveStatus]}
        options={options}
        onChange={value => setInstituteActiveStatus(value[value?.length - 1])}
      />
    </Wrapper>
  )
}

export default StatusFilter
