import React from 'react'
import {
  ActionMenu,
  ButtonText,
  ItemText,
  Main,
  StyledDropDownButton,
} from 'components/ActionStyles/ActionStyles'
import DownArrow from 'components/icons/DownArrow'
import { useNavigate } from 'react-router-dom'

const ActionDropdown = ({
  rowData,
  getSingleUserInfo,
  setAddUserDrawer,
  setUserId,
  getUserData,
  getSingleUser,
  updateUser,
}) => {
  const navigate = useNavigate()
  const onClickView = rowId => {
    navigate(`/users/${rowId}`, { state: rowId })
    getSingleUserInfo(rowId)
  }

  const onClickEdit = rowData => {
    setAddUserDrawer(true)
    setUserId(rowData?.id)
    getSingleUserInfo(rowData?.id)
  }

  const onUpdateStatus = async value => {
    let userId = rowData?.id
    const payload = {
      // admin: {
      //   firstName: value?.firstName,
      //   lastName: value?.lastName,
      //   adminRoleId: value?.adminRoleId,
      // },
      user: {
        status: value ? 0 : 1,
      },
    }
    setUserId(rowData?.id)
    await updateUser(userId, payload)
    getUserData()
  }
  const menu = (
    <ActionMenu
      width={'165px'}
      items={[
        {
          key: 'edit',
          label: <ItemText onClick={() => onClickEdit(rowData)}>Edit</ItemText>,
        },
        rowData?.status == 0 && {
          key: '1',
          label: (
            <ItemText onClick={() => onUpdateStatus(false)}>
              Set to Inactive
            </ItemText>
          ),
        },
        rowData?.status == 1 && {
          key: '0',
          label: (
            <ItemText onClick={() => onUpdateStatus(true)}>
              Set to Active
            </ItemText>
          ),
        },
      ]}
    />
  )

  return (
    <Main id="filterDropdown">
      <StyledDropDownButton
        overlay={menu}
        shape="round"
        trigger={['click']}
        icon={<DownArrow />}
        getPopupContainer={() => document.getElementById('filterDropdown')}
      >
        <ButtonText
          onClick={() => onClickView(rowData?.id)}
          getSingleUser={getSingleUser}
        >
          View
        </ButtonText>
      </StyledDropDownButton>
    </Main>
  )
}

export default ActionDropdown
