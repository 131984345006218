import {
  DateFormat,
  filterData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import CorporateInfoTable from 'modules/Reports/Partials/Corporates/Partials/CorporateInfoTable'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessage from 'utils/WarningMessage'
import ExportButton from '../../components/ExportButton/ExportButton.js'
import { industrySectorPart } from './Partials/Students/Partials/Function/Function.js'
import {
  PaddedDiv,
  SectionWrapper,
  TopHeader,
  TopHeading,
} from './Styles/style'
import {
  setCityList,
  setCorporateListForReport,
  setIndustryList,
  setSectorList,
  setSingleIndustryData,
  setSingleSectorData,
  setStateList,
} from './actions.js'

const Reports = ({
  getCorporateListForReport,
  corporateListDataForReport,
  getSectorList,
  sectorList,
  getCityList,
  getIndustryList,
  getStateList,
  industryList,
  cityList,
  stateList,
  excelDataForReport,
  IndustrySectorUrl,
  industrySectorData,

  getSingleSectorList,
  singleSectorData,
  singleIndustryData,
  getSingleIndustryList,

  singleUserData,
  getSingleUser,
  getUserInfo,
  userData,
}) => {
  const dispatch = useDispatch()
  const [first, setFirst] = useState(true)
  const [stateCount, setStateCount] = useState(0)
  const [stateData, setStateData] = useState([])
  const [cityCount, setCityCount] = useState(0)
  const [cityPage, setCityPage] = useState(0)
  const [cityData, setCityData] = useState([])
  const [statePage, setStatePage] = useState(0)
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [moreLoading, setMoreLoading] = useState(false)
  const [moreLoadingCity, setMoreLoadingCity] = useState(false)
  const [searchDegree, setSearchDegree] = useState('')
  const [keyDegree, setKeyDegree] = useState('')
  const [degreeList, setDegreeList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [degreeListData, setDegreeListData] = useState([])
  const [degreeLoader, setDegreeLoader] = useState(false)
  const [debouncedDegree, setDebouncedDegree] = useState(() => keyDegree)
  const [tooltip, setToolTip] = useState(false)
  const [sectorLoading, setSectorLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [sortBy, setSortBy] = useState({})
  const [filterDatas, setFilterData] = useState({})
  const [keySector, setKeySector] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(() => searchValue)
  const [loadingDep, setLoadingDep] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingCorp, setLoadingCorp] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)
  const [previousPage, setPreviousPage] = useState('')
  const [sectorValue, setSectorValue] = useState([])
  const [stateValue, setStateValue] = useState([])
  const [cityValue, setCityValue] = useState([])
  const [industryValue, setIndustryValue] = useState([])
  const [keyState, setKeyState] = useState('')
  const [keyCity, setKeyCity] = useState('')
  const [keyIndustry, setKeyIndustry] = useState('')
  const [createdAtStart, setCreatedAtStart] = useState('')
  const [createdAtEnd, setCreatedAtEnd] = useState('')
  const [pageNum, setPageNum] = useState(1)
  const [debounced, setDebounced] = useState(() => keySector)
  const [debouncedIndustry, setDebouncedIndustry] = useState(() => keyIndustry)
  const [debouncedState, setDebouncedState] = useState(() => keyState)
  const [excel, setExcel] = useState([])
  const [cancel, onCanceled] = useState(false)
  const [clearDate, setClearDate] = useState(false)
  const [keySingleSector, setKeySingleSector] = useState('')
  const [debouncedSingleSectorSearch, setDebouncedSingleSearch] = useState(
    () => keySingleSector
  )
  const [singleSectorLoading, setSingleSectorLoading] = useState(false)
  const [singleSectorValue, setSingleSectorValue] = useState([])
  const [singleSectorCount, setSingleSectorCount] = useState(0)
  const [singleSectorPage, setSingleSectorPage] = useState(0)
  const [singleSectorDataFinal, setSingleSectorDataFinal] = useState([])
  const [keySingleIndustry, setKeySingleIndustry] = useState('')
  const [debouncedSingleIndustrySearch, setDebouncedSingleIndustrySearch] =
    useState(() => keySingleIndustry)
  const [singleIndustryLoading, setSingleIndustryLoading] = useState(false)
  const [singleIndustryValue, setSingleIndustryValue] = useState([])
  const [singleIndustryCount, setSingleIndustryCount] = useState(0)
  const [singleIndustryPage, setSingleIndustryPage] = useState(0)
  const [singleIndustryDataFinal, setSingleIndustryDataFinal] = useState([])

  useEffect(() => {
    getUserInfo()
  }, [])

  function hasData(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDegree(searchDegree)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchDegree])
  useEffect(() => {
    dispatch(setCorporateListForReport([]))

    if (hasData(filterDatas)) {
      setFirst(false)
      getCorporateListForReport({
        pageSize: 10,
        pageNumber: 0,
        setLoadingCorp,
        searchValue,
        filterDatas,
        sortBy,
      })
      setPageNum(1)
    }
  }, [debouncedSearch, filterDatas, sortBy])

  useEffect(() => {
    dispatch(setCorporateListForReport([]))

    if (hasData(filterDatas)) {
      if (pageNum) {
        getCorporateListForReport({
          pageSize: 10,
          pageNumber: pageNum - 1,
          setLoadingCorp,
          searchValue,
          filterDatas,
          sortBy,
        })
      }
    }
  }, [pageNum])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSingleSearch(keySingleSector)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySingleSector])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSingleIndustrySearch(keySingleIndustry)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySingleIndustry])
  useEffect(() => {
    dispatch(setSingleIndustryData([]))
    let flag = 'LC'
    let page = 0
    setSingleIndustryPage(0)
    getSingleIndustryList({
      setSingleIndustryLoading,
      keySingleIndustry,
      flag,
      page,
    })
  }, [debouncedSingleIndustrySearch])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(keySector)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySector])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(searchValue)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedState(keyState)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyState])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedIndustry(keyIndustry)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyIndustry])
  useEffect(() => {
    dispatch(setSingleSectorData([]))
    let flag = 'LC'
    let page = 0
    setSingleSectorPage(0)
    getSingleSectorList({ setSingleSectorLoading, keySingleSector, flag, page })
  }, [debouncedSingleSectorSearch])
  useEffect(() => {
    dispatch(setSectorList([]))
    let flag = 'LC'
    getSectorList({ setSectorLoading, keySector, flag })
  }, [debounced])

  useEffect(() => {
    dispatch(setCityList([]))
    let flag = 'LC'
    let page = 0
    setCityPage(0)
    getCityList({ setLoadingCity, keyCity, flag, page })
  }, [keyCity])

  useEffect(() => {
    dispatch(setStateList([]))
    let flag = 'LC'
    let page = 0
    setStatePage(0)
    getStateList({ setStateLoading, keyState, flag, page, setMoreLoading })
  }, [debouncedState])

  useEffect(() => {
    dispatch(setIndustryList([]))
    getIndustryList({ setLoading, keyIndustry })
  }, [debouncedIndustry])
  const title = [
    { title: 'ID' },
    {
      title: 'Corporate Name',
      width: { wch: 40 },
      padding: { ppx: 100 },
      height: { wpx: 22 },
    },
    { title: 'Industry', width: { wch: 40 }, padding: { ppx: 100 } },
    { title: 'Onboard', width: { wch: 30 } },
    { title: 'Location', width: { wch: 10 }, padding: { ppx: 100 } },
    { title: 'Address', width: { wch: 30 }, padding: { ppx: 100 } },
    { title: 'Contact Person', width: { wch: 45 } },
    { title: 'Email Id', width: { wch: 20 } },
    { title: 'Contact Number', width: { wch: 12 } },
  ]

  const tableData = excelDataForReport?.result?.map(item => [
    { value: item.prefix_id ? item.prefix_id : '-' },
    {
      value: item.name ? item.name : '-',
    },
    { value: item.industry ? item.industry : '-' },
    { value: item.createdat ? item.createdat?.split('/').join('-') : '-' },
    { value: item.city ? item.city : '-' },
    {
      value:
        (item?.addressLine1 ? item?.addressLine1 + ',' : '') +
        (item?.addressLine2 ? item.addressLine2 + ',' : '') +
        (item?.city ? item.city + ',' : '') +
        (item?.state ? item.state + ',' : '') +
        (item?.country ? item.country : ''),
    },
    { value: item.contactperson ? item.contactperson : '-' },
    { value: item.contactemail ? item.contactemail : '-' },
    { value: item.contactnumber ? item.contactnumber : '-' },
  ])

  const excelData = [{ columns: title, data: tableData }]
  const [isExcel, setIsExcel] = useState('')

  const clickedExcel = data => {
    let excel = true
    getCorporateListForReport({
      pageSize: 5000,
      pageNumber: 0,
      setLoading,
      searchValue,
      filterDatas,
      sortBy,
      excel,
    })
    setIsExcel(data)
  }
  useEffect(() => {
    if (
      (excelDataForReport?.result && tableData && isExcel == 'excel') ||
      (excelDataForReport?.result && tableData && isExcel == 'pdf')
    ) {
      if (loadingCorp) {
        setExcel([])
      } else {
        setExcel(excelData)
      }
    }
  }, [excelDataForReport?.result, loadingCorp])
  const handleHover = () => {
    if (corporateListDataForReport?.count > 5000) {
      WarningMessage(
        'Note : The Corporate count should not exceed 5,000 for data export.  Apply filters if necessary'
      )
    }
  }
  useEffect(() => {
    let flag = 'LC'
    setDegreeList([])
    setPage(0)
    IndustrySectorUrl(flag, setLoadingDep, searchDegree, 0, setDegreeLoader)
  }, [debouncedDegree])
  const handleSearch = e => {
    const query = e.target.value
    setSearchQuery(query)
  }

  useEffect(() => {
    const updatedData = industrySectorPart({
      listOfDegree: industrySectorData?.result,
      selectedIds: [],
      isEditActiveRole: [],
      childValueParam: 'sectorindustryid',
      childNameParam: 'industry',
      parentNameParam: 'sector',
      parentValueParam: 'sector_id',
      pathName: 'industry',
    })
    setCount(industrySectorData?.count)
    setPage(industrySectorData?.page)
    setDegreeListData(updatedData)
  }, [industrySectorData])
  const loadMoreData = async () => {
    let flag = 'LC'
    IndustrySectorUrl(
      flag,
      setLoadingDep,
      searchDegree,
      industrySectorData?.page + 1,
      setDegreeLoader
    )
    setPage(page + 1)
  }
  const loadMoreSingleSectorData = async () => {
    let flag = 'LC'
    let page = singleSectorData?.page + 1
    getSingleSectorList({ setSingleSectorLoading, keySingleSector, flag, page })

    setSingleSectorPage(singleSectorPage + 1)
  }
  const loadMoreSingleIndustryData = async () => {
    let flag = 'LC'
    let page = singleIndustryData?.page + 1
    getSingleIndustryList({
      setSingleIndustryLoading,
      keySingleIndustry,
      flag,
      page,
    })

    setSingleIndustryPage(singleIndustryPage + 1)
  }
  const loadMoreStateData = async () => {
    let flag = 'LC'
    let page = stateList?.page + 1
    getStateList({ setStateLoading, keyState, flag, page, setMoreLoading })

    setStatePage(statePage + 1)
  }
  const loadMoreCityData = async () => {
    let flag = 'LC'
    let page = cityList?.page + 1
    getCityList({ setLoadingCity, keyCity, flag, page })

    setCityPage(page + 1)
  }

  useEffect(() => {
    if (stateList) {
      setStateCount(stateList?.count)
      let data = filterData(stateList?.result, 'state')
      setStateData(data)
    }
    if (cityList) {
      setCityCount(cityList?.count)
      let data = filterData(cityList?.result, 'city')
      setCityData(data)
    }
    if (singleSectorData) {
      setSingleSectorCount(singleSectorData?.count)
      let data = filterData(singleSectorData?.result, 'sector')
      setSingleSectorDataFinal(data)
    }
    if (singleIndustryData) {
      setSingleIndustryCount(singleIndustryData?.count)
      let data = filterData(singleIndustryData?.result, 'industry')
      setSingleIndustryDataFinal(data)
    }
  }, [stateList, cityList, singleSectorData, singleIndustryData])
  useEffect(() => {
    setFilterData({})
    dispatch(setCorporateListForReport([]))
  }, [])
  const columnWidths = [17, 48, 37, 21, 24, 38, 35, 42, 25] // Array of column widths

  const HAlign = [
    'center',
    'left',
    'left',
    'center',
    'left',
    'left',
    'left',
    'left',
    'center',
  ]
  return (
    <SectionWrapper column>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>Lists of Corporates</TopHeading>
          <TopHeading flexEnd style={{ display: 'flex', gap: 10 }}>
            <div role="button" tabIndex="0" onMouseEnter={handleHover}>
              <ExportButton
                dataset={excel}
                title={'Lists of Corporates'}
                loading={loading}
                clickedExcel={clickedExcel}
                data={isExcel}
                setIsExcel={setIsExcel}
                count={corporateListDataForReport?.count || 0}
                columnWidth={columnWidths} // Array of column widths
                columnFontSize={10} // Font size for the column data
                horizontalAlignment={HAlign}
                userData={userData}
              />
            </div>
          </TopHeading>
        </TopHeader>
      </PaddedDiv>
      <CorporateInfoTable
        singleIndustryPage={singleIndustryPage}
        setSingleIndustryPage={setSingleIndustryPage}
        singleIndustryDataFinal={singleIndustryDataFinal}
        singleIndustryCount={singleIndustryCount}
        loadMoreSingleIndustryData={loadMoreSingleIndustryData}
        singleIndustryData={filterData(singleIndustryData?.result, 'industry')}
        singleIndustryValue={singleIndustryValue}
        setSingleIndustryValue={setSingleIndustryValue}
        keySingleIndustry={keySingleIndustry}
        singleIndustryLoading={singleIndustryLoading}
        setKeySingleIndustry={setKeySingleIndustry}
        singleSectorPage={singleSectorPage}
        setSingleSectorPage={setSingleSectorPage}
        singleSectorDataFinal={singleSectorDataFinal}
        singleSectorCount={singleSectorCount}
        loadMoreSingleSectorData={loadMoreSingleSectorData}
        singleSectorData={filterData(singleSectorData?.result, 'sector')}
        singleSectorValue={singleSectorValue}
        setSingleSectorValue={setSingleSectorValue}
        keySingleSector={keySingleSector}
        singleSectorLoading={singleSectorLoading}
        setKeySingleSector={setKeySingleSector}
        first={first}
        cityCount={cityCount}
        cityPage={cityPage}
        loadMoreCityData={loadMoreCityData}
        loadMoreStateData={loadMoreStateData}
        setDegreeList={setDegreeList}
        setKeyDegree={setKeyDegree}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        degreeList={degreeList}
        setSearchDegree={setSearchDegree}
        degreeListData={degreeListData}
        setSearchQuery={setSearchQuery}
        setDegreeLoader={setDegreeLoader}
        degreeLoader={degreeLoader}
        degreeData={industrySectorData}
        DegreeUrl={IndustrySectorUrl}
        stateLoading={stateLoading}
        sectorLoading={sectorLoading}
        setStateLoading={setStateLoading}
        setSectorLoading={setSectorLoading}
        setLoadingCity={setLoadingCity}
        loadingCity={loadingCity}
        hasProp={hasData(filterDatas)}
        loading={loading}
        loadingCorp={loadingCorp}
        setSortBy={setSortBy}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        tableData={first ? [] : corporateListDataForReport}
        sectorValue={sectorValue}
        setSectorValue={setSectorValue}
        setFilterData={setFilterData}
        filterData={filterDatas}
        industryList={filterData(industryList?.result, 'industry')}
        cityList={cityData}
        stateList={stateData}
        sectorList={filterData(sectorList?.result, 'sector')}
        stateCount={stateCount}
        stateValue={stateValue}
        cityValue={cityValue}
        tooltip={tooltip}
        setToolTip={setToolTip}
        industryValue={industryValue}
        setStateValue={setStateValue}
        setCityValue={setCityValue}
        setIndustryValue={setIndustryValue}
        setKeySector={setKeySector}
        keySector={keySector}
        keyState={keyState}
        keyIndustry={keyIndustry}
        keyCity={keyCity}
        setKeyCity={setKeyCity}
        setKeyIndustry={setKeyIndustry}
        setKeyState={setKeyState}
        isEmpanelled={false}
        createdAtStart={createdAtStart}
        createdAtEnd={createdAtEnd}
        setCreatedAtStart={setCreatedAtStart}
        setCreatedAtEnd={setCreatedAtEnd}
        setPageNum={setPageNum}
        pageNum={pageNum}
        onCanceled={onCanceled}
        cancel={cancel}
        setClearDate={setClearDate}
        clearDate={clearDate}
        setPreviousPage={setPreviousPage}
        previousPage={previousPage}
        loadingDep={loadingDep}
        page={page}
        count={count}
        statePage={statePage}
        loadMoreData={loadMoreData}
        moreLoading={moreLoading}
        setMoreLoading={setMoreLoading}
        moreLoadingCity={moreLoadingCity}
        setMoreLoadingCity={setMoreLoadingCity}
        getStateList={getStateList}
        getCityList={getCityList}
        getSectorList={getSectorList}
        getSingleIndustryList={getSingleIndustryList}
        setSingleIndustryLoading={setSingleIndustryLoading}
        getSingleSectorList={getSingleSectorList}
      />
    </SectionWrapper>
  )
}
export default Reports
