import React, { useState } from 'react'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import {
  StyledTab,
  SubmitFlex,
  MenuFlex,
} from 'modules/Dashboard/Partials/UsersFilter/Styles/style'
import Button from 'components/Button/index'
import LocationFilter from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/InstituteFilter/Partials/LocationFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import StatusFilter from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/InstituteFilter/Partials/StatusFilter'

const InstituteFilter = ({
  instituteList,
  currentPage,
  searchValue,
  setSearchValue,
  userRoleValue,
  instituteStatus,
  setInstituteStatus,
  getUserData,
  setState,
  location,
  setLocation,
  setFilterData,
  filterData,
  clearInput
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      role: userRoleValue,
      status: instituteStatus,
      location: location,
    })
    getUserData()
    setState(instituteStatus || 'all')
  }

  const onCancelFilter = open => {
    if (isMenuOpen === true) {
      setInstituteStatus(filterData.status)
      setLocation(filterData.location)
      setSearch('')
    }
    setIsMenuOpen(!isMenuOpen)
  }

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'Location',
            key: '1',
            children: (
              <LocationFilter
                location={location}
                setLocation={setLocation}
                setSearch={setSearch}
                search={search}
                loading={loading}
                setLoading={setLoading}
              />
            ),
          },
          {
            label: 'Status',
            key: '2',
            children: (
              <StatusFilter
                instituteStatus={instituteStatus}
                setInstituteStatus={setInstituteStatus}
              />
            ),
          },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )
  return (
    <TableSearchFilter
      placeholder={'Search by Institute Name'}
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      setSearchValue={setSearchValue}
      currentCount={10 * currentPage + instituteList?.result?.length}
      totalCount={instituteList?.count}
      onOpenChange={onCancelFilter}
      clearInput={clearInput}
    />
  )
}

export default InstituteFilter
