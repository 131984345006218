import styled from 'styled-components'

const Divider = styled.div`
  margin-top: ${props => (props.mt ? props.mt : '25px')};
  width: 100%;
  height: 1px;
  background-color: #e6e7ea;
`

export default Divider
