import React from 'react'

const CourseIcon = () => {
  return (
    <svg
      width="19"
      height="13"
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      <path
        d="M1 2C0.716667 2 0.479333 1.90433 0.288 1.713C0.0960001 1.521 0 1.28333 0 1C0 0.716667 0.0960001 0.479 0.288 0.287C0.479333 0.0956668 0.716667 0 1 0H11C11.2833 0 11.521 0.0956668 11.713 0.287C11.9043 0.479 12 0.716667 12 1C12 1.28333 11.9043 1.521 11.713 1.713C11.521 1.90433 11.2833 2 11 2H1ZM1 6C0.716667 6 0.479333 5.904 0.288 5.712C0.0960001 5.52067 0 5.28333 0 5C0 4.71667 0.0960001 4.479 0.288 4.287C0.479333 4.09567 0.716667 4 1 4H11C11.2833 4 11.521 4.09567 11.713 4.287C11.9043 4.479 12 4.71667 12 5C12 5.28333 11.9043 5.52067 11.713 5.712C11.521 5.904 11.2833 6 11 6H1ZM1 10C0.716667 10 0.479333 9.904 0.288 9.712C0.0960001 9.52067 0 9.28333 0 9C0 8.71667 0.0960001 8.479 0.288 8.287C0.479333 8.09567 0.716667 8 1 8H7C7.28333 8 7.521 8.09567 7.713 8.287C7.90433 8.479 8 8.71667 8 9C8 9.28333 7.90433 9.52067 7.713 9.712C7.521 9.904 7.28333 10 7 10H1ZM12.65 12.3L10.5 10.15C10.3167 9.96667 10.225 9.73333 10.225 9.45C10.225 9.16667 10.3167 8.93333 10.5 8.75C10.6833 8.56667 10.9127 8.475 11.188 8.475C11.4627 8.475 11.7 8.56667 11.9 8.75L13.35 10.15L16.9 6.6C17.0833 6.41667 17.3123 6.325 17.587 6.325C17.8623 6.325 18.1 6.425 18.3 6.625C18.5 6.825 18.596 7.06667 18.588 7.35C18.5793 7.63333 18.475 7.875 18.275 8.075L14.05 12.3C13.8667 12.4833 13.6333 12.575 13.35 12.575C13.0667 12.575 12.8333 12.4833 12.65 12.3Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default CourseIcon
