import adminRequest from 'utils/adminRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'

export const setEventList = data => {
  return {
    type: 'SET_EVENT_LIST',
    data,
  }
}

export const setNotificationType = data => {
  return {
    type: 'SET_NOTIFICATION_TYPE',
    data,
  }
}

export const setNotificationTypeByID = data => {
  return {
    type: 'SET_NOTIFICATION_TYPE_BY_ID',
    data,
  }
}
export const setAllEventTemplate = data => {
  return {
    type: 'SET_ALL_EVENT_TEMPLATE',
    data,
  }
}

export const setNotificationCatalogues = data => {
  return {
    type: 'SET_NOTIFICATION_CATALOGUES',
    data,
  }
}

export const getEventList = journey => async (dispatch, getState) => {
  let journeys = journey ? `&journey=${journey}` : ``

  try {
    const result = await adminRequest.get(
      `screens?pageLimit=500&pageNo=0${journeys}`
    )
    dispatch(setEventList(result?.data?.data))
    return result
  } catch (error) {
    console.log('Error in getting skills list =>', error?.message)
    ErrorMessage(error?.message)
    dispatch(setEventList([]))
  }
}

export const getNotificationType = () => async (dispatch, getState) => {
  try {
    const result = await adminRequest.get(
      `notificationType?pageLimit=10&pageNo=0`
    )
    dispatch(setNotificationType(result?.data?.data))
    return result
  } catch (error) {
    console.log('Error in getting skills list =>', error?.message)
    ErrorMessage(error?.message)
    dispatch(setNotificationType([]))
  }
}
export const getNotificationTypeByID = id => async (dispatch, getState) => {
  try {
    const result = await adminRequest.get(`notificationType/${id}`)
    dispatch(setNotificationTypeByID(result?.data?.data))
    return result
  } catch (error) {
    console.log('Error in getting skills list =>', error?.message)
    ErrorMessage(error?.message)
    dispatch(setNotificationTypeByID([]))
  }
}

export const getNotificationCatalogues =
  eventId => async (dispatch, getState) => {
    try {
      const result = await adminRequest.get(
        `notificationCatalogue?pageLimit=10&pageNo=0${eventId}`
      )
      dispatch(setNotificationCatalogues(result?.data?.data))
      return result
    } catch (error) {
      console.log('Error in getting skills list =>', error)
      //  ErrorMessage(error?.response?.data?.message)
      dispatch(setNotificationCatalogues([]))
    }
  }

export const AddNewNotificationTemplate =
  payload => async (dispatch, getState) => {
    try {
      const result = await adminRequest.post(`notificationTemplate`, payload)
      SuccessMessage('Template Added Successfully')
      return result
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)

      return error?.response
    }
  }
export const GetAllNotificationTemplate =
  (id, eventId) => async (dispatch, getState) => {
    let typeID = id ? `?notificationTypeId=${id}` : ` `
    try {
      const result = await adminRequest.get(
        `notificationTemplate${typeID}${eventId}`
      )
      dispatch(setAllEventTemplate(result?.data?.data))
      return result
    } catch (error) {
      console.log('Error in getting skills list =>', error?.message)
      ErrorMessage(error?.message)
      dispatch(setAllEventTemplate([]))
    }
  }
export const EditNewNotificationTemplate =
  (payload, id) => async (dispatch, getState) => {
    try {
      const result = await adminRequest.put(
        `notificationTemplate/${id}`,
        payload
      )
      SuccessMessage('Template Updated Successfully')
      return result
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)

      return error?.response
    }
  }

export const DeleteNewNotificationTemplate =
  id => async (dispatch, getState) => {
    try {
      const result = await adminRequest.delete(`notificationTemplate/${id}`)
      SuccessMessage('Template Deleted Successfully')
      return result
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)

      return error?.response
    }
  }

export const DeleteEvent = id => async (dispatch, getState) => {
  try {
    const result = await adminRequest.delete(`events/${id}`)
    SuccessMessage('Event Deleted Successfully')
    return result
  } catch (error) {
    ErrorMessage(error?.response?.data?.message)

    return error?.response
  }
}

export const DeleteSubEvent = id => async (dispatch, getState) => {
  try {
    const result = await adminRequest.delete(`subEvents/${id}`)
    SuccessMessage('Event Deleted Successfully')
    return result
  } catch (error) {
    ErrorMessage(error?.response?.data?.message)

    return error?.response
  }
}

export const ManageNotificationCatalogue =
  payload => async (dispatch, getState) => {
    try {
      const result = await adminRequest.post(`notificationCatalogue`, payload)
      SuccessMessage('Event Mapped with Channel Successfully')
      return result
    } catch (error) {
      ErrorMessage('Please Select The Template')

      return error?.response
    }
  }

export const reorderEvent = payload => async (dispatch, getState) => {
  try {
    const result = await adminRequest.put(`screens`, payload)
    SuccessMessage('Event Reorder Successfully')
    return result
  } catch (error) {
    ErrorMessage(error?.response?.data?.message)

    return error?.response
  }
}
