import { Empty as antdEmpty } from 'antd'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const ScrollBar = styled.div`
  height: ${props => (props?.height ? props?.height : '100vh')};
  overflow: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc;
    display: none;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      display: block;
    }
  }
`

export const Empty = styled(antdEmpty)`
  padding: ${props => props?.padding};
  margin: ${props => props?.margin};
  .ant-empty-image {
    height: ${props => (props?.height ? props?.height : '100px')};
    margin-bottom: 8px;
  }
`

export const EmptyText = styled.div`
  color: ${COLORS.GREY_T_60};
  size: ${props => props.size};
`
