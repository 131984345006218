import { Checkbox, Spin } from 'antd'
import SearchInput from 'components/FilterSearchInput'
import React, { useCallback, useEffect, useState } from 'react'
import { FixedSizeList } from 'react-window'
const VirtualizedCheckboxGroup = ({
  options,
  value,
  setValue,
  valueParam = 'value',
  labelParam = 'label',
  setStateName,
  stateName,
  countryName,
  setCountryName,
  filterId,
  searchNotNeeded = false,
  setDegreeSearch,
  setCityName,
  parentId,
  saveById = false,
  data,
  content,
  check,
  getStateData,
  labelType,
  loading,
  setLoading,
}) => {
  const [filter, setFilter] = useState('')
  useEffect(() => {
    setFilter('')
  }, [parentId, check])

  const handleCheckboxChange = (optionValue, id) => {
    const newValue = [...value]

    const index = newValue.indexOf(saveById ? id : optionValue)

    if (index === -1) {
      newValue.push(saveById ? id : optionValue)
    } else {
      setFilter('')
      newValue.splice(index, 1)
    }

    handleChange(newValue)

    if (filterId) {
      // Toggle the id in the stateName array
      setStateName(prevState => {
        if (prevState.includes(id)) {
          return prevState.filter(item => item !== id)
        } else {
          return [...prevState, id]
        }
      })
    }

    //setFilter('')
  }

  const filteredOptions = options?.filter(
    option =>
      option?.[labelParam]?.toLowerCase().includes(filter.toLowerCase()) ||
      option?.[valueParam]?.toLowerCase().includes(filter.toLowerCase())
  )
  const handleChange = newValue => {
    setValue(newValue)
  }

  const search = e => {
    setFilter(e.target.value)
    setDegreeSearch(e.target.value)
    if (labelType) {
      delayToFetch(e.target.value, labelType)
      if (setLoading) {
        setLoading(true)
      }
    }
  }
 

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetch = useCallback(
    debounce((data, key) => {
      if (key == 'country') {
        //
      }
      if (key == 'state') {
        if (getStateData) {
          getStateData('state', data)
        }
      }
      if (key == 'city') {
        if (getStateData) {
          getStateData('city', data)
        }
      }
    }, 1000),
    []
  )

  return (
    <div>
      {!searchNotNeeded && (
        <SearchInput
          placeholder="Search by keyword"
          value={filter}
          onChange={search}
        />
      )}
      {loading ? (
        <>
          {' '}
          <Spin />
          <> Loading...</>
        </>
      ) : filteredOptions?.length === 0 ? (
        'No data Found'
      ) : (
        <div style={{ height: 200, overflowY: 'auto'}}>
        {filteredOptions?.map((option, index) => (
          <>
          <div key={index} style={{paddingBottom: '15px'}}>
            <Checkbox
              checked={value?.indexOf(option[saveById ? valueParam : labelParam]) !== -1}
              onChange={() =>
                handleCheckboxChange(option[labelParam], option[valueParam])
              }
            >
              {option[labelParam]}
            </Checkbox>
          </div>
          </>
        ))}
      </div>
      )}
    </div>
  )
}

export default VirtualizedCheckboxGroup
