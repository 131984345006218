import {
  SET_STUDENT_LIST_FOR_REPORT,
  SET_COURSE_LIST,
  SET_SPECIALISATION_LIST,
  SET_DEGREE_LIST,
  SET_COLLEGE_LIST,
  SET_PLACED_STUDENT_LIST,
  SET_STUDENT_SKILL_WISE,
  SET_STUDENT_COURSE_WISE,
  SET_CORPORATE_NAME_LIST,
  SET_SKILL_LIST,
  SET_EXCEL_DATA,
  SET_UNIVERSITY_DATA,
  SET_COLLEGE_DATA,
  SET_CITY_DATA,
  SET_SPECIALISATION_DATA,
  SET_DEGREE_DATA,
  SET_TIER_DATA,
  SET_CORPORATE_DATA,
  SET_SKILL_DATA,
  SET_STATE_STUDENT_DATA,
  SET_CITY_STUDENT_DATA,
} from 'modules/Reports/Partials/Students/actions'

const initialState = {
  getStudentState: {},
  getStudentCity: {},
  getStudentListForReport: {},
  getCollegeListForReport: {},
  getCourseListForReport: {},
  getDegreeListForReport: {},
  getSpecilisationListForReport: {},
  getPlacedStudentListForReport: {},
  getStudentSkillDetails: {},
  getStudentCourseDetails: {},
  getCorporateNameList: {},
  getSkillList: {},
  excelData: {},
  universityData: {},
  collegeData: {},
  specialisationData: {},
  cityData: {},
  degreeData: {},
  tierData: {},
  corporateData: {},
  skillData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CORPORATE_DATA:
      return {
        ...state,
        corporateData: action.data,
      }
    case SET_SKILL_DATA:
      return {
        ...state,
        skillData: action.data,
      }
    case SET_CITY_DATA:
      return {
        ...state,
        cityData: action.data,
      }
    case SET_TIER_DATA:
      return {
        ...state,
        tierData: action.data,
      }
    case SET_DEGREE_DATA:
      return {
        ...state,
        degreeData: action.data,
      }
    case SET_SPECIALISATION_DATA:
      return {
        ...state,
        specialisationData: action.data,
      }
    case SET_STUDENT_LIST_FOR_REPORT:
      return {
        ...state,
        getStudentListForReport: action.data,
      }
    case SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.data,
      }
    case SET_COLLEGE_DATA:
      return {
        ...state,
        collegeData: action.data,
      }
    case SET_UNIVERSITY_DATA:
      return {
        ...state,
        universityData: action.data,
      }
    case SET_COLLEGE_LIST:
      return {
        ...state,
        getCollegeListForReport: action.data,
      }
    case SET_COURSE_LIST:
      return {
        ...state,
        getCourseListForReport: action.data,
      }
    case SET_DEGREE_LIST:
      return {
        ...state,
        getDegreeListForReport: action.data,
      }
    case SET_SPECIALISATION_LIST:
      return {
        ...state,
        getSpecialisationListForReport: action.data,
      }
    case SET_PLACED_STUDENT_LIST:
      return {
        ...state,
        getPlacedStudentListForReport: action.data,
      }
    case SET_STUDENT_SKILL_WISE:
      return {
        ...state,
        getStudentSkillDetails: action.data,
      }
    case SET_STUDENT_COURSE_WISE:
      return {
        ...state,
        getStudentCourseDetails: action.data,
      }
    case SET_CORPORATE_NAME_LIST:
      return {
        ...state,
        getCorporateNameList: action.data,
      }
    case SET_SKILL_LIST:
      return {
        ...state,
        getSkillList: action.data,
      }
    case SET_STATE_STUDENT_DATA:
      return {
        ...state,
        getStudentState: action.data,
      }
    case SET_CITY_STUDENT_DATA:
      return {
        ...state,
        getStudentCity: action.data,
      }
    default:
      return state
  }
}
