import { StyledTable } from 'components/TableStyles/TableStyles'
import React, { useState } from 'react'

import { Empty, Spin } from 'antd'
import Pagination from 'components/Pagination'
import ProgressCircle from 'components/ProgressCircle'
import {
  RowNumberData,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { EmptyWrapper } from 'modules/Dashboard/Styles/style'
import ChangeCorporateDetails from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/ChangeCorporateDetails'
import ActionDropdown from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/CorporateTable/ActionDropdown'
import RequestSentDrawer from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/RequestSentDrawer'
import VerifyCorporateDrawer from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer'
import ViewCorporateDrawer from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/ViewCorporateDrawer'
import { toCamelCase } from '../../../../../../../utils/camelCase'
import { fixedSorterColumn } from '../../../../../../../utils/constants'
import CorporateStatus from '../CorporateStatus'

const CorporateTable = ({
  corporateList,
  getSingleCorporateData,
  setSortBy,
  loading,
  setCurrentPage,
  currentPage,
  updateLoading,
  getCorporatesListData,
  sortBy,
  setClearInput,
}) => {
  const [viewCorporateDrawer, setViewCorporateDrawer] = useState(false)
  const [verifyCorporateDrawer, setVerifyCorporateDrawer] = useState(false)
  const [changeCorporateDetails, setChangeCorporateDetails] = useState(false)
  const [requestSentDrawer, setRequestSentDrawer] = useState(false)

  const onSorting = (pagination, row, sorter) => {
    let order = ''
    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    setSortBy(order)
  }
  const onPageChange = num => {
    setCurrentPage(num - 1)
  }

  const columns = [
    {
      title: 'CORPORATE NAME',
      dataIndex: 'name',
      key: 'corporate_name',
      sorter: true,
      showSorterTooltip: fixedSorterColumn(sortBy),
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.name ? toCamelCase(rowData?.name) : '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'role_id',
      key: 'role_id',
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.city ? toCamelCase(rowData?.city) : '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (_, rowData) => (
        <RowNumberData>
          <CorporateStatus status={rowData?.status} />
        </RowNumberData>
      ),
    },
    {
      title: 'COMP.RATE',
      dataIndex: 'comp.rate',
      key: 'comp.rate',
      render: (_, rowData) => (
        <RowNumberData>
          <ProgressCircle
            progressRate={rowData?.profileCompletionPerc}
            width="30px"
          />
        </RowNumberData>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          verifyCorporateDrawer={verifyCorporateDrawer}
          setVerifyCorporateDrawer={setVerifyCorporateDrawer}
          changeCorporateDetails={changeCorporateDetails}
          setChangeCorporateDetails={setChangeCorporateDetails}
          setViewCorporateDrawer={setViewCorporateDrawer}
          getSingleCorporateData={getSingleCorporateData}
        />
      ),
    },
  ]

  return (
    <>
      <Wrapper>
        <StyledTable
          columns={columns}
          pagination={false}
          dataSource={corporateList?.list}
          onChange={onSorting}
          locale={{
            emptyText: (
              <EmptyWrapper center isSpin={loading}>
                {!loading ? (
                  <Empty description={'No corporate found!'} />
                ) : (
                  <Spin />
                )}
              </EmptyWrapper>
            ),
          }}
        />

        <Pagination
          onChange={onPageChange}
          totalPages={corporateList?.count}
          pageSize={10}
          current={currentPage}
        />
      </Wrapper>

      <ViewCorporateDrawer
        viewCorporateDrawer={viewCorporateDrawer}
        setViewCorporateDrawer={setViewCorporateDrawer}
        verifyCorporateDrawer={verifyCorporateDrawer}
        setVerifyCorporateDrawer={setVerifyCorporateDrawer}
        updateLoading={updateLoading}
      />

      <VerifyCorporateDrawer
        verifyCorporateDrawer={verifyCorporateDrawer}
        setVerifyCorporateDrawer={setVerifyCorporateDrawer}
        changeCorporateDetails={changeCorporateDetails}
        setChangeCorporateDetails={setChangeCorporateDetails}
        setClearInput={setClearInput}
      />

      <ChangeCorporateDetails
        changeCorporateDetails={changeCorporateDetails}
        setChangeCorporateDetails={setChangeCorporateDetails}
        requestSentDrawer={requestSentDrawer}
        setRequestSentDrawer={setRequestSentDrawer}
      />

      <RequestSentDrawer
        requestSentDrawer={requestSentDrawer}
        setRequestSentDrawer={setRequestSentDrawer}
        getCorporatesListData={getCorporatesListData}
        currentPage={currentPage}
      />
    </>
  )
}

export default CorporateTable
