import React, { useCallback } from 'react'

import { TwoFields } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import LinkIcon from 'components/icons/LinkIcon'
import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'

import SuccessMessage from 'utils/SuccessMessage'
import Avatar from 'components/Avatar'
import CONSTANTS from 'utils/constants'

const InstitutesDetails = ({
  onCountryChange,

  countryList,
  stateList,
  cityList,

  onStateChange,
  onCityChange,

  isTemAddress,
  isSameAddress,
  countryValue,
  stateValue,
  cityValue,
  countryId,
  stateId,
  getStateData,
  getCityData,
  setSearchCountry,
  isSystemConfig,
  createEditForm,
  onKeyPress,
}) => {
  const copyLink = () => {
    let copyText = document.getElementById('WebsiteLink')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const delayStateToDb = useCallback(
    debounce((data1, data2, data3) => {
      getStateData(data1, data2, data3)
      createEditForm.setFieldsValue({
        state: '',
        stateId: '',
      })
    }, 500),
    []
  )

  const delayCitiesToDb = useCallback(
    debounce((data1, data2, data3, data4) => {
      getCityData(data1, data2, data3, data4)
      createEditForm.setFieldsValue({
        city: '',
        cityId: '',
      })
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  return (
    <>
      {!isSystemConfig ? (
        <AntdInput
          width="100%"
          label="College Name"
          placeholder="Eg.IIAM"
          optional={'*'}
          name={'collegeName'}
          rules={[
            {
              required: true,
              pattern: CONSTANTS.REGEX.NAME,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('college name'),
            },
          ]}
        />
      ) : (
        <AntdInput
          width="100%"
          label="University Name"
          placeholder="Eg.Anna University"
          optional={'*'}
          name={'name'}
          rules={[
            {
              required: true,
              pattern: CONSTANTS.REGEX.NAME,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('University name'),
              validator: (_, value) =>
                isNaN(value)
                  ? Promise.resolve()
                  : Promise.reject(new Error('Value must not be a number')),
            },
          ]}
        />
      )}

      <AntdInput
        id={'WebsiteLink'}
        label="Website *"
        placeholder="Eg. www.google.com"
        width="100%"
        name={'website'}
        // type='url'
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('website'),
          },
          // {
          //   pattern: CONSTANTS.REGEX.WEBSITE,
          //   message: CONSTANTS.ERROR_MESSAGE.VALID('website'),
          // },
        ]}
        onChange={event => {
          createEditForm.setFieldsValue({
            website: event.target.value.trim(),
          })
        }}
        suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
      />

      <TwoFields spaceBetween>
        {/* <AntdSelect
          width="100%"
          label="Country *"
          placeholder="Eg.India"
          optional={'*'}
          name={'country'}
          rules={[
            {
              validator: async (_, value) => {
                if (!createEditForm.getFieldValue(['country']) ||
                  !createEditForm.getFieldValue(['countryId'])
                ) {
                  return Promise.reject('Please select country!')
                }
                return Promise.resolve()
              },
            },           
          ]}
          OptionData={countryList}
          valueParam="name"
          nameParam="name"
          onChange={onCountryChange}
          showSearch={true}
          // disabled={isSystemConfig ? true : false}
          onSearch={data => delayCountryToFetch(data)}
          value={createEditForm.getFieldValue(['country'])}
        /> */}
        <AntdSelect
          width="100%"
          label="Country *"
          placeholder="Eg.India"
          name={'country'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('Country'),
            },
          ]}
          filterOption={false}
          OptionData={countryList}
          valueParam="name"
          nameParam="name"
          onChange={onCountryChange}
          showSearch={true}
          onSearch={data => {
            setSearchCountry(data)
          }}
          allowClear={true}
          value={countryId ? createEditForm.getFieldValue(['country']) : ''}
        />
        <AntdSelect
          width="100%"
          label="State *"
          placeholder="Eg.Tamil Nadu"
          optional={'*'}
          name={'state'}
          rules={[
            {
              validator: async (_, value) => {
                if (!createEditForm.getFieldValue(['state'])) {
                  return Promise.reject(new Error('Please select state!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={stateList}
          valueParam="name"
          nameParam="name"
          onChange={onStateChange}
          showSearch={true}
          disabled={createEditForm.getFieldValue(['country']) === ''}
          onSearch={data => delayStateToDb(countryId, false, data)}
          value={createEditForm.getFieldValue(['state'])}
          allowClear={true}
          filterOption={false}
        />
      </TwoFields>

      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="City *"
          placeholder="Eg.Chennai"
          optional={'*'}
          name={'city'}
          rules={[
            {
              validator: async (_, value) => {
                if (!createEditForm.getFieldValue(['city'])) {
                  return Promise.reject(new Error('Please select city!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={cityList}
          valueParam="name"
          nameParam="name"
          onChange={onCityChange}
          showSearch={true}
          disabled={
            createEditForm.getFieldValue(['state']) === '' ||
            createEditForm.getFieldValue(['stateId']) === undefined
          }
          onSearch={data =>
            delayCitiesToDb(countryId, stateId, !isTemAddress, data)
          }
          value={createEditForm.getFieldValue(['city'])}
          allowClear={true}
        />
        {!isSystemConfig ? (
          <AntdInput
            width="100%"
            label="Zipcode"
            placeholder="Eg.604407"
            optional={'*'}
            name={'zipcode'}
            rules={[
              {
                required: true,
                message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('Zipcode'),
              },
              {
                pattern: CONSTANTS.REGEX.PIN_CODE,
                message: CONSTANTS.ERROR_MESSAGE.VALID('Zipcode'),
              },
            ]}
          />
        ) : (
          ''
        )}
      </TwoFields>
    </>
  )
}

export default InstitutesDetails
