import request from 'utils/instituteRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'
export const SET_INSTITUTE_LIST = 'institute/SET_INSTITUTE_LIST'
export const SET_DEPARTMENT_LIST = 'institute/SET_DEPARTMENT_LIST'
export const SET_SPECIALISATION_LIST = 'institute/SET_SPECIALISATION_LIST'
export const setInstituteList = data => {
  return {
    type: SET_INSTITUTE_LIST,
    data,
  }
}
export const setDepartmentList = data => {
  return {
    type: SET_DEPARTMENT_LIST,
    data,
  }
}
export const setSpecialisationList = data => {
  return {
    type: SET_SPECIALISATION_LIST,
    data,
  }
}
export const getInstituteListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
      dispatch(setInstituteList([]))
    }
    try {
      let degreeType = filterDatas?.type || ''
      let domain = filterDatas?.domain || ''
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      // let location = encodeURIComponent(filterDatas?.location || '')

      let status = filterDatas?.status?.toString()
      const Status =
        status?.toString() && filterDatas?.menu !== 'College'
          ? `&status=${Number(status)}`
          : ``
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&state=${state}` : ``
      const City = city ? `&city=${city}` : ``
      const Domain = domain ? `&domain=${domain}` : ``
      const DegreeType = degreeType
        ? `&degreeType=${
            degreeType === 'UG Diploma'
              ? 'DIPLOMA'
              : degreeType === 'PG Diploma'
              ? 'P_G_DIPLOMA'
              : degreeType
          }`
        : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const replacedDegree = DegreeType.replace('PG Diploma', 'P_G_DIPLOMA')

      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${replacedDegree}${Sort}${OrderBy}${State}${City}${Domain}${Status}${Active}`,
      }
      // url
      if (
        url == 'crud/college' ||
        url == 'crud/degree' ||
        url == 'crud/streams' ||
        url == 'crud/specialisation' ||
        url == 'crud/universities'
      ) {
        const response = await elasticSearchRequest(options)
        dispatch(setInstituteList(response?.data?.data || []))
      } else {
        const response = await request(options)
        dispatch(setInstituteList(response?.data?.data || []))
      }

      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setInstituteList([]))
        setLoading(false)
      }
    }
  }
export const getDepartmentListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      // const status =
      // userStatus?.length > 0 ? `&status=${userStatus ? 1 : 0}` : ''
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await request(options)
      dispatch(setDepartmentList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setDepartmentList([]))
        setLoading(false)
      }
    }
  }
export const getDepartmentListForSysConfigElastic =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      // const status =
      // userStatus?.length > 0 ? `&status=${userStatus ? 1 : 0}` : ''
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await elasticSearchRequest(options)
      dispatch(setDepartmentList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setDepartmentList([]))
        setLoading(false)
      }
    }
  }
export const getSpecialisationListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await request(options)
      dispatch(setSpecialisationList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setSpecialisationList([]))
        setLoading(false)
      }
    }
  }

export const getSpecialisationListForSysConfigElastic =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await elasticSearchRequest(options)
      dispatch(setSpecialisationList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setSpecialisationList([]))
        setLoading(false)
      }
    }
  }
export const createInstituteSysConfig =
  ({ url, type = 'post', value, id, isSuccess, success, setLoading }) =>
  async () => {
    console.log(value, type, 'type')
    if (setLoading) setLoading(true)
    try {
      const Id = id ? `/${id}` : ``
      const response = await request({
        method: type,
        url: `/institutes/${url}${Id}`,
        data: value,
      })

      function formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      }

      let payload = {
        query_date: formatDate(new Date()), // This will format today's date as "yyyy-mm-dd"
      }

      await elasticSearchSyncRequest.post(`/sync/universities_master`, payload)
      await elasticSearchSyncRequest.post(`/sync/institutes_master`, payload)
      await elasticSearchSyncRequest.post(`/sync/degree_data`, payload)
      await elasticSearchSyncRequest.post(`/sync/institute_degree`, payload)
      await elasticSearchSyncRequest.post(`/sync/institute_streams`, payload)
      await elasticSearchSyncRequest.post(
        `/sync/crud_degree_department`,
        payload
      )
      await elasticSearchSyncRequest.post(
        `/sync/institute_specialisation`,
        payload
      )
      await elasticSearchSyncRequest.post(
        `/sync/institute_campus_cources`,
        payload
      )
      await elasticSearchSyncRequest.post(`/sync/locationsDetails`, payload)
      await elasticSearchSyncRequest.post(`/sync/institute_locations`, payload)

      SuccessMessage(response?.data?.message)
      isSuccess(!success)
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) setLoading(false)
      ErrorMessage(error?.response?.data?.message)
    }
  }

export const dropDownValues = async ({ url, type = 'get' }) => {
  const options = {
    method: type,
    url: `/institutes/${url}`,
  }
  const response = await request(options)

  return response?.data
}
