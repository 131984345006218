import Button from 'components/Button'
import Control from 'components/Control/Control'
import CorporateInfoForm from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/CorporateInfoForm'
import { FormWrapper } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import { getSectorBasedIndustry } from 'modules/Onboarding/Partials/Corporates/Register/actions.js'
import { deleteFile, getListOfCounties } from 'modules/Onboarding/actions'
import { getGeneralListForSysConfig } from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import { searchConfig } from 'utils/config'
import { searchAPI } from '../../../../actions'

const CorporateInfo = ({
  singleCorporateData,
  form,
  onSubmit,
  setProfileFile,
  profileFile,
  corporatePresentation,
  setCorporatePresentation,
  descriptiona,
}) => {
  const dispatch = new useDispatch()
  const [activeIndustry, setActiveIndustry] = useState([])
  const [industrySearchList, setIndustrySearchList] = useState([])
  const [sectorSearchList, setSectorSearchList] = useState([])
  const [sectorsId, setSectorId] = useState('')
  const [sectorMapId, setSectorMapId] = useState('')
  const [industry, setIndustry] = useState('')

  const [isHeadOffice, setIsHeadOffice] = useState(false)
  useEffect(() => {
    if (singleCorporateData?.isHeadOffice) {
      setIsHeadOffice(singleCorporateData?.isHeadOffice)
    }
  }, [singleCorporateData])

  // const onIndustryChange = (data,option) => {
  //   // setIndustry(data)
  //   // setIndustryId(option?.option?.id)
  //   // form.setFieldsValue({
  //   //   industry: data,
  //   //   industryId: option?.option?.id,
  //   // })
  //   if (data?.toUpperCase() === 'OTHERS' ){
  //     setIndustry(null)
  //     form.setFieldsValue({[addIndustry: 'OTHERS']})
  //     form.setFieldsValue({[industry:'']})
  //   }
  //   else{
  //     setIndustry(data)
  //     form.setFieldsValue({[addIndustry: null]})
  //   }
  //   form.setFieldsValue({[industry: option?.option?.name]})
  // }

  const onIndustryChange = (data, option) => {
    if (data?.toUpperCase() === 'OTHERS') {
      setIndustry(null)
      form.setFieldsValue({
        addIndustry: 'OTHERS',
        industry: '',
      })
    } else {
      setIndustry(data)
      form.setFieldsValue({ addIndustry: null, industry: option?.option?.name })
    }
    // form.setFieldsValue({ industry: option?.option?.name })
  }

  useEffect(() => {
    if (industrySearchList.length === 0) {
      setIndustrySearchList([
        {
          name: 'Others',
          id: 'a12c22d8-26ba-4970-8721-8dfeebc2889a',
          isActive: 'true',
        },
        ...industrySearchList,
      ])
    }
  }, [industrySearchList])

  useEffect(() => {
    dispatch(getListOfCounties())
  }, [])
  useEffect(() => {
    if (sectorsId) {
      let sectorId = sectorsId
      dispatch(getSectorBasedIndustry({ sectorId }))
    }
  }, [sectorsId])

  const sectorList = useSelector(
    state => state?.GeneralSystemConfig?.getGeneralListForSysConfig
  )

  const industryList = useSelector(state => state?.CorporateData?.industryList)

  useEffect(() => {
    dispatch(
      searchAPI(searchConfig?.industries?.name, '', searchConfig?.industries)
    )
      .then(results => setIndustrySearchList(results))
      .catch(error =>
        console.error(
          `Error searching ${searchConfig?.industries?.name}:`,
          error
        )
      )
  }, [industry])

  useEffect(() => {
    handleCall()
  }, [])

  const handleCall = () => {
    dispatch(searchAPI(searchConfig?.sector?.name, '', searchConfig?.sector))
      .then(results => setSectorSearchList(results))
      .catch(error =>
        console.error(`Error searching ${searchConfig?.sector?.name}:`, error)
      )
  }

  const onRemoveImage = async () => {
    let response = await dispatch(deleteFile(profileFile))
    if (response) {
      setProfileFile(null)
      form.setFieldsValue({ profileUrl: '' })
      SuccessMessage('File deleted successfully!')
    } else {
      ErrorMessage('Failed to delete the file!')
    }
  }

  useEffect(() => {
    form.setFieldsValue({ profileUrl: profileFile })
    if (sectorMapId !== '') {
      form.setFieldsValue({ sectorIndustryMapId: sectorMapId })
    }
  }, [profileFile, sectorMapId])

  const OncheckBoxChange = ({ target }) => {
    setIsHeadOffice(target?.checked)
    form.setFieldsValue({ isHeadOffice: target?.checked })
  }

  return (
    <FormWrapper
      scrollToFirstError
      onFinish={onSubmit}
      id={'corporateForm'}
      form={form}
      layout="vertical"
    >
      <CorporateInfoForm
        onIndustryChange={onIndustryChange}
        setIndustry={setIndustry}
        description={descriptiona}
        form={form}
        file={profileFile}
        setFile={setProfileFile}
        industryList={industryList}
        sectorList={sectorList}
        activeIndustry={activeIndustry}
        onRemoveImage={onRemoveImage}
        getGeneralListForSysConfig={getGeneralListForSysConfig}
        singleCorporateData={singleCorporateData}
        corporatePresentation={corporatePresentation}
        setCorporatePresentation={setCorporatePresentation}
        getSectorBasedIndustry={getSectorBasedIndustry}
        setSectorId={setSectorId}
        sectorsId={sectorsId}
        setSectorMapId={setSectorMapId}
        sectorMapId={sectorMapId}
        OncheckBoxChange={OncheckBoxChange}
        isHeadOffice={isHeadOffice}
        setActiveIndustry={setActiveIndustry}
        industryValue={industry}
        setIndustrySearchList={setIndustrySearchList}
        industrySearchList={industrySearchList}
        setSectorSearchList={setSectorSearchList}
        sectorSearchList={sectorSearchList}
        handleCall={handleCall}
      />

      <Control top="25px" flexEnd>
        <Button.Primary text="Save and Continue" htmlType={'submit'} />
      </Control>
    </FormWrapper>
  )
}

export default CorporateInfo
