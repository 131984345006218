import React from 'react'

const MailInvite = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M20.75 4H4.75C3.65 4 2.76 4.9 2.76 6L2.75 18C2.75 19.1 3.65 20 4.75 20H20.75C21.85 20 22.75 19.1 22.75 18V6C22.75 4.9 21.85 4 20.75 4ZM20.75 18H4.75V8L12.75 13L20.75 8V18ZM12.75 11L4.75 6H20.75L12.75 11Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default MailInvite
