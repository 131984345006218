import authReducer from 'modules/Auth/reducers'
import coursesReducer from 'modules/Courses/reducers'
import EventCatalogueReducer from 'modules/EventCatalogue/reducers'
import RankingAlgorithmReducer from 'modules/RankingAlgorithm/reducers'
import corporateListReducer from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/reducers'
import CorporateOnboardingReducer from 'modules/Onboarding/Partials/Corporates/Register/reducers'
import corporateReducer from 'modules/Onboarding/Partials/Corporates/reducers'
import instituteListReducer from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/reducers'
import InstituteOnboardingReducer from 'modules/Onboarding/Partials/Institutes/Register/reducers'
import instituteReducer from 'modules/Onboarding/Partials/Institutes/reducers'
import { onboarding } from 'modules/Onboarding/reducers'
import InstituteReports from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/reducers'
import StudentReducer from 'modules/Reports/Partials/Students/reducers'
import Reports from 'modules/Reports/reducers'
import GeneralSysConfigReducer from 'modules/Systemconfig/Partials/GeneralTableSettings/reducers'
import instituteSysConfigReducer from 'modules/Systemconfig/Partials/InstituteSettings/reducers'
import PermissionSettingsReducer from 'modules/Systemconfig/Partials/PermissionSettings/reducers'
import sysSettings from 'modules/Systemconfig/reducers'
import userViewReducers from 'modules/User/Partials/UsersView/reducers'
import userMetrics, { default as eventReducer, default as roleReducer, default as userReducers } from 'modules/User/reducers'
import { combineReducers } from 'redux'
import { Corporates } from '../modules/Corporates/reducers'
import { Institutes } from '../modules/Institutes/reducers'
import CompanySysConfigReducer from '../modules/Systemconfig/Partials/CorporateSettings/reducers'

export default combineReducers({
  auth: authReducer,
  formData: corporateReducer,
  InstituteFormData: instituteReducer,
  user: userReducers,
  userView: userViewReducers,
  role: roleReducer,
  CorporateData: onboarding,
  CorporateList: corporateListReducer,
  InstituteOnboarding: InstituteOnboardingReducer,
  InstituteList: instituteListReducer,
  CorporateOnboarding: CorporateOnboardingReducer,
  report: Reports,
  InstituteReports: InstituteReports,
  StudentReducer: StudentReducer,
  event: eventReducer,
  userMetrics: userMetrics,
  sysSettings: sysSettings,
  InstituteSystemConfig: instituteSysConfigReducer,
  GeneralSystemConfig: GeneralSysConfigReducer,
  CompanySystemConfig: CompanySysConfigReducer,
  UserInstituteList: Institutes,
  UserCorporateList: Corporates,
  courses: coursesReducer,
  EventCatalogue: EventCatalogueReducer,
  permissionSettings: PermissionSettingsReducer,
  RankingAlgorithmReducer: RankingAlgorithmReducer,
})
