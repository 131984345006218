import React, { useEffect, useState } from 'react'

import Flex from 'components/Flex'
import { SignIn } from 'modules/Auth/actions'
import LayoutWithNav from 'modules/Layouts/ContainerWithNav'
import LayoutWithoutNav from 'modules/Layouts/ContainerWithoutNav'
import { useDispatch } from 'react-redux'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { authenticatedWithNav, authenticatedWithoutNav } from '../config/user'

const { AUTH_URL } = process.env

const PageFlex = styled(Flex)`
  // overflow: hidden;
`

const AuthPage = ({ isAuthenticated, permissions }) => {
  const navigate = useNavigate()
  const dispatch = new useDispatch()
  const [searchParams] = useSearchParams()
  const ddd = window.location.pathname
  const [isIdle, setIsIdle] = useState(false)
  const [landing, setLanding] = useState('')

  useEffect(() => {
    let idleTimer

    const resetTimer = () => {
      clearTimeout(idleTimer)
      idleTimer = setTimeout(() => setIsIdle(true), 900000)
    }

    const onActivity = () => {
      setIsIdle(false)
      resetTimer()
    }

    resetTimer()

    // Add event listeners to track activity
    window.addEventListener('mousemove', onActivity)
    window.addEventListener('keydown', onActivity)
    window.addEventListener('scroll', onActivity)

    // Clean up event listeners on component unmount
    return () => {
      clearTimeout(idleTimer)
      window.removeEventListener('mousemove', onActivity)
      window.removeEventListener('keydown', onActivity)
      window.removeEventListener('scroll', onActivity)
    }
  }, [])

  useEffect(() => {
    if (isIdle) {
      window.location.href = AUTH_URL
      window.location.replace(AUTH_URL)
    }
  }, [isIdle])

  useEffect(() => {
    const authToken = searchParams.get('token')
    const LandingPage = searchParams.get('landingPage')
    setLanding(LandingPage)
    dispatch(SignIn(authToken))
    if (!authToken && !isAuthenticated) {
      window.open(AUTH_URL, '_self')
    }
  }, [])

  useEffect(() => {
    if (ddd === '/signin') {
      if (landing !== '' && landing) {
        navigate(landing)
      } else {
        navigate('/onboarding')
      }
    } else if (isAuthenticated) {
      if (ddd === '/') {
        if (landing !== '' && landing) {
          navigate(landing)
        } else {
          navigate('/onboarding')
        }
      } else if (landing !== '' && landing) {
        navigate(landing)
      } else {
        navigate(ddd)
      }
    }
  }, [isAuthenticated, landing])

  return (
    <PageFlex>
      <Routes>
        {authenticatedWithNav.map(({ routePath, Component }) => {
          return (
            isAuthenticated && (
              <Route
                key={routePath}
                path={routePath}
                element={
                  <LayoutWithNav>
                    <Component />
                  </LayoutWithNav>
                }
              ></Route>
            )
          )
        })}
        {authenticatedWithoutNav.map(({ routePath, Component }) => {
          return (
            <Route
              key={routePath}
              path={routePath}
              element={
                <LayoutWithoutNav>
                  <Component />
                </LayoutWithoutNav>
              }
            ></Route>
          )
        })}
      </Routes>
    </PageFlex>
  )
}

export default AuthPage
