import React from 'react'

const LeftArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 15.3L0.275 8.69996C0.175 8.59996 0.104 8.49163 0.0619998 8.37496C0.0206665 8.25829 0 8.13329 0 7.99996C0 7.86663 0.0206665 7.74163 0.0619998 7.62496C0.104 7.50829 0.175 7.39996 0.275 7.29996L6.875 0.69996C7.05833 0.516627 7.28733 0.420627 7.562 0.411961C7.83733 0.403961 8.075 0.49996 8.275 0.69996C8.475 0.883294 8.57933 1.11229 8.588 1.38696C8.596 1.66229 8.5 1.89996 8.3 2.09996L3.4 6.99996H14.575C14.8583 6.99996 15.096 7.09563 15.288 7.28696C15.4793 7.47896 15.575 7.71663 15.575 7.99996C15.575 8.28329 15.4793 8.52063 15.288 8.71196C15.096 8.90396 14.8583 8.99996 14.575 8.99996H3.4L8.3 13.9C8.48333 14.0833 8.57933 14.3166 8.588 14.6C8.596 14.8833 8.5 15.1166 8.3 15.3C8.11667 15.5 7.88333 15.6 7.6 15.6C7.31667 15.6 7.075 15.5 6.875 15.3Z"
        fill="#CCCFD6"
      />
    </svg>
  )
}

export default LeftArrow
