import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import React from 'react'
import { Form } from '../../../../../Corporates/Style/style'
import { FlexWrap } from '../../../../../User/Partials/AddUserDrawer/Partials/AddUserForm/styles'
import DrawerForm from './Partials'

const ScreenDrawer = ({
  form,
  drawer,
  inputs,
  onClose,
  setInputs,
  handleJourneyChange,
  handleSubmitCongif,
  view,
  toEditId,
  loading = false,
}) => {
  const content = () => {
    return (
      <div style={{ pointerEvents: view ? 'none' : '' }}>
        {loading && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          id={'DrawerForm'}
          form={form}
          layout="vertical"
          onFinish={handleSubmitCongif}
          initialValues={{
            screens: [{ screens: '' }],
          }}
        >
          <DrawerForm
            form={form}
            toEditId={toEditId}
            handleJourneyChange={handleJourneyChange}
            inputs={inputs}
            setInputs={setInputs}
            handleSubmitCongif={handleSubmitCongif}
          />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" height="38px" onClick={onClose} />
        <Button.Primary
          text={view ? 'Update' : 'Confirm'}
          htmlType="submit"
          form={'DrawerForm'}
          disabled={view}
          icon={<CheckIconWhite />}
          height="38px"
          loading={loading}
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={view ? 'Preview' : toEditId ? 'Edit Screens' : 'Add Screens'}
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={drawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ScreenDrawer
