import CustomTag from 'components/CustomTag'
import React from 'react'

const InstituteStatus = ({ status }) => {
  let colorType
  let tagMessage
  if (status == '0') {
    colorType = 'orange'
    tagMessage = 'Veri.Pending'
  } else if (status == '1') {
    colorType = 'green'
    tagMessage = 'Onboarding'
  } else if (status == '2') {
    colorType = 'grey'
    tagMessage = 'Req. To Change'
  }
  return (
    <CustomTag colorType={colorType} message={tagMessage} bordered={false} />
  )
}

export default InstituteStatus
