import { Spin } from 'antd'
import Flex from 'components/Flex'
import SearchInput from 'components/SearchInput'
import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import FilterCheckBoxComp from 'components/UIComponents/ReportSearchFilter/FilterCheckBox/FilterCheckBox.js'
import {
  Container,
  Contents,
  FilterHeader,
  FilterText,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
const CityFilter = ({
  cityValue,
  setCityValue,
  cityList,
  setKeyCity,
  loadingCity,
  cityCount,
  loadMoreCityData,
  cityPage,
  flag,
  setFlag,
}) => {
  const [search, setSearch] = useState('')
  const [state, setState] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    let finalData = []

    if (cityPage == 0) {
      finalData = cityList
    } else {
      finalData = [...state, ...cityList]
    }

    setState(finalData)
  }, [cityList])
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyCity(search)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const searchFilter = e => {
    setSearch(e)
  }

  const onChange = checkedvalue => {
    let indexToRemove = cityValue?.indexOf(...checkedvalue)
    let cityCopy = [...cityValue]
    if (indexToRemove !== -1) {
      cityCopy.splice(indexToRemove, 1)
      setCityValue([...cityCopy])
    } else {
      setCityValue([...cityValue, ...checkedvalue])
    }
  }

  const Spinner = (
    <>
      {!cityList?.length && loadingCity ? (
        <Spin />
      ) : state >= 0 && !loadingCity ? (
        <RowNumberData>No Data Found</RowNumberData>
      ) : (
        ''
      )}
    </>
  )

  return (
    <Contents>
      <FilterHeader>
        <FilterText>Location</FilterText>
        <SearchInput
          background="#F8F8F8"
          width={'100%'}
          placeholder="Search by City"
          bordered={false}
          flag={flag}
          setFlag={setFlag}
          setSearchValue={searchFilter}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </FilterHeader>
      <Container>
        {state?.length > 0 ? (
          <p>
            <InfiniteScroll
              key={cityList}
              dataLength={state?.length}
              height="18vh"
              next={loadMoreCityData}
              hasMore={state.length < cityCount}
              loader={
                <Flex center>
                  <Spin small />
                </Flex>
              }
            >
              <FilterCheckBoxComp
                options={state}
                value={cityValue}
                onChangeCheck={e => onChange([e.target.value])}
              />
            </InfiniteScroll>
          </p>
        ) : (
          <Flex center>{Spinner}</Flex>
        )}
      </Container>
    </Contents>
  )
}

export default CityFilter
