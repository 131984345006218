import React, { useEffect, useState } from 'react'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import SuccessMessage from 'utils/SuccessMessage'
import { Spinner } from '../../../Style/style'
import AddUserForm from './Partials/AddUserForm'
import { FlexWrap, Form } from './Partials/AddUserForm/styles'
import { removeDuplicatesFromArray } from '../../../../../utils/removeDuplicatesFromArray'
import selector from 'modules/Auth/selectors'
import {getListOfCountries} from 'modules/Auth/actions'
import { useDispatch } from 'react-redux'


const AddUserDrawer = ({
  form,
  roleList,
  addUserDrawer,
  setAddUserDrawer,
  userRole,
  singleUserData,
  corporateViewData,
  updateCorporate,
  updateLoading,
  handleUserJourneyChange,
  getSingleUser,
}) => {
  const [loading, setLoading] = useState(false)
  const [countryCodeSearch, setCountryCodeSearch] = useState('')
  const [countryCodeList, setCountryCodeList] = useState([])
  const dispatch = useDispatch()



  const authId = selector.getUserId()

  const getCurrency = async ({ setState, searchValue }) => {
    const { data, status } = await dispatch(getListOfCountries({
      phoneCodeSearch: searchValue,
      pageLimit: 500,
    }))

    if (status >= 200 && status <= 300) {
      setState(removeDuplicatesFromArray(data?.data?.countries, 'phone_code'))
    }
  }

  useEffect(() => {
    let getData
    if (countryCodeSearch) {
      getData = setTimeout(() => {
        getCurrency({
          setState: setCountryCodeList,
          searchValue: countryCodeSearch,
        })
      }, 500)
    } else {
      getCurrency({
        setState: setCountryCodeList,
        searchValue: countryCodeSearch,
      })
    }

    return () => clearTimeout(getData)
  }, [countryCodeSearch])


  useEffect(() => {
    form?.setFieldsValue({
      firstName: singleUserData?.firstName,
      lastName: singleUserData?.lastName,
      userType: singleUserData?.userType,
      adminRoleId: singleUserData?.adminRoleId,
      userEmail: singleUserData?.userEmail,
      email: singleUserData?.userEmail,
      userMobile: singleUserData?.userMobile,
      countryCode:singleUserData?.countryCode,
      phoneNumber: singleUserData?.userMobile,
    })
  }, [singleUserData])

  const onCreateUser = async value => {
    const roleIdfind = roleList?.find(Item => Item?.id === value?.userType)
    const updatepayload = {
      admin: {
        firstName: value?.firstName,
        lastName: value?.lastName,
        adminRoleId: roleIdfind?.id,
        authId: authId,
      },
      user: {
        userEmail: value?.email,
        userMobile: value?.phoneNumber,
        countryCode:value?.countryCode,
        userType: roleIdfind?.name,
        journey: roleIdfind?.journey,
      },
    }
    let response = await updateCorporate(singleUserData?.id, updatepayload)
    if (response?.status >= 200 && response?.status) {
      SuccessMessage('User updated successfully!')
      setLoading(false)
      setAddUserDrawer(false)
      form.resetFields()
    } else {
      setAddUserDrawer(false)
      setLoading(false)
    }
  }

  const onClose = () => {
    setAddUserDrawer(false)
  }

  const content = () => {
    return (
      <>
        {updateLoading && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          id={'addUserForm'}
          form={form}
          layout="vertical"
          onFinish={onCreateUser}
        >
          <AddUserForm
            roleList={roleList}
            singleUserData={singleUserData}
            form={form}
            setCountryCodeSearch={setCountryCodeSearch}
            countryCodeList={countryCodeList}

          />
        </Form>
      </>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" height="38px" onClick={onClose} />
        <Button.Primary
          text={'Update'}
          htmlType="submit"
          form={'addUserForm'}
          icon={<CheckIconWhite />}
          height="38px"
          loading={loading}
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={'Edit User'}
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={addUserDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddUserDrawer
