import ErrorMessage from 'components/UIComponents/MessagePopup/ErrorMessage'
import selector from 'modules/Auth/selectors.js'
import SuccessMessage from 'utils/SuccessMessage'
import adminRequest from 'utils/adminRequest'

export const SET_USER_METRICS = 'corporate/SET_USER_METRICS'
export const SET_USER_LIST = 'corporate/SET_USER_LIST'
export const SET_SINGLE_USER = 'corporate/SET_SINGLE_USER'
export const SET_ROLE_LIST = 'corporate/SET_ROLE_LIST'
export const SET_EVENT = 'corporate/SET_EVENT'
export const SET_ROLE = 'corporate/SET_ROLE'
export const SET_SINGLE_ROLE = 'corporate/SET_SINGLE_ROLE'

export const setUserMetrics = data => {
  return {
    type: SET_USER_METRICS,
    data,
  }
}

export const setRoleList = data => {
  return {
    type: SET_ROLE_LIST,
    data,
  }
}
export const setRoles = roles => {
  return {
    type: SET_ROLE,
    roles,
  }
}

export const setEvent = data => {
  return {
    type: SET_EVENT,
    data,
  }
}

export const setUserList = data => {
  return {
    type: SET_USER_LIST,
    data,
  }
}

export const setSingleUser = data => {
  return {
    type: SET_SINGLE_USER,
    data,
  }
}

export const setSingleRole = data => {
  return {
    type: SET_SINGLE_ROLE,
    data,
  }
}

export const getUserMetrics =
  ({ searchValue, sortBy, userRoleValue, userStatus, pageNum = 0 }) =>
  async (dispatch, getState) => {
    try {
      const search = searchValue ? `&searchBy=${searchValue}` : ''
      const sortKey = sortBy?.columnKey ? `&sort=${sortBy?.columnKey}` : ''
      const order = sortBy?.order === 'ascend' ? 'asc' : 'desc'
      const sortOrder = sortBy?.order ? `&orderBy=${order}` : ''
      const userRole =
        userRoleValue?.[0]?.length > 0 ? `&role=${userRoleValue}` : ''
      const status = userStatus?.[0]?.length > 0 ? `&status=${userStatus}` : ''

      const response = await adminRequest.get(
        `/users?pageLimit=10&pageNo=${pageNum}${
          sortOrder && sortKey
        }${search}${userRole}${status}${sortOrder}`
      )
      dispatch(setUserMetrics(response?.data || {}))
      return response
    } catch (error) {
      return false
    }
  }

export const getRoleList =
  ({ searchValue, sortBy, roleJourney, userStatus, pageNum = 0 }) =>
  async (dispatch, getState) => {
    try {
      const search = searchValue ? `&searchBy=${searchValue}` : ''
      const sortKey = sortBy?.columnKey ? `&sort=${sortBy?.columnKey}` : ''
      const order = sortBy?.order === 'ascend' ? 'asc' : 'desc'
      const sortOrder = sortBy?.order ? `&order=${order}` : ''

      const roleFilter =
        roleJourney?.[0]?.length > 0 ? `&journey=${roleJourney}` : ''

      const status =
        userStatus?.length > 0 ? `&status=${userStatus ? 1 : 0}` : ''

      const response = await adminRequest.get(
        `/roles?pageLimit=10&pageNo=${pageNum}${
          sortOrder && sortKey
        }${search}${roleFilter}${status}${sortOrder}`
      )
      dispatch(setRoleList(response?.data || {}))
      return false
    } catch (error) {
      return false
    }
  }

export const getRoles =
  (
    journeyName,
    isActiveRole = false,
    isState = false,
    forInternalUser = false
  ) =>
  async (dispatch, getState) => {
    try {
      let response = await adminRequest.get(
        `/roles/journey/${journeyName?.toUpperCase()}?isActiveRole=${isActiveRole}&forInternalUser=${forInternalUser}`
      )
      if (!isState) {
        dispatch(setRoles(response?.data || {}))
      }

      return response
    } catch (error) {
      return error
    }
  }

export const getEvent = journey => async (dispatch, getState) => {
  try {
    if (journey) {
      let response = await adminRequest.get(`/permissions?journey=${journey}`)
      dispatch(setEvent(response?.data?.data || {}))

      return response?.data?.data
    }
  } catch (error) {
    return error
  }
}

export const getUserList =
  ({ searchValue, sortBy, userRoleValue, userStatus, pageNum }) =>
  async (dispatch, getState) => {
    try {
      const state = getState()
      const instituteId = selector.getInstituteCampusId(state)

      const search = searchValue ? `&search=${searchValue}` : ''

      const sortKey = sortBy?.columnKey ? `&sortBy=${sortBy?.columnKey}` : ''
      const order = sortBy?.order === 'ascend' ? 'asc' : 'desc'
      const sortOrder = sortBy?.order ? `&orderBy=${order}` : ''

      const userRole = userRoleValue?.length > 0 ? `&role=${userRoleValue}` : ''
      const status = userStatus?.length > 0 ? `&status=${userStatus}` : ''

      const response = await adminRequest.get(
        `/users?pageLimit=10&currentPage=${pageNum}&instituteId=${instituteId}${sortKey}${sortOrder}${search}${userRole}${status}`
      )
      dispatch(setUserList(response?.data?.data))
      return response
    } catch (error) {
      return false
    }
  }

export const createUser =
  ({ payload }) =>
  async (dispatch, getState) => {
    try {
      let updatedPayload = {
        ...payload,
        firstName: payload?.admin?.firstName?.trim(),
        lastName: payload?.admin?.lastName?.trim(),
      }
      const response = await adminRequest.post(`/users`, updatedPayload)
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }

export const updateUser =
  (userId, updatePayload) => async (dispatch, getState) => {
    const state = getState()
    const currentAuthId = selector.getUserId(state)
    try {
      let id = userId
      const payload = {
        ...updatePayload,
        admin: {
          ...updatePayload?.admin,
          firstName: updatePayload?.admin?.firstName?.trim(),
          lastName: updatePayload?.admin?.lastName?.trim(),
          authId: currentAuthId,
        },
      }
      const response = await adminRequest.put(`/users/${id}`, payload)
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }

export const createRoles =
  ({ payload, url = `/roles` }) =>
  async (dispatch, getState) => {
    try {
      const response = await adminRequest.post(url, payload)
      return response
    } catch (error) {
      // ErrorMessage(error?.response?.data?.message)

      return error
    }
  }
export const updateRoles =
  ({ roleId, payload, url }) =>
  async (dispatch, getState) => {
    try {
      const response = await adminRequest.put(
        url ?? `permissions/roles/${roleId}`,
        payload
      )
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)

      return error
    }
  }

export const getSingleUser =
  ({ userId }) =>
  async (dispatch, getState) => {
    try {
      let id = userId
      const response = await adminRequest.get(`/users/${id}`)
      dispatch(setSingleUser(response?.data?.data))
    } catch (error) {
      console.error('Getting error in single user', error)
    }
  }

export const getSingleRole =
  ({ roleId }) =>
  async (dispatch, getState) => {
    try {
      let id = roleId
      const response = await adminRequest.get(`/roles/${id}`)
      dispatch(setSingleRole(response?.data?.data))
      return response?.data?.data
    } catch (error) {
      console.error('Getting error in single role', error)
    }
  }

export const updateUserStatus =
  ({ userId, status }) =>
  async (dispatch, getState) => {
    try {
      const response = await adminRequest.patch(`/user/${userId}/status`, {
        status: status,
      })
      if (response?.status >= 200 && response?.status <= 300) {
        SuccessMessage(
          status
            ? 'User activated successfully!'
            : 'User inactivated successfully!'
        )
      }

      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }

export const deleteUser =
  ({ userId }) =>
  async (dispatch, getState) => {
    try {
      const response = await adminRequest.delete(`/user/${userId}`)
      if (response?.status >= 200 && response?.status <= 300) {
        SuccessMessage('User Deleted Successfully!')
      }
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }

export const deleteRolesAndPermission =
  ({ roleId }) =>
  async (dispatch, getState) => {
    try {
      const response = await adminRequest.delete(`/permissions/roles/${roleId}`)
      if (response?.status >= 200 && response?.status <= 300) {
        SuccessMessage('Role Deleted Successfully!')
      }
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }

export const updateUsersRole = payLoad => async (dispatch, getState) => {
  try {
    const response = await adminRequest.put(
      `/users/journey/roleUpdation`,
      payLoad
    )
    return response
  } catch (error) {
    ErrorMessage(error?.response?.data?.message)
    return error
  }
}
