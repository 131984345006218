const getToken = state => state?.auth?.token
const getUserRoleList = state => state?.auth?.userRoleList
const getRoleList = state => state?.auth?.roleList
const getUserId = state => state?.auth?.userId
const getListOfCountries = state => state?.auth?.listOfCountries
const getUserData = state => state?.auth?.userData
const getUserPermissions = state => state?.auth?.permissions

const selectors = {
  getToken,
  getUserRoleList,
  getUserId,
  getUserData,
  getListOfCountries,
  getUserPermissions,
  getRoleList,
}

export default selectors
