import { Form as AntdForm, DatePicker, Divider, Form } from 'antd'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import Flex from 'components/Flex'
import AntdInput from 'components/Form/Input'
import styled from 'styled-components'
import { COLORS } from 'theme/index'

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  width: 100%;
  .ant-form-item-explain-error {
    padding-left: ${props => (props.padding ? '20%' : '0')};
    text-align: ${props => props.padding && 'center'};
  }
  .ant-form-item-control-input {
    min-height: ${props => (props.padding ? ' 4px' : '32px')};
  }
`

export const Heading = styled.div`
  font-weight: 600;
  font-size: 1.1428571428571428em;
  line-height: 21px;
  color: #1b1f22;
`

export const ImageUploadWrapper = styled(Flex)`
  background: #f9fafe;
  border: ${props => props?.boarder && '1px dashed #98a4eb'};
  border-radius: 50%;
  height: 100px;
  width: 100px;
  

  ${ImageUploadWrapper} > ${Control} > div {
    font-weight: 500;
    font-size: 8px;
    line-height: 8.5px;
    color: #cccfd6;
  }
  margin-left: 20px;
`
export const ImageTag = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-left: 20px;
`

export const UploadText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3249d7;
  cursor: pointer;
`
export const FileInput = styled.input`
  opacity: 0;
  display: block;
  width: 100px;
  position: relative;
  top: -30px;
`
export const AddIcon = styled.div`
  margin-top: 5px;
  margin-left: 20px;
  cursor: pointer;
`

export const AntdDatePicker = styled(DatePicker)`
  border-radius: 8px;
  border-color: #e9e9e9;
  width: 100%;
  height: 48px;
  background: #fafafb !important;
`

export const StyledInput = styled(AntdInput)`
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
`

export const StyledSelectDiv = styled(Flex)`
  height: 48px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0;
  }
`

export const StyledInputDiv = styled(Flex)`
  margin-bottom: 20px !important;
  align-items: baseline;
  width: 100%;
  border-radius: 8px;
  .ant-inputselect:not(.ant-select-customize-input) .ant-select-selector {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`
export const FormWrapper = styled(Form)`
  width: 100%;
`

export const ButtonWrap = styled(Flex)`
  margin-left: 45px;
`

export const HorizontalDivider = styled(Divider)`
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 600px;
  width: 100%;
}
`
export const Div = styled.div`
color: ${COLORS.GREY_T_35};
font-size: 12px;
`
export const DivBold = styled.div`
font-weight: 600;
`

export const SelectButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_60)} !important;
  background: none !important;
`


export const AlterContent = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
color: #999FAC;`

export const SpinWrapper = styled(Flex)`
  position: absolute;
  padding-top: 30%;
  align-items: center;
  padding-left: 45%;
`
