import InstituteConstituencyInfo from 'modules/Onboarding/Partials/Institutes/Register/InstituteConstituency/index'
import InstituteInfo from 'modules/Onboarding/Partials/Institutes/Register/InstituteInfo'
import InstitutePricingContractInfo from 'modules/Onboarding/Partials/Institutes/Register/InstitutePricingContract/index'
import InstituteTaxInfo from 'modules/Onboarding/Partials/Institutes/Register/InstituteTaxInfo'

export const menuData = [
  {
    key: 1,
    activeKey: 0,
    title: 'Add Institute',
    description: ['Basic Details'],
    heading: "Let's Get Started",
    subHeading: 'Tell us bit about the organization',
    component: [InstituteInfo, InstituteConstituencyInfo],
  },
  {
    key: 2,
    activeKey: 1,
    title: 'Tax Info',
    heading: 'Tax Information',
    subHeading:
      'Tax Information Network (TIN) is an initiative by Income Tax Department of India',
    component: InstituteTaxInfo,
  },
  // {
  //   key: 3,
  //   activeKey: 2,
  //   title: 'Add Partners',
  //   heading: 'Add Partners',
  //   subHeading:'Add existing empanelled Corporate',
  //   component:PartnerInfo,
  // }, for fututre use case
  {
    key: 3,
    activeKey: 2,
    title: 'Pricing and Contract',
    heading: 'Pricing and Contract',
    subHeading: 'Choose the right prising for you to get started',
    component: InstitutePricingContractInfo,
  },
]
