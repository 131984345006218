import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'

export const CollageDetailsFlex = styled(Flex)`
  margin: 0 0 20px 0;
  padding: 20px 15px;
  border: 1px solid ${COLORS.GREY_T_90};
  border-radius: 10px;
  line-height: normal;
`
export const CollegeDetailsFlex = styled(Flex)`
  line-height: normal;
  width: 100%;
  align-items: center;
`

export const CollageNameFlex = styled(Flex)`
  flex-direction: column;
  flex: 1;
  gap: 5px;
  margin-left: 10px;
`
export const CollegeNameDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  color: ${COLORS.GREY_T_20};
`
export const CollegeCity = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_XS};
  color: ${COLORS.GREY_T_60};
`
export const SelectClgDiv = styled.div`
  font-style: normal;
  cursor: pointer;
  font-weight: ${FONT.FW_700};
  font-size: ${FONT.F_XS};
  color: ${COLORS.GREY_T_20};
  background: ${props =>
    props.background ? props.background : COLORS.GREY_T_95};
  border-radius: 6px;
  padding: 4px 6px;
`