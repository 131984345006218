import { Form, Select, Spin } from 'antd'
import Button from 'components/Button/index'
import Label from 'components/Form/Label'
import InfoCards from 'components/InfoCards'
import InfoIcon from 'components/icons/InfoIcon'
import PlusIcon from 'components/icons/PlusIcon'
import UserDefaultIcon from 'components/icons/UserDefaultIcon'
import AddCourseDrawer from 'modules/Courses/Partials/AddCourseDrawer'
import CoursesInfoTable from 'modules/Courses/Partials/CoursesTable'
import {
  AlertText,
  CardRow,
  NoContentStyle,
  SectionWrapper,
  StyledAlert,
  TopHeader,
  TopHeading,
  TopRightFlex,
} from 'modules/Courses/Style/style'
import {
  getListOfCity,
  getListOfCounties,
  getListOfState,
} from 'modules/Onboarding/actions'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { COLORS } from 'theme'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import { searchConfig } from '../../utils/config'
import { permittedEventCruds } from '../../utils/permissionsValidation'
import CourseCityStateDetails from '../Courses/Partials/StateDropdown/index'
import { StyledSelect } from './Style/style'

const { INST_API_URL } = process.env

const Container = styled.div`
  padding: ${props => (props?.padding ? '0px' : '20px')};
  display: flex;
  margin-top: 25px;
`
const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const InnerContainer = styled.div`
  display: flex;
  margin-top: 6px;
  gap: 10px;
  width: 100%;
`

const Courses = ({
  getCoursesList,
  degreeList,
  getSkillList,
  getDegreeDataValue,
  degreeFilterList,
  DeleteCourses,
  EditCourses,
  ActiveIsCourses,
  DeleteSpecialization,
  AddMultiCourses,
  permissions,
  systemConfig = false,
  getInstituteListForSysConfig,
  getDepartmentListForSysConfigElastic,
  getSpecialisationListForSysConfigElastic,
  instituteTableList,
  specialisationTableList,
  departmentTableList,
  AddCoursesInSysConfig,
  EditCoursesInSysConfig,
  sendButtonText,
}) => {
  //India
  const countryId = process.env.COUNTRY_ID
  //State and City Filter
  const [form] = Form.useForm()
  const [searchCountry, setSearchCountry] = useState('')
  const dispatch = new useDispatch()
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [stateId, setStateId] = useState('')
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)

  // Course Page
  const [page, setPage] = useState(0)
  const [addCourse, setAddCourse] = useState(false)
  const [isediting, setIsEditing] = useState(false)
  const [degreeStreamMapId, setDegreeStreamMapId] = useState('')
  const [specialisationId, setSpecialisationId] = useState('')
  const [courseId, setCourseId] = useState('')
  const [createEditForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [degreeFilter, setDegreeFilter] = useState([])
  const [duration, setDuration] = useState([])
  const [examType, setExamType] = useState([])
  const [skillList, setSkillList] = useState([])
  const [statusFilter, setStatusFilter] = useState([])
  const [carryParam, setParam] = useState({})
  const [cardsData, setCardsData] = useState([])
  const [listOfStudentSkills, setListOfStudentSkills] = useState([])
  const [sort, setSort] = useState('')
  const [orderBy, setOrderBy] = useState('asc')
  const [collegeName, setCollegeName] = useState('')
  const [collegeID, setCollegeID] = useState('')
  const [listOfCollege, setListOfCollege] = useState('')
  const [searchCollege, setSearchCollege] = useState('')
  const [departmentFilter, setDepartmentFilter] = useState('')
  const [specialisationFilter, setSpecialisationFilter] = useState('')
  const [degreeMapId, setDegreeMapId] = useState('')
  const [data, setData] = useState({})
  const [view, setView] = useState(false)
  const [placeholder, setPlaceholder] = useState(false)
  const [cityPlaceholder, setCityPlaceholder] = useState(false)
  const [SkillSearch, setSkillSerach] = useState('')
  const [domainFilter, setDomainFilter] = useState([])
  const [loader, setLoader] = useState(false)

  const onCollegeNameChange = (value, option) => {
    setCollegeID(option?.key)
    setCollegeName(value || '')
    getListOfAllColleges(stateName, cityName, '')
  }

  useEffect(() => {
    if (
      collegeID === undefined ||
      selectedCity === undefined ||
      stateId === undefined
    ) {
      getCoursesList({
        page: 0,
        search,
        duration,
        examType,
        degreeFilter,
        skillList,
        statusFilter,
        sort,
        orderBy,
        collegeID,
        setLoading,
        systemConfig,
        domainFilter,
      })
    }
  }, [collegeID, selectedCity, stateId])
  //State City

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await dispatch(getListOfState(countryId, search))
      setLoader(false)
      return setStateList(list || [])
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    if (countryId && stateId) {
      let list = await dispatch(getListOfCity(countryId, stateId, search))
      setLoader(false)
      return setCityList(list || [])
    }
  }

  useEffect(() => {
    let getData
    getData = setTimeout(() => {
      dispatch(getListOfCounties(searchCountry))
    }, 500)
    return () => clearTimeout(getData)
  }, [searchCountry])

  const onStateChange = (data, option) => {
    form.setFieldsValue({
      state: data,
      city: '',
      stateId: data,
      cityId: '',
    })
    setStateName(option?.name || '')
    setStateId(data)
    setCityName('')
    getStateData(countryId, true)
    getCityData(countryId, data, true)
    getListOfAllColleges(data || '', '', '')
    setSelectedState(data)
    setSelectedCity(null)
    setPlaceholder(true)
  }
  const onCityChange = (data, option) => {
    form.setFieldsValue({
      city: data,
      cityId: data,
    })
    setCityName(option?.name || '')
    getCityData(countryId, stateId, true, '')
    getListOfAllColleges(stateName, option?.name, '')
    setSelectedCity(data)
    setCityPlaceholder(true)
  }

  useEffect(() => {
    form.resetFields()
    getCityData('', '', true)
    form.setFieldsValue({
      name: '',
      website: '',
      country: '',
      state: '',
      stateId: '',
      cityId: '',
      city: '',
    })
    setStateName('')
    setStateId('')
    setCardsData([])
    getStateData(countryId, true)
  }, [])

  // Course Page
  useEffect(() => {
    getListOfAllColleges(stateName, cityName, searchCollege)
  }, [searchCollege])

  const delayToFetch = useCallback(
    debounce(data => {
      setLoader(true)
      setSearchCollege(data)
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const getListOfAllColleges = (
    stateID,
    cityID,
    searchCollege = searchCollege
  ) => {
    setLoader(true)
    let filterType = [
      {
        type: 'match',
        field: 'state',
        value: stateID || '',
      },
      {
        type: 'match',
        field: 'city',
        value: cityID || '',
      },
    ]
    let payload = {
      ...searchConfig?.institutesCampuses,
      q: searchCollege,
    }
    payload.filters = searchConfig?.degrees?.filters
      ? [...searchConfig.institutesCampuses.filters, ...filterType]
      : filterType
    try {
      elasticSearchRequest
        .post(`/search/institutes_campuses`, payload)
        .then(response => {
          let data = response.data.data?.hits?.hits?.map(item => item._source)
          setLoader(false)
          setListOfCollege(data)
        })
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  useEffect(() => {
    skillsAPI()
  }, [])

  const skillsAPI = async (pages, SearchKey) => {
    const { data } = await getSkillList('', SearchKey)
    if (data?.data?.result) {
      setListOfStudentSkills(data?.data?.result)
    }
  }

  const delayToFetchSkill = useCallback(
    debounce(data => {
      setSkillSerach(data)
    }, 500),
    []
  )

  useEffect(() => {
    skillsAPI(0, SkillSearch)
  }, [SkillSearch])

  useEffect(() => {
    if (collegeID) {
      getDegreeDataValue(collegeID)

      if (!systemConfig && collegeID) {
        getCoursesList({
          page: 0,
          search,
          duration,
          examType,
          degreeFilter,
          skillList,
          statusFilter,
          sort,
          orderBy,
          collegeID,
          setLoading,
          systemConfig,
          domainFilter,
        })
      }
    }
  }, [
    search,
    duration,
    examType,
    degreeFilter,
    skillList,
    statusFilter,
    sort,
    orderBy,
    collegeID,
    domainFilter,
  ])
  useEffect(() => {
    if (systemConfig)
      getCoursesList({
        page: 0,
        search,
        duration,
        examType,
        degreeFilter,
        skillList,
        statusFilter,
        sort,
        orderBy,
        collegeID,
        setLoading,
        systemConfig,
        degreeMapId,
        departmentFilter,
        specialisationFilter,
        domainFilter,
      })
  }, [
    systemConfig,
    search,
    duration,
    examType,
    degreeFilter,
    skillList,
    statusFilter,
    sort,
    orderBy,
    departmentFilter,
    degreeMapId,
    domainFilter,
    specialisationFilter,
  ])

  // useEffect(() => {
  //   let page = '0'
  //   getCoursesList({
  //     page,
  //     search,
  //     duration,
  //     examType,
  //     degreeFilter,
  //     skillList,
  //     statusFilter,
  //     sort,
  //     orderBy,
  //     collegeID,
  //     setLoading,
  //     systemConfig,
  //     degreeMapId,
  //     departmentFilter,
  //     specialisationFilter,
  //     domainFilter,
  //   })
  //   setPage(0)
  // }, [
  //   search,
  //   duration,
  //   examType,
  //   degreeFilter,
  //   skillList,
  //   statusFilter,
  //   sort,
  //   orderBy,
  //   departmentFilter,
  //   degreeMapId,
  //   domainFilter,
  //   specialisationFilter,
  // ])
  useEffect(() => {
    const cardData = [
      {
        key: 1,
        icon: <UserDefaultIcon />,
        text: 'No of degree',
        value: collegeID && degreeList?.degreeCount,
        isSelected: false,
      },
      {
        key: 2,
        icon: <UserDefaultIcon />,
        text: 'No of Department',
        value: collegeID && degreeList?.departmentCount,
        isSelected: false,
      },
      {
        key: 3,
        icon: <UserDefaultIcon />,
        text: 'Active Department',
        value: collegeID && degreeList?.activeCourse,
        filter: '1',
        isSelected: false,
      },
      {
        key: 4,
        icon: <UserDefaultIcon />,
        text: 'Inactive Department',
        value: collegeID && degreeList?.inActiveCourse,
        filter: '0',
        isSelected: false,
      },
    ]
    setCardsData(cardData)
  }, [degreeList])

  const [degreeId, setDegreeId] = useState('')
  const onEdit = param => {
    setParam(param)
    setCourseId(param?.id)
    setDegreeStreamMapId(param?.degreeStreamMapId)
    setSpecialisationId(param?.courseSpecialisationMap[0]?.specialisation?.id)
    createEditForm.setFieldsValue({
      type: param?.degreeStreamMap?.degree?.type,
      duration: param?.degreeStreamMap?.duration,
      degreeName: param?.degreeStreamMap?.degree?.name,
      suffix: param?.degreeStreamMap?.degree?.shortForm,
      departmentName: param?.degreeStreamMap?.stream?.name,
      examtype: param?.degreeStreamMap?.examType,
      domainFilter: param?.degreeStreamMap?.domainFilter,
      skills: param?.ancillaryMainSkills,
      specialisation: param?.courseSpecialisationMap?.map(
        item => item?.specialisation?.name?.toUpperCase()
      ),
      degreeId: param?.degreeStreamMap?.degree?.id,
    })
    setDegreeId(param?.degreeStreamMap?.degree?.id)
    setIsEditing(true)
    setAddCourse(true)
  }

  const onDelete = async param => {
    await DeleteCourses(param?.id, collegeID,setLoading)
    //no need to consider this
    getCoursesList({
      page,
      search,
      duration,
      examType,
      degreeFilter,
      skillList,
      statusFilter,
      sort,
      orderBy,
      collegeID,
      setLoading,
      systemConfig,
      domainFilter,
    })
  }
  const onActivate = async param => {
    if (systemConfig) {
      let payload = {
        degreeStreamMap: { ...data?.degreeStreamMap },
        status: param?.status === 1 ? 0 : 1,
      }
      await EditCoursesInSysConfig(param?.id, payload)
    } else {
      const data = param?.status === 1 ? 0 : 1
      let payload = {
        status: data,
      }
      await ActiveIsCourses(param?.id, payload)
    }
    let page = 0
    getCoursesList({
      page,
      search,
      duration,
      examType,
      degreeFilter,
      skillList,
      statusFilter,
      sort,
      orderBy,
      collegeID,
      setLoading,
      systemConfig,
      domainFilter,
    })
    setPage(0)
  }

  const AddNewCourse = () => {
    setDegreeStreamMapId('')
    createEditForm.setFieldsValue({
      type: '',
      duration: 0,
      degreeName: '',
      suffix: '',
      departmentName: '',
      examtype: '',
      skills: [],
    })
    setIsEditing(false)
    setAddCourse(true)
    setData({})
    setView(false)
  }
  const submitClose = () => {
    getCoursesList({
      page,
      search,
      duration,
      examType,
      degreeFilter,
      skillList,
      statusFilter,
      sort,
      orderBy,
      collegeID,
      setLoading,
      systemConfig,
      domainFilter,
    })

    setAddCourse(false)
  }

  const onPageChange = key => {
    setPage(key - 1)

    getCoursesList({
      page: key - 1,
      search,
      duration,
      examType,
      degreeFilter,
      skillList,
      statusFilter,
      sort,
      orderBy,
      collegeID,
      setLoading,
      systemConfig,
      degreeMapId,
      departmentFilter,
      specialisationFilter,
      domainFilter,
    })
  }

  const applyCardFilter = query => {
    let cardsCopy = [...cardsData]
    cardsCopy = cardsCopy.map(val => {
      if (query.filter === val.filter) {
        if (val.isSelected) {
          val.isSelected = false
        } else {
          setStatusFilter(query.filter)
        }
      } else {
        val.isSelected = false
      }
      return val
    })
    setCardsData(cardsCopy)
  }

  const cruds = permittedEventCruds(permissions, 'Courses')
  const setEditData = data => {
    setData(data)
  }

  useEffect(() => {
    if (
      stateName !== form.getFieldValue('state') ||
      cityName !== form.getFieldValue('city')
    ) {
      setCollegeID('')
      setCollegeName('')
    }
  }, [stateName, cityName, form])

  const downloadExcel = data => {
    let uri = INST_API_URL + 'institutes/curd/export/DDSMapping'
    window.open(uri, '_blank')
  }

  const noContent = load => {
    return (
      <NoContentStyle>
        {load ? (
          <Spin />
        ) : (
          <div>
            <svg
              className="ant-empty-img-simple"
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse
                  className="ant-empty-img-simple-ellipse"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g className="ant-empty-img-simple-g" fillRule="nonzero">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    className="ant-empty-img-simple-path"
                  ></path>
                </g>
              </g>
            </svg>
            <div>No Data</div>
          </div>
        )}
      </NoContentStyle>
    )
  }
  return (
    <>
      <SectionWrapper column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>
            {systemConfig
              ? 'Degree, Department & Specialisation Mapping' +
                '(' +
                degreeList?.count +
                ')'
              : 'Institute Courses Mapping'}
          </TopHeading>

          <TopRightFlex>
            {systemConfig && (
              <Button.Primary
                height={'38px'}
                text={'Export to Excel'}
                onClick={() => downloadExcel()}
              />
            )}
            <Button.Primary
              height={'38px'}
              text={systemConfig ? 'New Course Mapping' : 'New Courses'}
              icon={<PlusIcon />}
              onClick={AddNewCourse}
              disable={collegeName === '' && !systemConfig}
            />
          </TopRightFlex>
        </TopHeader>
        {!systemConfig && (
          <>
            <Container>
              <InnerContainer>
                <CourseCityStateDetails
                  cityList={cityList}
                  stateList={stateList}
                  onStateChange={onStateChange}
                  onCityChange={onCityChange}
                  form={form}
                  countryValue={''}
                  stateValue={stateName}
                  cityValue={cityName}
                  countryId={countryId}
                  stateId={stateId}
                  getStateData={getStateData}
                  getCityData={getCityData}
                  setSearchCountry={setSearchCountry}
                  isSystemConfig={true}
                  placeholder={placeholder}
                  setPlaceholder={setPlaceholder}
                  cityPlaceholder={cityPlaceholder}
                  setCityPlaceholder={setCityPlaceholder}
                  loader={loader}
                  setLoader={setLoader}
                  noContent={noContent}
                />

                {!addCourse && !systemConfig && (
                  <ContainerDiv>
                    <Label>Institute Name *</Label>
                    <StyledSelect
                      name="campusName"
                      paddingTop="5px"
                      width="350px"
                      label="Institute Name *"
                      placeholder="Select Institute"
                      OptionData={listOfCollege || []}
                      nameParam="campusName"
                      valueParam="campusName"
                      bordered={true}
                      onChange={onCollegeNameChange}
                      showSearch={true}
                      allowClear={true}
                      value={collegeName || undefined}
                      onSearch={data => delayToFetch(data)}
                      listHeight={160}
                      // filterOption={(inputValue, option) =>
                      //   option?.name
                      //     ?.toLowerCase()
                      //     ?.indexOf(inputValue?.toLowerCase()) !== -1
                      // }
                     filterOption={false}

                      loading={loader}
                      notFoundContent={noContent(loader)}
                      getPopupContainer={trigger => trigger.parentNode}
                      disabled={!selectedState || cityName === ''}
                    >
                      {(listOfCollege || []).map(college => (
                        <Select.Option
                          key={college.id}
                          value={college.id}
                          name={college.campusName}
                        >
                          {college.campusName}
                        </Select.Option>
                      ))}
                    </StyledSelect>
                  </ContainerDiv>
                )}
              </InnerContainer>
            </Container>
            <div style={{ padding: '10px 10px 25px 20px', width: '435px' }}>
              {collegeName === '' && (
                <StyledAlert
                  message={
                    <AlertText>
                      Note:- Please Select Any State, City, and Institute.
                    </AlertText>
                  }
                  type="info"
                  showIcon
                  icon={<InfoIcon />}
                />
              )}
            </div>
            <CardRow spaceBetween>
              {cardsData?.map(card => (
                <InfoCards
                  key={card?.text}
                  padding={'20px'}
                  width={'25%'}
                  icon={card?.icon}
                  text={card?.text}
                  value={card?.value}
                  background={
                    card?.isSelected ? COLORS.BLUE_T_50 : COLORS.BLUE_T_95
                  }
                  isSelected={card?.isSelected}
                  onClick={() => {
                    applyCardFilter(card)
                  }}
                />
              ))}
            </CardRow>
          </>
        )}
        {!systemConfig ? (
          <div style={{ pointerEvents: collegeName == '' ? 'none' : '' }}>
            <CoursesInfoTable
              collegeId={collegeID}
              degreeList={collegeID ? degreeList : []}
              degreeFilterList={degreeFilterList}
              onEdit={onEdit}
              onDelete={onDelete}
              onActivate={onActivate}
              loading={loading}
              page={page + 1}
              onPageChange={onPageChange}
              setSearch={setSearch}
              duration={duration}
              setDuration={setDuration}
              setExamType={setExamType}
              setDomainFilter={setDomainFilter}
              setDegreeFilter={setDegreeFilter}
              setSkillList={setSkillList}
              setStatusFilter={setStatusFilter}
              listOfStudentSkills={listOfStudentSkills}
              setPage={setPage}
              setSort={setSort}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              sortBy={sort}
              cruds={cruds}
              departmentTableList={departmentTableList}
              getDepartmentListForSysConfig={getDepartmentListForSysConfigElastic}
              setView={setView}
              setAddCourse={setAddCourse}
              addCourse={addCourse}
              setEditData={setEditData}
              getInstituteListForSysConfig={getInstituteListForSysConfig}
              instituteTableList={instituteTableList}
              getSpecialisationListForSysConfig={
                getSpecialisationListForSysConfigElastic
              }
            />
          </div>
        ) : (
          <CoursesInfoTable
            setAddCourse={setAddCourse}
            addCourse={addCourse}
            setEditData={setEditData}
            degreeList={degreeList || []}
            degreeFilterList={degreeFilterList}
            onEdit={onEdit}
            onDelete={onDelete}
            onActivate={onActivate}
            loading={loading}
            page={page + 1}
            onPageChange={onPageChange}
            setSearch={setSearch}
            duration={duration}
            setDuration={setDuration}
            setExamType={setExamType}
            setDomainFilter={setDomainFilter}
            setDegreeFilter={setDegreeFilter}
            setSkillList={setSkillList}
            setStatusFilter={setStatusFilter}
            listOfStudentSkills={listOfStudentSkills}
            setPage={setPage}
            setSort={setSort}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            sortBy={sort}
            cruds={cruds}
            systemConfig={systemConfig}
            getDepartmentListForSysConfig={getDepartmentListForSysConfigElastic}
            departmentTableList={departmentTableList}
            setDepartmentFilter={setDepartmentFilter}
            departmentFilter={departmentFilter}
            setDegreeMapId={setDegreeMapId}
            degreeMapId={degreeMapId}
            setView={setView}
            getInstituteListForSysConfig={getInstituteListForSysConfig}
            instituteTableList={instituteTableList}
            sendButtonText={sendButtonText}
            getSpecialisationListForSysConfig={
              getSpecialisationListForSysConfigElastic
            }
            setSpecialisationFilter={setSpecialisationFilter}
            specialisationFilter={specialisationFilter}
            specialisationTableList={specialisationTableList}
          />
        )}
      </SectionWrapper>
      {addCourse && (
        <AddCourseDrawer
          data={data}
          degreeId={degreeId}
          title={
            view
              ? 'Preview'
              : Object.keys(data)?.length > 0
              ? 'View Course Mapping'
              : 'Add New Course Mapping'
          }
          setData={setData}
          createEditForm={createEditForm}
          collegeID={collegeID}
          addCourse={addCourse}
          setAddCourse={setAddCourse}
          isediting={isediting}
          degreeStreamMapId={degreeStreamMapId}
          specialisationId={specialisationId}
          setDegreeStreamMapId={setDegreeStreamMapId}
          setSpecialisationId={setSpecialisationId}
          // AddCourses={AddCourses}
          AddMultiCourses={AddMultiCourses}
          getSkillList={getSkillList}
          courseId={courseId}
          setPage={setPage}
          EditCourses={EditCourses}
          submitClose={submitClose}
          listOfStudentSkills={listOfStudentSkills}
          DeleteSpecialization={DeleteSpecialization}
          carryParam={carryParam}
          cruds={cruds}
          systemConfig={systemConfig}
          getInstituteListForSysConfig={getInstituteListForSysConfig}
          getDepartmentListForSysConfig={getDepartmentListForSysConfigElastic}
          getSpecialisationListForSysConfig={getSpecialisationListForSysConfigElastic}
          instituteTableList={instituteTableList}
          specialisationTableList={specialisationTableList}
          departmentTableList={departmentTableList}
          AddCoursesInSysConfig={AddCoursesInSysConfig}
          EditCoursesInSysConfig={EditCoursesInSysConfig}
          view={view}
          sendButtonText={sendButtonText}
          delayToFetchSkill={delayToFetchSkill}
        />
      )}
      {/* <StudentsBulkUpload
        uploadDrawer={uploadDrawer}
        setUploadDrawer={setUploadDrawer}
      /> */}
    </>
  )
}
export default Courses
