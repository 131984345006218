import { LoginOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AntdAvatar from '../../../../components/Avatar'
import { EmptyWrapper } from '../../Style/style'
import { setSingleCorporateData } from '../../actions'
import {
  BorderedDiv,
  FlexWrap,
  GreyText,
  MediumText,
  StyledDivider,
  UserName,
} from './Style/style'

const ViewCorporateDrawer = ({
  viewCorporateDrawer,
  setViewCorporateDrawer,
}) => {
  const singleCorporateData = useSelector(
    state => state?.UserCorporateList?.singleCorporateData
  )
  const handler = redirectLink => {
    window.open(redirectLink, '_self')
  }
  let size =
    (singleCorporateData?.regDocs?.size / 1024 / 1024).toFixed(2) + ' MB'

  const dispatch = useDispatch()
  const content = () => {
   
    return (
      <Fragment>
        {singleCorporateData?.name ? (
          <>
            <BorderedDiv>
              <FlexWrap gap="10px" alignCenter>
                <div>
                  <AntdAvatar
                    size={39}
                    src={singleCorporateData?.logo}
                    IconName={singleCorporateData?.name?.charAt(0)}
                    font={18}
                  />
                </div>
                <FlexWrap column>
                  <UserName>{singleCorporateData?.name}</UserName>
                  <GreyText>{singleCorporateData?.website}</GreyText>
                </FlexWrap>
              </FlexWrap>
              <StyledDivider />
              <FlexWrap>
                <GreyText width={'50%'}>Plan </GreyText>
                <FlexWrap column>
                  <MediumText>Free Plan</MediumText>
                </FlexWrap>
              </FlexWrap>
              {singleCorporateData?.regDocs && (
                <>
                  {' '}
                  <StyledDivider />
                  <FlexWrap>
                    <GreyText width={'50%'}>Contract</GreyText>
                    <FlexWrap column>
                      <MediumText>
                        {singleCorporateData?.regDocs?.name}
                      </MediumText>

                      <GreyText>{size}</GreyText>
                    </FlexWrap>
                  </FlexWrap>
                </>
              )}
            </BorderedDiv>
            <EmptyWrapper center>
              {singleCorporateData?.redirectLink && (
                <Button.IconButton
                  text-align={'center'}
                  icon={<LoginOutlined />}
                  onClick={() => handler(singleCorporateData?.redirectLink)}
                  text="Checkin"
                  height="38px"
                />
              )}
            </EmptyWrapper>
          </>
        ) : (
          <EmptyWrapper center isSpin={!singleCorporateData}>
            <Spin />
          </EmptyWrapper>
        )}
      </Fragment>
    )
  }
  const onClose = () => {
    setViewCorporateDrawer(false)
    dispatch(setSingleCorporateData({}))
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClose} height="38px" />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={'Preview'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={viewCorporateDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ViewCorporateDrawer
