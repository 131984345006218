/* eslint-disable react/no-unknown-property */

import React from 'react'

const EventIcon = ({ color = '#999FAC' }) => (
<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.45 11.5C8.85 11.9 9.36667 12.0957 10 12.087C10.6333 12.079 11.1 11.85 11.4 11.4L15.625 5.075C15.775 4.84167 15.754 4.62933 15.562 4.438C15.3707 4.246 15.1583 4.225 14.925 4.375L8.6 8.6C8.15 8.9 7.91267 9.35833 7.888 9.975C7.86267 10.5917 8.05 11.1 8.45 11.5ZM3.1 16C2.75 16 2.41667 15.9167 2.1 15.75C1.78333 15.5833 1.53333 15.35 1.35 15.05C0.916667 14.3 0.583333 13.5043 0.35 12.663C0.116667 11.821 0 10.9333 0 10C0 8.61667 0.262667 7.32067 0.788 6.112C1.31267 4.904 2.02933 3.84567 2.938 2.937C3.846 2.029 4.90833 1.31233 6.125 0.787C7.34167 0.262334 8.63333 0 10 0C10.75 0 11.475 0.079 12.175 0.237C12.875 0.395667 13.55 0.633333 14.2 0.95C14.55 1.13333 14.754 1.38333 14.812 1.7C14.8707 2.01667 14.7833 2.31667 14.55 2.6C14.4167 2.75 14.2333 2.84167 14 2.875C13.7667 2.90833 13.5583 2.88333 13.375 2.8C12.8417 2.53333 12.2917 2.33333 11.725 2.2C11.1583 2.06667 10.5833 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 10.7 2.09567 11.3917 2.287 12.075C2.479 12.7583 2.75 13.4 3.1 14H16.9C17.2833 13.3667 17.5627 12.7083 17.738 12.025C17.9127 11.3417 18 10.6333 18 9.9C18 9.31667 17.9293 8.746 17.788 8.188C17.646 7.62933 17.4417 7.09167 17.175 6.575C17.0583 6.35833 17.025 6.13333 17.075 5.9C17.125 5.66667 17.2417 5.475 17.425 5.325C17.6917 5.09167 17.9833 5.025 18.3 5.125C18.6167 5.225 18.85 5.44167 19 5.775C19.3 6.40833 19.5333 7.06267 19.7 7.738C19.8667 8.41267 19.9667 9.11667 20 9.85C20 10.8 19.8877 11.7083 19.663 12.575C19.4377 13.4417 19.1 14.2667 18.65 15.05C18.4667 15.35 18.2167 15.5833 17.9 15.75C17.5833 15.9167 17.25 16 16.9 16H3.1Z" fill="#999FAC"/>
</svg>
)

export default EventIcon
