import { Menu, Tabs } from 'antd'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import styled from 'styled-components'
import { COLORS } from 'theme'

const BREAKPOINT = '999px'

export const StyledFlex = styled(Flex)`
  position: static;
  padding: 5px 120px 5px 120px;
  height: 65px;

  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);
    @media only screen and (max-device-width: 767px) {
      padding: 0px;
    }
    @media only screen and (min-width: 768px )  and  (max-width : 1200px){
      padding: 0px;
    }
`

export const IconWrapper = styled(Flex)`
  background-color: white;
  min-width: 200px;
  max-width: 200px;
  justify-content: center;
`

export const Divider = styled.div`
  border-left: 1px solid #e6e7ea;
  margin-right: 25px;
  height: 55px;
`
export const Name = styled.div`
  font-weight: 600;
  font-size: 1em;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
`

export const Address = styled.div`
  font-weight: 500;
  font-size: 0.8571428571428571em;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  opacity: 0.48;
`

export const Institute = styled(Flex)`
  gap: 10px;
`

export const NameAdd = styled(Flex)`
  gap: 3px;
`

export const ManageFlex = styled(Flex)`
  height: 35px;
  padding-top: 8px;
  gap: 8px;
`
export const DropMenu = styled(Menu)`
width: 300px;
padding:10px;
}
`
export const Outerdiv = styled.div`
  padding: 5px;
  text-align: center;
`
export const NameDiv = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding: 8px;
`
export const EmailDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #808697;
`

export const ManageText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #343e59;
`

export const StyledFlex1 = styled.div`
  position: static;
  height: 50px;
  padding: 2px 120px 5px 120px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);
    @media only screen and (max-device-width: 767px) {
      padding: 0px;
    }
    @media only screen and (min-width: 768px )  and  (max-width : 1200px){
      padding: 0px;
    }

`

export const TopNavMenu = styled(Menu)`
  font-weight: 600;
  border: 0;
  .ant-menu-horizontal {
    border-bottom: 0px !important;
  }
`
export const Heading = styled.div`
  font-size:14px;
  font-weight: 400;
`
export const BoldHeading = styled.span`
  font-size:14px;
  font-weight: 600;
`


export const LeftDiv = styled.div`
  float: left;
  width: 85%;
`
export const RightDiv = styled.div`
  float: right;
  padding-top: 2px;
`
export const ContentWrapper = styled(Flex)`
  overflow-y: auto;
  background: ${COLORS.PRIMARY_GREY_T_98};
  &::-webkit-scrollbar {
    display: none;
  }
`
export const PageWrapper = styled(Flex)`
  background: '#FFFFFF';
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex-grow: 1;
  @media screen and (max-width: ${BREAKPOINT}) {
    padding: 0px;
    overflow-x: hidden;
    box-sizing: border-box;
  }
`

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const StyledTabs = styled(Tabs)`
  width: ${props => (props.width ? props.width : '')};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  .ant-tabs-nav-list {
    border-right: ${props =>
    props.border ? `1px solid ${COLORS.GREY_T_90}` : 'none'};
  }

  .ant-tabs-tab {
    margin-top: 0 !important;
    color: ${COLORS.GREY_T_60};
    border-radius: 0 !important;
    border-bottom: 1px solid ${COLORS.GREY_T_90} !important;
    
  }
  .ant-tabs-tab .Badge{
    padding:5px;
    border-radius: 50%;
    background-color: ${COLORS.PAGE_BORDER};
    color: ${COLORS.GREY_T_60};
    font-size: 12px;
    }
  .ant-tabs-tab-active {
    background: ${COLORS.WHITE} !important;
  }
  .ant-tabs-tab-active .Badge{
  padding:5px;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  font-size: 12px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props =>
    props.textColor ? props.textColor : COLORS.PRIMARY} !important;
  }
`
export const NotifyContain = styled(Flex)`
  gap: 3px;
  padding-left: 15px;
`

export const NotifyText = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
padding-left:10px;
color: ${COLORS.GREY_T_20};
`
export const ButtonDiv = styled.div`
padding-top:10px;
padding-left: 55px;
`

export const LightText = styled.div`
font-weight: 500;
font-size: 12px;
line-height: 15px;
padding-top: 10px;
color:  ${COLORS.GREY_T_60};`

export const PaddedDiv = styled.div`
padding:5px;
`
export const PaddingDetails = styled.div`
padding-bottom:40px;
`

export const PaddingDetail = styled.div`
padding-bottom:20px;
`