import React, { useState, useEffect } from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import SearchInput from 'components/SearchInput'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ScrollBar } from 'components/CommonStyles'
import { Skeleton } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getCitiesWithPagination } from 'modules/Onboarding/actions.js'

const Wrapper = styled.div`
  padding: 1rem 0.5rem;
`
const LocationFilter = ({ setLocation, location, setSearch, search }) => {
  const [citiesData, setCitiesData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [debounced, setDebounced] = useState(() => search)
  const dispatch = useDispatch()
  const citiesList = useSelector(
    state => state?.CorporateData?.citiesWithPagination
  )

  React.useEffect(() => {
    dispatch(getCitiesWithPagination(search, pageNum))
  }, [])
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(search)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const getCities = (search = '', pageNum = '') => {
    dispatch(getCitiesWithPagination(search, pageNum))
  }
  React.useEffect(() => {
    if (search) {
      setCitiesData([])
      getCities(search)
    }
    setPageNum(1)
  }, [debounced])

  useEffect(() => {
    if (citiesList?.result?.length) {
      let newList = [...citiesData, ...(citiesList?.result ?? [])]
      {console.log(citiesList?.result,"result")}
      setCitiesData(newList)
      setPageNum(prev => prev + 1)
    }
  }, [citiesList?.result])

  const onChange = checkedvalue => {
    setLocation(checkedvalue)
  }
  const loadMore = () => {
    if (search) {
      getCities(search, pageNum)
    } else {
      getCities(pageNum)
    }
  }
  const locationSearch = value => {
    setSearch(value)
    if (value?.length) {
      getCities(value, pageNum)
    } else {
      getCities('', pageNum)
    }
  }

  return (
    <Wrapper>
      <SearchInput
        width={'90%'}
        placeholder="Search by keyword"
        bordered={true}
        searchValue={search}
        setSearchValue={value => {
          locationSearch(value)
        }}
      />

      <ScrollBar id="scrollableDiv" height={'28vh'}>
        <InfiniteScroll
          dataLength={citiesData?.length}
          next={loadMore}
          hasMore={citiesList?.totalPages > pageNum}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget="scrollableDiv"
        >
          <FilterCheckBoxComp
            options={citiesData}
            value={location}
            onChange={onChange}
            valueParam="name"
            labelParam="name"
          />
          <br />
          {!citiesData.length && 'No data Found'}
        </InfiniteScroll>
      </ScrollBar>
    </Wrapper>
  )
}

export default LocationFilter
