import React from 'react'
import AuthPage from 'routes/Components/AuthPage'

const AuthRouter = ({ isAuthenticated, permissions }) => {
  return (
    <AuthPage isAuthenticated={isAuthenticated} permissions={permissions} />
  )
}

export default AuthRouter
