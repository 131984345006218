import AntdAvatar from 'components/Avatar'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import {
  BorderedDiv,
  FlexWrap,
  GreyText,
  MediumText,
  StyledDivider,
  UserName,
} from 'modules/Dashboard/Partials/ViewUserRoleDrawer/Style/style'
import { Spinner } from 'modules/Dashboard/Styles/style'
import React, { Fragment } from 'react'
import { COLORS } from 'theme'

const ViewUserRoleDrawer = ({
  viewUserDrawer,
  setViewUserDrawer,
  singleUserData,

  updateLoading,
}) => {
  const info = [
    {
      heading: 'Email ID',
      value: singleUserData?.email,
    },
    {
      heading: 'Contact Number',
      value: singleUserData?.phone_number ? singleUserData?.phone_number : 'NA',
    },
  ]

  const content = () => {
    return (
      <Fragment>
        {updateLoading ? (
          <Flex center>
            <Spinner />
          </Flex>
        ) : (
          <BorderedDiv>
            <FlexWrap gap="10px" alignCenter>
              <AntdAvatar
                size={39}
                IconName={singleUserData?.first_name?.charAt(0)}
                font={18}
              />
              <FlexWrap column>
                <UserName>
                  {singleUserData?.first_name} {singleUserData?.last_name}
                </UserName>
                {/* <GreyText>{getRoleName(singleUserData?.role_id)}</GreyText> */}
              </FlexWrap>
            </FlexWrap>

            <StyledDivider />
            <MediumText color={COLORS.GREY_T_20} marginbottom={'15px'}>
              Contact Details
            </MediumText>

            <FlexWrap gap="15px" column>
              {info?.map((item, id) => (
                <FlexWrap alignCenter key={item?.id}>
                  <GreyText width="30%" color={COLORS.GREY_T_30}>
                    {item?.heading}
                  </GreyText>
                  <MediumText>{item?.value}</MediumText>
                </FlexWrap>
              ))}
            </FlexWrap>
          </BorderedDiv>
        )}
      </Fragment>
    )
  }

  const footer = () => {
    return (
      <FlexWrap right>
        <Button.Primary
          onClick={() => setViewUserDrawer(false)}
          text="Done"
          icon={<CheckIconWhite />}
          height="38px"
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={'View Details'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={() => setViewUserDrawer(false)}
      visible={viewUserDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default ViewUserRoleDrawer
