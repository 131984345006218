import Avatar from 'components/Avatar'
import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import LinkIcon from 'components/icons/LinkIcon'
import {
  TwoFields,
  SpinWrapper,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import React, { useCallback } from 'react'
import SuccessMessage from 'utils/SuccessMessage'
import CONSTANTS from 'utils/constants'
import { Empty, Spin } from 'antd'
const CorporateDetails = ({
  onCountryChange,
  delayCountryToFetch,
  countryList,
  stateList,
  cityList,
  onStateChange,
  onCityChange,
  countryId,
  stateId,
  getStateData,
  getCityData,
  setSearchCountry,
  setHeadOffice,
  isHeadOffice,
  form,
  loadingCountry,
  loadingState,
  loadingCity,
  setLoadingCountry,
  setLoadingState,
  setLoadingCity,
}) => {
  const copyLink = () => {
    let copyText = document.getElementById('WebsiteLink')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const delayStateToDb = useCallback(
    debounce((data1, data2, data3) => {
      
      getStateData(data1, data2, data3)
      form.setFieldsValue({
        state: '',
        stateId: '',
      })
    }, 500),
    []
  )

  const delayCitiesToDb = useCallback(
    debounce((data1, data2, data3, data4) => {
      getCityData(data1, data2, data3, data4)
      form.setFieldsValue({
        city: '',
        cityId: '',
      })
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  return (
    <>
      <AntdInput
        width="100%"
        label="Corporate Name"
        placeholder="Eg. Google"
        optional={'*'}
        name={'corporateName'}
        maxLength={300}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('corporate name'),
          },
        ]}
        onChange={event => {
          form.setFieldsValue({
            corporateName: event.target.value.replace(/^ /, ''),
          })
        }}
      />
      <AntdInput
        id={'WebsiteLink'}
        label="Website"
        placeholder="Eg. www.google.com"
        width="100%"
        optional={'*'}
        name={'website'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('website'),
          },
          // {
          //   pattern: CONSTANTS.REGEX.WEBSITE,
          //   message: CONSTANTS.ERROR_MESSAGE.VALID('website'),
          // },
        ]}
        onChange={event => {
          form.setFieldsValue({
            website: event.target.value.trim(),
          })
        }}
        suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
      />
      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="Country *"
          placeholder="Eg.India"
          name={'country'}
          filterOption={false}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  !form.getFieldValue(['country']) ||
                  !form.getFieldValue(['countryId'])
                ) {
                  return Promise.reject(new Error('Please select country!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={loadingCountry ? [] : countryList}
          notFoundContent={
            loadingCountry ? (
              <SpinWrapper center>
                <Spin />
              </SpinWrapper>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          valueParam="name"
          nameParam="name"
          onChange={onCountryChange}
          value={form.getFieldValue(['country'])}
          showSearch={true}
          disabled={false}
          onSearch={data => {
            setLoadingCountry(true)
            delayCountryToFetch(data)
          }}
          allowClear={true}
        />
        <AntdSelect
          width="100%"
          label="State *"
          placeholder="Eg.Tamil Nadu"
          name={'state'}
          filterOption={false}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  !form.getFieldValue(['state']) ||
                  !form.getFieldValue(['stateId'])
                ) {
                  return Promise.reject(new Error('Please select state!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={loadingState ? [] : stateList}
          notFoundContent={
            loadingState ? (
              <SpinWrapper center>
                <Spin />
              </SpinWrapper>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          valueParam="name"
          nameParam="name"
          onChange={onStateChange}
          value={form.getFieldValue(['state'])}
          showSearch={true}
          disabled={
            form.getFieldValue(['country']) === '' ||
            form.getFieldValue(['country']) === undefined
          }
          onSearch={data => {
            setLoadingState(true)
            delayStateToDb(countryId, false, data)
          }}
          allowClear={true}
        />
      </TwoFields>
      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="City *"
          placeholder="Eg.Chennai"
          name={'city'}
          filterOption={false}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  !form.getFieldValue(['city']) ||
                  !form.getFieldValue(['cityId'])
                ) {
                  return Promise.reject(new Error('Please select city!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={loadingCity ? [] : cityList}
          notFoundContent={
            loadingCity ? (
              <SpinWrapper center>
                <Spin />
              </SpinWrapper>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          valueParam="name"
          nameParam="name"
          onChange={onCityChange}
          value={form.getFieldValue(['city'])}
          showSearch={true}
          disabled={
            form.getFieldValue(['state']) === '' ||
            form.getFieldValue(['state']) === undefined
          }
          onSearch={data => {
            setLoadingCity(true)
            delayCitiesToDb(countryId, stateId, true, data)
          }}
          allowClear={true}
        />
        <AntdInput
          width="100%"
          label="Zipcode *"
          placeholder="Eg.604407"
          name={'zipcode'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('Zipcode'),
            },
            {
              pattern: CONSTANTS.REGEX.PIN_CODE,
              message: CONSTANTS.ERROR_MESSAGE.VALID('Zipcode'),
            },
          ]}
        />
      </TwoFields>

      {/*
       https://pluginlivedevelopment.atlassian.net/browse/PLUG-2900 
      <FormItem
        name={['isHeadOffice']}
        rules={[{ required: false, message: 'Please select Job Location' }]}
      >
        <Checkbox
          value={isHeadOffice}
          label={'Head Office'}
          checked={isHeadOffice}
          onClick={handleCheckboxChange}
        >
          {'Head Office'}
        </Checkbox>
      </FormItem> */}
    </>
  )
}

export default CorporateDetails
