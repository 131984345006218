import { Checkbox, Divider, Row, Tooltip } from 'antd'
import { ScrollBar } from 'components/CommonStyles'
import SearchInput from 'components/SearchInput'
import ArrowSideIcon from 'components/icons/ArrowSideIcon'
import {
  CheckboxLabel,
  ColoContent,
  ColoItem,
  DepartColumn,
  DepartmentItem,
  MenuFlex,
  SideArrowItem,
  SubmitFlex,
} from 'modules/Courses/Partials/AddCourseDrawer/Style/style'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox {
    display: none !important;
  }
`
function DepartmentAndSpecialization({
  setCheckListCount,
  setSpecialisationCount,
  departmentList,
  departmentValue,
  specializationValue,
  setDepartmentName,
  setSpecializationName,
  carryParam,
  isediting,
  depList,
  setDepList,
  checkedList,
  setCheckedList,
  setDepName,
  setSpecialisationList,
  createEditForm,
}) {
  const [specValue, setSpecValue] = useState([])
  const [Specialization, setSpecialization] = useState(specializationValue)
  const [department, setDepartment] = useState([])
  const [search, setSearch] = useState('')
  const [specSearch, setSpecSearch] = useState('')
  const [departmentId, setDepartmentId] = useState('')
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [clickedValueInDep, setClickedValueInDep] = useState('')

  const handleCheckboxChange = event => {
    const targetName = event?.target?.name

    const id = event?.target?.id
    const targetChecked = event?.target?.checked

    if (targetName === 'select-all') {
      let ad = specValue?.[0]?.specialisation?.map(fruit => ({
        ...fruit,
        checked: fruit?.name
          ?.toLocaleLowerCase()
          ?.includes(specSearch?.toLowerCase())
          ? targetChecked
          : false,
      }))
      const filteredArray = depList?.map(obj => {
        if (obj?.id === specValue?.[0]?.id) {
          return {
            ...obj,
            specialisation: ad,
          }
        }
        return obj
      })
      setDepList(filteredArray)
    } else {
      const a = specValue?.[0]?.specialisation?.map(fruit =>
        fruit?.id === id ? { ...fruit, checked: targetChecked } : fruit
      )

      const b = [{ id: specValue?.[0]?.id, specialisation: a }]
      setSpecValue(b)

      const filteredArray = depList?.map(obj => {
        if (obj?.id === specValue?.[0]?.id) {
          return {
            ...obj,
            specialisation: obj?.specialisation?.map(specObj => {
              if (specObj?.id === id) {
                return { ...specObj, checked: targetChecked }
              }
              return specObj
            }),
          }
        }
        return obj
      })
      setDepList(filteredArray)
    }
  }
  const trueCheckboxes = Specialization?.filter(
    checkbox => checkbox?.checked === true
  )
  useEffect(() => {
    setSpecialisationCount(trueCheckboxes?.length)
    const specialisationName = Specialization?.find(item => item?.checked)
    setSpecializationName(specialisationName?.name)
  }, [Specialization, trueCheckboxes])

  const onCheckAllChange = e => {
    let check = convertedArray?.map(item => item?.value)
    setDepartmentId('')
    setClickedValueInDep('')
    setCheckedList(e?.target?.checked ? check : [])
    setIndeterminate(false)
    setCheckAll(e?.target?.checked)
    onChange(e?.target?.checked ? check : [])
    if (!e?.target?.checked) {
      setSpecialisationList([])
    }
  }
  useEffect(() => {
    onChange(departmentValue)
  }, [departmentValue])
  useEffect(() => {
    setSpecialization(specializationValue)
  }, [specializationValue])
  useEffect(() => {
    let dList = departmentList?.map(item => {
      return {
        id: item?.id,
        name: item?.name,
        checked: item?.checked ? item?.checked : false,
        specialisation: item?.specialisation?.map(data => {
          return {
            ...data,
            checked: item?.checked ? item?.checked : false,
          }
        }),
      }
    })
    setDepList(dList)
  }, [departmentList, isediting])
  useEffect(() => {
    if (checkedList?.length > 0) {
      setCheckListCount(checkedList?.length)
      createEditForm.setFieldsValue({
        Department: `Department (${checkedList?.length})`,
      })
    } else {
      createEditForm.setFieldsValue({
        Department: `Department (0)`,
      })
      setCheckListCount(0)
    }
    if (departmentList?.length > 0) {
      let dpv = depList?.filter(item => {
        let filterdpv = checkedList?.includes(item?.id)
        return filterdpv
      })
      let depName = depList?.find(item => checkedList?.includes(item?.id))
      setDepName(depName?.name)
      dpv?.map(item => {
        return {
          id: item?.id,
          name: item?.name,
          checked: item?.checked ? item?.checked : false,
          specialisation: item?.specialisation?.map(data => {
            return {
              ...data,
              checked: item?.checked ? item?.checked : false,
            }
          }),
        }
      })
    }
  }, [checkedList])
  const iconClicked = (event, value) => {
    setDepartmentId(value)
  }
  const clickedItem = value => {
    setClickedValueInDep(value)
  }
  const convertedArray = departmentList
    ?.filter(f => {
      if (search === '') {
        return true
      } else {
        return f?.name?.toLocaleLowerCase()?.includes(search?.toLowerCase())
      }
    })
    .map(item => {
      return {
        label: (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              paddingTop: '10px',
            }}
            key={item?.id}
          >
            <div>
              <DepartmentItem
                backgroundColor={
                  checkedList?.includes(item?.id) ? '#3249D7' : ''
                }
                borderRadius="10px"
                color={checkedList?.includes(item?.id) ? '#FFFFFF' : '#999FAC'}
                fontSize="16px"
                fontWeight="800"
              >
                <Tooltip title={item?.name} placement="left">
                  <div
                    // eslint-disable-next-line react/no-unknown-property
                    data-custom-prop={item?.id}
                    tabIndex="0"
                    role="button"
                    onClick={e =>
                      clickedItem(e.target.getAttribute('data-custom-prop'))
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        clickedItem(e.target.getAttribute('data-custom-prop'))
                      }
                    }}
                  >
                    {item?.name}
                  </div>
                </Tooltip>
              </DepartmentItem>
            </div>
          </div>
        ),
        value: item?.id,
      }
    })
  const convertedArray1 = departmentList
    ?.filter(f => {
      if (search === '') {
        return true
      } else {
        return f?.name?.toLocaleLowerCase()?.includes(search?.toLowerCase())
      }
    })
    .map(item => {
      return {
        label: (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              paddingTop: '11px',
            }}
            key={item?.id}
          >
            <div>
              <SideArrowItem
                backgroundColor={
                  checkedList?.includes(item?.id) && departmentId === item?.id
                    ? '#3249D7'
                    : '#EBECEE'
                }
                borderRadius={
                  checkedList?.includes(item?.id) && departmentId === item?.id
                    ? '10px'
                    : '12px'
                }
                color={
                  checkedList?.includes(item?.id) && departmentId === item?.id
                    ? '#FFFFFF'
                    : '#999FAC'
                }
                fontSize="16px"
                fontWeight={
                  checkedList?.includes(item?.id) && departmentId === item?.id
                    ? '500'
                    : '400'
                }
                pointerEvents={checkedList?.includes(item?.id) ? '' : 'none'}
                // onClick={e => iconClicked(e, item.id)}
              >
                <div
                  role="button"
                  tabIndex="0"
                  onClick={e => iconClicked(e, item?.id)}
                  onKeyDown={() => {}}
                >
                  <ArrowSideIcon />
                </div>
              </SideArrowItem>
            </div>
          </div>
        ),
        value: item?.id,
      }
    })

  useEffect(() => {
    if (departmentId) {
      let abc = depList?.filter(item => item?.id == departmentId)
      setSpecValue(abc)
    }
  }, [departmentId, depList])
  function merge_arrays(array1, array2) {
    const map = new Map(array1?.map(obj => [obj?.name, obj]))
    return array2?.map(obj => {
      const mergedObj = { ...obj }
      const existingObj = map?.get(obj?.name)
      if (existingObj) {
        mergedObj.checked = existingObj?.checked || mergedObj?.checked
      }
      return mergedObj
    })
  }

  useEffect(() => {
    let data = convertedArray?.map(item => item?.value)
    setCheckAll(JSON.stringify(department) === JSON.stringify(data))
  }, [department, convertedArray])
  const [dep, isDep] = useState(false)
  useEffect(() => {
    if (dep) {
      let chec = checkedList
      let toRemove = clickedValueInDep
      let data = [clickedValueInDep]
      if (chec?.includes(toRemove)) {
        // Data exists in the array, so remove it
        let removedData = chec?.filter(item => item !== clickedValueInDep)
        setCheckedList(removedData)
      } else {
        // Data doesn't exist in the array, so add it
        let remainingData = [...new Set(chec?.concat(data))]
        let removedData = remainingData?.filter(item => item !== '')
        setCheckedList(removedData)
      }
      setClickedValueInDep('')
    }
  }, [clickedValueInDep, dep])

  const onChange = list => {
    if (
      (checkedList?.length >= 1 && list?.length !== checkedList?.length) ||
      (checkedList?.length >= 1 && list?.length == 1)
    ) {
      isDep(true)
    } else {
      isDep(false)
      setCheckedList(list)
    }
    if (specValue?.[0]?.id && !list?.includes(specValue?.[0]?.id)) {
      setSpecValue([])
    }
    if (depList?.length !== list?.length) {
      const filteredArray = depList?.map(obj => {
        if (!list?.includes(obj?.id)) {
          return {
            ...obj,
            specialisation: obj?.specialisation?.map(specObj => {
              return { ...specObj, checked: false }
            }),
          }
        }
        return obj
      })
      // setDepList(filteredArray)
    }
    setDepartment(list)
    setIndeterminate(!!list?.length && list?.length < convertedArray?.length)
    setCheckAll(list?.length === convertedArray?.length)
    let SpecializationFilter = departmentList?.filter(item =>
      list?.includes(item?.id)
    )
    let depName = SpecializationFilter?.[0]?.name
    setDepartmentName(depName)
    let filterOptions = SpecializationFilter?.map(item => {
      return { id: item?.id, specialisation: item?.specialisation }
    })

    const combinedArray = filterOptions?.map(item => {
      return {
        specialisation: item?.specialisation?.map(data => {
          return {
            ...data,
            checked: false,
            degreeStreamId: item?.id,
          }
        }),
      }
    })
    let a = combinedArray?.map(item => item?.specialisation)
    const combinedArray2 = a ? [].concat(...a) : []
    const mergedArray = merge_arrays(Specialization, combinedArray2)
    setSpecialization(mergedArray?.length > 0 ? mergedArray : combinedArray2)
  }

  const handleChange = e => {
    setSearch(e)
  }
  const handleSpecChanges = event => {
    setSpecSearch(event)
  }

  useEffect(() => {
    if (isediting) {
      let specID = carryParam?.courseSpecialisationMap?.map(
        item => item?.specialisation
      )
      let id = specID?.map(item => item?.id)
      onChange([carryParam?.degreeStreamMapId])

      if (carryParam?.courseSpecialisationMap?.length > 0) {
        setDepartmentId(carryParam?.degreeStreamMapId)
      } else {
        setDepartmentId('')
      }
      let idsToUpdate = id
      let updated_a = departmentList?.map(obj => ({
        id: obj?.id,
        name: obj?.name,
        checked: false,
        specialisation: obj?.specialisation?.map(sub_obj => ({
          id: sub_obj?.id,
          name: sub_obj?.name,
          checked: idsToUpdate?.includes(sub_obj?.id)
            ? true
            : sub_obj?.checked || false,
        })),
      }))
      setDepList(updated_a)
    }
  }, [isediting, departmentList])

  const conditionCheck = () => {
    const check = specValue?.[0]?.specialisation?.filter(v => {
      if (specSearch === '') {
        return true
      } else {
        return v?.name?.toLocaleLowerCase()?.includes(specSearch?.toLowerCase())
      }
    })
    return check?.every(fruit => fruit?.checked)
  }

  useEffect(() => {
    conditionCheck()
  }, [specSearch])

  return (
    <MenuFlex column>
      <Row>
        <ColoContent>Department</ColoContent>
        <Divider
          type="vertical"
          style={{
            height: '60px',
            border: '1px solid #E6E7EA',
          }}
        />

        <ColoContent>Specialization</ColoContent>
        <SubmitFlex style={{ width: '100%' }} />
      </Row>
      <Row>
        <ScrollBar
          id="scrollableDiv"
          height={'40vh'}
          overFlowX="hidden"
          style={{ width: '46%' }}
        >
          <ColoItem>
            <>
              <div style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                <SearchInput
                  width={'90%'}
                  placeholder="Search by keyword"
                  bordered={true}
                  setSearchValue={handleChange}
                />
              </div>
              {departmentList?.length > 0 ? (
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
              ) : (
                <Checkbox disabled>Select All</Checkbox>
              )}
              <div style={{ display: 'flex', gap: '5%', paddingTop: '10px' }}>
                <div style={{ width: '70%' }}>
                  <StyledCheckboxGroup
                    options={convertedArray}
                    value={checkedList || departmentValue}
                    defaultValue={departmentValue}
                    onChange={onChange}
                  />
                </div>
                <div style={{ width: '15%' }}>
                  <StyledCheckboxGroup options={convertedArray1} />
                </div>
              </div>
            </>
          </ColoItem>
        </ScrollBar>
        <Divider
          type="vertical"
          style={{
            height: '380px',
            border: '1px solid #E6E7EA',
          }}
        />

        <ScrollBar id="scrollableDiv" height={'40vh'} style={{ width: '50%' }}>
          {specValue?.[0]?.specialisation?.length > 0 ? (
            <ColoItem>
              <DepartColumn>
                <div>
                  <SearchInput
                    width={'90%'}
                    placeholder="Search by keyword"
                    bordered={true}
                    setSearchValue={handleSpecChanges}
                  />
                </div>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    name="select-all"
                    checked={conditionCheck()}
                    onChange={handleCheckboxChange}
                    style={{
                      width: '15px',
                      outline: 'unset',
                      outlineOffset: 'unset',
                    }}
                  />
                  Select All
                </CheckboxLabel>
                {specValue?.[0]?.specialisation
                  ?.filter(v => {
                    if (specSearch === '') {
                      return true
                    } else {
                      return v?.name
                        ?.toLocaleLowerCase()
                        ?.includes(specSearch?.toLowerCase())
                    }
                  })
                  ?.map(fruit => (
                    <CheckboxLabel key={fruit?.name} id={fruit?.id}>
                      <input
                        style={{
                          width: '15px',
                          outline: 'unset',
                          outlineOffset: 'unset',
                        }}
                        type="checkbox"
                        name={fruit?.name}
                        id={fruit?.id}
                        checked={fruit?.checked}
                        onChange={handleCheckboxChange}
                      />
                      {fruit?.name}
                    </CheckboxLabel>
                  ))}
              </DepartColumn>
            </ColoItem>
          ) : (
            <div style={{ margin: '50px' }}>
              There is no Specialization in the department...
            </div>
          )}
        </ScrollBar>
      </Row>
    </MenuFlex>
  )
}
export default DepartmentAndSpecialization
