import Button from 'components/Button/index'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import StatusFilter from 'modules/Users/Partials/UsersFilter/Partials/StatusFilter'
import UserRoleFilter from 'modules/Users/Partials/UsersFilter/Partials/UserRoleFilter'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Users/Partials/UsersFilter/Styles/style'
import React, { useState } from 'react'

const UsersFilter = ({
  searchValue,
  setSearchValue,
  userRole,
  userRoleValue,
  setUserRoleValue,
  userStatus,
  setUserStatus,
  getUserData,
  setState,
  userList,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [filterData, setFilterData] = useState({})

  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      role: userRoleValue,
      status: userStatus,
    })
    getUserData()
    setState(userStatus || 'all')
  }

  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setUserRoleValue(filterData?.role)
    setUserStatus(filterData?.status)
  }

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'User Role',
            key: '1',
            children: (
              <UserRoleFilter
                userRole={userRole}
                userRoleValue={userRoleValue}
                setUserRoleValue={setUserRoleValue}
              />
            ),
          },
          {
            label: 'Status',
            key: '2',
            children: (
              <StatusFilter
                userStatus={userStatus}
                setUserStatus={setUserStatus}
              />
            ),
          },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )

  return (
    <TableSearchFilter
      placeholder={'Search by Name'}
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      currentCount={
        userList?.count < 10
          ? userList?.count
          : userList?.currentPage * 10 - 10 + userList?.list?.length
      }
      totalCount={userList?.count}
      onOpenChange={onCancelFilter}
    />
  )
}

export default UsersFilter
