import { Col, Row } from 'antd'
import FileReaderViewOnly from 'components/Uploader/FileReaderViewOnly'
import {
  DetailHeader,
  HorizontalDivider,
  LeftText,
  RightText,
} from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Style/style'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const PreviewDetails = () => {
  const formDetail = useSelector(state => state?.formData?.formData)
  const [formData, setFormData] = useState([])
  const [markSheetFile, setMarkSheetFile] = useState(formDetail?.contractURL)

  useEffect(() => {
    let previewData = [
      {
        id: 1,
        name: 'Website',
        value: formDetail.website,
      },
      {
        id: 2,
        name: 'Country',
        value: formDetail.country,
      },
      {
        id: 3,
        name: 'State',
        value: formDetail.state,
      },
      {
        id: 4,
        name: 'City',
        value: formDetail.city,
      },
      {
        id: 5,
        name: 'Zipcode',
        value: formDetail.zipcode,
      },
      {
        id: 6,
        name: 'Contact Name',
        value: formDetail.contactPerson,
      },
      {
        id: 7,
        name: 'Email Id',
        value: formDetail.email,
      },
      {
        id: 8,
        name: 'Contact Number',
        value: formDetail.phoneNumber,
      },
    ]
    setFormData(previewData)
  }, [formDetail])

  return (
    <>
      <DetailHeader>{formDetail?.corporateName}</DetailHeader>
      {formData?.map((item, id) => (
        <Row key={item?.id} style={{ width: '100%' }}>
          <Col span={12}>
            <LeftText>{item.name}</LeftText>
          </Col>
          <Col span={12}>
            <RightText style={{ wordBreak: 'break-all' }}>
              {item.value}
            </RightText>
          </Col>
        </Row>
      ))}
      {markSheetFile && (
        <>
          <HorizontalDivider />
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <LeftText>
                <DetailHeader>Contract</DetailHeader>
              </LeftText>
            </Col>
            <Col span={12}>
              {' '}
              <FileReaderViewOnly
                file={markSheetFile}
                setFile={setMarkSheetFile}
                showDeleteIcon={false}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default PreviewDetails
