import React from 'react'
import styled from 'styled-components'

const SvgIcon = styled.svg`
  cursor: pointer;
`

const LinkIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 17C5.61667 17 4.43767 16.5123 3.463 15.537C2.48767 14.5623 2 13.3833 2 12C2 10.6167 2.48767 9.43733 3.463 8.462C4.43767 7.48733 5.61667 7 7 7H10C10.2833 7 10.521 7.09567 10.713 7.287C10.9043 7.479 11 7.71667 11 8C11 8.28333 10.9043 8.52067 10.713 8.712C10.521 8.904 10.2833 9 10 9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H10C10.2833 15 10.521 15.0957 10.713 15.287C10.9043 15.479 11 15.7167 11 16C11 16.2833 10.9043 16.5207 10.713 16.712C10.521 16.904 10.2833 17 10 17H7ZM9 13C8.71667 13 8.47933 12.904 8.288 12.712C8.096 12.5207 8 12.2833 8 12C8 11.7167 8.096 11.479 8.288 11.287C8.47933 11.0957 8.71667 11 9 11H15C15.2833 11 15.521 11.0957 15.713 11.287C15.9043 11.479 16 11.7167 16 12C16 12.2833 15.9043 12.5207 15.713 12.712C15.521 12.904 15.2833 13 15 13H9ZM14 17C13.7167 17 13.4793 16.904 13.288 16.712C13.096 16.5207 13 16.2833 13 16C13 15.7167 13.096 15.479 13.288 15.287C13.4793 15.0957 13.7167 15 14 15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H14C13.7167 9 13.4793 8.904 13.288 8.712C13.096 8.52067 13 8.28333 13 8C13 7.71667 13.096 7.479 13.288 7.287C13.4793 7.09567 13.7167 7 14 7H17C18.3833 7 19.5627 7.48733 20.538 8.462C21.5127 9.43733 22 10.6167 22 12C22 13.3833 21.5127 14.5623 20.538 15.537C19.5627 16.5123 18.3833 17 17 17H14Z"
          fill="#CCCFD6"
        />
      </svg>
    </div>
  )
}

export default LinkIcon
//mask="url(#mask0_1510_1547)"
