import React from 'react'

const ArrowDown = ({ color = '#CCCFD6' }) => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30001 4.8L0.700012 2.2C0.383345 1.88333 0.312679 1.521 0.488012 1.113C0.662679 0.704333 0.975012 0.5 1.42501 0.5H6.57501C7.02501 0.5 7.33735 0.704333 7.51201 1.113C7.68735 1.521 7.61668 1.88333 7.30001 2.2L4.70001 4.8C4.60001 4.9 4.49168 4.975 4.37501 5.025C4.25835 5.075 4.13335 5.1 4.00001 5.1C3.86668 5.1 3.74168 5.075 3.62501 5.025C3.50835 4.975 3.40001 4.9 3.30001 4.8Z"
        fill="#CCCFD6"
      />
    </svg>
  )
}

export default ArrowDown
