import { Col, Row, Switch } from 'antd'
import AntdInput from 'components/Form/Input'
import 'draft-js/dist/Draft.css'
import { Form } from 'modules/RankingAlgorithm/Styles/FormStyle.js'
import {
  ActiveHeader,
  EventHeader,
  EventHeader1,
  EventHeader2,
  Note,
  TopHeader2,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { Fragment, useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../../style.css'
export default function TierIndex({
  singleAcademicData,
  singleAcademicTierData,
  academicListSelection,
  setSingleAcademicTierData,
  setAcademicToggle,
  academicToggle,
}) {
  const [form] = Form.useForm()
  const [keysList, setKeysList] = useState(singleAcademicTierData)
  useEffect(() => {
    setKeysList(singleAcademicTierData)
  }, [singleAcademicTierData, academicListSelection])
  const onClickField = (value, index, type) => {
    let academic = keysList.map((data, indx) => {
      if (index === indx) {
        return {
          ...data,
          [type]: value,
        }
      } else {
        return data
      }
    })
    setKeysList(academic)

    form.setFieldsValue({
      academic: academic,
    })
  }
  useEffect(() => {
    form.setFieldsValue({
      academic: keysList,
    })
    setSingleAcademicTierData(keysList)
  }, [keysList])
  const validateInput = (value, name) => {
    const regex = name === 'score' ? /^\d*\.?\d*$/ : /^\d*$/ // Only allow numbers and the period (.)
    return regex.test(value)
  }
  const handleKeyPress = (event, name) => {
    // Perform custom validation based on keypress
    const data = event.target.value + event.key
    const number = name === 'score' ? 5 : name === 'toMonth' ? 11 : 1000

    if (Number(data) > number || !validateInput(event.key, name)) {
      event.preventDefault() // Prevent the input from updating if it's not valid
    }
  }

  const onFinish = (data, value) => {}
  return (
    <div>
      <Form
        id={'uploadForm'}
        form={form}
        onFinish={onFinish}
        initialValues={{
          academic: keysList,
        }}
      >
        <Form.List name="academic">
          {(fields, { add, remove }) => {
            return (
              <>
                <Row>
                  <TopHeader2 row spaceBetween alignCenter>
                    <Col span={15}>
                      <EventHeader>{academicListSelection} </EventHeader>
                    </Col>
                    <Col span={6}>
                      <ActiveHeader>
                        {' '}
                        {academicToggle ? 'Active' : 'Inactive'}
                      </ActiveHeader>
                    </Col>
                    <Col span={3}>
                      <div style={{ display: 'flex', marginLeft: '10%' }}>
                        <Switch
                          checked={academicToggle}
                          onChange={checked => setAcademicToggle(checked)}
                        />
                      </div>
                    </Col>
                  </TopHeader2>
                </Row>
                <Row>
                  <Col span={4}>
                    <EventHeader1>Tier</EventHeader1>
                  </Col>
                  <Col span={4}>
                    <EventHeader1>Score</EventHeader1>
                  </Col>
                </Row>
                {fields.map(({ key, name }, index) => (
                  <Fragment key={name}>
                    <Row>
                      <Col span={4}>
                        <EventHeader2>
                          {'Tier' + ' ' + Number(name + 1)}
                        </EventHeader2>
                      </Col>
                      <Col span={4}>
                        <AntdInput
                          ismargin
                          key={name}
                          name={[name, 'score']}
                          onChange={e =>
                            onClickField(e.target.value, name, 'score')
                          }
                          onKeyPress={e => handleKeyPress(e, 'score')}
                          rules={[
                            {
                              required: true,
                              message: 'Kindly enter the data',
                            },

                            {
                              validator: (_, value) => {
                                if (value) {
                                  if (
                                    !/^(?:[0-5](?:\.\d{1,2})?)$/.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        'Kindly enter valid Number. Eg: 5.00'
                                      )
                                    )
                                  }
                                }
                                if (name === 0) {
                                  if (Number(value) > 5) {
                                    return Promise.reject(
                                      new Error('The Max score is 5')
                                    )
                                  }
                                }
                                // else {
                                //   if (
                                //     Number(value) >=
                                //     Number(keysList[name - 1]?.score)
                                //   ) {
                                //     return Promise.reject(
                                //       'Score Should be lesser than ' +
                                //         keysList[name - 1]?.score
                                //     )
                                //   }
                                // }
                                return Promise.resolve()
                              },
                            },
                          ]}
                          value={keysList?.[name]?.score}
                          height="50px"
                          width="80%"
                          borderRadius="10px"
                          backgroundColor="#FAFAFB"
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ))}
                <Row>
                  <Note>NOTE: Maximum Weightage Value is 5</Note>
                </Row>
              </>
            )
          }}
        </Form.List>
      </Form>
    </div>
  )
}
