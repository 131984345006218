const CONSTANTS = {
  REGEX: {
    EMAIL: /^\s*[\w.%+-]+@[a-z0-9.-]+\.[a-z]{2,}\s*$/i,
    SPACE_PATTERN: /^[a-zA-Z]+(?:[\s.]*[a-zA-Z0-9]+)*$/,
    NAME: /^[a-zA-Z0-9\s.#-]+$/,
    NO_NUMERIC: /^[a-zA-Z\s.,#-]+$/g,
    WEBSITE: /^(?:https?:\/\/)?(?:www\.)?[\w-]+\.[\w.-]+(?:\/[\w-]*)*\/?$/,
    MOBILE_NUMBER: /^\d{10}$/,
    PIN_CODE: /^\d{6}$/,
    ONLY_NUMBER: /^\d+$/,

    WEB_URL:
      /^(?:https?:\/\/)?(?:www\.)?[\w.-]+\.[a-z]{2,}(?:\/[\w-.~:/?#[\]@!$&'()*+,;=]*)?$/i,

    PAN: /^[A-Z]{5}\d{4}[A-Z]$/,
    GST: /^\d{2}[A-Z]{5}\d{4}[A-Z]\d[A-Z]\d$/,
    ADDRESS1: /^[a-zA-Z0-9\s.,#-]+$/,
    ADDRESS2: /^[a-zA-Z0-9\s.,#-]+$/,
    NON_SPACE: /\S+/,
    CIN_NUMBER: /^([LUu])(\d{5})([A-Za-z]{2})(\d{4})([A-Za-z]{3})(\d{6})$/,
  },
  ERROR_MESSAGE: {
    REQ_SELECT: name => `Please select a ${name}!`,
    REQ_INPUT: name => `Please enter a ${name}!`,
    VALID: name => `Please enter a valid ${name}!`,
  },
  DATA: {
    COUNTRY_CODE: [],
  },
  ValidatePasswordInput: (rule, value, callback) => {
    if (value?.includes(' ')) {
      callback('Spaces are not allowed')
    } else if (value && value?.length < 8) {
      callback(
        'Password should be alpha numeric with special characters. Minimum length is 8 characters.'
      )
    } else {
      callback()
    }
  },
  ValidateNameInput: (rule, value, callback, message) => {
    if (!/^[a-zA-Z]+$/.test(value)) {
      callback('Please Enter Valid Name')
    } else {
      callback()
    }
  },
}

export default CONSTANTS

export const getTooltipTitle = orderBy => {
  if (orderBy === 'asc') {
    return 'Click to sort descending'
  } else if (orderBy === 'desc') {
    return 'Click to sorting'
  }
  return 'Click to sort ascending'
}

export const fixedSorterColumn = sortBy => {
  return {
    title: getTooltipTitle(sortBy),
    placement: 'bottom',
    overlayClassName: 'table-container-tooltip',
    getPopupContainer: trigger => trigger.parentNode,
  }
}

export const YearList = [
  {
    value: '2023',
    name: '2023',
  },
  {
    value: '2022',
    name: '2022',
  },
  {
    value: '2021',
    name: '2021',
  },
  {
    value: '2020',
    name: '2020',
  },
  {
    value: '2019',
    name: '2019',
  },
  {
    value: '2018',
    name: '2018',
  },
  {
    value: '2017',
    name: '2017',
  },
  {
    value: '2016',
    name: '2016',
  },
  {
    value: '2015',
    name: '2015',
  },
  {
    value: '2014',
    name: '2014',
  },
  {
    value: '2013',
    name: '2013',
  },
  {
    value: '2012',
    name: '2012',
  },
  {
    value: '2011',
    name: '2011',
  },
  {
    value: '2010',
    name: '2010',
  },
  {
    value: '2009',
    name: '2009',
  },
  {
    value: '2008',
    name: '2008',
  },
  {
    value: '2007',
    name: '2007',
  },
  {
    value: '2006',
    name: '2006',
  },
  {
    value: '2005',
    name: '2005',
  },
  {
    value: '2004',
    name: '2004',
  },
  {
    value: '2003',
    name: '2003',
  },
  {
    value: '2002',
    name: '2002',
  },
  {
    value: '2001',
    name: '2001',
  },
  {
    value: '2000',
    name: '2000',
  },
  {
    value: '1999',
    name: '1999',
  },
  {
    value: '1998',
    name: '1998',
  },
  {
    value: '1997',
    name: '1997',
  },
  {
    value: '1996',
    name: '1996',
  },
  {
    value: '1995',
    name: '1995',
  },
  {
    value: '1994',
    name: '1994',
  },
  {
    value: '1993',
    name: '1993',
  },
  {
    value: '1992',
    name: '1992',
  },
  {
    value: '1991',
    name: '1991',
  },
  {
    value: '1990',
    name: '1990',
  },
  {
    value: '1989',
    name: '1989',
  },
  {
    value: '1988',
    name: '1988',
  },
  {
    value: '1987',
    name: '1987',
  },
  {
    value: '1986',
    name: '1986',
  },
  {
    value: '1985',
    name: '1985',
  },
  {
    value: '1984',
    name: '1984',
  },
  {
    value: '1983',
    name: '1983',
  },
  {
    value: '1982',
    name: '1982',
  },
  {
    value: '1981',
    name: '1981',
  },
  {
    value: '1980',
    name: '1980',
  },
  {
    value: '1979',
    name: '1979',
  },
  {
    value: '1978',
    name: '1978',
  },
  {
    value: '1977',
    name: '1977',
  },
  {
    value: '1976',
    name: '1976',
  },
  {
    value: '1975',
    name: '1975',
  },
  {
    value: '1974',
    name: '1974',
  },
  {
    value: '1973',
    name: '1973',
  },
  {
    value: '1972',
    name: '1972',
  },
  {
    value: '1971',
    name: '1971',
  },
  {
    value: '1970',
    name: '1970',
  },
]

export const journeyDropdown = [
  {
    value: 'Internal',
    id: 'INTERNAL',
  },
  {
    value: 'Corporate',
    id: 'CORPORATE',
  },
  {
    value: 'Institute',
    id: 'INSTITUTE',
  },
  {
    value: 'Student',
    id: 'STUDENT',
  },
]
