/* eslint-disable */
import Flex from 'components/Flex'
import SidebarPage from 'components/SidebarPage'
import Header from 'modules/Layouts/Components/Partials/Header'
import Nav from 'modules/Nav/Container/index'
import React, {  useLayoutEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from 'theme'

const BREAKPOINT = '999px'

const ContentWrapper = styled(Flex)`
  overflow-y: auto;
  border-radius: 10px;
  margin-bottom: 0%;
  /* margin-top: 7%; */
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.35);
  border: 1px solid ${COLORS.PAGE_BORDER};
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc;
  }
`
const PageWrapper = styled(Flex)`
  background: '#FFFFFF';
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  flex-grow: 1;
  @media screen and (max-width: ${BREAKPOINT}) {
    padding: 0px;
    overflow-x: hidden;
    box-sizing: border-box;
  }
`

const Page = ({
  children,
  corporateDetails,
  corporateData,
  userData,
  getUserInfo,
  roleList,
  getRoles,
  getUserPermissions,
}) => {
  useLayoutEffect(() => {
    getUserInfo()
    getRoles()
  }, [])

  useLayoutEffect(() => {
    if (userData?.admin_role_id !== undefined) {
      getUserPermissions(userData?.admin_role_id)
    }
  }, [userData])

  return (
    <PageWrapper>
      <Header
        corporateData={corporateData}
        userData={userData}
        roleList={roleList}
        getRoles={getRoles}
        getUserInfo={getUserInfo}
      />
      <Flex>
        <Nav />
        <SidebarPage>
          <ContentWrapper column>{children}</ContentWrapper>
        </SidebarPage>
      </Flex>
    </PageWrapper>
  )
}
export default Page
