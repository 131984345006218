import { Form as AntdForm, Select as AntdSelect, Tooltip } from 'antd'
import Flex from 'components/Flex'
import Label from 'components/Form/Label'
import CrossIcon from 'components/icons/CrossIcon'
import SelectArrowIcon from 'components/icons/SelectArrowIcon'
import React from 'react'
import styled from 'styled-components'

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  width: 100%;
  .ant-form-item-explain-error {
    padding-left: ${props => (props.padding ? '15%' : '0')};
  }
`

const { Option } = AntdSelect

const StyledSelect = styled(AntdSelect)`
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #010e30;
  text-transform: capitalize;

  .ant-select-selector {
    background-color: ${props =>
      !props.disabled
        ? props.backgroundcolor
          ? '#fafafb'
          : '#ffffff'
        : '#f5f5f5'} !important;
    border-color: #e9e9e9 !important;
    box-shadow: none !important;
    border-left: ${props => (props.borderLeft ? props.borderLeft : '')};
    border: ${props => (!props.bordered ? 'unset !important ' : '')};
    border-radius: ${props =>
      props.borderRadius ? props.borderRadius : '8px !important'};
    height: ${props => (props.height ? props.height : '')};
  }
  .ant-select-selection-item {
    background-color: ${props =>
      !props.disabled
        ? props?.mode === 'multiple' || props?.mode === 'tags'
          ? '#ebecee'
          : '#ffffff'
        : '#f5f5f5'};
    border-radius: 6px;
    color: ${props => !props.disabled && '#010e30'};
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    background-color: ${props =>
      !props.disabled
        ? props?.mode === 'multiple' || props?.mode === 'tags'
          ? '#ebecee'
          : '#ffffff'
        : '#f5f5f5'};
  }
  :hover {
    border-radius: 6px;
    color: #010e30;
  }
  .ant-select-dropdown-placement-bottomLeft {
    width: 1000px !important;
  }
  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`
const LabelWrapper = styled.div`
  padding-bottom: 8px;
`

const Select = ({
  OptionData,
  placeholder,
  labelStyle,
  onChange,
  label,
  value,
  position = 'relative',
  width = '100%',
  defaultValue,
  loading,
  bordered = true,
  valueParam = 'id',
  nameParam = 'name',
  showSearch = false,
  backgroundcolor,
  name,
  rules,
  mode,
  disabled = false,
  iscampusLocationshow = false,
  isShortName = false,
  onSearch,
  borderRadius,
  borderLeft,
  height,
  isTooltip = false,
  iskey = false,
  listHeight = 200,
  ...rest
}) => {
  return (
    <FormItem name={name} rules={rules} {...rest} key={name}>
      <Flex
        column
        style={{
          width: width,
          position: position,
        }}
        id="select"
      >
        {label && (
          <LabelWrapper>
            <Label labelStyle={labelStyle}>{label}</Label>
          </LabelWrapper>
        )}

        <StyledSelect
          showSearch={true}
          suffixIcon={<SelectArrowIcon />}
          placeholder={placeholder}
          onChange={onChange}
          size={'large'}
          value={value || undefined}
          loading={loading}
          bordered={bordered}
          defaultValue={defaultValue}
          backgroundcolor={!value}
          removeIcon={<CrossIcon />}
          mode={mode}
          getPopupContainer={() => document.getElementById('select')}
          disabled={disabled}
          onSearch={onSearch}
          filterOption={(inputValue, option) =>
            option[nameParam]
              ?.toLowerCase()
              ?.indexOf(inputValue?.toLowerCase()) !== -1
          }
          borderLeft={borderLeft}
          borderRadius={borderRadius}
          height={height}
          listHeight={listHeight}
          {...rest}
        >
          {OptionData?.map(option => {
            return (
              <Option
                style={{
                  padding: '10px 12px',
                }}
                // key={option[valueParam]}
                key={iskey ? option?._id : option?.id}
                value={option[valueParam]}
                id={option?.id}
                name={option[nameParam]}
                option={option}
                formName={name}
              >
                <Tooltip
                  title={`${option[nameParam]}${
                    isShortName && option[nameParam] !== 'Other'
                      ? ` (${option['shortForm']})`
                      : ''
                  }${
                    iscampusLocationshow ? ` , ${option?.campusLocation}` : ''
                  }`}
                  placement="left"
                >
                  {option[nameParam]}{' '}
                  {isShortName && option[nameParam] != 'Other'
                    ? `(` + option['shortForm'] + `)`
                    : ''}{' '}
                  {iscampusLocationshow ? ' , ' + option?.campusLocation : ''}
                </Tooltip>
              </Option>
            )
          })}
        </StyledSelect>
      </Flex>
    </FormItem>
  )
}

export default Select
