import React from 'react'
import {
  StyledGroup,
  StyledCheckbox,
} from 'components/UIComponents/FilterCheckBoxComp/style'
import Flex from 'components/Flex'
import { RowNumberDataReport } from '../../../TableStyles/TableContentStyles/TableContentStyles'

const FilterCheckBoxComp = ({
  value,
  options,
  onChange,
  valueParam = 'value',
  labelParam = 'label',
  onChangeCheck,
}) => {
  return (
    <StyledGroup value={value} onChange={onChange}>
      {options?.map((item, id) => (
        <Flex spaceBetween centerVertically key={item?.id}>
          <StyledCheckbox
            value={item[valueParam]}
            label={
              <RowNumberDataReport>{item[labelParam]}</RowNumberDataReport>
            }
            onChange={onChangeCheck}
          />
        </Flex>
      ))}
    </StyledGroup>
  )
}

export default FilterCheckBoxComp
