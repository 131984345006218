import Button from 'components/Button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Container,
  Content,
  Heading,
  Para,
  Wrapper,
} from 'modules/Onboarding/Components/RegisterSuccessful/RegisterSuccessfulPage/style'
import Group from 'components/Images/Group.png'
import Flex from 'components/Flex'

const RegisterSuccessfulPage = () => {
  const navigate = useNavigate()

  return (
    <Container alignCenter column>
      <Wrapper>
        <Content alignCenter column center>
          <Flex>
            <img src={Group} alt='group' />
          </Flex>
          <Flex column>
            <Heading center>Registered Successfully.</Heading>
            <Para>
              The form has been sent to the Plugin team to verify and confirm
              onboarding.
            </Para>
          </Flex>
          <Button.Secondary
            center
            text="Close"
            onClick={() => {
              navigate('/onboarding')
            }}
          />
        </Content>
      </Wrapper>
    </Container>
  )
}

export default RegisterSuccessfulPage
