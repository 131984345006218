const getSingleCorporateData = state =>
  state?.CorporateOnboarding?.singleCorporateData

const getCorporateFormInfo = state =>
  state?.CorporateOnboarding?.corporateFormInfo

const getaddInstituteDrawer = state => state?.CorporateOnboarding?.addInstitute

const selectors = {
  getSingleCorporateData,
  getCorporateFormInfo,
  getaddInstituteDrawer,
}

export default selectors
