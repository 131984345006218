import CorporateIcon from 'modules/Nav/icons/CorporateIcon'
import InstituteIcon from 'modules/Nav/icons/InstituteIcon'
import OnboardingIcon from 'modules/Nav/icons/OnboardingIcon'
import Systems from 'modules/Nav/icons/Systems'
import Users from 'modules/Nav/icons/Users'
import EventIcon from 'modules/Nav/icons/EventIcon'
import Speed from 'modules/Nav/icons/SpeedIcon'
import RankIcon from 'modules/Nav/icons/RankIcon'
export const navItems = [
  // {
  //   path: '/dashboard',
  //   icon: DasgboardIcon,
  //   navTitle: 'Dashboard',
  //   pageTitle: 'Dashboard',
  //   menuType: 'GlobalMenu',
  // },
  {
    path: '/onboarding',
    icon: OnboardingIcon,
    navTitle: 'Onboarding',
    pageTitle: 'Onboarding',
    menuType: 'GlobalMenu',
  },
  {
    path: '/corporates',
    icon: CorporateIcon,
    navTitle: 'Corporates',
    pageTitle: 'Corporates',
    menuType: 'GlobalMenu',
  },
  {
    path: '/institutes',
    icon: InstituteIcon,
    navTitle: 'Institutes',
    pageTitle: 'Institutes',
    menuType: 'GlobalMenu',
  },
  // {
  //   path: '/exstudents',
  //   icon: ExStudents,
  //   navTitle: 'Talents/Ex-Stud.,',
  //   pageTitle: 'Talents/Ex-students',
  //   menuType: 'GlobalMenu',
  // },
  // {
  //   path: '/payments',
  //   icon: Payments,
  //   navTitle: 'Payments',
  //   pageTitle: 'Payments',
  //   menuType: 'GlobalMenu',
  // },
  {
    path: '/reports',
    icon: Speed,
    navTitle: 'Reports',
    pageTitle: 'Reports',
    menuType: 'GlobalMenu',
    externalMenu: [
      {
        path: '/reports/corporate',
        icon: Systems,
        navTitle: 'Corporate',
        pageTitle: 'Reports',
        menuType: 'GlobalMenu',
        subMenu: [
          {
            path: '/',
            label: ' List of Corporate ',
            key: '/reports',
          },
          {
            path: '/empanelledinfotable',
            label: ' List of Corp. Empanelled',
            key: '/onboarding',
          },
          {
            path: '/top10index',
            label: 'Top/Bottom Corporate',
            key: '/onboarding',
          },
        ],
      },
      {
        path: '/reports/institute',
        icon: Systems,
        navTitle: 'Institutes',
        pageTitle: 'Reports',
        menuType: 'GlobalMenu',
        subMenu: [
          {
            path: '/',
            label: 'List of Institutes',
            key: '0',
          },
          {
            path: '/empanelledinfotable',
            label: ' List of Inst. Empanelled',
            key: '1',
          },

          {
            path: '/top10index',
            label: 'Top 10 Institutes',
            key: '3',
          },
        ],
      },
      {
        path: '/reports/students',
        icon: Systems,
        navTitle: 'Student',
        pageTitle: 'Student',
        menuType: 'GlobalMenu',
        subMenu: [
          {
            path: '/',
            label: 'List of Students',
            key: '5',
          },
          {
            path: '/listofstudentspalced',
            label: ' List of Students Placed',
            key: '6',
          },

          {
            path: '/studentplacedscoursewise',
            label: 'Stud. Placed-Course-Wise',
            key: '7',
          },
          {
            path: '/studentskillwise',
            label: 'Stud. Placed-Skill-Wise',
            key: '8',
          },
        ],
      },
      // {
      //   path: '/reports/admin',
      //   icon: Systems,
      //   navTitle: 'Admin',
      //   pageTitle: 'Admin',
      //   menuType: 'GlobalMenu',
      // },
    ],
  },
  {
    path: '/users',
    icon: Users,
    navTitle: 'Users',
    pageTitle: 'Users',
    menuType: 'GlobalMenu',
  },
  {
    path: '/systemConfig',
    icon: Systems,
    navTitle: 'System Config',
    pageTitle: 'System Config',
    menuType: 'GlobalMenu',
  },
  {
    path: '/coursemapping',
    icon: OnboardingIcon,
    navTitle: 'Course Mapping',
    pageTitle: 'Course Mapping',
    menuType: 'GlobalMenu',
  },
  {
    path: '/eventcatalogue',
    icon: EventIcon,
    navTitle: 'Event Catalogue',
    pageTitle: 'Event Catalogue',
    menuType: 'GlobalMenu',
  },

  {
    path: '/rankingAlgorithm',
    icon: RankIcon,
    navTitle: 'Rating Algorithm',
    pageTitle: 'Rating Algorithm',
    menuType: 'GlobalMenu',
  },
  // {
  //   path: '/settings',
  //   icon: SettingIcon,
  //   navTitle: 'Settings',
  //   pageTitle: 'Settings',
  //   menuType: 'GlobalMenu',
  // },
]
