export const Data = [
  ({
    id: '3a420d35-c1de-4a19-8241-7eae87f80d3c',
    screenName: 'MANAGE PROFILE',
    journey: 'STUDENT',
    orderNo: 2,
    events: [
      {
        id: '9a970200-d2e6-4dd0-a30e-0972ecfb943b',
        eventName: 'ACTION',
        subEvents: [],
      },
    ],
  },
  {
    id: '09acc1f6-368b-4005-b3b5-992c55060301',
    screenName: 'OFFER RELEASED',
    journey: 'STUDENT',
    orderNo: 3,
    events: [
      {
        id: '633c07fb-5d30-404f-b46d-8360dcf6bde0',
        eventName: 'ACTION',
        subEvents: [],
      },
    ],
  },
  {
    id: 'ba3eb8cb-7edd-4ca2-875a-3a5092e39c91',
    screenName: 'STUDENT',
    journey: 'STUDENT',
    orderNo: null,
    events: [
      {
        id: '8a08d9c0-1930-49d6-ac03-7e3f5a5833e2',
        eventName: 'MANAGE PROFILE',
        subEvents: [],
      },
      {
        id: 'd8c283b9-8103-4e4e-90bc-17ce4623808d',
        eventName: 'OFFER RELEASED',
        subEvents: [],
      },
    ],
  }),
]

export const ACADEMIC_MENU = [
  {
    id: '1',
    name: '10th',
  },
  {
    id: '2',
    name: '12th',
  },
  {
    id: '3',
    name: 'UG',
  },
  {
    id: '4',
    name: 'PG',
  },
  {
    id: '5',
    name: 'Diploma',
  },
  {
    id: '6',
    name: 'PGDM',
  },
  {
    id: '7',
    name: 'Phd',
  },
]
export const TIER_MENU = [
  {
    id: '3',
    name: 'UG',
  },
  {
    id: '4',
    name: 'PG',
  },
  {
    id: '5',
    name: 'Diploma',
  },
  {
    id: '6',
    name: 'PGDM',
  },
  {
    id: '7',
    name: 'Phd',
  },
]

export const TIER_DATA_ALONE = [
  {
    academic: 'UG',
    data: [
      {
        tier: 'Tier 1',
        score: '',
      },
    ],
  },
  {
    academic: 'PG',
    data: [
      {
        tier: 'Tier 1',
        score: '',
      },
    ],
  },
  {
    academic: 'Diploma',
    data: [
      {
        tier: 'Tier 1',
        score: '',
      },
    ],
  },
  {
    academic: 'PGDM',
    data: [
      {
        tier: 'Tier 1',
        score: '',
      },
    ],
  },
]
export const ACADEMIC_DATA_ALONE = [
  {
    academic: '10th',
    scoreConfig: [
      {
        from: '100',
        to: '50',
        score: '5',
      },
      {
        from: '49.99',
        to: '35',
        score: '3',
      },
      {
        from: '34.99',
        to: '20',
        score: '0',
      },
    ],
    isScoreActive: true,
    isTierActive: false,
    tierConfig: [],
  },
  {
    academic: '12th',
    scoreConfig: [
      {
        from: '100',
        to: '50',
        score: '5',
      },
      {
        from: '49.99',
        to: '35',
        score: '3',
      },
      {
        from: '34.99',
        to: '30',
        score: '0',
      },
    ],
    isScoreActive: false,
    isTierActive: true,
    tierConfig: [],
  },
  {
    academic: 'UG',
    scoreConfig: [
      {
        from: '100',
        to: '50',
        score: '5',
      },
      {
        from: '49.99',
        to: '35',
        score: '3',
      },
      {
        from: '34.99',
        to: '25',
        score: '0',
      },
    ],
    isScoreActive: false,
    isTierActive: true,
    tierConfig: [
      {
        tier: '1',
        score: '5',
      },
      {
        tier: '2',
        score: '4',
      },
      {
        tier: '3',
        score: '3',
      },
      {
        tier: '4',
        score: '2',
      },
      {
        tier: '5',
        score: '1',
      },
    ],
  },
  {
    academic: 'PG',
    scoreConfig: [
      {
        from: '100',
        to: '50',
        score: '5',
      },
      {
        from: '49.99',
        to: '35',
        score: '3',
      },
      {
        from: '34.99',
        to: '18',
        score: '0',
      },
    ],
    isScoreActive: false,
    isTierActive: false,
    tierConfig: [
      {
        tier: '1',
        score: '5',
      },
      {
        tier: '2',
        score: '4',
      },
      {
        tier: '3',
        score: '3',
      },
      {
        tier: '4',
        score: '2',
      },
      {
        tier: '5',
        score: '1',
      },
    ],
  },
  {
    academic: 'Diploma',
    scoreConfig: [
      {
        from: '100',
        to: '50',
        score: '5',
      },
      {
        from: '49.99',
        to: '35',
        score: '3',
      },
      {
        from: '34.99',
        to: '15',
        score: '0',
      },
    ],
    isScoreActive: false,
    isTierActive: false,
    tierConfig: [
      {
        tier: '1',
        score: '5',
      },
      {
        tier: '2',
        score: '4',
      },
      {
        tier: '3',
        score: '3',
      },
      {
        tier: '4',
        score: '2',
      },
      {
        tier: '5',
        score: '1',
      },
    ],
  },
  {
    academic: 'PGDM',
    scoreConfig: [
      {
        from: '100',
        to: '50',
        score: '5',
      },
      {
        from: '49.99',
        to: '35',
        score: '3',
      },
      {
        from: '34.99',
        to: '10',
        score: '0',
      },
    ],
    isScoreActive: false,
    isTierActive: false,
    tierConfig: [
      {
        tier: '1',
        score: '5',
      },
      {
        tier: '2',
        score: '4',
      },
      {
        tier: '3',
        score: '3',
      },
      {
        tier: '4',
        score: '2',
      },
      {
        tier: '5',
        score: '1',
      },
    ],
  },
]

export const TIER_DATA = [
  {
    tier: 'Tier 1',
    score: '',
  },
  {
    tier: 'Tier 2',
    score: '',
  },
  {
    tier: 'Tier 3',
    score: '',
  },
  {
    tier: 'Tier 4',
    score: '',
  },
]

export const DATA = [
  {
    id: '86835f15-9b3f-4754-a41c-58b89b836f52',
    config: null,
    orderNo: 1,
    isActive: true,
    menuName: 'Academic',
    menuType: 'ACADAMIC',
    createdAt: '2023-08-18T14:59:22.883Z',
    updatedAt: null,
  },
  {
    id: '4b21bc78-d57d-49f1-a41d-6221fdc0d6fe',
    config: null,
    orderNo: 2,
    isActive: true,
    menuName: 'Internship',
    menuType: 'INTERNSHIP',
    createdAt: '2023-08-18T14:59:22.883Z',
    updatedAt: null,
  },
  {
    id: '21c4cb94-5c23-40be-9c88-4b1cf680c5d4',
    config: null,
    orderNo: 3,
    isActive: true,
    menuName: 'Work Experience - Student / Master Student',
    menuType: 'WORK_EXPERIENCE',
    createdAt: '2023-08-18T14:59:22.883Z',
    updatedAt: null,
  },
  {
    id: '243b09e3-cb0a-4866-8250-b6632571796c',
    config: null,
    orderNo: 4,
    isActive: true,
    menuName: 'TrainingCourse',
    menuType: 'TRAINING_COURSE',
    createdAt: '2023-08-18T14:59:22.883Z',
    updatedAt: null,
  },
  {
    id: '6b1d8f2f-102a-42ac-b92c-3b11004f1a02',
    config: null,
    orderNo: 5,
    isActive: true,
    menuName: 'Project',
    menuType: 'PROJECT',
    createdAt: '2023-08-18T14:59:22.883Z',
    updatedAt: null,
  },
  {
    id: '3c81275e-8a89-4646-93df-4d7381f29db8',
    config: null,
    orderNo: 6,
    isActive: true,
    menuName: 'SkillBucket',
    menuType: 'SKILL_BUCKET',
    createdAt: '2023-08-18T14:59:22.883Z',
    updatedAt: null,
  },
]
export const INTERNSHIP_MENU = [
  {
    id: '1',
    name: 'Mandatory Skills',
  },
  {
    id: '2',
    name: 'Duration',
  },
]
export const ASSESSMENT = [
  {
    id: '1',
    name: 'Mandatory Skills',
  },
]
export const WORK_EXP_MENU = [
  {
    id: '1',
    name: 'Mandatory Skills',
  },
  {
    id: '2',
    name: 'Duration',
  },
  {
    id: '2',
    name: 'Domain',
  },
]
export const TRAINING_COURSE_MENU = [
  {
    id: '1',
    name: 'Mandatory Skills',
  },
  {
    id: '2',
    name: 'Duration',
  },
  {
    id: '3',
    name: 'Certificate',
  },
]
export const OPTIONS = [
  {
    name: 'month',
    value: 'month',
  },
  {
    name: 'year',
    value: 'year',
  },
]
export const SECOND_OPTIONS = [
  {
    name: '+ months',
    value: 'month',
  },
  {
    name: '+ years',
    value: 'year',
  },
]

export const DURATION = {
  mandatorySkills: { isActive: true, maxScore: 5 },
  duration: {
    config: [
      {
        from: 0,
        to: 10,
        score: 1,
        fromType: 'month',
        toType: 'month',
      },
      {
        from: '11',
        to: '3',
        score: 3,
        fromType: 'month',
        toType: 'year',
      },
      {
        from: '3',
        to: '0',
        score: '0',
        fromType: 'Year',
      },
    ],
    isActive: true,
  },
}
export const SKILL_BUCKET_MENU = [
  {
    id: '1',
    name: 'Work Experince',
  },
  {
    id: '2',
    name: 'Internship',
  },
  {
    id: '3',
    name: 'Training',
  },
  {
    id: '4',
    name: 'Academic',
  },
  {
    id: '5',
    name: 'Project',
  },
]

const DATAs = {
  workExperience: {
    skill: { maxScore: 5 },
    duration: {
      config: [
        { fromYear: 7, fromMonth: 1, toYear: '+', toMonth: '+', score: 5 },
      ],
      isActive: true,
    },
  },
  internship: {
    skill: { maxScore: 5 },
    duration: {
      config: [
        { fromYear: 7, fromMonth: 1, toYear: '+', toMonth: '+', score: 5 },
      ],
      isActive: true,
    },
  },
  training: {
    skill: { maxScore: 5 },
    duration: {
      config: [
        { fromYear: 7, fromMonth: 1, toYear: '+', toMonth: '+', score: 5 },
      ],
      isActive: true,
    },
  },
  academic: { skill: { maxScore: 5 } },
  project: {
    skill: { maxScore: 5 },
    duration: {
      config: [
        { fromYear: 7, fromMonth: 1, toYear: '+', toMonth: '+', score: 5 },
      ],
      isActive: true,
    },
  },
}
