import ErrorMessage from 'components/UIComponents/MessagePopup/ErrorMessage'
import selector from 'modules/Auth/selectors.js'
import SuccessMessage from 'utils/SuccessMessage'
import adminRequest from 'utils/adminRequest'
import request from '../../../../utils/instituteRequest'
import { getSingleUser } from '../../actions'
export const SET_CORPORATES_VIEW = 'corporate/SET_CORPORATES_VIEW'
export const SET_INSTITUTE_VIEW = 'corporate/SET_INSTITUTE_VIEW'
export const SET_CORPORATE_JOURNEY = 'corporate/SET_CORPORATE_JOURNEY'
export const SET_INSTITUTE_JOURNEY = 'corporate/SET_INSTITUTE_JOURNEY'

export const setCorporatesView = data => {
  return {
    type: SET_CORPORATES_VIEW,
    data,
  }
}

export const setInstituteView = data => {
  return {
    type: SET_INSTITUTE_VIEW,
    data,
  }
}

export const setCorporateJourney = data => {
  return {
    type: SET_CORPORATE_JOURNEY,
    data,
  }
}

export const setInstituteJourney = data => {
  return {
    type: SET_INSTITUTE_JOURNEY,
    data,
  }
}

export const getCorporatesView =
  ({ userId, searchValue = '', pageNo, city, state, industry, sector }) =>
  async (dispatch, getState) => {
    try {
      const search = searchValue ? `&search=${searchValue}` : ''
      const State = state ? `&state=${state}` : ``
      const City = city ? `&city=${city}` : ``
      const Industry = industry ? `&industry=${industry}` : ``
      const Sector = sector ? `&sector=${sector}` : ``
      let id = userId
      const response = await adminRequest.get(
        `/users/${id}/corporate/journey?pageLimit=${10}&currentPage=${
          pageNo ? pageNo - 1 : 0
        }${City}${State}${Industry}${search}${Sector}`
      )

      dispatch(setCorporatesView(response?.data?.data))
      return response
    } catch (error) {
      return error
    }
  }
export const getInstituteView =
  ({
    userId,
    searchValue = '',
    pageNo,
    city,
    state,
    tier,
    university: uni,
    degree: deg,
    department: dep,
  }) =>
  async (dispatch, getState) => {
    try {
      const search = searchValue ? `&search=${searchValue}` : ''
      const State = state !== '' && state ? `&state=${state}` : ``
      const City = city !== '' && city ? `&city=${city}` : ``
      const Tier = tier ? `&tier=${tier}` : ``
      const university = uni ? `&university=${uni}` : `` // Use the renamed parameter
      const degree = deg ? `&degreeId=${deg}` : `` // Use the renamed parameter
      const department = dep ? `&departmentId=${dep}` : `` // Use the renamed parameter
      let id = userId
      const response = await adminRequest.get(
        `/users/${id}/institute/journey?pageLimit=${10}&currentPage=${
          pageNo ? pageNo - 1 : 0
        }${City}${State}${Tier}${search}${university}${degree}${department}`
      )
      dispatch(setInstituteView(response?.data?.data))
      return response
    } catch (error) {
      return error
    }
  }
export const createCorporate =
  (payload, creratePayload, callback) => async (dispatch, getState) => {
    // return new Promise(async resolve => {

    try {
      let id = payload
      const response = await adminRequest.post(
        `/users/${id}/instCorpMap`,
        creratePayload
      )
      callback(true)
      // resolve(response)
      SuccessMessage('Corporate added successfully!')
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
      // resolve(error)
    }
    // })
  }

export const createInstitute =
  (payload, creratePayload, callback) => async (dispatch, getState) => {
    // return new Promise(async resolve => {

    try {
      let id = payload
      const response = await adminRequest.post(
        `/users/${id}/instCorpMap`,
        creratePayload
      )
      callback(true)
      // resolve(response)
      SuccessMessage('Institute added successfully!')
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error

      // resolve(error)
    }
    // })
  }

export const updateCorporate =
  (userId, updatePayload) => async (dispatch, getState) => {
    // return new Promise(async resolve => {
    const state = getState()
    selector.getUserId(state)
    try {
      //  resolve(response)

      SuccessMessage('User Updated successfully!')
      dispatch(getSingleUser({ userId }))
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)

      // resolve(error)    let userId = id
    }
    // })
  }

export const getCorporate =
  (id, searchValue, sortBy, pageNo, location) => async (dispatch, getState) => {
    try {
      const search = searchValue ? `&search=${searchValue}` : ''
      const loc = location ? `&location=${location}` : ''
      const sortKey = sortBy?.columnKey ? `&sort=${sortBy?.columnKey}` : ''
      const order = sortBy?.order === 'ascend' ? 'asc' : 'desc'
      const sortOrder = sortBy?.order ? `&orderBy=${order}` : ''

      let userId = id
      if (userId) {
        const response = await adminRequest.get(
          `/users/${userId}/CORPORATE?pageLimit=${10}&currentPage=${pageNo}${search}${sortKey}${loc}${sortOrder}`
        )
        dispatch(setCorporateJourney(response?.data?.data))
        return response
      }
    } catch (error) {
      return error
    }
  }

export const getInstitute =
  (id, searchValue, sortBy, pageNo, location) => async (dispatch, getState) => {
    try {
      const search = searchValue ? `&search=${searchValue}` : ''
      const loc = location ? `&location=${location}` : ''
      const sortKey = sortBy?.columnKey ? `&sort=${sortBy?.columnKey}` : ''
      const order = sortBy?.order === 'ascend' ? 'asc' : 'desc'
      const sortOrder = sortBy?.order ? `&orderBy=${order}` : ''

      let userId = id
      if (userId) {
        const response = await adminRequest.get(
          `/users/${userId}/INSTITUTE?pageLimit=${10}&currentPage=${pageNo}${search}${sortKey}${loc}${sortOrder}`
        )

        dispatch(setInstituteJourney(response?.data?.data))
        return response
      }
    } catch (error) {
      return error
    }
  }

export const deleteUser =
  ({ instCorpMapId, journey, callback }) =>
  async (dispatch, getState) => {
    try {
      const payload = {
        instCorpMapId: instCorpMapId,
      }
      const response = await adminRequest.delete(`/instCorp`, {
        data: payload,
      })
      if (response?.status >= 200 && response?.status <= 300) {
        SuccessMessage(`${journey} Un-Linked Successfully!`)
      }
      if (typeof callback === 'function') {
        callback(true)
      }
      return response
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }

export const updateJourneyRole =
  (userId, updatePayload) => async (dispatch, getState) => {
    // return new Promise(async resolve => {

    try {
      SuccessMessage('User Updated successfully!')
      dispatch(getSingleUser({ userId }))
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)

      // resolve(error)    let userId = id
    }
    // })
  }

export const getCorporateUniversityData =
  ({
    dropDownUrl,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async () => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status
      const Status =
        status == '1'
          ? `&isActive=${true}`
          : status == '0'
          ? `&isActive=${false}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${dropDownUrl}?${pageLimit}${pageNo}${Status}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}`,
      }
      const response = await request(options)
      let result = response?.data?.data || []
      if (setLoading) setLoading(false)
      return result
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
      return []
    }
  }
