import SearchInput from 'components/SearchInput'
import {
  CenterAlign,
  RowNumberData,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import React, { useCallback, useEffect, useState } from 'react'
const { COUNTRY_ID } = process.env
const VirtualizedCheckboxGroup = ({
  options,
  value,
  setValue,
  valueParam = 'value',
  labelParam = 'label',
  setDegreeSearch,
  parentId,
  clearInput = false,
  placeholder = 'Search by keyword',
  toShow,
  getStateData,
  labelType,
}) => {
  const [filter, setFilter] = useState('')
  useEffect(() => {
    setFilter('')
  }, [parentId])

  const search = e => {
    setFilter(e)
    setDegreeSearch(e)
    if (labelType) {
      delayToFetch(e.target.value)
    }
  }

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetch = useCallback(
    debounce((data, key) => {
      if (key == 'country') {
        //
      }
      if (key == 'state') {
        if (getStateData) {
          getStateData(COUNTRY_ID, true, data)
        }
      }
      if (key == 'city') {
        //
      }
    }, 500),
    []
  )

  const onChange = checkedvalue => {
    let indexToRemove = value?.indexOf(...checkedvalue)
    let stateCopy = [...value]
    if (indexToRemove !== -1) {
      stateCopy.splice(indexToRemove, 1)
      setValue([...stateCopy])
    } else {
      setValue([...value, ...checkedvalue])
    }
  }
  return (
    <div>
      {toShow ? (
        <>
          <div
            style={{
              backgroundColor: 'white',
              zIndex: '1',
              position: 'fixed',
            }}
          >
            <SearchInput
              placeholder={placeholder}
              value={filter}
              clearInput={clearInput}
              bordered={true}
              setSearchValue={search}
            />
          </div>
          <div>
            {options?.length > 0 ? (
              <FilterCheckBoxComp
                top="30px"
                options={options}
                value={value}
                onChangeCheck={e => onChange([e.target.value])}
                valueParam="_id"
                labelParam="name"
              />
            ) : (
              <RowNumberData>
                <CenterAlign>No Data Found</CenterAlign>
              </RowNumberData>
            )}
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', margin: '80px 0px 0px 80px' }}>
          Kindly Select State
        </div>
      )}
    </div>
  )
}

export default VirtualizedCheckboxGroup
