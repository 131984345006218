import React from 'react'

const DoubleArrowIcon = () => {
  return (
    <svg
      style={{ marginBottom: '5px' }}
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000128541 0L2.95855 4.4374L0.000128541 8.86752C0.613644 8.86191 1.22665 8.85915 1.83915 8.85915L4.78957 4.43739L1.85028 0.0276133C1.35506 0.0276133 0.859856 0.0231796 0.364648 0.0143956C0.399785 0.0143956 0.217455 0.00837228 0 6.53565e-06L0.000128541 0Z"
        fill="#3249D7"
      />
      <path
        d="M3.22574 0.0299054L6.16417 4.43732L3.21289 8.86166C3.60949 8.86166 4.00605 8.86609 4.40266 8.87488C4.3147 8.87488 4.71131 8.86409 5.04233 8.87488L8.00075 4.43748L5.05032 0.0180664C4.44184 0.0264319 3.83819 0.0288582 3.22573 0.0300293L3.22574 0.0299054Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default DoubleArrowIcon
