import { connect } from 'react-redux'
import GeneralSysConfig from 'modules/Systemconfig/Partials/GeneralTableSettings/index.js'
import selectors from 'modules/Onboarding/selectors'
import {
  getListOfCounties,
  getListOfState,
  getListOfCity,
  getIndustryList,
} from 'modules/Onboarding/actions'

import {
  getGeneralListForSysConfig,
  createGeneralSysConfig,
  getFunctionSysConfig,
} from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'
import GeneralSelectors from 'modules/Systemconfig/Partials/GeneralTableSettings/selectors'
const mapStateToProps = state => ({
 countryList: selectors.getCountryList(state),
 GeneralTableList: GeneralSelectors?.generalSysConfigList(state),
 FunctionTableList: GeneralSelectors?.generalFunctionList(state),
})

const mapDispatchToProps = {
  getListOfCounties,
  getListOfState,
  getListOfCity,
  getIndustryList,
  getGeneralListForSysConfig,
  createGeneralSysConfig,
  getFunctionSysConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSysConfig)
