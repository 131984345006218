import React from 'react'
import { message } from 'antd'
import styled from 'styled-components'

const StyledText = styled.span`
  text-transform: capitalize;
`
const WarningMessageReport = description => {
  message.config({
    duration: 1,
    maxCount: 1,
  })
  message.warning({
    content: <StyledText>{description}</StyledText>,
    className: 'custom-class',
    style: {
      marginTop: '50px',
      color: '#010E30',
      borderLeft: '5px solid red',
      transitionDuration: '5s',
    },
  })
}

export default WarningMessageReport
