import styled from 'styled-components'
import Checkboxes from 'components/Form/Checkbox'
import { Checkbox } from 'antd'
import Flex from 'components/Flex'

export const StyledGroup = styled(Checkbox.Group)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`
export const StyledCheckbox = styled(Checkboxes)`
  margin-left: 0 !important;
  padding: 10px !important;
`
export const StyledCheckbox1 = styled(Checkboxes)`
  font-size: 12px !important;
  margin-left: 0 !important;
  padding: 10px !important;
`
export const StyledOutline = styled(Flex)`
  padding: 10px;
  margin: 10px;
  border: 1px solid #e6e7ea;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`
export const StyledGroupWithTop = styled(Checkbox.Group)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  top: ${props => (props.top ? props.top : '')};
`
