import CustomTag from 'components/CustomTag'
import React from 'react'

const CoursesStatus = ({ status }) => {
  let colorType
  let tagMessage
  if (status == '1') {
    colorType = 'green'
    tagMessage = 'Active'
  } else if (status == '0') {
    colorType = 'red'
    tagMessage = 'InActive'
  }
  return (
    <CustomTag colorType={colorType} message={tagMessage} bordered={false} />
  )
}

export default CoursesStatus
