import {
  RowNumberData,
  RowNumberWebsiteData,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import React from 'react'
import CONSTANTS from 'utils/constants'
import { toCamelCase } from '../../../../../utils/camelCase'

export const InstituteMenuData = [
  {
    id: 1,
    text: 'University',
  },
  {
    id: 2,
    text: 'College',
  },
  {
    id: 3,
    text: 'Domain',
  },
  {
    id: 4,
    text: 'Degree Type',
  },
  {
    id: 5,
    text: 'Degree Level',
  },
  {
    id: 6,
    text: 'Degree',
  },
  {
    id: 7,
    text: 'Department',
  },

  {
    id: 8,
    text: 'Specialisation',
  },
  {
    id: 9,
    text: 'Degree, Department & Specialisation Mapping',
  },
]

export const UniversityData = [
  {
    title: 'University',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Website Url',
    dataIndex: 'website',
    key: 'website',
    render: (_, rowData) => (
      <RowNumberWebsiteData>{rowData?.website}</RowNumberWebsiteData>
    ),
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.state) || '-'}</RowNumberData>
    ),
  },
  {
    title: 'city',
    dataIndex: 'city',
    key: 'city',
    sorter: 'true',
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.city) || '-'}</RowNumberData>
    ),
  },
]

export const CollegeData = [
  {
    title: 'College',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },

  {
    title: 'University',
    dataIndex: 'university',
    key: 'university',
    render: (_, rowData) => {
      return (
        <RowNumberData>
          {' '}
          {toCamelCase(rowData?.universities?.name) || '-'}
        </RowNumberData>
      )
    },
  },

  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (_, rowData) => (
      <RowNumberData>
        {' '}
        {toCamelCase(rowData?.instituteCampus?.[0]?.state) || '-'}
      </RowNumberData>
    ),
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    render: (_, rowData) => (
      <RowNumberData>
        {' '}
        {toCamelCase(rowData?.instituteCampus?.[0]?.city) || '-'}
      </RowNumberData>
    ),
  },
]
export const DepartmentData = [
  {
    title: 'Department',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Short Form',
    dataIndex: 'shortForm',
    key: 'shortForm',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.shortForm || '-'}</RowNumberData>
    ),
  },
  
]

export const CategoryData = [
  {
    title: 'Domain',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
  // {
  //   title: 'Short Form',
  //   dataIndex: 'shortForm',
  //   key: 'shortForm',
  //   sorter: true,
  //   render: (_, rowData) => (
  //     <RowNumberData>{rowData?.shortForm || '-'}</RowNumberData>
  //   ),
  // },
]
export const DegreeTypeData = [
  {
    title: 'Degree Type',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Short Form',
    dataIndex: 'shortForm',
    key: 'shortForm',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.shortForm || '-'}</RowNumberData>
    ),
  },
]
export const DegreeLevelData = [
  {
    title: 'Degree Level',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Short Form',
    dataIndex: 'shortForm',
    key: 'shortForm',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.shortForm || '-'}</RowNumberData>
    ),
  },
]
export const SpecialisationData = [
  {
    title: 'Specialisation',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData> {toCamelCase(rowData?.name) || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Short Form',
    dataIndex: 'shortForm',
    key: 'shortForm',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.shortForm || '-'}</RowNumberData>
    ),
  },
]
export const DegreeData = [
  {
    title: 'Degree',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
        {toCamelCase(rowData?.name) || '-'}
      </RowNumberData>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: true,

    render: (_, rowData) => (
      <RowNumberData>
        {rowData?.type === 'DIPLOMA'
          ? 'UG Diploma'
          : rowData?.type === 'P_G_DIPLOMA'
          ? 'PG Diploma'
          : rowData?.type || '-'}
      </RowNumberData>
    ),
  },
  {
    title: 'Short Form',
    dataIndex: 'shortForm',
    key: 'shortForm',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.shortForm || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.domain?.name || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Degree Type',
    dataIndex: 'degreeType',
    key: 'degreeType',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.degreesType?.name || '-'}</RowNumberData>
    ),
  },
  {
    title: 'Degree Level',
    dataIndex: 'degreeLevel',
    key: 'degreeLevel',
    sorter: true,
    render: (_, rowData) => (
      <RowNumberData>{rowData?.degreesLevel?.name || '-'}</RowNumberData>
    ),
  },
]

export const DummyData = [
  {
    degree: 'Bachelor of Technology',
    type: 'UG',
    shortForm: 'B.Tech',
    status: '3',
  },
  {
    degree: 'Master of Technology',
    type: 'PG',
    shortForm: 'M.Tech',
    status: '4',
  },
]
export const AddDegreeContent = [
  {
    id: '2',
    name: 'type',
    label: 'Type',
    placeholder: 'UG',
    required: true,
    type: 'radio',
    valueParam: 'label',
    nameParam: 'label',
    data: [
      {
        label: 'UG',
        value: 'UG',
      },
      {
        label: 'PG',
        value: 'PG',
      },
      {
        label: 'PHD',
        value: 'PHD',
      },
    ],
  },
  {
    id: '4',
    name: 'domainId',
    label: 'Domain',
    placeholder: 'Domain',
    required: true,
    type: 'select',
    data: [],
    valueParam: 'id',
    nameParam: 'name',
  },
  {
    id: '5',
    name: 'degreesTypeId',
    label: 'Degree Type',
    placeholder: 'Degree Type',
    // required: true,
    type: 'select',
    data: [],
    valueParam: 'id',
    nameParam: 'name',
  },
  {
    id: '6',
    name: 'degreesLevelId',
    label: 'Degree Level',
    placeholder: 'Degree Level',
    // required: true,
    type: 'select',
    data: [],
    valueParam: 'id',
    nameParam: 'name',
  },
  {
    id: '3',
    name: 'shortForm',
    label: 'Short Form',
    placeholder: 'BE',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '1',
    name: 'name',
    label: 'Degree',
    placeholder: 'Bachelor of Engineering',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]
export const AddDepartmentContent = [
  {
    id: '1',
    name: 'name',
    label: 'Department',
    placeholder: 'Information Technology',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '2',
    name: 'shortForm',
    label: 'Short Form',
    placeholder: 'IT',
    required: false,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  
]

export const AddDegreeLevel = [
  {
    id: '1',
    name: 'name',
    label: 'Degree Level',
    placeholder: "Bachelor's degree",
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '2',
    name: 'shortForm',
    label: 'Short Form',
    placeholder: '',
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]
export const AddDegreeType = [
  {
    id: '1',
    name: 'name',
    label: 'Degree Type',
    placeholder: 'Single degree',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '2',
    name: 'shortForm',
    label: 'Short Form',
    placeholder: '',
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]

export const AddCategoryContent = [
  {
    id: '1',
    name: 'name',
    label: 'Domain',
    placeholder: 'Engineering',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  // {
  //   id: '2',
  //   name: 'shortForm',
  //   label: 'Short Form',
  //   placeholder: '',
  //   required: true,
  //   type: 'text',
  //   pattern: CONSTANTS.REGEX.NAME,
  // },
  // {
  //   id: '3',
  //   name: 'status',
  //   label: 'Status',
  //   placeholder: 'ENG',
  //   required: true,
  //   type: 'number',
  //   pattern: CONSTANTS.REGEX.ONLY_NUMBER,
  // },
]

export const AddSpecialisationContent = [
  {
    id: '1',
    name: 'name',
    label: 'Specialisation',
    placeholder: 'Internet Of Things',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '2',
    name: 'shortForm',
    label: 'Short Form',
    placeholder: 'IOT',
    required: false,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]
export const AddUniversityContent = [
  {
    id: '1',
    name: 'university',
    label: 'University',
    placeholder: 'Anna university',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '4',
    name: 'website',
    label: 'Website Url',
    placeholder: 'https://www.example.com',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.WEBSITE,
  },
  {
    id: '5',
    name: 'country',
    label: 'Country',
    placeholder: 'Country',
    required: true,
    type: 'select',
    data: [],
    valueParam: '_id',
    nameParam: 'name',
  },
  {
    id: '3',
    name: 'state',
    label: 'State',
    placeholder: 'Select',
    required: true,
    type: 'select',
    data: [],
  },
  {
    id: '2',
    name: 'city',
    label: 'City',
    placeholder: 'Select',
    required: true,
    type: 'select',
    data: [],
  },
]

export const Status = [
  {
    value: 'Active',
    label: 'Active',
    checked: false,
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    checked: false,
  },
]
export const Type = [
  {
    value: 'UG',
    label: 'UG',
    checked: false,
  },
  {
    value: 'PG',
    label: 'PG',
    checked: false,
  },
  {
    value: 'PHD',
    label: 'PHD',
    checked: false,
  },
  {
    value: 'DIPLOMA',
    label: 'DIPLOMA',
    checked: false,
  },
  {
    value: 'P_G_DIPLOMA',
    label: 'PG Diploma',
    checked: false,
  },
]
