import styled from 'styled-components'
import Flex from 'components/Flex'

const BREAKPOINT = '999px'

const SidebarPage = styled(Flex)`
  padding: 30px;
  background: '#FFFFFF';
  flex-direction: column;
  height: calc(100vh - 82px);

  flex-grow: 1;
  width: 10px;
  @media screen and (max-width: ${BREAKPOINT}) {
    padding: 20px;
    // overflow-x: hidden;
    // box-sizing: border-box;
  }
`

export default SidebarPage
