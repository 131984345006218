export const searchConfig = {
  degrees: {
    name: 'degrees',
    size: 3000,
    page: 1,
    autoCorrect: true,
    q: '',
    removeSpecialChars: true,
    filters: [
      {
        field: 'status.keyword',
        value: '1',
      },
    ],
  },
  streams: {
    name: 'streams',
    size: 3000,
    page: 1,
    autoCorrect: true,
    removeSpecialChars: true,
    filters: [
      {
        field: 'status.keyword',
        value: '1',
      },
    ],
  },
  specialisations: {
    name: 'specialisations',
    size: 3000,
    page: 1,
    autoCorrect: true,
    removeSpecialChars: true,
    filters: [
      {
        field: 'status.keyword',
        value: '1',
      },
    ],
  },
  institutesCampuses: {
    name: 'institutes_campuses',
    size: 3000,
    page: 1,
    autoCorrect: true,
    removeSpecialChars: true,
    filters: [
      {
        field: 'instituteIsActive.keyword',
        value: true,
      },
    ],
  },
  universities: {
    name: 'universities',
    size: 3000,
    page: 1,
    autoCorrect: true,
    removeSpecialChars: true,
    filters: [
      {
        field: 'status.keyword',
        value: '1',
      },
    ],
  },
  industries: {
    name: 'industries',
    size: 3000,
    page: 1,
    autoCorrect: true,
    removeSpecialChars: true,
    filters: [
      {
        field: 'isActive',
        value: true,
      },
    ],
  },
  sector: {
    name: 'sector',
    size: 3000,
    page: 1,
    autoCorrect: true,
    removeSpecialChars: true,
    filters: [
      {
        field: 'isActive',
        value: true,
      },
    ],
  },
}
