import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'components/Avatar'
import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import LinkIcon from 'components/icons/LinkIcon'
import { TwoFields } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import SuccessMessage from 'utils/SuccessMessage'
import CONSTANTS from 'utils/constants'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import { getMasterSearchApi } from '../../../../../../actions'
import {
  getSingleInstituteData,
  setSingleInstituteData,
} from '../../../../Register/actions'
import { searchConfig } from 'utils/config'

const InstDetails = ({
  form,
  delayCountryToFetch,
  onUniversityChange,
  onCountryChange,
  countryList,
  stateList,
  cityList,
  universityList,
  onStateChange,
  onCityChange,
  countryId,
  stateId,
  getStateData,
  getCityData,
  setSearchCountry,
  setCollegeID,
}) => {
  const dispatch = useDispatch()
  const [listOfCollege, setListOfCollege] = useState('')
  const [searchCollege, setSearchCollege] = useState('')
  const [collegeName, setCollegeName] = useState('')
  const instituteData = useSelector(
    state => state?.InstituteOnboarding?.singleInstituteData
  )

  useEffect(() => {
    getListOfAllColleges(searchCollege)
  }, [searchCollege])

  const delayToFetch = useCallback(
    debounce(data => {
      setSearchCollege(data)
    }, 500),
    []
  )
  useEffect(() => {
    form.resetFields()
    dispatch(setSingleInstituteData({}))
  }, [])
  useEffect(() => {
    if (instituteData?.university !== '') {
      form.setFieldsValue({
        universityName: instituteData?.university,
        universityId: instituteData?.universityId,
        website: instituteData?.instituteCampus?.[0]?.website,
        zipcode: instituteData?.instituteCampus?.[0]?.postalCode,
        phoneNumber: instituteData?.instituteCampus?.[0]?.contactNumber,
        contactPerson: instituteData?.instituteCampus?.[0]?.contactPerson,
        email: instituteData?.instituteCampus?.[0]?.contactEmail,
        country: instituteData?.instituteCampus?.[0]?.countryId
          ? instituteData?.instituteCampus?.[0]?.country
          : null,
        state: instituteData?.instituteCampus?.[0]?.stateId
          ? instituteData?.instituteCampus?.[0]?.state
          : null,
        city: instituteData?.instituteCampus?.[0]?.cityId
          ? instituteData?.instituteCampus?.[0]?.city
          : null,
        countryId: instituteData?.instituteCampus?.[0]?.countryId,
        stateId: instituteData?.instituteCampus?.[0]?.stateId,
        cityId: instituteData?.instituteCampus?.[0]?.cityId,
        countryCode: instituteData?.instituteCampus?.[0]?.countryCode,
      })
      getStateData(form.getFieldValue(['countryId']), true)
      getCityData(
        form.getFieldValue(['countryId']),
        form.getFieldValue(['stateId']),
        true
      )
    }
  }, [instituteData])
  const callBackError = msg => {}
  const onCollegeNameChange = (value, option) => {
    getListOfAllColleges('')
    dispatch(getSingleInstituteData(option?.option?.instituteId, callBackError))

    //campus id modified based on elastic search
    setCollegeID(option?.id)
    form.setFieldsValue({
      instituteName: value,
    })
    setCollegeName(value)
  }

  const copyLink = () => {
    let copyText = document.getElementById('WebsiteLink')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const delayStateToDb = useCallback(
    debounce((data1, data2, data3) => {
      getStateData(data1, data2, data3)
      form.setFieldsValue({
        state: '',
        stateId: '',
      })
    }, 500),
    []
  )

  const delayCitiesToDb = useCallback(
    debounce((data1, data2, data3, data4) => {
      getCityData(data1, data2, data3, data4)
      form.setFieldsValue({
        city: '',
        cityId: '',
      })
    }, 500),
    []
  )

  const delayUniversityToFetch = useCallback(
    debounce(data => {
      let payload = {
        ...searchConfig?.universities,
        q: data || '',
      }
      dispatch(getMasterSearchApi('universities', payload))
      form.setFieldsValue({
        universityName: '',
        universityId: '',
      })
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const getListOfAllColleges = searchCollege => {
    let payload = {
      ...searchConfig?.institutesCampuses,
      q: searchCollege,
    }
    try {
      elasticSearchRequest
        .post(`/search/institutes_campuses`, payload)
        .then(response => {
          let data = response.data.data?.hits?.hits?.map(item => item._source)
          setListOfCollege(data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <AntdSelect
        width="100%"
        label="Institute Campus Name *"
        placeholder="Eg. Madras Institute of Technology"
        name={'instituteName'}
        optional={'*'}
        rules={[
          {
            required: true,
            message: 'Please select Institute Name!',
          },
        ]}
        OptionData={listOfCollege || []}
        nameParam="campusName"
        valueParam="campusName"
        bordered={true}
        onChange={onCollegeNameChange}
        showSearch={true}
        value={collegeName}
        onSearch={data => delayToFetch(data)}
        filterOption={false}
        allowClear={true}
      />

      <AntdSelect
        width="100%"
        label="University Name *"
        placeholder="Eg. Pune University"
        name={'universityName'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('University name'),
          },
        ]}
        OptionData={universityList}
        valueParam="name"
        nameParam="name"
        showSearch={true}
        onSearch={delayUniversityToFetch}
        onChange={onUniversityChange}
        filterOption={false}
        allowClear={true}
        value={form.getFieldValue(['universityName'])}
      />

      <AntdInput
        width="100%"
        label="Group Name"
        placeholder="Eg. RV University"
        name={'groupName'}
        maxLength={300}
        rules={[
          {
            required: false,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('group name'),
          },
        ]}
        onChange={event => {
          form.setFieldsValue({
            groupName: event.target.value.replace(/[^A-Za-z ]|^ /g, ''),
          })
        }}
      />

      <AntdInput
        id={'WebsiteLink'}
        label="Website"
        placeholder="Eg. www.google.com"
        width="100%"
        name={'website'}
        rules={[
          {
            required: false,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('website'),
          },
          // {
          //   pattern: CONSTANTS.REGEX.WEBSITE,
          //   message: CONSTANTS.ERROR_MESSAGE.VALID('website'),
          // },
        ]}
        onChange={event => {
          form.setFieldsValue({
            website: event.target.value.trim(),
          })
        }}
        suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
      />

      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="Country *"
          placeholder="Eg.India"
          name={'country'}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  !form.getFieldValue(['country']) ||
                  !form.getFieldValue(['countryId'])
                ) {
                  return Promise.reject(new Error('Please select country!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={countryList}
          valueParam="name"
          nameParam="name"
          onChange={onCountryChange}
          showSearch={true}
          disabled={false}
          onSearch={data => delayCountryToFetch(data)}
          allowClear={true}
          filterOption={false}
          value={form.getFieldValue(['country'])}
        />
        <AntdSelect
          width="100%"
          label="State *"
          placeholder="Eg.Tamil Nadu"
          name={'state'}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  !form.getFieldValue(['state']) ||
                  !form.getFieldValue(['stateId'])
                ) {
                  return Promise.reject(new Error('Please select state!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={stateList}
          valueParam="name"
          nameParam="name"
          onChange={onStateChange}
          showSearch={true}
          filterOption={false}
          disabled={
            form.getFieldValue(['country']) === '' ||
            form.getFieldValue(['country']) === undefined
          }
          onSearch={data =>
            delayStateToDb(form.getFieldValue(['countryId']), false, data)
          }
          allowClear={true}
          value={form.getFieldValue(['state'])}
        />
      </TwoFields>

      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="City *"
          placeholder="Eg.Chennai"
          name={'city'}
          rules={[
            {
              validator: async (_, value) => {
                if (
                  !form.getFieldValue(['city']) ||
                  !form.getFieldValue(['cityId'])
                ) {
                  return Promise.reject(new Error('Please select city!'))
                }
                return Promise.resolve()
              },
            },
          ]}
          OptionData={cityList}
          valueParam="name"
          nameParam="name"
          onChange={onCityChange}
          showSearch={true}
          disabled={
            form.getFieldValue(['state']) === '' ||
            form.getFieldValue(['state']) === undefined
          }
          onSearch={data =>
            delayCitiesToDb(
              form.getFieldValue(['countryId']),
              form.getFieldValue(['stateId']),
              true,
              data
            )
          }
          allowClear={true}
          value={form.getFieldValue(['city'])}
        />
        <AntdInput
          width="100%"
          label="Zipcode *"
          placeholder="Eg.604407"
          name={'zipcode'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('Zipcode'),
            },
            {
              pattern: CONSTANTS.REGEX.PIN_CODE,
              message: CONSTANTS.ERROR_MESSAGE.VALID('Zipcode'),
            },
          ]}
        />
      </TwoFields>
    </>
  )
}

export default InstDetails
