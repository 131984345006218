import Button from 'components/Button/index'
import VirtualizedCheckboxGroup from 'components/UIComponents/Filter/index'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import { getFunctionSysConfig } from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MenuFlex, StyledTab, SubmitFlex } from './Style/style'
const { TabPane } = StyledTab
const { COUNTRY_ID } = process.env

const InstituteFilter = ({
  FunctionTableList,
  setValue,
  value,
  submitFilter,
  userRole,
  getListOfState,
  setLocation,
  location,
  loading,
  setLoading,
  degreeSearch,
  setDegreeSearch,
  stateId,
  setStateId,
  stateList,
  setStateList,
  stateNames,
  setStateNames,
  searchCollege,
  setSearchCollege,
  tier,
  setTierName,
  industryName,
  setIndustryName,
  tierId,
  setTierId,
  tierName,
  cityList,
  cityId,
  setPermCityId,
  setCityName,
  cityName,
  setCityList,
  universityList,
  university,
  setUniversity,
  universityName,
  fetchUniversityData,
  setUniversityName,
  degree,
  setDegree,
  degreeName,
  setDegreeName,
  degreeList,
  fetchDegreeData,
  department,
  setDepartment,
  departmentName,
  setDepartmentName,
  departmentList,
  fetchDepartmentData,
  check,
}) => {
  const dispatch = useDispatch()

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await getListOfState(countryId, search)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setStateList(updatedArray || [])
    }
  }
  const Tier = [
    {
      name: '1',
    },
    {
      name: '2',
    },
    {
      name: '3',
    },
    {
      name: '4',
    },
  ]
  useEffect(() => {
    getStateData(COUNTRY_ID, true)
  }, [])
  const delayToFetchUniversityData = useCallback(
    debounce(data => {
      fetchUniversityData(data)
    }, 500),
    []
  )
  const delayToFetchDegreeData = useCallback(
    debounce(data => {
      fetchDegreeData(data)
    }, 500),
    []
  )
  const delayToFetchDepartmentData = useCallback(
    debounce(data => {
      fetchDepartmentData(data)
    }, 500),
    []
  )

  useEffect(() => {
    let dropDownUrl = 'crud/industry'
    let searchValue = searchCollege
    dispatch(
      getFunctionSysConfig({
        dropDownUrl,
        type: 'get',
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
      })
    )
  }, [searchCollege])

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const delayToFetch = useCallback(
    debounce(data => {
      setSearchCollege(data)
    }, 500),
    []
  )

  const onCancel = value => {
    setValue(!value)
  }

  return (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="10px"
        popupClassName="filter"
      >
        <TabPane tab="Tier" key="1">
          <VirtualizedCheckboxGroup
            options={Tier}
            value={tierId}
            setValue={setTierId}
            valueParam="name"
            labelParam="name"
            setStateName={setTierName}
            filterId={true}
            stateName={tierName}
            searchNotNeeded={true}
            // setDegreeSearch={setS}
          />
        </TabPane>
        <TabPane tab="State" key="2">
          <VirtualizedCheckboxGroup
            options={stateList}
            value={stateId}
            setValue={setStateId}
            valueParam="_id"
            labelParam="name"
            setStateName={setStateNames}
            filterId={true}
            stateName={stateNames}
            setDegreeSearch={delayToFetch}
            check={check}
            getStateData={getStateData}
            labelType={'state'}
          />
        </TabPane>
        <TabPane tab="City" key="3">
          <VirtualizedCheckboxGroup
            options={cityList}
            value={cityId}
            setValue={setPermCityId}
            valueParam="name"
            labelParam="name"
            filterId={true}
            setStateName={setCityName}
            stateName={cityName}
            loading={loading}
            setLoading={setLoading}
            // degreeSearch={degreeSearch}
            setDegreeSearch={delayToFetch}
            check={check}
          />
        </TabPane>
        <TabPane tab="University" key="4">
          <VirtualizedCheckboxGroup
            options={universityList}
            value={university}
            setValue={setUniversity}
            valueParam="name"
            labelParam="name"
            setStateName={setUniversityName}
            filterId={true}
            stateName={universityName}
            setDegreeSearch={delayToFetchUniversityData}
            check={check}
          />
        </TabPane>
        <TabPane tab="Degree" key="5">
          <VirtualizedCheckboxGroup
            options={degreeList}
            value={degree}
            setValue={setDegree}
            valueParam="name"
            labelParam="name"
            setStateName={setDegreeName}
            filterId={true}
            stateName={degreeName}
            setDegreeSearch={delayToFetchDegreeData}
            check={check}
          />
        </TabPane>
        <TabPane tab="Department" key="6">
          <VirtualizedCheckboxGroup
            options={departmentList}
            value={department}
            setValue={setDepartment}
            valueParam="name"
            labelParam="name"
            setStateName={setDepartmentName}
            filterId={true}
            stateName={departmentName}
            setDegreeSearch={delayToFetchDepartmentData}
            check={check}
          />
        </TabPane>
      </StyledTab>

      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={onCancel} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )
}

export default InstituteFilter
