import React from 'react'
import { Tooltip } from 'antd'
import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import CONSTANTS from 'utils/constants'
import { toCamelCase } from '../../../../../utils/camelCase'

export const InstituteMenuData = [
  {
    id: 1,
    text: 'Screens',
  },
  {
    id: 2,
    text: 'Events',
  },
  {
    id: 3,
    text: 'Sub-Events',
  },
]

export const UniversityData = [
  {
    title: 'Screen Name',
    dataIndex: 'screenName',
    key: 'screenName',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.screenName) : '-'}>
        <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
          {toCamelCase(rowData?.screenName) || '-'}
        </RowNumberData>
      </Tooltip>
    ),
  },
  {
    title: 'journey',
    dataIndex: 'journey',
    key: 'journey',
    sorter: 'true',
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.journey) : '-'}>
        <RowNumberData>{toCamelCase(rowData?.journey) || '-'}</RowNumberData>
      </Tooltip>
    ),
  },
]

export const CollegeData = [
  {
    title: 'Events',
    dataIndex: 'eventName',
    key: 'eventName',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.eventName) : '-'}>
        <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
          {toCamelCase(rowData?.eventName) || '-'}
        </RowNumberData>
      </Tooltip>
    ),
  },
  {
    title: 'Journey',
    dataIndex: 'journey',
    key: 'journey',
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.screens?.journey) : '-'}>
        <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
          {toCamelCase(rowData?.screens?.journey) || '-'}
        </RowNumberData>
      </Tooltip>
    ),
  },
  {
    title: 'Screen Name',
    dataIndex: 'screenName',
    key: 'screenName',
    render: (_, rowData) => (
      <Tooltip
        title={rowData ? toCamelCase(rowData?.screens?.screenName) : '-'}
      >
        <RowNumberData>
          {toCamelCase(rowData?.screens?.screenName) || '-'}
        </RowNumberData>
      </Tooltip>
    ),
  },
]
export const DepartmentData = [
  {
    title: 'Sub-Events',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.name) : '-'}>
        <RowNumberData>{toCamelCase(rowData?.name) || '-'}</RowNumberData>
      </Tooltip>
    ),
  },
]
export const SpecialisationData = [
  {
    title: 'Specialisation',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.name) : '-'}>
        <RowNumberData>{toCamelCase(rowData?.name) || '-'}</RowNumberData>
      </Tooltip>
    ),
  },
]
export const DegreeData = [
  {
    title: 'Sub-Events',
    dataIndex: 'subEventName',
    key: 'subEventName',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.subEventName) : '-'}>
        <RowNumberData style={{ width: '300px', wordWrap: 'break-word' }}>
          <RowNumberData>
            {toCamelCase(rowData?.subEventName) || '-'}
          </RowNumberData>
        </RowNumberData>
      </Tooltip>
    ),
  },
  {
    title: 'journey',
    dataIndex: 'journey',
    key: 'journey',
    sorter: 'true',
    render: (_, rowData) => (
      <Tooltip
        title={rowData ? toCamelCase(rowData?.events?.screens?.journey) : '-'}
      >
        <RowNumberData>
          <RowNumberData>
            {toCamelCase(rowData?.events?.screens?.journey) || '-'}
          </RowNumberData>
        </RowNumberData>
      </Tooltip>
    ),
  },
  {
    title: 'Event Name',
    dataIndex: 'eventName',
    key: 'eventName',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip title={rowData ? toCamelCase(rowData?.events?.eventName) : '-'}>
        <RowNumberData>
          {toCamelCase(rowData?.events?.eventName) || '-'}
        </RowNumberData>
      </Tooltip>
    ),
  },
  {
    title: 'Screen Name	',
    dataIndex: 'screenName	',
    key: 'screenName	',
    sorter: true,
    render: (_, rowData) => (
      <Tooltip
        title={
          rowData ? toCamelCase(rowData?.events?.screens?.screenName) : '-'
        }
      >
        <RowNumberData>
          {toCamelCase(rowData?.events?.screens?.screenName) || '-'}
        </RowNumberData>
      </Tooltip>
    ),
  },
]

export const DummyData = [
  {
    degree: 'Bachelor of Technology',
    type: 'UG',
    shortForm: 'B.Tech',
    status: '3',
  },
  {
    degree: 'Master of Technology',
    type: 'PG',
    shortForm: 'M.Tech',
    status: '4',
  },
]

export const AddDegreeContent = [
  {
    id: '2',
    name: 'type',
    label: 'Type',
    placeholder: 'UG',
    required: true,
    type: 'radio',
    valueParam: 'label',
    nameParam: 'label',
    data: [
      {
        label: 'UG',
        value: 'UG',
      },
      {
        label: 'PG',
        value: 'PG',
      },
      {
        label: 'PHD',
        value: 'PHD',
      },
    ],
  },
  {
    id: '3',
    name: 'shortForm',
    label: 'Short Form',
    placeholder: 'BE',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '1',
    name: 'name',
    label: 'Degree',
    placeholder: 'Bachelor of Engineering',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]
export const AddDepartmentContent = [
  {
    id: '1',
    name: 'name',
    label: 'Department',
    placeholder: 'Maths',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]
export const AddSpecialisationContent = [
  {
    id: '1',
    name: 'name',
    label: 'Specialisation',
    placeholder: 'Maths',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
]
export const AddUniversityContent = [
  {
    id: '1',
    name: 'university',
    label: 'University',
    placeholder: 'Anna university',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.NAME,
  },
  {
    id: '4',
    name: 'website',
    label: 'Website Url',
    placeholder: 'https://www.example.com',
    required: true,
    type: 'text',
    pattern: CONSTANTS.REGEX.WEBSITE,
  },
  {
    id: '5',
    name: 'country',
    label: 'Country',
    placeholder: 'Country',
    required: true,
    type: 'select',
    data: [],
    valueParam: '_id',
    nameParam: 'name',
  },
  {
    id: '3',
    name: 'state',
    label: 'State',
    placeholder: 'Select',
    required: true,
    type: 'select',
    data: [],
  },
  {
    id: '2',
    name: 'city',
    label: 'City',
    placeholder: 'Select',
    required: true,
    type: 'select',
    data: [],
  },
]

export const Status = [
  {
    value: 'Active',
    label: 'Active',
    checked: false,
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    checked: false,
  },
]
export const Type = [
  {
    value: 'UG',
    label: 'UG',
    checked: false,
  },
  {
    value: 'PG',
    label: 'PG',
    checked: false,
  },
  {
    value: 'PHD',
    label: 'PHD',
    checked: false,
  },
  {
    value: 'UG DIPLOMA',
    label: 'Diploma',
    checked: false,
  },
  {
    value: 'P_G_DIPLOMA',
    label: 'PG Diploma',
    checked: false,
  },
]
