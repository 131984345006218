import { Col, Divider, Form, Row } from 'antd'
import RoutePath from 'components/RoutePath/index'
import LeftArrow from 'components/icons/LeftArrow'
import { arrayStatus } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import {
  LeftSection,
  PaddedDiv,
  RoleNameDiv,
  RoleNameFlex,
  SectionName,
  TopHeader,
} from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import { SystemPaddedDiv } from 'modules/Systemconfig/Styles/style'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MasterItem from '../../../../components/UIComponents/SideMenuMaster'
import SystemconfigTable from '../../../../components/UIComponents/SystemConfigTable/index'
import CrudDrawer from '../.././/Components/Drawer/Drawer'
import {
  AddCorporateClassificationContent,
  AddCorporateEntityContent,
  AddCorporateMasterContent,
  AddCorporateSubMasterContent,
  CorporateClassification,
  CorporateEntityData,
  CorporateMasterData,
  CorporateSubMasterData,
  Status,
  CorporateMenuData,
} from './Partials/TableColumnData'
import VirtualizedCheckboxGroupForStatus from '../../../../components/UIComponents/StatusFilter'
const CorporateSysConfig = ({
  getCompanyListForSysConfig,
  CompanyTableList,
  createCompanySysConfig,
}) => {
  const [tableData, setTableData] = React.useState({
    id: 1,
    text: 'Company',
  })
  const [statusValue, setStatusValues] = useState([])

  const setStatusValue = value => {
    setStatusValues(value)
  }
  const FilterItems = [
    {
      label: 'Status',
      key: '1',
      visible: true,
      children: (
        <VirtualizedCheckboxGroupForStatus
          options={Status}
          value={statusValue}
          setValue={setStatusValue}
          searchNotNeeded={true}
          rose={statusValue}
        />
      ),
    },
  ]
  const [drawer, setDrawer] = useState(false)
  const [view, setView] = useState(false)
  const [form] = Form.useForm()
  const [toEditId, setEditId] = useState('')
  const [sortBy, setSortBy] = useState({})
  const [url, setUrl] = useState('crud/company')
  const [pageNum, setPageNum] = useState(1)
  const [submit, setSubmit] = useState(false)
  const [success, isSuccess] = useState(false)
  const [menu, setMenu] = useState('Company')
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filterDatas, setFilterData] = useState({})
  const [itemsInFilter, setItemsInFilter] = useState(FilterItems)
  const [typeValue, setTypeValue] = useState([])
  const [addContent, setAddContent] = useState(false)
  const [content, setContent] = useState(AddCorporateMasterContent)
  const [id, setId] = React.useState('1')
  const [dataToAdd, setDataToAdd] = useState(CorporateMasterData)
  const [clearInput, setClearInput] = useState(false)

  const showDrawer = e => {
    if (Object.prototype.toString.call(e) === '[object Object]') {
      setDrawer(true)
      setAddContent(true)
    } else {
      setDrawer(true)
      setAddContent(false)
    }
    setDrawer(true)
  }
  const onClose = () => {
    setDrawer(false)
    form.resetFields()
    setEditId('')
    setView(false)
  }
  useEffect(() => {
    const newPageNum = pageNum
    getCompanyListForSysConfig({
      url,
      type: 'get',
      pageSize: 10,
      pageNumber: newPageNum == 0 ? 0 : newPageNum - 1,
      setLoading,
      searchValue,
      filterDatas,
      sortBy,
    })
    // Set pageNum using the separate variable
    setPageNum(newPageNum)
  }, [url, pageNum, searchValue, submit, success, sortBy, menu])

  useEffect(() => {
    setItemsInFilter(FilterItems)
  }, [statusValue, typeValue, menu])
  const onTableClick = data => {
    setTableData(data)
    setId(data.id)
    setSortBy({})
    setFilterData({})
    setEditId('')
    setTypeValue([])
    setStatusValue([])
    setSearchValue('')
    setSearchValue('')
    setMenu(data.text)
    switch (data.text) {
      case 'Company':
        setDataToAdd(CorporateMasterData)
        setContent(AddCorporateMasterContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/company')
        setClearInput(true)
        setSearchValue('')

        break
      case 'Corporate Entity':
        setDataToAdd(CorporateEntityData)
        setContent(AddCorporateEntityContent)
        setItemsInFilter(FilterItems)
        setClearInput(true)
        setSearchValue('')
        break
      case 'Corporate Sub Master':
        setDataToAdd(CorporateSubMasterData)
        setContent(AddCorporateSubMasterContent)
        break
      case 'Corporate Classification':
        setDataToAdd(CorporateClassification)
        setContent(AddCorporateClassificationContent)
        break
      default:
        break
    }
  }

  useEffect(() => {
    setSearchValue('')
  }, [tableData])

  useEffect(() => {
    if (
      menu === 'Company Master' ||
      menu === 'Corporate Sub Master' ||
      menu === 'Corporate Entity'
    ) {
      setItemsInFilter(FilterItems)
    }
  }, [statusValue, menu])

  const submitSystemConfigFilter = value => {
    let status = arrayStatus(statusValue)

    if (value == 'submit') {
      setSubmit(!submit)
      setPageNum(0)
      setFilterData({
        status: status,
        statusName: statusValue,
      })
    }
    if (value == 'reset') {
      setSubmit(!submit)
      setItemsInFilter(FilterItems)
      onTableClick(tableData)
      setFilterData({})
    }
  }

  const handleSubmitCongif = (value, call) => {
    if (toEditId) {
      createCompanySysConfig({
        url,
        value,
        type: 'put',
        id: toEditId,
        isSuccess,
        success,
      })
    } else {
      createCompanySysConfig({
        url,
        value,
        type: 'post',
        isSuccess,
        success,
      })
    }
  }

  const confirmDelete = data => {
    createCompanySysConfig({
      url,
      type: 'delete',
      id: data?.id,
      isSuccess,
      success,
    })
  }
  const onClickEdit = value => {
    form.setFieldsValue({
      name: value?.name,
      id: value?.id,
      type: value?.type,
      isActive: value?.isActive,
      corporateIdentificationNumber: value?.corporateIdentificationNumber,
      emailAddr: value?.emailAddr,
      companyStatus: value?.companyStatus,
      registeredOfficeAddress: value?.registeredOfficeAddress,
      companyName: value?.companyName,
    })
    setEditId(value?.id) // Set editId directly with the value
  }

  const toView = data => {
    onClickEdit(data)
    setView(true)
  }
  useEffect(() => {
    form.resetFields()
    onClose()
    setEditId('')
    setView(false)
  }, [success])

  const onUpdateStatus = (data, rowData) => {
    let value = {
      ...rowData,
      isActive: data === 1,
    }

    createCompanySysConfig({
      url,
      value,
      type: 'put',
      id: rowData?.id,
      isSuccess,
      success,
    })
  }

  const handleFilterCall = () => {
    setItemsInFilter(FilterItems)
    setStatusValues(filterDatas?.statusName || [])
  }
  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/systemConfig">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="System Config"
                on={'Company Settings'}
                path="/systemconfig"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>Company Settings</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>
      <Row>
        <Col span={5}>
          <SystemPaddedDiv>
            <MasterItem
              onTableClick={onTableClick}
              id={id}
              MasterTableData={CorporateMenuData}
            />
          </SystemPaddedDiv>
        </Col>
        <Col span={0.5}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={18}>
          <SystemconfigTable
            tableData={tableData}
            dataToAdd={dataToAdd}
            data={CompanyTableList?.result}
            list={CompanyTableList}
            showDrawer={showDrawer}
            title="Add Company"
            formId="form"
            sendButtonText="Submit"
            content={content}
            setSearchValue={setSearchValue}
            setSortBy={setSortBy}
            loading={loading}
            setPageNum={setPageNum}
            pageNum={pageNum}
            isSystemConfig={true}
            form={form}
            submitSystemConfigFilter={submitSystemConfigFilter}
            setStatusValue={setStatusValue}
            items={itemsInFilter}
            menu={menu}
            confirmDelete={confirmDelete}
            onEdit={onClickEdit}
            statusValue={statusValue}
            onUpdateStatus={onUpdateStatus}
            toView={toView}
            clearInput={clearInput}
            searchValue={searchValue}
            defaultFirstPageNumber={1}
            handleFilterCall={handleFilterCall}
          />
        </Col>
      </Row>
      <CrudDrawer
        open={drawer}
        onClose={onClose}
        tableData={tableData}
        content={content}
        form={form}
        handleSubmitCongif={handleSubmitCongif}
        toEditId={toEditId}
        setEditId={setEditId}
        view={view}
        setView={setView}
        addContent={addContent}
      />
    </>
  )
}
export default CorporateSysConfig
