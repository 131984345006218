import React from 'react'

const PlusIcon = ({ color = '#ffffff' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99996 13.1667C9.23607 13.1667 9.43413 13.0867 9.59413 12.9267C9.75357 12.7673 9.83329 12.5695 9.83329 12.3334V9.83342H12.3541C12.5902 9.83342 12.7847 9.75342 12.9375 9.59342C13.0902 9.43397 13.1666 9.23619 13.1666 9.00008C13.1666 8.76397 13.0866 8.56591 12.9266 8.40591C12.7672 8.24647 12.5694 8.16675 12.3333 8.16675H9.83329V5.64592C9.83329 5.4098 9.75357 5.21536 9.59413 5.06258C9.43413 4.9098 9.23607 4.83342 8.99996 4.83342C8.76385 4.83342 8.56607 4.91314 8.40663 5.07258C8.24663 5.23258 8.16663 5.43064 8.16663 5.66675V8.16675H5.64579C5.40968 8.16675 5.21524 8.24647 5.06246 8.40591C4.90968 8.56591 4.83329 8.76397 4.83329 9.00008C4.83329 9.23619 4.91302 9.43397 5.07246 9.59342C5.23246 9.75342 5.43052 9.83342 5.66663 9.83342H8.16663V12.3542C8.16663 12.5904 8.24663 12.7848 8.40663 12.9376C8.56607 13.0904 8.76385 13.1667 8.99996 13.1667ZM8.99996 17.3334C7.84718 17.3334 6.76385 17.1145 5.74996 16.6767C4.73607 16.2395 3.85413 15.6459 3.10413 14.8959C2.35413 14.1459 1.76051 13.264 1.32329 12.2501C0.885515 11.2362 0.666626 10.1529 0.666626 9.00008C0.666626 7.8473 0.885515 6.76397 1.32329 5.75008C1.76051 4.73619 2.35413 3.85425 3.10413 3.10425C3.85413 2.35425 4.73607 1.76036 5.74996 1.32258C6.76385 0.885359 7.84718 0.666748 8.99996 0.666748C10.1527 0.666748 11.2361 0.885359 12.25 1.32258C13.2638 1.76036 14.1458 2.35425 14.8958 3.10425C15.6458 3.85425 16.2394 4.73619 16.6766 5.75008C17.1144 6.76397 17.3333 7.8473 17.3333 9.00008C17.3333 10.1529 17.1144 11.2362 16.6766 12.2501C16.2394 13.264 15.6458 14.1459 14.8958 14.8959C14.1458 15.6459 13.2638 16.2395 12.25 16.6767C11.2361 17.1145 10.1527 17.3334 8.99996 17.3334Z"
        fill={color}
      />
    </svg>
  )
}

export default PlusIcon
