import {
  SET_CORPORATES_LIST,
  SET_GENERATED_LINK,
  SET_SINGLE_CORPORATES_DATA,
  SET_CORPORATES_METRICS_DATA,
} from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/actions'

const initialSingleCorporateInfo = {
  id: '',
  auto_increment_id: '',
  prefix_id: '',
  created_at: '',
  updated_up: '',
  name: '',
  logo: '',
  industry: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  city_id: '',
  state: '',
  state_id: '',
  country: '',
  country_id: '',
  employeesNumber: '',
  establishedYear: '',
  postalCode: '',
  website: '',
  contactNumber: '',
  country_code: '',
  shortDescription: '',
  longDescription: '',
  contactEmail: '',
  contactPerson: '',
  contact_person_role: '',
  corporateType: '',
  corporatePresentationLink: '',
  corporateVideoLink: '',

  pan: '',
  gst: '',
  authorisedSignatory: '',
  sector: '',
  regDocs: '',
  socialMedia: '',
  is_active: true,
  deleted_at: '',
  is_empanelled: false,
  sectorIndustryMapId: '',
}

const initialState = {
  corporatesList: {
    list: [],
  },
  singleCorporateData: initialSingleCorporateInfo,
  corporatesMetrics: {},
  generatedLinkAndId: { generatedLink: '', id: '' },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CORPORATES_LIST:
      return {
        ...state,
        corporatesList: action.data,
      }
    case SET_SINGLE_CORPORATES_DATA:
      return {
        ...state,
        singleCorporateData: action.data,
      }
    case SET_CORPORATES_METRICS_DATA:
      return {
        ...state,
        corporatesMetrics: action.data,
      }
    case SET_GENERATED_LINK:
      return {
        ...state,
        generatedLinkAndId: action.data,
      }
    default:
      return state
  }
}
