import { Form as AntdForm, Radio } from 'antd'
import { DateStyles } from 'components/DatePickerStyles/DatePickerStyles'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import Input from 'components/Form/Input'
import RadioButton from 'components/Form/RadioButton'
import { StyledTabs } from 'components/TabsPaneStyles'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  gap: 10px;
  margin-bottom: 10px;
`

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0px !important;
`
export const Form = styled(AntdForm)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
`
export const TwoFields = styled(Flex)`
  gap: 20px;
`
export const StyledDate = styled(DateStyles)`
  margin-top: 5px;
`
export const RadioGroup = styled(Radio.Group)`
  display: flex;
`
export const StyledRadio = styled(RadioButton)`
  margin-top: 10px;
`
export const SectionHeading = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin: 10px 0;
  color: ${COLORS.GREY_T_20};
`
export const StyledInputDiv = styled(Flex)`
  margin-bottom: 20px !important;
  align-items: flex-end;
  position: sticky;
  align-items: baseline;
  width: 100%;
  border-radius: 8px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0;
  }
`
export const FooterFlex = styled(Flex)`
  gap: 20px;
`
export const StyledInput = styled(Input)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`
export const MarksFlex = styled(Flex)`
  gap: 20px;
  width: 40%;
`
export const MenuFlex = styled(Flex)`
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  padding: 10px;
`
export const StyledTab = styled(StyledTabs)`
  .ant-tabs-content {
   
    height: ${props => (props.height? props.height : '270px')} !important;

    overflow: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-tabs-tab {
    justify-content: center;
  }
  // .ant-tabs-nav {
  //   height: 400px !important;
  // }
`
export const SubmitFlex = styled(Flex)`
  gap: 20px;
  padding: 15px 0px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const SubmitFlexBottom = styled(Flex)`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const ColoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  width: 46%;
`
export const ColoItem = styled.div`
  // width: 47%;
`
export const DepartmentItem = styled.div`
  text-overflow: ellipsis;
  width: 270px;
  font-size: 16px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 3px;
  margin: 4px;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : ' #FAFAFB'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  color: ${props => (props.color ? props.color : '#ffffff')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  id: ${props => (props.id ? props.id : '')};
`
export const SideArrowItem = styled.div`
  width: 35px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : ' #FAFAFB'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  color: ${props => (props.color ? props.color : '#ffffff')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  onclick: ${props => (props.onClick ? props.onClick : '')};
  id: ${props => (props.id ? props.id : '')};
  pointer-events: ${props => (props.pointerEvents ? props.pointerEvents : '')};
`
export const CheckboxColumn = styled.div`
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
  gap: 10px;
`
export const DepartColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 10px;
  padding-top: 10px;
`
export const DepartError = styled.div`
  color: red;
  margin-top: -16px;
`
