import { connect } from 'react-redux'
import Institutes from 'modules/Institutes/index'
import instituteSelector from '../selectors'
import { getUserInstitutePortalsList } from '../actions'
import { getListOfState, getListOfCity } from 'modules/Onboarding/actions'

const mapStateToProps = state => ({
  instituteList: instituteSelector.getUserInstitutesLists(state),
})

const mapDispatchToProps = {
  getUserInstitutePortalsList,
  getListOfState,
  getListOfCity,
}

export default connect(mapStateToProps, mapDispatchToProps)(Institutes)
