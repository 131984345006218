import React, { Fragment } from 'react'
import {
  FormItem,
  FlexWrap,
  SelectInputDiv,
  StyledInput,
} from 'components/UIComponents/FormStyles'
import Input from 'components/Form/Input'
import Select from 'components/Select'
import Label from 'components/Form/Label'
import CONSTANTS from '../../../../../../utils/constants'

const AddUserForm = ({ userRole }) => {
  const CountryCode = [
    {
      value: '+91',
      name: '+91',
    },
  ]

  return (
    <Fragment>
      <FlexWrap spaceBetween gap="25px">
        <FlexWrap width="49%">
          <Input
            type="text"
            width="100%"
            name={'firstName'}
            label="First Name"
            optional={'*'}
            placeholder="Eg: Vikram"
            rules={[
              {
                required: true,
                message: 'Please Enter Name',
              },
              {
                validator: CONSTANTS.ValidateNameInput,
              },
            ]}
          />
        </FlexWrap>
        <FlexWrap width="49%">
          <Input
            type="text"
            width="100%"
            name={'lastName'}
            label="Last Name"
            optional={'*'}
            placeholder="Eg: Sharma"
            rules={[
              {
                required: true,
                message: 'Please Enter Name',
              },
              {
                validator: CONSTANTS.ValidateNameInput,
              },
            ]}
          />
        </FlexWrap>
      </FlexWrap>
      <Input
        width="100%"
        name={'email'}
        label="Email ID"
        optional={'*'}
        placeholder="Eg. vikram.sharma@plugin.com"
        rules={[
          {
            required: true,
            message: 'Please Enter Email',
          },
          {
            type: 'email',
            message: 'Please Enter Valid Email',
          },
        ]}
      />
      <div>
        <Label required={true}>Phone No</Label>

        <SelectInputDiv borderleft={true} margintop="8px">
          <Select
            width="15%"
            OptionData={CountryCode}
            valueParam="value"
            bordered={false}
            name={'countryCode'}
            value={'+91'}
          />
          <StyledInput
            width="100%"
            type="tel"
            name={'phoneNumber'}
            optional={'*'}
            placeholder="Eg: 9999999999"
            rules={[
              {
                pattern: CONSTANTS.REGEX.MOBILE_NUMBER,
                message: 'Please Enter Valid Phone Number',
              },
            ]}
          />
        </SelectInputDiv>
      </div>
      <FormItem
        name={'roleId'}
        rules={[
          {
            required: true,
            message: 'Please select Any One Role',
          },
        ]}
      >
        <Select
          OptionData={userRole}
          label="User Role *"
          valueParam="_id"
          nameParam="name"
          placeholder="Select"
        />
      </FormItem>
      <FlexWrap spaceBetween gap="25px">
        <FlexWrap width="49%">
          <Input
            type="text"
            width="100%"
            name={'entity'}
            label="Entity"
            optional={'*'}
            placeholder="India"
            rules={[
              {
                required: true,
                message: 'Please Enter Entity',
              },
              {
                pattern: /^[a-zA-Z ]*$/,
                message: 'Please Enter Valid Entity',
              },
            ]}
          />
        </FlexWrap>
        <FlexWrap width="49%">
          <Input
            type="text"
            width="100%"
            name={'Region'}
            label="Region"
            optional={'*'}
            placeholder="Maharashtra"
            rules={[
              {
                required: true,
                message: 'Please Enter Region',
              },
              {
                pattern: /^[a-zA-Z ]*$/,
                message: 'Please Enter Valid Region',
              },
            ]}
          />
        </FlexWrap>
      </FlexWrap>
    </Fragment>
  )
}

export default AddUserForm
