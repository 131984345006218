import React, { useCallback, useEffect, useState } from 'react'
import Input from 'components/Form/Input'
import SearchIcon from 'components/icons/SearchIcon'
import styled from 'styled-components'

const StyleInput = styled(Input)`
  &&& .ant-row.ant-form-item-row {
    .ant-input {
      font-weight: 400;
      font-size: 16px;
      line-height: 24;
      margin-left: 10px;
    }
  }
`
const SearchInput = ({
  defaultFirstPageNumber = 0,
  searchValue,
  setSearchValue,
  height = '38px',
  width = '240px',
  placeholder = 'Search',
  allowClear,
  setCurrentPage,
  suffix = <SearchIcon />,
  prefix = <SearchIcon />,
  bordered = true,
  iconPosition = 'prefix',
  hasBorderRight,
  background,
  clearInput,
  clearFlag,
  setClearFlag,
  ...rest
}) => {
  const [input, setInput] = useState('')

  const onSearchChange = e => {
    if(setClearFlag){
        setClearFlag(false)
    }

    delayToFetch(e)
    setInput(e.target.value)
    setCurrentPage(defaultFirstPageNumber ?? 0)
  }

  useEffect(() => {
    if (input) {
      setInput('')
    }
  }, [clearInput])

  useEffect(()=>{
    if(clearFlag){
      setInput('')
    }
  },[clearFlag])

  const delayToFetch = useCallback(
    debounce(e => {
      setSearchValue(e.target.value)
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  return (
  
      <StyleInput
        {...rest}
        width={width}
        background={background}
        height={height}
        placeholder={placeholder}
        suffix={iconPosition == 'suffix' && suffix}
        prefix={iconPosition == 'prefix' && prefix}
        value={input}
        onChange={onSearchChange}
        allowClear={allowClear}
        bordered={bordered}
        hasBorderRight={hasBorderRight}
      />
    
  )
}
export default SearchInput
