import React from 'react'

const DraggerIcon = () => {
  return (
    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.58694 1.87788C3.58694 2.82445 2.81959 3.59182 1.873 3.59182C0.926431 3.59182 0.159058 2.82448 0.159058 1.87788C0.159058 0.931314 0.926401 0.16394 1.873 0.16394C2.81956 0.16394 3.58694 0.931284 3.58694 1.87788Z" fill="#CCCFD6"/>
    <path d="M3.42223 1.87788C3.41729 2.51833 3.01393 3.11599 2.40473 3.33333C1.78732 3.55395 1.09747 3.36296 0.675983 2.86081C0.257779 2.36196 0.213343 1.6227 0.550856 1.07116C0.881782 0.526177 1.54201 0.229842 2.1693 0.356619C2.89209 0.503151 3.41729 1.14197 3.42222 1.87791C3.42387 2.0903 3.75315 2.0903 3.75152 1.87791C3.74658 1.09091 3.25101 0.386244 2.51341 0.111285C1.78403 -0.158731 0.922967 0.0750641 0.430672 0.67764C-0.0698413 1.29012 -0.147214 2.16931 0.267675 2.85094C0.679286 3.52763 1.48438 3.87172 2.2582 3.71697C3.12257 3.54245 3.74658 2.75216 3.75315 1.87791C3.75315 1.66552 3.42385 1.66552 3.42222 1.87791L3.42223 1.87788Z" fill="#CCCFD6"/>
    <path d="M3.58694 7.74739C3.58694 8.69395 2.81959 9.46133 1.873 9.46133C0.926431 9.46133 0.159058 8.69398 0.159058 7.74739C0.159058 6.80079 0.926401 6.03345 1.873 6.03345C2.81956 6.03345 3.58694 6.80079 3.58694 7.74739Z" fill="#CCCFD6"/>
    <path d="M3.42223 7.74739C3.41729 8.38783 3.01393 8.9855 2.40473 9.20283C1.78732 9.42346 1.09747 9.23247 0.675983 8.73032C0.257779 8.23146 0.213343 7.49221 0.550856 6.94066C0.881782 6.39568 1.54201 6.09935 2.1693 6.22613C2.89209 6.37266 3.41729 7.01147 3.42222 7.74742C3.42387 7.95981 3.75315 7.95981 3.75152 7.74742C3.74658 6.96041 3.25101 6.25575 2.51341 5.98079C1.78403 5.71078 0.922967 5.94457 0.430672 6.54715C-0.0698413 7.15963 -0.147214 8.03881 0.267675 8.72045C0.679286 9.39714 1.48438 9.74122 2.2582 9.58648C3.12257 9.41196 3.74658 8.62167 3.75315 7.74742C3.75315 7.53503 3.42385 7.53503 3.42222 7.74742L3.42223 7.74739Z" fill="#CCCFD6"/>
    <path d="M3.58694 13.6169C3.58694 14.5635 2.81959 15.3308 1.873 15.3308C0.926431 15.3308 0.159058 14.5635 0.159058 13.6169C0.159058 12.6703 0.926401 11.903 1.873 11.903C2.81956 11.903 3.58694 12.6703 3.58694 13.6169Z" fill="#CCCFD6"/>
    <path d="M3.42223 13.6169C3.41729 14.2573 3.01393 14.855 2.40473 15.0723C1.78732 15.293 1.09747 15.102 0.675983 14.5998C0.257779 14.101 0.213343 13.3617 0.550856 12.8102C0.881782 12.2652 1.54201 11.9689 2.1693 12.0956C2.89209 12.2422 3.41729 12.881 3.42222 13.6169C3.42387 13.8293 3.75315 13.8293 3.75152 13.6169C3.74658 12.8299 3.25101 12.1253 2.51341 11.8503C1.78403 11.5803 0.922967 11.8141 0.430672 12.4167C-0.0698413 13.0291 -0.147214 13.9083 0.267675 14.59C0.679286 15.2666 1.48438 15.6107 2.2582 15.456C3.12257 15.2815 3.74658 14.4912 3.75315 13.6169C3.75315 13.4045 3.42385 13.4045 3.42222 13.6169L3.42223 13.6169Z" fill="#CCCFD6"/>
    <path d="M9.92547 1.87788C9.92547 2.82445 9.15812 3.59182 8.21153 3.59182C7.26496 3.59182 6.49759 2.82448 6.49759 1.87788C6.49759 0.931314 7.26493 0.16394 8.21153 0.16394C9.15809 0.16394 9.92547 0.931284 9.92547 1.87788Z" fill="#CCCFD6"/>
    <path d="M9.76076 1.87788C9.75582 2.51833 9.35246 3.11599 8.74327 3.33333C8.12585 3.55395 7.436 3.36296 7.01451 2.86081C6.59631 2.36196 6.55187 1.6227 6.88939 1.07116C7.22031 0.526177 7.88054 0.229842 8.50783 0.356619C9.23062 0.503151 9.75582 1.14197 9.76075 1.87791C9.7624 2.0903 10.0917 2.0903 10.0901 1.87791C10.0851 1.09091 9.58954 0.386244 8.85194 0.111285C8.12256 -0.158731 7.2615 0.0750641 6.7692 0.67764C6.26869 1.29012 6.19132 2.16931 6.60621 2.85094C7.01782 3.52763 7.82291 3.87172 8.59673 3.71697C9.46111 3.54245 10.0851 2.75216 10.0917 1.87791C10.0917 1.66552 9.76238 1.66552 9.76075 1.87791L9.76076 1.87788Z" fill="#CCCFD6"/>
    <path d="M9.92547 7.74739C9.92547 8.69395 9.15812 9.46133 8.21153 9.46133C7.26496 9.46133 6.49759 8.69398 6.49759 7.74739C6.49759 6.80079 7.26493 6.03345 8.21153 6.03345C9.15809 6.03345 9.92547 6.80079 9.92547 7.74739Z" fill="#CCCFD6"/>
    <path d="M9.76076 7.74739C9.75582 8.38783 9.35246 8.9855 8.74327 9.20283C8.12585 9.42346 7.436 9.23247 7.01451 8.73032C6.59631 8.23146 6.55187 7.49221 6.88939 6.94066C7.22031 6.39568 7.88054 6.09935 8.50783 6.22613C9.23062 6.37266 9.75582 7.01147 9.76075 7.74742C9.7624 7.95981 10.0917 7.95981 10.0901 7.74742C10.0851 6.96041 9.58954 6.25575 8.85194 5.98079C8.12256 5.71078 7.2615 5.94457 6.7692 6.54715C6.26869 7.15963 6.19132 8.03881 6.60621 8.72045C7.01782 9.39714 7.82291 9.74122 8.59673 9.58648C9.46111 9.41196 10.0851 8.62167 10.0917 7.74742C10.0917 7.53503 9.76238 7.53503 9.76075 7.74742L9.76076 7.74739Z" fill="#CCCFD6"/>
    <path d="M9.92547 13.6169C9.92547 14.5635 9.15812 15.3308 8.21153 15.3308C7.26496 15.3308 6.49759 14.5635 6.49759 13.6169C6.49759 12.6703 7.26493 11.903 8.21153 11.903C9.15809 11.903 9.92547 12.6703 9.92547 13.6169Z" fill="#CCCFD6"/>
    <path d="M9.76076 13.6169C9.75582 14.2573 9.35246 14.855 8.74327 15.0723C8.12585 15.293 7.436 15.102 7.01451 14.5998C6.59631 14.101 6.55187 13.3617 6.88939 12.8102C7.22031 12.2652 7.88054 11.9689 8.50783 12.0956C9.23062 12.2422 9.75582 12.881 9.76075 13.6169C9.7624 13.8293 10.0917 13.8293 10.0901 13.6169C10.0851 12.8299 9.58954 12.1253 8.85194 11.8503C8.12256 11.5803 7.2615 11.8141 6.7692 12.4167C6.26869 13.0291 6.19132 13.9083 6.60621 14.59C7.01782 15.2666 7.82291 15.6107 8.59673 15.456C9.46111 15.2815 10.0851 14.4912 10.0917 13.6169C10.0917 13.4045 9.76238 13.4045 9.76075 13.6169L9.76076 13.6169Z" fill="#CCCFD6"/>
    </svg>
    
  )
}

export default DraggerIcon
