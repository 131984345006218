import { Col, Divider, Form, Row } from 'antd'
import RoutePath from 'components/RoutePath/index'
import VirtualizedCheckboxGroup from 'components/UIComponents/Filter/index'
import MasterItem from 'components/UIComponents/SideMenuMaster'
import LeftArrow from 'components/icons/LeftArrow'
import { arrayStatus } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import {
  LeftSection,
  PaddedDiv,
  RoleNameDiv,
  RoleNameFlex,
  SectionName,
  StateCityMessage,
  TopHeader,
} from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import { SystemPaddedDiv } from 'modules/Systemconfig/Styles/style'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VirtualizedCheckboxGroupForStatus from '../../../../components/UIComponents/StatusFilter'
import SystemconfigTable from '../../../../components/UIComponents/SystemConfigTable/index'
import CrudDrawer from './Drawer'
import {
  CityMasterData,
  CountryMasterData,
  LocationMasterData,
  LocationMenuData,
  StateMasterData,
  Status,
} from './Partials/TableColumnData'
const MyLocationSettings = ({
  getInstituteListForSysConfig,
  instituteTableList,
  getListOfState,
  createInstituteSysConfig,
  getListOfCity,
  getListOfCounties,
}) => {
  const [form] = Form.useForm()
  const [id, setId] = useState('1')
  const [pageNum, setPageNum] = useState(1)
  const [url, setUrl] = useState()
  const [tableData, setTableData] = useState({
    id: 0,
    text: '',
  })

  const [searchValue, setSearchValue] = useState('')
  const [menu, setMenu] = useState('')
  const [toEditId, setEditId] = useState('')
  const [dataToAdd, setDataToAdd] = useState(CountryMasterData)
  const [itemsInFilter, setItemsInFilter] = useState(FilterItems)
  const [stateList, setStateList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [cityList, setCityList] = useState([])
  const [stateName, setStateName] = useState([])
  const [statusValue, setStatusValues] = useState([])
  const [typeValue, setTypeValue] = useState([])
  const [sortBy, setSortBy] = useState({
    orderBy: '',
    sortName: '',
  })
  const [filterDatas, setFilterData] = useState({})
  const [value, setValue] = useState({})
  const [success, isSuccess] = useState(false)
  const [view, setView] = useState(false)
  const [addContent, setAddContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [drawerLoading, setDrawerLoading] = useState(false)

  const [submit, setSubmit] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [locationId, setLocationId] = useState('')

  const currentUrl = window.location.href
  const parts = currentUrl.split('/')
  const cardName = parts[parts.length - 2]
  const cardId = parts[parts.length - 1]
  const [clearInput, setClearInput] = useState(false)

  const [countryId, setCountryId] = useState('')
  const [stateId, setStateId] = useState('')
  const [filterCountryId, setFilterCountryId] = useState('')
  const [filterStateId, setFilterStateId] = useState('')
  const [filterCityId, setFilterCityId] = useState('')
  const [check, setCheck] = useState(false)
  const [degreeSearch, setDegreeSearch] = useState('')
  const [stateList1, setStateList1] = useState([])
  const [countryList1, setCountryList1] = useState([])
  const [cityList1, setCityList1] = useState([])

  let getStateData = async (countryId, search) => {
    let pageLimit = '1500'

    if (countryId) {
      let list = await getListOfState(filterCountryId, search, pageLimit)
      // const updatedArray = list.map(obj => ({ ...obj, checked: false })
      setStateList(list || [])
    }
  }
  let getCountryData = async search => {
    let list = await getListOfCounties(search, true)
    // const updatedArray = list.map(obj => ({ ...obj, checked: false }))
    return setCountryList(list || [])
  }
  let getCityData = async (countryId, stateId, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await getListOfCity(filterCountryId, filterStateId, search, 0, pageLimit)
      // const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setCityList(list || [])
    }
  }

  let getStateData1 = async (countryId, search) => {
    let pageLimit = '1500'

    if (countryId) {
      let list = await getListOfState(countryId, search, pageLimit)
      // const updatedArray = list.map(obj => ({ ...obj, checked: false })
      setStateList1(list || [])
    }
  }
  let getCountryData1 = async search => {
    let list = await getListOfCounties(search, true)
    // const updatedArray = list.map(obj => ({ ...obj, checked: false }))
    return setCountryList1(list || [])
  }
  let getCityData1 = async (countryId, stateId, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await getListOfCity(countryId, stateId, search, 0, pageLimit)
      // const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setCityList1(list || [])
    }
  }
  const showDrawer = e => {
    if (Object.prototype.toString.call(e) === '[object Object]') {
      setDrawer(true)
      setAddContent(true)
    } else {
      setDrawer(true)
      setAddContent(false)
    }
    setDrawer(true)
  }
  const onClose = () => {
    form.resetFields()
    setEditId('')
    setDrawer(false)
    setView(false)
    setValue({})
  }

  useEffect(() => {
    if (cardName) {
      let data = {
        id: cardId,
        text: decodeURIComponent(cardName),
      }
      onTableClick(data)
    }
  }, [cardName])

  const onTableClick = data => {
    setTableData(data)
    setId(data.id)
    setFilterData({})
    setSearchValue('')
    setSortBy({})
    setEditId('')
    setStatusValue([])
    setTypeValue([])
    setCityList([])
    setSearchValue('')
    setLocationId([])
    setStateId('')
    setPageNum(1)
    setValue({})
    setCountryId()
    setFilterCountryId('')
    setFilterStateId('')
    setFilterCityId('')
    setCountryList([])
    setStateList([])
    setCityList([])
    setClearInput(!clearInput)
    setMenu(data.text)
    switch (data.text) {
      case 'Country':
        setDataToAdd(CountryMasterData)
        setItemsInFilter(FilterItems)
        setUrl('/countries')
        setSearchValue('')
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        break
      case 'State':
        setDataToAdd(StateMasterData)
        getCountryData()
        setUrl('/states')
        setSearchValue('')
        setItemsInFilter(FilterItems)
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        break

      case 'City':
        setDataToAdd(CityMasterData)
        setItemsInFilter(FilterItems)
        getCountryData()
        setUrl('/cities')
        setSearchValue('')
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        break
      case 'Location':
        setDataToAdd(LocationMasterData)
        setItemsInFilter(FilterItems)
        getCountryData()
        setUrl('/locations')
        setSearchValue('')
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        break
      // case 'Pincode':
      //   setDataToAdd(pinCodeMasterData)
      //   setContent(AddPinCodeContent)
      //   setUrl('/specialisation')
      //   setItemsInFilter(FilterItems)
      //   setSearchValue('')
      //   break
      default:
        break
    }
    window.history.replaceState(
      null,
      '',
      `/systemConfig/locationSettings/${data.text}/${data.id}`
    )
  }

  useEffect(() => {
    if (menu && url) {
      getInstituteListForSysConfig({
        url,
        type: 'get',
        pageSize: 10,
        pageNumber: pageNum,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
    }
  }, [url, pageNum, submit, success, sortBy, searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [tableData])

  const setStatusValue = value => {
    setStatusValues(value)
  }

  const setCountryStateValue = async value => {
    if (value && value.length > 0) {
      setFilterCountryId(value)

      // Fetch states for all selected countries
      const statesPromises = value.map(async countryId => {
        const states = await getListOfState(countryId, searchValue, '1500')
        return states || []
      })

      // Wait for all state promises to resolve
      const allStates = await Promise.all(statesPromises)

      // Combine the states from all countries into a single list
      const combinedStates = allStates.flat()
      setStateList(combinedStates)
    }
    else{
      setFilterCountryId([])
    }
  }
  const setStateCityValue = async selectedStateIds => {
    setFilterStateId(selectedStateIds)

    if (tableData.text === 'Location') {
      const countryId = filterCountryId || []
      const stateIds = selectedStateIds || []

      // Fetch cities for each selected state
      const cityPromises = stateIds.map(async stateId => {
        return await getListOfCity(countryId, stateId, searchValue, 0, '1500')
      })

      // Wait for all city promises to resolve
      const cityLists = await Promise.all(cityPromises)

      // Combine the cities from all selected states into a single list
      const combinedCities = cityLists.flat()
      setCityList(combinedCities || [])
    }
  }
  const FilterItems = [
    {
      label: 'Status',
      key: '1',
      visible: true,
      children: (
        <VirtualizedCheckboxGroupForStatus
          options={Status}
          value={statusValue}
          setValue={setStatusValue}
          searchNotNeeded={true}
        />
      ),
    },
    {
      label: 'Country',
      key: '2',
      visible: tableData?.text !== 'Country',
      children: (
        <VirtualizedCheckboxGroup
          options={countryList}
          valueParam="_id"
          labelParam="name"
          filterId={true}
          setStateName={setStateName}
          stateName={stateName}
          value={filterCountryId}
          setValue={setCountryStateValue}
          saveById={true}
          parentId={tableData?.text}
          check={check}
          degreeSearch={degreeSearch}
          setDegreeSearch={setDegreeSearch}
        />
      ),
    },
    {
      label: 'State',
      key: '3',
      visible: tableData?.text !== 'Country' && tableData?.text !== 'State',
      children:
        filterCountryId?.length > 0  ? (
          <VirtualizedCheckboxGroup
            options={stateList}
            valueParam="_id"
            labelParam="name"
            filterId={true}
            value={filterStateId}
            setStateName={setStateName}
            stateName={stateName}
            setValue={setStateCityValue}
            saveById={true}
            parentId={tableData?.text}
            check={check}
            degreeSearch={degreeSearch}
            setDegreeSearch={setDegreeSearch}
            getStateData={getStateData}
            labelType={'state'}
          />
        ) : (
          <StateCityMessage>
            Kindly select a country before choosing a state.
          </StateCityMessage>
        ),
    },
    {
      label: 'City',
      key: '4',
      visible: tableData?.text === 'Location',
      children:
        filterStateId?.length > 0  ? (
          <VirtualizedCheckboxGroup
            options={cityList}
            valueParam="_id"
            labelParam="name"
            filterId={true}
            setStateName={setStateName}
            stateName={stateName}
            value={filterCityId}
            setValue={setFilterCityId}
            saveById={true}
            parentId={tableData?.text}
            check={check}
            degreeSearch={degreeSearch}
            setDegreeSearch={setDegreeSearch}
          />
        ) : (
          <StateCityMessage>
            Kindly select a state before choosing a city.
          </StateCityMessage>
        ),
    },
  ]

  useEffect(() => {
    setItemsInFilter(FilterItems)
  }, [
    statusValue,
    countryList,
    filterCountryId,
    stateList,
    filterStateId,
    cityList,
    filterCityId,
    filterDatas,
  ])

  const submitSystemConfigFilter = value => {
    let status = arrayStatus(statusValue)
    let countryId = filterCountryId || []
    let state = filterStateId || []
    let city = filterCityId || []

    if (value == 'submit') {
      setFilterData({
        is_active: status,
        stateId: state,
        cityId: city,
        countryId: countryId,
        menu: menu,
        statusName: statusValue,
      })
      setSubmit(!submit)
      setPageNum(1)
    }
    if (value == 'reset') {
      setSubmit(!submit)
      setItemsInFilter(FilterItems)
      onTableClick(tableData)
      setFilterData({})
    }
  }
  const handleSubmitCongif = (value, call) => {
    if (toEditId) {
      createInstituteSysConfig({
        url,
        value,
        type: 'put',
        id: toEditId,
        isSuccess,
        success,
        setDrawerLoading,
      })
    } else {
      createInstituteSysConfig({
        url,
        value,
        type: 'post',
        isSuccess,
        success,
        setDrawerLoading,
      })
    }
  }
  const onClickEdit = value => {
    form.resetFields()
    form.setFieldsValue({
      name: value?.name,
      country_id: value?.country_id?._id,
      state_id: value?.state_id?._id,
      city_id: value?.city_id?._id,
      currency: value?.currency,
      capital: value?.capital,
      phone_code: value?.phone_code,
      alias: value?.alias,
      pin_code: value?.pin_code,
    })
    if (value?.country_id?._id) {
      getCountryData1()
      setCountryId(value?.country_id?._id)
      
      getStateData1(value?.country_id?._id)
    }
    if (value?.country_id?._id && value?.state_id?._id) {
      setCountryId(value?.country_id?._id)
      setStateId(value?.state_id?._id)
      getCityData1(value?.country_id?._id, value?.state_id?._id)
    }
    setEditId(value._id)
    setValue(value)
  }
  useEffect(() => {
    form.resetFields()
    onClose()
    setEditId('')
    setCountryId('')
    setStateId('')
    setValue({})
    setView(false)
  }, [success])

  const onUpdateStatus = (data, rowData) => {
    if (rowData.country_id) {
      rowData.country_id = rowData?.country_id?._id
    }
    if (rowData.state_id) {
      rowData.state_id = rowData?.state_id?._id
    }
    if (rowData.city_id) {
      rowData.city_id = rowData?.city_id?._id
    }
    let value = {
      ...rowData,
      is_active: data,
    }
    createInstituteSysConfig({
      url,
      value,
      type: 'put',
      id: rowData?._id,
      isSuccess,
      success,
    })
  }
  const confirmDelete = data => {}
  const toView = data => {
    onClickEdit(data)
    setView(true)
  }

  const onSelectOption = (id, field) => {
    if (field == 'country') {
      getStateData(id)
      setCountryId(id)
    }
    if (field == 'state') {
      getCityData(countryId, id)
      setStateId(id)
    }
    if (field == 'city') {
      // getCityData(countryId, id)
    }
  }

  const handleReset = () => {
    form.resetFields()
    onClose()
    setEditId('')
    setCountryId('')
    setStateId('')
    setView(false)
    setSearchValue('')
  }
  const currentab = url?.replace('/', '')

  const handleFilterCall = () => {
    setItemsInFilter(FilterItems)
    setCheck(!check)
    setStatusValues(filterDatas?.statusName || [])
    setFilterCountryId(filterDatas?.countryId || [])
    setFilterStateId(filterDatas?.stateId || [])
    setFilterCityId(filterDatas?.cityId || [])
  }
  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/systemconfig">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="System Config"
                on={'Location Settings'}
                path="/systemconfig"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>Location Settings</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>

      <Row>
        <Col span={5}>
          <SystemPaddedDiv>
            <MasterItem
              onTableClick={onTableClick}
              id={id}
              MasterTableData={LocationMenuData}
            />
          </SystemPaddedDiv>
        </Col>
        <Col span={0.5}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={18}>
          <SystemconfigTable
            form={form}
            loading={loading}
            tableData={tableData}
            dataToAdd={dataToAdd}
            data={instituteTableList?.[currentab] ?? []}
            showDrawer={showDrawer}
            statusValue={statusValue}
            setStatusValue={setStatusValue}
            typeValue={typeValue}
            setTypeValue={setTypeValue}
            items={itemsInFilter}
            setPageNum={setPageNum}
            pageNum={pageNum}
            list={instituteTableList}
            setSearchValue={setSearchValue}
            submitSystemConfigFilter={submitSystemConfigFilter}
            onEdit={onClickEdit}
            onUpdateStatus={onUpdateStatus}
            confirmDelete={confirmDelete}
            setSortBy={setSortBy}
            toView={toView}
            menu={menu}
            clearInput={clearInput}
            searchValue={searchValue}
            locationId={locationId}
            setLocationId={setLocationId}
            listKey={currentab}
            isCurrentPageLabel={true}
            handleFilterCall={handleFilterCall}
          />
        </Col>
      </Row>
      <CrudDrawer
        open={drawer}
        onClose={onClose}
        tableData={tableData}
        form={form}
        handleSubmitCongif={handleSubmitCongif}
        toEditId={toEditId}
        setEditId={setEditId}
        view={view}
        setView={setView}
        addContent={addContent}
        onChangeCapture={onSelectOption}
        search={true}
        countryList={countryList1}
        stateList={stateList1}
        cityList={cityList1}
        getCountryData={getCountryData1}
        getStateData={getStateData1}
        getCityData={getCityData1}
        countryId={countryId}
        stateId={stateId}
        existedvalue={value}
        handleReset={handleReset}
        drawerLoading={drawerLoading}
      />
    </>
  )
}
export default MyLocationSettings
