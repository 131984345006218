import axios from 'axios'
import adminRequest from 'utils/adminRequest'
import authRequest from 'utils/authRequest'
import corporateRequest from 'utils/corporateRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import ErrorMessage from 'utils/ErrorMessage'
import instituteRequest from 'utils/instituteRequest'
import request from 'utils/request'
import SuccessMessage from 'utils/SuccessMessage'

export const setListOfCountry = list => {
  return {
    type: 'SET_LIST_OF_COUNTRY',
    list,
  }
}
export const masterSearchApi = list => {
  return {
    type: 'MASTER_SEARCH_API',
    list,
  }
}
export const setListOfState = list => {
  return {
    type: 'SET_LIST_OF_STATE',
    list,
  }
}
export const setListOfCity = list => {
  return {
    type: 'SET_LIST_OF_CITY',
    list,
  }
}

export const setCountrycodeList = list => {
  return {
    type: 'SET_COUNTRY_CODE_LIST',
    list,
  }
}

export const setIndustrysList = data => {
  return {
    type: 'SET_INDUSTRY_LIST',
    data,
  }
}

export const setUniversityList = data => {
  return {
    type: 'SET_UNIVERSITY_LIST',
    data,
  }
}

export const setCitiesWithPagination = data => {
  return {
    type: 'SET_CITY_WITH_PAGINATION',
    data,
  }
}

export const getFile = async (url = '', file = '', fileURL) => {
  if (url && file) {
    try {
      let fileType = file.type
      let options = {
        headers: {
          'Content-Type': fileType,
        },
      }
      const { status } = await axios.put(url, file, options)
      if (status >= 200 && status < 300) {
        SuccessMessage('File Uploaded Successfully!')
        return fileURL
      }
      return false
    } catch (error) {
      ErrorMessage('Failed to upload!')
      return false
    }
  } else {
    ErrorMessage('Failed to upload!')
    return false
  }
}

export const deleteFile = fileUrl => async () => {
  try {
    if (fileUrl) {
      const data = await authRequest.delete(`/deleteFile`, {
        data: { fileUrl: fileUrl },
      })

      if (data.status >= 200 && data.status < 300) {
        return true
      }
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export const uploadFile = (file, fileName) => async () => {
  try {
    if (file) {
      const data = await authRequest.post(`/signedURL`, {
        files: [
          {
            fileName: fileName,
          },
        ],
      })

      if (data.status >= 200 && data.status < 300) {
        let url = getFile(
          data?.data?.data[0]?.signedURL,
          file,
          data?.data?.data[0]?.fileURL
        )
        return url
      }
    }
    return false
  } catch (error) {
    resolve(false)
    ErrorMessage('Failed to upload!')
    return false
  }
}
export const getListOfCounties =
  (search, getAll = false) =>
  async dispatch => {
    try {
      let searchValue = search ? `&search=${search}` : ''
      const data = await elasticSearchRequest.get(
        getAll
          ? `/search/countries?currentPage=0&pageLimit=500${searchValue}`
          : `/search/countries?currentPage=0&pageLimit=50${searchValue}`
      )
      let listOfCountry = data?.data?.data?.result
      dispatch(setListOfCountry(listOfCountry?.length > 0 ? listOfCountry : []))
      return listOfCountry
    } catch (error) {}
  }

export const getListOfCountryCode =
  (pageLimit = 50, phoneCodeSearch = '') =>
  async dispatch => {
    let phoneCode = phoneCodeSearch ? `&search=${phoneCodeSearch}` : ''
    try {
      let response = await elasticSearchRequest.get(
        `/search/countries?currentPage=0&pageLimit=${pageLimit}${phoneCode}`
      )
      dispatch(setCountrycodeList(response?.data?.data?.result || []))
      return response
    } catch (error) {
      ErrorMessage(error?.response)
      return error?.response
    }
  }

export const getListOfState =
  (countyId, search, pageLimit = '50') =>
  async dispatch => {
    let county = countyId ? `&countryId=${countyId}` : ''
    let searchValue = search ? `&search=${search}` : ''

    try {
      const data = await elasticSearchRequest.get(
        `/search/states?currentPage=0&pageLimit=${pageLimit}${county}${searchValue}`
      )
      dispatch(setListOfState(data?.data?.data?.result || []))
      if (data?.data?.data?.result?.length === 0) {
        !searchValue &&
          ErrorMessage('No states available for selected country!')
      }
      return data?.data?.data?.result
    } catch (error) {}
  }

export const getListOfCity =
  (countyId, stateId, search, pageNumber, pageLimit = '50') =>
  async dispatch => {
    let county = countyId ? `&countryId=${countyId}` : ''
    let state = stateId ? `&stateId=${stateId}` : ''
    let searchValue = search ? `&search=${search}` : ''
    let pageNum = pageNumber ? `&currentPage=${pageNumber}` : ''
    let limit = pageLimit ? `pageLimit=${pageLimit}` : ``

    try {
      const data = await elasticSearchRequest.get(
        `/search/cities?${limit}${county}${state}${searchValue}${pageNum}`
      )
      dispatch(setListOfCity(data?.data?.data?.result || []))
      if (data?.data?.data?.result?.length === 0) {
        !searchValue && ErrorMessage('No cities available for selected state!')
      }
      return data?.data?.data?.result
    } catch (error) {
      dispatch(setListOfCity([]))
      return []
    }
  }

export const getIndustryList = searchValue => async (dispatch, getState) => {
  let SearchKey = searchValue ? `&search=${searchValue}` : ''

  try {
    const result = await corporateRequest.get(
      `industries?currentPage=1&pageLimit=10${SearchKey}`
    )
    dispatch(setIndustrysList(result?.data?.data))
    return result
  } catch (error) {
    ErrorMessage(error?.message)
    dispatch(setIndustrysList({}))
  }
}

export const searchAPI =
  (type, search = '', payload) =>
  async (dispatch, getState) => {
    try {
      const result = await elasticSearchRequest.post(`/search/${type}`, {
        ...payload,
        name: undefined,
        q: search,
      })
      // resolve(result?.data?.data?.hits?.hits?.map(item => item?._source))
      return result?.data?.data?.hits?.hits?.map(item => item?._source)
    } catch (error) {
      console.log(`Error in getting list of ${type} =>`, error?.message)
      ErrorMessage(`Error in getting list of ${type}`)
      return []
    }
  }

export const getUniversityList = searchValue => async (dispatch, getState) => {
  let SearchKey = searchValue ? `&search=${searchValue}` : ''
  let query = `pageNo=0&pageLimit=30${SearchKey}`
  try {
    const result = await instituteRequest.get(
      `/institutes/crud/universities?${query}`
    )
    dispatch(setUniversityList(result?.data?.data?.result))
    return result
  } catch (error) {
    ErrorMessage(error?.message)
    dispatch(setUniversityList({}))
  }
}

export const sendOnboardingLink =
  (payload, successCallBack = '', errorCallBack = '') =>
  async (dispatch, getState) => {
    try {
      const result = await adminRequest.post(
        'users/onboardingLinkSharing',
        payload
      )
      SuccessMessage('Onboarding Link send Successfully!')
      if (successCallBack) {
        successCallBack()
      }
      return result
    } catch (error) {
      if (errorCallBack) {
        errorCallBack()
      }

      ErrorMessage(error?.message)
    }
  }

export const getCitiesWithPagination =
  (search, pageNumber) => async dispatch => {
    let searchValue = search ? `&search=${search}` : ''
    let pageNum = pageNumber ? `&currentPage=${pageNumber}` : ''

    try {
      const data = await authRequest.get(
        `/search/cities?pageLimit=50${searchValue}${pageNum}`
      )
      dispatch(
        setCitiesWithPagination(
          data?.data?.data || {
            cities: [],
            totalPages: 0,
            currentPage: 0,
          }
        )
      )
      return data?.data?.data
    } catch (error) {
      return error
    }
  }

export const getMasterSearchApi =
  (type, payload) => async (dispatch, getState) => {
    try {
      const result = await elasticSearchRequest.post(`/search/${type}`, payload)
      let data = result.data.data?.hits?.hits?.map(item => item._source)

      dispatch(masterSearchApi(data))

      return data
    } catch (error) {
      ErrorMessage(error?.message)
      dispatch(masterSearchApi({}))
      return []
    }
  }

export const getListOfInstituteLocation = async (
  groupBy,
  pageNo,
  search,
  stateId
) => {
  try {
    let group = groupBy ? `&groupBy=${groupBy}` : ''
    let searchValue = search ? `&search=${search.trimStart()}` : ''
    let pageNum = pageNo ? `&currentPage=${pageNo}` : ''
    let stateIdString = stateId ? `&stateId=${stateId}` : ''
    const data = await elasticSearchRequest.get(
      `/institute/location?pageLimit=50${group}${searchValue}${pageNum}${stateIdString}`
    )
    return data?.data?.data?.result || []
  } catch (error) {}
}

export const getListOfCorporateLocations = async (groupBy, pageNo, search) => {
  try {
    let group = groupBy ? `&groupBy=${groupBy}` : ''
    let searchValue = search ? `&search=${search.trimStart()}` : ''
    let pageNum = pageNo ? `&currentPage=${pageNo}` : ''

    const data = await elasticSearchRequest.get(
      `/corporate/location?pageLimit=50${group}${searchValue}${pageNum}`
    )

    return data?.data?.data?.result || []
  } catch (error) {
    console.error('Error fetching corporate locations:', error)
    throw error // Propagate the error so it can be handled where the function is called
  }
}
