import Button from 'components/Button/index'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import StatusFilter from 'modules/User/Partials/UsersFilter/Partials/StatusFilter'
import UserRoleFilter from 'modules/User/Partials/UsersFilter/Partials/UserRoleFilter'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Users/Partials/UsersFilter/Styles/style'
import React, { useState } from 'react'
const { TabPane } = StyledTab

const UsersFilter = ({
  userMetrics,
  setSearchValue,
  userRole,
  setUserRoleValue,
  setUserStatus,
  userStatus,
  filterData,
  setFilterData,
  setPageNum,
  setCurrentPage,
  pageNum,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [status, setStatus] = useState('')
  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      role: roleName,
      status: status,
    })
    setUserRoleValue(roleName)
    setUserStatus(status)
  }

  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setUserRoleValue(filterData?.role)
    setRoleName(filterData?.role)
    setStatus(filterData?.status)
  }

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="10px"
        popupClassName="filter"
      >
        <TabPane tab="User Role" key="1">
          <UserRoleFilter
            userRole={userRole}
            UserRoleValue={roleName}
            setUserRoleValue={setRoleName}
          />
        </TabPane>
        {userStatus !== '0' && userStatus !== '1' && (
          <TabPane tab="Status" key="2">
            <StatusFilter userStatus={status} setUserStatus={setStatus} />
          </TabPane>
        )}
      </StyledTab>

      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={() => submitFilter()}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )

  return (
    <TableSearchFilter
      placeholder={'Search by Name'}
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      setSearchValue={setSearchValue}
      currentCount={10 * pageNum + userMetrics?.data?.result?.length}
      totalCount={userMetrics?.data?.count}
      onOpenChange={onCancelFilter}
      setPageNum={setPageNum}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default UsersFilter
