import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'

const DurationFilter = ({ setStatusList, value }) => {
  const options = [
    {
      label: '1 Years',
      value: 1,
    },
    {
      label: '2 Years',
      value: 2,
    },
    {
      label: '3 Years',
      value: 3,
    },
    {
      label: '4 Years',
      value: 4,
    },
    {
      label: '5 Years',
      value: 5,
    },
    {
      label: '6 Years',
      value: 6,
    },
  ]

  const onChange = checkedValues => {
    setStatusList(checkedValues)
  }

  return (
    <FilterCheckBoxComp options={options} value={value} onChange={onChange} />
  )
}

export default DurationFilter
