import React from 'react'
import styled from 'styled-components'
import Flex from 'components/Flex'
import { Pagination as styledPagination } from 'antd'

export const PaginationStyles = styled(styledPagination)`
  z-index: 1;
`
export const FlexWrapper = styled(Flex)`
  margin: 20px 0;
  flex-wrap: wrap;
  position: sticky;
  text-align: center;
`

const Pagination = ({
  onChange,
  totalPages = 0,
  pageSize = 10,
  size = '',
  defaultCurrent = 0,
  ...rest
}) => {
  return (
    <FlexWrapper center>
      <PaginationStyles
        showSizeChanger={false}
        onChange={onChange}
        defaultCurrent={defaultCurrent}
        total={totalPages}
        pageSize={pageSize}
        {...rest}
      />
    </FlexWrapper>
  )
}
export default Pagination
