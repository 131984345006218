/* eslint-disable no-unused-vars */
import initializeApp from 'utils/initializeApp'
import jwtDecode from 'jwt-decode'
import selector from 'modules/Auth/selectors'
import adminRequest from 'utils/adminRequest'
import authRequest from 'utils/authRequest'
import SuccessMessage from 'utils/SuccessMessage'
import request from '../../utils/request'
const { AUTH_URL } = process.env

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SET_STUDENT_ID = 'SET_STUDENT_ID'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_ROLE = 'SET_ROLE'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'

export const SignInSuccess = token => {
  return {
    type: SIGN_IN_SUCCESS,
    token,
  }
}

export const setUserRoleData = list => {
  return {
    type: SET_USER_ROLE,
    list,
  }
}

export const setUserId = id => {
  return {
    type: SET_USER_ID,
    id,
  }
}

export const setCountriesList = data => {
  return {
    type: SET_COUNTRIES_LIST,
    data,
  }
}

export const setCurrentUserInfo = data => {
  return {
    type: SET_USER_INFO,
    data,
  }
}
export const setRoles = roleData => {
  return {
    type: SET_ROLE,
    roleData,
  }
}

export const setCurrentUserPermissions = data => {
  return {
    type: SET_USER_PERMISSIONS,
    data,
  }
}

export const getUserRoles = () => async (dispatch, getState) => {
  try {
    let response = await authRequest.get(`user/role`)
    dispatch(setUserRoleData(response?.data?.data))
  } catch (error) {
    console.error('Getting error in corporate data', error)
  }
}

export const getUserInfo = () => async (dispatch, getState) => {
  const state = getState()
  const userId = selector.getUserId(state)
  try {
    let resp = await authRequest.get(`user/${userId}`)
    dispatch(setCurrentUserInfo(resp?.data?.data))
  } catch (error) {
    console.error('Getting error in user data', error)
  }
}

export const getRoles = () => async (dispatch, getState) => {
  try {
    let resp = await adminRequest.get(`roles/journey/INTERNAL`)
    dispatch(setRoles(resp?.data?.data))
  } catch (error) {
    console.error('Getting error in user data', error)
  }
}

export const SignIn = token => async dispatch => {
  try {
    if (token) {
      const decodedToken = jwtDecode(token)
      dispatch(setUserId(decodedToken?.user?._id))
      initializeApp(token)
      dispatch(SignInSuccess(token))
    }
  } catch (error) {
    console.error('Getting error while login', error)
  }
}

export const getListOfCountries =
  ({ searchValue, pageLimit = 50, currentPage = 0 }) =>
  async (dispatch, getState) => {
    let searchKey = searchValue ? `&search=${searchValue}` : ''
    try {
      let response = await request.get(
        `/search/countries?currentPage=${currentPage}&pageLimit=${pageLimit}${searchKey}`
      )
      dispatch(setCountriesList(response?.data?.data?.result || []))
      return response
    } catch (error) {
      console.error('Error in getting list of countries.', error)
      ErrorMessage(error?.response)
      return error?.response
    }
  }

export const signOut = () => async dispatch => {
  SuccessMessage('Logged out successfully!')
  localStorage.clear()
  window.location.href = AUTH_URL
  window.location.replace(AUTH_URL)
}

export const getUserPermissions = roleId => async (dispatch, getState) => {
  try {
    let resp = await adminRequest.get(`roleEvents/${roleId}`)
    dispatch(setCurrentUserPermissions(resp?.data?.data))
  } catch (error) {
    console.error('Getting error in user data', error)
  }
}
