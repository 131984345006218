import { Spin } from 'antd'
import Flex from 'components/Flex'
import SearchInput from 'components/SearchInput'
import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Container, Contents, FilterHeader, FilterText, } from 'components/UIComponents/ReportSearchFilter/Style/style'
import React from 'react'


const TierFilter = ({
  industryValue,
  setIndustryValue,
  sectorList,
  setKeyIndustry,
  keyIndustry,
  isReport,
  loading,
  flag,
  setFlag,
}) => {

  const onChange = checkedvalue => {
    let indexToRemove = industryValue?.indexOf(...checkedvalue)
    let industryCopy = [...industryValue]
    if (indexToRemove !== -1) {
      industryCopy.splice(indexToRemove, 1)
      setIndustryValue([...industryCopy])
    } else {
      setIndustryValue([...industryValue, ...checkedvalue])
    }
  }

  const Spinner = (
    <>
      {!sectorList?.length && loading ? (
        <Spin />
      ) : !sectorList?.length && !loading ? (
        <RowNumberData>No Data Found</RowNumberData>
      ) : (
        ''
      )}

    </>
  )


  return (

    <Contents>
      <FilterHeader>
        <FilterText>Tier</FilterText>
        <SearchInput
          background="#F8F8F8"
          width={'100%'}
          placeholder="Search by Tier"
          bordered={false}
          setSearchValue={setKeyIndustry}
          searchValue={keyIndustry}
          isReport={isReport}
          flag={flag}
          setFlag={setFlag}
        />
      </FilterHeader>
      <Container>
        <p>
          <FilterCheckBoxComp
            options={sectorList}
            value={industryValue}
            onChangeCheck={e => onChange([e.target.value])}
          />
        </p>
        <br />
        <Flex center>{Spinner}</Flex>
      </Container>
    </Contents>

  )
}

export default TierFilter
