import { Empty } from 'antd'
import Button from 'components/Button/index'
import Pagination from 'components/Pagination'
import {
  RowNumberData,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import {
  default as GeneralStatus,
  default as InstituteStatus,
} from 'components/UIComponents/Status/index'
import PlusIcon from 'components/icons/PlusIcon'
import {
  EmptyWrapper,
  PaddedDiv,
  TopHeader,
  TopHeading,
} from 'modules/Dashboard/Styles/style'
import ActionDropdown from 'modules/Systemconfig/Components/ActionDropdown/index'
import React from 'react'
import InstituteFilter from '../../../modules/Systemconfig/Components/Filter/InstituteFilter'
import Flex from 'components/Flex'
const { CORPORATE_API_URL } = process.env
const { STD_API_URL } = process.env
const { ADMIN_API_URL } = process.env
const { INST_API_URL } = process.env
const { API_URL } = process.env

const SystemconfigTable = ({
  setSortBy,
  loading,
  setPageNum,
  pageNum,
  tableData,
  dataToAdd,
  showDrawer,
  data,
  setStatusValue,
  statusValue,
  setSearchValue,
  items,
  list,
  submitSystemConfigFilter,
  onEdit,
  onUpdateStatus,
  confirmDelete,
  toView,
  useDebounce,
  menu,
  searchValue,
  clearInput,
  defaultFirstPageNumber,
  listKey,
  isCurrentPageLabel = false,
  handleFilterCall,
}) => {
  const ListofDomains = [
    {
      name: 'Functions',
      base: 'corporate/curd/export/functions',
      URlData: CORPORATE_API_URL,
    },
    {
      name: 'Domain',
      base: 'institutes/curd/export/Domain',
      URlData: INST_API_URL,
    },
    {
      name: 'Roles',
      base: 'corporate/curd/export/roles',
      URlData: CORPORATE_API_URL,
    },
    {
      name: 'Sector',
      base: 'corporate/curd/export/sector',
      URlData: CORPORATE_API_URL,
    },
    {
      name: 'Industry',
      base: 'corporate/curd/export/industry',
      URlData: CORPORATE_API_URL,
    },
    {
      name: 'Skills',
      base: 'students/curd/export/skills',
      URlData: STD_API_URL,
    },
    // {
    //   name:'Company Name',
    //   base:'students/curd/export/company',
    //   URlData : STD_API_URL
    // },
    {
      name: 'Screens',
      base: 'admin/curd/export/screens',
      URlData: ADMIN_API_URL,
    },
    {
      name: 'Events',
      base: 'admin/curd/export/events',
      URlData: ADMIN_API_URL,
    },
    {
      name: 'Sub-Events',
      base: 'admin/curd/export/SubEvents',
      URlData: ADMIN_API_URL,
    },
    {
      name: 'University',
      base: 'institutes/curd/export/University',
      URlData: INST_API_URL,
    },
    {
      name: 'College',
      base: 'institutes/curd/export/College',
      URlData: INST_API_URL,
    },
    {
      name: 'Subject Area',
      base: 'institutes/curd/export/SubjectArea',
      URlData: INST_API_URL,
    },
    {
      name: 'Degree Type',
      base: 'institutes/curd/export/DegreeType',
      URlData: INST_API_URL,
    },
    {
      name: 'Degree Level',
      base: 'institutes/curd/export/DegreeLevel',
      URlData: INST_API_URL,
    },
    {
      name: 'Degree',
      base: 'institutes/curd/export/Degree',
      URlData: INST_API_URL,
    },
    {
      name: 'Department',
      base: 'institutes/curd/export/Department',
      URlData: INST_API_URL,
    },
    {
      name: 'Specialisation',
      base: 'institutes/curd/export/Specialisation',
      URlData: INST_API_URL,
    },
    {
      name: 'Country',
      base: 'admin/curd/export/Country',
      URlData: API_URL,
    },
    {
      name: 'State',
      base: 'admin/curd/export/State',
      URlData: API_URL,
    },
    {
      name: 'City',
      base: 'admin/curd/export/City',
      URlData: API_URL,
    },
    {
      name: 'Location',
      base: 'admin/curd/export/Location',
      URlData: API_URL,
    },
  ]
  const ListTOExport = [
    'Functions',
    'Roles',
    'Sector',
    'Industry',
    'Skills',
    'Screens',
    'Events',
    'Sub-Events',
    'University',
    'College',
    'Domain',
    'Degree Type',
    'Degree Level',
    'Degree',
    'Department',
    'Specialisation',
    'Country',
    'State',
    'City',
    'Location',
  ]
  const onSorting = (pagination, row, sorter) => {
    let order = ''
    let sort = ''
    if (sorter.order === 'descend') {
      sort = sorter.field

      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
      sort = sorter.field
    }
    let sortBy = {
      orderBy: order,
      sortName: sort,
    }
    setSortBy(sortBy)

    // Reset page number to 1 after sorting
    setPageNum(1)
  }

  const onPageChange = num => {
    setPageNum(num)
  }

  const downloadExcel = data => {
    ListofDomains?.map(items => {
      if (items?.name === data) {
        let uri = items?.URlData + items?.base
        window.open(uri, '_blank')
        return
      }
      return items
    })
  }
  const columns = [
    {
      title:
        menu === 'Screens' || menu === 'Events' || menu === 'Sub-Events'
          ? null
          : 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (_, rowData) => {
        if (menu === 'Screens' || menu === 'Events' || menu === 'Sub-Events') {
          return null // Return null to hide the column
        }

        return (
          <RowNumberData>
            {rowData?.status ? (
              <InstituteStatus
                status={
                  menu !== 'College'
                    ? rowData?.status.toString()
                    : rowData?.isActive
                }
              />
            ) : (
              <GeneralStatus
                status={Boolean(rowData?.isActive ?? rowData?.is_active)}
              />
            )}
          </RowNumberData>
        )
      },
    },

    {
      title: 'ACTION',
      dataIndex: 'id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          showDrawer={showDrawer}
          onEdit={onEdit}
          onUpdateStatus={onUpdateStatus}
          confirmDelete={confirmDelete}
          toView={toView}
          menu={menu}
        />
      ),
    },
  ]
  const TotalFields = [...dataToAdd, ...columns]
  return (
    <Wrapper>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>
            {tableData?.text}({list?.count || 0})
          </TopHeading>
          <Flex style={{ gap: '12px' }}>
            {list?.count !== 0 && ListTOExport?.includes(tableData?.text) && (
              <Button.Primary
                height={'38px'}
                text={'Export To Excel'}
                onClick={() => downloadExcel(tableData?.text)}
              />
            )}
            <Button.Primary
              height={'38px'}
              text={'Add ' + tableData?.id == 6 ? 'Course' : tableData?.text}
              icon={<PlusIcon />}
              onClick={showDrawer}
            />
          </Flex>
        </TopHeader>
      </PaddedDiv>
      <InstituteFilter
        list={list}
        statusValue={statusValue}
        setStatusValue={setStatusValue}
        items={items}
        setSearchValue={setSearchValue}
        submitSystemConfigFilter={submitSystemConfigFilter}
        useDebounce={useDebounce}
        searchValue={searchValue}
        clearInput={clearInput}
        menus={menu}
        setCurrentPage={setPageNum}
        listKey={listKey}
        defaultFirstPageNumber={defaultFirstPageNumber}
        isCurrentPageLabel={isCurrentPageLabel}
        dataList={data}
        handleFilterCall={handleFilterCall}
      />
      <StyledTable
        key={menu}
        columns={TotalFields}
        dataSource={data}
        pagination={false}
        onChange={onSorting}
        loading={loading}
        locale={{
          emptyText: (
            <EmptyWrapper center isSpin={loading}>
              {!loading ? (
                <Empty description={`No ` + tableData?.text + ` Found!`} />
              ) : null}
            </EmptyWrapper>
          ),
        }}
      />
      {list?.count !== 0 && (
        <Pagination
          onChange={onPageChange}
          totalPages={list?.count}
          pageSize={10}
          current={pageNum}
        />
      )}
    </Wrapper>
  )
}

export default SystemconfigTable
