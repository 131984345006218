import request from 'utils/adminRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'

export const SET_STUDENT_LIST_FOR_REPORT =
  'corporate/SET_STUDENT_LIST_FOR_REPORT'
export const SET_COLLEGE_LIST = `corporate/SET_SECTOR_LIST`
export const SET_COURSE_LIST = `corporate/SET_STATE_LIST`
export const SET_DEGREE_LIST = `corporate/SET_DEGREE_LIST`
export const SET_SPECIALISATION_LIST = `corporate/SET_INDUSTRY_LIST`
export const SET_PLACED_STUDENT_LIST = `corporate/SET_PLACED_STUDENT_LIST`
export const SET_STUDENT_SKILL_WISE = `corporate/SET_STUDENT_SKILL_WISE`
export const SET_STUDENT_COURSE_WISE = `corporate/SET_STUDENT_COURSE_WISE`
export const SET_CORPORATE_NAME_LIST = `corporate/SET_CORPORATE_NAME_LIST`
export const SET_SKILL_LIST = `corporate/SET_SKILL_LIST`
export const SET_EXCEL_DATA = `stud/EXCEL`
export const SET_UNIVERSITY_DATA = `stud/UNIVERSITY_DATA`
export const SET_COLLEGE_DATA = `stud/SET_COLLEGE_DATA`
export const SET_SPECIALISATION_DATA = `stud/SET_SPECIALISATION_DATA`
export const SET_CITY_DATA = `stud/SET_CITY_DATA`
export const SET_DEGREE_DATA = `stud/SET_DEGREE_DATA`
export const SET_TIER_DATA = `stud/SET_TIER_DATA`
export const SET_CORPORATE_DATA = `stud/SET_CORPORATE_DATA`
export const SET_SKILL_DATA = `stud/SET_SKILL_DATA`
export const SET_STATE_STUDENT_DATA = `stud/SET_STATE_STUDENT_DATA`
export const SET_CITY_STUDENT_DATA = `stud/SET_CITY_STUDENT_DATA`
export const setStudentListForReport = data => {
  return {
    type: SET_STUDENT_LIST_FOR_REPORT,
    data,
  }
}
export const setSkillData = data => {
  return {
    type: SET_SKILL_DATA,
    data,
  }
}
export const setTierData = data => {
  return {
    type: SET_TIER_DATA,
    data,
  }
}
export const setCorporateUrl = data => {
  return {
    type: SET_CORPORATE_DATA,
    data,
  }
}
export const setDegreeData = data => {
  return {
    type: SET_DEGREE_DATA,
    data,
  }
}
export const setSpecialisationData = data => {
  return {
    type: SET_SPECIALISATION_DATA,
    data,
  }
}
export const setCityData = data => {
  return {
    type: SET_CITY_DATA,
    data,
  }
}
export const setCollegeData = data => {
  return {
    type: SET_COLLEGE_DATA,
    data,
  }
}
export const setUniverstiyData = data => {
  return {
    type: SET_UNIVERSITY_DATA,
    data,
  }
}
export const setCollegeList = data => {
  return {
    type: SET_COLLEGE_LIST,
    data,
  }
}
export const setCourseList = data => {
  return {
    type: SET_COURSE_LIST,
    data,
  }
}
export const setDegreeList = data => {
  return {
    type: SET_DEGREE_LIST,
    data,
  }
}
export const setSpecialisationList = data => {
  return {
    type: SET_SPECIALISATION_LIST,
    data,
  }
}

export const setStudentPlacedList = data => {
  return {
    type: SET_PLACED_STUDENT_LIST,
    data,
  }
}
export const setStudentSkillWiseDetails = data => {
  return {
    type: SET_STUDENT_SKILL_WISE,
    data,
  }
}
export const setStudentCourseWiseDetails = data => {
  return {
    type: SET_STUDENT_COURSE_WISE,
    data,
  }
}
export const setCorporateNameList = data => {
  return {
    type: SET_CORPORATE_NAME_LIST,
    data,
  }
}
export const setSkillList = data => {
  return {
    type: SET_SKILL_LIST,
    data,
  }
}
export const setStudStateList = data => {
  return {
    type: SET_STATE_STUDENT_DATA,
    data,
  }
}
export const setStudCityList = data => {
  return {
    type: SET_CITY_STUDENT_DATA,
    data,
  }
}
export const setExcelData = data => {
  return {
    type: SET_EXCEL_DATA,
    data,
  }
}
export const getStudentListForReport =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    dispatch(setExcelData([]))
    try {
      let specialisation = encodeURIComponent(filterDatas?.specialisation || '')
      let course = encodeURIComponent(filterDatas?.course || '')
      let degree = encodeURIComponent(filterDatas?.degree || '')
      let college = encodeURIComponent(filterDatas?.college || '')
      let tier = encodeURIComponent(filterDatas?.tier || '')
      let corporate = encodeURIComponent(filterDatas?.corporate || '')
      let university = encodeURIComponent(filterDatas?.university || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''
      const Specialisation = specialisation
        ? `&specialisation=${specialisation}`
        : ``
      const Course = course ? `&course=${course}` : ``
      const Degree = degree ? `&degreestreammapid=${degree}` : ``
      const College = college ? `&college=${college}` : ``
      const Tier = tier ? `&tier=${tier}` : ``
      const Corporate = tier ? `&corporate=${corporate}` : ``
      const University = university ? `&university=${university}` : ``
      const City = city ? `&city=${city}` : ``
      const State = state ? `&state=${state}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()?.trim()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/student-report/lists?size=${pageSize}&page=${pageNumber}${Search}${Degree}${State}${Course}${College}${CreatedAtStart}${Tier}${University}${City}${CreatedAtEnd}${Specialisation}${Corporate}${Sort}${OrderBy}`
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setStudentListForReport(response?.data?.data || []))
      }

      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getCollegeList =
  ({ setLoading, keyCollege = '', page = 0 }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }

    try {
      const myEncodedString = encodeURIComponent(keyCollege.toLowerCase())
      const response = await request.get(
        `/collegenamemaster/lists?name=${myEncodedString}&page=${page}&size=10 `
      )
      dispatch(setCollegeList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getCourseList =
  ({ setLoading, keyCourse = '', page = 0 }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keyCourse.toLowerCase())
      const response = await request.get(
        `/student/coursemaster/lists?course=${myEncodedString}&page=${page}&size=10`
      )
      dispatch(setCourseList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getDegreeList =
  ({ setLoading, keyDegree = '', page = 0 }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keyDegree.toLowerCase())
      const response = await request.get(
        `/student/degreemaster/lists?degree=${myEncodedString}&page=${page}&size=10`
      )
      dispatch(setDegreeList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getSpecialisationList =
  ({ setLoading, keySpecialisation = '', page = 0 }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(
        keySpecialisation.toLowerCase()
      )
      const response = await request.get(
        `/student/specializationmaster/lists?special=${myEncodedString}&page=${page}&size=10`
      )
      dispatch(setSpecialisationList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getPlacedStudentListForReport =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    dispatch(setExcelData([]))
    try {
      let specialisation = encodeURIComponent(filterDatas?.specialisation || '')
      let course = encodeURIComponent(filterDatas?.course || '')
      let degree = encodeURIComponent(filterDatas?.degree || '')
      let college = encodeURIComponent(filterDatas?.college || '')
      let tier = encodeURIComponent(filterDatas?.tier || '')
      let university = encodeURIComponent(filterDatas?.university || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''
      let corporate = encodeURIComponent(filterDatas?.corporate || '')
      const Specialisation = specialisation
        ? `&specialisation=${specialisation}`
        : ``
      const Course = course ? `&course=${course}` : ``
      const Degree = degree ? `&degreestreammapid=${degree}` : ``
      const College = college ? `&college=${college}` : ``
      const Tier = tier ? `&tier=${tier}` : ``
      const University = university ? `&university=${university}` : ``
      const City = city ? `&city=${city}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Corporate = corporate ? `&corporate=${corporate}` : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()?.trim()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/studentplaced-report/lists?size=${pageSize}&page=${pageNumber}${Search}${Degree}${Course}${College}${CreatedAtStart}${Tier}${University}${City}${CreatedAtEnd}${Specialisation}${Corporate}${Sort}${OrderBy}`
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setStudentPlacedList(response?.data?.data || []))
      }

      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getStudentSkillWiseDetails =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async dispatch => {
    if (setLoading) {
      dispatch(setExcelData([]))
      setLoading(true)
    }
    let BASE_URL = '/studentplacedskillwise-report/lists'
    try {
      let specialisation = encodeURIComponent(filterDatas?.specialisation || '')
      let course = encodeURIComponent(filterDatas?.course || '')
      let degree = encodeURIComponent(filterDatas?.degree || '')
      let college = encodeURIComponent(filterDatas?.college || '')
      let tier = encodeURIComponent(filterDatas?.tier || '')
      let university = encodeURIComponent(filterDatas?.university || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let skill = encodeURIComponent(filterDatas?.skill || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''
      const Specialisation = specialisation
        ? `&specialisation=${specialisation}`
        : ``
      const Course = course ? `&course=${course}` : ``
      const Degree = degree ? `&degreestreammapid=${degree}` : ``
      const College = college ? `&college=${college}` : ``
      const Tier = tier ? `&tier=${tier}` : ``
      const Skill = skill ? `&skill=${skill}` : ``
      const University = university ? `&university=${university}` : ``
      const City = city ? `&city=${city}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()?.trim()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `${BASE_URL}?size=${pageSize}&page=${pageNumber}${Search}${Degree}${Course}${College}${CreatedAtStart}${Tier}${University}${City}${CreatedAtEnd}${Specialisation}${Skill}${Sort}${OrderBy}`
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setStudentSkillWiseDetails(response?.data?.data || []))
      }

      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getStudentCourseWiseDetails =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    dispatch(setExcelData([]))
    let BASE_URL = '/studentplacedcoursewise-report/lists'
    try {
      let specialisation = encodeURIComponent(filterDatas?.specialisation || '')
      let course = encodeURIComponent(filterDatas?.course || '')
      let degree = encodeURIComponent(filterDatas?.degree || '')
      let college = encodeURIComponent(filterDatas?.college || '')
      let tier = encodeURIComponent(filterDatas?.tier || '')
      let university = encodeURIComponent(filterDatas?.university || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let skill = encodeURIComponent(filterDatas?.skill || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''
      const Specialisation = specialisation
        ? `&specialisation=${specialisation}`
        : ``
      const Course = course ? `&course=${course}` : ``
      const Degree = degree ? `&degreestreammapid=${degree}` : ``
      const College = college ? `&college=${college}` : ``
      const Tier = tier ? `&tier=${tier}` : ``
      const University = university ? `&university=${university}` : ``
      const City = city ? `&city=${city}` : ``
      const Skill = skill ? `&skill=${skill}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()?.trim()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `${BASE_URL}?size=${pageSize}&page=${pageNumber}${Search}${Degree}${Course}${College}${CreatedAtStart}${Tier}${University}${City}${CreatedAtEnd}${Specialisation}${Skill}${Sort}${OrderBy}`
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setStudentCourseWiseDetails(response?.data?.data || []))
      }

      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getCorporateNameList =
  ({ setLoading, keyName = '', page = 0 }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keyName.toLowerCase())
      const response = await request.get(
        `/corporatesnamemaster/lists?name=${myEncodedString}&page=${page}&size=10`
      )
      dispatch(setCorporateNameList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getSkillList =
  ({ setLoading, keySkill = '', page = 0 }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keySkill.toLowerCase())
      const response = await request.get(
        `/student/skillsmaster/lists?skill=${myEncodedString}&page=${page}&size=10 `
      )
      dispatch(setSkillList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

//using university as specialisation
export const UniversityUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/specializationmaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setUniverstiyData(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

export const CollegeUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/collegenamemaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setCollegeData(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
//corporate...
export const SpecialisationUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await elasticSearchRequest.get(
        `/student-filter/universities/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setSpecialisationData(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
export const CityUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }

      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/citymaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setCityData(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
export const DegreeUrl =
  (flag, setLoading, name, page = 0, setDegreeLoader) =>
  async dispatch => {
    try {
      if (page == 0) {
        setDegreeLoader(true)
        setDegreeData([])
      }
      setLoading(true)
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name
        ? `&name=${name?.toLocaleLowerCase()?.replace(/\./g, '')?.trim()}`
        : ``

      let response = await request.get(
        `/student-filter/degreedepartmentmaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setDegreeData(response?.data?.data))
      setLoading(false)
      setDegreeLoader(false)
    } catch (error) {
      setLoading(false)
      setDegreeLoader(false)
    }
  }

export const TierUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/tierinstitutemaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setTierData(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

export const CorporateUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/corporatesnamemaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setCorporateUrl(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
export const SkillUrl =
  (setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const myEncodedString = encodeURIComponent(name.toLowerCase())
      const response = await request.get(
        `/student/skillsmaster/lists?skill=${myEncodedString}&page=${page}&size=10 `
      )
      dispatch(setSkillData(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

export const StateUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/statemaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setStudStateList(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

export const StudentCityUrl =
  (flag, setLoading, name, page = 0) =>
  async dispatch => {
    try {
      if (page == 0) {
        setLoading(true)
      }
      const Flag = flag ? `&flag=${flag}` : ``
      const Tier = name ? `&name=${name?.toLocaleLowerCase()}` : ``
      let response = await request.get(
        `/student-filter/citymaster/lists?page=${page}&size=10${Tier}${Flag}`
      )
      dispatch(setStudCityList(response?.data?.data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
