import React from 'react'

import {
  CardRow,
  ParagraphHeader,
  SystemConfigSubHeading,
  SystemPaddedDiv,
  TopHeader,
  Divider,
} from 'modules/Systemconfig/Styles/style'

import SysConfigCards from 'components/SysConfigCards'
import FunctionsIcons from 'components/icons/FunctionsIcons'
import IndustryIcon from 'components/icons/IndustryIcon.js'
import { useNavigate } from 'react-router-dom'
import CityIcon from '../../../../components/icons/CityIcon'
import CollegeIcon from '../../../../components/icons/CollegeIcon'
import CorporateMasterIcons from '../../../../components/icons/CorporateMaster'
import CountryIcon from '../../../../components/icons/CountryIcon'
import CourseIcon from '../../../../components/icons/CourseIcon'
import DegreeIcon from '../../../../components/icons/DegreeIcon'
import HolidayIcon from '../../../../components/icons/HolidayIcon'
import LocationIcon from '../../../../components/icons/Location'
import SkillsIcon from '../../../../components/icons/SkillsIcon'
import StateIcon from '../../../../components/icons/StateIcon'
import StreamIcon from '../../../../components/icons/StreamIcon'
import SubSkills from '../../../../components/icons/SubSkills'
import UniversityIcon from '../../../../components/icons/UniversityIcon'

const GeneralSettings = () => {
  const dataItem = [
    {
      title: 'General Settings',
      data: [
        { id: 1, icon: <FunctionsIcons />, text: 'Functions' },
        { id: 2, icon: <SubSkills />, text: 'Roles' },
        { id: 3, icon: <HolidayIcon />, text: 'Sector' },
        { id: 4, icon: <IndustryIcon />, text: 'Industry' },
        { id: 5, icon: <SkillsIcon />, text: 'Skills' },
        // { id: 6, icon: <HolidayIcon />, text: 'Holidays', value: 2 },
        // { id: 7, icon: <SubSkills />, text: 'SubSkills' },
        // { id: 8, icon: <CalendarNewIcon />, text: 'Calender', value: 2 },
      ],
    },
    {
      title: 'Company Settings',
      data: [
        {
          id: 1,
          icon: <CorporateMasterIcons />,
          text: 'Company',
        },
        // {
        //   id: 2,
        //   icon: <CorporateEntityIcon />,
        //   text: 'Corporate Entity',
        // ,
        // },
        // {
        //   id: 3,
        //   icon: <CorporateSubMasterIcons />,
        //   text: 'Corporate Submaster',
        //
        // },
        // {
        //   id: 4,
        //   icon: <CorporateClassificationIcons />,
        //   text: 'Corporate Classification',
        //   value: 2,
        // },
      ],
    },
    {
      title: 'Institute Settings',
      data: [
        { id: 1, icon: <UniversityIcon />, text: 'University' },
        { id: 2, icon: <CollegeIcon />, text: 'College' },
        { id: 3, icon: <CourseIcon />, text: 'Domain' },
        { id: 4, icon: <DegreeIcon />, text: 'Degree Type' },
        { id: 5, icon: <SubSkills />, text: 'Degree Level' },
        { id: 6, icon: <DegreeIcon />, text: 'Degree' },
        {
          id: 7,
          icon: <StreamIcon />,
          text: 'Department',
        },
        { id: 8, icon: <SubSkills />, text: 'Specialisation' },
        {
          id: 9,
          icon: <CourseIcon />,
          text: 'Degree, Department & Specialisation Mapping',
          value: 2,
        },
      ],
    },
    {
      title: 'Permission Settings',
      data: [
        { id: 1, icon: <UniversityIcon />, text: 'Screens' },
        { id: 2, icon: <CollegeIcon />, text: 'Events' },
        { id: 3, icon: <DegreeIcon />, text: 'Sub-Events' },
      ],
    },
    {
      title: 'Location Settings',
      data: [
        { id: 1, icon: <CountryIcon />, text: 'Country' },
        // { id: 2, icon: <CurrencyIcon />, text: 'Currency' },
        { id: 2, icon: <StateIcon />, text: 'State' },
        { id: 3, icon: <CityIcon />, text: 'City' },
        { id: 4, icon: <LocationIcon />, text: 'Location' },
        // { id: 6, icon: <PinCodeIcon />, text: 'Pincode' },
      ],
    },
    // {
    //   title: 'Billing/Payment Settings',
    //   data: [
    //     { id: 1, icon: <BankIcon />, text: 'Bank'  },
    //     { id: 2, icon: <IndustryIcon />, text: 'Rate Card', value: 15 },
    //   ],
    // },
  ]

  const navigate = useNavigate()
  const onCardClick = (title, cardTitle, cardId) => {
    switch (title) {
      case 'General Settings':
        navigate(`/systemConfig/GeneralTableSettings/${cardTitle}/${cardId}`)
        break
      case 'Company Settings':
        navigate('/systemConfig/corporateSettings')
        break
      case 'Institute Settings':
        navigate(`/systemConfig/instituteSettings/${cardTitle}/${cardId}`)
        break
      case 'Permission Settings':
        navigate(`/systemConfig/permissionSettings/${cardTitle}/${cardId}`)
        break
      case 'Location Settings':
        navigate(`/systemConfig/locationSettings/${cardTitle}/${cardId}`)
        break
      case 'Billing/Payment Settings':
        navigate('/systemConfig/billingSettings')
        break
      default:
        break
    }
  }
  return (
    <>
      {dataItem.map((item, index) => {
        return (
          <>
            <SystemPaddedDiv column>
              <TopHeader row spaceBetween alignCenter>
                <SystemConfigSubHeading flexStart>
                  {item.title}
                </SystemConfigSubHeading>
              </TopHeader>
            </SystemPaddedDiv>
            <SystemPaddedDiv>
              {item?.data?.map((card, i) => (
                <CardRow key={card.id}>
                  <div className="CardItem">
                    <SysConfigCards
                      icon={card.icon}
                      style={{ marginBottom: '10' }}
                      onClick={() =>
                        onCardClick(item.title, card.text, card.id)
                      }
                    />
                    <ParagraphHeader isWidth={card.id === 9}>
                      {card.text}
                    </ParagraphHeader>
                  </div>
                </CardRow>
              ))}
            </SystemPaddedDiv>
            <SystemPaddedDiv>
              <Divider />
            </SystemPaddedDiv>
          </>
        )
      })}
    </>
  )
}
export default GeneralSettings
