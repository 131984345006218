import React from 'react'
import CustomTag from 'components/CustomTag'

const Status = ({ status }) => {
  let colorType
  let tagMessage
  if (status == 1 || status) {
    colorType = 'green'
    tagMessage = 'Active'
  } else if (status == 0 || !status) {
    colorType = 'orange'
    tagMessage = 'Inactive'
  } else if (status == '2') {
    colorType = 'orange'
    tagMessage = 'Veri.Pending'
  } else if (status == '3') {
    colorType = 'green'
    tagMessage = 'Onboarding'
  } else if (status == '4') {
    colorType = 'grey'
    tagMessage = 'Request To Change'
  }
  return (
    <CustomTag colorType={colorType} message={tagMessage} bordered={false} />
  )
}

export default Status
