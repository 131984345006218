import React from 'react'
import { Form } from 'antd'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { FooterFlex } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import { createCorporate } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/actions'
import PreviewDetails from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer/Partials/PreviewDetails'
import { useDispatch, useSelector } from 'react-redux'

const PreviewDrawer = ({
  form,
  corporateClear,
  setNewPreviewDrawer,
  newPreviewDrawer,
  setNewLinkGenerateDrawer,
  title = 'Preview',
  sendButtonText = 'Generate Link',
  setNewUploadDocumentDrawer,
  isHeadOffice,
  setheadOffice,
  setClearInput
}) => {
  const dispatch = useDispatch()

  const onClear = () => {
    form.resetFields()
    setNewPreviewDrawer(false)
    corporateClear()
  }

  let formDetail = useSelector(state => state?.formData?.formData)
  const goBackToAddForm = () => {
    setNewPreviewDrawer(false)
    setNewUploadDocumentDrawer(true)
  }
  const submitData = () => {
    const reqData = {
      admin: {
        email: formDetail?.email,
        phoneNumber: formDetail?.phoneNumber,
      },
      corporate: {
        name: formDetail?.corporateName,
        city: formDetail?.city,
        country: formDetail?.country,
        countryId: formDetail?.countryId,
        cityId: formDetail?.cityId,
        stateId: formDetail?.stateId,
        state: formDetail?.state,
        postalCode: formDetail?.zipcode,
        isHeadOffice: formDetail?.isHeadOffice,
        contactNumber: formDetail?.phoneNumber,
        contactEmail: formDetail?.email,
        website: formDetail?.website,
        contactPerson: formDetail?.contactPerson,
        // regDocs: formDetail?.contractURL ? formDetail?.contractURL : null,
        ...(formDetail?.contractURL && { regDocs: formDetail?.contractURL }),
      },
    }
    let payLoad = { ...reqData }
    const callBack = () => {
      setNewPreviewDrawer(false)
      setNewLinkGenerateDrawer(true)
      corporateClear(0)
      setClearInput(true)
      setTimeout(()=>{
        setClearInput(false)
      },[1000])
    }
    dispatch(createCorporate(payLoad, callBack, goBackToAddForm))
  }
  const content = () => {
    return (
      <div>
        <div>
          <PreviewDetails />
        </div>
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary text="Back" onClick={goBackToAddForm} />
        <Button.Primary
          htmlType="submit"
          form="corpPreviewForm"
          text={sendButtonText}
        />
      </FooterFlex>
    )
  }
  return (
    <Form
      scrollToFirstError
      id="corpPreviewForm"
      form={form}
      layout="vertical"
      onFinish={submitData}
    >
      <Drawer
        width="48%"
        title={title}
        placement="right"
        closable={true}
        onClose={onClear}
        visible={newPreviewDrawer}
        content={content()}
        footer={footer()}
      />
    </Form>
  )
}

export default PreviewDrawer
