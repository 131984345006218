const INITIAL_STATE = {
  token: '',
  countrycodeList: [],
  countryList: [],
  stateList: [],
  cityList: [],
  industryList: {
    data: [],
  },
  universityList: [],
  masterSearchApi: [],
  citiesWithPagination: {
    cities: [],
  },
  instituteCityList: {
    cities: [],
  },
}

export const onboarding = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action?.token,
      }

    case 'SET_LIST_OF_COUNTRY':
      return {
        ...state,
        countryList: action?.list,
      }
    case 'MASTER_SEARCH_API':
      return {
        ...state,
        masterSearchApi: action?.list,
      }
    case 'SET_LIST_OF_STATE':
      return {
        ...state,
        stateList: action?.list,
      }

    case 'SET_LIST_OF_CITY':
      return {
        ...state,
        cityList: action?.list,
      }

    case 'SET_COUNTRY_CODE_LIST': {
      let uniqueCountries = []
      for (let i = 0; i < action?.list?.length; i++) {
        let isUnique = true
        for (let j = 0; j < uniqueCountries?.length; j++) {
          if (action?.list[i].phone_code === uniqueCountries[j].phone_code) {
            isUnique = false
            break
          }
        }
        if (isUnique) {
          uniqueCountries.push(action?.list[i])
        }
      }

      return {
        ...state,

        countrycodeList: uniqueCountries,
      }
    }
    case 'SET_INDUSTRY_LIST':
      return {
        ...state,
        industryList: action.data,
      }

    case 'SET_UNIVERSITY_LIST':
      return {
        ...state,
        universityList: action.data,
      }

    case 'SET_CITY_WITH_PAGINATION':
      return {
        ...state,
        citiesWithPagination: action.data,
      }

    default:
      return state
  }
}
