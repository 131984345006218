const getUserCorporatesLists = state =>
  state?.UserCorporateList?.userCorporatesList
const getSingleCorporateData = state =>
  state?.UserCorporateList?.singleCorporateData
const getActiveCorporatesLists = state =>
  state?.UserCorporateList?.activeCorporateData
const selectors = {
  getUserCorporatesLists,
  getSingleCorporateData,
  getActiveCorporatesLists,
}

export default selectors
