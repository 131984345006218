import Control from 'components/Control/Control'
import FileReader from 'components/Uploader/FileReader'
import Uploadloader from 'components/Uploader/Uploader'
import React, { useState,useEffect } from 'react'

import { Form } from 'antd'
import Label from 'components/Form/Label'
import {
  FormItem
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'


const DocumentDetails = ({ form, file, setFile }) => {
  const [contractFile, setContractFile] = useState(null)

  const contractURL = Form.useWatch('contractURL', form)
  const onChangeDocument = file => {
    form.setFieldsValue({ contractURL: file })
    setContractFile(file)
  }

  useEffect(() => {
    setContractFile(contractURL)
  }, [contractURL])
  return (
    <>
      <FormItem
        name={'contractURL'}
        rules={[
          {
            required: false,
            message: 'Please upload Contract!',
          },
        ]}
      >
        <Label>Contract </Label>
        {contractFile ? (
          <Control column top="10px" center>
            <FileReader file={contractFile} setFile={onChangeDocument} />
          </Control>
        ) : (
          <Control column top="10px" center>
            <Uploadloader
              setFile={onChangeDocument}
              isDefault={true}
              fileName={'document'}
            />
          </Control>
        )}
      </FormItem>
      <br />
    </>
  )
}

export default DocumentDetails