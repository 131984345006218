import { Form } from 'antd'
import AntdSteps from 'components/AntdSteps'
import Avatar from 'components/Avatar'
import Control from 'components/Control/Control'
import Flex from 'components/Flex'
import PluginLiveLogo from 'components/icons/PluginLiveLogo'
import {
  Container,
  Content,
  Heading,
  SubContainer,
  SubHeading,
  Wrapper,
} from 'modules/Onboarding/Partials/Corporates/Register/style'
import InstituteInfo from 'modules/Onboarding/Partials/Institutes/Register/InstituteInfo'
import { menuData } from 'modules/Onboarding/Partials/Institutes/Register/Menu'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ErrorMessage from 'utils/ErrorMessage'
import {
  editInstitute,
  getSingleInstituteData,
  setInstituteFormInfo,
} from './actions'

const BREAKPOINT = '999px'

const StyledFlex = styled(Flex)`
  position: static;
  padding: 5px 48px 5px 20px;
  height: 82px;
  margin: 0px -20px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: ${BREAKPOINT}) {
    padding: 15px 20px 15px 0px !important;
    background: #ffffff;
    margin: 0px !important;
  }
`

const IconWrapper = styled(Flex)`
  background-color: white;
  min-width: 200px;
  max-width: 200px;
  justify-content: center;
`

const Divider = styled.div`
  border-left: 1px solid #e6e7ea;
  margin-right: 25px;
  height: 55px;
`
const Header = () => {
  return (
    <StyledFlex spaceBetween centerVertically isDisplay={true}>
      <Flex centerVertically>
        <IconWrapper>
          <PluginLiveLogo />
        </IconWrapper>
        <Divider />
      </Flex>

      <Flex centerVertically>
        <Avatar background size={40} IconName="A" font={18} />
      </Flex>
    </StyledFlex>
  )
}

const Institute = ({ instituteFormInfo, ...props }) => {
  const singleInstituteData = useSelector(
    state => state?.InstituteOnboarding?.singleInstituteData
  )

  const [instCurrent, setInstCurrent] = useState(0)
  const [instSubstep, setInstSubstep] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [addInstituteForm] = Form.useForm()
  const [contactDetailsForm] = Form.useForm()
  const [taxInfoForm] = Form.useForm()
  const [pricingAndContract] = Form.useForm()
  const { instituteId } = useParams()

  //Add Corporate

  const [profileFile, setProfileFile] = useState(null)
  const [institutePresentation, setInstitutePresentation] = useState('')
  const [longDescription, setLongDescription] = useState('')

  //Add Tax

  const [authorisedSignatory, setAuthorizedSignatory] = useState(null)

  //Add Pricing and contract

  const [regDocs, setRegDocs] = useState(null)

  //Add Contract Details
  const [contractDetails, setContractDetails] = useState(null)

  const onChangeAuthorizedSignatory = file => {
    setAuthorizedSignatory(file)
    taxInfoForm.setFieldsValue({ authorisedSignatory: file })
  }

  const onSubmitAddInstitute = values => {
    const data = {
      admin: {
        email: singleInstituteData?.instituteCampus[0]?.contactEmail,
        phoneNumber: singleInstituteData?.instituteCampus[0]?.contactNumber,
        firstName: singleInstituteData?.instituteCampus[0]?.contactPerson,
      },
      institute: {
        university: values?.university,
        establish: values?.establish,
        constituency: values?.constituency,
        regDocs: singleInstituteData?.regDocs,
      },
      instituteCampus: {
        campusName: values?.name,
        campusLogoUrl: values?.profileUrl,
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2,
        website: values?.website,
        contactPerson: values?.contactPerson,
        contactEmail: values?.contactEmail,
        authorisedSignatory: singleInstituteData?.instituteCampus[0]?.authorisedSignatory,
        groupName: values?.groupName,
        instituteVideoLink: values?.instituteVideoLink,
        institutePresentationLink: values?.institutePresentationLink
          ? values?.institutePresentationLink
          : '',
        establishedYear: values?.establish,
        shortDescription: values?.shortDescription,
        longDescription: values?.longDescription,
      },
    }
    let payload = { ...data }
    dispatch(
      editInstitute({
        instituteCampusId: singleInstituteData?.campusId,
        data: payload,
        message: 'Institute Details Saved Successfully',
      })
    )
    dispatch(setInstituteFormInfo({ addInstitute: data }))
    setInstSubstep(3)
    setInstCurrent(1)
  }

  const onSubmitTaxInfo = values => {
    const data = {
      admin: {
        email: singleInstituteData?.instituteCampus[0]?.contactEmail,
        phoneNumber: singleInstituteData?.instituteCampus[0]?.contactNumber,
        firstName: singleInstituteData?.instituteCampus[0]?.contactPerson,
      },
      institute: {
        university: singleInstituteData?.university,
        establish: singleInstituteData?.establish,
        regDocs: singleInstituteData?.regDocs,
      },
      instituteCampus: {
        campusName: singleInstituteData?.instituteCampus[0]?.name,
        campusLogoUrl: singleInstituteData?.instituteCampus[0]?.profileUrl,
        addressLine1: singleInstituteData?.instituteCampus[0]?.addressLine1,
        addressLine2: singleInstituteData?.instituteCampus[0]?.addressLine2,
        website: singleInstituteData?.instituteCampus[0]?.website,
        contactPerson: singleInstituteData?.instituteCampus[0]?.contactPerson,
        contactEmail: singleInstituteData?.instituteCampus[0]?.contactEmail,
        groupName: singleInstituteData?.instituteCampus[0]?.groupName,
        instituteVideoLink:
          singleInstituteData?.instituteCampus[0]?.instituteVideoLink,
        institutePresentationLink:
          singleInstituteData?.instituteCampus[0]?.institutePresentationLink,
        establishedYear: singleInstituteData?.instituteCampus[0]?.establish,
        shortDescription:
          singleInstituteData?.instituteCampus[0]?.shortDescription,
        longDescription:
          singleInstituteData?.instituteCampus[0]?.longDescription,
        pan: values?.pan,
        gst: values?.gst,
        authorisedSignatory: values?.authorisedSignatory,
      },
    }
    let payload = { ...data }
    dispatch(
      editInstitute({
        instituteCampusId: singleInstituteData?.campusId,
        data: payload,
        message: ' TaxInfo Saved Successfully',
      })
    )

    dispatch(setInstituteFormInfo({ taxInfo: data }))
    setInstCurrent(2)
  }

  const onSubmitPricingAndContract = values => {
    const data = {
      admin: {
        email: singleInstituteData?.instituteCampus[0]?.contactEmail,
        phoneNumber: singleInstituteData?.instituteCampus[0]?.contactNumber,
        firstName: singleInstituteData?.instituteCampus[0]?.contactPerson,
      },
      institute: {
        university: singleInstituteData?.university,
        establish: singleInstituteData?.establish,
        regDocs: values?.regDocs,
      },
      instituteCampus: {
        campusName: singleInstituteData?.instituteCampus[0]?.name,
        campusLogoUrl: singleInstituteData?.instituteCampus[0]?.profileUrl,
        addressLine1: singleInstituteData?.instituteCampus[0]?.addressLine1,
        addressLine2: singleInstituteData?.instituteCampus[0]?.addressLine2,
        website: singleInstituteData?.instituteCampus[0]?.website,
        contactPerson: singleInstituteData?.instituteCampus[0]?.contactPerson,
        contactEmail: singleInstituteData?.instituteCampus[0]?.contactEmail,
        groupName: singleInstituteData?.instituteCampus[0]?.groupName,
        instituteVideoLink:
          singleInstituteData?.instituteCampus[0]?.instituteVideoLink,
        institutePresentationLink:
          singleInstituteData?.instituteCampus[0]?.institutePresentationLink,
        establishedYear: singleInstituteData?.instituteCampus[0]?.establish,
        shortDescription:
          singleInstituteData?.instituteCampus[0]?.shortDescription,
        longDescription:
          singleInstituteData?.instituteCampus[0]?.longDescription,
        pan: values?.pan,
        gst: values?.gst,
        authorisedSignatory: singleInstituteData?.instituteCampus[0]?.authorisedSignatory,
      },
    }
    let payload = { ...data }

    dispatch(setInstituteFormInfo({ pricingAndContract: values }))

    const callBack = () => {
      navigate('/onboarding/registeredSuccessfully')
    }
    if (singleInstituteData?.campusId) {
      dispatch(
        editInstitute({
          instituteCampusId: singleInstituteData?.campusId,
          data: payload,
          callBack: callBack,
          calldata: true,
        })
      )
    }
  }

  const Configs = [
    {
      form: taxInfoForm,
      submitHandler: onSubmitTaxInfo,
      props: {
        setAuthorizedSignatory,
        onChangeAuthorizedSignatory,
        authorisedSignatory,
      },
    },
    // {
    //   form: addPartners,
    //   submitHandler: onSubmitAddPartner,
    //   props: {},
    // }, for fututre use case
    {
      form: pricingAndContract,
      submitHandler: onSubmitPricingAndContract,
      props: { setContractDetails, contractDetails },
    },
  ]

  useEffect(() => {
    setRegDocs(singleInstituteData?.regDocs)
    addInstituteForm.setFieldsValue({
      institute: singleInstituteData?.name,
      university: singleInstituteData?.university,
      groupName: singleInstituteData?.instituteCampus?.[0]?.groupName,
      establish: singleInstituteData?.establish,
      website: singleInstituteData?.instituteCampus?.[0]?.website,
      country: singleInstituteData?.instituteCampus?.[0]?.country,
      state: singleInstituteData?.instituteCampus?.[0]?.state,
      city: singleInstituteData?.instituteCampus?.[0]?.city,
      postalCode: singleInstituteData?.instituteCampus?.[0]?.postalCode,
      profileUrl:
        singleInstituteData?.instituteCampus?.[0]?.campusLogoUrl ?? '',
      addressLine1:
        singleInstituteData?.instituteCampus?.[0]?.addressLine1 ?? '',
      addressLine2:
        singleInstituteData?.instituteCampus?.[0]?.addressLine2 ?? '',
      shortDescription:
        singleInstituteData?.instituteCampus?.[0]?.shortDescription ?? '',
      longDescription: singleInstituteData?.instituteCampus?.[0]
        ?.longDescription
        ? singleInstituteData?.instituteCampus?.[0]?.longDescription
        : null,
      institutePresentationLink:
        singleInstituteData?.instituteCampus?.[0]?.institutePresentationLink ??
        '',
      instituteVideoLink:
        singleInstituteData?.instituteCampus?.[0]?.instituteVideoLink ?? '',
    })
    setProfileFile(singleInstituteData?.instituteCampus?.[0]?.campusLogoUrl)
    if (singleInstituteData?.instituteCampus?.[0]?.institutePresentationLink) {
      setInstitutePresentation({
        url:
          singleInstituteData?.instituteCampus?.[0]
            ?.institutePresentationLink ?? '',
      })
    }
    if (
      singleInstituteData?.instituteCampus?.[0]?.longDescription != '' ||
      singleInstituteData?.instituteCampus?.[0]?.longDescription != undefined
    ) {
      setLongDescription(
        singleInstituteData?.instituteCampus?.[0]?.longDescription
      )
    } else {
      setLongDescription(null)
    }

    contactDetailsForm.setFieldsValue({
      contactPerson:
        singleInstituteData?.instituteCampus?.[0]?.contactPerson ?? '',
      contactEmail:
        singleInstituteData?.instituteCampus?.[0]?.contactEmail ?? '',
    })

    taxInfoForm.setFieldsValue({
      pan: singleInstituteData?.instituteCampus?.[0]?.pan ?? '',
      gst: singleInstituteData?.instituteCampus?.[0]?.gst ?? '',
      authorisedSignatory:
        singleInstituteData?.instituteCampus?.[0]?.authorisedSignatory ?? null,
    })
    setAuthorizedSignatory(
      singleInstituteData?.instituteCampus?.[0]?.authorisedSignatory ?? null
    )
    pricingAndContract.setFieldValue('regDocs', singleInstituteData?.regDocs)
  }, [singleInstituteData])

  const callBackError = msg => {
    console.log('datat')
    ErrorMessage(msg || 'No Institute Found')
    navigate('/onboarding')
  }

  useEffect(() => {
    dispatch(getSingleInstituteData(instituteId, callBackError))
  }, [instCurrent, instSubstep])

  useEffect(() => {
    setContractDetails(singleInstituteData?.regDocs ?? null)
    pricingAndContract.setFieldsValue(
      'regDocs',
      singleInstituteData?.regDocs ?? null
    )
  }, [singleInstituteData])
  return (
    <Container>
      <Control flexStart width="33%" gap="15px" column>
        <SubContainer column>
          <AntdSteps
            steps={menuData}
            current={instCurrent}
            substep={instSubstep}
          />
        </SubContainer>
      </Control>
      {/* <Control flexStart column> */}
      {menuData?.map((item, index) => {
        return (
          item?.activeKey === instCurrent && (
            <Wrapper column customWidth={'67%'} mBottom={'0px'}>
              <Heading>{item?.heading}</Heading>
              <SubHeading>{item?.subHeading}</SubHeading>
              {item?.title === 'Add Institute' ? (
                <Content>
                  {instSubstep === 0 && (
                    <InstituteInfo
                      instCurrent={instCurrent}
                      setInstCurrent={setInstCurrent}
                      setInstSubstep={setInstSubstep}
                      singleInstituteData={singleInstituteData}
                      form={addInstituteForm}
                      onSubmit={onSubmitAddInstitute}
                      setProfileFile={setProfileFile}
                      profileFile={profileFile}
                      institutePresentation={institutePresentation}
                      setInstitutePresentation={setInstitutePresentation}
                      longDescription={longDescription}
                      setLongDescription={setLongDescription}
                    />
                  )}
                </Content>
              ) : (
                <Content>
                  <item.component
                    instCurrent={instCurrent}
                    setInstCurrent={setInstCurrent}
                    setInstSubstep={setInstSubstep}
                    singleInstituteData={singleInstituteData}
                    authorisedSignatory={authorisedSignatory}
                    setAuthorizedSignatory={setAuthorizedSignatory}
                    regDocs={regDocs}
                    setRegDocs={setRegDocs}
                    form={Configs[index - 1]['form']}
                    onSubmit={Configs[index - 1]['submitHandler']}
                    {...Configs[index - 1]['props']}
                  />
                </Content>
              )}
            </Wrapper>
          )
        )
      })}
      {/* </Control> */}
    </Container>
  )
}

export default Institute
