const getTemplateListData = state =>
  state?.RankingAlgorithmReducer?.templateList
const getAcademicTierData = state =>
  state?.RankingAlgorithmReducer?.academicData
const getSkillBucketData = state =>
  state?.RankingAlgorithmReducer?.skillBucketData
const selectors = {
  getTemplateListData,
  getAcademicTierData,
  getSkillBucketData,
}

export default selectors
