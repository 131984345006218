import { connect } from 'react-redux'
import StudentsCourseWiseTable from 'modules/Reports/Partials/Students/Partials/StudentCourseWise/index'
import {
  getStudentListForReport,
  getCollegeList,
  getCourseList,
  getDegreeList,
  getSpecialisationList,
  getStudentSkillWiseDetails,
  getStudentCourseWiseDetails,
  getSkillList,
  TierUrl,
  UniversityUrl,
  CollegeUrl,
  DegreeUrl,
  SpecialisationUrl,
  CityUrl,
} from 'modules/Reports/Partials/Students/actions'
import {
  getUniversityList,
  getCityList,
  getTierList,
} from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/actions'
import instituteSelectors from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/selectors'
import selectors from 'modules/Reports/Partials/Students/selectors'
import {getUserInfo } from '../../../../Auth/actions'
import selector from '../../../../Auth/selectors'


const mapStateToProps = state => ({
  studentListDataForReport: selectors?.studentCourseDetails(state),
  collegeList: selectors?.CollegeListData(state),
  courseList: selectors?.courseListData(state),
  degreeList: selectors?.degreeListData(state),
  specialisationList: selectors?.specialisationListData(state),
  tierList: instituteSelectors?.tierListData(state),
  universityList: instituteSelectors?.universityListData(state),
  cityList: instituteSelectors?.cityListData(state),
  skillList: selectors?.skillList(state),
  excelDataForReport: selectors?.excelData(state),
  tierData: selectors?.tierData(state),
  universityData: selectors?.universityData(state),
  collegeData: selectors?.collegeData(state),
  degreeData: selectors?.degreeData(state),
  specialisationData: selectors?.specialisationData(state),
  cityData: selectors?.cityData(state),
  userData: selector?.getUserData(state),

})

const mapDispatchToProps = {
  getStudentListForReport,
  getCollegeList,
  getCourseList,
  getSpecialisationList,
  getStudentSkillWiseDetails,
  getStudentCourseWiseDetails,
  getTierList,
  getUniversityList,
  getCityList,
  getDegreeList,
  getSkillList,
  TierUrl,
  UniversityUrl,
  CollegeUrl,
  DegreeUrl,
  SpecialisationUrl,
  CityUrl,
  getUserInfo
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentsCourseWiseTable)
