import Button from 'components/Button/index'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Dashboard/Partials/UsersFilter/Styles/style'
import React, { useState } from 'react'
import LocationFilter from './Partials/LocationFilter'

const InstituteFilter = ({
  instituteList,
  currentPage,
  searchValue,
  location,
  industry,
  setLocation,
  setIndustry,
  setSearchValue,
  userRoleValue,
  instituteStatus,
  institutejourneyData,
  getInstituteData,
  setCurrentPage,
  setInstituteStatus,
  getUserData,
  getListOfCity,
  pageNum,
  setPageNum,
  setState,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [locationFilter, setLocationFilter] = useState([])
  const [search, setSearch] = useState('')
  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      location: locationFilter,
      // setIndustry: setIndustry,
    })
    // getInstituteData()
    setLocation(locationFilter)
    // setState(industry ? industry : 'all')
  }

  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setLocation(filterData?.location)
    setLocationFilter(filterData?.location)
  }

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'Location',
            key: '1',
            children: (
              <LocationFilter
                location={locationFilter}
                setLocation={setLocationFilter}
                setSearch={setSearch}
                search={search}
              />
            ),
          },
          // {
          //   label: 'Industry',
          //   key: '2',
          //   children: (
          //     <IndustryFilter industry={industry} setIndustry={setIndustry} />
          //   ),
          // },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )
  return (
    <TableSearchFilter
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      currentCount={10 * pageNum + institutejourneyData?.list?.length}
      totalCount={institutejourneyData?.count}
      onOpenChange={onCancelFilter}
      setPageNum={setPageNum}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default InstituteFilter
