import { Col, Divider, Form, Row } from 'antd'
import RoutePath from 'components/RoutePath/index'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import MasterItem from 'components/UIComponents/SideMenuMaster'
import LeftArrow from 'components/icons/LeftArrow'
import Course from 'modules/Courses/Container/index.js'
import { arrayStatus } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import {
  LeftSection,
  PaddedDiv,
  RoleNameDiv,
  RoleNameFlex,
  SectionName,
  TopHeader,
} from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import { SystemPaddedDiv } from 'modules/Systemconfig/Styles/style'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SystemconfigTable from '../../../../components/UIComponents/SystemConfigTable/index'
import { journeyDropdown } from '../../../../utils/constants'
import EventDrawer from './Partials/EventDrawer'
import ScreenDrawer from './Partials/ScreenDrawer'
import SubEventDrawer from './Partials/SubEventDrawer'
import {
  AddDegreeContent,
  AddUniversityContent,
  CollegeData,
  DegreeData,
  InstituteMenuData,
  UniversityData,
} from './Partials/TableColumnData'
import { toCamelCase } from '../../../../utils/camelCase'
const { COUNTRY_ID } = process.env

const PermissionSettings = ({
  getInstituteListForSysConfig,
  instituteTableList,
  getListOfState,
  roles,
  getRoles,
  createInstituteSysConfig,
  getListOfCity,
  getScreenData,
  getEventData,
  screenData,
  eventData,
}) => {
  const [form] = Form.useForm()
  const [id, setId] = React.useState('1')
  const [pageNum, setPageNum] = useState(1)
  const [url, setUrl] = useState()
  const [inputs, setInputs] = useState([
    {
      value: '',
      name: '',
    },
  ])
  const [tableData, setTableData] = React.useState({
    id: 1,
    text: 'Screens',
  })

  const journeyList = [
    {
      label: 'Internal',
      value: 'INTERNAL',
    },
    {
      label: 'Corporate',
      value: 'CORPORATE',
    },
    {
      label: 'Institute',
      value: 'INSTITUTE',
    },
    {
      label: 'Student',
      value: 'STUDENT',
    },
  ]

  const [stateId] = useState('')
  const [journeyId, setJourneyId] = useState('')
  const [screenIdValue, setScreentIdValue] = useState('')
  const [eventIdValue, setEventIdValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [menu, setMenu] = useState('Screens')
  const [toEditId, setEditId] = useState('')
  const [dataToAdd, setDataToAdd] = useState(UniversityData)
  const [content, setContent] = useState(AddUniversityContent)
  const [itemsInFilter, setItemsInFilter] = useState(FilterItems)
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [stateName, setStateName] = useState([])
  const [journey, setJourney] = useState([])
  const [journeyName, setJourneyName] = useState([])
  const [statusValue, setStatusValues] = useState([])
  const [typeValue, setTypeValue] = useState([])
  const [sortby, setSortBy] = useState({
    orderBy: '',
    sortName: '',
  })
  const [filterDatas, setFilterData] = useState({})
  const [universityValue, setUniversityValue] = useState({})
  const [success, isSuccess] = useState(false)
  const [view, setView] = useState(false)
  const [addContent, setAddContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [show, setShow] = useState(false)
  const [eventShow, setEventShow] = useState(false)
  const [isUniversity, setUniversity] = useState(false)
  const [locationList, setLocationList] = useState([])
  const [locationId, setLocationId] = useState('')
  const [locationName, setLocationName] = useState('')

  const currentUrl = window.location.href
  const parts = currentUrl.split('/')
  const cardName = parts[parts.length - 2]
  const cardId = parts[parts.length - 1]
  const [clearInput, setClearInput] = useState(false)
  const [check, setCheck] = useState(false)

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await getListOfState(countryId, search)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setStateList(updatedArray || [])
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await getListOfCity(countryId, stateId, search, 0, pageLimit)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      setLocationList(updatedArray)
      return setCityList(updatedArray || [])
    }
  }
  const showDrawer = e => {
    if (Object.prototype.toString.call(e) === '[object Object]') {
      setDrawer(true)
      setAddContent(true)
    } else {
      setDrawer(true)
      setAddContent(false)
    }
    setDrawer(true)
  }
  const onClose = () => {
    setDrawer(false)
    form.resetFields()
    setEditId('')
    setView(false)
    setUniversityValue({})
    setInputs([{}])
    setShow(false)
    setEventShow(false)
  }

  useEffect(() => {
    // Combine all conditions that lead to setting filter items
    if (
      [
        'Department',
        'Specialisation',
        'Sub-Events',
        'Screens',
        'Events',
      ].includes(menu) ||
      stateList ||
      journeyId ||
      screenIdValue ||
      eventIdValue ||
      locationList
    ) {
      setItemsInFilter(FilterItems)
    }
  }, [
    statusValue,
    typeValue,
    stateList,
    stateName,
    journeyId,
    screenIdValue,
    eventIdValue,
    journey,
    journeyDropdown,
    menu,
    locationList,
    locationName,
  ])

  useEffect(() => {
    getScreenData(journey)
  }, [journey])

  useEffect(() => {
    setJourneyId('')
    setEventIdValue('')
    setScreentIdValue('')
    getRoles('Internal')
    getCityData(COUNTRY_ID, stateName, true)
  }, [stateId])
  useEffect(() => {
    if (cardName) {
      let data = {
        id: cardId,
        text: decodeURIComponent(cardName),
      }

      onTableClick(data)
    }
  }, [cardName])
  const onTableClick = data => {
    setTableData(data)
    setId(data.id)
    setFilterData({})
    setSearchValue('')

    setEditId('')
    setStatusValue([])
    setTypeValue([])
    setCityList([])
    setJourneyName('')
    setStateName([])
    setJourneyId([])
    setEventIdValue([])
    setScreentIdValue([])
    setSearchValue('')
    setLocationId([])
    setLocationName('')
    setPageNum(1)
    if (data.text == 'Screens') {
      setUniversity(true)
    } else {
      setUniversity(false)
    }
    setClearInput(!clearInput)

    setMenu(data.text)
    switch (data.text) {
      case 'Screens':
        setDataToAdd(UniversityData)
        setUniversity(true)
        setItemsInFilter(FilterItems)
        setUrl('screens')
        setSearchValue('')
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        break
      case 'Events':
        setDataToAdd(CollegeData)
        setContent(AddDegreeContent)
        setUrl('events')
        setSearchValue('')
        setItemsInFilter(FilterItems)
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        break
      case 'Sub-Events':
        setDataToAdd(DegreeData)
        setContent(AddDegreeContent)
        setUrl('subEvents')
        setSearchValue('')
        setSortBy({
          orderBy: '',
          sortName: '',
        })
        setSearchValue('')
        break
      default:
        break
    }
    window.history.replaceState(
      null,
      '',
      `/systemConfig/permissionSettings/${data.text}/${data.id}`
    )
  }
  useEffect(() => {
    setUniversity(true)
    getStateData(COUNTRY_ID, true)
    setItemsInFilter(FilterItems)
  }, [])
  useEffect(() => {
    let sortBy = sortby
    if (sortby?.sortName == '') {
      sortBy = {
        orderBy: '',
        sortName: '',
      }
    }
    if (url) {
      getInstituteListForSysConfig({
        url,
        type: 'get',
        pageSize: 10,
        pageNumber: pageNum == 0 ? 0 : pageNum - 1,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
    }
  }, [url, pageNum, submit, success, sortby])
  useEffect(() => {
    let sortBy = sortby
    if (sortby?.sortName == '') {
      sortBy = {
        orderBy: '',
        sortName: '',
      }
    }
    if (url) {
      getInstituteListForSysConfig({
        url,
        type: 'get',
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
    }
    setPageNum(1)
  }, [searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [tableData])
  const setStatusValue = value => {
    setStatusValues(value)
  }
  const FilterItems = [
    {
      label: 'Journey',
      key: '1',
      visible: true,
      children: (
        <FilterCheckBoxComp
          options={journeyList}
          value={journeyId}
          onChange={values => setJourneyId(values)}
        />
      ),
    },
  ]
  const submitSystemConfigFilter = value => {
    let status = arrayStatus(statusValue)
    let type = typeValue?.[1]
    let state = stateName
    let journey = journeyId
    let screenId = screenIdValue
    let eventId = eventIdValue
    let location = locationName

    if (value == 'submit') {
      setFilterData({
        status: status,
        isActive: status,
        type: type,
        state: state,
        journey: journey,
        screenId: screenId,
        eventId: eventId,
        location: location,
        menu: menu,
      })
      setSubmit(!submit)
      setPageNum(1)
    }
    if (value == 'reset') {
      setSubmit(!submit)
      setItemsInFilter(FilterItems)
      onTableClick(tableData)
      setFilterData({})
    }
  }
  const handleSubmitCongif = (data, call) => {
    if (toEditId) {
      let value = data
      createInstituteSysConfig({
        url,
        value,
        type: 'put',
        id: toEditId,
        isSuccess,
        success,
      })
    } else if (menu == 'Screens') {
      const screensData = inputs.map(input => ({
        name: input.value,
      }))
      const payload = {
        journey: data.journey,
        screens: screensData,
      }
      createInstituteSysConfig({
        url,
        value: payload,
        type: 'post',
        isSuccess,
        success,
      })
    } else if (menu == 'Events') {
      const eventsData = inputs.map(input => ({
        name: input.value,
      }))
      const payload = {
        screenId: data.screenId,
        events: eventsData,
      }
      createInstituteSysConfig({
        url,
        value: payload,
        type: 'post',
        isSuccess,
        success,
      })
    } else if (menu == 'Sub-Events') {
      const subEventsData = inputs.map(input => ({
        name: input.value,
      }))
      const payload = {
        eventId: data.eventId,
        subEvents: subEventsData,
      }
      createInstituteSysConfig({
        url,
        value: payload,
        type: 'post',
        isSuccess,
        success,
      })
    }
  }

  const handleJourneyChange = ({ value }) => {
    // setJourney({ value })
  }

  const onClickEdit = value => {
    form.resetFields()
    form.setFieldsValue({
      journey:
        value?.journey ||
        value?.screens?.journey ||
        value?.events?.screens?.journey,
      screenName:toCamelCase( value?.screenName),
      screenId: value?.screens?.id || value?.events?.screens?.id,
      eventName: toCamelCase(value?.eventName || value?.events?.eventName),
      eventId: value?.events?.id,
      subEventName: toCamelCase(value?.subEventName),
      events: value?.events,
    })
    setEditId(value?.id)
    setUniversityValue(value)
    getScreenData(value?.journey)
    getEventData(value?.screens?.id || value?.events?.screens?.id)
  }
  useEffect(() => {
    form.resetFields()
    onClose()
    setEditId('')
    setView(false)
  }, [success])

  const confirmDelete = data => {
    createInstituteSysConfig({
      url,
      type: 'delete',
      id: data?.id,
      isSuccess,
      success,
    })
  }
  const toView = data => {
    onClickEdit(data)
    setView(true)
  }
  function handleKeyPress(e) {
    const charCode = e.which || e.keyCode
    const charStr = String.fromCharCode(charCode)
    if (charStr === charStr.toUpperCase()) {
      e.preventDefault()
    }
  }

  const handleFilterCall = () => {
    setItemsInFilter(FilterItems)
    setCheck(!check)
    setJourneyId(filterDatas?.journey || '')
  }
  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/systemconfig">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="System Config"
                on={'Permission Settings'}
                path="/systemconfig"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>Permission Settings</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>

      <Row>
        <Col span={5}>
          <SystemPaddedDiv>
            <MasterItem
              onTableClick={onTableClick}
              id={id}
              MasterTableData={InstituteMenuData}
            />
          </SystemPaddedDiv>
        </Col>
        <Col span={0.5}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={18}>
          {menu == 'Degree, Department & Specialisation Mapping' ? (
            <Course systemConfig={true} sendButtonText={'Save'} />
          ) : (
            <SystemconfigTable
              form={form}
              loading={loading}
              tableData={tableData}
              dataToAdd={dataToAdd}
              data={
                menu === 'Screens'
                  ? instituteTableList?.screens
                  : instituteTableList?.events ?? instituteTableList?.subEvents
              }
              showDrawer={showDrawer}
              content={content}
              statusValue={statusValue}
              setStatusValue={setStatusValue}
              typeValue={typeValue}
              setTypeValue={setTypeValue}
              items={itemsInFilter}
              setPageNum={setPageNum}
              pageNum={pageNum}
              list={instituteTableList}
              setSearchValue={setSearchValue}
              submitSystemConfigFilter={submitSystemConfigFilter}
              onEdit={onClickEdit}
              confirmDelete={confirmDelete}
              setSortBy={setSortBy}
              toView={toView}
              menu={menu}
              clearInput={clearInput}
              searchValue={searchValue}
              locationId={locationId}
              setLocationId={setLocationId}
              handleFilterCall={handleFilterCall}
            />
          )}
        </Col>
      </Row>
      {menu == 'Screens' ? (
        <ScreenDrawer
          drawer={drawer}
          setDrawer={setDrawer}
          onClose={onClose}
          inputs={inputs}
          setInputs={setInputs}
          formId="form"
          sendButtonText="Save"
          handleJourneyChange={handleJourneyChange}
          isSystemConfig={true}
          form={form}
          handleSubmitCongif={handleSubmitCongif}
          toEditId={toEditId}
          setEditId={setEditId}
          view={view}
          setView={setView}
          universityValue={universityValue}
          addContent={addContent}
          setAddContent={setAddContent}
          onKeyPress={handleKeyPress}
        />
      ) : menu == 'Events' ? (
        <EventDrawer
          drawer={drawer}
          setDrawer={setDrawer}
          onClose={onClose}
          journey={journey}
          inputs={inputs}
          setShow={setShow}
          show={show}
          setJourney={setJourney}
          setInputs={setInputs}
          roleList={roles}
          instituteTableList={instituteTableList}
          formId="form"
          sendButtonText="Save"
          isSystemConfig={true}
          getScreenData={getScreenData}
          screenData={screenData}
          form={form}
          handleJourneyChange={handleJourneyChange}
          handleSubmitCongif={handleSubmitCongif}
          toEditId={toEditId}
          setEditId={setEditId}
          view={view}
          setView={setView}
          universityValue={universityValue}
          addContent={addContent}
          setAddContent={setAddContent}
          menu={menu}
        />
      ) : (
        <SubEventDrawer
          drawer={drawer}
          setDrawer={setDrawer}
          onClose={onClose}
          inputs={inputs}
          setShow={setShow}
          show={show}
          setEventShow={setEventShow}
          eventShow={eventShow}
          setInputs={setInputs}
          setJourney={setJourney}
          instituteTableList={instituteTableList}
          tableData={tableData}
          roleList={roles}
          getEventData={getEventData}
          eventData={eventData}
          getScreenData={getScreenData}
          screenData={screenData}
          content={content}
          formId="form"
          sendButtonText="Save"
          isSystemConfig={true}
          form={form}
          handleJourneyChange={handleJourneyChange}
          handleSubmitCongif={handleSubmitCongif}
          toEditId={toEditId}
          setEditId={setEditId}
          view={view}
          setView={setView}
          menu={menu}
          universityValue={universityValue}
          addContent={addContent}
        />
      )}
    </>
  )
}
export default PermissionSettings
