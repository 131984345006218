import { SET_USER_INFO, SIGN_IN_SUCCESS } from 'modules/Auth/actions'
import {
  SET_COUNTRIES_LIST,
  SET_ROLE,
  SET_USER_ID,
  SET_USER_PERMISSIONS,
} from './actions'

const initialState = {
  token: null,
  userId: '',
  useData: '',
  listOfCountries: [],
  permissions: [],
  roleList: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.token,
      }
    case SET_USER_ID:
      return {
        ...state,
        userId: action.id,
      }
    case SET_COUNTRIES_LIST:
      return {
        ...state,
        listOfCountries: action.data,
      }
    case SET_USER_INFO:
      return {
        ...state,
        userData: action.data,
      }
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.data,
      }
    case SET_ROLE:
      return {
        ...state,
        roleList: action.roleData,
      }
    default:
      return state
  }
}
