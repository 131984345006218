import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import Flex from 'components/Flex'
import SearchInput from 'components/SearchInput'
import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import {
  Container,
  Contents,
  FilterHeader,
  FilterText,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import InfiniteScroll from 'react-infinite-scroll-component'
const UniversityFilter = ({
  sectorValue,
  setSectorValue,
  setKeySector,
  keySector,
  industryList,
  loading,
  isReport,
  stateCount = 0,
  loadMoreStateData,
  statePage = 0,
  flag,
  setFlag,
}) => {
  const [state, setState] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    let finalData = []
    if (statePage < 1) {
      finalData = industryList
    } else if (industryList) {
      finalData = [...state, ...industryList]
    }
    setState(finalData)
  }, [industryList])

  const onChange = checkedvalue => {
    let indexToRemove = sectorValue?.indexOf(...checkedvalue)
    let universityCopy = [...sectorValue]
    if (indexToRemove !== -1) {
      universityCopy?.splice(indexToRemove, 1)
      setSectorValue([...universityCopy])
    } else {
      setSectorValue([...sectorValue, ...checkedvalue])
    }
  }

  const Spinner = (
    <>
      {!industryList?.length && loading ? (
        <Spin />
      ) : state >= 0 && !loading ? (
        <RowNumberData>No Data Found</RowNumberData>
      ) : (
        ''
      )}
    </>
  )

  return (
    <Contents>
      <FilterHeader>
        <FilterText>University</FilterText>
        <SearchInput
          background="#F8F8F8"
          width={'100%'}
          placeholder="Search by University"
          bordered={false}
          setSearchValue={setKeySector}
          searchValue={keySector}
          isReport={isReport}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          flag={flag}
          setFlag={setFlag}
        />
      </FilterHeader>
      <Container>
        {state?.length > 0 ? (
          <p>
            <InfiniteScroll
              // key={industryList}
              dataLength={state?.length}
              height="18vh"
              next={loadMoreStateData}
              hasMore={state?.length < stateCount}
              loader={
                <Flex center>
                  <Spin small />
                </Flex>
              }
            >
              <FilterCheckBoxComp
                options={state}
                value={sectorValue}
                onChangeCheck={e => onChange([e?.target?.value])}
              />
            </InfiniteScroll>
          </p>
        ) : (
          <Flex center>{Spinner}</Flex>
        )}
      </Container>
    </Contents>
  )
}

export default UniversityFilter
