import request from 'utils/studentRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'
export const SET_COMPANY_LIST = 'students/SET_COMPANY_LIST'

export const setCompanyList = data => {
  return {
    type: SET_COMPANY_LIST,
    data,
  }
}
export const getCompanyListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status
      const Status =
        status == '1'
          ? `&isActive=${true}`
          : status == '0'
          ? `&isActive=${false}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/students/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}`,
      }
      const response = await request(options)
      dispatch(setCompanyList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setCompanyList([]))
        setLoading(false)
      }
    }
  }

export const createCompanySysConfig =
  ({ url, type = 'post', value, id, isSuccess, success }) =>
  async () => {
    try {
      const Id = id ? `/${id}` : ``
      const response = await request({
        method: type,
        url: `/students/${url}${Id}`,
        data: value,
      })
      SuccessMessage(response?.data?.message)
      isSuccess(!success)
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
    }
  }
