import { PageTitle, SectionWrapper } from 'modules/Systemconfig/Styles/style.js'
import GeneralSettings from 'modules/Systemconfig/Partials/GeneralSettings/index.js'
import { SystemConfigHeadTitle } from 'modules/Systemconfig/Styles/style'
import React from 'react'

const SystemsConfig = Props => {
  return (
    <SectionWrapper column>
      <PageTitle>
        <SystemConfigHeadTitle>System Configuration</SystemConfigHeadTitle>
      </PageTitle>

      <div>
        <GeneralSettings />
      </div>
    </SectionWrapper>
  )
}

export default SystemsConfig
