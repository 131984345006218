import React from 'react'
import {
  ActionMenu,
  ButtonText,
  ItemDeleteText,
  ItemText,
  Main,
  StyledDropDownButton,
} from 'components/ActionStyles/ActionStyles'
import DownArrow from 'components/icons/DownArrow'

const ActionDropdown = ({
  rowData,
  setViewRoleDrawer,
  setView,
  getSingleRoleInfo,
  setFormattedData,
  singleRoleData,
  setAddRoleDrawer,
  setRoleId,
  onDelete,
}) => {
  const onClickView = rowId => {
    //setFormattedData([])
    setAddRoleDrawer(true)
    getSingleRoleInfo(rowId)
    setView(true)
  }

  const onClickEdit = rowData => {
    setAddRoleDrawer(true);
    setRoleId(rowData?.id);
    getSingleRoleInfo(rowData?.id);
    setView(false)
  }

  const menu = (
    <ActionMenu
      width={'165px'}
      items={[
        {
          key: 'edit',
          label: <ItemText onClick={() => onClickEdit(rowData)}>Edit</ItemText>,
        },
        {
          key: 'delete',
          label: (
            <ItemDeleteText onClick={() => onDelete(rowData)}>
              Delete Role
            </ItemDeleteText>
          ),
        },
      ]}
    />
  )

  return (
    <Main id='filterDropdown'>
      <StyledDropDownButton
        overlay={menu}
        shape="round"
        trigger={['click']}
        icon={<DownArrow />}
        getPopupContainer={() => document.getElementById('filterDropdown')}

      >
        <ButtonText onClick={() => onClickView(rowData?.id)}>View</ButtonText>
      </StyledDropDownButton>
    </Main>
  )
}

export default ActionDropdown
