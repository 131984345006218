import React from 'react'

const InactiveUsers = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.38 18.75 17 17.13l1.62 1.62.63-.63-1.62-1.62 1.62-1.62-.63-.63L17 15.87l-1.62-1.62-.63.63 1.62 1.62-1.62 1.62.63.63ZM17 21a4.38 4.38 0 0 1-1.755-.355 4.543 4.543 0 0 1-1.429-.961 4.543 4.543 0 0 1-.961-1.429A4.38 4.38 0 0 1 12.5 16.5c0-.623.118-1.207.355-1.755a4.541 4.541 0 0 1 .961-1.429 4.548 4.548 0 0 1 1.429-.962A4.384 4.384 0 0 1 17 12a4.39 4.39 0 0 1 1.755.354 4.55 4.55 0 0 1 1.429.962c.405.405.725.881.961 1.429A4.38 4.38 0 0 1 21.5 16.5a4.38 4.38 0 0 1-.355 1.755 4.544 4.544 0 0 1-.961 1.429 4.544 4.544 0 0 1-1.429.961A4.38 4.38 0 0 1 17 21Zm0-.9c1.005 0 1.856-.349 2.554-1.046.697-.698 1.046-1.549 1.046-2.554s-.349-1.856-1.046-2.554C18.856 13.25 18.005 12.9 17 12.9s-1.856.349-2.554 1.046c-.697.698-1.046 1.549-1.046 2.554s.349 1.856 1.046 2.554c.698.697 1.549 1.046 2.554 1.046ZM10.5 12c-1.1 0-2.042-.392-2.825-1.175C6.892 10.042 6.5 9.1 6.5 8s.392-2.042 1.175-2.825C8.458 4.392 9.4 4 10.5 4s2.042.392 2.825 1.175C14.108 5.958 14.5 6.9 14.5 8s-.392 2.042-1.175 2.825C12.542 11.608 11.6 12 10.5 12Zm-7 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2.5 19v-1.8c0-.55.142-1.067.425-1.55.283-.483.675-.85 1.175-1.1.85-.433 1.808-.8 2.875-1.1 1.067-.3 2.242-.45 3.525-.45h.35c.1 0 .2.017.3.05-.133.3-.246.613-.338.938A7.82 7.82 0 0 0 10.6 15c-.083.6-.108 1.104-.075 1.512.033.409.125.905.275 1.488.1.35.233.696.4 1.038.167.341.35.662.55.962H3.5Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default InactiveUsers

// mask="url(#mask0_207_372)"
