import { Empty, Spin } from 'antd'
import Pagination from 'components/Pagination'
import {
  RowNumberData,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import ActionDropdown from 'modules/Dashboard/Partials/UsersTable/ActionDropdown'
import UserStatus from 'modules/Dashboard/Partials/UsersTable/UserStatus'
import ViewUserRoleDrawer from 'modules/Dashboard/Partials/ViewUserRoleDrawer'
import { EmptyWrapper } from 'modules/Dashboard/Styles/style'
import React, { useState } from 'react'

const UsersTable = ({
  userList,
  userRole,
  setSortBy,
  loading,
  getSingleUserInfo,
  singleUserData,
  setAddUserDrawer,
  setUserId,
  updateUserStatus,
  deleteUser,
  setPageNum,
  getUserData,
  pageNum,
  updateLoading,
}) => {
  const [viewUserDrawer, setViewUserDrawer] = useState(false)

  const getRoleName = id => {
    let roleName = userRole?.filter(data => data?._id === id)[0]?.name
    return roleName || '-'
  }

  const onSorting = (pagination, filter, sorter) => {
    setSortBy(sorter)
  }

  const onPageChange = num => {
    setPageNum(num)
  }

  const columns = [
    {
      title: 'CORPORATE NAME',
      dataIndex: 'name',
      key: 'first_name',
      sorter: true,
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.first_name} {rowData?.last_name}
        </RowNumberData>
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'role_id',
      key: 'role_id',
      sorter: true,
      render: rowData => <RowNumberData>{getRoleName(rowData)}</RowNumberData>,
    },
    {
      title: 'STATUS',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: rowData => rowData,
    },
    {
      title: 'COMP.RATE',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status, rowData) =>
        rowData?.is_active === true || rowData?.is_active === false ? (
          <UserStatus status={rowData?.is_active} />
        ) : (
          '-'
        ),
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          setViewUserDrawer={setViewUserDrawer}
          getSingleUserInfo={getSingleUserInfo}
          setAddUserDrawer={setAddUserDrawer}
          setUserId={setUserId}
          updateUserStatus={updateUserStatus}
          deleteUser={deleteUser}
          getUserData={getUserData}
        />
      ),
    },
  ]

  return (
    <>
      <Wrapper>
        <StyledTable
          columns={columns}
          pagination={false}
          dataSource={userList?.list}
          onChange={onSorting}
          locale={{
            emptyText: (
              <EmptyWrapper center isSpin={loading}>
                {!loading ? <Empty description={'No user found!'} /> : <Spin />}
              </EmptyWrapper>
            ),
          }}
        />

        <Pagination
          onChange={onPageChange}
          totalPages={userList?.count}
          pageSize={10}
          current={pageNum}
        />
      </Wrapper>

      <ViewUserRoleDrawer
        viewUserDrawer={viewUserDrawer}
        setViewUserDrawer={setViewUserDrawer}
        singleUserData={singleUserData}
        getRoleName={getRoleName}
        updateLoading={updateLoading}
      />
    </>
  )
}

export default UsersTable
