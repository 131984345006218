import { CorporateVerticalContent } from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import React from 'react'
const MasterItem = ({ MasterTableData, onTableClick, id }) => {
  return (
    <div style={{ cursor: 'pointer', width: '100%' }}>
      {MasterTableData.map((item, index) => {
        return (
          <div
            key={item.id}
            onClick={() => onTableClick(item)}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            <CorporateVerticalContent
              backgroundColor={item.id == id ? '#eaedfb' : ''}
              borderRadius={item.id == id ? '10px' : '0px'}
              borderBottom={
                index == MasterTableData?.length - 1 ? '0px' : `1px`
              }
              color={item.id == id ? '#3249d7' : '#999fac'}
              fontSize={'16px'}
              fontWeight={item.id == id ? 'bold' : '400'}
            >
              {item.text}
            </CorporateVerticalContent>
          </div>
        )
      })}
    </div>
  )
}
export default MasterItem
