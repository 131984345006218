import request from 'utils/authRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'
export const SET_INSTITUTE_LIST = 'institute/SET_INSTITUTE_LIST'
export const SET_DEPARTMENT_LIST = 'institute/SET_DEPARTMENT_LIST'
export const SET_SPECIALISATION_LIST = 'institute/SET_SPECIALISATION_LIST'
export const setInstituteList = data => {
  return {
    type: SET_INSTITUTE_LIST,
    data,
  }
}
export const setDepartmentList = data => {
  return {
    type: SET_DEPARTMENT_LIST,
    data,
  }
}
export const setSpecialisationList = data => {
  return {
    type: SET_SPECIALISATION_LIST,
    data,
  }
}
export const getInstituteListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
      dispatch(setInstituteList([]))
    }
    try {
      console.log(url,'location')
      let status = filterDatas?.is_active?.toString()
      const Active = status?.toString()
        ? `&is_active=${Boolean(Number(filterDatas?.is_active))}`
        : ``
      let countryId = filterDatas?.countryId
      let stateId = filterDatas?.stateId
      let cityId = filterDatas?.cityId
      const Country = countryId ? `&countryId=${countryId}` : ''
      const State = stateId ? `&stateId=${stateId}` : ``
      const City = cityId ? `&cityId=${cityId}` : ``

      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString()
        ? `&currentPage=${pageNumber != 0 ? pageNumber : 1}`
        : ``

      const updatedUrl = url.includes('/countries') ? url : `/crud${url}`
    
      const options = {
        method: type,
        url: `${updatedUrl}?${pageLimit}${pageNo}${Search}${Sort}${OrderBy}${Country}${State}${City}${Active}`,
      }
    
      if(url.includes('/locations'))
      {
        const response = await request(options)
        console.log(response?.data?.data ,'response?.data?.data')
        dispatch(setInstituteList(response?.data?.data || []))
      }
      else{
        const response = await elasticSearchRequest(options)
        console.log(response?.data?.data ,'response?.data?.data')
        dispatch(setInstituteList(response?.data?.data || []))
      }
   
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setInstituteList([]))
        setLoading(false)
      }
    }
  }
export const getDepartmentListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      // const status =
      // userStatus?.length > 0 ? `&status=${userStatus ? 1 : 0}` : ''
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await request(options)
      dispatch(setDepartmentList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setDepartmentList([]))
        setLoading(false)
      }
    }
  }
export const getSpecialisationListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status?.toString()
      const Status = status?.toString() ? `&status=${Number(status)}` : ``
      const Active =
        status?.toString() && filterDatas?.menu == 'College'
          ? `&isActive=${Boolean(Number(filterDatas.isActive))}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/institutes/${url}?${pageLimit}${pageNo}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}${Status}${Active}`,
      }
      const response = await request(options)
      dispatch(setSpecialisationList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setSpecialisationList([]))
        setLoading(false)
      }
    }
  }
export const createInstituteSysConfig =
  ({ url, type = 'post', value, id, isSuccess, success, setDrawerLoading }) =>
  async () => {
    if (setDrawerLoading) setDrawerLoading(true)
    try {
      const Id = id ? `/${id}` : ``
      const response = await request({
        method: type,
        url: `${url}${Id}`,
        data: value,
      })
      if(url != '/locations'){
        function formatDate(date) {
          let d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
        
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
        
          return [year, month, day].join('-');
        }

     
          let payload = {
            query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
          };

         await elasticSearchSyncRequest.post(`/sync/countries`,payload)
         await elasticSearchSyncRequest.post(`/sync/states`,payload)
         await elasticSearchSyncRequest.post(`/sync/cities`,payload)
         await elasticSearchSyncRequest.post(`/sync/state_with_country`,payload)
         await elasticSearchSyncRequest.post(`/sync/citiesDetails`,payload)
         await elasticSearchSyncRequest.post(`/sync/locationsDetails`,payload)
         
      }
      SuccessMessage(response?.data?.message)
      isSuccess(!success)
      if (setDrawerLoading) setDrawerLoading(false)
    } catch (error) {
      if (setDrawerLoading) setDrawerLoading(false)
      ErrorMessage(error?.response?.data?.message)
    }
  }
