const instituteSysConfigList = state =>
  state?.InstituteSystemConfig?.getInstituteListForSysConfig
const departmentSysConfigList = state =>
  state?.InstituteSystemConfig?.getDepartmentListForSysConfig
const specialisationSysConfigList = state =>
  state?.InstituteSystemConfig?.getSpecialisationListForSysConfig
const Instituteselectors = {
  instituteSysConfigList,
  departmentSysConfigList,
  specialisationSysConfigList,
}

export default Instituteselectors
