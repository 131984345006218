import React from 'react'

const Visibility = () => {
  return (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 12C12.25 12 13.3127 11.5627 14.188 10.688C15.0627 9.81267 15.5 8.75 15.5 7.5C15.5 6.25 15.0627 5.18733 14.188 4.312C13.3127 3.43733 12.25 3 11 3C9.75 3 8.68733 3.43733 7.812 4.312C6.93733 5.18733 6.5 6.25 6.5 7.5C6.5 8.75 6.93733 9.81267 7.812 10.688C8.68733 11.5627 9.75 12 11 12ZM11 10.2C10.25 10.2 9.61267 9.93733 9.088 9.412C8.56267 8.88733 8.3 8.25 8.3 7.5C8.3 6.75 8.56267 6.11233 9.088 5.587C9.61267 5.06233 10.25 4.8 11 4.8C11.75 4.8 12.3877 5.06233 12.913 5.587C13.4377 6.11233 13.7 6.75 13.7 7.5C13.7 8.25 13.4377 8.88733 12.913 9.412C12.3877 9.93733 11.75 10.2 11 10.2ZM11 15C8.56667 15 6.35 14.3207 4.35 12.962C2.35 11.604 0.9 9.78333 0 7.5C0.9 5.21667 2.35 3.39567 4.35 2.037C6.35 0.679 8.56667 0 11 0C13.4333 0 15.65 0.679 17.65 2.037C19.65 3.39567 21.1 5.21667 22 7.5C21.1 9.78333 19.65 11.604 17.65 12.962C15.65 14.3207 13.4333 15 11 15ZM11 13C12.8833 13 14.6127 12.504 16.188 11.512C17.7627 10.5207 18.9667 9.18333 19.8 7.5C18.9667 5.81667 17.7627 4.479 16.188 3.487C14.6127 2.49567 12.8833 2 11 2C9.11667 2 7.38733 2.49567 5.812 3.487C4.23733 4.479 3.03333 5.81667 2.2 7.5C3.03333 9.18333 4.23733 10.5207 5.812 11.512C7.38733 12.504 9.11667 13 11 13Z" fill="#CCCFD6"/>
    </svg>
    
  )
}

export default Visibility
