import React from 'react'

const TotalUsers = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 19v-1.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 0 1 2.6 14.55a14.866 14.866 0 0 1 3.15-1.163A13.776 13.776 0 0 1 9 13c1.1 0 2.183.129 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563V19c0 .283-.096.52-.288.712A.965.965 0 0 1 16 20H2Zm16.525 0a1.2 1.2 0 0 0 .35-.438 1.35 1.35 0 0 0 .125-.587V17c0-.733-.204-1.438-.612-2.113-.409-.675-.988-1.254-1.738-1.737.85.1 1.65.27 2.4.512s1.45.538 2.1.888c.6.333 1.058.704 1.375 1.112.317.409.475.855.475 1.338v2c0 .283-.096.52-.288.712A.965.965 0 0 1 22 20h-3.475ZM9 12c-1.1 0-2.042-.392-2.825-1.175C5.392 10.042 5 9.1 5 8s.392-2.042 1.175-2.825C6.958 4.392 7.9 4 9 4s2.042.392 2.825 1.175C12.608 5.958 13 6.9 13 8s-.392 2.042-1.175 2.825C11.042 11.608 10.1 12 9 12Zm10-4c0 1.1-.392 2.042-1.175 2.825C17.042 11.608 16.1 12 15 12c-.183 0-.417-.02-.7-.062a6.181 6.181 0 0 1-.7-.138 5.937 5.937 0 0 0 1.037-1.775C14.879 9.375 15 8.7 15 8s-.121-1.375-.363-2.025A5.937 5.937 0 0 0 13.6 4.2c.233-.083.467-.138.7-.163.233-.025.467-.037.7-.037 1.1 0 2.042.392 2.825 1.175C18.608 5.958 19 6.9 19 8Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default TotalUsers

//mask="url(#mask0_207_357)"
