import styled, { css } from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import { Divider } from 'antd'
import { FONT } from 'theme/font'
export const TableTop = styled(Flex)`
  padding: 5px 20px 5px 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const FilterFlex = styled(Flex)`
  width: 50%;
`
export const MarginLeft = styled.div`
  margin-left: 35px;
`

export const FilterFlexInput = styled(Flex)`
  width: 20%;
`
export const FilterFlexButton = styled(Flex)`
  width: 17%;
  margin-bottom: -30px;
  opacity: 1;
  animation: emerge 1s ease-in-out forwards;
  animation-delay: 15s;
`
export const FilterText = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.BLACK};
`
export const FilterTextLabel = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_60};
`

export const Content = styled.div`
  background-color: #ffffff;
  transition: max-height 0.3s ease-in-out;
  max-height: ${props => (props.isOpen ? '500px' : '0')};
  overflow: auto;

  & {
    scrollbar-width: thin; /* or 'auto' for automatic sizing */
    scrollbar-color: rgba(0, 0, 0, 0.4) transparent;
  }
  /* Reduce the size of the scrollbar */
  &::-webkit-scrollbar {
    width: 3px;
    height: 0.7rem;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #0000001f;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #0000001f;
  }

  /* Button (up and down arrows) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Corner */
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ${props => props.isOpen && css``}
`

export const Footer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  /* justify-content: 'flex-right'; */
  float: right;
`

export const FilterHeader = styled.div`
  padding: 1px;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  width: 300px;
  height: 200px;
  overflow: auto;
  line-height: 2;

  /* Hide the default scrollbar */
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #0000001f;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #0000001f;
  }

  /* Button (up and down arrows) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Corner */
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`

export const Contents = styled.div`
  padding: 10px;
`
export const FilterTextWithUnderLine = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.BLACK};
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`

export const FilterHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const Dividers = styled(Divider)`
  /* Custom styles for the divider */
  margin: 0;
`
export const FilterTextNew = styled.p`
  margin-bottom: 10px;
  margin-left: 10px;
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.BLACK};
  margin-top: 10px;
`
