import React from 'react'
import { Container, From, On } from 'components/RoutePath/style'
import { Link } from 'react-router-dom'

const RoutePath = ({ path, from, on, fromArr = [] }) => {
  return (
    <Container centerVertically>
      {fromArr?.length > 0 ? (
        fromArr?.map((item, id) => (
          <Container key={item?.id}>
            <Link to={item?.path}>
              <From>{item?.from}</From>
            </Link>
            <On>/</On>
          </Container>
        ))
      ) : (
        <>
          <Link to={path}>
            <From>{from}</From>
          </Link>
          <On>/</On>
        </>
      )}
      <On>{on}</On>
    </Container>
  )
}

export default RoutePath
