import {
  getIndustryList,
  getListOfCity,
  getListOfCounties,
  getListOfState,
} from 'modules/Onboarding/actions'
import selectors from 'modules/Onboarding/selectors'
import {
  createInstituteSysConfig,
  getEventData,
  getInstituteListForSysConfig,
  getScreenData,
} from 'modules/Systemconfig/Partials/PermissionSettings/action'
import PermissionSettings from 'modules/Systemconfig/Partials/PermissionSettings/index'
import Instituteselectors from 'modules/Systemconfig/Partials/PermissionSettings/selectors'
import { getRoleList, getRoles } from 'modules/User/actions'
import selector from 'modules/User/selectors'
import { connect } from 'react-redux'
const mapStateToProps = state => ({
  countryList: selectors.getCountryList(state),
  roleList: selector?.getRoleListData(state),
  roles: selector?.getRoleData(state),
  instituteTableList: Instituteselectors.instituteSysConfigList(state),
  screenData: Instituteselectors?.getscreensData(state),
  eventData: Instituteselectors?.geteventsData(state),
})

const mapDispatchToProps = {
  getListOfCounties,
  getListOfState,
  getRoleList,
  getRoles,
  getListOfCity,
  getIndustryList,
  getInstituteListForSysConfig,
  createInstituteSysConfig,
  getScreenData,
  getEventData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionSettings)
