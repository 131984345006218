import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Users/Partials/UsersFilter/Styles/style'

const StatusFilter = ({ instituteStatus, setInstituteStatus }) => {
  const options1 = [
    {
      label: 'Veri.Pending',
      value: '0',
    },
    {
      label: 'Onboarding',
      value: '1',
    },
    {
      label: 'Request To Change',
      value: '2',
    },
  ]

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={instituteStatus}
        options={options1}
        onChange={value => setInstituteStatus(value)}
        padding={'10px !important'}
      />
    </Wrapper>
  )
}

export default StatusFilter
