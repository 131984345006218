import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'

const ExamTypeFilter = ({ setStatusList, value }) => {
  const options = [
    {
      label: 'Semester',
      value: 'SEMESTER',
    },
    {
      label: 'Trimester',
      value: 'TRISEMESTER',
    },
    {
      label: 'Annual',
      value: 'ANNUAL',
    },
  ]

  const onChange = checkedValues => {
    setStatusList(checkedValues) // Update status list with all checked values
  }
  return (
    <FilterCheckBoxComp options={options} value={value} onChange={onChange} />
  )
}

export default ExamTypeFilter
