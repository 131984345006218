import { Modal } from 'antd'
import {
  ActionMenu,
  ButtonText,
  ItemDeleteText,
  ItemText,
  Main,
  StyledDropDownButton,
} from 'components/ActionStyles/ActionStyles'
import DownArrow from 'components/icons/DownArrow'
import React from 'react'

const { confirm } = Modal

const ActionDropdown = ({
  rowData,
  onUpdateStatus,
  showDrawer,
  menu,
  onEdit,
  confirmDelete,
  toView,
}) => {
  const onClickView = rowId => {
    showDrawer(true)
    toView(rowData)
  }

  const onClickEdit = rowData => {
    showDrawer(true)
    onEdit(rowData)
  }

  const onDelete = async rowData => {
    confirm({
      title: 'Are you sure? Do you want to delete?',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk: async () => {
        confirmDelete(rowData)
      },
    })
  }

  let items = [
    {
      key: 'edit',
      label: <ItemText onClick={() => onClickEdit(rowData)}>Edit</ItemText>,
    },
  ]

  if (menu === 'Screens' || menu === 'Events' || menu === 'Sub-Events') {
    items.push({
      key: 'delete',
      label: (
        <ItemDeleteText onClick={() => onDelete(rowData)}>
          Delete
        </ItemDeleteText>
      ),
    })
  } else {
    items.push({
      key: rowData?.status
        ? 'inactive'
        : rowData?.isActive
        ? 'active'
        : 'inactive',
      label: (
        <ItemText
          onClick={() =>
            onUpdateStatus(
              rowData?.status
                ? false
                : rowData?.is_active
                ? false
                : !rowData?.isActive,
              rowData
            )
          }
        >
          {menu === 'College' ||
          menu === 'Sector' ||
          menu === 'Roles' ||
          menu === 'Functions' ||
          menu === 'Industry' ||
          menu === 'Company'
            ? rowData?.isActive
              ? 'Set to Inactive'
              : 'Set to Active'
            : rowData?.status
            ? 'Set to Inactive'
            : rowData?.is_active
            ? 'Set to Inactive'
            : 'Set to Active'}
        </ItemText>
      ),
    })
  }

  const menus = <ActionMenu width={'165px'} items={items} />

  return (
    <Main id="filterDropdown">
      <StyledDropDownButton
        overlay={menus}
        shape="round"
        trigger={['click']}
        icon={<DownArrow />}
        getPopupContainer={() => document.getElementById('filterDropdown')}
      >
        <ButtonText onClick={() => onClickView(rowData?.id)}>View</ButtonText>
      </StyledDropDownButton>
    </Main>
  )
}

export default ActionDropdown
