import React from 'react'

const StepsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.704 1.869A10.743 10.743 0 0 1 12 1c1.513 0 2.949.288 4.296.869a11.105 11.105 0 0 1 3.486 2.349 11.092 11.092 0 0 1 2.348 3.486A10.72 10.72 0 0 1 23 12c0 1.514-.288 2.95-.87 4.296a11.092 11.092 0 0 1-2.348 3.486 11.092 11.092 0 0 1-3.486 2.348c-1.347.582-2.782.87-4.296.87a10.72 10.72 0 0 1-4.296-.87 11.092 11.092 0 0 1-3.486-2.348 11.091 11.091 0 0 1-2.348-3.486A10.732 10.732 0 0 1 1 12c0-1.513.288-2.949.87-4.296l5.834-5.835Zm0 0a11.105 11.105 0 0 0-3.486 2.349A11.091 11.091 0 0 0 1.87 7.704l5.834-5.835Z"
        fill="#fff"
        stroke="#2D42C1"
        strokeWidth="2"
      />
      <circle cx="12.001" cy="12.667" r="3.333" fill="#3249D7" />
      <path
        d="M6.668 12.667a5.333 5.333 0 1 0 5.333-5.333"
        stroke="#3249D7"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default StepsIcon
