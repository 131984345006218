import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import Flex from 'components/Flex'
import SearchInput from 'components/SearchInput'
import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import FilterCheckBoxComp from 'components/UIComponents/ReportSearchFilter/FilterCheckBox/FilterCheckBox.js'
import {
  Container,
  Contents,
  FilterHeader,
  FilterText,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import InfiniteScroll from 'react-infinite-scroll-component'
const StateFilter = ({
  stateValue,
  setStateValue,
  stateList,
  setKeyState,
  flag,
  setFlag,
  keyState,
  loading,
  isReport,
  loadMoreData,
  stateCount,
  loadMoreStateData,
  statePage,
  name = 'State',
}) => {
  const [state, setState] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    let finalData = []

    if (statePage == 0) {
      finalData = stateList
    } else {
      finalData = [...state, ...stateList]
    }

    setState(finalData)
  }, [stateList])
  const onChange = checkedvalue => {
    let indexToRemove = stateValue?.indexOf(...checkedvalue)
    let stateCopy = [...stateValue]
    if (indexToRemove !== -1) {
      stateCopy.splice(indexToRemove, 1)
      setStateValue([...stateCopy])
    } else {
      setStateValue([...stateValue, ...checkedvalue])
    }
  }

  const Spinner = (
    <>
      {!stateList?.length && loading ? (
        <Spin />
      ) : state >= 0 && !loading ? (
        <RowNumberData>No Data Found</RowNumberData>
      ) : (
        ''
      )}
    </>
  )
  return (
    <Contents>
      <FilterHeader>
        <FilterText>{name}</FilterText>
        <SearchInput
          background="#F8F8F8"
          width={'100%'}
          placeholder="Search by State"
          bordered={false}
          setSearchValue={setKeyState}
          searchValue={keyState}
          flag={flag}
          setFlag={setFlag}
          isReport={isReport}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </FilterHeader>
      <Container>
        {state?.length > 0 ? (
          <p>
            <InfiniteScroll
              key={stateList}
              dataLength={state?.length}
              height="18vh"
              next={loadMoreStateData}
              hasMore={state.length < stateCount}
              loader={
                <Flex center>
                  <Spin small />
                </Flex>
              }
            >
              <FilterCheckBoxComp
                options={state}
                value={stateValue}
                onChangeCheck={e => onChange([e.target.value])}
              />
            </InfiniteScroll>
          </p>
        ) : (
          <Flex center>{Spinner}</Flex>
        )}
      </Container>
    </Contents>
  )
}

export default StateFilter
