import Control from 'components/Control/Control'
import CheckIconGreen from 'components/icons/CheckIconGreen'
import Label from 'components/Label/Label'
import React from 'react'

import { FormItem } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'

import Uploadloader from 'components/Uploader/Uploader'
import {
  ColorSpan,
  Div,
  DivPadding,
  DivPaddingExtra,
  IconAlign,
  PlanStyle,
  SpanPadding,
  SpanWord,
  StatusSelected,
  StyledRadioPlan,
} from 'modules/Onboarding/Partials/Corporates/Partials/PlanDrawer/Style/style'

import FileReader from 'components/Uploader/FileReader'

import { Form } from 'antd'
import {
  RadioGroup,
  TwoFields,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'

const PricingContractDetails = ({ form, setContractDetails }) => {
  const onChangeRegDocs = file => {
    form.setFieldsValue({ regDocs: file ?? null })
    setContractDetails(file)
  }
  const regDocs = Form.useWatch('regDocs', form)

  return (
    <Control flexStart row width="100%" spaceBetween>
      <Control column flexStart width="67%" gap="20px">
        <Control flexStart column width="552px">
          <StatusSelected>Selected Plan</StatusSelected>

          <PlanStyle width="552px">
            <TwoFields spaceBetween>
              <Div>
                <RadioGroup value="pilot" onChange={''}>
                  <StyledRadioPlan boxed={true} value="pilot" label="Pilot" />
                </RadioGroup>
                <DivPadding>Unlimited time</DivPadding>
              </Div>
              <div>
                <SpanWord>Free</SpanWord>
                <ColorSpan>/month</ColorSpan>
              </div>
            </TwoFields>
            <DivPaddingExtra>
              <div>
                <IconAlign>
                  <CheckIconGreen />
                </IconAlign>
                <SpanPadding> Access to basic features</SpanPadding>
              </div>
              <div>
                <IconAlign>
                  <CheckIconGreen />
                </IconAlign>
                <SpanPadding> Basic reporting and analytics</SpanPadding>
              </div>
              <div>
                <IconAlign>
                  <CheckIconGreen />
                </IconAlign>
                <SpanPadding> Unlimited collaborators</SpanPadding>
              </div>
              <div>
                <IconAlign>
                  <CheckIconGreen />
                </IconAlign>
                <SpanPadding> Plugins,widgets and templates</SpanPadding>
              </div>
              <div>
                <IconAlign>
                  <CheckIconGreen />
                </IconAlign>
                <SpanPadding> Mobile app</SpanPadding>
              </div>
            </DivPaddingExtra>
          </PlanStyle>
        </Control>

        <Control flexStart column width="552px">
          <FormItem
            name={'regDocs'}
            rules={[
              {
                required: false,
                message: 'Please upload Contract!',
              },
            ]}
          >
            <Label>Contract</Label>
            {regDocs ? (
              <Control column top="10px" center>
                <FileReader file={regDocs} setFile={onChangeRegDocs} />
              </Control>
            ) : (
              <Control column top="10px" center>
                <Uploadloader
                  setFile={onChangeRegDocs}
                  isDefault={true}
                  fileName={'document'}
                />
              </Control>
            )}
          </FormItem>
        </Control>
      </Control>
    </Control>
  )
}

export default PricingContractDetails
