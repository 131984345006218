import { Spin } from 'antd'
import Flex from 'components/Flex'
import {
  FilterWrapper,
  RowNumberDataReport,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import FilterCheckBoxComp from 'components/UIComponents/ReportSearchFilter/FilterCheckBox/FilterCheckBox.js'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
const CityFilter = ({
  key,
  setValue,
  value,
  search,
  cityDetails,
  cityData,
  valueParam,
  labelParam,
  loading,
  setLoading,
  flag,
  batch,
}) => {
  const [citiesData, setCitiesData] = useState([])
  const [debounced, setDebounced] = useState(() => search)
  const [page, setPage] = useState(1)
  const getCities = (search = '') => {
    let city = search
    setPage(0)
    if (flag !== '' && flag) {
      cityDetails(flag, setLoading, city)
    } else {
      cityDetails(setLoading, city)
    }
  }
  useEffect(() => {
    setCitiesData([])
    setLoading(true)
  }, [key])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(search)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  useEffect(() => {
    setCitiesData([])
    getCities(search)
  }, [debounced])
  const uniqueItems = new Set()

  useEffect(() => {
    if (cityData?.result?.length) {
      let newList = [...(cityData?.result ?? [])]
      let uniqueList = Array.from(
        new Map(newList.map(item => [JSON.stringify(item), item])).values()
      ).map(item => {
        const updatedItem = { ...item }

        if (updatedItem?.education_level) {
          updatedItem.education_level_value = updatedItem?.education_level

          const educationLevel = updatedItem?.education_level.toUpperCase()

          // Check if the education level is already present in the set
          if (!uniqueItems.has(educationLevel)) {
            updatedItem.education_level = educationLevel
            uniqueItems.add(educationLevel)
          } else {
            updatedItem.education_level = null // Or any other value to indicate duplication
          }
        }

        return updatedItem
      })
      let data = uniqueList?.filter(item => item.education_level !== null)
      let finalData = []
      if (cityData?.page == 0) {
        finalData = data
      } else {
        finalData = [...citiesData, ...data]
      }

      setCitiesData(finalData)
    }
  }, [cityData])

  const onChange = checkedvalue => {
    let indexToRemove = value?.indexOf(...checkedvalue)
    let locationCopy = [...value]
    if (indexToRemove !== -1) {
      locationCopy?.splice(indexToRemove, 1)
      setValue([...locationCopy])
    } else if (batch) {
      setValue([...checkedvalue])
    } else {
      setValue([...value, ...checkedvalue])
    }
  }
  const loadMoreData = async () => {
    if (!loading) {
      if (flag !== '' && flag) {
        cityDetails(flag, setLoading, search, cityData?.page + 1)
      } else {
        cityDetails(setLoading, search, cityData?.page + 1)
      }
      setPage(page + 1)
    }
  }
  const Spinner = (
    <>
      {loading ? (
        <Spin />
      ) : !citiesData.length && !loading ? (
        <RowNumberDataReport>No Data Found</RowNumberDataReport>
      ) : (
        ''
      )}
    </>
  )
  return (
    <FilterWrapper key={key}>
      <InfiniteScroll
        dataLength={citiesData?.length}
        height="18vh"
        next={loadMoreData}
        hasMore={citiesData.length < cityData?.count}
        loader={
          <Flex center>
            <Spin small />
          </Flex>
        }
      >
        {!loading && (
          <FilterCheckBoxComp
            gap={'1rem'}
            options={citiesData}
            value={value}
            onChangeCheck={e => onChange([e.target.value])}
            valueParam={valueParam}
            labelParam={labelParam}
          />
        )}
        <Flex center>{Spinner}</Flex>
      </InfiniteScroll>
    </FilterWrapper>
  )
}

export default CityFilter
