import axios from 'axios'

const { API_URL } = process.env

const authRequest = axios.create({
  baseURL: API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export default authRequest
