import ExportButton from 'components/ExportButton/ExportButton.js'
import {
  arrayIntoString,
  DateFormat,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import ListOfStudentFilter from 'modules/Reports/Partials/Students/Partials/ListOfStudentTable/StudentFilter/index.js'
import StudentListTable from 'modules/Reports/Partials/Students/Partials/ListOfStudentTable/StudentListTable/index.js'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessage from 'utils/WarningMessage'
import {
  PaddedDiv,
  SectionWrapper,
  TopHeader,
  TopHeading,
} from '../../../../Styles/style'
import { setStudentListForReport } from '../../actions'

import { degreeListConvertionPart } from '../Function/Function'

const StudentListOfData = ({
  getStudentListForReport,
  studentListDataForReport,
  excelDataForReport,
  UniversityUrl,
  universityData,
  CollegeUrl,
  collegeData,
  DegreeUrl,
  degreeData,
  StateUrl,
  StudentCityUrl,
  studentStateData,
  studentCityData,
  userData,
}) => {
  const dispatch = useDispatch()
  const [sortBy, setSortBy] = useState('')
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loadingDep, setLoadingDep] = useState(false)
  const [searchDegree, setSearchDegree] = useState('')
  const [degreeList, setDegreeList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [degreeListData, setDegreeListData] = useState([])
  const [universityLoader, setUniversityLoader] = useState(false)
  const [degreeLoader, setDegreeLoader] = useState(false)
  const [collegeLoader, setCollegeLoader] = useState(false)
  const [stateLoader, setStateLoader] = useState(false)
  const [cityLoader, setCityLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [filterDatas, setFilterData] = useState({})
  const [keyCourse, setKeyCourse] = useState('')
  const [keyState, setKeyState] = useState('')
  const [keyCity, setKeyCity] = useState('')
  const [keySpecialisation, setKeySpecialisation] = useState('')
  const [keyDegree, setKeyDegree] = useState('')
  const [keyCollege, setKeyCollege] = useState('')
  const [keyName, setKeyName] = useState('')
  const [nameValue, setNameValue] = useState([])
  const [courseValue, setCourseValue] = useState([])
  const [specialisationValue, setSpecialisationValue] = useState([])
  const [degreeValue, setDegreeValue] = useState([])
  const [stateValue, setStateValue] = useState([])
  const [cityValue, setCityValue] = useState([])
  const [collegeValue, setCollegeValue] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [debouncedDegree, setDebouncedDegree] = useState(() => keyDegree)
  const [debounced, setDebounced] = useState(() => searchValue)
  const [tooltip, setToolTip] = useState(false)
  function hasData(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }
  function toCamelCase(str) {
    const words = str.split(' ')
    const camelCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    return camelCaseWords.join(' ')
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(searchValue)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])
  useEffect(() => {
    const timeout = setTimeout(() => {}, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyState])
  useEffect(() => {
    const timeout = setTimeout(() => {}, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyCity])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDegree(searchDegree)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchDegree])
  useEffect(() => {
    if (hasData(filterDatas)) {
      if (pageNum) {
        if (hasData(filterDatas)) {
          getStudentListForReport({
            pageSize: 10,
            pageNumber: pageNum - 1,
            setLoading,
            searchValue,
            filterDatas,
            sortBy,
          })
        }
      }
    }
  }, [pageNum])

  useEffect(() => {
    if (hasData(filterDatas)) {
      getStudentListForReport({
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
      setPageNum(1)
    }
  }, [debounced, filterDatas, sortBy])
  useEffect(() => {
    let college = arrayIntoString(collegeValue)
    let course = arrayIntoString(courseValue)
    let specialisation = arrayIntoString(specialisationValue)
    let degree = arrayIntoString(degreeList)
    let state = arrayIntoString(stateValue)
    let city = arrayIntoString(cityValue)

    let filterDatas = {
      college: college,
      course: course,
      specialisation: specialisation,
      degree: degree,
      state: state,
      city: city,
    }
    setToolTip(hasData(filterDatas))
  }, [
    collegeValue,
    courseValue,
    specialisationValue,
    degreeList,
    stateValue,
    cityValue,
  ])
  const title = [
    { title: 'ID' },
    {
      title: 'Student Name',
    },
    { title: 'Deg-Dept  (Spec.)', width: { wch: 30 } },
    { title: 'College Name', width: { wch: 20 } },
    { title: 'University', width: { wch: 20 } },
    { title: 'Empanalled', width: { wch: 20 } },
    { title: 'Location', width: { wch: 20 } },
  ]

  const tableData = excelDataForReport?.result?.map(item => [
    { value: item.prefix_id ? item.prefix_id : '-' },
    {
      value: item.first_name
        ? item.first_name +
          (item.last_name ? ' ' + String(item.last_name).trim() : '')
        : '-',
    },
    {
      value:
        item.course && item.specialisation && item.short_form
          ? item.short_form +
            '-' +
            toCamelCase(item.course) +
            ' (' +
            toCamelCase(item.specialisation) +
            ')'
          : item.short_form && item.course && !item.specialisation
          ? item.short_form + '-' + toCamelCase(item.course)
          : '-',
    },
    { value: item.institute_campus_name ? item.institute_campus_name : '-' },
    { value: item.university ? item.university : '-' },
    { value: item.createdat ? item.createdat?.split('/').join('-') : '-' },
    { value: item.corr_city ? item.corr_city : '-' },
  ])
  const excelData = [{ columns: title, data: tableData }]
  const [isExcel, setIsExcel] = useState('')
  const [excel, setExcel] = useState([])
  const clickedExcel = data => {
    let excel = true
    getStudentListForReport({
      pageSize: 5000,
      pageNumber: 0,
      setLoading,
      searchValue,
      filterDatas,
      sortBy,
      excel,
    })
    setIsExcel(data)
  }
  useEffect(() => {
    if (
      (excelDataForReport?.result && tableData && isExcel == 'excel') ||
      (excelDataForReport?.result && tableData && isExcel == 'pdf')
    ) {
      if (loading) {
        setExcel([])
      } else {
        setExcel(excelData)
      }
    }
  }, [excelDataForReport?.result, loading])
  const handleHover = () => {
    if (studentListDataForReport?.count > 5000) {
      WarningMessage(
        'Note : The student count should not exceed 5,000 for data export.  Apply filters if necessary'
      )
    }
  }

  useEffect(() => {
    let flag = 'LS'
    DegreeUrl(flag, setLoadingDep, searchDegree, 0, setDegreeLoader)
  }, [debouncedDegree])
  const loadMoreData = async () => {
    let flag = 'LS'
    DegreeUrl(
      flag,
      setLoadingDep,
      searchDegree,
      degreeData?.page + 1,
      setDegreeLoader
    )
    setPage(page + 1)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearchQuery(query)
  }
  useEffect(() => {
    const updatedData = degreeListConvertionPart({
      listOfDegree: degreeData?.result,
      selectedIds: [],
      isEditActiveRole: [],
    })
    setCount(degreeData?.count)
    setPage(degreeData?.page)
    setDegreeListData(updatedData)
  }, [degreeData])
  useEffect(() => {
    dispatch(setStudentListForReport([]))
  }, [])

  const columnWidths = [17, 50, 50, 50, 40, 35, 45, 25, 25] // Array of column widths
  const HAlign = [
    'center',
    'left',
    'left',
    'left',
    'left',
    'center',
    'left',
    'center',
    'left',
  ]

  return (
    <SectionWrapper column>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>Lists of Students</TopHeading>
          <TopHeading flexEnd style={{ display: 'flex', gap: 10 }}>
            <div role="button" tabIndex="0" onMouseEnter={handleHover}>
              <ExportButton
                title={'Lists of Students'}
                dataset={excel}
                loading={loading}
                clickedExcel={clickedExcel}
                data={isExcel}
                setIsExcel={setIsExcel}
                count={studentListDataForReport?.count || 0}
                columnWidth={columnWidths} // Array of column widths
                columnFontSize={10} // Font size for the column data
                horizontalAlignment={HAlign}
                userData={userData}
              />
            </div>
          </TopHeading>
        </TopHeader>
      </PaddedDiv>
      <ListOfStudentFilter
        setStateValue={setStateValue}
        setCityValue={setCityValue}
        cityValue={cityValue}
        stateValue={stateValue}
        setKeyState={setKeyState}
        setKeyCity={setKeyCity}
        keyState={keyState}
        keyCity={keyCity}
        setStateLoader={setStateLoader}
        setCityLoader={setCityLoader}
        cityLoader={cityLoader}
        stateLoader={stateLoader}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        degreeList={degreeList}
        setSearchDegree={setSearchDegree}
        degreeListData={degreeListData}
        setSearchQuery={setSearchQuery}
        setDegreeLoader={setDegreeLoader}
        degreeLoader={degreeLoader}
        degreeData={degreeData}
        DegreeUrl={DegreeUrl}
        setCollegeLoader={setCollegeLoader}
        collegeLoader={collegeLoader}
        setUniversityLoader={setUniversityLoader}
        universityLoader={universityLoader}
        UniversityUrl={UniversityUrl}
        universityData={universityData}
        setToolTip={setToolTip}
        tooltip={tooltip}
        currentPage={pageNum}
        tableData={studentListDataForReport}
        setPageNum={setPageNum}
        pageNum={pageNum}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        degreeValue={degreeValue}
        nameValue={nameValue}
        courseValue={courseValue}
        specialisationValue={specialisationValue}
        collegeValue={collegeValue}
        keyCollege={keyCollege}
        keyCourse={keyCourse}
        keySpecialisation={keySpecialisation}
        keyDegree={keyDegree}
        keyName={keyName}
        setDegreeValue={setDegreeValue}
        setNameValue={setNameValue}
        setCollegeValue={setCollegeValue}
        setCourseValue={setCourseValue}
        setSpecialisationValue={setSpecialisationValue}
        setKeyCollege={setKeyCollege}
        setKeyCourse={setKeyCourse}
        setKeyDegree={setKeyDegree}
        setKeyName={setKeyName}
        setKeySpecialisation={setKeySpecialisation}
        isList={false}
        filterData={filterDatas}
        setFilterData={setFilterData}
        isStudentPlaced={true}
        CollegeUrl={CollegeUrl}
        collegeData={collegeData}
        setDegreeList={setDegreeList}
        page={page}
        count={count}
        setLoadingDep={setLoadingDep}
        loadMoreData={loadMoreData}
        loadingDep={loadingDep}
        StateUrl={StateUrl}
        StudentCityUrl={StudentCityUrl}
        studentStateData={studentStateData}
        studentCityData={studentCityData}
      />
      <StudentListTable
        hasProp={hasData(filterDatas)}
        setSortBy={setSortBy}
        tableData={studentListDataForReport}
        loading={loading}
        isEmpanelled={false}
        setPageNum={setPageNum}
        pageNum={pageNum}
      />
    </SectionWrapper>
  )
}
export default StudentListOfData
