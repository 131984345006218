import React, { useEffect, useState } from 'react'
import { COLORS } from 'theme'
import InfoCards from '../../components/InfoCards'
import Active from '../../components/icons/Active'
import Corporate from '../../components/icons/Corporate'
import InActive from '../../components/icons/InActive'
import CorporateInfoTable from './Partials/CorporateTable'
import { CardRow, SectionWrapper, TopHeader, TopHeading } from './Style/style'

const Corporates = ({
  getUserCorporatePortalsList,
  corporateList,
  getListOfState,
  getListOfCity,
}) => {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [corporateStatus, setCorporateStatus] = useState('')
  const [corporateActiveStatus, setCorporateActiveStatus] = useState('')
  const [location, setLocation] = useState('')
  const [stateId, setStateId] = useState('')
  const [stateList, setStateList] = useState([])
  const [stateNames, setStateNames] = useState([])
  const [cityId, setPermCityId] = useState('')
  const [cityList, setCityList] = useState([])
  const [cityName, setCityName] = useState([])
  const [filterData, setFilterData] = useState({})

  const [cardsData, setCardsData] = useState([])

  useEffect(() => {
    getUserCorporatePortalsList({
      page,
      search,
      sort,
      orderBy,
      corporateStatus,
      corporateActiveStatus,
      setLoading,
      ...(filterData || {}),
    })
  }, [
    corporateStatus,
    page,
    search,
    sort,
    orderBy,
    filterData,
    corporateActiveStatus,
  ])
  useEffect(() => {
    setPage(0)
  }, [
    search,
    filterData,
    corporateStatus,
    corporateActiveStatus,
    location,
    orderBy,
  ])

  useEffect(() => {
    const cardData = [
      {
        key: 1,
        icon: <Corporate />,
        text: 'Total Corporates',
        value: corporateList?.totalCount || 0,
        isSelected: false,
      },
      {
        key: 2,
        icon: <Active />,
        text: 'Active',
        value: corporateList?.activeCount || 0,
        filter: true,
        isSelected: false,
      },
      {
        key: 3,
        icon: <InActive />,
        text: 'Inactive',
        value: corporateList?.inActiveCount || 0,
        filter: false,
        isSelected: false,
      },
      // { *** For Future Use Case ***
      //   key: 4,
      //   icon: <Expired />,
      //   text: 'Expired',
      //   value: corporateList?.expiredCount || 0,
      //   // filter: '2',
      //   isSelected: false,
      // },
    ]
    setCardsData(cardData)
  }, [corporateList])

  //handlers
  const applyCardFilter = query => {
    let cardsCopy = [...cardsData]
    cardsCopy = cardsCopy.map(val => {
      if (query.filter === val.filter) {
        if (val.isSelected) {
          val.isSelected = false
        } else {
          setCorporateActiveStatus(query.filter)
        }
      } else {
        val.isSelected = false
      }
      return val
    })
    setCardsData(cardsCopy)
  }

  const onPageChange = key => {
    setPage(key - 1)
  }

  return (
    <SectionWrapper column>
      <TopHeader row spaceBetween alignCenter>
        <TopHeading flexStart>Corporates</TopHeading>
      </TopHeader>
      <CardRow spaceBetween>
        {cardsData?.map(card => (
          <InfoCards
            key={card?.text}
            padding={'20px'}
            width={'25%'}
            icon={card?.icon}
            text={card?.text}
            value={card?.value}
            background={card?.isSelected ? COLORS.BLUE_T_50 : COLORS.BLUE_T_95}
            isSelected={card?.isSelected}
            onClick={() => {
              applyCardFilter(card)
            }}
          />
        ))}
      </CardRow>
      <CorporateInfoTable
        corporateActiveStatus={corporateActiveStatus}
        location={location}
        setLocation={setLocation}
        setCorporateActiveStatus={setCorporateActiveStatus}
        corporateList={corporateList}
        loading={loading}
        page={page + 1}
        onPageChange={onPageChange}
        setSearch={setSearch}
        stateId={stateId}
        setStateId={setStateId}
        stateList={stateList}
        setStateList={setStateList}
        stateNames={stateNames}
        setFilterData={setFilterData}
        filterData={filterData}
        setStateNames={setStateNames}
        getListOfState={getListOfState}
        cityList={cityList}
        setCityList={setCityList}
        cityName={cityName}
        setCityName={setCityName}
        cityId={cityId}
        setPermCityId={setPermCityId}
        getListOfCity={getListOfCity}
        setPage={setPage}
        setSort={setSort}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        sortBy={sort}
        setCorporateStatus={setCorporateStatus}
        corporateStatus={corporateStatus}
      />
    </SectionWrapper>
  )
}

export default Corporates
