import React, { useEffect, useLayoutEffect, useState } from 'react'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import ErrorMessage from 'components/UIComponents/MessagePopup/ErrorMessage'
import { Spinner } from 'modules/Dashboard/Styles/style'
import { useLocation } from 'react-router-dom'
import { Form } from '../../AddRoleDrawer/Partials/AddRoleForm/styles'
import { FlexWrap } from '../Style/style'
import AddUserForm from './Partials'

const AddCorporatesDrawer = ({
  addUserDrawer,
  setAddUserDrawer,
  form,
  FunctionTableList,
  getCorporateMasterData,
  loc,
  getListOfState,
  loading,
  setLoading,
  setLocation,
  userData,
  corporateViewData,
  getCorporatesView,
  getcoporateData,
  creatId,
  updateCorporate,
  createCorporate,
  userRole,
  getListOfCity,
}) => {
  const [id, getId] = useState(id)
  const [journeyId, setJourneyId] = useState([])
  const [checked, setChecked] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [uuid, setuuid] = useState('')
  const [stateId, setStateId] = useState('')
  const [filterData, setFilterData] = useState({})
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [cityId, setPermCityId] = useState('')
  const [industry, setIndustry] = useState('')
  const [sectorList, setSectorList] = useState([])
  const [sector, setSector] = useState('')
  const [checkedCount, setCheckedCount] = useState(0) // Step 1: Initialize count state
  const callback = data => {
    getcoporateData([])
  }
  const userIdLocation = useLocation()
  useEffect(() => {
    if (userIdLocation) {
      const parts = userIdLocation?.pathname?.split('/')
      const data = parts?.[parts?.length - 1]
      setuuid(data)
    }
  }, [userIdLocation])

  useEffect(() => {
    form.setFieldsValue({
      city: corporateViewData?.list?.city,
      name: corporateViewData?.list?.name,
      industry: corporateViewData?.list?.industry,
      sector: corporateViewData?.list?.sector,
    })
  }, [corporateViewData?.list])

  const onClose = () => {
    setAddUserDrawer(false)
    setChecked(false)
    setStateId([])
    setPermCityId([])
    setJourneyId([])
    setIndustry([])
    setSector([])
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen) // Use the previous state
    setCheckedCount(0)
    setPageNumber(1)
    form.resetFields()
  }

  const onLinkCorporate = async value => {
    if (!checked) {
      ErrorMessage('Please Select one Corporate')
      return
    }
    const payload = {
      roleId: userData?.admin_role_id,
      journeyId: journeyId,
      journey: 'CORPORATE',
    }
    let response = await createCorporate(uuid, payload, callback)
    if (response?.status >= 200 && response?.status) {
      getcoporateData([])
      form.resetFields()
      setIsMenuOpen(false)
      setLoading(false)
      setAddUserDrawer(false)
      setChecked(false)
      setJourneyId([])
      setStateId([])

      setCheckedCount(0)
    } else {
      setLoading(false)
    }
  }

  const fetchSectorData = async (searchValue = '') => {
    let dropDownUrl = 'crud/sector'
    const response = await getCorporateMasterData({
      dropDownUrl,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,
      setLoading: '',
      searchValue,
    })
    if (response?.result) {
      setSectorList(response.result)
    }
  }

  useLayoutEffect(() => {
    fetchSectorData()
  }, [])

  const content = () => {
    return (
      <>
        {!corporateViewData && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          id={'linkCorporateForm'}
          form={form}
          layout="vertical"
          onFinish={onLinkCorporate}
        >
          <AddUserForm
            addUserDrawer={addUserDrawer}
            userRole={userRole}
            corporateViewData={corporateViewData}
            id={id}
            creatId={creatId}
            setStateId={setStateId}
            stateId={stateId}
            setFilterData={setFilterData}
            form={form}
            cityId={cityId}
            setPermCityId={setPermCityId}
            loc={loc}
            FunctionTableList={FunctionTableList}
            getListOfState={getListOfState}
            getCorporatesView={getCorporatesView}
            getcoporateData={getcoporateData}
            setLocation={setLocation}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setChecked={setChecked}
            setAddUserDrawer={setAddUserDrawer}
            checked={checked}
            getId={getId}
            setIndustry={setIndustry}
            industry={industry}
            setSector={setSector}
            sector={sector}
            fetchSectorData={fetchSectorData}
            sectorList={sectorList}
            setJourneyId={setJourneyId}
            journeyId={journeyId}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            getListOfCity={getListOfCity}
            setCheckedCount={setCheckedCount}
            checkedCount={checkedCount}
            filterData={filterData}
          />
        </Form>
      </>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Close" height="38px" onClick={onClose} />
        <Button.Primary
          disabled={!checkedCount}
          text={
            <div
              style={{ fontWeight: '600' }}
            >{`${checkedCount} Selected`}</div>
          }
          htmlType="submit"
          form={'linkCorporateForm'}
          height="38px"
          loading={!corporateViewData}
        />
      </FlexWrap>
    )
  }
  return (
    <Drawer
      width="40%"
      title={'Select  Corporates'}
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={addUserDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddCorporatesDrawer
