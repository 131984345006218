import { Col, Row } from 'antd'
import AntdInput from 'components/Form/Input'
import 'draft-js/dist/Draft.css'
import {
  ContentHeightForAssessment,
  EventHeader1,
  RowStyling,
  SectionWrapperToggle,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import 'modules/RankingAlgorithm/style.css'
import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
export default function AssessmentIndex({ academicDataConfig }) {
  return (
    
      <SectionWrapperToggle column>
        <RowStyling>
          <Col span={24}>
            <Col span={18} style={{ maxWidth: '100%' }}>
              <ContentHeightForAssessment>
                {' '}
                <Row>
                  <Col span={4}>
                    <EventHeader1>Maximum Score</EventHeader1>
                  </Col>
                  <Col span={2}>
                    <AntdInput
                      ismargin
                      placeholder={' Eg: 5.00'}
                      rules={[
                        {
                          required: true,
                          message: 'Kindly enter the data',
                        },
                      ]}
                      value="5"
                      disabled
                      height="50px"
                      width="80%"
                      borderRadius="10px"
                      backgroundColor="#FAFAFB"
                      background="#FAFAFB"
                    />
                  </Col>
                </Row>
              </ContentHeightForAssessment>
            </Col>
          </Col>
        </RowStyling>
      </SectionWrapperToggle>
    
  )
}
