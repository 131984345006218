import React from 'react'

const HolidayIcon = ({ color = '#3249D7' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      style={{ margin: 'auto' }}
    >
      <path
        d="M9.59005 9.29227C10.5465 9.11646 11.7017 8.99393 13.0557 8.99393C13.7918 8.99393 14.4688 9.03122 15.0867 9.08982L15.8389 7.41169C14.9631 7.29981 14.0282 7.24121 13.0557 7.24121C11.3524 7.24121 9.82647 7.34776 8.52618 7.64609L9.59005 9.29227Z"
        fill="#3249D7"
      />
      <path
        d="M21.9695 10.9383C21.486 9.4679 20.0567 8.46102 18.0902 7.875L17.3756 9.47322C18.7135 9.81418 19.5409 10.251 19.8902 10.5494C20.0245 10.6666 20.0943 10.8317 20.0943 10.9915C20.0943 11.1567 20.0245 11.3165 19.8902 11.4337C19.4281 11.8279 18.1117 12.4779 15.8872 12.8029L15.6293 13.3835C15.4466 13.7884 15.0437 14.0495 14.5977 14.0495C14.4365 14.0495 14.286 14.0175 14.141 13.9536C13.867 13.831 13.652 13.6126 13.5446 13.3356C13.5016 13.2237 13.4747 13.1118 13.4693 12.9946C13.3296 13 13.1953 13 13.0502 13C12.5559 13 12.0885 12.984 11.6479 12.9574C11.6425 12.984 11.6371 13.0106 11.6318 13.0426C11.5673 13.3356 11.3953 13.586 11.1428 13.7458C10.9601 13.863 10.7506 13.9216 10.5303 13.9216C10.1434 13.9216 9.78879 13.7298 9.57924 13.4102L9.04731 12.5951C7.52136 12.2488 6.58108 11.764 6.21033 11.439C5.93631 11.2046 5.93631 10.7838 6.21033 10.5547C6.41451 10.3789 6.77988 10.1551 7.31719 9.93671L6.36078 8.46102C5.37751 9.02039 4.62528 9.81951 4.13096 10.9436L3.30888 14.5822C2.91665 17.3791 3.76559 20.2026 5.63542 22.3336C7.50524 24.4646 10.2079 25.6845 13.0556 25.6845C15.9033 25.6845 18.606 24.4646 20.4758 22.3336C22.3456 20.2026 23.1946 17.3791 22.8024 14.5822L21.9695 10.9383ZM7.9727 20.8046C7.90285 20.8686 7.81151 20.8952 7.72554 20.8952C7.61808 20.8952 7.51599 20.8526 7.44076 20.7673C6.05451 19.1904 5.40975 17.0968 5.66765 15.0191C5.69452 14.8166 5.88258 14.6675 6.08675 14.6941C6.29093 14.7207 6.44138 14.9072 6.41451 15.1096C6.18347 16.9742 6.76376 18.8601 8.00494 20.2772C8.14464 20.4317 8.12852 20.6714 7.9727 20.8046Z"
        fill="#3249D7"
      />
      <path
        d="M25.4083 2.22797H20.9916C20.9862 2.22797 20.9809 2.22797 20.9755 2.22797C20.9056 1.91365 20.6262 1.68457 20.2877 1.68457H19.0842C18.8048 1.68457 18.5522 1.84972 18.4394 2.10011L17.8054 3.51187C17.6711 3.81554 17.7678 4.16715 18.031 4.35893C18.031 4.36426 18.0257 4.36959 18.0257 4.37491L14.2592 12.7922C14.1732 12.9787 14.2592 13.2024 14.4526 13.2824C14.5009 13.3037 14.5547 13.3143 14.603 13.3143C14.7481 13.3143 14.8824 13.2291 14.9469 13.0906L18.7134 4.67325C18.7134 4.66792 18.7188 4.66259 18.7188 4.65727C19.0358 4.7212 19.3636 4.56137 19.5033 4.25238L19.6483 3.93274C19.7612 3.68235 20.0137 3.5172 20.2931 3.5172C20.6316 3.5172 20.911 3.28279 20.9809 2.9738C20.9862 2.9738 20.9916 2.9738 20.997 2.9738H25.4136C25.6232 2.9738 25.7898 2.80865 25.7898 2.60089C25.7898 2.39312 25.6124 2.22797 25.4083 2.22797Z"
        fill="#3249D7"
      />
      <path
        d="M10.2133 13.0051C10.2831 13.117 10.4067 13.1756 10.5303 13.1756C10.6001 13.1756 10.67 13.1543 10.7345 13.117C10.9064 13.0051 10.9601 12.7761 10.8473 12.6003L6.82288 6.4098L10.3046 4.18294C8.91837 2.05198 6.05453 1.43932 3.91068 2.8138C1.76145 4.18827 1.14355 7.02778 2.5298 9.15342L6.19423 6.81468L10.2133 13.0051Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default HolidayIcon
