import React from 'react'
import Control from 'components/Control/Control'
import Button from 'components/Button'
import { FormWrapper } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import InstituteTaxDetails from 'modules/Onboarding/Partials/Institutes/Register/InstituteTaxInfo/InstituteTaxDetails'

const InstituteTaxInfo = ({
  setInstCurrent,
  setInstSubstep,
  authorisedSignatory,
  setAuthorizedSignatory,
  onSubmit,
  form,
  ...props
}) => {
const back=()=>{
    setInstCurrent(0)
    setInstSubstep(0)
  }

  return (
    <FormWrapper
      scrollToFirstError
      onFinish={onSubmit}
      id={'taxDetails'}
      form={form}
      layout="vertical"
    >
      <InstituteTaxDetails
        form={form}
        {...props}
        authorisedSignatory={authorisedSignatory}
        setAuthorizedSignatory={setAuthorizedSignatory}
      />

      <Control width="100%" flexEnd gap="15px" top="35px">
        <Button.Secondary text="Back" onClick={back} />
        <Button.Primary
          text="Save & Continue"
          htmlType={'submit'}
          form={'taxDetails'}
        />
      </Control>
    </FormWrapper>
  )
}

export default InstituteTaxInfo
