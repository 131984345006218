import React from 'react'

const RankIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 1.28595H15.362C15.4052 1.49417 15.4286 1.70876 15.4286 1.92874C15.4286 2.14887 15.4052 2.36341 15.362 2.57161H18V1.28595ZM18 7.71447H8.93345C8.97666 7.92269 9 8.13733 9 8.35734C9 8.57722 8.97668 8.792 8.93345 9.0002H18V7.71447ZM18 14.1431H15.362C15.4052 14.3513 15.4286 14.5659 15.4286 14.7859C15.4286 15.0058 15.4052 15.2206 15.362 15.4288H18V14.1431ZM14.1429 1.92874C14.1429 1.41725 13.9397 0.926754 13.578 0.565076C13.2163 0.203399 12.7258 0.000213623 12.2143 0.000213623C11.7028 0.000213623 11.2123 0.203399 10.8506 0.565076C10.4889 0.926754 10.2857 1.41725 10.2857 1.92874C10.2857 2.44023 10.4889 2.9308 10.8506 3.29248C11.2123 3.65416 11.7028 3.85734 12.2143 3.85734C12.7258 3.85734 13.2163 3.65416 13.578 3.29248C13.9397 2.9308 14.1429 2.44023 14.1429 1.92874ZM14.1429 14.7859C14.1429 14.2744 13.9397 13.7839 13.578 13.4222C13.2163 13.0605 12.7258 12.8573 12.2143 12.8573C11.7028 12.8573 11.2123 13.0605 10.8506 13.4222C10.4889 13.7839 10.2857 14.2744 10.2857 14.7859C10.2857 15.2974 10.4889 15.7879 10.8506 16.1496C11.2123 16.5113 11.7028 16.7145 12.2143 16.7145C12.7258 16.7145 13.2163 16.5113 13.578 16.1496C13.9397 15.7879 14.1429 15.2974 14.1429 14.7859ZM9.06655 1.28595H0V2.57161H9.06655C9.02334 2.36338 9 2.14875 9 1.92874C9 1.70885 9.02332 1.49415 9.06655 1.28595ZM9.06655 14.1431H0V15.4288H9.06655C9.02334 15.2206 9 15.0059 9 14.7859C9 14.5658 9.02332 14.3513 9.06655 14.1431ZM7.71429 8.35734C7.71429 7.84585 7.5111 7.35535 7.14942 6.99367C6.78774 6.632 6.2972 6.42881 5.78571 6.42881C5.27423 6.42881 4.78369 6.632 4.42201 6.99367C4.06033 7.35535 3.85714 7.84585 3.85714 8.35734C3.85714 8.86883 4.06033 9.3594 4.42201 9.72108C4.78369 10.0828 5.27423 10.2859 5.78571 10.2859C6.2972 10.2859 6.78774 10.0828 7.14942 9.72108C7.5111 9.3594 7.71429 8.86883 7.71429 8.35734ZM2.63798 7.71447H0V9.0002H2.63798C2.59477 8.79198 2.57143 8.57732 2.57143 8.35734C2.57143 8.13745 2.59475 7.92267 2.63798 7.71447Z"
        fill="#CCCFD6"
      />
    </svg>
  )
}

export default RankIcon
