import React from 'react'

import { Main } from 'components/ActionStyles/ActionStyles'
import { useDispatch } from 'react-redux'
import { StyledButton } from '../../../../components/ActionStyles/ActionStyles'
import { getSingleInstituteData } from '../../actions'

const ActionDropdown = ({ rowData, setViewInstituteDrawer }) => {
  const dispatch = useDispatch()

  const callBackError = msg => {
    ErrorMessage(msg || 'No Corporate Found')
  }

  const onClickView = () => {
    setViewInstituteDrawer(true)
    dispatch(
      getSingleInstituteData(rowData?.id, callBackError, setViewInstituteDrawer)
    )
  }

  return (
    <Main>
      <StyledButton onClick={() => onClickView()}>View</StyledButton>
    </Main>
  )
}

export default ActionDropdown
