import React from 'react'

const CityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      style={{ margin: 'auto' }}
    >
      <path
        d="M11 0C10.3975 0 9.90911 0.488436 9.90911 1.09091V2.28022C8.16263 2.59258 6.66874 3.64044 5.7598 5.09091H16.2402C15.3313 3.64044 13.8374 2.59258 12.0909 2.28022V1.09091C12.0909 0.488436 11.6025 0 11 0Z"
        fill="#3249D7"
      />
      <path
        d="M20.4546 13.8195L19.3636 13.8195V13.8184H2.63638H2.18496H1.54554H1.54547C1.25616 13.8184 0.978705 13.9333 0.774123 14.1379C0.569468 14.3424 0.454559 14.62 0.454559 14.9093V22.9093C0.454559 23.5117 0.942996 24.0002 1.54547 24.0002H9.9091V20.7267C9.9091 20.1242 10.3975 19.6357 11 19.6357C11.6025 19.6357 12.0909 20.1242 12.0909 20.7267V24.0002H20.4546C21.057 24.0002 21.5455 23.5117 21.5455 22.9093V14.9104C21.5455 14.308 21.0571 13.8196 20.4546 13.8195Z"
        fill="#3249D7"
      />
      <path
        d="M19.3637 8.36386C19.3637 7.76139 18.8752 7.27295 18.2727 7.27295H17.7227H17.0834H4.9166H4.37995H3.72729C3.12482 7.27295 2.63638 7.76139 2.63638 8.36386V11.6367H19.3637V8.36386Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default CityIcon
