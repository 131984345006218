import {
  SET_GENERAL_LIST,
  SET_FUNCTION_LIST,
} from 'modules/Systemconfig/Partials/GeneralTableSettings/actions'

const initialState = {
  getGeneralListForSysConfig: {},
  getFunctionSysConfig: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GENERAL_LIST:
      return {
        ...state,
        getGeneralListForSysConfig: action.data,
      }
    case SET_FUNCTION_LIST:
      return {
        ...state,
        getFunctionSysConfig: action.data,
      }
    default:
      return state
  }
}
