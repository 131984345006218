import SuccessMessage from 'utils/SuccessMessage'
import adminRequest from 'utils/adminRequest'
import instituteRequest from 'utils/instituteRequest'
import ErrorMessage from 'utils/ErrorMessage'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import { getInstitutesListData, setInstituteGeneratedLink } from '../Partials/InstituteInfoTable/actions'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'

export const SET_SINGLE_INSTITUTES_DATA = 'SET_SINGLE_INSTITUTES_DATA'

export const SET_INSTITUTE_INFO = 'SET_INSTITUTE_INFO'

export const setSingleInstituteData = data => {
  return {
    type: SET_SINGLE_INSTITUTES_DATA,
    data,
  }
}

export const editInstitute =
  ({ instituteCampusId, data, callBack, message = 'Institute created successfully.', calldata=false }) =>
    async dispatch => {
      try {
        const result = await instituteRequest.put(`/institutes/${instituteCampusId}`, data)

        dispatch(getInstitutesListData({}))
        function formatDate(date) {
          let d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
        
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
        
          return [year, month, day].join('-');
        }
  
     
          let payloadData = {
            query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
          };
  
        await elasticSearchSyncRequest.post(`/sync/institutes_master`,payloadData)
        await elasticSearchSyncRequest.post(`/sync/institute_locations`,payloadData)

        SuccessMessage(message)
        dispatch(
          setInstituteGeneratedLink({
            //generatedLink: result?.data?.data?.generatedLink,
            id: result?.data?.data?.instituteId,
          })
        )
        if(calldata)
        callBack()
      } catch (error) {
        ErrorMessage(error?.response?.data?.message)
      }
    }

    export const updateInstituteStatus =
  ({ instituteCampusId, data, callBack, message = 'Institute created successfully.' }) =>
    async dispatch => {
      try {
        const result = await instituteRequest.put(`/institutes/statusupdate/${instituteCampusId}`, data)
        function formatDate(date) {
          let d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
        
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
        
          return [year, month, day].join('-');
        }
  
     
          let payloadData = {
            query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
          };
  
        await elasticSearchSyncRequest.post(`/sync/institutes_master`,payloadData)
        await elasticSearchSyncRequest.post(`/sync/institute_locations`,payloadData)

        dispatch(getInstitutesListData({}))
        SuccessMessage(message)
        dispatch(
          setInstituteGeneratedLink({
            //generatedLink: result?.data?.data?.generatedLink,
            id: result?.data?.data?.instituteId,
          })
        )
        callBack()
      } catch (error) {
        ErrorMessage(error?.response?.data?.message)
      }
    }

export const getSingleInstituteData =
  (instituteId, callBack) => async dispatch => {
    try {
      const res = await adminRequest.get(`/institutes/${instituteId}`)
      dispatch(setSingleInstituteData(res?.data?.data || {}))
      return true
    } catch (error) {
      console.log(error)
      if (callBack) {
        callBack()
      }
    }
  }



export const setInstituteFormInfo = data => {
  return {
    type: SET_INSTITUTE_INFO,
    data,
  }
}
