import React from 'react'

const ArrowSideIcon = () => {
  return (
    <svg
      width="9"
      height="17"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000241011 0L5.5472 8.32L0.000241011 16.6263C1.15056 16.6158 2.29993 16.6107 3.44835 16.6107L8.98031 8.31997L3.46923 0.0517741C2.5407 0.0517741 1.6122 0.043461 0.683705 0.0269913C0.749586 0.0269913 0.407723 0.0156978 0 1.22542e-05L0.000241011 0Z"
        fill="white"
      />
    </svg>
  )
}

export default ArrowSideIcon
