import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'
import Button from 'components/Button'
import { StyledTabs } from 'components/TabsPaneStyles'
import { List } from 'antd'
import { RadioGroup } from 'components/UIComponents/FormStyles'
import Radio from 'components/Form/RadioButton'

export const FlexWrap = styled(Flex)`
  gap: 15px;
`
export const CollagesFlex = styled(Flex)`
  line-height: normal;
  padding: ${props => (props?.padding ? props?.padding : '0')};
  margin: ${props => (props?.margin ? props?.margin : '0')};
`
export const CollagesInfoDiv = styled.div`
  margin-right: 10px;
  color: ${COLORS.GREY_T_30};
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_BASE};
`
export const CollagesSpan = styled.span`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: ${FONT.F_XS};
  color: ${COLORS.GREY_T_20};
  background: #f2f3f5;
  border-radius: 10px;
  padding: 3px 8px 4px 8px;
  text-align: center;
`
export const CollageNameFlex = styled(Flex)`
  flex-direction: column;
  flex: 1;
  gap: 5px;
  margin-left: 10px;
`
export const CollegeNameDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  color: ${COLORS.GREY_T_20};
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CollegeCity = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_XS};
  color: ${COLORS.GREY_T_60};
`
export const CollageDetailsFlex = styled(Flex)`
  margin: 0 0 24px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid ${COLORS.GREY_T_90};
  line-height: normal;
  width: 100%;
`
export const SelectButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_60)} !important;
  background: none !important;
`
export const HeaderDiv = styled.div`
  position: sticky;
  top: ${props => (props?.top ? props?.top : '0')};
  background: ${COLORS.WHITE};
  z-index: 1;
  padding-bottom: 10px;
`
export const Wrapper = styled.div`
  .ant-list-split .ant-list-item {
    border-bottom: none;
  }
  .ant-list-item {
    padding: 0;
  }

  .infinite-scroll-component {
    ::-webkit-scrollbar {
      display: '';
    }
  }
`
export const HeaderFlex = styled(Flex)`
  border: 1px solid ${COLORS.GREY_T_90};
  border-radius: 10px;
`
export const FilterSection = styled(Flex)`
  cursor: pointer;
  /* padding: 0 11px; */
  border-left: 1px solid ${COLORS.GREY_T_90};
  /* height: 38px; */
  background: ${COLORS.GREY_T_95};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`
export const MenuFlex = styled(Flex)`
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
`
export const StyledTab = styled(StyledTabs)`
  .ant-tabs-content {
    height: 250px !important;
    min-width: 400px !important;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const SubmitFlex = styled(Flex)`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const FilterWrapper = styled(Flex)`
  padding: 20px 20px 20px 0;
  gap: 5px;
`
export const StickyDiv = styled.div`
  position: relative;
`
export const PublishedFlex = styled(Flex)`
  margin: ${props => (props?.margin ? props?.margin : '20px 0')};
  line-height: 100%;
`
export const PublishedFlex2 = styled(Flex)`
  margin: ${props => (props?.margin ? props?.margin : '20px 0')};
  padding-bottom: 10px;
  line-height: 100%;
  position: sticky;
  top: 0;
`
export const PublishedWrap = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`
export const ListFlex = styled(List)`
  padding-top: 25px;
`
export const HeaderContent = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #4d566e;
`
export const StickyHeader = styled.div`
  position: sticky;
  background: white;
  z-index: 1;
  top: 0px;
`
export const PublishContentFlex = styled(Flex)`
  padding-bottom: 20px;
  width: 21rem;
`

export const RadioGroups = styled(RadioGroup)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`
export const Radios = styled(Radio)`
  border: none !important;
  font-weight: ${FONT.FW_600} !important;
  font-size: ${FONT.F_SM} !important;
  color: ${COLORS.GREY_T_20} !important;
  padding: 15px 0 15px 0;
  margin: 0;
`

export const AlignAlreadySelectedCorporate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`
