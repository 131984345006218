import Button from 'components/Button/index'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Dashboard/Partials/UsersFilter/Styles/style'
import React, { useState } from 'react'
import LocationFilter from './Partials/LocationFilter'

const CorporateFilter = ({
  setLocation,
  searchValue,
  setCurrentPage,
  setSearchValue,
  getcoporateData,
  corporatejourneyData,
  setPageNum,
  pageNum,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [locationFilter, setLocationFilter] = useState([])
  const [search, setSearch] = useState('')

  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      location: locationFilter,
    })
    setLocation(locationFilter)
  }

  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setLocation(filterData?.location)
    setLocationFilter(filterData?.location)
    setSearch('')
  }

  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'Location',
            key: '1',
            children: (
              <LocationFilter
                location={locationFilter}
                setLocation={setLocationFilter}
                setSearch={setSearch}
                search={search}
              />
            ),
          },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )
  return (
    <TableSearchFilter
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      currentCount={10 * pageNum + corporatejourneyData?.list?.length}
      totalCount={corporatejourneyData?.count}
      onOpenChange={onCancelFilter}
      setPageNum={setPageNum}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default CorporateFilter
