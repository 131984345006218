import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import { FlexWrap } from 'components/UIComponents/FormStyles'
import {
  getListOfCity,
  getListOfCounties,
  getListOfState,
} from 'modules/Onboarding/actions'
import { Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import { searchConfig } from 'utils/config'
import { getMasterSearchApi } from '../../../../../Onboarding/actions'
import ContactInstituteDetails from './ContactInstituteDetails'
import InstitutesDetails from './InstituteDetails'
const { COUNTRY_ID } = process.env

const AddNewCollegeDrawer = ({
  newInstituteDrawer,
  setNewInstituteDrawer,
  formId = 'institute_form',
  title = 'Add Institutes',
  sendButtonText = 'Add',
  dateFormat,
  isSystemConfig = false,
  universityValue,
  handleSubmitCongif,
  view = false,
  setView,
  addContent,
  form,
  universityId,
  setUniversityId,
}) => {
  const [searchCountry, setSearchCountry] = useState('')
  const dispatch = new useDispatch()

  useEffect(() => {
    dispatch(getListOfCounties())
  }, [])

  useEffect(() => {
    let payLoad = { ...searchConfig.universities, q: '' }
    dispatch(getMasterSearchApi('universities', payLoad))
  }, [])

  const countryList = useSelector(state => state?.CorporateData?.countryList)
  const universityList = useSelector(
    state => state?.CorporateData?.masterSearchApi
  )
  const [countryCode, setCountryCode] = useState('+91')
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [countryId, setCountryId] = useState('')
  const [stateId, setStateId] = useState('')
  const [cityId, setPermCityId] = useState('')
  const [universityName, setUniversityName] = useState('')
  const [isSearchActive, setIsSearchActive] = useState(true)
  const [loadingCountry, setLoadingCountry] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      setLoadingState(true)
      let list = await dispatch(getListOfState(countryId, search))
      setLoadingState(false)
      return setStateList(list || [])
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    if (countryId && stateId) {
      setLoadingCity(true)
      let list = await dispatch(getListOfCity(countryId, stateId, search))
      setLoadingCity(false)
      return setCityList(list || [])
    }
  }

  useEffect(() => {
    let getData
    getData = setTimeout(() => {
      setLoadingCountry(true)
      dispatch(getListOfCounties(searchCountry))
      setLoadingCountry(false)
    }, 500)
    return () => clearTimeout(getData)
  }, [searchCountry])

  const onStateChange = (data, option) => {
    form.setFieldsValue({
      state: data,
      city: '',
      stateId: option?.option?._id,
      cityId: '',
    })
    setStateName(data)
    setStateId(option?.option?._id)
    setCityName('')
    getStateData(countryId, true)
    getCityData(countryId, option?.option?._id, true)

    const isMatching = stateList.some(option =>
      option.name.toLowerCase().includes(data.toLowerCase())
    )

    setIsSearchActive(isMatching)
  }

  const onCityChange = (data, option) => {
    form.setFieldsValue({
      city: data,
      cityId: option?.option?._id,
    })
    setCityName(data)
    setPermCityId(option?.option?._id)
    getCityData(countryId, stateId, true)

    const isMatching = cityList.some(option =>
      option.name.toLowerCase().includes(data.toLowerCase())
    )

    setIsSearchActive(isMatching)
  }

  const onCountryChange = (data, option) => {
    form.setFieldsValue({
      country: data,
      state: '',
      city: '',
      countryId: option?.option?._id,
      stateId: '',
      cityId: '',
    })
    setCountryName(data)
    setCountryId(option?.option?._id)
    setStateName('')
    setStateId('')
    setCityName('')
    setSearchCountry('')
    getStateData(option?.option?._id, true)

    const isMatching = countryList.some(option =>
      option.name.toLowerCase().includes(data.toLowerCase())
    )

    setIsSearchActive(isMatching)
  }

  const onUniversityChange = (data, option) => {
    setUniversityId(option?.option?.id)
    setUniversityName(data)
    form.setFieldsValue({ universityName: data })
    let payLoad = { ...searchConfig.universities, q: '' }
    dispatch(getMasterSearchApi('universities', payLoad))
  }

  const onClear = () => {
    setNewInstituteDrawer(false)
    setUniversityName('')
    if (isSystemConfig) {
      form.resetFields()
      setView(false)
    } else {
      form.resetFields()
    }
  }
  const onSubmitFormData = async formData => {
    let instituteDetails = {
      institute: {
        name: formData.name,
        tier: formData.tier,
        ranking: formData.ranking,
        universityId: universityId,
      },
      instituteCampus: {
        campusName: formData.name,
        campusLocation: formData.campusLocation,
        addressLine1: formData.addressLine1,
        postalCode: formData.postalCode,
        website: formData.website,
        contactPerson: formData.contactPerson,
        contactPersonRole: formData.contactPersonRole,
        contactNumber: formData.contactNumber,
        secondaryContactNumber: formData.secondaryContactNumber,
        contactEmail: formData.contactEmail,
        secondaryContactEmail: formData.secondaryContactEmail,
        city: formData.city,
        state: formData.state,
        cityId: cityId,
        stateId: stateId,
        country: formData.country,
        countryId: countryId,
        countryCode: countryCode,
        shortName: formData.shortName,
      },
    }
    handleSubmitCongif(instituteDetails, 'post')
    setNewInstituteDrawer(false)
    setUniversityName('')
  }
  useEffect(() => {
    if (addContent) {
      form.resetFields()
      getCityData('', '', true)
      form.setFieldsValue({
        name: '',
        website: '',
        country: '',
        state: '',
        stateId: '',
        cityId: '',
        city: '',
      })
      setStateName('')
      setStateId('')
      getStateData(COUNTRY_ID, true)
    } else if (universityValue) {
      setCountryName(universityValue?.instituteCampus?.[0]?.country)
      setCountryId(universityValue?.instituteCampus?.[0]?.countryId)
      setStateName(universityValue?.instituteCampus?.[0]?.state)
      setStateId(universityValue?.instituteCampus?.[0]?.stateId)
      setCityName(universityValue?.instituteCampus?.[0]?.city)
      setPermCityId(universityValue?.instituteCampus?.[0]?.cityId)
      getStateData(universityValue?.instituteCampus?.[0]?.countryId, true)
      getCityData(
        universityValue?.instituteCampus?.[0]?.countryId,
        universityValue?.instituteCampus?.[0]?.stateId,
        true
      )
    }
  }, [universityValue, newInstituteDrawer, addContent])
  function handleKeyPress(e) {
    setIsSearchActive(false)
  }

  const content = () => {
    return (
      <div style={{ pointerEvents: view ? 'none' : '' }}>
        <Form
          scrollToFirstError
          onFinish={onSubmitFormData}
          id={formId}
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <InstitutesDetails
            cityList={cityList}
            countryList={countryList}
            stateList={stateList}
            dateFormat={dateFormat}
            onCountryChange={onCountryChange}
            countryValue={countryName}
            stateValue={stateName}
            cityValue={cityName}
            countryId={countryId}
            stateId={stateId}
            getStateData={getStateData}
            getCityData={getCityData}
            setSearchCountry={setSearchCountry}
            onStateChange={onStateChange}
            onCityChange={onCityChange}
            isSystemConfig={true}
            form={form}
            onKeyPress={handleKeyPress}
            setUniversityName={setUniversityName}
            universityValue={universityName}
            universityList={universityList}
            onUniversityChange={onUniversityChange}
            loadingCountry={loadingCountry}
            loadingState={loadingState}
            loadingCity={loadingCity}
            setLoadingCountry={setLoadingCountry}
            setLoadingState={setLoadingState}
            setLoadingCity={setLoadingCity}
          />
          <ContactInstituteDetails
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            form={form}
          />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary
          text="Cancel"
          onClick={() => {
            onClear()
          }}
        />
        <Button.Primary
          htmlType="submit"
          form={formId}
          text={sendButtonText}
          disabled={view} // Disable if view is true or search input is not empty
          icon={<CheckIconWhite />}
        />
      </FlexWrap>
    )
  }
  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClear}
      visible={newInstituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddNewCollegeDrawer
