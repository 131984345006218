import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { Wrapper } from 'modules/Users/Partials/UsersFilter/Styles/style'

const UserRoleFilter = ({ userRole, UserRoleValue, setUserRoleValue }) => {
  const handleChange = selectedValues => {
    setUserRoleValue(selectedValues)
  }

  return (
    <Wrapper column>
      <FilterCheckBoxComp
        value={UserRoleValue ? [...UserRoleValue] : []}
        options={userRole.map(role => ({ id: role.id, name: role.name }))}
        valueParam={'name'}
        labelParam={'name'}
        padding={'10px !important'}
        onChange={handleChange}
      />
    </Wrapper>
  )
}

export default UserRoleFilter
