import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #FAFAFB;
    font-family: 'Plus Jakarta Sans';
    style:normal;
    weight:400;
    size:11px;
    line-height:16px;
    overflow: hidden;
  }
`

export default GlobalStyle
