import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { FlexWrap } from 'components/UIComponents/FormStyles'
import { Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import InstContactDetails from 'modules/Onboarding/Partials/Institutes/Partials/AddInstDrawer/Partials/InstContactDetails'
import InstDetails from 'modules/Onboarding/Partials/Institutes/Partials/AddInstDrawer/Partials/InstDetails'
import { saveInstituteFormData } from 'modules/Onboarding/Partials/Institutes/actions'
import {
  getListOfCity,
  getListOfCounties,
  getListOfState,
  getMasterSearchApi,
} from 'modules/Onboarding/actions'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchConfig } from '../../../../../../utils/config'

const AddInstDrawer = ({
  form,
  instituteClear,
  instituteDrawer,
  setInstituteDrawer,
  setInstitutePlanDrawer,
  setCollegeID,
  collegeID,
  formId = 'institute_form',
  title = 'Add Institutes',
  sendButtonText = 'Next',
  dateFormat,
}) => {
  const [searchCountry, setSearchCountry] = useState('')
  const dispatch = new useDispatch()

  useEffect(() => {
    dispatch(getListOfCounties())
  }, [])

  const countryList = useSelector(state => state?.CorporateData?.countryList)
  const universityList = useSelector(
    state => state?.CorporateData?.masterSearchApi
  )
  const [universityName, setUniversityName] = useState('')

  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [countryId, setCountryId] = useState('')
  const [stateId, setStateId] = useState('')

  const onUniversityChange = (data, option) => {
    setUniversityName(data)

    form.setFieldsValue({
      universityName: data,
      universityId: option?.option?.id,
    })
    handleUniversities()
  }

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await dispatch(getListOfState(countryId, search))
      return setStateList(list || []) 
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    if (countryId && stateId) {
      let list = await dispatch(getListOfCity(countryId, stateId, search))
      return setCityList(list || []) 
    }
  }

  useEffect(() => {
    let getData
    getData = setTimeout(() => {
      dispatch(getListOfCounties(searchCountry))
    }, 500)
    return () => clearTimeout(getData)
  }, [searchCountry])

  const delayCountryToFetch = useCallback(
    debounce(data => {
      dispatch(getListOfCounties(data))
      form.setFieldsValue({
        country: '',
        countryId: '',
      })
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const onClear = () => {
    setCountryName('')
    setStateName('')
    setCityName('')
    setUniversityName('')

    form.resetFields()
    setInstituteDrawer(false)
    instituteClear()
  }

  const handleUniversities = () => {
    let payLoad = { ...searchConfig.universities, q: '' }
    dispatch(getMasterSearchApi('universities', payLoad))
  }

  useEffect(() => {
    handleUniversities()
  }, [])

  const onStateChange = (data, option) => {
    form.setFieldsValue({
      state: data,
      city: '',
      stateId: option?.option?._id,
      cityId: '',
    })
    setStateName(data)
    setStateId(option?.option?._id)
    setCityName('')
    getStateData(form.getFieldValue(['countryId']), true)
    getCityData(form.getFieldValue(['countryId']), option?.option?._id, true)
  }

  const onCityChange = (data, option) => {
    form.setFieldsValue({
      city: data,
      cityId: option?.option?._id,
    })
    setCityName(data)

    getCityData(
      form.getFieldValue(['countryId']),
      form.getFieldValue(['stateId']),
      true
    )
  }

  const onCountryChange = (data, option) => {
    form.setFieldsValue({
      country: data,
      state: '',
      city: '',
      countryId: option?.option?._id,
      stateId: '',
      cityId: '',
    })
    form.resetFields(['state', 'city'])

    setCountryName(data)
    setCountryId(option?.option?._id)
    setStateName('')
    setStateId('')
    setCityName('')
    setSearchCountry('')
    getStateData(option?.option?._id, true)
  }

  const onSubmitFormData = async InstituteFormData => {
    const InstituteFormDataNew = {
      ...InstituteFormData,
      universityId: form.getFieldValue(['universityId']),
      stateId: form.getFieldValue(['stateId']),
      cityId: form.getFieldValue(['cityId']),
      countryId: form.getFieldValue(['countryId']),
      website: InstituteFormData?.website
        ? /^https?:\/\//i.test(InstituteFormData?.website)
          ? InstituteFormData?.website
          : `https://${InstituteFormData?.website}`
        : null,
    }
    dispatch(saveInstituteFormData(InstituteFormDataNew))
    setInstituteDrawer(false)
    setInstitutePlanDrawer(true)
    setCountryName('')
    setStateName('')
    setCityName('')
    setUniversityName('')
  }

  const content = () => {
    return (
      <div>
        <Form
          scrollToFirstError
          onFinish={onSubmitFormData}
          id={formId}
          form={form}
          layout="vertical"
        >
          <InstDetails
            onUniversityChange={onUniversityChange}
            delayCountryToFetch={delayCountryToFetch}
            form={form}
            universityList={universityList}
            cityList={cityList}
            countryList={countryList}
            stateList={stateList}
            dateFormat={dateFormat}
            onCountryChange={onCountryChange}
            universityValue={universityName}
            setUniversityName={setUniversityName}
            countryValue={countryName}
            stateValue={stateName}
            cityValue={cityName}
            countryId={countryId}
            stateId={stateId}
            getStateData={getStateData}
            getCityData={getCityData}
            setSearchCountry={setSearchCountry}
            onStateChange={onStateChange}
            onCityChange={onCityChange}
            setCollegeID={setCollegeID}
            collegeID={collegeID}
          />

          <InstContactDetails form={form} />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClear} />
        <Button.Primary htmlType="submit" form={formId} text={sendButtonText} />
      </FlexWrap>
    )
  }
  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClear}
      visible={instituteDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddInstDrawer
