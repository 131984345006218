import styled from 'styled-components'
import { COLORS } from 'theme'
import Flex from 'components/Flex'
import { FONT } from 'theme/font'
import { Spin } from 'antd'

export const PaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 25px;
`
export const SectionWrapper = styled(Flex)`
  width: 100%;
  // overflow: scroll;
  background: ${COLORS.WHITE};
  line-height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.35);
  border: 1px solid ${COLORS.PAGE_BORDER};
  &::-webkit-scrollbar {
    display: none;
  }
`
export const TopHeader = styled(Flex)`
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: ${FONT.F_XL};
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const CardRow = styled(Flex)`
  gap: 25px;
`
export const EmptyWrapper = styled(Flex)`
  margin: 50px 0px;
  margin: ${props => props?.isSpin && '102px 0px'};
`
export const Spinner = styled(Spin)`
  padding-top: 30%;
  position: absolute;
`
export const TableDiv = styled.div`
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_XS};
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
`
