import React from 'react'

const CountryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      style={{ margin: 'auto' }}
    >
      <path
        d="M23.4615 10.0022L16.0926 7.69945C15.9519 7.65554 15.8014 7.65301 15.6593 7.69216L12.0594 8.68618C11.9429 8.87731 11.8216 9.0719 11.6917 9.2727C11.3843 9.74768 11.0656 10.2088 10.7563 10.6388L12.1127 10.2642V12.619L7.97379 13.7538L2.90955 12.2319L3.40872 9.77324L4.78876 10.1041C4.60185 9.83434 4.41464 9.55624 4.23149 9.27331C3.99845 8.91331 3.78783 8.56936 3.59793 8.23999L2.99353 8.09514C2.79104 8.04655 2.57757 8.0824 2.40195 8.19439C2.2264 8.30631 2.10382 8.48477 2.06237 8.68879L0.0155966 18.7698C-0.0676099 19.1795 0.192372 19.5803 0.600268 19.6717L8.14819 21.3604C8.27407 21.3885 8.40517 21.3845 8.52914 21.3486L14.3385 19.6675L22.5262 21.8144C22.5904 21.8313 22.6558 21.8395 22.7208 21.8395C22.8824 21.8395 23.0416 21.7885 23.1746 21.691C23.3612 21.5543 23.4761 21.3408 23.4876 21.1098L23.9993 10.7729C24.0164 10.4234 23.7954 10.1065 23.4615 10.0022ZM12.1128 18.7134L8.29134 19.8192L1.66982 18.3378L2.52614 14.1204L7.68556 15.6708C7.85643 15.722 8.04211 15.7244 8.21535 15.6773L12.1128 14.6087V18.7134ZM22.0009 20.0895L14.5232 18.1288C14.4594 18.112 14.3939 18.1037 14.3285 18.1037C14.2566 18.1037 14.1848 18.1138 14.1151 18.134L14.0317 18.1581V14.0828L14.5433 13.9425L22.221 15.6441L22.0009 20.0895ZM22.3172 13.7L14.7262 12.0174C14.575 11.9841 14.4146 11.988 14.2653 12.0289L14.0318 12.0929V9.73432L15.8504 9.23217L22.4366 11.2904L22.3172 13.7Z"
        fill="#3249D7"
      />
      <path
        d="M7.95734 12.1445C8.07263 12.1445 8.18171 12.0927 8.25463 12.0035C9.2386 10.7987 12.4492 6.70793 12.4492 4.65241C12.4492 2.17564 10.4342 0.160645 7.95742 0.160645C5.48072 0.160645 3.46573 2.17564 3.46573 4.65241C3.46573 6.70909 6.67616 10.799 7.66013 12.0035C7.73298 12.0928 7.84213 12.1445 7.95734 12.1445ZM6.42225 4.39542C6.42225 3.54893 7.11092 2.86025 7.95742 2.86025C8.80392 2.86025 9.49259 3.54893 9.49259 4.39542C9.49259 5.24192 8.80392 5.9306 7.95742 5.9306C7.11092 5.9306 6.42225 5.24192 6.42225 4.39542Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default CountryIcon
