import ExportButton from 'components/ExportButton/ExportButton.js'
import {
  DateFormat,
  filterData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import InstEmpTable from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/InstituteEmpListTable/index.js'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessage from 'utils/WarningMessage'
import { PaddedDiv, SectionWrapper, TopHeader, TopHeading } from './Style/style'
import { setCityList, setTierList, setUniversityList } from './actions'

const InstEmpanelledTable = ({
  getInstituteListForReport,
  getUniversityList,
  getCityList,
  getTierList,
  instituteListDataForReport,
  universityList,
  cityList,
  tierList,
  excelDataForReport,
  userData,
}) => {
  const dispatch = useDispatch()
  const [first, setFirst] = useState(true)
  const [loadingUniversity, setLoadingUniversity] = useState(false)
  const [sectorLoading, setSectorLoading] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [sortBy, setSortBy] = useState('')
  const [pageNum, setPageNum] = useState(1)
  const [filterDatas, setFilterData] = useState({})
  const [keySector, setKeySector] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(() => searchValue)
  const [stateCount, setStateCount] = useState(0)
  const [stateData, setStateData] = useState([])
  const [cityCount, setCityCount] = useState(0)
  const [cityPage, setCityPage] = useState(0)
  const [cityData, setCityData] = useState([])
  const [statePage, setStatePage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingInst, setLoadingInst] = useState(false)
  const [sectorValue, setSectorValue] = useState([])
  const [stateValue, setStateValue] = useState([])
  const [cityValue, setCityValue] = useState([])
  const [industryValue, setIndustryValue] = useState([])
  const [keyState, setKeyState] = useState('')
  const [keyCity, setKeyCity] = useState('')
  const [keyIndustry, setKeyIndustry] = useState('')
  const [createdAtStart, setCreatedAtStart] = useState('')
  const [createdAtEnd, setCreatedAtEnd] = useState('')
  const [debounced, setDebounced] = useState(() => keySector)
  const [debouncedIndustry, setDebouncedIndustry] = useState(() => keyIndustry)
  const [debouncedCity, setDebouncedCity] = useState(() => keyCity)
  const [clearDate, setClearDate] = useState(false)
  const [tooltip, setToolTip] = useState(false)
  console.log(setClearDate, 'kkkk')
  function hasData(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (hasData(filterDatas)) {
      if (pageNum) {
        getInstituteListForReport({
          pageSize: 10,
          pageNumber: pageNum - 1,
          setLoadingInst,
          searchValue,
          filterDatas,
          sortBy,
        })
      }
    }
  }, [pageNum])

  useEffect(() => {
    if (hasData(filterDatas)) {
      setFirst(false)
      getInstituteListForReport({
        pageSize: 10,
        pageNumber: 0,
        setLoadingInst,
        searchValue,
        filterDatas,
        sortBy,
      })
      setPageNum(1)
    }
  }, [debouncedSearch, filterDatas, sortBy])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(keySector)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keySector])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(searchValue)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedCity(keyCity)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyCity])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedIndustry(keyIndustry)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyIndustry])
  useEffect(() => {
    dispatch(setUniversityList([]))
    let flag = 'LI'
    setStatePage(0)
    let setLoading = setLoadingUniversity
    getUniversityList({ setLoading, keySector, flag })
  }, [debounced])

  useEffect(() => {
    dispatch(setCityList([]))
    let flag = 'LI'
    setCityPage(0)
    getCityList({ setLoading, keyCity, flag })
  }, [debouncedCity])

  useEffect(() => {
    dispatch(setTierList([]))
    let flag = 'LI'
    getTierList({ setLoading, keyIndustry, flag })
  }, [debouncedIndustry])
  const title = [
    { title: 'ID' },
    {
      title: 'Institute Name',
    },
    { title: 'University', width: { wch: 40 }, padding: { ppx: 100 } },
    { title: 'Tier', width: { wch: 30 } },
    { title: 'Emapnelled', width: { wch: 45 } },
    { title: 'City', width: { wch: 20 } },
    { title: 'TPO Person', width: { wch: 20 } },
    { title: 'Contact Email', width: { wch: 20 } },
    { title: 'Contact Number', width: { wch: 20 } },
  ]

  const tableData = excelDataForReport?.result?.map(item => [
    { value: item.prefix_id ? item.prefix_id : '-' },
    {
      value: item.name ? item.name : '-',
    },
    { value: item.university ? item.university : '-' },
    { value: item.tier?.toString() ? item.tier : '-' },
    { value: item.createdat ? item.createdat?.split('/').join('-') : '-' },
    { value: item.city ? item.city : '-' },
    { value: item.contact_person ? item.contact_person : '-' },
    { value: item.contact_email ? item.contact_email : '-' },
    { value: item.contact_number ? item.contact_number : '-' },
  ])

  const excelData = [{ columns: title, data: tableData }]
  const [isExcel, setIsExcel] = useState('')
  const [excel, setExcel] = useState([])
  const clickedExcel = data => {
    let excel = true
    getInstituteListForReport({
      pageSize: 5000,
      pageNumber: 0,
      setLoadingInst,
      searchValue,
      filterDatas,
      sortBy,
      excel,
    })
    setIsExcel(data)
  }
  useEffect(() => {
    if (
      (excelDataForReport?.result && tableData && isExcel == 'excel') ||
      (excelDataForReport?.result && tableData && isExcel == 'pdf')
    ) {
      if (loadingInst) {
        setExcel([])
      } else {
        setExcel(excelData)
      }
    }
  }, [excelDataForReport?.result, loadingInst])
  const handleHover = () => {
    if (instituteListDataForReport?.count > 5000) {
      WarningMessage(
        'Note : The Institute count should not exceed 5,000 for data export.  Apply filters if necessary'
      )
    }
  }
  const loadMoreStateData = async () => {
    let flag = 'LI'
    let page = universityList?.page + 1
    let setLoading = setLoadingUniversity
    getUniversityList({ setLoading, keyState, flag, page })

    setStatePage(universityList?.page + 1)
  }
  const loadMoreCityData = async () => {
    let flag = 'LI'
    let page = cityList?.page + 1
    getCityList({ setLoading, keyCity, flag, page })

    setCityPage(cityPage + 1)
  }

  useEffect(() => {
    if (universityList) {
      setStateCount(universityList?.count)
      let data = filterData(universityList?.result, 'university')
      setStateData(data)
    }
    if (cityList) {
      setCityCount(cityList?.count)
      let data = filterData(cityList?.result, 'city')
      setCityData(data)
    }
  }, [universityList, cityList])

  const columnWidths = [17, 54, 44, 18, 24, 28, 35, 42, 25] // Array of column widths

  const HAlign = [
    'center',
    'left',
    'left',
    'center',
    'left',
    'left',
    'left',
    'left',
    'center',
  ]

  return (
    <SectionWrapper column>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>List of Inst. Empanelled</TopHeading>
          <TopHeading flexEnd style={{ display: 'flex', gap: 10 }}>
            <div role="button" tabIndex="0" onMouseEnter={handleHover}>
              <ExportButton
                dataset={excel}
                title={'List of Inst. Empanelled'}
                loading={loading}
                clickedExcel={clickedExcel}
                data={isExcel}
                setIsExcel={setIsExcel}
                count={instituteListDataForReport?.count || 0}
                columnWidth={columnWidths} // Array of column widths
                columnFontSize={10} // Font size for the column data
                horizontalAlignment={HAlign}
                userData={userData}
              />
            </div>
          </TopHeading>
        </TopHeader>
      </PaddedDiv>

      <InstEmpTable
        loadingUniversity={loadingUniversity}
        cityCount={cityCount}
        cityPage={cityPage}
        stateCount={stateCount}
        statePage={statePage}
        loadMoreStateData={loadMoreStateData}
        loadMoreCityData={loadMoreCityData}
        sectorLoading={sectorLoading}
        stateLoading={stateLoading}
        setSectorLoading={setSectorLoading}
        setStateLoading={setStateLoading}
        hasProp={hasData(filterDatas)}
        loadingInst={loadingInst}
        tableData={first ? [] : instituteListDataForReport}
        loading={loading}
        isEmpanelled={true}
        currentPage={pageNum}
        setCurrentPage={setPageNum}
        setPageNum={setPageNum}
        setSortBy={setSortBy}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        open={open}
        industryList={stateData}
        cityList={cityData}
        sectorList={filterData(tierList?.result, 'tier')}
        sectorValue={sectorValue}
        setSectorValue={setSectorValue}
        setFilterData={setFilterData}
        filterData={filterDatas}
        isReport={true}
        industryValue={industryValue}
        setIndustryValue={setIndustryValue}
        cityValue={cityValue}
        stateValue={stateValue}
        setCityValue={setCityValue}
        setStateValue={setStateValue}
        setKeySector={setKeySector}
        keySector={keySector}
        setKeyCity={setKeyCity}
        setKeyIndustry={setKeyIndustry}
        setKeyState={setKeyState}
        keyState={keyState}
        keyIndustry={keyIndustry}
        keyCity={keyCity}
        createdAtStart={createdAtStart}
        createdAtEnd={createdAtEnd}
        setCreatedAtStart={setCreatedAtStart}
        setCreatedAtEnd={setCreatedAtEnd}
        setClearDate={setClearDate}
        clearDate={clearDate}
        setToolTip={setToolTip}
        tooltip={tooltip}
        getCityList={getCityList}
        setLoading={setLoading}
        getUniversityList={getUniversityList}
        getTierList={getTierList}
        setTierList={setTierList}
      />
    </SectionWrapper>
  )
}
export default InstEmpanelledTable
