/* eslint-disable react/jsx-key */
import { Tabs } from 'antd'
import Button from 'components/Button/index'
import InfoCards from 'components/InfoCards'
import { StyledTabs } from 'components/TabsPaneStyles/index.js'
import ActiveUser from 'components/icons/ActiveUsers.js'
import Inactive from 'components/icons/InactiveUsers'
import MailInvite from 'components/icons/MailInvite.js'
import PlusIcon from 'components/icons/PlusIcon'
import TotalUser from 'components/icons/TotalUsers'
import Users from 'modules/Dashboard'
import UsersFilter from 'modules/Dashboard/Partials/UsersFilter'
import UsersTable from 'modules/Dashboard/Partials/UsersTable'
import {
  CardRow,
  HeadTitle,
  PaddedDiv,
  PageTitle,
  SectionWrapper,
  TabDiv,
  TopHeader,
  TopHeading,
} from 'modules/Dashboard/Styles/style'
import Roles from 'modules/Roles'
import React from 'react'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

const Dashboard = Props => {
  const userdata = [
    { id: 1, icon: <TotalUser />, text: 'Total Users', value: 30 },
    { id: 2, icon: <ActiveUser />, text: 'Active', value: 15 },
    { id: 3, icon: <Inactive />, text: 'Inactive', value: 3 },
    { id: 4, icon: <MailInvite />, text: 'Pending Invites', value: 2 },
  ]

  return (
    <SectionWrapper column>
      <PageTitle>
        <HeadTitle>User Management</HeadTitle>
      </PageTitle>
      <TabDiv>
        <StyledTabs defaultActiveKey={'User'} onChange={'/Users'}>
          <TabPane tab="User" key="User">
            <Link to="/" component={Users} />
          </TabPane>

          <TabPane tab="Roles and Permissions" key="roles" onchange={'/Roles'}>
            <Link to="/roles" component={Roles} />
          </TabPane>
        </StyledTabs>
      </TabDiv>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>Users</TopHeading>
          <Button.Primary
            height={'38px'}
            text={'New User'}
            icon={<PlusIcon />}
            onClick={'/NewUserDrawer '}
          />
        </TopHeader>
      </PaddedDiv>
      <PaddedDiv>
        {userdata?.map(card => (
          <CardRow key={card.id}>
            <InfoCards
              width={'25%'}
              height="130px"
              icon={card.icon}
              text={card.text}
              value={card.value}
              // eslint-disable-next-line no-undef
              onClick={() => onCardClick(card.text)}
            />
          </CardRow>
        ))}
      </PaddedDiv>
      <UsersFilter />
      <UsersTable />
    </SectionWrapper>
  )
}

export default Dashboard
