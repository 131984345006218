import selector from 'modules/Auth/selectors'
import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import adminRequest from 'utils/adminRequest'
import authRequest from 'utils/authRequest'
import corporateRequest from 'utils/corporateRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import { getRoleName } from '../../utils/getRoleName'

export const SET_USER_CORPORATES_LIST = 'SET_USER_CORPORATES_LIST'
export const SET_SINGLE_CORPORATES_DATA = 'SET_SINGLE_CORPORATE_DATA'
export const SET_ACTIVE_CORPORATE_DATA = `SET_ACTIVE_CORPORATE_DATA`
export const setUserCorporatesList = data => {
  return {
    type: SET_USER_CORPORATES_LIST,
    data,
  }
}

export const setSingleCorporateData = data => {
  return {
    type: SET_SINGLE_CORPORATES_DATA,
    data,
  }
}
export const setActiveCorporateData = data => {
  return {
    type: SET_ACTIVE_CORPORATE_DATA,
    data,
  }
}
export const setCitiesWithPagination = data => {
  return {
    type: 'SET_CITY_WITH_PAGINATION',
    data,
  }
}

export const getCitiesWithPagination =
  (search, pageNumber) => async dispatch => {
    let searchValue = search ? `&search=${search}` : ''
    let pageNum = pageNumber ? `&currentPage=${pageNumber}` : ''
    // return new Promise(async resolve => {
    try {
      const data = await authRequest.get(
        `/search/cities?pageLimit=50${searchValue}${pageNum}`
      )
      dispatch(
        setCitiesWithPagination(
          data?.data?.data || {
            cities: [],
            totalPages: 0,
            currentPage: 0,
          }
        )
      )
      // resolve(data?.data?.data)
      return data?.data?.data
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      // resolve(error)
      return error
    }
    // })
  }

export const getUserCorporatePortalsList =
  ({
    page = 0,
    search,
    sort,
    orderBy,
    corporateStatus,
    stateNames,
    cityName,
    corporateActiveStatus,
    setLoading,
    pageLimit = 10,
    isRankingEnabled = false,
    stateIds,
    cityIds,
  }) =>
  async (dispatch, getState) => {
    let pageKey = `pageLimit=${pageLimit}&currentPage=${page}`
    let searchKey = search ? `&search=${search}` : ''
    let isRanking = isRankingEnabled ? `&isRankingActive=false` : ''
    let sortKey = sort ? `&sort=${sort}` : ''
    let orderKey = orderBy && sort ? `&orderBy=${orderBy}` : ''
    const State = stateNames !== '' && stateNames ? `&state=${stateNames}` : ``
    const City = cityName !== '' && cityName ? `&city=${cityName}` : ``
    const StateId = stateIds?.length ? `&state=${stateIds}` : ``
    const CityId = cityIds?.length ? `&cityId=${cityIds}` : ``
    let statusFilterKey =
      corporateStatus?.length > 0 ? `&status=${corporateStatus}` : ''
    let activeFilterKey =
      typeof corporateActiveStatus == 'boolean'
        ? `&isActive=${corporateActiveStatus}`
        : ''
    if (setLoading) {
      setLoading(true)
    }
    const state = getState()
    const userId = selector.getUserData(state)?.pluginlive_id
    const userRoleId = selector.getUserData(state)?.admin_role_id
    const roleList = selector?.getRoleList(state)
    const userRole = getRoleName(roleList, userRoleId)
    try {
      if (userId) {
        if (userRole?.toLocaleUpperCase() !== 'ADMIN') {
          const data = await adminRequest.get(
            `/users/${userId}/CORPORATE?${pageKey}${sortKey}${orderKey}${searchKey}${statusFilterKey}${activeFilterKey}`
          )
          dispatch(setUserCorporatesList(data?.data?.data || []))
        } else {
          const data = await elasticSearchRequest.get(
            `corporates/list?${pageKey}${sortKey}${orderKey}${searchKey}${statusFilterKey}${activeFilterKey}${State}${City}${isRanking}${StateId}${CityId}`
          )
          dispatch(setUserCorporatesList(data?.data?.data || []))
        }
        if (setLoading) setLoading(false)
      } else {
        ErrorMessage('No PluginLive Id found for this User !')
        if (setLoading) setLoading(false)
      }
    } catch (error) {
      ErrorMessage(error?.response?.data?.message || 'Something went wrong')

      if (setLoading) setLoading(false)
    }
  }

export const getActiveCorporatePortalsList =
  ({
    page = 0,
    search,
    sort,
    orderBy,
    corporateStatus,
    stateNames,
    cityName,
    corporateActiveStatus,
    setLoading,
    pageLimit = 10,
    stateIds,
    cityIds,
  }) =>
  async (dispatch, getState) => {
    let pageKey = `pageLimit=${pageLimit}&currentPage=${page}`
    let searchKey = search ? `&search=${search}` : ''
    let sortKey = sort ? `&sort=${sort}` : ''
    let orderKey = orderBy && sort ? `&orderBy=${orderBy}` : ''
    const State = stateNames !== '' && stateNames ? `&state=${stateNames}` : ``
    const City = cityName !== '' && cityName ? `&city=${cityName}` : ``
    let statusFilterKey =
      corporateStatus?.length > 0 ? `&status=${corporateStatus}` : ''
    let activeFilterKey =
      typeof corporateActiveStatus == 'boolean'
        ? `&isActive=${corporateActiveStatus}`
        : ''
    const StateId = stateIds?.length ? `&stateId=${stateIds}` : ``
    const CityId = cityIds?.length ? `&cityId=${cityIds}` : ``
    let isRankingEnabled = `&isRankingActive=true`
    if (setLoading) {
      setLoading(true)
    }
    const state = getState()
    const userId = selector.getUserData(state)?.pluginlive_id
    const userRoleId = selector.getUserData(state)?.admin_role_id
    const roleList = selector?.getRoleList(state)
    const userRole = getRoleName(roleList, userRoleId)
    try {
      if (userId) {
        if (userRole?.toLocaleUpperCase() !== 'ADMIN') {
          const data = await adminRequest.get(
            `/users/${userId}/CORPORATE?${pageKey}${sortKey}${orderKey}${searchKey}${statusFilterKey}${activeFilterKey}`
          )
          dispatch(setUserCorporatesList(data?.data?.data || []))
        } else {
          const data = await elasticSearchRequest.get(
            `corporates/list?${pageKey}${sortKey}${orderKey}${searchKey}${statusFilterKey}${activeFilterKey}${State}${City}${isRankingEnabled}${StateId}${CityId}`
          )
          dispatch(setActiveCorporateData(data?.data?.data || []))
        }
        if (setLoading) setLoading(false)
      } else {
        ErrorMessage('No PluginLive Id found for this User !')
        if (setLoading) setLoading(false)
      }
    } catch (error) {
      ErrorMessage(error?.response?.data?.message || 'Something went wrong')

      if (setLoading) setLoading(false)
    }
  }

export const getSingleCorporateData =
  (corporateId, callBack, setViewCorporateDrawer) =>
  async (dispatch, getState) => {
    try {
      const res = await adminRequest.get(`/corporate/${corporateId}`)
      const state = getState()

      const email = selector?.getUserData(state)?.email

      const journey = 'CORPORATE'

      let payLoad = {
        email,
        journey,
        journeyId: corporateId,
      }
      let corpoareData = res?.data?.data
      try {
        const data = await authRequest.post(`/user/portal/signin`, payLoad)

        if (data.status === 200) {
          corpoareData.redirectLink = data?.data?.data?.redirectLink
          setViewCorporateDrawer(true)
        }
      } catch (error) {
        corpoareData.error = error?.response?.data?.message
      }
      dispatch(setSingleCorporateData(corpoareData || {}))
      return true
    } catch (error) {
      if (callBack) {
        callBack()
      }
    }
  }

export const userPortalSwitchingToken =
  ({ journeyId }, setLoading) =>
  async (dispatch, getState) => {
    try {
      const state = getState()

      const email = selector?.getUserData(state)?.email

      const journey = 'CORPORATE'

      let payLoad = {
        email,
        journey,
        journeyId,
      }
      if (setLoading) {
        setLoading(true)
      }
      const data = await authRequest.post(`/user/portal/signin`, payLoad)
      if (data.status === 200) {
        setLoading(false)
        SuccessMessage(data?.data?.message)
        return data
        // dispatch(setSingleCorporateData(data?.data?.data || []))
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      ErrorMessage(error?.response?.data?.message)
    }
  }
export const getCorporateBasedPlaces =
  ({ pageLimit = 50, groupBy = 'city', search = '', stateId = '' }) =>
  async () => {
    try {
      const response = await elasticSearchRequest.get(
        `/corporate/location?pageLimit=${pageLimit}&groupBy=${groupBy}&search=${search}&stateId=${stateId}`
      )
      if (response?.data?.message === 'Success') {
        return response?.data?.data
      }
    } catch (err) {
      return err
    }
  }
