import Button from 'components/Button/index'
import CheckIconWhite from 'components/icons/CheckIconWhite'
import TableSearchFilter from 'components/UIComponents/TableSearchFilter'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Dashboard/Partials/UsersFilter/Styles/style'
import React, { useEffect, useState } from 'react'
const InstituteFilter = ({
  searchValue,
  setSearchValue,
  list,
  items,
  submitSystemConfigFilter,
  useDebounce,
  menus,
  setCurrentPage,
  setPageNum,
  defaultFirstPageNumber,
  clearInput,
  listKey,
  isCurrentPageLabel,
  handleFilterCall,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [data, setData] = useState([])
  const submitFilter = () => {
    setIsMenuOpen(false)
    submitSystemConfigFilter('submit')
  }
  let currentCount

  if (menus === 'Screens') {
    currentCount =
      list?.count < 10 ? list?.count : list?.page * 10 + list?.screens?.length
  } else if (menus === 'Sub-Events') {
    currentCount =
      list?.count < 10 ? list?.count : list?.page * 10 + list?.subEvents?.length
  } else if (menus === 'Events') {
    currentCount =
      list?.count < 10 ? list?.count : list?.page * 10 + list?.events?.length
  } else if (isCurrentPageLabel === true) {
    currentCount =
      list?.count < 10
        ? list?.count
        : (list?.currentPage - 1) * 10 + list?.[listKey]?.length
  } else {
    currentCount =
      list?.count < 10 ? list?.count : list?.page * 10 + list?.result?.length
  }
  useEffect(() => {
    handleFilterCall()
  }, [isMenuOpen])
  const onCancelFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    // setData([])
    submitSystemConfigFilter('clear')
  }
  const onResetFilter = open => {
    setIsMenuOpen(!isMenuOpen)
    setData([])
    submitSystemConfigFilter('reset')
  }
  useEffect(() => {
    if (items) {
      let data = items?.filter(item => item.visible !== false)
      setData(data)
    }
  }, [items])
  const menu = (
    <MenuFlex column className="filter">
      <StyledTab
        style={{ width: '100%' }}
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={data}
      />
      <SubmitFlex right>
        <Button.Secondary text="Reset" onClick={() => onResetFilter(false)} />
        <Button.Secondary text="Cancel" onClick={() => onCancelFilter(false)} />
        <Button.Primary
          onClick={submitFilter}
          text="Apply Filter"
          icon={<CheckIconWhite />}
        />
      </SubmitFlex>
    </MenuFlex>
  )

  return (
    <TableSearchFilter
      placeholder={`Search by ${menus}`}
      style={{ width: '100%' }}
      menu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setCurrentPage={setCurrentPage}
      setPageNum={setPageNum}
      defaultFirstPageNumber={defaultFirstPageNumber}
      currentCount={currentCount}
      totalCount={list?.count}
      onOpenChange={onCancelFilter}
      useDebounce={useDebounce}
      menus={menus}
      clearInput={clearInput}
    ></TableSearchFilter>
  )
}

export default InstituteFilter
