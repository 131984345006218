import { connect } from 'react-redux'
import Users from 'modules/User/index'
import {
  getUserMetrics,
  getUserList,
  getRoleList,
  getEvent,
  createUser,
  createRoles,
  updateRoles,
  updateUser,
  getSingleUser,
  getSingleRole,
  updateUserStatus,
  deleteUser,
  setSingleUser,
  setSingleRole,
  getRoles,
  deleteRolesAndPermission,
} from '../actions'
import selectors from 'modules/User/selectors'
import { getUserRoles, getListOfCountries } from 'modules/Auth/actions'
import authSelector from 'modules/Auth/selectors'

const mapStateToProps = state => ({
  userMetrics: selectors?.getUserMetricsData(state),
  userList: selectors?.getUserListData(state),
  roleList: selectors?.getRoleListData(state),
  userRole: authSelector?.getUserRoleList(state),
  eventList: selectors?.getEventData(state),
  roles: selectors?.getRoleData(state),
  singleUserData: selectors?.getSingleUserData(state),
  singleRoleData: selectors?.getSingleRoleData(state),
  listOfCountries: authSelector?.getListOfCountries(state),
})

const mapDispatchToProps = {
  getUserMetrics,
  getUserList,
  getRoleList,
  getEvent,
  getUserRoles,
  createUser,
  createRoles,
  updateRoles,
  updateUser,
  getSingleUser,
  updateUserStatus,
  deleteUser,
  setSingleUser,
  setSingleRole,
  getRoles,
  getSingleRole,
  getListOfCountries,
  deleteRolesAndPermission,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
