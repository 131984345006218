import React from 'react'
import { useDispatch } from 'react-redux'
import Drawer from 'components/Drawer'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import {
  Heading,
  Para,
} from 'modules/Onboarding/Components/RegisterSuccessful/RegisterSuccessfulPage/style'
import Group from 'components/Images/Group.png'
import Flex from 'components/Flex'
import { PaddingDetails } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/VerifyCorporateDrawer/style'

const RequestSentDrawer = ({
  requestSentDrawer,
  setRequestSentDrawer,
  getCorporatesListData,
  currentPage,
}) => {
  const dispatch = useDispatch()
  const onClick = () => {
    setRequestSentDrawer(false)
    dispatch(
      getCorporatesListData({
      })
    )
  }

  const onClose = () => {
    setRequestSentDrawer(false)
    dispatch(
      getCorporatesListData({
        currentPage,
      })
    )
  }

  const content = () => {
    return (
    
        <Control alignCenter column center style={{ height: '100%' }}>
          <Flex>
            <img src={Group} alt='group'/>
          </Flex>
          <Flex column>
            <Heading center>Request Sent Successfully.</Heading>
            <Para>Request sent to update the onboarding form</Para>
          </Flex>
          <PaddingDetails />
          <Button.Secondary center text="Close" onClick={onClick} />
        </Control>
      
    )
  }

  return (
    <Drawer
      width="40%"
      title={'Request sent'}
      placement="right"
      padding="30px"
      headermargin="0 30px"
      headerpadding="16px 0"
      closable
      onClose={onClose}
      visible={requestSentDrawer}
      content={content()}
    />
  )
}

export default RequestSentDrawer
