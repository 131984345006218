const getEventListData = state => state?.EventCatalogue?.eventList
const getNotificationTypeData = state => state?.EventCatalogue?.notificationType
const getNotificationCatalogueData = state => state?.EventCatalogue?.notificationCatalogue
const getNotificationTypeByIdData = state => state?.EventCatalogue?.notificationTypeByID
const getAllEventTemplate = state => state?.EventCatalogue?.allEventTemplate

const selectors = {
  getEventListData,
  getNotificationTypeData,
  getNotificationCatalogueData,
  getNotificationTypeByIdData,
  getAllEventTemplate
}

export default selectors