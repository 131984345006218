import { COLORS } from 'theme'
import { Steps } from 'antd'
import EmptyCircle from 'components/icons/EmptyCircle'
import React from 'react'
import StepsCompleted from 'components/icons/StepsCompleted'
import StepsIcon from 'components/icons/StepsIcon'
import styled from 'styled-components'

const { Step } = Steps

const Div = styled.div`
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: ${COLORS.GREEN_S_100};
  }
`
const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 21px;
  color: ${props => props?.textcolor};
  white-space: nowrap;
`

const Description = styled.div`
  color: ${props => props?.textcolor};
`

const StyledStep = styled(Step)`
  .ant-steps-item-title {
    width: 57%;
    border-bottom: 1px solid #e6e7ea;
  }
`

const AntdSteps = ({
  steps,
  direction = 'vertical',
  size = 'small',
  current = '1',
  substep = '0',
  ...props
}) => {
  const IconComponent = ({ step, current }) => {
    return current > step?.activeKey ? (
      <StepsCompleted />
    ) : step?.activeKey == current ? (
      <StepsIcon />
    ) : (
      <EmptyCircle />
    )
  }
  return (
    <Div>
      <Steps direction={direction} size={size} current={current} {...props}>
        {steps?.map(step => (
          <StyledStep
            key={step?.id} // Use a unique identifier like "id" if available
            icon={<IconComponent step={step} current={current} />}
            title={
              <Title
                textcolor={step?.activeKey == current ? '#4D566E' : '#999FAC;'}
              >
                {step?.title}
              </Title>
            }
            description={step?.description?.map((item, index1) => (
              <Description
                key={item?.id}
                textcolor={index1 === substep ? '#4D566E' : '#999FAC;'}
              >
                {item}
              </Description>
            ))}
          />
        ))}
      </Steps>
    </Div>
  )
}

export default AntdSteps
