import {
  SET_USER_METRICS,
  SET_USER_LIST,
  SET_SINGLE_USER,
  SET_ROLE_LIST,
  SET_EVENT,
  SET_ROLE,
  SET_SINGLE_ROLE,
} from 'modules/User/actions'

const initialState = {
  userMetrics: {},
  userList: {},
  roleList: {},
  eventList: {},
  singleUserData: {},
  singleRoleData: {},
  roles: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_METRICS:
      return {
        ...state,
        userMetrics: action.data,
      }
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.data,
      }
    case SET_EVENT:
      return {
        ...state,
        eventList: action.data,
      }
    case SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.data,
      }
    case SET_SINGLE_USER:
      return {
        ...state,
        singleUserData: action.data,
      }
    case SET_SINGLE_ROLE:
      return {
        ...state,
        singleRoleData: action.data,
      }
    case SET_ROLE:
      return {
        ...state,
        roles: action.roles,
      }
    default:
      return state
  }
}
