const initialState = {
  eventList: [],
  notificationType: [],
  notificationCatalogue: [],
  notificationTypeByID: [],
  allEventTemplate: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EVENT_LIST':
      return {
        ...state,
        eventList: action.data,
      }
    case 'SET_NOTIFICATION_TYPE':
      return {
        ...state,
        notificationType: action.data,
      }
    case 'SET_NOTIFICATION_CATALOGUES':
      return {
        ...state,
        notificationCatalogue: action.data,
      }
      case 'SET_NOTIFICATION_TYPE_BY_ID':
        return {
          ...state,
          notificationTypeByID: action.data,
        }

        case 'SET_ALL_EVENT_TEMPLATE':
          return {
            ...state,
            allEventTemplate: action.data,
          }  
    default:
      return state
  }
}
