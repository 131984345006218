import React from 'react'
import { message } from 'antd'
import styled from 'styled-components'

const StyledText = styled.span`
  text-transform: capitalize;
`
const ErrorMessage = description =>
  message.error({
    content: <StyledText>{description}</StyledText>,
    className: 'custom-class',
    style: {
      marginTop: '50px',
      color: '#010E30',
      borderLeft: '5px solid red',
      transitionDuration: '5s',
    },
  })

export default ErrorMessage
