import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Modal } from 'antd'
import {
  ButtonText,
  ItemDeleteText,
  StyledDropDownButton,
} from 'components/ActionStyles/ActionStyles'
import DownArrow from 'components/icons/DownArrow'
import VerticalThreeDots from 'components/icons/VerticalThreeDots'
import { SmallButtons } from 'modules/Courses/Style/style'
import React from 'react'
import styled from 'styled-components'

const { confirm, warning } = Modal
const CustomMenu = styled(Menu)`
  width: 150px;
`

const ActionMenu = ({
  item,
  editClicked,
  deletedClicked,
  editCourse,
  setStatusIsActive,
  courseID,
  systemConfig,
  onViewClick,
  setAddCourse,
  isStudentLinked,
}) => {
  const onDelete = id => {
    deletedClicked(id)
  }
  const showConfirm = id => {
    !isStudentLinked
      ? confirm({
          title: 'Do you want to delete this course',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            onDelete(id)
          },
          onCancel() {},
          okText: 'Delete',
        })
      : warning({
          title:
            'This course cannot be deleted as there are previous transactions associated',
          icon: <ExclamationCircleOutlined />,
          onOk() {},

          okText: 'Ok',
        })
  }
  const menu = (
    <CustomMenu
      items={[
        {
          key: '1',
          label: (
            <div
              role="button"
              tabIndex="0"
              onClick={event => editClicked(event, item, '')}
              onKeyDown={() => {}}
            >
              {systemConfig ? 'View & Edit' : 'View & Edit'}
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div
              tabIndex="0"
              role="button"
              onClick={event => setStatusIsActive(event, item)}
              onKeyDown={() => {}}
            >
              {' '}
              {systemConfig
                ? item?.status === 1
                  ? 'Set as Inactive'
                  : 'Set as Active'
                : item?.status === 0
                ? 'Set as Active'
                : 'Set as Inactive'}
            </div>
          ),
        },

        {
          key: '3',
          hidden: systemConfig,
          label: <ItemDeleteText>Delete </ItemDeleteText>,
          onClick: () => showConfirm(courseID),
        },
      ].filter(item => !item.hidden)}
    />
  )

  return (
    <>
      {systemConfig ? (
        <StyledDropDownButton
          id="filterDropdown"
          onClick={event => editCourse(event, item)}
          overlay={menu}
          shape="round"
          trigger={['click']}
          icon={<DownArrow onClick={event => editCourse(event, item)} />}
          getPopupContainer={() => document.getElementById('filterDropdown')}
        >
          <ButtonText onClick={event => onViewClick(event, item)}>
            View
          </ButtonText>
        </StyledDropDownButton>
      ) : (
        <Dropdown
          id="filterDropdown"
          onClick={event => editCourse(event, item)}
          overlay={menu}
          trigger={['click']}
          getPopupContainer={() => document.getElementById('filterDropdown')}
        >
          <SmallButtons height={'38px'} icon={<VerticalThreeDots />} />
        </Dropdown>
      )}
    </>
  )
}
export default ActionMenu
