import React, { useEffect, useState } from 'react'
import Control from 'components/Control/Control'
import FileReader from 'components/Uploader/FileReader'
import Uploadloader from 'components/Uploader/Uploader'
import { Form } from 'antd'
import Label from 'components/Form/Label'
import {
  FormItem
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'

const InstDocumentDetails = ({ form }) => {
  const [instDocument, setInstDocument] = useState(null)
  const onChangeDocument = file => {
    form.setFieldsValue({ contractURL: file })
    setInstDocument(file)
  }
  const contractURL = Form.useWatch('contractURL', form)

  useEffect(() => {
    setInstDocument(contractURL)
  }, [contractURL])

  return (
    <>
      <FormItem
        name={'contractURL'}
        rules={[
          {
            required: false,
            message: 'Please upload Contract!',
          },
        ]}
      >
        <Label>Contract</Label>
        {instDocument ? (
          <Control column top="10px" center>
            <FileReader file={instDocument} setFile={onChangeDocument} />
          </Control>
        ) : (
          <Control column top="10px" center>
            <Uploadloader
              setFile={onChangeDocument}
              isDefault={true}
              fileName={'document'}
            />
          </Control>
        )}
      </FormItem>
      <br />
    </>
  )
}

export default InstDocumentDetails
