import React from 'react'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'

const StatusFilter = ({ setStatusList, value }) => {
  const options = [
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'InActive',
      value: 0,
    },

  ]
  const onChange = checkedValues => {
    setStatusList(checkedValues)
  }
  return (
    <FilterCheckBoxComp options={options} value={value} onChange={onChange} />
  )
}

export default StatusFilter
