import selector from 'modules/Auth/selectors'
import adminRequest from 'utils/adminRequest'
import authRequest from 'utils/authRequest'
import instituteRequest from 'utils/instituteRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import ErrorMessage from 'utils/ErrorMessage'
import { getRoleName } from '../../utils/getRoleName'

export const SET_USER_INSTITUTES_LIST = 'SET_USER_INSTITUTES_LIST'
export const SET_SINGLE_INSTITUTES_DATA = 'SET_SINGLE_INSTITUTES_DATA'

export const setUserInstitutesList = data => {
  return {
    type: SET_USER_INSTITUTES_LIST,
    data,
  }
}
export const setSingleInstituteData = data => {
  return {
    type: SET_SINGLE_INSTITUTES_DATA,
    data,
  }
}

export const getUserInstitutePortalsList =
  ({
    page,
    search,
    sort,
    orderBy,
    cityName,
    stateNames,
    instituteActiveStatus,
    instituteStatus,
    setLoading,
    stateId,
  }) =>
  async (dispatch, getState) => {
    let pageKey = `${page}`
    let searchKey = search ? `&search=${search}` : ''
    let sortKey = sort ? `&sort=${sort}` : ''
    let orderKey = orderBy && sort ? `&orderBy=${orderBy}` : ''
    const State = stateNames !== '' && stateNames ? `&state=${stateId}` : ``
    const City = cityName !== '' && cityName ? `&city=${cityName}` : ``
    let statusFilterKey =
      instituteStatus?.length > 0 ? `&status=${instituteStatus}` : ''
    let activeFilterKey =
      typeof instituteActiveStatus == 'boolean'
        ? `&isActive=${instituteActiveStatus}`
        : ''
    if (setLoading) {
      setLoading(true)
    }
    const state = getState()
    const userId = selector.getUserData(state)?.pluginlive_id
    const userRoleId = selector.getUserData(state)?.admin_role_id
    const roleList = selector?.getRoleList(state)
    const userRole = getRoleName(roleList, userRoleId)
    try {
      if (userId) {
        if (userRole?.toLocaleUpperCase() !== 'ADMIN') {
          const data = await adminRequest.get(
            `/users/${userId}/INSTITUTE?pageLimit=10&currentPage=${pageKey}${sortKey}${orderKey}${searchKey}${statusFilterKey}${activeFilterKey}`
          )
          dispatch(setUserInstitutesList(data?.data?.data || []))
        } else {
          const data = await elasticSearchRequest.get(
            `institutes?pageLimit=10&pageNo=${pageKey}${sortKey}${orderKey}${searchKey}${statusFilterKey}${activeFilterKey}${State}${City}`
          )
          dispatch(setUserInstitutesList(data?.data?.data || []))
        }
        if (setLoading) setLoading(false)
      } else {
        ErrorMessage('No PluginLive Id found for this User !')
        if (setLoading) setLoading(false)
      }
    } catch (error) {
      ErrorMessage(error?.response?.data?.message || 'Something went wrong')

      if (setLoading) setLoading(false)
    }
  }

export const getSingleInstituteData =
  (instituteId, callBack, setViewInstituteDrawer) =>
  async (dispatch, getState) => {
    try {
      const res = await adminRequest.get(`/institutes/${instituteId}`)
      const state = getState()

      const email = selector?.getUserData(state)?.email

      const journey = 'INSTITUTE'

      let payLoad = {
        email,
        journey,
        journeyId: instituteId,
      }
      let instituteData = res?.data?.data
      try {
        const data = await authRequest.post(`/user/portal/signin`, payLoad)

        if (data.status === 200) {
          instituteData.redirectLink = data?.data?.data?.redirectLink
          setViewInstituteDrawer(true)
        }
      } catch (error) {
        instituteData.error = error?.response?.data?.message
      }
      dispatch(setSingleInstituteData(instituteData || {}))
      return true
    } catch (error) {
      if (callBack) {
        callBack()
      }
    }
  }
