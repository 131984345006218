import { Form } from 'antd'
import Button from 'components/Button/index'
import Toggle from 'components/UIComponents/Toggle'
import PlusIcon from 'components/icons/PlusIcon'
import CountDetails from 'modules/Onboarding/Components/CountDetails'
import AddNewCorporateDrawer from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer'
import CorporateInfoTable from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable'
import LinkGenerateDrawer from 'modules/Onboarding/Partials/Corporates/Partials/LinkGenerateDrawer'
import PlanDrawer from 'modules/Onboarding/Partials/Corporates/Partials/PlanDrawer'
import PreviewDrawer from 'modules/Onboarding/Partials/Corporates/Partials/PreviewDrawer'
import UploadDocumentDrawer from 'modules/Onboarding/Partials/Corporates/Partials/UploadDocumentDrawer'
import AddInstDrawer from 'modules/Onboarding/Partials/Institutes/Partials/AddInstDrawer'
import InstituteDocumentDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstituteDocumentDrawer'
import InstituteInfoTable from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable'
import InstituteLinkGenerateDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstituteLinkGenerateDrawer'
import InstitutePlanDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstitutePlanDrawer'
import InstitutePreviewDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstitutePreviewDrawer'
import { getListOfCounties } from 'modules/Onboarding/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  PaddedDiv,
  SectionWrapper,
  TopHeader,
  TopHeading,
} from './Styles/style'

const Onboarding = () => {
  const dispatch = new useDispatch()
  useEffect(() => {
    dispatch(getListOfCounties())
  }, [])
  const [newCorporateDrawer, setNewCorporateDrawer] = useState(false)
  const [instituteDrawer, setInstituteDrawer] = useState(false)
  const [newPlanDrawer, setNewPlanDrawer] = useState(false)
  const [institutePlanDrawer, setInstitutePlanDrawer] = useState(false)
  const [newUploadDocumentDrawer, setNewUploadDocumentDrawer] = useState(false)
  const [newInstituteDocumentDrawer, setNewInstituteDocumentDrawer] =
    useState(false)
  const [newPreviewDrawer, setNewPreviewDrawer] = useState(false)
  const [institutePreviewDrawer, setInstitutePreviewDrawer] = useState(false)
  const [newLinkGenerateDrawer, setNewLinkGenerateDrawer] = useState(false)
  const [clearInput,setClearInput]=useState(false)
  const [instituteLinkGenerateDrawer, setInstituteLinkGenerateDrawer] =
    useState(false)
  const [current, setCurrent] = useState('Corporates')
  const initialModelState = {
    institute: false,
    corporate: false,
  }
  const [model, setModel] = useState(initialModelState)
  const [collegeID, setCollegeID] = useState('')

  const onClick = target => {
    if (!target) {
      setCurrent('Corporates')
    } else {
      setCurrent('Institutes')
    }
  }

  const corporateList = useSelector(
    state => state?.CorporateList?.corporatesList
  )
  const instituteList = useSelector(
    state => state?.InstituteList?.institutesList
  )
  const [corporateAddForm] = Form.useForm()
  const [corporatePlan] = Form.useForm()
  const [corporateContract] = Form.useForm()
  const [corporatePreview] = Form.useForm()
  const [corporateGenerateLink] = Form.useForm()

  const [instituteAddForm] = Form.useForm()
  const [institutePlan] = Form.useForm()
  const [instituteContract] = Form.useForm()
  const [institutePreview] = Form.useForm()
  const [instituteGenerateLink] = Form.useForm()

  const corporateClear = (clearModel = 1) => {
    corporateAddForm.resetFields()
    corporatePlan.resetFields()
    corporateContract.resetFields()
    corporatePreview.resetFields()
    corporateGenerateLink.resetFields()
    if (clearModel) {
      setModel(initialModelState)
    }
  }

  const instituteClear = (clearModel = 1) => {
    instituteAddForm.resetFields()
    institutePlan.resetFields()
    instituteContract.resetFields()
    institutePreview.resetFields()
    instituteGenerateLink.resetFields()
    if (clearModel) {
      setModel(initialModelState)
    }
  }

  return (
    <>
      <SectionWrapper column id="root-Container">
        <PaddedDiv column>
          <TopHeader row spaceBetween alignCenter>
            <TopHeading flexStart>Onboarding</TopHeading>
            {current === 'Corporates' ? (
              <Button.Primary
                height={'38px'}
                text={'New Corporate'}
                icon={<PlusIcon />}
                onClick={() => {
                  setNewCorporateDrawer(true)
                  setModel({ ...initialModelState, corporate: true })
                }}
              />
            ) : (
              <Button.Primary
                height={'38px'}
                text={'New Institute'}
                icon={<PlusIcon />}
                onClick={() => {
                  setInstituteDrawer(true)
                  setModel({ ...initialModelState, institute: true })
                }}
              />
            )}
          </TopHeader>
          <TopHeader row spaceBetween alignCenter>
            <TopHeading flexStart>
              <Toggle
                left="Corporates"
                right="Institutes"
                initialValue={current ? 'left' : 'right'}
                onClick={e => onClick(e)}
              />
            </TopHeading>

            <CountDetails
              {...(current === 'Corporates' ? corporateList : instituteList)}
            />
          </TopHeader>
        </PaddedDiv>

        {current === 'Corporates' ? (
          <CorporateInfoTable clearInput={clearInput}
          setClearInput={setClearInput}/>
        ) : (
          <InstituteInfoTable 
          clearInput={clearInput}
          setClearInput={setClearInput}/>
        )}
      </SectionWrapper>
      {model.corporate && (
        <>
          <AddNewCorporateDrawer
            newCorporateDrawer={newCorporateDrawer}
            setNewCorporateDrawer={setNewCorporateDrawer}
            setNewPlanDrawer={setNewPlanDrawer}
            form={corporateAddForm}
            corporateClear={corporateClear}
          />
          <PlanDrawer
            newPlanDrawer={newPlanDrawer}
            setNewPlanDrawer={setNewPlanDrawer}
            setNewUploadDocumentDrawer={setNewUploadDocumentDrawer}
            form={corporatePlan}
            setNewCorporateDrawer={setNewCorporateDrawer}
            corporateClear={corporateClear}
          />
          <UploadDocumentDrawer
            newUploadDocumentDrawer={newUploadDocumentDrawer}
            setNewUploadDocumentDrawer={setNewUploadDocumentDrawer}
            setNewPreviewDrawer={setNewPreviewDrawer}
            form={corporateContract}
            setNewPlanDrawer={setNewPlanDrawer}
            corporateClear={corporateClear}
          />
          <PreviewDrawer
            newPreviewDrawer={newPreviewDrawer}
            setNewPreviewDrawer={setNewPreviewDrawer}
            setNewLinkGenerateDrawer={setNewLinkGenerateDrawer}
            setNewUploadDocumentDrawer={setNewUploadDocumentDrawer}
            form={corporatePreview}
            corporateClear={corporateClear}
            setClearInput={setClearInput}
          />
          <LinkGenerateDrawer
            newLinkGenerateDrawer={newLinkGenerateDrawer}
            setNewLinkGenerateDrawer={setNewLinkGenerateDrawer}
            form={corporateGenerateLink}
            corporateClear={corporateClear}
          />
        </>
      )}

      {model.institute && (
        <>
          {' '}
          <AddInstDrawer
            instituteDrawer={instituteDrawer}
            setInstituteDrawer={setInstituteDrawer}
            setInstitutePlanDrawer={setInstitutePlanDrawer}
            form={instituteAddForm}
            instituteClear={instituteClear}
            setCollegeID={setCollegeID}
            collegeID={collegeID}
          />
          <InstitutePlanDrawer
            institutePlanDrawer={institutePlanDrawer}
            setInstitutePlanDrawer={setInstitutePlanDrawer}
            setNewInstituteDocumentDrawer={setNewInstituteDocumentDrawer}
            form={institutePlan}
            instituteClear={instituteClear}
            setInstituteDrawer={setInstituteDrawer}
          />
          <InstituteDocumentDrawer
            newInstituteDocumentDrawer={newInstituteDocumentDrawer}
            setNewInstituteDocumentDrawer={setNewInstituteDocumentDrawer}
            setInstitutePreviewDrawer={setInstitutePreviewDrawer}
            form={instituteContract}
            instituteClear={instituteClear}
            setInstitutePlanDrawer={setInstitutePlanDrawer}
          />
          <InstitutePreviewDrawer
            institutePreviewDrawer={institutePreviewDrawer}
            setInstitutePreviewDrawer={setInstitutePreviewDrawer}
            setInstituteLinkGenerateDrawer={setInstituteLinkGenerateDrawer}
            setInstituteDrawer={setInstituteDrawer}
            form={institutePreview}
            instituteClear={instituteClear}
            setCollegeID={setCollegeID}
            collegeID={collegeID}
            setNewInstituteDocumentDrawer={setNewInstituteDocumentDrawer}
            setClearInput={setClearInput}
          />
          <InstituteLinkGenerateDrawer
            instituteLinkGenerateDrawer={instituteLinkGenerateDrawer}
            setInstituteLinkGenerateDrawer={setInstituteLinkGenerateDrawer}
            form={instituteGenerateLink}
            instituteClear={instituteClear}
            setCollegeID={setCollegeID}
            collegeID={collegeID}
          />{' '}
        </>
      )}
    </>
  )
}
export default Onboarding
