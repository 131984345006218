import { Empty, Spin } from 'antd'
import AntdAvatar from 'components/Avatar'
import Pagination from 'components/Pagination'
import {
  RowNumberData,
  RowNumberDataCompany,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import React, { useState } from 'react'
import { FlexWrap } from '../../../../components/TableStyles/TableContentStyles'
import { toCamelCase } from '../../../../utils/camelCase'
import { fixedSorterColumn } from '../../../../utils/constants'
import { EmptyWrapper, NavWrapper } from '../../Style/style'
import CorporateFilter from '../CorporateFilter'
import CorporateStatus from '../CorporateStatus'
import CorporateOnboardingStatus from '../CorporateStatus/onboardingStatus'
import ViewCorporateDrawer from '../ViewCorporateDrawer'
import ActionDropdown from './ActionDropdown'

const CorporateInfoTable = ({
  corporateList,
  page,
  search,
  loading,
  onPageChange,
  setSearch,
  setPage,
  setSort,
  setOrderBy,
  stateId,
  setStateId,
  stateList,
  setStateList,
  stateNames,
  setStateNames,
  setFilterData,
  filterData,
  getListOfState,
  cityList,
  setCityList,
  cityName,
  setCityName,
  cityId,
  setPermCityId,
  getListOfCity,
  orderBy,
  corporateActiveStatus,
  setCorporateActiveStatus,
  location,
  setLocation,
  setCorporateStatus,
  corporateStatus,
}) => {
  const [viewCorporateDrawer, setViewCorporateDrawer] = useState(false)
  const onSorting = (pagination, row, sorter) => {
    let order = ''
    if (sorter.order === 'descend') {
      order = 'desc'
      setSort(sorter?.field)
    } else if (sorter.order === 'ascend') {
      order = 'asc'
      setSort(sorter?.field)
    } else {
      setSort('')
    }
    setOrderBy(order)
  }

  const columns = [
    {
      title: 'COMPANY',
      dataIndex: 'name',
      key: 'company_name',
      width: '20%',
      sorter: true,
      showSorterTooltip: fixedSorterColumn(orderBy),
      render: (_, rowData) => {
        const CompanyItem = (
          <FlexWrap alignCenter gap="14px">
            <AntdAvatar
              src={rowData?.logo}
              size={37}
              IconName={rowData?.name?.split('')[0]}
              font={18}
            />
            <RowNumberDataCompany>
              {toCamelCase(rowData?.name)}
            </RowNumberDataCompany>
          </FlexWrap>
        )
        return CompanyItem // Return the JSX for rendering
      },
    },

    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      render: (_, rowData) => (
        <RowNumberData>{toCamelCase(rowData?.state)}</RowNumberData>
      ),
    },
    {
      title: 'CITY',
      dataIndex: 'city',
      key: 'location_id',
      render: (_, rowData) => (
        <RowNumberData>{toCamelCase(rowData?.city)}</RowNumberData>
      ),
    },
    {
      title: 'PLAN',
      dataIndex: 'plan',
      key: 'plan_id',
      render: (_, rowData) => <RowNumberData>{'Free Plan'}</RowNumberData>,
    },
    // {
    //***************************Future Use Case ***************************
    //   title: 'Next Due',
    //   dataIndex: 'location',
    //   key: 'location_id',
    //   render: (_, rowData) => <RowNumberData>{'-'}</RowNumberData>,
    // },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, rowData) => (
        <RowNumberData>
          <CorporateStatus status={rowData?.isActive} />
        </RowNumberData>
      ),
    },
    {
      title: 'ONBOARDING STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (_, rowData) => (
        <RowNumberData>
          <CorporateOnboardingStatus status={rowData?.status} />
        </RowNumberData>
      ),
    },

    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          setViewCorporateDrawer={setViewCorporateDrawer}
        />
      ),
    },
  ]
  return (
    <NavWrapper column>
      <CorporateFilter
        corporateList={corporateList}
        location={location}
        setLocation={setLocation}
        searchValue={search}
        setSearchValue={setSearch}
        stateId={stateId}
        setStateId={setStateId}
        stateList={stateList}
        setStateList={setStateList}
        stateNames={stateNames}
        setStateNames={setStateNames}
        getListOfState={getListOfState}
        cityList={cityList}
        setCityList={setCityList}
        setFilterData={setFilterData}
        filterData={filterData}
        cityName={cityName}
        setCityName={setCityName}
        getListOfCity={getListOfCity}
        cityId={cityId}
        setPermCityId={setPermCityId}
        currentPage={page}
        setCurrentPage={setPage}
        setCorporateActiveStatus={setCorporateActiveStatus}
        corporateActiveStatus={corporateActiveStatus}
        setCorporateStatus={setCorporateStatus}
        corporateStatus={corporateStatus}
      />
      <StyledTable
        columns={columns}
        onChange={onSorting}
        dataSource={corporateList?.list}
        pagination={false}
        locale={{
          emptyText: (
            <EmptyWrapper center isSpin={loading}>
              {!loading ? (
                <Empty description={'No corporate found!'} />
              ) : (
                <Spin />
              )}
            </EmptyWrapper>
          ),
        }}
      />
      {!loading && corporateList.count > 0 && (
        <Pagination
          onChange={onPageChange}
          totalPages={corporateList.count}
          pageSize={10}
          current={page}
        />
      )}
      <ViewCorporateDrawer
        viewCorporateDrawer={viewCorporateDrawer}
        setViewCorporateDrawer={setViewCorporateDrawer}
      />
    </NavWrapper>
  )
}

export default CorporateInfoTable
