import styled from 'styled-components'
import { COLORS } from 'theme'
import Flex from 'components/Flex'
import { FONT } from 'theme/font'
import { Dropdown, Button, Divider, Checkbox, Radio, Alert } from 'antd'
export const DropDown = styled(Dropdown)``
export const DropBtn = styled(Button)`
  .ant-btn {
    background: ${COLORS.PRIMARY_GREY_T_98} !important;
  }
`
export const PaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 25px;
`

export const Count = styled.div`
  padding-top: 8px;
  font-size: 14px;
  color: ${COLORS.GREY_T_35};
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
`

export const Content = styled(Flex)`
  height: ${props => (props.height ? props.height : 'auto')};
  /* padding: ${props => (props.smallPadding ? 0 : '50px 75px 50px 75px')}; */
  gap: 20px;
  border-radius: 10px;
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
  align-items: center;
  text-align: ${props => (props.alignCenter ? 'center' : '')};
`
export const Spacer = styled.div`
  height: 24px;
`

export const VerticalTableContent = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #999fac;
  border-bottom: 1px solid ${COLORS.GREY_T_90};
  width: 210px;
  padding: 20px;
  background: ${props => (props.backgroundColor ? props.backgroundColor : ' ')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  color: ${props => (props.color ? props.color : '#3249d7')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};

  // &:hover {
  //   background: #eaedfb;
  //   border-radius: 10px;
  //   color: #3249d7;
  //   font-size: 16px;
  //   font-weight: 600;
  // }
`

export const BorderedDiv = styled.div`
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_T_90};
  border-radius: 10px;
  padding: 15px;
`

export const SectionWrapper = styled(Flex)`
  width: 100%;
  overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
`

export const TopHeader = styled(Flex)`
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const RoleNameFlex = styled(Flex)``
export const TopEndFlex = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`
export const LeftSection = styled(Flex)`
  gap: 20px;
`
export const SectionName = styled(Flex)`
  gap: 10px;
`
export const RoleNameDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: ${FONT.F_XL};
  color: ${COLORS.GREY_T_20};
  margin: 10px 0 10px;
`
export const InterviewDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 18px;
  color: ${COLORS.GREY_T_30};
  margin: 0 10px 0 0;
`
export const ClockDiv = styled.div`
  margin: 0 20px;
  border: 1px solid ${COLORS.GREY_T_90};
  padding: 9px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
`
export const ButtonDiv = styled.div`
  padding: 0 20px 0 0;
  border-right: 1px solid ${COLORS.GREY_T_90};
`
export const ThreeDotsDiv = styled.div`
  cursor: pointer;

  .ant-btn {
    background: ${COLORS.PRIMARY_GREY_T_98} !important;
    border-radius: 10px !important;
    border: 1px solid ${COLORS.GREY_T_90} !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
`
export const SectinFlex = styled(Flex)``
export const DriveDetailDiv = styled.div`
  width: 68%;
  margin-right: 70px;
`
export const DrivInfoDiv = styled.div`
  width: 40%;
`
export const AntdSpan = styled.span`
  margin-right: 0.5px;
`
export const RoleNames = styled.h1`
  /* width: 89px; */
  height: 21px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #3249d7;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const UserName = styled.h1`
  width: 112px;
  height: 28px;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #010e30;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: ${props => (props.pt ? props.pt : 0)};
`

export const RolesDiv = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: #999fac;
  /* border-bottom: 1px solid ${COLORS.GREY_T_90}; */
  width: 'auto';
  padding: 10px 0px 2px 13px;
  background: ${props => (props.backgroundColor ? props.backgroundColor : ' ')};
  background: ${props => (props.active ? '#eaedfb' : ' ')};

  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  color: ${props => (props.color ? props.color : '#3249d7')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  &:hover {
    background: #eaedfb;
    /* align-self: stretch; */
    border-radius: 10px;
    /* padding: 15px; */
    /* gap: 10px; */
    /* width: 237px; */
    /* height: 51px; */
  }
`
export const FlexWrap = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`
export const StyledAlert = styled(Alert)`
  border: none;
  border-radius: 10px;
  margin: ${props => (props?.margin ? props?.margin : 0)};
  ${props =>
    props?.backgroundColor && `background-color: ${props?.backgroundColor};`}
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '0')};
  /* margin-top: ${props => (props.margintop ? props.margintop : '0')}; */
  height: ${props => (props.height ? props.height : '0`')};
`
export const AlertText = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 18px;
  color: ${COLORS.GREY_T_20};
  margin-left: 5px;
`
export const FlexWrap1 = styled(Flex)`
  gap: 100px;
`
export const RoleDiv = styled.div`
  margin-left: 10px;
`
export const ListName = styled.div`
  font-size: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
`
export const CircleCheckbox = styled(Checkbox)`
  .ant-checkbox {
    border-radius: 50%;
    border: 1px solid #d6dbf7;
    background: #f9fafe;
  }
  .ant-checkbox-input:hover {
    border: 1px solid #d6dbf7;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #d6dbf7;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-radius: 50%;
    border: 1px solid #d6dbf7;
    /* background: #5db594; */
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-input {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  /* .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner:hover,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    background: #5db594;
    border-radius: 50%;
  } */
`

export const StyledRadio = styled(Radio)`
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.HEADING};
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none !important;
  }
  .ant-radio-inner {
    background: transparent;
  }
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.7);
  }

  ${props => props.boxed && 'padding: 12px 10px'};
  ${props => props.boxed && 'border: 1px solid #e6e7ea'};
  ${props => props.boxed && 'border-radius: 10px'};
`

export const RoleDivider = styled(Divider)`
  width: 1px;
  height: 413px;
  background: #e6e7ea;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-top: 0px;
`
export const RoleNameDivider = styled(Divider)`
  width: 852px;
  height: 1px;
  background: #e6e7ea;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
export const ListDivider = styled(Divider)`
  height: 1px;
  background: #e6e7ea;
`

export const CandidatesSpan = styled.span`
  padding: 2px 6px;
  margin-left: 10px;
  width: 29px;
  height: 19px;
  background: ${COLORS.PAGE_BORDER};
  border-radius: 20px;
`

export const RowNumberData = styled.div`
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  text-transform: capitalize;
  display: block; /* Change it as per your requirement. */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
`
export const UserInfoDiv = styled.div`
  display: flex !important;
  gap: 20px !important;
`
export const UserInfoBottom = styled.div`
  margin-top: 10px !important;
`
export const UserInfoHeading = styled.div`
  font-weight: 700;
  margin-bottom: 10px !important;
  color: #343e59;
  font-size: 20px;
`
export const UserInfoSubHeading = styled.div`
  font-weight: 400;
  margin-bottom: 30px !important;
  color: #999fac;
  font-size: 14px;
`
export const UserInfoSubSpan = styled.span`
  font-weight: 500;
  color: #676e83;
  font-size: 14px;
`
export const UserInfoImgSpan = styled.span`
  font-weight: 500;
  color: #343e59;
  font-size: 14px;
`
export const DividerCode = styled.div`
  border-left: 1px solid #ccc;
  height: 20px;
  margin: 0 10px;
`
export const UserInfoSubDiv = styled.div`
  display: flex !important;
  gap: 10px !important;
`
export const StartDiv = styled.div`
  display: flex !important;
  justify-content: space-between !important;
`

export const textButton = styled.div`
  display: flex;
  align-items: center;
`

export const StatusDiv = styled.div`
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  margin: ${props => props.margin ?? ''};
`
export const StatusSubSpan = styled.div`
  display: flex !important;
  gap: 10px !important;
  color: #999fac;
  font-size: 14px;
  font-weight: 500;
`
export const TextAlign = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  width: 47px;
  height: 32px;
  /* Primary/Purple/T - 98 */
  background: #fbfaff;
  /* Primary/Purple/T - 80 */
  border: 1px solid #e3deff;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: #3249d7;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`
export const TableHeadAlign = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
`
export const buttonAlignItem = styled.div`
  margin-right: 20px;
`
