import PageNotFound from 'routes/Components/PageNotFound'
import Dashboard from 'modules/Dashboard/Container/index'
import Onboarding from 'modules/Onboarding/Container/index'
import RegisterSuccessfulPage from 'modules/Onboarding/Components/RegisterSuccessful/RegisterSuccessfulPage'
import Institutes from 'modules/Institutes/Container/index'
import Users from 'modules/User/Container/index'
import Settings from 'modules/Settings/Container/index'
import Corporate from 'modules/Onboarding/Partials/Corporates/Register/Container'
import Institute from 'modules/Onboarding/Partials/Institutes/Register/Container'
import UserView from 'modules/User/Partials/UsersView/Container/index'
import Reports from 'modules/Reports/Container/index'
import InstituteInfoTable from 'modules/Reports/Partials/Institutes/Container/InstituteInfoIndex.js'
import EmpanelledInfoTable from 'modules/Reports/Partials/Corporates/Container/index'
import Top10Index from 'modules/Reports/Partials/Corporates/Container/TopBottomCorporateIndex'
import InstEmpanelledTable from 'modules/Reports/Partials/Institutes/Container/index.js'
import Top10InstituteInfoTable from '../../modules/Reports/Partials/Institutes/Container/TopInstituteList'
// import ListOfStudentsData from 'modules/Reports/Partials/students/Partials/ListOfStudentTable/index.js'
// import ListOfStudentsData from '../../..//src/modules/Reports/Partials/Students/Partials/ListOfStudentTable/index'
import StudentListOfData from 'modules/Reports/Partials/Students/Container/ListOfStudentTableIndex'
import StudentsCourseWiseTable from '../../modules/Reports/Partials/Students/Container/StudentCourseBaseIndex'
import ListOfStudentsPlacedTable from '../../modules/Reports/Partials/Students/Container/StudentPlacedTableIndex'
import StudentSkillWiseData from '../../modules/Reports/Partials/Students/Container/StudentSkillWiseDetails'
import ManageProfile from 'modules/ManageProfile/index'
import SystemsConfig from '../../modules/Systemconfig/index'
import GeneralSysConfig from 'modules/Systemconfig/Partials/GeneralTableSettings/Container/index'
// import CorporateSysConfig from 'modules/Systemconfig/Partials/CorporateSettings/index'
import CorporateSysConfig from 'modules/Systemconfig/Partials/CorporateSettings/Container/index'
import Corporates from '../../modules/Corporates/Container/index'
import Courses from 'modules/Courses/Container/index'
// export const authenticated = [
// import Institute from 'modules/Onboarding/Partials/Institutes/Register/Container'
// import Corporate from 'modules/Onboarding/Partials/Corporates/Register/Container'
// import Institute from 'modules/Onboarding/Partials/Institutes/Register/Container'
// import Corporate from 'modules/Onboarding/Partials/Corporates/Register/Container'
// import UserView from 'modules/User/Partials/UsersView/index'
import InstituteSettings from 'modules/Systemconfig/Partials/InstituteSettings/Container/index'
import MyLocationSettings from 'modules/Systemconfig/Partials/MyLocationSettings/Container/index'
import BillingSysConfig from 'modules/Systemconfig/Partials/BillingSettings/Container/index'
import EventCatalogue from 'modules/EventCatalogue/Container/index'
import PermissionSettings from 'modules/Systemconfig/Partials/PermissionSettings/Container/index'
import RankingAlgorithm from 'modules/RankingAlgorithm/Container/index.js'

export const authenticatedWithNav = [
  {
    routePath: '/dashboard',
    Component: Dashboard,
    // navTitle: 'Dashboard',
  },
  {
    routePath: '/rankingAlgorithm',
    Component: RankingAlgorithm,
    navTitle: 'Ranking Algorithm',
  },
  {
    routePath: '/onboarding',
    Component: Onboarding,
    navTitle: 'Onboarding',
  },
  {
    routePath: '/onboarding/corporate',
    Component: Corporate,
  },
  {
    routePath: '/onboarding/institute',
    Component: Institute,
  },
  {
    routePath: '/onboarding/corporate/registeredSuccessfully',
    Component: RegisterSuccessfulPage,
  },
  {
    routePath: '/corporates',
    Component: Corporates,
  },
  {
    routePath: '/institutes',
    Component: Institutes,
    navTitle: 'Institutes',
  },
  {
    routePath: '/reports/corporate',
    Component: Reports,
  },
  {
    routePath: '/reports/corporate/empanelledinfotable',
    Component: EmpanelledInfoTable,
  },
  {
    routePath: '/reports/corporate/top10index',
    Component: Top10Index,
  },
  {
    routePath: '/reports/institute',
    Component: InstituteInfoTable,
  },
  {
    routePath: '/reports/institute/empanelledinfotable',
    Component: InstEmpanelledTable,
  },
  {
    routePath: '/reports/institute/top10index',
    Component: Top10InstituteInfoTable,
  },
  {
    routePath: '/reports/students',
    Component: StudentListOfData,
  },
  {
    routePath: '/reports/students/studentplacedscoursewise',
    Component: StudentsCourseWiseTable,
  },
  {
    routePath: '/reports/students/listofstudentspalced',
    Component: ListOfStudentsPlacedTable,
  },
  {
    routePath: '/reports/students/studentskillwise',
    Component: StudentSkillWiseData,
  },
  {
    routePath: '/users',
    Component: Users,
    navTitle: 'Users',
  },
  {
    routePath: '/users/:id',
    Component: UserView,
  },
  {
    routePath: '/manageprofile',
    Component: ManageProfile,
  },
  {
    routePath: '/systemConfig',
    Component: SystemsConfig,
  },
  {
    routePath: '/systemConfig/GeneralTableSettings/:GeneralCard/:id',
    Component: GeneralSysConfig,
  },
  {
    routePath: '/systemConfig/corporateSettings',
    Component: CorporateSysConfig,
  },
  {
    routePath: '/settings',
    Component: Settings,
    navTitle: 'Settings',
  },
  {
    routePath: '/systemConfig/instituteSettings/:institueCard/:id',
    Component: InstituteSettings,
  },
  {
    routePath: '/systemConfig/permissionSettings/:institueCard/:id',
    Component: PermissionSettings,
  },
  {
    routePath: '/systemConfig/locationSettings/:locationCard/:id',
    Component: MyLocationSettings,
  },
  {
    routePath: '/systemConfig/billingSettings',
    Component: BillingSysConfig,
  },
  {
    routePath: '/coursemapping',
    Component: Courses,
    navTitle: 'Courses',
  },
  {
    routePath: '/eventcatalogue',
    Component: EventCatalogue,
    navTitle: 'Event Catalogue',
  },
]

export const authenticatedWithoutNav = [
  {
    routePath: '/onboarding/corporate/:corporateId',
    Component: Corporate,
  },
  {
    routePath: '/onboarding/institute/:instituteId',
    Component: Institute,
  },
  {
    routePath: '/onboarding/registeredSuccessfully',
    Component: RegisterSuccessfulPage,
  },
  {
    routePath: '*',
    Component: PageNotFound,
  },
]
