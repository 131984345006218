import styled from 'styled-components'
import Flex from 'components/Flex'
import { Form as AntdForm } from 'antd'
import Input from 'components/Form/Input'

export const Form = styled(AntdForm)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
`
export const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  ${props => props.width && `width: ${props.width}`}
`

export const FlexWrap = styled(Flex)`
  width: ${props => (props.width ? props.width : '100%')};
  gap: ${props => (props.gap ? props.gap : '0')};
`

export const SelectInputDiv = styled(Flex)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '20px'} !important;
  margin-top: ${props =>
    props.margintop ? props.margintop : '0px'} !important;
  align-items: flex-end;
  position: sticky;
  align-items: stretch;
  width: ${props => (props.width ? props.width : '100%')};
  border-radius: 8px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-top-right-radius: ${props =>
      props?.borderright ? '8px' : 0} !important;
    border-bottom-right-radius: ${props =>
      props?.borderright ? '8px' : 0} !important;
    border-right: ${props =>
      props?.borderright
        ? `${props?.borderright} solid  #e9e9e9`
        : 0} !important;

    border-top-left-radius: ${props =>
      props?.borderleft ? '8px' : 0} !important;
    border-bottom-left-radius: ${props =>
      props?.borderleft ? '8px' : 0} !important;
  }
`
export const StyledInput = styled(Input)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`
