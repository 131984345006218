import React from 'react'
import CustomTag from 'components/CustomTag'

const UserStatus = ({ status }) => {
  let colorType
  let tagMessage
  if (status === true) {
    colorType = 'green'
    tagMessage = 'Active'
  } else if (status === false) {
    colorType = 'red'
    tagMessage = 'Inactive'
  }

  return (
    <CustomTag colorType={colorType} message={tagMessage} bordered={false} />
  )
}

export default UserStatus
