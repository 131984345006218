import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { FlexWrap } from 'components/UIComponents/FormStyles'
import {
  getListOfCity,
  getListOfCounties,
  getListOfState,
} from 'modules/Onboarding/actions'
import { saveFormData } from 'modules/Onboarding/Partials/Corporates/actions'
import ContactDetails from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Partials/ContactDetails'
import CorporateDetails from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Partials/CorporateDetails'
import { Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const AddNewCorporateDrawer = ({
  newCorporateDrawer,
  setNewCorporateDrawer,
  setNewPlanDrawer,
  formId = 'corporate_form',
  title = 'Add New Corporate',
  sendButtonText = 'Next',
  dateFormat,
  form,
  corporateClear,
}) => {
  const [searchCountry, setSearchCountry] = useState('')
  const dispatch = new useDispatch()
  const countryCode = Form.useWatch('countryCode', form)
  useEffect(() => {
    getCountryData()
    //dispatch(getListOfCounties())
  }, [])

  //const countryList = useSelector(state => state?.CorporateData?.countryList)
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [countryId, setCountryId] = useState('')
  const [stateId, setStateId] = useState('')
  const [cityId, setPermCityId] = useState('')
  const [isHeadOffice, setHeadOffice] = useState(false)
  const [loadingCountry, setLoadingCountry] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      setLoadingState(true)
      let list = await dispatch(getListOfState(countryId, search))
      setLoadingState(false)
      return setStateList(list || []) 
    }
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    if (countryId && stateId) {
      setLoadingCity(true)
      let list = await dispatch(getListOfCity(countryId, stateId, search))
      setLoadingCity(false)
      return setCityList(list || []) 
    }
  }
  let getCountryData = async search => {
    setLoadingCountry(true)
    let list = await dispatch(getListOfCounties(search))
    setLoadingCountry(false)
    console.log(list,'data')
    return setCountryList(list || [])
  }

  useEffect(() => {
    let getData
    getData = setTimeout(() => {
      dispatch(getListOfCounties(searchCountry))
    }, 500)
    return () => clearTimeout(getData)
  }, [searchCountry])

  const onStateChange = (data, option) => {
    const newHeadOfficeValue = isHeadOffice
    form.setFieldsValue({
      state: data,
      city: '',
      stateId: option?.option?._id,
      cityId: '',
      isHeadOffice: newHeadOfficeValue,
    })
    setStateName(data)
    setStateId(option?.option?._id)
    setCityName('')
    setHeadOffice(newHeadOfficeValue)
    getStateData(countryId, true)
    getCityData(countryId, option?.option?._id, true)
  }

  const onCityChange = (data, option) => {
    form.setFieldsValue({
      city: data,
      cityId: option?.option?._id,
    })
    setCityName(data)
    setPermCityId(option?.option?._id)
    getCityData(countryId, stateId, true)
  }

  const onCountryChange = (data, option) => {
    const newHeadOfficeValue = isHeadOffice
    form.setFieldsValue({
      country: data,
      state: '',
      city: '',
      countryId: option?.option?._id,
      stateId: '',
      cityId: '',
      isHeadOffice: newHeadOfficeValue,
    })
    form.resetFields(['state', 'city'])
    setCountryName(data)
    setCountryId(option?.option?._id)
    setStateName('')
    setStateId('')
    setCityName('')
    setHeadOffice(newHeadOfficeValue)
    setSearchCountry('')
    getStateData(option?.option?._id, true)
  }

  const delayCountryToFetch = useCallback(
    debounce(data => {
      getCountryData(data)
      //  dispatch(getListOfCounties(data))
      form.setFieldsValue({
        country: '',
        countryId: '',
      })
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const clearAllFields = () => {
    setCountryName('')
    setStateName('')
    setCityName('')
    setNewCorporateDrawer(false)
  }

  const onClear = () => {
    clearAllFields()
    corporateClear()
  }

  const onSubmitFormData = async formData => {
    let payload = {
      ...formData,
      countryId: countryId,
      stateId: stateId,
      cityId: cityId,
      website: formData?.website
        ? /^https?:\/\//i.test(formData?.website)
          ? formData?.website
          : `https://${formData?.website}`
        : null,
    }

    dispatch(saveFormData(payload))
    setNewCorporateDrawer(false)
    setNewPlanDrawer(true)
  }

  const content = () => {
    return (
      <div>
        <Form
          scrollToFirstError
          onFinish={onSubmitFormData}
          id={formId}
          form={form}
          layout="vertical"
        >
          <CorporateDetails
            form={form}
            cityList={cityList}
            countryList={countryList}
            stateList={stateList}
            dateFormat={dateFormat}
            onCountryChange={onCountryChange}
            delayCountryToFetch={delayCountryToFetch}
            countryValue={countryName}
            stateValue={stateName}
            cityValue={cityName}
            countryId={countryId}
            stateId={stateId}
            getStateData={getStateData}
            getCityData={getCityData}
            setSearchCountry={setSearchCountry}
            onStateChange={onStateChange}
            onCityChange={onCityChange}
            setHeadOffice={setHeadOffice}
            isHeadOffice={isHeadOffice}
            loadingCountry={loadingCountry}
            loadingState={loadingState}
            loadingCity={loadingCity}
            setLoadingCountry={setLoadingCountry}
            setLoadingState={setLoadingState}
            setLoadingCity={setLoadingCity}
          />

          <ContactDetails form={form} countryCode={countryCode} />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClear} />
        <Button.Primary htmlType="submit" form={formId} text={sendButtonText} />
      </FlexWrap>
    )
  }

  return (
  
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={onClear}
        visible={newCorporateDrawer}
        content={content()}
        footer={footer()}
      />
    
  )
}

export default AddNewCorporateDrawer
