import { Form, InputNumber } from 'antd'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import Control from 'components/Control/Control'
import Flex from 'components/Flex'
import Checkbox from 'components/Form/Checkbox'
import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import Label from 'components/Label/Label'
import RichEditor from 'components/RichEditor/index'
import Select from 'components/Select'
import FileReader from 'components/Uploader/FileReader'
import {
  default as Uploader,
  default as Uploadloader,
} from 'components/Uploader/Uploader'
import Camera from 'components/icons/Camera'
import LinkIcon from 'components/icons/LinkIcon'
import {
  ButtonWrap,
  DivBold,
  FormItem,
  Heading,
  ImageTag,
  ImageUploadWrapper,
} from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'
import SuccessMessage from 'utils/SuccessMessage'
import { searchConfig } from 'utils/config'
import CONSTANTS from 'utils/constants'
import { searchAPI } from '../../../../actions'

export const TierInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none !important;
  }
  .ant-input-number-suffix {
    display: none;
  }
`
export const CommonFlex = styled(Flex)`
  padding: 0 0 20px 0;
`

export const RightSection = styled.div`
  width: 70%;
  height: 80vh;
  padding-left: 1rem;
  border-left: 1px solid ${COLORS.GREY_T_90};
`

export const SubTitleDiv = styled.div`
  font-style: normal;
  font-weight: ${FONT.FW_400};
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
`

const employees = [
  {
    value: '0 to 10',
    id: '0 to 10',
  },
  {
    value: '10 to 100',
    id: '10 to 100',
  },
  {
    value: '100 to 1000',
    id: '100 to 1000',
  },
  {
    value: '1000 to 5000',
    id: '1000 to 5000',
  },
  {
    value: '5000+',
    id: '5000+',
  },
]

const CorporateInfoForm = ({
  form,
  file,
  setFile,
  onRemoveImage,
  industryList,
  corporatePresentation,
  setCorporatePresentation,
  singleCorporateData,
  getGeneralListForSysConfig,
  sectorList,
  activeIndustry,
  setSectorId,
  setSectorMapId,
  sectorMapId,
  description,
  OncheckBoxChange,
  isHeadOffice,
  onIndustryChange,
  setIndustrySearchList,
  industrySearchList,
  setSectorSearchList,
  sectorSearchList,
  handleCall,
}) => {
  const establishedYear = Form.useWatch('establishedYear', form)
  const corporateVideoLink = Form.useWatch('corporateVideoLink', form)
  const longDescription = Form.useWatch('longDescription', form)
  const Sector = Form.useWatch('sector', form)
  const Industry = Form.useWatch('industry', form)
  const [employee, setEmployees] = useState('')
  const onChangePresentationLink = file => {
    setCorporatePresentation(file)
    form.setFieldsValue({ corporatePresentationLink: file?.url })
  }
  const onChangeProfile = file => {
    setFile(file)
    form.setFieldsValue({ profileUrl: file })
  }

  const dispatch = useDispatch()

  const copyLink = () => {
    navigator.clipboard.writeText(corporateVideoLink)
    if (corporateVideoLink.length != 0) {
      SuccessMessage('Link Coppied!')
    }
  }

  const delaySectorToFetch = useCallback(
    debounce(data => {
      if (data === '') {
        dispatch(
          searchAPI(searchConfig?.sector?.name, data, searchConfig?.sector)
        )
          .then(results => {
            setSectorSearchList(results)
          })
          .catch(error =>
            console.error(
              `Error searching ${searchConfig?.sector?.name}:`,
              error
            )
          )
      }
      form.setFieldsValue({
        sector: '',
        sectorIndustryMapId: '',
      })
    }, 100),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  const onChangeDescription = description => {
    const result = description
      .slice(3)
      .replaceAll('&nbsp;', '')
      .replaceAll('<br>', '')
      .slice(0, -4)
    if (!result) {
      form.setFieldsValue({ longDescription: '' })
    } else {
      form.setFieldsValue({ longDescription: description })
    }
  }
  useEffect(() => {
    if (longDescription)
      form.setFieldsValue({ longDescription: longDescription })
  }, [longDescription])

  const currentYear = new Date().getFullYear()
  const years = []
  for (let year = 1800; year <= currentYear; year++) {
    years.push({
      value: year,
      name: year,
    })
  }

  const onYearChange = (data, option) => {
    form.setFieldsValue({ establishedYear: data })
  }

  const onEmployeeChange = (data, option) => {
    setEmployees(data)
    form.setFieldsValue({ employeesNumber: data })
  }
  useEffect(() => {
    if (singleCorporateData)
      onEmployeeChange(singleCorporateData?.employeesNumber)
    setFile(singleCorporateData?.logo)
  }, [singleCorporateData])

  useEffect(() => {
    if (Sector && Sector.length !== 0) {
      const filteredOptions = sectorSearchList?.filter(option =>
        option.name.toLowerCase().includes(Sector.toLowerCase())
      )
      setSectorSearchList([{ name: Sector, id: '' }, ...filteredOptions])
    }
  }, [Sector])

  const delayIndustryToFetch = useCallback(
    debounce(data => {
      dispatch(
        searchAPI(
          searchConfig?.industries?.name,
          data.trim(),
          searchConfig?.industries
        )
      )
        .then(results => setIndustrySearchList(results))
        .catch(error =>
          console.error(
            `Error searching ${searchConfig?.industries?.name}:`,
            error
          )
        )
      form.setFieldsValue({
        industry: '',
        industryId: '',
      })
    }, 500),
    []
  )

  return (
    <Control flexStart row width="100%" spaceBetween>
      <Control flexStart width="67%" gap="20px">
        <Control flexStart column gap="30px">
          <Control row flexStart width="700px" gap="40px">
            <Control column flexStart gap="30px">
              <Control>
                <Heading>Basic Details</Heading>
              </Control>
              <Control column flexStart>
                <Label>Company Name </Label>
                <AntdInput
                  width="350px"
                  placeholder="Company name"
                  name={'name'}
                  maxLength={300}
                  rules={[
                    {
                      required: true,
                      message:
                        CONSTANTS.ERROR_MESSAGE.REQ_INPUT('corporate name'),
                    },
                  ]}
                />
              </Control>
              <Control column flexStart>
                <Label>Short Name / Brand Name </Label>
                <AntdInput
                  width="350px"
                  placeholder="Enter Shortname / Brand Name"
                  name={'brandName'}
                  rules={[
                    {
                      required: false,
                      message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT(
                        'Enter Shortname / Brand Name'
                      ),
                    },
                    {
                      pattern: CONSTANTS.REGEX.ADDRESS2,
                      message: CONSTANTS.ERROR_MESSAGE.VALID(
                        'Enter Shortname / Brand Name'
                      ),
                    },
                  ]}
                />
              </Control>
              <Control column flexStart>
                <Label>Sector *</Label>
                <Select
                  width="350px"
                  placeholder="Eg:Banking sector"
                  name={'sector'}
                  rules={[
                    {
                      required: true,
                      message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('Sector'),
                    },
                  ]}
                  OptionData={sectorSearchList}
                  valueParam="name"
                  nameParam="name"
                  showSearch={true}
                  allowClear={true}
                  onSearch={data => delaySectorToFetch(data)}
                  onChange={(value, event) => {
                    form.setFieldsValue({
                      sector: value,
                    })
                    handleCall()
                  }}
                  filterOption={false}
                  value={form.getFieldValue(['sector'])}
                />
                <AntdInput
                  width="400px"
                  name={'sectorIndustryMapId'}
                  value={sectorMapId}
                  display="none"
                />
              </Control>
            </Control>
            <Control>
              <Control column alignCenter width="200px" center>
                <ImageUploadWrapper column alignCenter center boarder={!file}>
                  {!file && (
                    <Fragment>
                      <Control column alignCenter>
                        <Camera />
                      </Control>
                      <Control column alignCenter top={'3px'}>
                        <Control column alignCenter>
                          Max file size: 2MB
                        </Control>
                        <Control column alignCenter>
                          Dimension: 130*130
                        </Control>
                        <Control column alignCenter>
                          File format: .png, .jpeg
                        </Control>
                      </Control>
                    </Fragment>
                  )}
                  {file && <ImageTag src={file} />}
                </ImageUploadWrapper>
                {!file && (
                  <Uploader
                    setFile={onChangeProfile}
                    showTextUploder={!file}
                    fileName={
                      singleCorporateData?.name
                        ?.toLowerCase()
                        .replace(/\s/g, '') + '_profile'
                    }
                  />
                )}
                <FormItem
                  name={'profileUrl'}
                  padding={true}
                  value={file}
                  rules={[
                    {
                      required: false,
                      message: 'Please upload a image!',
                    },
                  ]}
                />
                {file ? (
                  <ButtonWrap>
                    <Control
                      width="100%"
                      flexEnd
                      gap="10px"
                      top="15px"
                      column
                      alignCenter
                    >
                      <Button.Secondary
                        text="Remove"
                        onClick={onRemoveImage}
                        height={'32px'}
                      />
                      <Uploader
                        isDelete={true}
                        file={file}
                        setFile={onChangeProfile}
                        showButtonUploder={!!file}
                        fileName={
                          singleCorporateData.name
                            ?.toLowerCase()
                            .replace(/\s/g, '') + '_profile'
                        }
                      />
                    </Control>
                  </ButtonWrap>
                ) : null}
              </Control>
            </Control>
          </Control>
          <Control column flexStart>
            <Label>Industry *</Label>

            <Select
              width="552px"
              placeholder="Eg:Software Industry"
              name={'industry'}
              rules={[
                {
                  required: form.getFieldValue(['addIndustry']) !== 'OTHERS',
                  message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('Industry'),
                },
              ]}
              OptionData={industrySearchList}
              valueParam="name"
              nameParam="name"
              showSearch={true}
              allowClear={true}
              onSearch={data => delayIndustryToFetch(data)}
              onChange={onIndustryChange}
              value={
                form.getFieldValue(['addIndustry']) === 'OTHERS'
                  ? 'Other'
                  : form.getFieldValue(['industry'])
              }
              filterOption={(inputValue, option) =>
                option?.name === 'Others'
                  ? true
                  : option?.name
                      ?.toLowerCase()
                      ?.indexOf(inputValue?.toLowerCase()) !== -1
              }
            />
          </Control>
          {form.getFieldValue(['addIndustry']) === 'OTHERS' && (
            <Control top="20px" flexStart column>
              <Label>Please enter the Industry name</Label>
              <AntdInput
                placeholder="Enter the Industry name"
                name={'industry'}
                value={form.getFieldValue(['industry'])}
                rules={[
                  {
                    required: true,
                    message: 'Please enter the Industry name.',
                  },
                  {
                    pattern: /^(?!\s).*$/,
                    message: 'Industry cannot start with a space',
                  },
                ]}
              />
            </Control>
          )}

          {/* <Control column flexStart>
              <Label>No of Employees </Label>
              <AntdInput
                placeholder="Eg 200 and more"
                name={'employeesNumber'}
                type="number"
                rules={[
                  {
                    required: false,
                    message:
                      CONSTANTS.ERROR_MESSAGE.REQ_INPUT('No of Employees'),
                  },
                  {
                    pattern: CONSTANTS.REGEX.ONLY_NUMBER,
                    message: CONSTANTS.ERROR_MESSAGE.VALID('No of Employees'),
                  },
                ]}
              />
            </Control> */}
          <FormItem
            name={'employeesNumber'}
            rules={[
              {
                required: false,
                message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('No of Employees'),
              },
              // {
              //   pattern: CONSTANTS.REGEX.ONLY_NUMBER,
              //   message: CONSTANTS.ERROR_MESSAGE.VALID('No of Employees'),
              // },
            ]}
          >
            <Control column flexStart>
              <Label>No of Employees</Label>
              <AntdSelect
                width="552px"
                placeholder="Select"
                name="No of Employees"
                OptionData={employees}
                valueParam="id"
                nameParam="value"
                showSearch={true}
                onChange={value => onEmployeeChange(value)}
                value={employee}
              />
            </Control>
          </FormItem>
          <FormItem
            name={'establishedYear'}
            rules={[
              {
                required: false,
                message: 'Please select start date!',
              },
              {
                validator: (_, value) => {
                  const selectedYear = moment(value, 'YYYY').year()
                  if (selectedYear > currentYear) {
                    return Promise.reject(
                      new Error('The selected year must be in the past')
                    )
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Control flexStart column width="250px">
              <Label>Year of Establishment</Label>
              <AntdSelect
                placeholder="Eg.1960"
                name={'year'}
                rules={[
                  {
                    required: false,
                    message: 'Please select Year!',
                  },
                ]}
                OptionData={years}
                valueParam="value"
                showSearch={true}
                nameParam="name" // Replace with the actual nameParam value
                onChange={value => onYearChange(value)}
                value={establishedYear}
                filterOption={(inputValue, option) => {
                  const optionName = option['name'].toString().toLowerCase()
                  const inputValueLower = inputValue.toLowerCase()
                  return optionName.indexOf(inputValueLower) >= 0
                }}
              />
            </Control>
          </FormItem>

          <Control column flexStart>
            <Label>Address line 1 </Label>
            <AntdInput
              placeholder="Enter your address"
              name={'addressLine1'}
              rules={[
                {
                  required: false,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('address1'),
                },
              ]}
            />
          </Control>
          <Control column flexStart>
            <Label>Address line 2</Label>
            <AntdInput
              placeholder="Enter your address"
              name={'addressLine2'}
              rules={[
                {
                  required: false,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('address 2'),
                },
              ]}
            />
          </Control>

          <Control flexStart row width="552px" gap="52px">
            <Control flexStart column>
              <Label>Country *</Label>
              <AntdInput name={'country'} disabled={true} width="250px" />
            </Control>
            <Control flexStart column>
              <Label>State *</Label>
              <AntdInput name={'state'} disabled={true} width="250px" />
            </Control>
          </Control>
          <Control flexStart row width="552px" gap="52px">
            <Control flexStart column>
              <Label>City *</Label>
              <AntdInput name={'city'} disabled={true} width="250px" />
            </Control>

            <Control flexStart column>
              <Label>Pincode *</Label>
              <AntdInput
                width="250px"
                placeholder="121212"
                name={'postalCode'}
                type={'number'}
                disabled={true}
              />
            </Control>
          </Control>
          <Control row>
            <Checkbox
              name="isHeadOffice"
              checked={isHeadOffice}
              onChange={OncheckBoxChange}
            />
            <DivBold>{'Head Office'}</DivBold>
          </Control>
          <Control flexStart column>
            <Label>Website *</Label>
            <AntdInput
              id={'WebsiteLink'}
              placeholder="Eg. www.google.com"
              name={'website'}
              // type='url'
              rules={[
                {
                  required: true,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('website'),
                },
                // {
                //   pattern: CONSTANTS.REGEX.WEBSITE,
                //   message: CONSTANTS.ERROR_MESSAGE.VALID('website'),
                // },
              ]}
              suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
            />
          </Control>
          <Control>
            <Heading>Company Additional Details</Heading>
          </Control>

          <Control column flexStart>
            <Label>One Liner </Label>
            <AntdInput
              placeholder="Add short description about company"
              name={'shortDescription'}
              maxLength={300}
              rules={[
                {
                  required: false,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('description'),
                },
              ]}
            />
          </Control>

          <Control top="20px" flexStart column width="552px">
            <Label>About Company </Label>
            <FormItem
              key={description}
              name={'longDescription'}
              rules={[
                {
                  required: false,
                  message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('description'),
                },
              ]}
            >
              <RichEditor value={description} onChange={onChangeDescription} />
            </FormItem>
          </Control>
          <Control top="20px" flexStart column width="552px">
            <FormItem
              name={'corporatePresentationLink'}
              rules={[
                {
                  required: false,
                  message: 'Please upload Contract!',
                },
              ]}
            >
              <Label>Corporate Presentation (If Any)</Label>
              {corporatePresentation ? (
                <Control column top="10px" center>
                  <FileReader
                    file={corporatePresentation}
                    setFile={onChangePresentationLink}
                  />
                </Control>
              ) : (
                <Control column top="10px" center>
                  <Uploadloader
                    setFile={onChangePresentationLink}
                    isDefault={true}
                    fileName={'document'}
                  />
                </Control>
              )}
            </FormItem>
          </Control>
          <Control flexStart column>
            <Label>Corporate Video Link (If Any)</Label>
            <AntdInput
              id={'VideoLink'}
              placeholder="Eg. www.youtube.com/result?search_query=about+lim"
              name={'corporateVideoLink'}
              suffix={<Avatar onClick={copyLink} src={<LinkIcon />} />}
              rules={[
                {
                  pattern: CONSTANTS.REGEX.WEB_URL,
                  message: CONSTANTS.ERROR_MESSAGE.VALID('VideoLink'),
                },
              ]}
            />
          </Control>
        </Control>
      </Control>
    </Control>
  )
}

export default CorporateInfoForm
