import { Col, Divider, Form, Row } from 'antd'
import RoutePath from 'components/RoutePath/index'
import LeftArrow from 'components/icons/LeftArrow'
import { arrayStatus } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import {
  LeftSection,
  PaddedDiv,
  RoleNameDiv,
  RoleNameFlex,
  SectionName,
  TopHeader,
} from 'modules/Systemconfig/Partials/TableSettings/Style/style'
import { SystemPaddedDiv } from 'modules/Systemconfig/Styles/style'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MasterItem from '../../../../components/UIComponents/SideMenuMaster'
import VirtualizedCheckboxGroupForStatus from '../../../../components/UIComponents/StatusFilter'
import SystemconfigTable from '../../../../components/UIComponents/SystemConfigTable/index'
import CrudDrawer from '../../Components/Drawer/Drawer'
import {
  AddFunctionContent,
  AddIndustryContent,
  AddRolesContent,
  AddSectorContent,
  AddSkillsContent,
  FunctionsData,
  GeneralMenuData,
  IndustryData,
  RolesData,
  SectorData,
  SkillsData,
  Status,
} from './Partials/TableColumnData'
const GeneralSysConfig = ({
  getGeneralListForSysConfig,
  GeneralTableList,
  createGeneralSysConfig,
  getFunctionSysConfig,
  FunctionTableList,
}) => {
  const [form] = Form.useForm()
  const [id, setId] = React.useState('1')
  const [url, setUrl] = useState()
  const [tableData, setTableData] = React.useState({
    id: 1,
    text: 'Functions',
  })
  const [drawer, setDrawer] = useState(false)
  const [view, setView] = useState(false)
  const [toEditId, setEditId] = useState('')
  const [sortBy, setSortBy] = useState({})
  const [content, setContent] = useState(AddFunctionContent)
  const [searchValue, setSearchValue] = useState('')
  const [dataToAdd, setDataToAdd] = useState(FunctionsData)
  const [menu, setMenu] = useState('Functions')
  const [itemsInFilter, setItemsInFilter] = useState(FilterItems)
  const [statusValue, setStatusValues] = useState([])
  const [submit, setSubmit] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [loading, setLoading] = useState(false)
  const [filterDatas, setFilterData] = useState({})
  const [success, isSuccess] = useState(false)
  const [cityList] = useState([])
  const [stateName] = useState([])
  const [addContent, setAddContent] = useState(false)
  const [stateList] = useState([])
  const [typeValue] = useState([])
  const [dropDownUrl, setDropDownUrl] = useState('crud/functions')
  const currentUrl = window.location.href
  const parts = currentUrl.split('/')
  const cardName = parts[parts.length - 2]
  const cardId = parts[parts.length - 1]

  const [clearInput, setClearInput] = useState(false)

  const showDrawer = e => {
    if (Object.prototype.toString.call(e) === '[object Object]') {
      setDrawer(true)
      setAddContent(true)
    } else {
      setDrawer(true)
      setAddContent(false)
    }
    setDrawer(true)
  }
  const onClose = () => {
    setDrawer(false)
    form.resetFields()
    setEditId('')
    setView(false)
  }

  useEffect(() => {
    if (dropDownUrl)
      getFunctionSysConfig({
        dropDownUrl,
        type: 'get',
        pageSize: 10,
        pageNumber: pageNum - 1,
        setLoading,
        searchValue,
        filterDatas,
        // filterDatas:menu=='Roles'?{status:'1'}:filterDatas,
        sortBy,
      })
  }, [menu])

  useEffect(() => {
    // Create a separate variable for pageNum
    const newPageNum = pageNum
    if (url)
      getGeneralListForSysConfig({
        url,
        type: 'get',
        pageSize: 10,
        pageNumber: newPageNum == 0 ? 0 : newPageNum - 1,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
    setPageNum(newPageNum)
  }, [url, pageNum, searchValue, submit, success, sortBy, menu])

  useEffect(() => {
    if (FunctionTableList) {
      AddRolesContent.forEach(item => {
        if (item.label === 'Function') {
          item.data = FunctionTableList?.result?.filter(func => func.isActive)
        }
      })

      AddIndustryContent.forEach(item => {
        if (item.label === 'Sector') {
          item.data = FunctionTableList?.result?.filter(sec => sec.isActive)
        }
      })
    }
  }, [FunctionTableList])

  useEffect(() => {
    if (cardName) {
      let decodedCardName = decodeURIComponent(cardName)
      let data = {
        id: cardId,
        text: decodedCardName,
      }

      onTableClick(data)
    }
  }, [cardName])

  const onTableClick = data => {
    setTableData(data)
    setId(data.id)
    setSortBy({})
    setFilterData({})
    setEditId('')
    setStatusValue([])
    setSearchValue('')
    setClearInput(!clearInput)
    setPageNum(1)

    setMenu(data.text)
    switch (data.text) {
      case 'Functions':
        setDataToAdd(FunctionsData)
        setContent(AddFunctionContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/functions')
        setSearchValue('')
        setSortBy({})

        break
      case 'Roles':
        setDataToAdd(RolesData)
        setContent(AddRolesContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/designations')
        setDropDownUrl(`crud/functions`)
        setSearchValue('')
        setSortBy({})

        break

      case 'Sector':
        setDataToAdd(SectorData)
        setContent(AddSectorContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/sector')
        setSearchValue('')
        setSortBy({})

        break
      case 'Industry':
        setDataToAdd(IndustryData)
        setContent(AddIndustryContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/industry')
        setDropDownUrl('crud/sector')
        setSearchValue('')
        setSortBy({})

        break
      case 'Skills':
        setDataToAdd(SkillsData)
        setContent(AddSkillsContent)
        setItemsInFilter(FilterItems)
        setUrl('crud/skill')
        setSearchValue('')
        setSortBy({})

        break
      // case 'Sub-Skills':
      //   setDataToAdd(SubSkillsData)
      //   setContent(AddSubSkillsContent)
      //   setItemsInFilter(FilterItems)

      //   break
      // case 'Holidays':
      //   setDataToAdd(HolidaysData)
      //   setContent(AddHolidayContent)
      //   setItemsInFilter(FilterItems)
      //   break
      // case 'Calendar':
      //   setDataToAdd(CalendarData)
      //   setContent(AddCalenderContent)
      //   setItemsInFilter(FilterItems)
      //   break
      default:
        break
    }
    window.history.replaceState(
      null,
      '',
      `/systemConfig/GeneralTableSettings/${data.text}/${data.id}`
    )
  }

  useEffect(() => {
    setSearchValue('')
  }, [tableData])

  const handleSubmitCongif = (value, call) => {
    if (toEditId) {
      createGeneralSysConfig({
        url,
        value,
        type: 'put',
        id: toEditId,
        isSuccess,
        success,
      })
    } else {
      createGeneralSysConfig({
        url,
        value,
        type: 'post',
        isSuccess,
        success,
      })
    }
  }

  const onUpdateStatus = (data, rowData) => {
    let value = {}
    if (menu === 'Skills') {
      value = {
        ...rowData,
        status: data ? 1 : 0,
      }
    } else if (menu == 'Industry') {
      value = {
        sectorId: rowData?.sectorIndustryMap?.[0]?.sector?.id,
        name: rowData?.name,
        isActive: data == 1 ? true : false,
      }
    } else {
      value = {
        ...rowData,
        isActive: data == 1 ? true : false,
      }
    }

    let statusId = rowData?.id
    createGeneralSysConfig({
      url,
      value,
      type: 'put',
      id: statusId,
      isSuccess,
      success,
    })
  }
  useEffect(() => {
    form.resetFields()
    onClose()
    setEditId('')
    setView(false)
  }, [success])

  const confirmDelete = data => {
    createGeneralSysConfig({
      url,
      type: 'delete',
      id: data?.id,
      isSuccess,
      success,
    })
  }

  const onClickEdit = value => {
    let ids = value.id
    form.setFieldsValue({
      name: value?.name,
      functionId: value?.functions?.id,
      sectorId: value?.sectorIndustryMap?.[0]?.sector?.id,
      id: ids,
    })
    setEditId(ids)
  }

  useEffect(() => {
    if (
      menu === 'Industry' ||
      menu === 'sector' ||
      menu === 'Function' ||
      menu === 'Role'
    ) {
      setItemsInFilter(FilterItems)
    }

    if (stateList) {
      setItemsInFilter(FilterItems)
    }
    if (cityList) {
      setItemsInFilter(FilterItems)
    }
  }, [statusValue, stateList, typeValue, stateName, cityList, menu])

  const setStatusValue = value => {
    setStatusValues(value)
  }
  const toView = data => {
    onClickEdit(data)
    setView(true)
  }

  const submitSystemConfigFilter = value => {
    let status = arrayStatus(statusValue)

    if (value == 'submit') {
      setFilterData({
        status: status?.toString(),
        statusName: statusValue,
      })
      setSubmit(!submit)
      setPageNum(1)
    }
    if (value == 'reset') {
      setSubmit(!submit)
      setItemsInFilter(FilterItems)
      onTableClick(tableData)
      setFilterData({})
    }
  }

  const FilterItems = [
    {
      label: 'Status',
      key: '1',
      visible: true,
      children: (
        // <VirtualizedCheckboxGroup
        //   options={Status}
        //   value={statusValue}
        //   setValue={setStatusValue}
        //   searchNotNeeded={true}
        //   rose={'ASDFADSFADSF'}
        // />
        <VirtualizedCheckboxGroupForStatus
          options={Status}
          value={statusValue}
          setValue={setStatusValue}
          searchNotNeeded={true}
        />
      ),
    },
  ]

  const handleFilterCall = () => {
    setItemsInFilter(FilterItems)
    setStatusValues(filterDatas?.statusName || [])
  }

  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/systemConfig">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="System Config"
                on={'General Settings'}
                path="/systemConfig"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>General Settings</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>
      <Row>
        <Col span={5}>
          <SystemPaddedDiv>
            <MasterItem
              onTableClick={onTableClick}
              id={id}
              MasterTableData={GeneralMenuData}
            />
          </SystemPaddedDiv>
        </Col>
        <Col span={0.5}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={18}>
          <SystemconfigTable
            form={form}
            tableData={tableData}
            dataToAdd={dataToAdd}
            data={GeneralTableList?.result}
            list={GeneralTableList}
            title="Add University"
            formId="form"
            sendButtonText="Submit"
            isSystemConfig={true}
            showDrawer={showDrawer}
            content={content}
            setSearchValue={setSearchValue}
            setSortBy={setSortBy}
            items={itemsInFilter}
            setPageNum={setPageNum}
            pageNum={pageNum}
            setStatusValue={setStatusValue}
            loading={loading}
            toView={toView}
            submitSystemConfigFilter={submitSystemConfigFilter}
            onUpdateStatus={onUpdateStatus}
            confirmDelete={confirmDelete}
            onEdit={onClickEdit}
            typeValue={typeValue}
            clearInput={clearInput}
            searchValue={searchValue}
            menu={menu}
            defaultFirstPageNumber={1}
            handleFilterCall={handleFilterCall}
          />
        </Col>
      </Row>
      <CrudDrawer
        open={drawer}
        onClose={onClose}
        tableData={tableData}
        content={content}
        form={form}
        handleSubmitCongif={handleSubmitCongif}
        toEditId={toEditId}
        setEditId={setEditId}
        view={view}
        setView={setView}
        addContent={addContent}
        menu={menu}
      />
    </>
  )
}
export default GeneralSysConfig
