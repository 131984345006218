import React from 'react'

const UniversityIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      <path
        d="M4 16C3.71667 16 3.479 15.904 3.287 15.712C3.09567 15.5207 3 15.2833 3 15V9.975C3 9.69167 3.09567 9.45833 3.287 9.275C3.479 9.09167 3.71667 9 4 9C4.28333 9 4.521 9.09567 4.713 9.287C4.90433 9.479 5 9.71667 5 10V15.025C5 15.3083 4.90433 15.5417 4.713 15.725C4.521 15.9083 4.28333 16 4 16ZM10 16C9.71667 16 9.47933 15.904 9.288 15.712C9.096 15.5207 9 15.2833 9 15V9.975C9 9.69167 9.096 9.45833 9.288 9.275C9.47933 9.09167 9.71667 9 10 9C10.2833 9 10.521 9.09567 10.713 9.287C10.9043 9.479 11 9.71667 11 10V15.025C11 15.3083 10.9043 15.5417 10.713 15.725C10.521 15.9083 10.2833 16 10 16ZM0.975 20C0.691667 20 0.458333 19.904 0.275 19.712C0.0916668 19.5207 0 19.2833 0 19C0 18.7167 0.0960001 18.4793 0.288 18.288C0.479333 18.096 0.716667 18 1 18H19.025C19.3083 18 19.5417 18.096 19.725 18.288C19.9083 18.4793 20 18.7167 20 19C20 19.2833 19.904 19.5207 19.712 19.712C19.5207 19.904 19.2833 20 19 20H0.975ZM16 16C15.7167 16 15.4793 15.904 15.288 15.712C15.096 15.5207 15 15.2833 15 15V9.975C15 9.69167 15.096 9.45833 15.288 9.275C15.4793 9.09167 15.7167 9 16 9C16.2833 9 16.5207 9.09567 16.712 9.287C16.904 9.479 17 9.71667 17 10V15.025C17 15.3083 16.904 15.5417 16.712 15.725C16.5207 15.9083 16.2833 16 16 16ZM10.9 0.45L19.325 4.65C19.5417 4.76667 19.7083 4.929 19.825 5.137C19.9417 5.34567 20 5.575 20 5.825C20 6.15833 19.8793 6.43733 19.638 6.662C19.396 6.88733 19.1083 7 18.775 7H1.25C0.916667 7 0.625 6.88733 0.375 6.662C0.125 6.43733 0 6.15833 0 5.825C0 5.59167 0.0543334 5.36667 0.163 5.15C0.271 4.93333 0.441667 4.775 0.675 4.675L9.1 0.45C9.38333 0.316667 9.68333 0.25 10 0.25C10.3167 0.25 10.6167 0.316667 10.9 0.45Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default UniversityIcon
