import { Empty, Spin } from 'antd'
import AntdAvatar from 'components/Avatar'
import Pagination from 'components/Pagination'
import {
  RowNumberData,
  RowNumberDataCompany,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import React, { useState } from 'react'
import { FlexWrap } from '../../../components/TableStyles/TableContentStyles'
import { toCamelCase } from '../../../utils/camelCase'
import { fixedSorterColumn } from '../../../utils/constants'
import InstituteFilter from '../InstituteFilter'
import InstituteStatus from '../InstituteStatus'
import InstituteOnboardingStatus from '../InstituteStatus/onboardingStatus'
import { EmptyWrapper, NavWrapper } from '../Style/style'
import ViewInstituteDrawer from '../ViewInstituteDrawer/index'
import ActionDropdown from './ActionDropdown'

const InstituteInfoTable = ({
  instituteList,
  page,
  search,
  loading,
  filterData,
  setFilterData,
  onPageChange,
  setSearch,
  setPage,
  setSort,
  setOrderBy,
  orderBy,
  stateId,
  setStateId,
  stateList,
  setStateList,
  stateNames,
  setStateNames,
  getListOfState,
  cityList,
  setCityList,
  cityName,
  setCityName,
  getListOfCity,
  cityId,
  setPermCityId,
  setDegreeSearch,
  instituteActiveStatus,
  setInstituteActiveStatus,
  location,
  setLocation,
  setInstituteStatus,
}) => {
  const [viewInstituteDrawer, setViewInstituteDrawer] = useState(false)

  const onSorting = (pagination, row, sorter) => {
    let order = ''
    if (sorter.order === 'descend') {
      order = 'desc'
      setSort(sorter?.field)
    } else if (sorter.order === 'ascend') {
      order = 'asc'
      setSort(sorter?.field)
    } else {
      setSort('')
    }
    setOrderBy(order)
    setPage(0)
  }

  const columns = [
    {
      title: 'INSTITUTE',
      dataIndex: 'name',
      key: 'institute_name',
      showSorterTooltip: fixedSorterColumn(orderBy),
      sorter: true,
      render: (_, rowData) => {
        const CompanyItem = (
          <FlexWrap alignCenter gap="14px">
            <AntdAvatar
              src={rowData?.instituteCampus[0]?.campusLogoUrl}
              size={35}
              IconName={rowData?.name?.split('')[0]}
              font={18}
            />
            <RowNumberDataCompany>
              {toCamelCase(rowData?.name)}
            </RowNumberDataCompany>
          </FlexWrap>
        )
        return CompanyItem // Return the JSX for rendering
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      render: (_, rowData) => (
        <RowNumberData>
          {toCamelCase(rowData?.instituteCampus[0]?.state) || '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'CITY',
      dataIndex: 'city',
      key: 'location_id',
      render: (_, rowData) => (
        <RowNumberData>
          {toCamelCase(rowData?.instituteCampus[0]?.city) || '-'}
        </RowNumberData>
      ),
    },

    {
      title: 'PLAN',
      dataIndex: 'location',
      key: 'location_id',
      render: (_, rowData) => <RowNumberData>{'Free Plan'}</RowNumberData>,
    },
    // {
    //***************************Future Use Case ***************************
    //   title: 'Next Due',
    //   dataIndex: 'location',
    //   key: 'location_id',
    //   render: (_, rowData) => <RowNumberData>{'-'}</RowNumberData>,
    // },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, rowData) => (
        <RowNumberData>
          <InstituteStatus status={rowData?.isActive} />
        </RowNumberData>
      ),
    },
    {
      title: 'ONBOARDING STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (_, rowData) => (
        <RowNumberData>
          <InstituteOnboardingStatus status={rowData?.status} />
        </RowNumberData>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          setViewInstituteDrawer={setViewInstituteDrawer}
        />
      ),
    },
  ]
  return (
    <NavWrapper column>
      <InstituteFilter
        instituteList={instituteList}
        location={location}
        setLocation={setLocation}
        searchValue={search}
        setSearchValue={setSearch}
        currentPage={page}
        setCurrentPage={setPage}
        filterData={filterData}
        setFilterData={setFilterData}
        stateId={stateId}
        setStateId={setStateId}
        stateList={stateList}
        setStateList={setStateList}
        stateNames={stateNames}
        setStateNames={setStateNames}
        getListOfState={getListOfState}
        cityList={cityList}
        setCityList={setCityList}
        cityName={cityName}
        setCityName={setCityName}
        getListOfCity={getListOfCity}
        cityId={cityId}
        setPermCityId={setPermCityId}
        setDegreeSearch={setDegreeSearch}
        setInstituteActiveStatus={setInstituteActiveStatus}
        instituteActiveStatus={instituteActiveStatus}
        setInstituteStatus={setInstituteStatus}
      />
      <StyledTable
        columns={columns}
        onChange={onSorting}
        dataSource={instituteList?.list || instituteList?.result}
        pagination={false}
        locale={{
          emptyText: (
            <EmptyWrapper center isSpin={loading}>
              {!loading ? (
                <Empty description={'No institute found!'} />
              ) : (
                <Spin />
              )}
            </EmptyWrapper>
          ),
        }}
      />
      {!loading && instituteList.count > 0 ? (
        <Pagination
          onChange={onPageChange}
          totalPages={instituteList.count}
          pageSize={10}
          current={page}
        />
      ) : null}
      <ViewInstituteDrawer
        viewInstituteDrawer={viewInstituteDrawer}
        setViewInstituteDrawer={setViewInstituteDrawer}
      />
    </NavWrapper>
  )
}

export default InstituteInfoTable
