import { Col, Form, Menu, Row } from 'antd'
import Button from 'components/Button/index'
import Switch from 'components/Form/Switch'
import 'draft-js/dist/Draft.css'
import { SKILL_BUCKET_MENU } from 'modules/RankingAlgorithm/Data.js'
import 'modules/RankingAlgorithm/style.css'
import {
  ActiveHeader,
  EventHeader,
  FooterDiv,
  FooterFlex,
  RowStyling,
  SectionWrapperToggle,
  StyledMenu,
  TopHeader2,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import CommonIndex from './Common/commonIndex'

export default function SkillBucketIndex({
  academicDataConfig,
  updateAcademictData,
  setCurrent,
  current,
  handleChange,
}) {
  const [form] = Form.useForm()
  const [defaultInternship, setDefaultInternship] = useState('Work Experince')
  const [data, setData] = useState({})
  const [academicDataAlone, setAcademicDataAlone] = useState([])
  const [academicToggle, setAcademicToggle] = useState(false)
  const [academicValidation, setAcademicValidation] = useState(false)
  const [singleDurationData, setSingleDurationData] = useState([])
  const [durationSwitch, setDurationSwitch] = useState(false)
  const [singleAcademicTierData, setSingleAcademicTierData] = useState([])
  const [currentValue, setCurrentValue] = useState('UG')
  const [academicToggle1, setAcademicToggle1] = useState(false)
  useEffect(() => {
    let data1 = academicDataConfig?.academic?.academicConfigs
    setSingleAcademicTierData(
      data1?.find(item => item?.academic === currentValue)?.tierConfig
    )
    setAcademicToggle(
      data1?.find(item => item?.academic === currentValue)?.isActive
    )
  }, [academicDataConfig])

  const academicListOnClick = event => {
    setDefaultInternship(event)
    setCurrent('left')
    if (event === 'Work Experince') {
      setAcademicToggle(data?.workExperience?.isActive)
      setDurationSwitch(data?.workExperience?.duration?.isActive)
    } else if (event === 'Academic') {
      let tabEvent = event?.toLocaleLowerCase()
      setAcademicToggle(data?.[tabEvent]?.isActive)
      setCurrentValue('UG')
    } else {
      let tabEvent = event?.toLocaleLowerCase()
      setAcademicToggle(data?.[tabEvent]?.isActive)
      setDurationSwitch(data?.[tabEvent]?.duration?.isActive)
    }
  }
  useEffect(() => {
    if (academicDataConfig) {
      setAcademicToggle(academicDataConfig?.workExperience?.isActive)
      setDurationSwitch(academicDataConfig?.workExperience?.duration?.isActive)
    }
  }, [academicDataConfig])
  useEffect(() => {
    if (data) {
      if (defaultInternship == 'Work Experince') {
        setSingleDurationData(data?.workExperience?.duration?.config || [])
      } else if (defaultInternship) {
        let tabData = defaultInternship.toLocaleLowerCase()
        setSingleDurationData(data[tabData]?.duration?.config || [])
      }
    }
  }, [defaultInternship])
  useEffect(() => {
    if (academicDataConfig) {
      setData(academicDataConfig)

      if (defaultInternship === 'Work Experince') {
        setAcademicToggle(academicDataConfig?.workExperience?.isActive)
        setDurationSwitch(
          academicDataConfig?.workExperience?.duration?.isActive
        )
      } else if (defaultInternship === 'Academic') {
        let tabEvent = defaultInternship?.toLocaleLowerCase()
        setAcademicToggle(academicDataConfig?.[tabEvent]?.isActive)
        setCurrentValue('UG')
      } else {
        let tabEvent = defaultInternship?.toLocaleLowerCase()
        setAcademicToggle(academicDataConfig?.[tabEvent]?.isActive)
        setDurationSwitch(academicDataConfig?.[tabEvent]?.duration?.isActive)
      }
      setSingleDurationData(
        academicDataConfig?.workExperience?.duration?.config || []
      )
    }
  }, [academicDataConfig])
  function deepClone(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }

    if (Array.isArray(obj)) {
      return obj.map(item => deepClone(item))
    }

    const clonedObj = {}
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        clonedObj[key] = deepClone(obj[key])
      }
    }

    return clonedObj
  }
  useEffect(() => {
    updateScoreConfigForUG()
  }, [singleDurationData, singleAcademicTierData, academicToggle1])

  const updateScoreConfigForUG = () => {
    if (defaultInternship != 'Academic') {
      setData(prevData => {
        const newData = { ...prevData }

        const clonedDATAs = deepClone(newData)

        if (defaultInternship === 'Work Experince') {
          clonedDATAs['workExperience']?.duration?.config?.splice(
            0,
            clonedDATAs['workExperience']?.duration?.config?.length,
            ...singleDurationData
          )
        } else {
          let tabData = defaultInternship.toLocaleLowerCase()
          if (defaultInternship) {
            clonedDATAs[tabData]?.duration?.config?.splice(
              0,
              clonedDATAs[tabData]?.duration?.config?.length,
              ...singleDurationData
            )
          }
        }
        return clonedDATAs
      })
    } else {
      setData(prevData => {
        const newData = { ...prevData }

        const ugAcademicIndex = newData?.academic?.academicConfigs.findIndex(
          item => item?.academic === currentValue
        )
        if (
          ugAcademicIndex !== -1 &&
          newData?.academic?.academicConfigs &&
          singleAcademicTierData
        ) {
          newData.academic.academicConfigs[ugAcademicIndex].tierConfig =
            singleAcademicTierData
          newData.academic.academicConfigs[ugAcademicIndex].isActive =
            academicToggle1
        }

        return newData
      })
    }
  }
  const onsubmit = () => {
    let mainbase = 'SKILL_BUCKET'
    let payload = data
    updateAcademictData({ mainbase, payload })
  }
  useEffect(() => {
    setDefaultInternship('Work Experince')
  }, [])
  const setToggle = checked => {
    setAcademicToggle(checked)
    setData(prevData => {
      let newData = prevData
      if (defaultInternship === 'Work Experince') {
        newData['workExperience'].isActive = checked
      } else {
        newData[defaultInternship.toLowerCase()].isActive = checked
      }
      return newData
    })
  }
  const setDurationToggle = checked => {
    setDurationSwitch(checked)
    setData(prevData => {
      let newData = prevData
      let dataEvent = defaultInternship?.toLowerCase()
      if (defaultInternship === 'Work Experince') {
        newData['workExperience'].duration.isActive = checked
      } else {
        newData[dataEvent].duration.isActive = checked
      }
      return newData
    })
  }
  return (
    <Form form={form} onFinish={onsubmit}>
      <SectionWrapperToggle column>
        <RowStyling>
          <Col span={6}>
            {SKILL_BUCKET_MENU?.map(item => (
              <StyledMenu
                key={item.name}
                selectedKeys={defaultInternship}
                defaultSelectedKeys={defaultInternship}
              >
                <Menu.Item
                  key={item?.name}
                  onClick={() => academicListOnClick(item.name)}
                >
                  {item?.name}
                </Menu.Item>
              </StyledMenu>
            ))}
          </Col>
          <Col span={18}>
            <Row>
              <TopHeader2 row spaceBetween alignCenter>
                <Col span={15}>
                  <EventHeader>{defaultInternship}</EventHeader>
                </Col>
                <Col span={6}>
                  <ActiveHeader>
                    {' '}
                    {academicToggle ? 'Active' : 'Inactive'}
                  </ActiveHeader>
                </Col>
                <Col span={3}>
                  <Switch
                    checked={academicToggle}
                    onChange={checked => setToggle(checked)}
                  />
                </Col>
              </TopHeader2>
            </Row>
            <Col span={18} style={{ maxWidth: '100%' }}>
              <CommonIndex
                keys={academicValidation}
                singleDurationData={singleDurationData}
                setSingleDurationData={setSingleDurationData}
                setAcademicDataAlone={setAcademicDataAlone}
                academicDataAlone={academicDataAlone}
                setAcademicValidation={setAcademicValidation}
                academicValidation={academicValidation}
                academicName={defaultInternship}
                updateScoreConfigForUG={updateScoreConfigForUG}
                current={current}
                setCurrent={setCurrent}
                durationSwitch={durationSwitch}
                setDurationSwitch={setDurationSwitch}
                setDurationToggle={setDurationToggle}
                academicDataConfig={academicDataConfig}
                setSingleAcademicTierData={setSingleAcademicTierData}
                singleAcademicTierData={singleAcademicTierData}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
                academicToggle={academicToggle1}
                setAcademicToggle={setAcademicToggle1}
                data={data}
                form={form}
              />
              {/* <DurationIndex
              keys={academicValidation}
              singleDurationData={singleDurationData}
              setSingleDurationData={setSingleDurationData}
              setAcademicDataAlone={setAcademicDataAlone}
              academicDataAlone={academicDataAlone}
              setAcademicValidation={setAcademicValidation}
              durationSwitch={durationSwitch}
              setDurationSwitch={setDurationSwitch}
              setDurationToggle={setDurationToggle}
              /> */}
            </Col>
          </Col>
          <FooterDiv>
            <FooterFlex right>
              <Button.Secondary text="Cancel" onClick={handleChange} />
              <Button.Primary text={'Save and Update'} htmlType={'submit'} />
            </FooterFlex>
          </FooterDiv>
        </RowStyling>
      </SectionWrapperToggle>
    </Form>
  )
}
