import { Col, Row, Switch } from 'antd'
import Button from 'components/Button/index'
import AntdInput from 'components/Form/Input'
import DeleteIcon from 'components/icons/DeleteIcon'
import PlusIcon from 'components/icons/PlusIcon'
import 'draft-js/dist/Draft.css'
import {
  CommonForm,
  InnerContextScrollForAcademic,
  PaddingContextScrollForAcademic,
} from 'modules/RankingAlgorithm/Styles/FormStyle.js'
import {
  ActiveContentPadding,
  ActiveStyle,
  EventHeader1,
  IconWrapper,
  Note,
  SwitchPadding,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useCallback, useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../../../style.css'
export default function DurationIndex({
  singleDurationData,
  academicDataAlone,
  setSingleDurationData,
  setAcademicDataAlone,
  academicName,
  isActiveNeeded = true,
  durationSwitch,
  setDurationToggle,
  isTen = false,
  form,
}) {
  // const [form] = CommonForm.useForm()

  const [error, setError] = useState('')
  const [indexNumber, setIndexNumber] = useState('')
  const [addFieldDisabled] = useState(false)
  const [keysList, setKeysList] = useState(singleDurationData)
  const updateFromValues = list => {
    return list.map((entry, index) => {
      if (index === 0) {
        entry.fromMonth = '0'
        entry.fromYear = '0'
      } else {
        const prevYear = list[index - 1].toYear
        const prevMonth = list[index - 1].toMonth
        let calculatedMonth =
          Number(prevMonth) + 1 == 12 ? 0 : Number(prevMonth) + 1
        let calculatedYear =
          Number(prevMonth) + 1 == 12 ? Number(prevYear) + 1 : Number(prevYear)
        entry.fromMonth = calculatedMonth
        entry.fromYear = calculatedYear
      }
      return entry
    })
  }

  const addToForm = useCallback(() => {
    const lastEntry = keysList[keysList.length - 2]
    if (
      !lastEntry &&
      !lastEntry.toYear == '' &&
      !lastEntry.toMonth == '' &&
      !lastEntry.score == ''
      // lastScore.score === ''
    ) {
      const newEntry = {
        fromMonth: '',
        fromYear: '',
        score: '',
        toMonth: '',
        toYear: '',
      }
      const newEntryJK = {
        fromMonth: '',
        fromYear: '',
        score: '',
        toMonth: '+',
        toYear: '+',
      }
      if (keysList.length === 0) {
        newEntry.fromMonth = '0'
        newEntry.fromYear = '0'
      } else {
        const prevYear = keysList[keysList?.length - 1].fromYear
        const prevMonth = keysList[keysList?.length - 1].fromMonth
        let calculatedMonth =
          Number(prevMonth) + 1 == 12 ? 0 : Number(prevMonth) + 1
        let calculatedYear =
          Number(prevMonth) + 1 == 12 ? Number(prevYear) + 1 : Number(prevYear)
        newEntry.fromMonth = prevMonth
        newEntry.fromYear = prevYear
        newEntry.toMonth = ''
        newEntry.toYear = ''
        newEntry.score = ''
        newEntryJK.fromMonth = calculatedMonth
        newEntryJK.fromYear = calculatedYear
      }
      // Find the index of the second-to-last object
      const secondToLastIndex = keysList.length - 2

      // Create a new array with the new object inserted
      const newArray = [
        ...keysList.slice(0, secondToLastIndex + 1),
        newEntry,
        {
          fromMonth: '',
          fromYear: '',
          score: '',
          toMonth: '+',
          toYear: '+',
        },
      ]

      setKeysList(newArray)
    }
    // Submit the form
  }, [keysList])
  const onDelete = name => {
    let updatedList = keysList?.filter((data, index) => index !== name)
    const newListWithUpdatedFrom = updateFromValues(updatedList)
    setKeysList(newListWithUpdatedFrom)
  }
  useEffect(() => {
    if (singleDurationData.length !== 0) {
      setKeysList(singleDurationData)
    }
    form.setFieldsValue({
      academic: singleDurationData,
    })

    validateDate(singleDurationData)
  }, [singleDurationData])

  const validateDate = data => {
    const B = data.map(item => {
      const toYear = item.toYear === '+' ? 0 : parseInt(item.toYear, 10)
      const toMonth = item.toMonth === '+' ? 0 : parseInt(item.toMonth, 10)
      const total = toYear * 12 + toMonth

      return total
    })
    const withoutLastNumber = B.slice(0, B.length - 1)
    const indexNumbers = findIndexNotStrictAscending(withoutLastNumber)
    if (indexNumbers !== -1) {
      setIndexNumber(indexNumbers)
      setError('Current year must be greather than previous year')
    } else {
      setError('')
      findIndexOfFirstEmptyOrNonNumeric(data)
    }
  }
  const onClickField = (value, index, type) => {
    let academic = keysList.map((data, indx) => {
      if (index === indx) {
        return {
          ...data,
          [type]: value,
        }
      }
      return data
    })

    const newListWithUpdatedFrom = updateFromValues(academic)
    setKeysList(newListWithUpdatedFrom)
    form.setFieldsValue({
      academic: academic,
    })
    updateAcademicData()
    setSingleDurationData(academic)
    validateDate(academic)
  }

  const updateAcademicData = () => {
    const updatedData = academicDataAlone.map(item => {
      if (item.academic === academicName) {
        return {
          ...item,
          ...keysList,
        }
      }
      return item
    })
    const newListWithUpdatedFrom = updateFromValues(updatedData)
    setAcademicDataAlone(newListWithUpdatedFrom)
  }

  function findIndexNotStrictAscending(arr) {
    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i] >= arr[i + 1]) {
        return i + 1 // Return the index of the first element that breaks the ascending order
      }
    }
    return -1 // Return -1 if the array is in ascending order or empty
  }

  // Function to check if an object has any empty or non-numeric values
  const hasEmptyOrNonNumericValues = obj => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key]
        if (value === '') {
          return true // Object has at least one empty or non-numeric value
        }
      }
    }
    return false // Object has no empty or non-numeric values
  }

  // Find the index of the first object with empty or non-numeric values
  const findIndexOfFirstEmptyOrNonNumeric = arr => {
    for (let i = 0; i <= arr.length; i++) {
      if (hasEmptyOrNonNumericValues(arr[i])) {
        setError('Please enter the required data')
        setIndexNumber(i)
        return i // Return the index of the first object with empty or non-numeric values
      } else {
        setIndexNumber('')
        setError('')
      }
    }
    return -1 // Return -1 if no such object is found
  }

  useEffect(() => {
    form.setFieldsValue({
      academic: keysList,
    })
    updateAcademicData()
    setSingleDurationData(keysList)

    validateDate(keysList)
    // const emptyIndex = findIndexOfFirstEmptyOrNonNumeric(keysList)
  }, [])
  // Custom validation function using the regex pattern
  const validateInput = (value, name) => {
    const regex = name === 'score' ? /^\d*\.?\d*$/ : /^\d*$/ // Only allow numbers and the period (.)

    return regex.test(value)
  }
  const handleKeyPress = (event, name, isTen) => {
    // Perform custom validation based on keypress
    const data = event.target.value + event.key
    const compare = isTen ? 10 : 5
    const number = name === 'score' ? compare : name === 'toMonth' ? 11 : 1000

    // Combine conditions to reduce redundancy
    if (Number(data) > number || !validateInput(event.key, name)) {
      event.preventDefault() // Prevent the input from updating if it's not valid or exceeds the allowed number
    }
  }

  return (
    <>
      {isActiveNeeded && (
        <ActiveContentPadding>
          <Row>
            <Col span={2}>
              <ActiveStyle>
                {durationSwitch ? 'Active' : 'Inactive'}
              </ActiveStyle>
            </Col>
            <Col span={3}>
              <SwitchPadding>
                <Switch
                  checked={durationSwitch}
                  onChange={checked => {
                    setDurationToggle(checked)
                  }}
                />
              </SwitchPadding>
            </Col>
          </Row>
        </ActiveContentPadding>
      )}
      <div id={'uploadForm'} key={keysList}>
        <CommonForm.List name="academic">
          {(fields, { add, remove }) => {
            return (
              <>
                <Row>
                  <Col span={5}>
                    <EventHeader1>Year</EventHeader1>
                  </Col>
                  <Col span={5}>
                    <EventHeader1>Month</EventHeader1>
                  </Col>
                  <Col span={5}>
                    <EventHeader1>Score</EventHeader1>
                  </Col>
                </Row>

                <InnerContextScrollForAcademic>
                  {fields?.map(({ key, name }, index) => (
                    <PaddingContextScrollForAcademic key={name}>
                      {index === fields.length - 1 && ( // Check if it's not the last data element
                        <Row>
                          <Col span={14}>
                            <CommonForm.Item>
                              <Button.Secondary
                                text={'Add Duration'}
                                width="100%"
                                icon={<PlusIcon color="#3249D7" />}
                                dashed="true"
                                htmlType="submit"
                                form={'uploadForm'}
                                disabled={addFieldDisabled}
                                onClick={() => {
                                  addToForm()
                                }}
                              />
                            </CommonForm.Item>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        {index === fields.length && (
                          <>
                            <Col span={5}>
                              <AntdInput
                                ismargin
                                placeholder={' Eg: 5'}
                                key={name}
                                disabled
                                name={[name, 'fromYear']}
                                onChange={e =>
                                  onClickField(e.target.value, name, 'fromYear')
                                }
                                suffix={
                                  <div
                                    style={{
                                      color: '#B7BAC4',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {keysList?.length - 1 !== name
                                      ? Number(keysList[name]?.fromYear) > 1
                                        ? 'Years'
                                        : 'Year'
                                      : '+Years'}
                                  </div>
                                }
                                value={keysList[name]?.fromYear}
                                height="50px"
                                width="80%"
                                borderRadius="10px"
                                backgroundColor="#FAFAFB"
                                background="#FAFAFB"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Kindly enter the data',
                                  },
                                ]}
                              />
                            </Col>
                            <Col span={5}>
                              <AntdInput
                                ismargin
                                placeholder={' Eg: 5'}
                                key={name}
                                disabled
                                name={[name, 'fromMonth']}
                                onChange={e =>
                                  onClickField(
                                    e.target.value,
                                    name,
                                    'fromMonth'
                                  )
                                }
                                suffix={
                                  <div
                                    style={{
                                      color: '#B7BAC4',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {keysList?.length - 1 !== name
                                      ? Number(keysList[name]?.fromMonth) > 1
                                        ? 'Months'
                                        : 'Month'
                                      : '+Months'}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: false,
                                    message: 'Kindly enter the data',
                                  },
                                ]}
                                value={keysList[name]?.fromMonth}
                                height="50px"
                                width="80%"
                                borderRadius="10px"
                                backgroundColor="#FAFAFB"
                                background="#FAFAFB"
                              />
                            </Col>
                          </>
                        )}
                        {keysList?.length - 1 !== name ? (
                          <>
                            <Col span={5}>
                              <AntdInput
                                ismargin
                                key={name}
                                placeholder={' Eg: 5'}
                                name={[name, 'toYear']}
                                onChange={e =>
                                  onClickField(e.target.value, name, 'toYear')
                                }
                                onKeyPress={e => handleKeyPress(e, 'toYear')}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Kindly enter the data',
                                  },

                                  {
                                    validator: (_, value) => {
                                      if (value) {
                                        if (!/^\d+$/.test(Number(value))) {
                                          return Promise.reject(
                                            new Error('Kindly enter valid data')
                                          )
                                        } else {
                                          return Promise.resolve()
                                        }
                                      }
                                      return Promise.resolve()
                                    },
                                  },
                                ]}
                                suffix={
                                  <div
                                    style={{
                                      color: '#B7BAC4',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {keysList?.length - 1 !== name
                                      ? Number(keysList[name]?.toYear) > 1
                                        ? 'Years'
                                        : 'Year'
                                      : '+Years'}
                                  </div>
                                }
                                value={keysList[name]?.toYear}
                                height="50px"
                                width="80%"
                                borderRadius="10px"
                                backgroundColor="#FAFAFB"
                              />

                              {name === indexNumber ? (
                                <CommonForm.Item
                                  name="errorMessage"
                                  rules={[
                                    {
                                      required: true,
                                      message: '',
                                    },
                                  ]}
                                  style={{
                                    marginTop: '5%',
                                    color: 'red',
                                    textWrap: 'nowrap',
                                  }}
                                >
                                  {error}
                                </CommonForm.Item>
                              ) : (
                                ''
                              )}
                            </Col>
                            <Col span={5}>
                              <AntdInput
                                ismargin
                                key={name}
                                placeholder={' Eg: 5'}
                                name={[name, 'toMonth']}
                                onKeyPress={e => handleKeyPress(e, 'toMonth')}
                                onChange={e =>
                                  onClickField(e.target.value, name, 'toMonth')
                                }
                                rules={[
                                  {
                                    required: false,
                                    message: 'Kindly enter the data',
                                  },

                                  {
                                    validator: (_, value) => {
                                      if (value)
                                        if (
                                          !/^(?:\d|10|11)$/.test(Number(value))
                                        ) {
                                          return Promise.reject(
                                            new Error('Kindly enter valid data')
                                          )
                                        } else {
                                          return Promise.resolve()
                                        }
                                      return Promise.resolve()
                                    },
                                  },
                                ]}
                                suffix={
                                  <div
                                    style={{
                                      color: '#B7BAC4',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {keysList?.length - 1 !== name
                                      ? Number(keysList[name]?.toMonth) > 1
                                        ? 'Months'
                                        : 'Month'
                                      : '+Months'}
                                  </div>
                                }
                                value={keysList[name]?.toMonth}
                                height="50px"
                                width="80%"
                                borderRadius="10px"
                                backgroundColor="#FAFAFB"
                              />
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={5}>
                              <AntdInput
                                ismargin
                                placeholder={' Eg: 5'}
                                key={name}
                                disabled
                                name={[name, 'fromYear']}
                                onChange={e =>
                                  onClickField(e.target.value, name, 'fromYear')
                                }
                                suffix={
                                  <div
                                    style={{
                                      color: '#B7BAC4',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {keysList?.length - 1 !== name
                                      ? Number(keysList[name]?.fromYear) > 1
                                        ? 'Years'
                                        : 'Year'
                                      : '+Years'}
                                  </div>
                                }
                                value={keysList[name]?.fromYear}
                                height="50px"
                                width="80%"
                                borderRadius="10px"
                                backgroundColor="#FAFAFB"
                                background="#FAFAFB"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Kindly enter the data',
                                  },
                                ]}
                              />
                            </Col>
                            <Col span={5}>
                              <AntdInput
                                ismargin
                                placeholder={' Eg: 5'}
                                key={name}
                                disabled
                                name={[name, 'fromMonth']}
                                onChange={e =>
                                  onClickField(
                                    e.target.value,
                                    name,
                                    'fromMonth'
                                  )
                                }
                                suffix={
                                  <div
                                    style={{
                                      color: '#B7BAC4',
                                      fontSize: '1rem',
                                    }}
                                  >
                                    {keysList?.length - 1 !== name
                                      ? Number(keysList[name]?.fromMonth) > 1
                                        ? 'Months'
                                        : 'Month'
                                      : '+Months'}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: false,
                                    message: 'Kindly enter the data',
                                  },
                                ]}
                                value={keysList[name]?.fromMonth}
                                height="50px"
                                width="80%"
                                borderRadius="10px"
                                backgroundColor="#FAFAFB"
                                background="#FAFAFB"
                              />
                            </Col>
                          </>
                        )}
                        <Col span={5}>
                          <AntdInput
                            ismargin
                            key={name}
                            name={[name, 'score']}
                            placeholder={' Eg: 5.00'}
                            onChange={e =>
                              onClickField(e.target.value, name, 'score')
                            }
                            onKeyPress={e => handleKeyPress(e, 'score')}
                            value={keysList[name]?.score}
                            rules={[
                              {
                                required: false,
                                message: 'Kindly enter the data',
                              },

                              {
                                validator: (_, value) => {
                                  if (
                                    value &&
                                    isTen &&
                                    !/^(?:\d(?:\.\d{1,2})?|10)$/.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error('kindly enter the valid data')
                                    )
                                  }
                                  if (
                                    value &&
                                    !isTen &&
                                    !/^(?:[0-5](?:\.\d{1,2})?)$/.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error('kindly enter the valid data')
                                    )
                                  }

                                  return Promise.resolve()
                                },
                              },
                            ]}
                            height="50px"
                            width="80%"
                            borderRadius="10px"
                            backgroundColor="#FAFAFB"
                          />

                          {keysList?.length - 1 == indexNumber &&
                            indexNumber == name && (
                              <CommonForm.Item
                                name="errorMessage"
                                rules={[
                                  {
                                    required: true,
                                    message: error,
                                  },
                                ]}
                                style={{
                                  marginTop: '5%',
                                  color: 'red',
                                  textWrap: 'nowrap',
                                }}
                              ></CommonForm.Item>
                            )}
                        </Col>
                        {name !== 0 && keysList?.length - 1 !== name && (
                          <IconWrapper
                            center
                            onClick={() => {
                              remove(name)
                              onDelete(name)
                            }}
                            disable={
                              name === 0 || keysList?.length - 1 === name
                            }
                          >
                            <DeleteIcon />
                          </IconWrapper>
                        )}
                      </Row>
                    </PaddingContextScrollForAcademic>
                  ))}
                </InnerContextScrollForAcademic>
              </>
            )
          }}
        </CommonForm.List>
        <Row>
          <PaddingContextScrollForAcademic>
            <Note>
              NOTE: According to the skill match and the duration, a maximum
              score is {isTen ? 10 : 5}.
            </Note>
          </PaddingContextScrollForAcademic>
        </Row>
      </div>
    </>
  )
}
