import { Form as AntdForm, Select } from 'antd'
import Flex from 'components/Flex'
import { StyledTabs } from 'components/TabsPaneStyles'
import styled from 'styled-components'
import { COLORS } from 'theme'
import { FONT } from 'theme/font'

export const NavWrapper = styled(Flex)`
  //padding-bottom: 50px;
`
export const TableTop = styled(Flex)`
  padding: 5px 20px 0px 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`

export const StyledP = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  ${props => props.textType && `text-transform: ${props.textType};`}
  span {
    text-transform: capitalize;
  }
`
export const StyledTab = styled(StyledTabs)`
  .ant-tabs-content {
    height: 270px !important;

    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const SubmitFlex = styled(Flex)`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const MenuFlex = styled(Flex)`
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
`
export const OverlayFlex = styled(Flex)`
  padding: 10px 10px 0 10px;
  gap: 5px;
  ul {
    padding-left: 30px;
  }
`
export const BlacklistHeading = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.PRIMARY_GREY_T_98};
  margin-bottom: 0;
`
export const StyledLi = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GREY_T_80};
  margin-bottom: 5px;
  :last-child {
    margin-bottom: 0px;
  }
`
export const Form = styled(AntdForm)`
  padding: 1rem;
`
export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0px !important;
`

export const SectionWrapper = styled(Flex)`
  width: 100%;
  height: 650px;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);

  border-radius: 10px;
  overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
`
export const SectionWrapperCorporate = styled(Flex)`
  width: 100%;
  height: 810px;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);

  border-radius: 10px;
  overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
`
export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;
`
export const TopHeaderCorp = styled(Flex)`
  position: relative;
  width: 100%;
  font-size: 2em;
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const DropDownContent = styled.div`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  // margin-top: 10px;

  color: #0a0f2b;
`

export const PaddedDiv = styled(Flex)`
  padding: 20px 25px;
  gap: 25px;
`
export const SelectStyle = styled(Select)`
  .ant-select-selector {
    position: relative;
    background-color: #fbfaff !important;
    border-color: #e3deff !important;
    border-radius: 10px !important;
    height: 38px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: #3249d7 !important;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-select-arrow {
    color: #3249d7;
  }
`
