import { ItemDeleteText } from 'components/ActionStyles/ActionStyles'
import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import ErrorMessage from 'components/UIComponents/MessagePopup/ErrorMessage'
import React, { useEffect, useState } from 'react'
import SuccessMessage from 'utils/SuccessMessage'
import { toCamelCase } from '../../../../utils/camelCase'
import { Spinner } from '../../Style/style'
import AddRoleForm from './Partials/AddRoleForm'
import { FlexWrap, Form } from './Partials/AddRoleForm/styles'

const AddRoleDrawer = ({
  form,
  addRoleDrawer,
  setAddRoleDrawer,
  userRole,
  createRoles,
  setView,
  view,
  updateRoles,
  setCurrentSingleData,
  eventList,
  setSubEventsId,
  setEventsId,
  eventsId,
  subEventsId,
  getRoleList,
  updateLoading,
  roleJourney,
  setRoleJourney,
  setFormattedData,
  formattedData,
  setFormattedInternalData,
  formattedInternalData,
  setFormattedCorporateData,
  formattedCorporateData,
  setFormattedInstituteData,
  formattedInstituteData,
  singleRoleData,
  getEvent,
  setJourney,
  internalEventsList,
  corporateEventsList,
  instituteEventsList,
}) => {
  const [loading, setLoading] = useState(false)
  const [forInternalUser, setForInternalUser] = useState(false)
  const [intPermissions, setIntPermissions] = useState({})
  const [corpPermissions, setCorpPermissions] = useState({})
  const [instPermissions, setInstPermissions] = useState({})
  const [selectionType, setSelectionType] = useState()
  const [inValid, setInValid] = useState(true)
  const [errorField, setErrorField] = useState('')

  const [journeyArray, setJourneyArray] = useState([])

  useEffect(() => {
    if (singleRoleData) {
      form.setFieldsValue({
        name: singleRoleData?.name,
        journey: singleRoleData?.journey?.split(','),
        journeys: singleRoleData?.journeys,
        forInternalUser: singleRoleData?.forInternalUser,
      })
    }

    setForInternalUser(singleRoleData?.forInternalUser)
    setJourneyArray(singleRoleData?.journeys)

    if (singleRoleData?.journeys?.length)
      setSelectionType(singleRoleData?.journeys[0])
  }, [singleRoleData])

  const validateEventsBasedOnJourney = (journeys, isSubmit = false) => {
    if (journeys?.includes('INTERNAL') && !intPermissions?.events?.length) {
      setInValid(true)
      setErrorField('INTERNAL')
      if (isSubmit) setSelectionType('INTERNAL')
      return 'Atleast One Permissions Must Be Added for the Internal Journey'
    }
    if (journeys?.includes('CORPORATE') && !corpPermissions?.events?.length) {
      setInValid(true)
      setErrorField('CORPORATE')
      if (isSubmit) setSelectionType('CORPORATE')

      return 'Atleast One Permissions Must Be Added for the Corporate Journey'
    }
    if (journeys?.includes('INSTITUTE') && !instPermissions?.events?.length) {
      setInValid(true)
      setErrorField('INSTITUTE')
      if (isSubmit) setSelectionType('INSTITUTE')

      return 'Atleast One Permissions Must Be Added for the Institute Journey'
    }
    setInValid(false)
    setErrorField('')

    return false
  }
  useEffect(() => {
    validateEventsBasedOnJourney(journeyArray)
  }, [intPermissions, corpPermissions, instPermissions])
  const oncreateRoles = async value => {
    let permissions = [intPermissions, corpPermissions, instPermissions]
    const error = validateEventsBasedOnJourney(value?.journeys, true)
    if (!error) {
      value.forInternalUser = forInternalUser
      let payload = {
        roles: { ...value, name: value.name.trim() },
        permissions,
      }
      let isEdit = singleRoleData?.id
      setLoading(true)
      let response = !isEdit
        ? await createRoles({ payload: payload, url: 'roles/bulk' })
        : await updateRoles({
            roleId: singleRoleData?.id,
            payload: payload,
            url: `/permissions/roles/${singleRoleData?.id}/bulk`,
          })
      if (response?.status >= 200 && response?.status) {
        SuccessMessage(
          isEdit ? 'Role updated successfully!' : 'Role created successfully!'
        )
        setLoading(false)
        setAddRoleDrawer(false)
        getRoleList('')
        setFormattedInternalData([])
        setFormattedCorporateData([])
        setFormattedInstituteData([])
        setJourneyArray([])
        form.resetFields()
      } else {
        ErrorMessage(response?.response?.data?.message)
        setLoading(false)
      }
    } else {
      ErrorMessage(error)
    }
  }

  const onClose = () => {
    setAddRoleDrawer(false)
    setSubEventsId('')
    setEventsId('')
    setFormattedInternalData([])
    setJourneyArray([])
    setFormattedCorporateData([])
    setFormattedInstituteData([])
    setCurrentSingleData('')
    form.resetFields()
    setView(false)
  }

  const content = () => {
    return (
      <div>
        {updateLoading && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          id={'addRoleForm'}
          form={form}
          layout="vertical"
          onFinish={oncreateRoles}
        >
          <AddRoleForm
            setView={setView}
            view={view}
            userRole={userRole}
            eventList={eventList}
            setSubEventsId={setSubEventsId}
            setEventsId={setEventsId}
            setFormattedInternalData={setFormattedInternalData}
            formattedInternalData={formattedInternalData}
            setFormattedCorporateData={setFormattedCorporateData}
            formattedCorporateData={formattedCorporateData}
            setFormattedInstituteData={setFormattedInstituteData}
            formattedInstituteData={formattedInstituteData}
            roleJourney={roleJourney}
            setRoleJourney={setRoleJourney}
            form={form}
            journeyValue={
              (!singleRoleData
                ? form.getFieldValue('journeys')
                : singleRoleData?.journeys) ?? ''
            }
            singleRoleData={singleRoleData}
            setForInternalUser={setForInternalUser}
            forInternalUser={forInternalUser}
            internalEventsList={internalEventsList}
            corporateEventsList={corporateEventsList}
            instituteEventsList={instituteEventsList}
            setInstPermissions={setInstPermissions}
            setCorpPermissions={setCorpPermissions}
            setIntPermissions={setIntPermissions}
            setInValid={setInValid}
            validateEventsBasedOnJourney={validateEventsBasedOnJourney}
            setSelectionType={setSelectionType}
            selectionType={selectionType}
            setJourneyArray={setJourneyArray}
            journeyArray={journeyArray}
          />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FlexWrap>
        {errorField && errorField !== selectionType && (
          <div style={{ textAlign: 'start', width: '50%' }}>
            <ItemDeleteText>
              Atleast One Permissions Must Be Added for the{' '}
              {toCamelCase(errorField)} Journey
            </ItemDeleteText>
          </div>
        )}
        <FlexWrap
          gap="20px"
          width={errorField && errorField !== selectionType ? '50%' : '100%'}
          right
        >
          <Button.Secondary text="Close" height="38px" onClick={onClose} />
          {!view ? (
            <Button.Primary
              text={singleRoleData?.id ? 'Update' : 'Add'}
              htmlType="submit"
              form={'addRoleForm'}
              height="38px"
              loading={loading}
              disabled={inValid}
            />
          ) : null}
        </FlexWrap>
      </FlexWrap>
    )
  }
  return (
    <Drawer
      width="40%"
      title={
        view
          ? 'Preview'
          : singleRoleData?.id
          ? 'Edit Roles and Permissions'
          : 'Add Roles and Permissions'
      }
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={addRoleDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddRoleDrawer
