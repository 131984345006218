import React, { useState } from 'react'
// antDesign
import { Empty, Spin } from 'antd'
// Styled Components
import { CenterAlignedText } from './style'
//imported
import Pagination from 'components/Pagination'
import {
  RowNumberData,
  Wrapper,
} from 'components/TableStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import Avatar from '../../../.././components/Avatar'
import { FlexWrap } from '../../../../components/TableStyles/TableContentStyles'
import { toCamelCase } from '../../../../utils/camelCase'
import { EmptyWrapper, TableDiv } from '../../Style/style'
import ViewUserRoleDrawer from '../ViewUserRoleDrawer'
import ActionDropdown from './ActionDropdown'
import UserStatus from './UserStatus'

const UsersTable = ({
  userMetrics,
  setSortBy,
  loading,
  getSingleUserInfo,
  singleUserData,
  corporateViewData,
  getCorporatesView,
  setAddUserDrawer,
  setUserId,
  updateUserStatus,
  deleteUser,
  getUserData,
  onPageChange,
  pageNum,
  updateLoading,
  getUserMetrics,
  getSingleUser,
  updateUser,
  userId,
}) => {
  const [viewUserDrawer, setViewUserDrawer] = useState(false)

  const onSorting = (pagination, filter, sorter) => {
    setSortBy(sorter)
  }

  const StringConversion = string => {
    return string.toLowerCase()
  }

  const columns = [
    {
      title: 'user',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_, rowData) => (
        <FlexWrap gap={'10px'} display="flex" flexDirection="column">
          <Avatar
            background
            src={''}
            marginRight={'10px'}
            size={35}
            IconName={rowData?.name?.split('')[0]}
            font={18}
          />

          <RowNumberData isMail={true}>
            <TableDiv>{toCamelCase(rowData?.name)}</TableDiv>
            <div>{StringConversion(rowData?.userEmail)}</div>
          </RowNumberData>
        </FlexWrap>
      ),
    },
    {
      title: 'user role',
      dataIndex: 'userType',
      key: 'userType',
      sorter: true,
      render: (_, rowData) => (
        <RowNumberData>{toCamelCase(rowData?.userType)}</RowNumberData>
      ),
    },
    {
      title: <CenterAlignedText>Corporates</CenterAlignedText>,
      dataIndex: 'Corporates',
      key: 'Corporates',
      render: (_, rowData) => (
        <CenterAlignedText> {rowData?.corporate} </CenterAlignedText>
      ),
    },
    {
      title: <CenterAlignedText>institutes</CenterAlignedText>,
      dataIndex: 'institutes',
      key: 'institutes',
      render: (_, rowData) => (
        <CenterAlignedText>{rowData?.institute}</CenterAlignedText>
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      // sorter: true,
      render: rowData => { 
         if (!rowData) {
      return <CenterAlignedText>-</CenterAlignedText>;
    }
    
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(new Date(rowData)).replace(/am|pm/i, match => match.toUpperCase())

    return <CenterAlignedText>{formattedDate}</CenterAlignedText>;
  },
     
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (status, rowData) =>
        rowData?.status === 0 || rowData?.status === 1 ? (
          <UserStatus status={rowData?.status} />
        ) : (
          '-'
        ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          setViewUserDrawer={setViewUserDrawer}
          getCorporatesView={getCorporatesView}
          corporateViewData={corporateViewData}
          getSingleUserInfo={getSingleUserInfo}
          setAddUserDrawer={setAddUserDrawer}
          setUserId={setUserId}
          updateUserStatus={updateUserStatus}
          updateUser={updateUser}
          deleteUser={deleteUser}
          getUserData={getUserData}
          getSingleUser={getSingleUser}
          userId={userId}
          getUserMetrics={getUserMetrics}
        />
      ),
    },
  ]

  return (
    <>
      <Wrapper>
        <StyledTable
          columns={columns}
          pagination={false}
          dataSource={userMetrics?.data?.result}
          onChange={onSorting}
          locale={{
            emptyText: (
              <EmptyWrapper center isSpin={loading}>
                {!loading ? <Empty description={'No user found!'} /> : <Spin />}
              </EmptyWrapper>
            ),
          }}
        />
        {!!userMetrics?.data?.count && (
          <Pagination
            onChange={onPageChange}
            totalPages={userMetrics?.data?.count}
            pageSize={userMetrics?.data?.limit}
            current={pageNum}
          />
        )}
      </Wrapper>

      <ViewUserRoleDrawer
        viewUserDrawer={viewUserDrawer}
        setViewUserDrawer={setViewUserDrawer}
        userMetrics={userMetrics}
        singleUserData={singleUserData}
        updateLoading={updateLoading}
      />
    </>
  )
}

export default UsersTable
