import { Spin } from 'antd'
import Flex from 'components/Flex'

import {
  RowNumberDataReport,
  TreeMargin,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tree from 'components/Tree'
import React, { useEffect, useState } from 'react'
const DegreeAndDepartmentFilter = ({
  degreeList,
  setDegreeList,
  searchQuery,
  degree,
  filterCancel,
  setFilterCancel,
  setSearchDegree,
  loading,
  loadMoreData,
  count,
  page = 0,
  degreeLoader,
}) => {
  const [checkedNodes, setCheckedNodes] = useState([])
  const [child, setChild] = useState([])
  const [filteredDegreeList, setFilteredDegreeList] = useState(degreeList)
  useEffect(() => {
    let finalData = []
    if (page == 0) {
      setFilteredDegreeList([])
      setChild([])
      finalData = degreeList
    } else {
      let data = degreeList || []
      if (degreeList) finalData = [...filteredDegreeList, ...data]
    }
    setFilteredDegreeList(finalData)
  }, [degreeList])

  const updateCheckedValue = nodes => {
    return nodes.map(node => {
      if (node.children && node.children.length > 0) {
        return {
          ...node,
          checked: true,
          children: updateCheckedValue(node.children),
        }
      } else {
        return {
          ...node,
          checked: true,
        }
      }
    })
  }
  const setCheckedValue = (nodes, value) => {
    return nodes.map(node => ({
      ...node,
      checked: value,
      children: node.children ? setCheckedValue(node.children, value) : null,
    }))
  }

  const mergeNodes = (existingNodes, newNodes, checked) => {
    const mergedNodes = [...existingNodes]
    const bca = setCheckedValue(newNodes, checked)
    bca?.forEach(newNode => {
      const existingIndex = mergedNodes.findIndex(
        node => node.key === newNode.key
      )

      if (existingIndex === -1) {
        mergedNodes.forEach(node => {
          if (node.children && node.children.length > 0) {
            return {
              ...node,
              checked: checked,
              children: updateCheckedValue(node.children),
            }
          } else {
            return {
              ...node,
              checked: checked,
            }
          }
        })
        mergedNodes.push({ ...newNode, checked: checked })
      } else {
        mergedNodes[existingIndex].checked = checked
        mergedNodes[existingIndex].children = mergeNodes(
          mergedNodes[existingIndex].children || [],
          newNode.children || [],
          checked
        )
      }
    })

    return mergedNodes
  }
  const findParentKey = (nodes, childKey) => {
    for (const node of nodes) {
      if (node.key === childKey) {
        return null // Child key has no direct parent in the provided array
      }
      if (node.children && node.children.length > 0) {
        if (node.children.some(child => child.key === childKey)) {
          return node.key // Return the parent key if any child has the matching key
        }
        const result = findParentKey(node.children, childKey)
        if (result !== null) {
          return result // Return the parent key from the nested children
        }
      }
    }
    return null // Child key not found in the current nodes
  }

  //
  const removeNodeFromCheckedNodes = (nodes, selectedValue) => {
    return nodes.filter(node => {
      if (node.value === selectedValue) {
        return false
      } else if (node.children && node.children.length > 0) {
        node.children = removeNodeFromCheckedNodes(node.children, selectedValue)
        return node.children.length > 0
      } else {
        return true
      }
    })
  }
  const onCheck = (checkedKeys, info) => {
    const { node, checked } = info
    const selectedValue = node.value // Get the value of the clicked node
    const activeNodes = info.checkedNodes
    const filteredNodes = activeNodes.filter(
      item => item.value === selectedValue
    ) // Filter the nodes by the selected value
    const parentKey = findParentKey(filteredDegreeList, selectedValue)

    let mergedNodes = []
    if (!checked) {
      if (selectedValue) {
        setChild([])
        let node = filteredDegreeList?.find(item => item.key == selectedValue)
        let childNode = node?.children?.map(item => item.key)
        if (childNode) {
          setChild(childNode)
          let filterChildFromChecked = checkedNodes.filter(
            value => !childNode.includes(value.key)
          )
          mergedNodes = removeNodeFromCheckedNodes(
            filterChildFromChecked,
            selectedValue
          )
        } else {
          mergedNodes = removeNodeFromCheckedNodes(checkedNodes, selectedValue)
        }
      }

      // If the node is unchecked, remove it from the checkedNodes state
    } else {
      // If the node is checked, perform the merge operation as before
      mergedNodes = mergeNodes(checkedNodes, filteredNodes, checked)
    }
    setCheckedNodes(mergedNodes)
    let parentData = mergedNodes?.map(item => item?.key)
    let childData = mergedNodes?.map(item => item?.children)
    let dataChild = childData?.flatMap(arr => arr?.map(obj => obj.key))
    let finalData = parentData?.concat(dataChild)
    const remainingValues = finalData.filter(key => key !== parentKey)
    setDegreeList(remainingValues)
  }
  useEffect(() => {
    if (child) {
      const remainingArray = degree?.filter(value => !child.includes(value))
      const filteredArray = remainingArray?.filter(Boolean)
      setDegreeList(filteredArray)
    }
  }, [child])
  useEffect(() => {
    filterDegreeList(searchQuery)
  }, [searchQuery])
  useEffect(() => {
    if (degree && filterCancel) {
      if (degree.length == 0 && filterCancel === true) {
        setCheckedNodes([])
        setFilterCancel(false)
      }
    }
  }, [degree, filterCancel])
  const filterDegreeList = query => {
    setSearchDegree(query)
  }
  const Spinner = (
    <>
      {loading ? (
        <Spin />
      ) : !loading && filteredDegreeList <= 0 ? (
        <RowNumberDataReport>No Data Found</RowNumberDataReport>
      ) : (
        ''
      )}
    </>
  )
  return (
    <>
      {!loading && filteredDegreeList?.length > 0 ? (
        <InfiniteScroll
          dataLength={filteredDegreeList?.length}
          height="18vh"
          next={loadMoreData}
          hasMore={filteredDegreeList.length < count}
          loader={<Flex center>{degreeLoader && <Spin small />}</Flex>}
        >
          <TreeMargin>
            <Tree
              key={filteredDegreeList}
              treeData={filteredDegreeList}
              checkedKeys={degree}
              onCheck={onCheck}
            />
          </TreeMargin>
        </InfiniteScroll>
      ) : (
        <div style={{ marginTop: '10px' }}>
          <Flex center>{Spinner}</Flex>
        </div>
      )}
    </>
  )
}

export default DegreeAndDepartmentFilter
