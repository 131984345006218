import CorporateFilter from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/CorporateFilter'
import CorporateTable from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/CorporateTable'
import { NavWrapper } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/Style/style'
import { getCorporatesListData } from 'modules/Onboarding/Partials/Corporates/Partials/CorporateInfoTable/actions'
import { getSingleCorporateData } from 'modules/Onboarding/Partials/Corporates/Register/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
const CorporateInfoTable = ({
  corporatesList,
  createCorporate,
  clearInput,
  setClearInput,
}) => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const [searchBy, setSearchBy] = useState('')
  const [corporateStatus, setCorporateStatus] = useState('')
  const [location, setLocation] = useState('')
  const [locationFilter, setLocationFilter] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [filterData, setFilterData] = useState({})

  useEffect(() => {
    dispatch(
      getCorporatesListData({
        currentPage,
        searchBy,
        filterData,
        sortBy,
      })
    )
  }, [currentPage, searchBy, filterData, sortBy])

  useEffect(() => {
    setCurrentPage(0)
  }, [searchBy, corporateStatus, location, sortBy])

  const corporateList = useSelector(
    state => state?.CorporateList?.corporatesList
  )

  return (
    <NavWrapper column>
      <CorporateFilter
        corporateList={corporateList}
        createCorporate={createCorporate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchValue={setSearchBy}
        searchValue={searchBy}
        corporateStatus={corporateStatus}
        setCorporateStatus={setCorporateStatus}
        location={location}
        setLocation={setLocation}
        setLocationFilter={setLocationFilter}
        locationFilter={locationFilter}
        setFilterData={setFilterData}
        filterData={filterData}
        clearInput={clearInput}
      />

      <CorporateTable
        getSingleCorporateData={getSingleCorporateData}
        corporateList={corporateList}
        createCorporate={createCorporate}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
        setSortBy={setSortBy}
        sortBy={sortBy}
        setClearInput={setClearInput}
        getCorporatesListData={getCorporatesListData}
      />
    </NavWrapper>
  )
}

export default CorporateInfoTable
