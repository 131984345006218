import AntdInput from 'components/Form/Input'
import AntdSelect from 'components/Form/Select'
import {
  SectionHeading,
  TwoFields,
} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { searchConfig } from 'utils/config'
import CONSTANTS from 'utils/constants'
import { getMasterSearchApi } from '../../../../../../Onboarding/actions'
import { Empty, Spin } from 'antd'
import {SpinWrapper} from '../Style/style'


const InstitutesDetails = ({
  onCountryChange,
  onUniversityChange,
  universityList,
  countryList,
  stateList,
  cityList,
  onStateChange,
  onCityChange,
  isTemAddress,
  countryId,
  stateId,
  getStateData,
  getCityData,
  setSearchCountry,
  form,
  onKeyPress,
  loadingCountry,
  loadingState,
  loadingCity,
  setLoadingCountry,
  setLoadingState,
  setLoadingCity,
}) => {
  const dispatch = useDispatch()

  const delayStateToDb = useCallback(
    debounce((data1, data2, data3) => {
      getStateData(data1, data2, data3)
    }, 500),
    []
  )

  const delayCitiesToDb = useCallback(
    debounce((data1, data2, data3, data4) => {
      getCityData(data1, data2, data3, data4)
    }, 500),
    []
  )

  const delayUniversityToFetch = useCallback(
    debounce(data => {
      //change 4
      let payload = {
        ...searchConfig?.universities,
        q: data || '',
      }
      dispatch(getMasterSearchApi('universities', payload))
      // dispatch(getUniversityList(data))
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const handleClear = () => {
    let payload = {
      ...searchConfig?.universities,
      q: '',
    }
    dispatch(getMasterSearchApi('universities', payload));
  };
  const validateTier = (rule, value, callback) => {
    const tier = parseInt(value, 10)
    if (tier < 1 || tier > 4) {
      callback('Tier must be a number between 1 and 4')
    } else {
      callback()
    }
  }
  return (
    <>
      <SectionHeading>Institute</SectionHeading>
      <AntdSelect
        width="100%"
        label="University Name *"
        placeholder="Eg. Pune University"
        name={'universityName'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('University name'),
          },
        ]}
        OptionData={universityList}
        valueParam="name"
        nameParam="name"
        showSearch={true}
        onSearch={delayUniversityToFetch}
        onChange={onUniversityChange}
        onClear={handleClear} 
        filterOption={false}
        allowClear={true}
        value={form.getFieldValue(['universityName'])}
      />
      <AntdInput
        width="100%"
        label="Institute Name"
        placeholder="Eg.IIAM"
        optional={'*'}
        name={'name'}
        rules={[
          {
            required: true,
            // pattern: CONSTANTS.REGEX.NON_SPACE,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('college name'),
          },
          {
            pattern: /^(?!\s).*$/,
            message: 'Institute Name cannot start with a space',
          },
        ]}
        value={form.getFieldValue(['name'])}
      />

      <AntdInput  
        width="100%"
        label="Institute Short Name (Alias)"
        placeholder="Eg.IIT"
        name={'shortName'}
        rules={[
          {
            required: false,
            // pattern: CONSTANTS.REGEX.NON_SPACE,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('short name of your college'),
          },
        ]}
        value={form.getFieldValue(['name'])}
      />

      <TwoFields spaceBetween>
        <AntdInput
          width="100%"
          label="Tier"
          placeholder="Eg.1"
          optional={'*'}
          name={'tier'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('tier'),
            },
            {
              pattern: /^\d+$/,
              message: 'Tier must be a number',
            },
            {
              validator: validateTier,
            },
          ]}
        />
        <AntdInput
          width="100%"
          label="Ranking"
          placeholder="Eg.1"
          optional={'*'}
          name={'ranking'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('ranking'),
            },
            {
              pattern: /^\d+$/,
              message: 'Rank must be a number',
            },
          ]}
        />
      </TwoFields>

      {/* <SectionHeading>Institute Campus</SectionHeading> */}
      {/* <AntdInput
        width="100%"
        label="Institute Campus Name"
        placeholder="Eg.IIAM"
        optional={'*'}
        name={'campusName'}
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('campus name'),
          },
        ]}
      /> */}
      <AntdInput
        id={'Website'}
        label="Website *"
        placeholder="Eg. www.google.com"
        width="100%"
        name={'website'}
        // type="url"
        rules={[
          {
            required: true,
            message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('website'),
          },
          // {
          //   pattern: CONSTANTS.REGEX.WEBSITE,
          //   message: CONSTANTS.ERROR_MESSAGE.VALID('website'),
          // },
        ]}
        onChange={event => {
          form.setFieldsValue({
            website: event.target.value.trim(),
          })
        }}
        value={form.getFieldValue(['website'])}
      />

      <TwoFields spaceBetween>
        <AntdInput
          width="100%"
          label="Address Line 1 *"
          placeholder="Eg.Street Name"
          name={'addressLine1'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('address Line 1'),
            },
            {
              pattern: /^(?!\s).*$/,
              message: 'An address cannot start with a space',
            },
          ]}
        />
        <AntdInput
          id={'Location'}
          label="Campus Location *"
          placeholder="Eg. Tiruchendur"
          width="100%"
          name={'campusLocation'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('city'),
            },
            {
              pattern: /^(?!\s).*$/,
              message: 'Location cannot start with a space',
            },
          ]}
        />
        
      </TwoFields>
      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="Country *"
          placeholder="Eg.India"
          name={'country'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('Country'),
            },
          ]}
          OptionData={loadingCountry ? [] : countryList}
          notFoundContent={
            loadingCountry ? (
              <SpinWrapper center>
              <Spin />
            </SpinWrapper>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          valueParam="name"
          nameParam="name"
          onChange={onCountryChange}
          showSearch={true}
          onSearch={data => {
            setLoadingCountry(true)
            setSearchCountry(data)
          }}
          value={countryId ? form.getFieldValue(['country']) : ''}
          allowClear={true}
          filterOption={false}
        />
        <AntdSelect
          width="100%"
          label="State *"
          placeholder="Eg.Tamil Nadu"
          optional={'*'}
          name={'state'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('State'),
            },
          ]}
          OptionData={loadingState ? [] : stateList}
          notFoundContent={
            loadingState ? (
              <SpinWrapper center>
              <Spin />
            </SpinWrapper>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          valueParam="name"
          nameParam="name"
          onChange={onStateChange}
          showSearch={true}
          // disabled={!(stateList?.length > 0)}
          disabled={!countryId ? true : false}
          onSearch={data => 
          {
            setLoadingState(true),
            delayStateToDb(countryId, false, data)
          }
          }
          value={form.getFieldValue(['state'])}
          onKeyPress={onKeyPress}
          allowClear={true}
          filterOption={false}
        />
      </TwoFields>

      <TwoFields spaceBetween>
        <AntdSelect
          width="100%"
          label="City *"
          placeholder="Eg.Chennai"
          optional={'*'}
          name={'city'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('City'),
            },
          ]}
          OptionData={loadingCity ? [] : cityList}
          notFoundContent={
            loadingCity ? (
              <SpinWrapper center>
              <Spin />
            </SpinWrapper>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          valueParam="name"
          nameParam="name"
          onChange={onCityChange}
          showSearch={true}
          disabled={!form.getFieldValue('state')}
          onSearch={data =>
          {
            setLoadingCity(true),
            delayCitiesToDb(countryId, stateId, !isTemAddress, data)
          }
          }
          value={form.getFieldValue(['city'])}
          onKeyPress={onKeyPress}
          allowClear={true}
          filterOption={false}
        />
        <AntdInput
          width="100%"
          label="Zipcode"
          placeholder="Eg.604407"
          optional={'*'}
          name={'postalCode'}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('Zipcode'),
            },
            {
              pattern: CONSTANTS.REGEX.PIN_CODE,
              message: CONSTANTS.ERROR_MESSAGE.VALID('Zipcode'),
            },
          ]}
        />
      </TwoFields>
    </>
  )
}

export default InstitutesDetails
