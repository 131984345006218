import { Col, Row } from 'antd'
import AntdInput from 'components/Form/Input'
import Switch from 'components/Form/Switch'
import 'draft-js/dist/Draft.css'
import {
  Form,
  InnerContextScrollForAcademic,
  PaddingContextScrollForAcademic,
} from 'modules/RankingAlgorithm/Styles/FormStyle.js'
import {
  DomainHead,
  DomainRow,
  Note,
  SwitchPadding,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../../style.css'

export default function DomainIndex({
  academicName,
  domainData,
  setDomainData,
  form,
}) {
  const [totalValue, setTotalValue] = useState(0)
  const [keysList, setKeysList] = useState({})
  const [functionSwitch, setFunctionSwitch] = useState({
    industry: false,
    role: false,
    function: false,
  })
  useEffect(() => {
    if (domainData) {
      setKeysList(domainData)
      let activeScore = 0
      for (const key in domainData) {
        if (domainData[key].isActive === true) {
          activeScore += parseInt(domainData[key]?.score)
        }
      }

      form.setFieldsValue({
        industry: parseInt(domainData?.industry?.score),
        function: parseInt(domainData?.function?.score),
        role: parseInt(domainData?.role?.score),
      })

      setTotalValue(activeScore)
    }
  }, [domainData])

  const onClickField = (value, index, type) => {
    let academic = keysList
    if (type) {
      academic[index][type] = value
    }
    setFunctionSwitch({
      ...functionSwitch,
      [index]: value,
    })

    let totalScores = 0

    for (const key in academic) {
      if (Object.prototype.hasOwn(academic, key) && academic[key].isActive) {
        totalScores += Number(academic[key].score)
      }
    }
    setTotalValue(totalScores)
    setKeysList(academic)
    setDomainData(academic)
  }
  const validateInput = value => {
    const regex = /^[0-9.]*$/ // Only allow numbers and the period (.)
    return regex.test(value)
  }
  const handleKeyPress = event => {
    // Perform custom validation based on keypress
    const data = event.target.value + event.key
    const number = 5

    // Combine conditions to reduce redundancy
    if (Number(data) > number || !validateInput(event.key)) {
      event.preventDefault() // Prevent the input from updating if it's not valid or exceeds the allowed number
    }
  }

  // useEffect(() => {
  //   form.resetFields()
  // }, [academicName])
  return (
    <div id={'uploadForm'} key={keysList}>
      <InnerContextScrollForAcademic key={keysList}>
        <Row>
          <DomainRow>
            <Col span={4}>
              <DomainHead>Industry</DomainHead>
            </Col>
            <Col span={4}>
              <DomainHead>
                <AntdInput
                  ismargin
                  name={'industry'}
                  placeholder={' Eg: 5.00'}
                  onKeyPress={handleKeyPress}
                  onChange={e =>
                    onClickField(e.target.value, 'industry', 'score')
                  }
                  defaultValue={domainData?.industry?.score}
                  height="50px"
                  width="80%"
                  borderRadius="10px"
                  disabled={!keysList?.industry?.isActive}
                  backgroundColor="#FAFAFB"
                  rules={[
                    {
                      required: domainData?.industry?.isActive,
                      message: 'Kindly enter the data. Eg: 3.00',
                    },
                  ]}
                />
              </DomainHead>
            </Col>
            <Col span={2}>
              <DomainHead>
                {' '}
                {keysList?.industry?.isActive ? 'Active' : 'Inactive'}
              </DomainHead>
            </Col>
            <Col span={3}>
              <SwitchPadding>
                <Switch
                  checked={keysList?.industry?.isActive}
                  onChange={checked =>
                    onClickField(checked, 'industry', 'isActive')
                  }
                />
              </SwitchPadding>
            </Col>
          </DomainRow>
        </Row>
        <Row>
          <DomainRow>
            <Col span={4}>
              <DomainHead>Function</DomainHead>
            </Col>
            <Col span={4}>
              <DomainHead>
                <AntdInput
                  ismargin
                  name={'function'}
                  placeholder={' Eg: 5.00'}
                  onKeyPress={handleKeyPress}
                  onChange={e =>
                    onClickField(e.target.value, 'function', 'score')
                  }
                  defaultValue={domainData?.function?.score}
                  disabled={!keysList?.function?.isActive}
                  height="50px"
                  width="80%"
                  borderRadius="10px"
                  backgroundColor="#FAFAFB"
                  rules={[
                    {
                      required: domainData?.function?.isActive,
                      message: 'Kindly enter the data. Eg: 3.00',
                    },
                  ]}
                />
              </DomainHead>
            </Col>
            <Col span={2}>
              <DomainHead>
                {keysList?.function?.isActive ? 'Active' : 'Inactive'}
              </DomainHead>
            </Col>
            <Col span={3}>
              <SwitchPadding>
                <Switch
                  checked={keysList?.function?.isActive}
                  onChange={checked =>
                    onClickField(checked, 'function', 'isActive')
                  }
                />
              </SwitchPadding>
            </Col>
          </DomainRow>
        </Row>
        <Row>
          <DomainRow>
            <Col span={4}>
              <DomainHead>Role</DomainHead>
            </Col>
            <Col span={4}>
              <DomainHead>
                <AntdInput
                  ismargin
                  name={'role'}
                  placeholder={' Eg: 5.00'}
                  onChange={e => onClickField(e.target.value, 'role', 'score')}
                  onKeyPress={handleKeyPress}
                  defaultValue={domainData?.role?.score}
                  disabled={!keysList?.role?.isActive}
                  rules={[
                    {
                      required: domainData?.role?.isActive,
                      message: 'Kindly enter the data. Eg: 3.00',
                    },
                  ]}
                  height="50px"
                  width="80%"
                  borderRadius="10px"
                  backgroundColor="#FAFAFB"
                />
              </DomainHead>
            </Col>
            <Col span={2}>
              <DomainHead>
                {keysList?.role?.isActive ? 'Active' : 'Inactive'}
              </DomainHead>
            </Col>
            <Col span={3}>
              <SwitchPadding>
                <Switch
                  checked={keysList?.role?.isActive}
                  onChange={checked =>
                    onClickField(checked, 'role', 'isActive')
                  }
                />
              </SwitchPadding>
            </Col>
          </DomainRow>
        </Row>

        {totalValue !== 5 && (
          <Form.Item
            name="totalValue"
            rules={[
              {
                required: totalValue !== 5,
                message: 'The sum of active domain must be 5',
              },
            ]}
          ></Form.Item>
        )}
        <Row>
          <PaddingContextScrollForAcademic>
            <Note>
              NOTE: According to the skill match and the duration, a maximum
              score is 5.
            </Note>
          </PaddingContextScrollForAcademic>
        </Row>
      </InnerContextScrollForAcademic>
    </div>
  )
}
