import { Avatar, Col } from 'antd'
import Pagination from 'components/Pagination'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import AntdAvatar from '../../../../../../components/Avatar'
import SearchInput from '../../../../../../components/SearchInput'
import TuneSetting from '../../../../../../components/icons/TuneSetting'
import {
  CandidatesSpan,
  CircleCheckbox,
  Count,
  ListDivider,
  ListName,
} from '../../Style/style'
import InstituteFilter from '../Institute'
const { COUNTRY_ID } = process.env

const AddUserForm = ({
  userRole,
  searchValue,
  journeyId,
  getId,
  getInstituteView,
  setJourneyId,
  pageNumber,
  setPageNumber,
  loc,
  FunctionTableList,
  getListOfState,
  getListOfCity,
  setChecked,
  checked,
  setPermCityId,
  cityId,
  stateId,
  setStateId,
  tierId,
  setTierId,
  instituteViewData,
  setCheckedCount,
  universityList,
  university,
  setUniversity,
  fetchUniversityData,
  fetchDegreeData,
  degreeList,
  degree,
  setDegree,
  department,
  setDepartment,
  departmentList,
  fetchDepartmentData,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [citiesData, setCitiesData] = useState([])
  const [tierName, setTierName] = useState([])
  const [search, setSearch] = useState('')
  const [location, setLocation] = useState('')
  const [value, setValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [degreeSearch, setDegreeSearch] = useState('')
  const [stateList, setStateList] = useState([])
  const [stateNames, setStateNames] = useState([])
  const [searchCollege, setSearchCollege] = useState('')
  const [industry, setIndustry] = useState('')
  const [industryName, setIndustryName] = useState([])
  const [debounced, setDebounced] = useState(() => search)
  const [cityList, setCityList] = useState([])
  const [cityName, setCityName] = useState([])
  const [useingId, setUseingId] = useState('')
  const [universityName, setUniversityName] = useState([])
  const [degreeName, setDegreeName] = useState([])
  const [departmentName, setDepartmentName] = useState([])
  const [check, setCheck] = useState(false)

  const params = useParams()

  const citiesList = useSelector(
    state => state?.CorporateData?.citiesWithPagination
  )
  const userIdLocation = useLocation()
  useEffect(() => {
    if (userIdLocation) {
      const parts = userIdLocation?.pathname?.split('/')
      const data = parts?.[parts?.length - 1]
      setUseingId(data)
    }
  }, [userIdLocation])

  React.useEffect(() => {
    let pageNo = pageNumber
    let searchValue = search || ''
    let city = cityName
    let userId = params.id
    let state = stateNames != '' ? stateNames : undefined
    let tier = tierName != '' ? tierName : undefined

    getInstituteView({ userId, searchValue, pageNo, city, state, tier })
  }, [pageNumber])
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(search)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const getCities = (search = '', pageNum = '') => {
    let searchValue = search
    let pageNo = pageNum
    let userId = params.id
    let city = cityName
    let state = stateNames != '' ? stateNames : undefined
    let tier = tierName != '' ? tierName : undefined

    getInstituteView({ userId, searchValue, pageNo, city, state, tier })
  }
  useEffect(() => {
    setCitiesData([])
    getCities(search)
  }, [debounced])

  useEffect(() => {
    if (citiesList?.cities?.length) {
      let newList = [...citiesData, ...(citiesList?.cities ?? [])]
      setCitiesData(newList)
    }
  }, [citiesList?.cities])

  const handleCheckboxChange = e => {
    if (Array.isArray(checked)) {
      if (checked.includes(e.target.value)) {
        setChecked(checked.filter(item => item !== e.target.value))
        setCheckedCount(prevCount => prevCount - 1) // Step 2: Decrease count when deselected
      } else {
        setChecked([...checked, e.target.value])
        setCheckedCount(prevCount => prevCount + 1) // Step 2: Increase count when selected
      }
    } else {
      setChecked([e.target.value])
      setCheckedCount(1) // Step 2: Initialize count to 1 when first selected
    }
    let data = e.target.value
    if (e.target.checked) {
      let a = [...journeyId, ...[data]]
      setJourneyId(a)
    } else {
      let removeData = journeyId?.filter(item => item !== e.target.value)
      setJourneyId(removeData)
    }
  }

  const submitFilter = () => {
    setIsMenuOpen(false)
    setFilterData({
      location: cityId,
      status: stateId,
      tier: tierId,
      university: university,
      degree: degree,
      department: department,
    })

    let pageNo = '1'
    let city = cityId
    let state = stateId != '' ? stateId : undefined
    let tier = tierId != '' ? tierId : undefined
    let universitydata = universityList.filter(item =>
      university.includes(item.name)
    )

    let degreedata = degreeList.filter(item => degree.includes(item.name))
    let departmentdata = departmentList.filter(item =>
      department.includes(item.name)
    )

    let userId = useingId
    getInstituteView({
      userId,
      pageNo,
      city,
      state,
      tier,
      searchValue,
      university: universitydata.map(item => item.id).join(','),
      degree: degreedata.map(item => item.id).join(','),
      department: departmentdata.map(item => item.id).join(','),
    })

    setValue(false)
    // setState(userStatus ? userStatus : 'all')
  }

  useEffect(() => {
    setCheck(prev => !prev)
    setTierId(filterData?.tier || [])
    setStateId(filterData?.status || [])
    setPermCityId(filterData?.location || [])
    setUniversity(filterData?.university || [])
    setDegree(filterData?.degree || [])
    setDepartment(filterData?.department || [])
    fetchUniversityData('')
    fetchDegreeData('')
    fetchDepartmentData('')
  }, [value])

  const onPageChange = key => {
    setPageNumber(key)
  }

  let getCityData = async (countryId, stateId, isSate, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await getListOfCity(countryId, stateId, search, 0, pageLimit)
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setCityList(updatedArray || [])
    }
  }

  useEffect(() => {
    setPermCityId('')
    getCityData(COUNTRY_ID, stateNames, true)
  }, [stateId])

  return (
    <>
      <Fragment>
        <SearchInput
          id={'search'}
          placeholder="Search"
          width="100%"
          name={'website'}
          rules={[
            {
              required: false,
              message: 'Please enter Search!',
            },
          ]}
          setSearchValue={e => {
            setSearch(e)
          }}
          iconPosition="suffix"
          suffix={
            <Avatar
              style={{ cursor: 'pointer' }}
              onClick={() => setValue(!value)}
              src={<TuneSetting />}
            />
          }
        />
        {value ? (
          <InstituteFilter
            setValue={setValue}
            getListOfState={getListOfState}
            FunctionTableList={FunctionTableList}
            setLocation={setLocation}
            location={location}
            value={value}
            submitFilter={submitFilter}
            setIsMenuOpen={setIsMenuOpen}
            isMenuOpen={isMenuOpen}
            loading={loading}
            setDegreeSearch={setDegreeSearch}
            setLoading={setLoading}
            stateId={stateId}
            setStateId={setStateId}
            degreeSearch={degreeSearch}
            stateList={stateList}
            setStateList={setStateList}
            setStateNames={setStateNames}
            searchCollege={searchCollege}
            setSearchCollege={setSearchCollege}
            industry={industry}
            industryName={industryName}
            setIndustry={setIndustry}
            setIndustryName={setIndustryName}
            stateNames={stateNames}
            tierName={tierName}
            setTierName={setTierName}
            tierId={tierId}
            setTierId={setTierId}
            cityList={cityList}
            cityId={cityId}
            setPermCityId={setPermCityId}
            cityName={cityName}
            setCityName={setCityName}
            setCityList={setCityList}
            fetchUniversityData={fetchUniversityData}
            universityList={universityList}
            university={university}
            setUniversity={setUniversity}
            universityName={universityName}
            setUniversityName={setUniversityName}
            fetchDegreeData={fetchDegreeData}
            degreeList={degreeList}
            degree={degree}
            setDegree={setDegree}
            setDegreeName={setDegreeName}
            degreeName={degreeName}
            fetchDepartmentData={fetchDepartmentData}
            departmentList={departmentList}
            department={department}
            setDepartment={setDepartment}
            setDepartmentName={setDepartmentName}
            departmentName={setDepartmentName}
            check={check}
          />
        ) : null}
        <Count>
          Total Institute
          <CandidatesSpan>{instituteViewData?.count}</CandidatesSpan>
        </Count>
        <div>
          {instituteViewData?.list?.map(item => (
            <>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <AntdAvatar
                    size={65}
                    IconName={item?.name.charAt(0)}
                    font={18}
                    src={item?.logo}
                  />
                  <Col column style={{ paddingLeft: '10px' }}>
                    <ListName>{item?.name}</ListName>
                    {item?.instituteCampus.map(data => (
                      <Count key={data.id}>{data?.city}</Count>
                    ))}
                  </Col>
                </div>
                <div>
                  <CircleCheckbox
                    key={item?.id}
                    value={item?.id}
                    checked={
                      Array.isArray(checked) && checked.includes(item?.id)
                    }
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <ListDivider />
            </>
          ))}
        </div>
      </Fragment>
      <Pagination
        onChange={onPageChange}
        totalPages={instituteViewData?.count}
        pageSize={instituteViewData?.limit}
        current={pageNumber}
      />
    </>
  )
}

export default AddUserForm
