import request from 'utils/corporateRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import studentRequest from 'utils/studentRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'
export const SET_GENERAL_LIST = 'institute/SET_GENERAL_LIST'
export const SET_FUNCTION_LIST = 'institute/SET_FUNCTION_LIST'

export const setGeneralList = data => {
  return {
    type: SET_GENERAL_LIST,
    data,
  }
}

export const setFunctionList = data => {
  return {
    type: SET_FUNCTION_LIST,
    data,
  }
}

export const getGeneralListForSysConfig =
  ({
    url,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status

      const Status =
        status == '1'
          ? url == 'crud/skill'
            ? `&status=${1}`
            : `&isActive=${true}`
          : status == '0'
          ? url == 'crud/skill'
            ? `&status=${0}`
            : `&isActive=${false}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      let response = {}
      if (
        url == 'crud/skill' ||
        url == 'crud/functions' ||
        url == 'crud/sector'
      ) {
        const options = {
          method: type,
          url: `/${
            url == 'crud/skill' ? 'students' : 'corporate'
          }/${url}?${pageLimit}${pageNo}${Status}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}`,
        }
        response = await elasticSearchRequest(options)
      } else {
        const options = {
          method: type,
          url: `/corporate/${url}?${pageLimit}${pageNo}${Status}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}`,
        }
        response = await request(options)
      }

      dispatch(setGeneralList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setGeneralList([]))
        setLoading(false)
      }
    }
  }

export const createGeneralSysConfig =
  ({ url, type = 'post', value, id, isSuccess, success }) =>
  async () => {
    try {
      const Id = id ? `/${id}` : ``
      let response = {}
      if (url == 'crud/skill') {
        response = await studentRequest({
          method: type,
          url: `/students/${url}${Id}`,
          data: value,
        })
      } else {
        response = await request({
          method: type,
          url: `/corporate/${url}${Id}`,
          data: value,
        })
      }
      function formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      }

      let payload = {
        query_date: formatDate(new Date()), // This will format today's date as "yyyy-mm-dd"
      }

      await elasticSearchSyncRequest.post(`/sync/skill_master`, payload)
      await elasticSearchSyncRequest.post(`/sync/student_crud_skill`, payload)
      await elasticSearchSyncRequest.post(`/sync/functiondata`, payload)
      await elasticSearchSyncRequest.post(`/sync/sectordata`, payload)

      SuccessMessage(response?.data?.message)
      isSuccess(!success)
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
    }
  }

export const getFunctionSysConfig =
  ({
    dropDownUrl,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async dispatch => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status
      const Status =
        status == '1'
          ? `&isActive=${true}`
          : status == '0'
          ? `&isActive=${false}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/corporate/${dropDownUrl}?${pageLimit}${pageNo}${Status}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}`,
      }
      const response = await request(options)
      dispatch(setFunctionList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        dispatch(setFunctionList([]))
        setLoading(false)
      }
    }
  }

export const getCorporateMasterData =
  ({
    dropDownUrl,
    type = 'get',
    pageSize,
    pageNumber,
    setLoading,
    searchValue = '',
    filterDatas,
    sortBy,
  }) =>
  async () => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      let degreeType = encodeURIComponent(filterDatas?.type || '')
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let status = filterDatas?.status
      const Status =
        status == '1'
          ? `&isActive=${true}`
          : status == '0'
          ? `&isActive=${false}`
          : ``
      const State = state ? `&stateId=${state}` : ``
      const City = city ? `&cityId=${city}` : ``
      const DegreeType = degreeType ? `&degreeType=${degreeType}` : ``
      const Search = searchValue
        ? `&search=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&order=${sortBy.orderBy}` : ``
      const pageLimit = pageSize ? `pageLimit=${pageSize}` : ``
      const pageNo = pageNumber.toString() ? `&pageNo=${pageNumber}` : ``
      const options = {
        method: type,
        url: `/corporate/${dropDownUrl}?${pageLimit}${pageNo}${Status}${Search}${DegreeType}${Sort}${OrderBy}${State}${City}`,
      }
      const response = await request(options)
      let result = response?.data?.data || []
      if (setLoading) setLoading(false)
      return result
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
      return []
    }
  }
