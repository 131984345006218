export const degreeListConvertion = listOfDegree => {
  return (
    listOfDegree?.length &&
    listOfDegree?.map(item => {
      return {
        title: item?.name,
        value: item?.id,
        children: item?.stream?.map(data => {
          return {
            title: data?.name,
            value: data?.degreeStreamMapId,
          }
        }),
      }
    })
  )
}
