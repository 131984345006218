import React from 'react'

const CorporateMasterIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      style={{ margin: 'auto' }}
    >
      <path
        d="M9.13259 7.12999H11.4064V9.68633H12.9069V1.07612C12.9069 0.481846 12.4255 0 11.8312 0H1.21191C0.617633 0 0.135498 0.481846 0.135498 1.07612V22.924C0.135498 23.5183 0.617585 24 1.21191 24H5.03247V17.0049H7.99201V11.1503C7.99201 10.5144 8.45995 9.98648 9.13226 9.78034V7.13004H9.13259V7.12999ZM9.13259 2.15243H11.4064V5.38099H9.13259V2.15243ZM3.94866 20.3051H1.67499V17.0765H3.9487V20.3051H3.94866ZM3.94866 15.5786H1.67499V12.3498H3.9487V15.5786H3.94866ZM3.94866 10.3585H1.67499V7.12999H3.9487V10.3585H3.94866ZM3.94866 5.38123H1.67499V2.15243H3.9487V5.38123H3.94866ZM7.74079 15.5786H5.46751V12.3498H7.74079V15.5786ZM7.74079 10.3585H5.46751V7.12999H7.74079V10.3585ZM7.74079 5.38123H5.46751V2.15243H7.74079V5.38123ZM18.9529 10.5472H9.9586C9.45516 10.5472 9.04696 10.8172 9.04696 11.1503V23.3966C9.04696 23.7302 9.45516 24 9.9586 24H13.1947V20.079H15.7931V24H18.9529C19.4562 24 19.8645 23.7302 19.8645 23.3966V11.1503C19.8645 10.8172 19.4562 10.5472 18.9529 10.5472ZM12.2762 21.9287H10.3506V20.1192H12.2762V21.9287ZM12.2762 19.2794H10.3506V17.4698H12.2762V19.2794ZM12.2762 16.3537H10.3506V14.5441H12.2762V16.3537ZM12.2762 13.5634H10.3506V11.7536H12.2762V13.5634ZM15.4889 19.2794H13.5632V17.4698H15.4889V19.2794ZM15.4889 16.3537H13.5632V14.5441H15.4889V16.3537ZM15.4889 13.5634H13.5632V11.7536H15.4889V13.5634ZM18.5934 21.9691H16.6678V20.1595H18.5934V21.9691ZM18.5934 19.2794H16.6678V17.4698H18.5934V19.2794ZM18.5934 16.3537H16.6678V14.5441H18.5934V16.3537ZM18.5934 13.5634H16.6678V11.7536H18.5934V13.5634Z"
        fill="#3249D7"
      />
    </svg>
  )
}
export default CorporateMasterIcons
