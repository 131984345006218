import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import Button from 'components/Button/index'
import { Checkbox, Collapse, Divider,Input,Tag } from 'antd'
import Drawer from 'components/Drawer'

export const SectionWrapper = styled(Flex)`
  width: 100%;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  margin: 30px 100px 20px 100px;
  border-radius: 10px;
  overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-device-width: 767px) {
    margin: 10px;
  }
  @media only screen and (min-width: 768px )  and  (max-width : 1200px){
    margin: 10px;
  }
`

export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const ShowNum = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  padding-top: 10px;
  padding-right: 15px;
  font-size: 13px;
  line-height: 18px;
  color: ${COLORS.GREY_T_30};
`
export const AnchorText = styled.a`
  font-style: normal;
  font-weight: 500;
  padding-top: 10px;
  padding-right: 25px;
  font-size: 13px;
  line-height: 18px;
  color: ${COLORS.PRIMARY};
`

export const TopRightFlex = styled(Flex)`
  gap: 15px;
`
export const SmallButtons = styled(Button.Default)`
  background: ${COLORS.WHITE};
  padding: 9px;
  > div {
    margin-left: 0;
  }
`
export const CardRow = styled(Flex)`
  padding: 0 25px 25px 25px;
  gap: 20px;
`

export const FilterCollapse = styled(Collapse)`
  background-color: #fafafb !important;
  margin-right: 5px;

  .ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px !important;
    font-weight: 600;
    border-radius: 10px !important;
    border-radius: 5px;
    color: ${COLORS.GREY_T_60} !important;
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }
`

export const StyleLeft = styled.div`
  border-left: 1px solid #e6e7ea;
  padding-left: 10px;
`

export const LocationDiv = styled.div`
  height: 200px;
  overflow-y: scroll;
`

export const VerticalDivider = styled(Divider)`
  height: 30px;
`
export const HorizontalDivider = styled(Divider)`
  margin: 20px 0;
`
  


export const OutlineDiv = styled.div`
  height: 70px;
  padding-top: 10px;
`

export const RightDiv = styled.div`
  position: absolute;
  right: 0%;
  padding-right: 70px;
`

export const ContentCenterDiv = styled.div`
  text-align: center;
`
export const CenterButton = styled.div`
display: flex;
justify-content: center;
padding-bottom: 20px;
`
export const PaddedLeftFlex = styled(Flex)`
  padding-left: 50px;
  @media only screen and (max-device-width: 767px) {
    padding-left: 0px;
  }
  @media only screen and (min-width: 768px )  and  (max-width : 1200px){
    padding-left: 0px;
  }
`

export const StyledFlex = styled.div`
  position: static;
  padding: 5px 48px 5px 20px;
  width: '100%';
  height: 140px;
  border-radius: 10px;
  border: 1px solid #e6e7ea;
  margin: 20px;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #1b1f22;
`

export const Address = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  opacity: 0.48;
  @media only screen and (max-device-width: 767px) {
    font-size: 11px;
    line-height: 18px;
  }
  @media only screen and (min-width: 768px )  and  (max-width : 1200px){
    font-size: 11px;
    line-height: 18px;
  }
`
export const BoldText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
`

export const NameAdd = styled(Flex)`
  gap: 3px;
  padding-left: 15px;
`

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const FooterFlex = styled(Flex)`
  gap: 20px;
`
export const Name1 = styled.div`
  font-weight: 600;
  font-size: 16px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  color: #343e59;
`
export const PopHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding-top: 15px;
`
export const PopSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808697;
  padding-top: 5px;
`

export const SearchInput = styled(Input)`
::placeholder {
  font-size: 16px;
}
width: ${props => (props.width ? props.width : '250px')} !important;
height: ${props => (props.height ? props.height : '40px')};
border-radius: 8px;
box-shadow: none;
border-color: ${props => (props.error ? 'red' : '#e9e9e9')};
:focus {
  border-color: #e9e9e9;
  box-shadow: none;
}
:hover {
  border-color: #e9e9e9;
}
:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #e9e9e9 !important;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border-right-width: 0px !important;
}
`
export const StyledCheckbox = styled(Checkbox)`
padding-top: 10px;
  .ant-checkbox + span {
    padding-left: 12px;
  }
  .ant-checkbox-group-item {
    width: 100%;
    padding-top: 10px;
  }
  .ant-checkbox-wrapper {
    padding: 5px !important;
  }
`
export const StyledCheckboxGroup = styled(Checkbox.Group)`

  .ant-checkbox-group-item {
    width: 90%;
    padding-top: 15px;
  }

`
export const FilterChipPanel = styled.div`
padding: 5px 0px 10px 30px;
`

export const StyledTag = styled(Tag)`
line-height: 30px;
background: #F2F3F5;
border: 1px solid #F2F3F5;
font-weight: 600;
`

export const RecentHeading = styled.div`
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 28px;
color: #000000;
padding: 10px 25px;
`

export const SpinWrapper = styled(Flex)`
  padding-top: 25px;
`
export const NoDataText = styled.div`
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 18px;
color: #999FAC;`

export const DetailHeader = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 18px;
color: #343E59;
padding-bottom:10px`

export const SubDetailHeader=styled.div`
font-weight: 500;

`


export const LeftText= styled.div`
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #808697;
padding-top:14px;
`

export const RightText= styled.div`
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #4D566E;
padding-top:14px;
`
