import { connect } from 'react-redux'
import InstEmpanelledTable from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/index.js'
import {
  getInstituteListForReport,
  getUniversityList,
  getCityList,
  getTierList,
} from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/actions'
import selectors from 'modules/Reports/Partials/Institutes/Partials/InstEmpanelledTable/selectors'
import {getUserInfo } from '../../../../Auth/actions'
import selector from '../../../../Auth/selectors'

const mapStateToProps = state => ({
  instituteListDataForReport: selectors?.instituteListReportData(state),
  universityList: selectors?.universityListData(state),
  cityList: selectors?.cityListData(state),
  tierList: selectors?.tierListData(state),
  excelDataForReport: selectors?.excelData(state),
  userData: selector?.getUserData(state),

})

const mapDispatchToProps = {
  getInstituteListForReport,
  getUniversityList,
  getCityList,
  getTierList,
  getUserInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(InstEmpanelledTable)
