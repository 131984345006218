/* eslint-disable */
import React from 'react'
import { Input as AntdInput, Form } from 'antd'
import Label from 'components/Form/Label'
import styled, { css } from 'styled-components'

const { Item } = Form

const StyledItem = styled(Item)`
  > div {
    width: 100%;
    text-align: left;
    ${props =>
      props.hasBorderRight &&
      css`
        border-right: 1px solid #e6e7ea !important;
        height: 50px !important;
      `}
  }
  border-radius: 8px;
  margin-bottom: 0px !important;
  background: ${props => (props.background ? props.background : '')};
`

const AntdInputStyle = styled(AntdInput)`
  ::placeholder {
    font-size: 16px;
  }
  height: ${props => (props.height ? props.height : '48px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '8px')};
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : ''}!important;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : ''} !important;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '')};
  box-shadow: none;
  border-right: ${props => (props.borderRight ? 'unset' : '')};
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  border-color: ${props => (props.error ? 'red' : '#e9e9e9')};
  :focus {
    border-color: #e9e9e9;
    box-shadow: none;
  }
  :hover {
    border-color: #e9e9e9;
  }
  :not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e9e9e9 !important;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-right-width: 0px !important;
  }
`
const Input = ({
  label,
  type,
  name,
  rules,
  onChange,
  placeholder,
  required,
  background,
  disabled,
  width = '552px',
  height,
  marginRight,
  labelStyle,
  defaultValue,
  optional,
  noStyle = undefined,
  display,
  hasBorderRight,
  paddingRight,
  borderRight,
  textAlign,
  trigger,
  validateStatus,
  help,
  ...rest
}) => {
  return (
    
      <StyledItem
        style={{
          width: width,
          marginRight: marginRight,
          display: display,
          paddingRight: paddingRight,
        }}
        background={background}
        rules={rules}
        trigger={trigger}
        noStyle={noStyle}
        name={name}
        colon={false}
        validateStatus={validateStatus}
        help={help}
        required={false}
        label={
          label && (

              <Label required={required} labelStyle={labelStyle}>
                {label} <span>{optional}</span>
              </Label>
            
          )
        }
        hasBorderRight={hasBorderRight}
      >
        <AntdInputStyle
          {...rest}
          defaultValue={defaultValue}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          height={height}
          style={{
            borderRight: borderRight,
            textAlign: textAlign,
          }}
        />
      </StyledItem>
    
  )
}
export default Input
