import Button from 'components/Button'
import FilterDiv from 'components/FilterDiv'
import VirtualizedCheckboxGroup from 'components/UIComponents/Filter/index'
import {
  MenuFlex,
  StyledTab,
  SubmitFlex,
} from 'modules/Courses/Partials/AddCourseDrawer/Style/style'
import DegreeAndDepartmentFilter from 'modules/Courses/Partials/CourseFilter/Partials/DegreeAndDepartmentFilter'
import DurationFilter from 'modules/Courses/Partials/CourseFilter/Partials/DurationFilter'
import ExamTypeFilter from 'modules/Courses/Partials/CourseFilter/Partials/ExamTypeFilter'
import StatusFilter from 'modules/Courses/Partials/CourseFilter/Partials/StatusFilter'
import React, { useEffect, useState } from 'react'
import { degreeListConvertion } from 'utils/degreeListConversion'
import { dropDownValues } from '../../../Systemconfig/Partials/InstituteSettings/actions'
const { TabPane } = StyledTab

const CourseFilter = ({
  degreeList,
  setDuration,
  setExamType,
  setDegreeFilter,
  listOfStudentSkills,
  setSkillList,
  setStatusFilter,
  systemConfig,
  getDepartmentListForSysConfig,
  departmentTableList,
  setDepartmentFilter,
  setDegreeMapId,
  getInstituteListForSysConfig,
  instituteTableList,
  getSpecialisationListForSysConfig,
  specialisationFilter,
  setSpecialisationFilter,
  specialisationTableList,
  domainFilter,
  setDomainFilter,
}) => {
  const [degreeSearch, setDegreeSearch] = useState('')
  const [departmentSearch, setDepartmentSearch] = useState('')
  const [filters, setFilters] = useState({})
  const [durationValue, setDurationValue] = useState([])
  const [statusValue, setStatusValue] = useState([])
  const [examtypeValue, setExamTypeValue] = useState([])
  const [streamIdList, setStreamIdList] = useState([])
  const [selectedSkill] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [degreeListData, setDegreeListData] = useState([])
  const [degreeFilterValue, setDegreeFilterValue] = useState([])
  const [departmentFilterValue, setDepartmentFilterValue] = useState([])
  const [departmentListSysConfig, setDepartmentListSysConfig] = useState([])
  const [degreeId, setDegreeId] = useState([])
  const [departmentId, setDepartmentId] = useState([])
  const [specialisationListSysConfig, setSpecialisationListSysConfig] =
    useState([])
  const [specialisationFilterValue, setSpecialisationFilterValue] = useState([])
  const [specId, setSpecId] = useState([])
  const [specialisationSearch, setSpecialisationSearch] = useState('')
  const [check, setCheck] = useState(false)
  const [domainId, setDomainId] = useState([])
  const [domainValues, setDomainValues] = useState([])
  const [domainName, setDomainName] = useState([])
  const [filterData, setFilterData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dropDownValues({ url: 'crud/domains?status=1' })
        setDomainValues(response.data.result)
      } catch (error) {
        console.error('Error fetching domain values:', error)
      }
    }
    fetchData()
  }, [])
  const handleSelectedIdsChange = selectedIds => {
    const names = domainValues
      .filter(item => selectedIds.includes(item.id))
      .map(item => item.id)
    setDomainId(names)
  }

  useEffect(() => {
    const updatedData = degreeListConvertion(degreeList)
    setDegreeListData(updatedData)
  }, [degreeList])

  useEffect(() => {}, [systemConfig])

  useEffect(() => {
    gettingDepartmentData(degreeSearch)
  }, [degreeSearch])

  const gettingDegreeData = data => {
    let url = 'crud/degree'
    let searchValue = data
    getInstituteListForSysConfig({
      url,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,
      searchValue,
      filterDatas: {status: 1,isActive: 1}
    })
  }

  const gettingSpecialisationData = data => {
    let url = 'crud/specialisation'
    let searchValue = data
    getSpecialisationListForSysConfig({
      url,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,
      searchValue,
    })
  }
  const gettingDepartmentData = data => {
    let url = 'crud/streams'
    let searchValue = data
    getDepartmentListForSysConfig({
      url,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,
      searchValue,
    })
  }

  useEffect(() => {
    gettingSpecialisationData(specialisationSearch)
  }, [specialisationSearch])

  useEffect(() => {
    if (systemConfig) {
      let url = 'crud/streams'
      getDepartmentListForSysConfig({
        url,
        type: 'get',
        pageSize: 100,
        pageNumber: 0,
      })
    }
    gettingDegreeData()
    gettingSpecialisationData()
  }, [systemConfig])

  useEffect(() => {
    gettingDegreeData(departmentSearch)
  }, [departmentSearch])

  const [degreeFilterList, setDegreeFilterList] = useState([])

  useEffect(() => {
    if (systemConfig) {
      setDepartmentListSysConfig(departmentTableList?.result)
        console.log(instituteTableList?.result,"instituteTableList")
      setDegreeFilterList(instituteTableList?.result)
      setSpecialisationListSysConfig(specialisationTableList?.result)
    }
  }, [
    systemConfig,
    departmentTableList,
    instituteTableList,
    specialisationTableList,
  ])

  const onStatusChange = status => {
    if (status.length) {
      setStatusValue([status[status.length - 1]])
      setFilters(prev => {
        return { ...prev, status: status[status.length - 1] }
      })
    } else {
      setStatusValue([])
      let filterCopy = { ...filters }
      delete filterCopy.status
      setFilters(filterCopy)
    }
  }

  const onDurationChange = durations => {
    if (durations.length) {
      setDurationValue(durations)
      setFilters(prev => {
        return { ...prev, durations: durations }
      })
    } else {
      setDurationValue([])
      let filterCopy = { ...filters }
      delete filterCopy.durations
      setFilters(filterCopy)
    }
  }

  const onExamTypeChange = examtype => {
    if (examtype.length) {
      setExamTypeValue(examtype)
      setFilters(prev => {
        return { ...prev, examtype: examtype }
      })
    } else {
      setExamTypeValue([])
      let filterCopy = { ...filters }
      delete filterCopy.examtype
      setFilters(filterCopy)
    }
  }

  const submitFilter = () => {
    setFilterData({
      durationValue: durationValue,
      examtypeValue: examtypeValue,

      selectedSkill: selectedSkill,
      statusValue: statusValue,
      domainId: domainId,
      specialisationFilterValue: specialisationFilterValue,
      degreeId: degreeId,
      departmentId: departmentId,
      departmentFilterValue: departmentFilterValue,
      specId: specId,
    })
    setDuration(durationValue)
    setExamType(examtypeValue)
    setDegreeFilter(streamIdList)
    setSkillList(selectedSkill)
    setStatusFilter(statusValue)
    setIsMenuOpen(false)

    setDomainFilter(domainId)
    setDepartmentFilter(departmentId)
    setSpecialisationFilter(specId)
    if (systemConfig) setDegreeMapId(degreeId)
  }
  const resetFilter = () => {
    setFilterData({})
    setFilters({})
    setDuration([])
    setExamType([])
    setDegreeFilter([])
    setSkillList([])
    setStatusFilter([])
    setIsMenuOpen(false)
    setDegreeMapId('')
    setDegreeId('')
    setDepartmentId('')
    setDepartmentFilter('')
    setDomainFilter('')
    setSpecialisationFilter('')
    setStatusValue([])
    setDegreeFilterValue([])
    setDepartmentFilterValue([])
    setSpecialisationFilterValue([])
    setDurationValue([])
    setExamTypeValue([])
    setCheck(prevCheck => !prevCheck)
    let url = 'crud/degree'
    let search = ''
    getInstituteListForSysConfig({
      url,
      type: 'get',
      pageSize: 100,
      pageNumber: 0,
      search,
      filterDatas: {status: 1,isActive: 1}
    })
    gettingSpecialisationData('')
  }

  useEffect(() => {
    if (systemConfig && isMenuOpen) {
      gettingDegreeData('')
      gettingDepartmentData('')
      gettingSpecialisationData('')
      setCheck(!check)
    }
    if (systemConfig) {
      setDurationValue(filterData?.durationValue || [])
      setExamTypeValue(filterData?.examtypeValue || [])
      setDomainId(filterData?.domainId || [])
      setDegreeFilterValue(filterData?.degreeId || [])
      setDegreeId(filterData?.degreeId || [])
      setSpecialisationFilterValue(filterData?.specialisationFilterValue || [])
      setDepartmentId(filterData?.departmentId || [])

      setDepartmentFilterValue(filterData?.departmentFilterValue || [])
      setSpecId(filterData?.specId || [])
      setStatusValue(filterData?.statusValue || '')
    }
  }, [isMenuOpen, systemConfig])

  const onDegreeFilterChange = data => {
    setStreamIdList(data)
  }
  // useEffect(() => {
  //   let url = 'crud/streams'
  //   let searchValue = degreeSearch
  //   getDepartmentListForSysConfig({
  //     url,
  //     type: 'get',
  //     pageSize: 10,
  //     pageNumber: 0,
  //     searchValue,
  //   })
  // }, [degreeSearch,searchValue])

  const menu = (
    <MenuFlex column>
      <StyledTab tabPosition={'left'} type="card" height="388px">
        {!systemConfig && (
          <TabPane tab="Degree & Department" key="1">
            <DegreeAndDepartmentFilter
              degreeList={degreeListData || []}
              setDegreeList={onDegreeFilterChange}
              saveById={true}
            />
          </TabPane>
        )}

        {systemConfig && (
          <>
            <TabPane tab="Degree" key="1">
              <VirtualizedCheckboxGroup
                options={degreeFilterList}
                value={degreeFilterValue}
                setValue={setDegreeFilterValue}
                valueParam="id"
                labelParam="name"
                stateName={degreeId}
                setStateName={setDegreeId}
                filterId={true}
                setDegreeSearch={setDepartmentSearch}
                degreeSearch={departmentSearch}
                saveById={true}
                check={check}
              />
            </TabPane>
            <TabPane tab="Department" key="6">
              <VirtualizedCheckboxGroup
                options={departmentListSysConfig || []}
                value={departmentFilterValue}
                setValue={setDepartmentFilterValue}
                valueParam="id"
                labelParam="name"
                stateName={departmentId}
                setStateName={setDepartmentId}
                filterId={true}
                setDegreeSearch={setDegreeSearch}
                degreeSearch={degreeSearch}
                check={check}
              />
            </TabPane>
            <TabPane tab="Specialisation " key="7">
              <VirtualizedCheckboxGroup
                options={specialisationListSysConfig || []}
                value={specialisationFilterValue}
                setValue={setSpecialisationFilterValue}
                valueParam="id"
                labelParam="name"
                stateName={specId}
                setStateName={setSpecId}
                filterId={true}
                setDegreeSearch={setSpecialisationSearch}
                degreeSearch={specialisationSearch}
                check={check}
              />
            </TabPane>
          </>
        )}

        <TabPane tab="Domain " key="8">
          <VirtualizedCheckboxGroup
            options={domainValues}
            value={domainId}
            valueParam="id"
            labelParam="name"
            setValue={handleSelectedIdsChange}
            stateName={domainName}
            setStateName={setDomainName}
            filterId={true}
            saveById={true}
            check={check}
          />
        </TabPane>
        <TabPane tab="Duration" key="2">
          <DurationFilter
            setStatusList={onDurationChange}
            value={durationValue}
          />
        </TabPane>
        <TabPane tab="Exam Type" key="3">
          <ExamTypeFilter
            setStatusList={onExamTypeChange}
            value={examtypeValue}
          />
        </TabPane>

        <TabPane tab="Status" key="5">
          <StatusFilter setStatusList={onStatusChange} value={statusValue} />
        </TabPane>
      </StyledTab>
      <SubmitFlex right>
        {systemConfig && (
          <Button.Secondary text="Reset" onClick={resetFilter} />
        )}
        <Button.Secondary
          text="Cancel"
          onClick={() => {
            setIsMenuOpen(false)
          }}
        />
        <Button.Primary onClick={submitFilter} text="Apply Filter" />
      </SubmitFlex>
    </MenuFlex>
  )

  const onOpenChange = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <FilterDiv
      overlayMenu={menu}
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      onOpenChange={onOpenChange}
    />
  )
}

export default CourseFilter
