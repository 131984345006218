import {
  EventHeader1,
  PaddedToggleDive,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useEffect, useState } from 'react'
import DurationIndex from 'modules/RankingAlgorithm/Partials/SkillBucket/Common/Duration/durationIndex.js'
import Toggle from 'components/UIComponents/Toggle/MultipleOptionsTier'
import TierIndex from 'modules/RankingAlgorithm/Partials/AcademicGrid/Tier/TierIndex'
export default function CommonIndex({
  keys,
  singleDurationData,
  academicDataAlone,
  setSingleDurationData,
  setAcademicDataAlone,
  setAcademicValidation,
  academicName,
  current,
  setCurrent,
  durationSwitch,
  setDurationSwitch,
  setDurationToggle,
  academicDataConfig,
  singleAcademicTierData,
  setSingleAcademicTierData,
  currentValue,
  setCurrentValue,
  academicToggle,
  setAcademicToggle,
  data,
  form,
}) {
  // const [singleAcademicTierData, setSingleAcademicTierData] = useState(academicDataConfig?.academic?.academicConfigs?.find(item => item?.academic === 'UG')?.tierConfig)
  const [academicListSelection, setAcademicListSelection] =
    useState(currentValue)

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      let data1 = data?.academic?.academicConfigs
      setSingleAcademicTierData(
        data1?.find(item => item?.academic === academicListSelection)
          ?.tierConfig
      )
      setAcademicToggle(
        data1?.find(item => item?.academic === academicListSelection)?.isActive
      )
    }
  }, [currentValue, academicListSelection])
  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      let data1 = data?.academic?.academicConfigs
      setSingleAcademicTierData(
        data1?.find(item => item?.academic === academicListSelection)
          ?.tierConfig
      )
      setAcademicToggle(
        data1?.find(item => item?.academic === academicListSelection)?.isActive
      )
    }
  }, [academicName])
  const onClick = target => {
    setCurrentValue(target)
    setAcademicListSelection(target)
  }
  const content = () => {
    return (
      <>
        {/* <Toggle   initialValue={'UG'}
            onClick={e => {
              onClick(e)
            }} /> */}
        {academicName !== 'Academic' ? (
          <>
            <EventHeader1>Duration</EventHeader1>
            <DurationIndex
              keys={keys}
              singleDurationData={singleDurationData}
              setSingleDurationData={setSingleDurationData}
              setAcademicDataAlone={setAcademicDataAlone}
              academicDataAlone={academicDataAlone}
              setAcademicValidation={setAcademicValidation}
              durationSwitch={durationSwitch}
              setDurationSwitch={setDurationSwitch}
              setDurationToggle={setDurationToggle}
              isTen={true}
              form={form}
            />
          </>
        ) : (
          <>
            <Toggle
              initialValue={academicListSelection}
              onClick={e => {
                onClick(e)
              }}
            />

            <TierIndex
              singleAcademicTierData={singleAcademicTierData}
              setSingleAcademicTierData={setSingleAcademicTierData}
              academicListSelection={academicListSelection}
              academicToggle={academicToggle}
              setAcademicToggle={setAcademicToggle}
            />
          </>
        )}
      </>
    )
  }
  return (
    <div>
      <PaddedToggleDive>
        {content()}
      </PaddedToggleDive>
    </div>
  )
}
