import { Form } from 'antd'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { FooterFlex } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import InstitutePreviewDetails from 'modules/Onboarding/Partials/Institutes/Partials/InstitutePreviewDrawer/Partials/InstitutePreviewDetails'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editInstitute } from '../../Register/actions'

const InstitutePreviewDrawer = ({
  form,
  instituteClear,
  institutePreviewDrawer,
  setInstitutePreviewDrawer,
  setInstituteLinkGenerateDrawer,
  title = 'Preview',
  sendButtonText = 'Generate Link',
  setCollegeID,
  collegeID,
  setNewInstituteDocumentDrawer,
  setClearInput
}) => {
  const dispatch = useDispatch()

  const onClear = () => {
    form.resetFields()
    setInstitutePreviewDrawer(false)
    instituteClear()
  }

  const InstituteFormDetail = useSelector(
    state => state?.InstituteFormData?.InstituteFormData
  )

  const goBackToAddForm = () => {
    setInstitutePreviewDrawer(false)
    setNewInstituteDocumentDrawer(true)
  }

  const submitData = () => {
    const reqData = {
      admin: {
        email: InstituteFormDetail?.email,
        phoneNumber: InstituteFormDetail?.phoneNumber,
      },
      institute: {
        name: InstituteFormDetail?.instituteName,
        university: InstituteFormDetail?.universityName,
        ...(InstituteFormDetail?.universityId && {
          universityId: InstituteFormDetail?.universityId,
        }),
        ...(InstituteFormDetail?.contractURL && {
          regDocs: InstituteFormDetail?.contractURL,
        }),
      },
      instituteCampus: {
        groupName: InstituteFormDetail?.groupName,
        city: InstituteFormDetail?.city,
        countryId: InstituteFormDetail?.countryId,
        cityId: InstituteFormDetail?.cityId,
        stateId: InstituteFormDetail?.stateId,
        country: InstituteFormDetail?.country,
        state: InstituteFormDetail?.state,
        postalCode: InstituteFormDetail?.zipcode,
        contactEmail: InstituteFormDetail?.email,
        secondaryContactNumber: InstituteFormDetail?.phoneNumber,
        contactNumber: InstituteFormDetail?.phoneNumber,
        secondaryContactEmail: InstituteFormDetail?.email,
        website: InstituteFormDetail?.website,
        contactPerson: InstituteFormDetail?.contactPerson,
        campusLocation: InstituteFormDetail?.city,
      },
    }

    let payLoad = { ...reqData }

    const callBack = () => {
      setInstitutePreviewDrawer(false)
      setInstituteLinkGenerateDrawer(true)
      instituteClear(0)
      setClearInput(true)
      setTimeout(()=>{
        setClearInput(false)
      },[1000])
    }
    dispatch(
      editInstitute({
        instituteCampusId: collegeID,
        data: payLoad,
        callBack: callBack,
        calldata: true,
      })
    )
  }

  const content = () => {
    return (
      <div>
        <div>
          <InstitutePreviewDetails />
        </div>
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary text="Back" onClick={goBackToAddForm} />
        <Button.Primary
          htmlType="submit"
          text={sendButtonText}
          onClick={submitData}
        />
      </FooterFlex>
    )
  }
  return (
    <Form
      scrollToFirstError
      id="insPreviewForm"
      form={form}
      layout="vertical"
      onFinish={submitData}
    >
      <Drawer
        width="48%"
        title={title}
        placement="right"
        closable={true}
        onClose={onClear}
        visible={institutePreviewDrawer}
        content={content()}
        footer={footer()}
      />
    </Form>
  )
}

export default InstitutePreviewDrawer
