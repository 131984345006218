import {
  SET_CORPORATE_LIST_FOR_REPORT,
  SET_INDUSTRY_LIST,
  SET_CITY_LIST,
  SET_STATE_LIST,
  SET_CORP_EMPANELLED_LIST,
  SET_TOP_BOTTOM_CORPORATE_DETAILS,
  SET_EXCEL_DATA,
  SET_INDUSTRY_SECTOR_DATA,
  SET_SINGLE_SECTOR_DATA,
  SET_SINGLE_INDUSTRY_DATA,
} from 'modules/Reports/actions'
import { SET_SECTOR_LIST } from './actions'

const initialState = {
  getCorporateListForReport: {},
  getSectorListForReport: {},
  getStateListForReport: {},
  getCityListForReport: {},
  getIndustryListForReport: {},
  getEmpanelledCorporateListForReport: {},
  getTopBottomCoraporateDetails: {},
  excelData: {},
  getIndustrySectorData: {},
  getSingleSectorData: {},
  getSingleIndustryData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_SECTOR_DATA:
      return {
        ...state,
        getSingleSectorData: action.data,
      }
    case SET_SINGLE_INDUSTRY_DATA:
      return {
        ...state,
        getSingleIndustryData: action.data,
      }
    case SET_INDUSTRY_SECTOR_DATA:
      return {
        ...state,
        getIndustrySectorData: action.data,
      }
    case SET_CORPORATE_LIST_FOR_REPORT:
      return {
        ...state,
        getCorporateListForReport: action.data,
      }
    case SET_SECTOR_LIST:
      return {
        ...state,
        getSectorListForReport: action.data,
      }
    case SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.data,
      }
    case SET_STATE_LIST:
      return {
        ...state,
        getStateListForReport: action.data,
      }
    case SET_CITY_LIST:
      return {
        ...state,
        getCityListForReport: action.data,
      }
    case SET_INDUSTRY_LIST:
      return {
        ...state,
        getIndustryListForReport: action.data,
      }
    case SET_CORP_EMPANELLED_LIST:
      return {
        ...state,
        getEmpanelledCorporateListForReport: action.data,
      }
    case SET_TOP_BOTTOM_CORPORATE_DETAILS:
      return {
        ...state,
        getTopBottomCoraporateDetails: action.data,
      }
    default:
      return state
  }
}
