import React from 'react'

const DegreeIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      <path
        d="M20 13.9999C19.7167 13.9999 19.4793 13.9039 19.288 13.7119C19.096 13.5206 19 13.2832 19 12.9999V7.0999L10.975 11.4749C10.825 11.5582 10.6707 11.6206 10.512 11.6619C10.354 11.7039 10.1833 11.7249 10 11.7249C9.81668 11.7249 9.64601 11.7039 9.48801 11.6619C9.32935 11.6206 9.17501 11.5582 9.02501 11.4749L0.600012 6.8749C0.433345 6.79157 0.304346 6.67057 0.213012 6.5119C0.121012 6.3539 0.0750122 6.18324 0.0750122 5.9999C0.0750122 5.81657 0.121012 5.6459 0.213012 5.4879C0.304346 5.32924 0.433345 5.20824 0.600012 5.1249L9.05001 0.524902C9.20001 0.441569 9.35401 0.378902 9.51201 0.336902C9.67068 0.295569 9.83335 0.274902 10 0.274902C10.1667 0.274902 10.3293 0.295569 10.488 0.336902C10.646 0.378902 10.8 0.441569 10.95 0.524902L20.475 5.7249C20.6417 5.80824 20.771 5.9289 20.863 6.0869C20.9543 6.24557 21 6.41657 21 6.5999V12.9999C21 13.2832 20.904 13.5206 20.712 13.7119C20.5207 13.9039 20.2833 13.9999 20 13.9999ZM10 17.7249C9.83335 17.7249 9.67068 17.7042 9.51201 17.6629C9.35401 17.6209 9.20001 17.5582 9.05001 17.4749L4.05001 14.7749C3.71668 14.5916 3.45835 14.3459 3.27501 14.0379C3.09168 13.7292 3.00001 13.3832 3.00001 12.9999V9.1999L8.67501 12.2749C8.89168 12.3916 9.10835 12.4956 9.32501 12.5869C9.54168 12.6789 9.76668 12.7249 10 12.7249C10.2333 12.7249 10.4627 12.6749 10.688 12.5749C10.9127 12.4749 11.1333 12.3666 11.35 12.2499L17 9.1999V12.9999C17 13.3832 16.9083 13.7292 16.725 14.0379C16.5417 14.3459 16.2833 14.5916 15.95 14.7749L10.95 17.4749C10.8 17.5582 10.646 17.6209 10.488 17.6629C10.3293 17.7042 10.1667 17.7249 10 17.7249Z"
        fill="#3249D7"
      />
    </svg>
  )
}

export default DegreeIcon
