import { connect } from 'react-redux'
import Reports from 'modules/Reports/index'
import {
  getCorporateListForReport,
  getSectorList,
  getCityList,
  getIndustryList,
  getStateList,
  IndustrySectorUrl,
  getSingleSectorList,
  getSingleIndustryList,
} from '../actions'
import {getUserInfo } from '../../Auth/actions'
import selector from '../../Auth/selectors'

import selectors from 'modules/Reports/selectors'

const mapStateToProps = state => ({
  corporateListDataForReport: selectors?.corporateListReportData(state),
  sectorList: selectors?.sectorListData(state),
  cityList: selectors?.cityListData(state),
  stateList: selectors?.stateListData(state),
  industryList: selectors?.industryListData(state),
  excelDataForReport: selectors?.excelData(state),
  industrySectorData: selectors?.industrySectorData(state),

  singleSectorData: selectors?.singleSectorData(state),
  singleIndustryData: selectors?.singleIndustryData(state),

  userData: selector?.getUserData(state),

})

const mapDispatchToProps = {
  getCorporateListForReport,
  getSectorList,
  getCityList,
  getIndustryList,
  getStateList,
  IndustrySectorUrl,

  getSingleSectorList,
  getSingleIndustryList,

  getUserInfo

}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
