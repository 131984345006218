import styled from 'styled-components'
import { StyledTabs } from 'components/TabsPaneStyles'
import Flex from 'components/Flex'
import { COLORS } from 'theme'

export const StyledTab = styled(StyledTabs)`
  .ant-tabs-content {
    height: 250px !important;

    overflow: scroll;
    &::-webkit-scrollbar {
      display: '';
    }
  }
`

export const SubmitFlex = styled(Flex)`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const MenuFlex = styled(Flex)`
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
`
export const Wrapper = styled(Flex)`
  padding: 20px 20px 20px 0;
  gap: 5px;
`
export const Wrappers = styled.div`
  padding: 1rem 0.5rem;
  width: max-content;

`
export const StartMargin = styled.div`
margin-bottom: 10px;

`
export const EndMargin = styled.div`
  margin-top: 10px;
`

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`

export const ClearButton = styled.div`
  margin-top: 20px;
  float: right;
  margin-right: 10px;
`
