import React from 'react'
import Header from 'modules/Layouts/Components/Partials/Header'
import Flex from 'components/Flex'
import styled from 'styled-components'

function LayoutWithOutNav({ children, userData, roleList }) {
  const PageWrapper = styled(Flex)`
    background: '#FFFFFF';
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
    flex-grow: 1;
  `
  return (

      <PageWrapper>
        <Header userData={userData} roleList={roleList} />
        {children}
      </PageWrapper>
    
  )
}

export default LayoutWithOutNav
