import request from 'utils/request'
import authRequest from 'utils/authRequest'
import corporateRequest from 'utils/corporateRequest'
import adminRequest from 'utils/adminRequest'
import instituteRequest from 'utils/instituteRequest'
import studentRequest from 'utils/studentRequest'
const initializeApp = token => {
  request.defaults.headers.common['Authorization'] = token
  authRequest.defaults.headers.common['Authorization'] = token
  corporateRequest.defaults.headers.common['Authorization'] = token
  adminRequest.defaults.headers.common['Authorization'] = token
  instituteRequest.defaults.headers.common['Authorization'] = token
  studentRequest.defaults.headers.common['Authorization'] = token
}

export default initializeApp
