import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import { StyledTabs } from 'components/TabsPaneStyles'
import { Form as AntdForm } from 'antd'
import { FONT } from 'theme/font'

export const SectionWrapper = styled(Flex)`
  width: 100%;
  border: 1px solid ${COLORS.PAGE_BORDER};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  border-radius: 10px;
  // overflow: scroll;
  background: ${COLORS.WHITE};
  &::-webkit-scrollbar {
    display: none;
  }

  // box-shadow: 0px 4px 12px rgba(153, 153, 153, 0.35);
  // border: 1px solid ${COLORS.PAGE_BORDER};
`
export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: ${FONT.F_3XL};
`
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: ${FONT.F_XL};
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
`
export const CardRow = styled(Flex)`
  padding: 0 25px 25px 25px;
  gap: 25px;
`
export const NavWrapper = styled(Flex)`
  padding-bottom: 50px;
`
export const EmptyWrapper = styled(Flex)`
  margin: 50px 0px;
  margin: ${props => props?.isSpin && '102px 0px'};
`
export const TableTop = styled(Flex)`
  padding: 5px 20px 0px 20px;
  border-top: 1px solid #e6e7ea;
`

export const StyledP = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  ${props => props.textType && `text-transform: ${props.textType};`}
  span {
    text-transform: capitalize;
  }
`
export const StyledTab = styled(StyledTabs)`
  .ant-tabs-content {
    height: 270px !important;

    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const SubmitFlex = styled(Flex)`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const MenuFlex = styled(Flex)`
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
`
export const OverlayFlex = styled(Flex)`
  padding: 10px 10px 0 10px;
  gap: 5px;
  ul {
    padding-left: 30px;
  }
`
export const BlacklistHeading = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.PRIMARY_GREY_T_98};
  margin-bottom: 0;
`
export const StyledLi = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GREY_T_80};
  margin-bottom: 5px;
  :last-child {
    margin-bottom: 0px;
  }
`
export const Form = styled(AntdForm)`
  padding: 1rem;
`
export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0px !important;
`
