import { List, Skeleton, Tooltip } from 'antd'
import AntdAvatar from 'components/Avatar'
import Button from 'components/Button'
import FilterDiv from 'components/FilterDiv'
import Flex from 'components/Flex'
import SearchInput from 'components/SearchInput'
import CheckIcon from 'components/icons/CheckIcon'
import { getListOfCity, getListOfState } from 'modules/Onboarding/actions'
import VirtualizedCheckboxGroup from 'modules/RankingAlgorithm/Partials/FilterDropDown/CheckboxGroup'
import React, { Fragment, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'
import { COLORS } from 'theme'
import {
  AlignAlreadySelectedCorporate,
  CollageDetailsFlex,
  CollageNameFlex,
  CollagesFlex,
  CollagesInfoDiv,
  CollagesSpan,
  CollegeCity,
  CollegeNameDiv,
  FilterSection,
  HeaderFlex,
  ListFlex,
  MenuFlex,
  PublishContentFlex,
  RadioGroups,
  Radios,
  SelectButton,
  StickyHeader,
  StyledTab,
  SubmitFlex,
  Wrapper,
} from '../Style/Style'
const { COUNTRY_ID } = process.env

export default function Content({
  getUserCorporatePortalsList,
  corporateList,
  getActiveCorporatePortalsList,
  activeCorporateList,
  payload,
  setPayload,
  loading,
  setRadioValue,
  radioValue,
  setActiveCorporateData,
  activeCorporateData,
  setSelectedCorporateData,
  selectedCorporateData,
  setViewSelectedCorporates,
  viewSelectedCorporates,
  setCorporateListData,
  corporateListData,
  mappingCorporate,
  searchValue,
  setSearchValue,
  clearInput,
  setClearInput,
  filterOpen,
  setFilterOpen,
  stateId,
  setStateId,
  stateList,
  setStateList,
  stateNames,
  setStateNames,
  stateSearch,
  setStateSearch,
  stateLoading,
  setStateLoading,
  citySearch,
  setCitySearch,
  cityLoading,
  setCityLoading,
  cityId,
  setPermCityId,
  cityList,
  setCityList,
  cityName,
  setCityName,
  id,
  setId,
  clear,
  setClear,
}) {
  const dispatch = new useDispatch()

  const RadioOptions = [
    {
      id: 1,
      label: 'All Corporates ',
      value: true,
    },
    {
      id: 2,
      label: 'Select Corporates',
      value: false,
    },
  ]
  const onClickAddFilter = () => {
    let search = searchValue
    let stateIds = stateId
    let cityIds = cityId
    getUserCorporatePortalsList({
      pageLimit: '10',
      isRankingEnabled: true,
      search,
      stateIds,
      cityIds,
    })
    getActiveCorporatePortalsList({
      pageLimit: '1000',
      search,
      stateIds,
      cityIds,
    })
    setId({ state: stateId, city: cityId })
    setFilterOpen(false)
    setCorporateListData([])
  }
  const onClickCancelFilter = () => {
    setFilterOpen(false)
    setPermCityId(id.city)
    setStateId(id.state)
  }
  const menu = (
    <MenuFlex column>
      <StyledTab
        border={true}
        tabPosition={'left'}
        type="card"
        padding="15px"
        popupClassName="filter"
        items={[
          {
            label: 'State',
            key: '1',
            children: (
              <VirtualizedCheckboxGroup
                options={stateList}
                value={stateId}
                setValue={setStateId}
                valueParam="_id"
                labelParam="name"
                setStateName={setStateNames}
                filterId={true}
                stateName={stateNames}
                loading={stateLoading}
                setLoading={setStateLoading}
                degreeSearch={stateSearch}
                saveById={true}
                setDegreeSearch={setStateSearch}
                clearInput={clear.state}
                placeholder="Search by State"
                toShow={true}
                getStateData={getStateData}
                labelType={'state'}
              />
            ),
          },
          {
            label: 'City',
            key: '2',
            children: (
              <VirtualizedCheckboxGroup
                options={cityList}
                value={cityId}
                setValue={setPermCityId}
                valueParam="_id"
                labelParam="name"
                filterId={true}
                setStateName={setCityName}
                stateName={cityName}
                loading={cityLoading}
                setLoading={setCityLoading}
                degreeSearch={citySearch}
                setDegreeSearch={setCitySearch}
                clearInput={clear.city}
                placeholder="Search by City"
                toShow={stateId?.length > 0}
              />
            ),
          },
        ]}
      />
      <SubmitFlex right>
        <Button.Secondary text="Cancel" onClick={onClickCancelFilter} />
        <Button.Primary text="Add" onClick={onClickAddFilter} />
      </SubmitFlex>
    </MenuFlex>
  )

  let getStateData = async (countryId, isSate, search) => {
    if (countryId) {
      let list = await dispatch(getListOfState(countryId, search))
      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setStateList(updatedArray || [])
    }
  }

  useEffect(() => {
    let search = stateSearch
    getStateData(COUNTRY_ID, true, search)
  }, [stateSearch, filterOpen])
  let getCityData = async (countryId, stateId, isSate, search) => {
    let pageLimit = '1500'
    if (countryId && stateId) {
      let list = await dispatch(
        getListOfCity(countryId, stateId, search, 0, pageLimit)
      )

      const updatedArray = list.map(obj => ({ ...obj, checked: false }))
      return setCityList(updatedArray || [])
    }
  }

  useEffect(() => {
    if (stateId?.length > 0) {
      getCityData(COUNTRY_ID, stateId, true)
    } else {
      setPermCityId('')
    }
  }, [stateId])
  useEffect(() => {
    if (stateId?.length > 0) {
      let search = citySearch
      getCityData(COUNTRY_ID, stateId, true, search)
    }
  }, [citySearch])
  const onChangeRadio = e => {
    setCitySearch('')
    setClearInput(true)
    setClear({ state: true, city: true })
    setStateSearch('')
    setRadioValue(e.target.value)
    setPayload({
      isAllCorporate: e.target.value,
      selectedCorporates: [],
      unSelectedCorporates: [],
    })
    setSearchValue('')
    setActiveCorporateData([])
    setViewSelectedCorporates(false)
    setPermCityId([])
    setStateId([])
    setTimeout(() => {
      setClearInput(false)
      setClear({ state: false, city: false })
    }, 500)
  }
  const onClickSelect = (id, data, action) => {
    if (action == 'add') {
      setActiveCorporateData([...activeCorporateData, data])
    }
    if (action == 'remove') {
      const updatedData = activeCorporateData.filter(item => item?.id != id)
      setActiveCorporateData(updatedData)
    }
  }
  useEffect(() => {
    let id = activeCorporateData?.map(item => item.id)
    let data = selectedCorporateData?.map(item => item.id)
    setPayload({
      ...payload,
      unSelectedCorporates: id,
      selectedCorporates: data,
    })
  }, [activeCorporateData, selectedCorporateData])

  useEffect(() => {
    let search = searchValue
    let getData
    let stateIds = stateId
    let cityIds = cityId
    setCorporateListData([])
    getData = setTimeout(() => {
      getUserCorporatePortalsList({
        pageLimit: '10',
        isRankingEnabled: true,
        search,
        stateIds,
        cityIds,
      })
      getActiveCorporatePortalsList({
        pageLimit: '1000',
        search,
        stateIds,
        cityIds,
      })
    }, 500)
    return () => clearTimeout(getData)
  }, [searchValue, mappingCorporate])
  //if we want to retrieve the data when loading we can use this
  // useEffect(() => {
  //   let search = searchValue
  //   let stateIds = stateId
  //   let cityIds = cityId
  //   setCorporateListData([])
  //   getUserCorporatePortalsList({
  //     pageLimit: '10',
  //     isRankingEnabled: true,
  //     search,
  //     stateIds,
  //     cityIds,
  //   })
  //   getActiveCorporatePortalsList({
  //     pageLimit: '1000',
  //     search,
  //     stateIds,
  //     cityIds,
  //   })
  // }, [loading])
  const onClickSelectCorporate = (id, data, action) => {
    if (action == 'add') {
      setSelectedCorporateData([...selectedCorporateData, data])
    }
    if (action == 'remove') {
      const updatedData = selectedCorporateData.filter(item => item?.id != id)
      setSelectedCorporateData(updatedData)
    }
  }
  const fetchMoreData = () => {
    let search = searchValue
    let stateIds = stateId
    let cityIds = cityId
    let page = corporateList?.currentPage + 1
    getUserCorporatePortalsList({
      pageLimit: '10',
      isRankingEnabled: true,
      search,
      page,
      stateIds,
      cityIds,
    })
  }
  useEffect(() => {
    if (corporateList) {
      if (corporateList?.list?.length > 0) {
        let data = corporateList?.list || []
        setCorporateListData(pre => [...pre, ...data])
      } else {
        setCorporateListData([])
      }
    } else {
      setCorporateListData([])
    }
  }, [corporateList])
  return (
    <div>
      {' '}
      <StickyHeader>
        <PublishContentFlex>
          <RadioGroups onChange={onChangeRadio} value={radioValue}>
            {RadioOptions?.map(item => (
              <Radios
                key={item.id} // Use a unique identifier instead of the index
                border={true}
                label={item?.label}
                value={item?.value}
              />
            ))}
          </RadioGroups>
        </PublishContentFlex>
        <HeaderFlex alignCenter>
          <SearchInput
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            width="100%"
            iconPosition={'suffix'}
            bordered={false}
            height="50px"
            clearInput={clearInput}
            placeholder="Search Corporate"
          />
          <FilterSection alignCenter>
            <FilterDiv
              label={''}
              width={'100%'}
              bordered={false}
              overlayMenu={menu}
              open={filterOpen}
              setOpen={setFilterOpen}
              onOpenChange={() => setFilterOpen(!filterOpen)}
            />
          </FilterSection>
        </HeaderFlex>
        <AlignAlreadySelectedCorporate>
          <CollagesFlex centerVertically margin="15px 0px 0px 0px;">
            <CollagesInfoDiv>Already Selected Corporates</CollagesInfoDiv>
            <CollagesSpan>{activeCorporateList?.count || 0}</CollagesSpan>
          </CollagesFlex>
          <CollagesFlex>
            {viewSelectedCorporates ? (
              <Button.Secondary
                height="30px"
                text="Close"
                onClick={() => setViewSelectedCorporates(false)}
              />
            ) : (
              <Button.Primary
                height="30px"
                text="View"
                onClick={() => setViewSelectedCorporates(true)}
              />
            )}
          </CollagesFlex>
        </AlignAlreadySelectedCorporate>
      </StickyHeader>
      {viewSelectedCorporates && (
        <Wrapper>
          <ListFlex
            bordered={false}
            dataSource={activeCorporateList?.list}
            renderItem={(item, index) => (
              <Fragment key={index}>
                <List.Item>
                  <CollageDetailsFlex
                    key={item?.id}
                    spaceBetween
                    centerVertically
                  >
                    <Flex>
                      <AntdAvatar
                        src={item?.logo}
                        size={36}
                        IconName={item?.name?.split(' ')[0].split('')[0]}
                      />

                      <CollageNameFlex>
                        <Tooltip
                          title={item?.name?.length > 19 ? item?.name : ''}
                        >
                          <CollegeNameDiv>{item?.name}</CollegeNameDiv>
                        </Tooltip>
                        <CollegeCity>{item?.city}</CollegeCity>
                      </CollageNameFlex>
                    </Flex>
                    {!radioValue &&
                      (activeCorporateData
                        ?.map(item => item?.id)
                        ?.includes(item?.id) ? (
                        <SelectButton
                          type="text"
                          id={item?.id}
                          icon={<CheckIcon />}
                          color={COLORS.GREY_T_30}
                          onClick={() =>
                            onClickSelect(item?.id, item, 'remove')
                          }
                        >
                          Select
                        </SelectButton>
                      ) : (
                        <Tooltip title="Unselect">
                          <SelectButton
                            type="text"
                            id={item?.id}
                            onClick={() => onClickSelect(item?.id, item, 'add')}
                          >
                            Selected
                          </SelectButton>
                        </Tooltip>
                      ))}
                  </CollageDetailsFlex>
                </List.Item>
              </Fragment>
            )}
          />
        </Wrapper>
      )}
      <Wrapper>
        <CollagesFlex centerVertically margin="15px 0px 0px 0px;">
          <CollagesInfoDiv>Corporates Info</CollagesInfoDiv>
          <CollagesSpan>{corporateList?.count || 0}</CollagesSpan>
        </CollagesFlex>
        <InfiniteScroll
          dataLength={corporateListData?.length || 0}
          height={'62vh'}
          next={fetchMoreData}
          hasMore={corporateListData?.length < corporateList?.count}
          loader={
            corporateListData?.length < corporateList?.count ? (
              <Skeleton avatar paragraph={{ rows: 1 }} active />
            ) : null
          }
        >
          <ListFlex
            bordered={false}
            dataSource={corporateListData}
            renderItem={(item, index) => (
              <Fragment key={index}>
                <List.Item>
                  <CollageDetailsFlex
                    key={item?.id}
                    spaceBetween
                    centerVertically
                  >
                    <Flex>
                      <AntdAvatar
                        src={item?.logo}
                        size={36}
                        IconName={item?.name?.split(' ')[0].split('')[0]}
                      />
                      <CollageNameFlex>
                        <CollegeNameDiv>{item?.name}</CollegeNameDiv>
                        <CollegeCity>{item?.city}</CollegeCity>
                      </CollageNameFlex>
                    </Flex>
                    {!radioValue &&
                      (selectedCorporateData
                        ?.map(item => item?.id)
                        ?.includes(item?.id) ? (
                        <Tooltip title="Unselect">
                          <SelectButton
                            type="text"
                            id={item?.id}
                            icon={<CheckIcon />}
                            color={COLORS.GREY_T_30}
                            onClick={() =>
                              onClickSelectCorporate(item?.id, item, 'remove')
                            }
                          >
                            Selected
                          </SelectButton>
                        </Tooltip>
                      ) : (
                        <SelectButton
                          type="text"
                          id={item?.id}
                          onClick={() =>
                            onClickSelectCorporate(item?.id, item, 'add')
                          }
                        >
                          Select
                        </SelectButton>
                      ))}
                  </CollageDetailsFlex>
                </List.Item>
              </Fragment>
            )}
          />
        </InfiniteScroll>
      </Wrapper>
    </div>
  )
}
