import { SET_SINGLE_INSTITUTES_DATA, SET_USER_INSTITUTES_LIST } from './actions'

const INITIAL_STATE = {
  userInstitutesList: {
    list: [],
  },
  singleInstituteData: {},
}

export const Institutes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_INSTITUTES_LIST:
      return {
        ...state,
        userInstitutesList: action.data,
      }
    case SET_SINGLE_INSTITUTES_DATA:
      return {
        ...state,
        singleInstituteData: action.data,
      }

    default:
      return state
  }
}
