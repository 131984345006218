/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import Button from 'components/Button'
import ErrorMessage from 'utils/ErrorMessage'
import UploadIcon from 'components/icons/UploadIcon'
import Control from 'components/Control/Control'
import {
  UploadText,
  AddIcon,
} from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import Add from 'components/icons/Add'
import { uploadFile, deleteFile } from 'modules/Onboarding/actions'
import { useDispatch } from 'react-redux'
import {
  Wrapper,
  StyledUpload,
  AdjustIcon,
  Cirlcle,
  SpanText,
  TextWrapper,
} from 'components/Uploader/Styles'
import RepeatIcon from 'components/icons/RepeatIcon'

const Uploader = ({
  isDelete,
  file,
  text,
  setText,
  setFile,
  isDefault = false,
  showTextUploder,
  showButtonUploder,
  showIconUploader,
  fileName,
  accpect = '.jpeg, .jpg , .pdf',
}) => {
  const dispatch = new useDispatch()

  const getFileUrl = async info => {
    let getType = info?.file?.type?.includes('png') ? 'png' : 'jpg'
    let name = fileName + '.' + getType
    let response = await dispatch(uploadFile(info?.file?.originFileObj, name))
    if (response) {
      setFile(response)
    }
  }

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    accept: '.jpeg, .jpg , .png',
    beforeUpload: file => {
      const isLt10mb = file.size / 1024 / 1024 < 2
      if (!(file?.type.includes('png') || file?.type?.includes('jpeg'))) {
        ErrorMessage('File should be in jpg/jpeg or png format!')
        return false
      } else if (!isLt10mb) {
        ErrorMessage(`${file?.type}  Must Smaller Than 2mb!`)
        return false
      }
    },
    onChange: async info => {
      if (isDelete && info?.file?.status === 'done') {
        let response = await dispatch(deleteFile(file))
        if (response) {
          return getFileUrl(info)
        }
      } else if (!isDelete && info?.file?.status === 'done') {
        return getFileUrl(info)
      }
    },
    itemRender: () => {},
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('OK')
    }, 0)
  }

  const onBeforeUpload = file => {
    const isLt10mb = file.size / 1024 / 1024 < 2
    if (
      !(
        file?.type.includes('pdf') ||
        file?.type?.includes('jpeg') ||
        file?.type?.includes('png')
      )
    ) {
      ErrorMessage('File should be in jpg/jpeg or pdf format!')
      return false
    } else if (!isLt10mb) {
      ErrorMessage(`${file?.type}  Must Smaller Than 2mb!`)
      return false
    }
  }

  const onChangeFileUpload = async info => {
    if (info?.file?.status === 'done') {
      let getType = info?.file?.type?.includes('pdf') ? 'pdf' : 'jpg'
      let name = fileName + '.' + getType
      let response = await dispatch(uploadFile(info?.file?.originFileObj, name))
      if (response) {
        let fileObject = {
          name: name,
          url: response,
          size: info?.file?.size,
          date: new Date().getTime(),
          type: getType,
        }
        setFile(fileObject)
      }
    }
  }

  return (
    <Fragment>
      {isDefault && (
        <Wrapper center alignCenter column>
          <StyledUpload
            {...props}
            type="file"
            accept={accpect}
            customRequest={dummyRequest}
            onChange={onChangeFileUpload}
            beforeUpload={onBeforeUpload}
          >
            <Button.Secondary
              icon={
                <AdjustIcon>
                  <UploadIcon />
                </AdjustIcon>
              }
              text="Upload File"
              height={'32px'}
            />
          </StyledUpload>

          <TextWrapper center alignCenter>
            <SpanText>Size: 2 mb max</SpanText> <Cirlcle />
            <SpanText>Format : .PDF, .JPEG/JPG</SpanText>
          </TextWrapper>
        </Wrapper>
      )}
      {showTextUploder && (
        <StyledUpload {...props} type="file" customRequest={dummyRequest}>
          <Control center alignCenter top="5px">
            <AddIcon>
              <Add />
            </AddIcon>
            <UploadText>Upload Logo</UploadText>
          </Control>
        </StyledUpload>
      )}
      {showButtonUploder && (
        <StyledUpload {...props} type="file" customRequest={dummyRequest}>
          <Button.Primary text="Change Logo" height={'32px'} />
        </StyledUpload>
      )}
      {showIconUploader && (
        <StyledUpload {...props} type="file" customRequest={dummyRequest}>
          <RepeatIcon />
        </StyledUpload>
      )}
    </Fragment>
  )
}

export default Uploader
