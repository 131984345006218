import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { FooterFlex, Form } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import InstituteLinkGenerationDetails from 'modules/Onboarding/Partials/Institutes/Partials/InstituteLinkGenerateDrawer/Partials/InstituteLinkGenerationDetails'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const InstituteLinkGenerateDrawer = ({
  form,
  instituteClear,
  setInstituteLinkGenerateDrawer,
  instituteLinkGenerateDrawer,
  title = 'Link Generate',
  formId = 'linkForm',
  setCollegeID,
  collegeID,
}) => {

  const [value, setValue] = useState('')

  const navigate = useNavigate()

  const getInstituteGeneratedLinkAndID = useSelector(
    state => state?.InstituteList?.instituteGeneratedLinkAndID
  )

  const onClear = () => {
    form.resetFields()
    setInstituteLinkGenerateDrawer(false)
    instituteClear()
  }

  const submitHandler = () => {
    navigate(`/onboarding/institute/${getInstituteGeneratedLinkAndID?.id}`)
  }

  const content = () => {
    return (
      <div>
        <Form
          scrollToFirstError
          onFinish={submitHandler}
          id={formId}
          form={form}
          layout="vertical"
        >
          <InstituteLinkGenerationDetails
            form={form}
            value={value}
            setValue={setValue}
          />
        </Form>
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary form="linkForm" text={'Close'} onClick={onClear} />
      </FooterFlex>
    )
  }
  return (
    
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={onClear}
        visible={instituteLinkGenerateDrawer}
        content={content()}
        footer={footer()}
      />
    
  )
}

export default InstituteLinkGenerateDrawer
