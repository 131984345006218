import React from 'react'

const PDFIcon = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2025_69593"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="42"
        height="43"
      >
        <rect y="0.5" width="42" height="42" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2025_69593)">
        <path
          d="M10.5 39C9.5375 39 8.71383 38.6576 8.029 37.9728C7.343 37.2868 7 36.4625 7 35.5V7.5C7 6.5375 7.343 5.71325 8.029 5.02725C8.71383 4.34242 9.5375 4 10.5 4H24.5L35 14.5V35.5C35 36.4625 34.6576 37.2868 33.9728 37.9728C33.2868 38.6576 32.4625 39 31.5 39H10.5ZM22.75 16.25H31.5L22.75 7.5V16.25Z"
          fill="#E6E7EA"
        />
      </g>
      <rect
        x="4"
        y="20.5"
        width="17.375"
        height="11.25"
        rx="1"
        fill="#6F80E3"
      />
      <path
        d="M7.82494 28.625V24.6672H9.30182C9.57099 24.6672 9.80828 24.7168 10.0137 24.8159C10.2227 24.9116 10.3856 25.0532 10.5024 25.2409C10.6193 25.4251 10.6778 25.65 10.6778 25.9156C10.6778 26.1777 10.6175 26.4026 10.4971 26.5903C10.3803 26.7745 10.2191 26.9161 10.0137 27.0153C9.80828 27.1145 9.57099 27.1641 9.30182 27.1641H8.54744V28.625H7.82494ZM8.54744 26.5266H9.31244C9.44349 26.5266 9.55682 26.5018 9.65244 26.4522C9.74807 26.3991 9.82244 26.3265 9.87557 26.2344C9.92869 26.1423 9.95526 26.036 9.95526 25.9156C9.95526 25.7917 9.92869 25.6854 9.87557 25.5969C9.82244 25.5048 9.74807 25.434 9.65244 25.3844C9.55682 25.3312 9.44349 25.3047 9.31244 25.3047H8.54744V26.5266ZM11.2698 28.625V24.6672H12.5607C12.9786 24.6672 13.3416 24.7522 13.6498 24.9222C13.9614 25.0886 14.2023 25.3206 14.3723 25.6181C14.5423 25.9121 14.6273 26.2539 14.6273 26.6434C14.6273 27.0295 14.5423 27.373 14.3723 27.6741C14.2023 27.9716 13.9614 28.2053 13.6498 28.3753C13.3416 28.5418 12.9786 28.625 12.5607 28.625H11.2698ZM11.9923 27.9875H12.5766C12.8458 27.9875 13.0778 27.9326 13.2726 27.8228C13.4709 27.7095 13.625 27.5536 13.7348 27.3553C13.8446 27.1534 13.8995 26.9161 13.8995 26.6434C13.8995 26.3672 13.8446 26.1299 13.7348 25.9316C13.625 25.7332 13.4709 25.5792 13.2726 25.4694C13.0778 25.3596 12.8458 25.3047 12.5766 25.3047H11.9923V27.9875ZM15.2023 28.625V24.6672H17.8426V25.3047H15.9248V26.3991H17.6035V27.0366H15.9248V28.625H15.2023Z"
        fill="white"
      />
    </svg>
  )
}

export default PDFIcon
