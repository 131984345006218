import styled from 'styled-components'
import Flex from 'components/Flex'

export const Container = styled(Flex)`
  height: calc(100vh - 142px);
  width: 100vw;
  background-color: #fafafb;
  gap: 40px;
`
export const Wrapper = styled.div`
  width: 48%;
  padding-top: 30px;
`

export const Content = styled(Flex)`
  height: auto;
  width: 100%;
  background-color: white;
  border: 1px solid #e6e7ea;
  box-shadow: 0px 6px 10px rgba(86, 94, 106, 0.1);
  padding: 93px 160px 137px 160px;
  gap: 20px;
  border-radius: 10px;
  margin-top: 70px;
`
export const Heading = styled(Flex)`
  padding-top: 20px;
  font-weight: 700;
  font-size: 1.4285714285714286em;
  line-height: 28px;
  color: #010e30;
  text-align: -webkit-center;
`
export const Para = styled(Flex)`
  padding-top: 6px;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  color: #676e83;
  text-align: -webkit-center;
`
export const Anchor = styled.a`
  margin-top: 5px;
  font-weight: 600;
  font-size: 1em;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 10px;
  background: #3249d7;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  &:hover {
    color: #ffffff;
    background: #3249d7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
  &:focus {
    color: #ffffff;
    background: #3249d7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
`
