import request from 'utils/adminRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
export const SET_INSTITUTE_LIST_FOR_REPORT =
  'corporate/SET_INSTITUTE_LIST_FOR_REPORT'
export const SET_UNIVERSITY_LIST = `corporate/SET_UNIVERSITY_LIST`
export const SET_TIER_LIST = `corporate/SET_TIER_LIST`
export const SET_CITY_LIST = `corporate/SET_CITY_LIST`
export const SET_EXCEL_DATA = `inst/EXCEL`
export const SET_TOP_INSTITUTE_LIST = `corporate/SET_TOP_INSTITUTE_LIST`
export const SET_CORP_EMPANELLED_LIST = `corporate/SET_EMPANELLED_LIST`
export const SET_TOP_BOTTOM_CORPORATE_DETAILS = `corporate/SET_TOP_BOTTOM_CORPORATE_DETAILS`

export const setInstituteListForReport = data => {
  return {
    type: SET_INSTITUTE_LIST_FOR_REPORT,
    data,
  }
}
export const setUniversityList = data => {
  return {
    type: SET_UNIVERSITY_LIST,
    data,
  }
}
export const setTierList = data => {
  return {
    type: SET_TIER_LIST,
    data,
  }
}
export const setCityList = data => {
  return {
    type: SET_CITY_LIST,
    data,
  }
}

//--------------
export const setTopInstituteList = data => {
  return {
    type: SET_TOP_INSTITUTE_LIST,
    data,
  }
}
export const setCorporateEmpanelledList = data => {
  return {
    type: SET_CORP_EMPANELLED_LIST,
    data,
  }
}
export const setTopBottomCorporateDetails = data => {
  return {
    type: SET_TOP_BOTTOM_CORPORATE_DETAILS,
    data,
  }
}
export const setExcelData = data => {
  return {
    type: SET_EXCEL_DATA,
    data,
  }
}
export const getInstituteListForReport =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoadingInst,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async (dispatch, getState) => {
    setLoadingInst(true)

    try {
      let city = encodeURIComponent(filterDatas?.city || '')
      let sector = encodeURIComponent(filterDatas?.university || '')
      let industry = encodeURIComponent(filterDatas?.tier || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''

      const City = city ? `&city=${city}` : ``
      const Sector = sector ? `&university=${sector}` : ``
      const Industry = industry ? `&tiers=${industry}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/institute-report/lists?size=${pageSize}&page=${pageNumber}${Search}${Sector}${Industry}${City}${CreatedAtStart}${CreatedAtEnd}${Sort}${OrderBy} `
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setInstituteListForReport(response?.data?.data || []))
      }

      setLoadingInst(false)
    } catch (error) {
      setLoadingInst(false)
    }
  }

export const getUniversityList =
  ({ setLoading, keySector = '', flag, page = 0 }) =>
  async (dispatch, getState) => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keySector.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await elasticSearchRequest.get(
        `/institute-filter/universityinstmaster/lists?name=${myEncodedString}&page=${page}&size=10${Flag} `
      )
      dispatch(setUniversityList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getTierList =
  ({ setLoading, keyIndustry = '', flag }) =>
  async (dispatch, getState) => {
    setLoading(true)
    try {
      const myEncodedString = encodeURIComponent(keyIndustry.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/institute-filter/tierinstmaster/lists?name=${myEncodedString}&page=${0}&size=10${Flag}`
      )
      dispatch(setTierList(response?.data?.data || []))
      setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
export const getCityList =
  ({ setLoading, keyCity = '', flag, page = 0 }) =>
  async (dispatch, getState) => {
    if (setLoading) {
      setLoading(true)
    }
    try {
      const myEncodedString = encodeURIComponent(keyCity.toLowerCase())
      const Flag = flag ? `&flag=${flag}` : ``
      const response = await request.get(
        `/institute-filter/cityinstmaster/lists?name=${myEncodedString}&page=${page}&size=10${Flag}`
      )
      dispatch(setCityList(response?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) {
        setLoading(false)
      }
    }
  }

export const getTopInstituteReport =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoadingTopInst,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async (dispatch, getState) => {
    if (setLoadingTopInst) {
      setLoadingTopInst(true)
      dispatch(setExcelData([]))
    }

    try {
      let city = encodeURIComponent(filterDatas?.city || '')
      let sector = encodeURIComponent(filterDatas?.university || '')
      let industry = encodeURIComponent(filterDatas?.tier || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''

      const City = city ? `&city=${city}` : ``
      const Sector = sector ? `&university=${sector}` : ``
      const Industry = industry ? `&tiers=${industry}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/topinstitute-report/lists?size=${pageSize}&page=${pageNumber}${Search}${Sector}${Industry}${City}${CreatedAtStart}${CreatedAtEnd}${Sort}${OrderBy} `
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setTopInstituteList(response?.data?.data || []))
      }

      if (setLoadingTopInst) setLoadingTopInst(false)
    } catch (error) {
      if (setLoadingTopInst) {
        setLoadingTopInst(false)
      }
    }
  }
export const getTopBottomCorporateDetails =
  ({
    pageSize = 10,
    pageNumber = 0,
    setLoadingTopCorp,
    searchValue = '',
    filterDatas,
    sortBy,
    excel = false,
  }) =>
  async (dispatch, getState) => {
    if (setLoadingTopCorp) {
      setLoadingTopCorp(true)
      dispatch(setExcelData([]))
    }
    try {
      let state = encodeURIComponent(filterDatas?.state || '')
      let city = encodeURIComponent(filterDatas?.city || '')
      let sector = encodeURIComponent(filterDatas?.sector || '')
      let industry = encodeURIComponent(filterDatas?.industry || '')
      let createdAtEnd = filterDatas?.createdAtEnd || ''
      let createdAtStart = filterDatas?.createdAtStart || ''

      const State = state ? `&state=${state}` : ``
      const City = city ? `&city=${city}` : ``
      const Sector = sector ? `&university=${sector}` : ``
      const Industry = industry ? `&tiers=${industry}` : ``
      const CreatedAtEnd = createdAtEnd ? `&createdAtEnd=${createdAtEnd}` : ``
      const CreatedAtStart = createdAtStart
        ? `&createdAtStart=${createdAtStart}`
        : ``
      const Search = searchValue
        ? `&name=${searchValue?.toLocaleLowerCase()}`
        : ``
      const Sort = sortBy?.sortName ? `&sort=${sortBy.sortName}` : ``
      const OrderBy = sortBy?.orderBy ? `&orderBy=${sortBy.orderBy}` : ``
      const response = await request.get(
        `/topcorporatesreport/toplists?size=${pageSize}&page=${pageNumber}${Search}${State}${Sector}${Industry}${City}${CreatedAtStart}${CreatedAtEnd}${Sort}${OrderBy}`
      )
      if (excel) {
        dispatch(setExcelData(response?.data?.data || []))
      } else {
        dispatch(setTopBottomCorporateDetails(response?.data?.data || []))
      }

      if (setLoadingTopCorp) setLoadingTopCorp(false)
    } catch (error) {
      if (setLoadingTopCorp) {
        setLoadingTopCorp(false)
      }
    }
  }
