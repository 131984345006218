const studentListReportData = state =>
  state?.StudentReducer?.getStudentListForReport
const courseListData = state => state?.StudentReducer?.getCourseListForReport
const CollegeListData = state => state?.StudentReducer?.getCollegeListForReport
const degreeListData = state => state?.StudentReducer?.getDegreeListForReport
const specialisationListData = state =>
  state?.StudentReducer?.getSpecialisationListForReport
const placedStudentListReportData = state =>
  state?.StudentReducer?.getPlacedStudentListForReport
const studentSkillDetails = state =>
  state?.StudentReducer?.getStudentSkillDetails
const studentCourseDetails = state =>
  state?.StudentReducer?.getStudentCourseDetails
const corporateNameList = state => state?.StudentReducer?.getCorporateNameList
const skillList = state => state?.StudentReducer?.getSkillList
const excelData = state => state?.StudentReducer?.excelData
const universityData = state => state?.StudentReducer?.universityData
const specialisationData = state => state?.StudentReducer?.specialisationData
const cityData = state => state?.StudentReducer?.cityData
const collegeData = state => state?.StudentReducer?.collegeData
const degreeData = state => state?.StudentReducer?.degreeData
const tierData = state => state?.StudentReducer?.tierData
const corporateData = state => state?.StudentReducer?.corporateData
const skillData = state => state?.StudentReducer?.skillData
const stateDatas = state => state?.StudentReducer?.getStudentState
const cityDatas = state => state?.StudentReducer?.getStudentCity
const selectors = {
  studentListReportData,
  courseListData,
  CollegeListData,
  degreeListData,
  specialisationListData,
  placedStudentListReportData,
  studentSkillDetails,
  studentCourseDetails,
  corporateNameList,
  skillList,
  excelData,
  universityData,
  specialisationData,
  cityData,
  collegeData,
  degreeData,
  tierData,
  corporateData,
  skillData,
  stateDatas,
  cityDatas,
}

export default selectors
