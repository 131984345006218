import instituteRequest from 'utils/instituteRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import instituteSelector from 'modules/Auth/selectors'
import studentRequest from 'utils/studentRequest'
import SuccessMessage from 'utils/SuccessMessage'
import ErrorMessage from 'utils/ErrorMessage'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const setDegreeList = list => {
  return {
    type: 'SET_DEGREE_LIST',
    list,
  }
}

export const setCoursesList = data => {
  return {
    type: 'SET_COURSES_LIST',
    data,
  }
}

export const setListOfSkill = list => {
  return {
    type: 'SET_LIST_OF_SKILL',
    list,
  }
}
export const setStudentDegreeData = data => {
  return {
    type: 'SET_STUDENTS_DEGREE_LIST',
    data,
  }
}
export const getCoursesList =
  ({
    page,
    search,
    duration,
    examType,
    degreeFilter,
    skillList,
    statusFilter,
    sort,
    orderBy,
    collegeID,
    setLoading,
    systemConfig = false,
    departmentFilter,
    degreeMapId,
    specialisationFilter,
    domainFilter,
  }) =>
  async (dispatch, getState) => {
    // const state = getState()
    let pageKey = `pageLimit=10&pageNo=${page}`
    let searchKey = search ? `&search=${search}` : ''
    let durationKey = duration?.length > 0 ? `&duration=${duration}` : ''
    let examTypeKey = examType?.length > 0 ? `&examType=${examType}` : ''
    let degreeFilterKey =
      degreeFilter.length > 0 ? `&degreeStreamMapId=${degreeFilter}` : ''
    let skillListKey = skillList?.length > 0 ? `&skills=${skillList}` : ''
    let statusFilterKey =
      statusFilter?.length > 0 ? `&status=${statusFilter}` : ''
    let sortKey = sort ? `&sort=${sort}` : ''
    let orderKey = orderBy ? `&order=${orderBy}` : ''
    let degreeId = degreeMapId?.length > 0 ? `&degreeId=${degreeMapId}` : ``
    let streamId =
      departmentFilter?.length > 0 ? `&streamId=${departmentFilter}` : ``
    let domainIdKey =
      domainFilter?.length > 0 ? `&domainId=${domainFilter}` : ``
    let SpecialisationId =
      specialisationFilter?.length > 0
        ? `&specialisationId=${specialisationFilter}`
        : ''
    setLoading(true)
    try {
      const res = systemConfig
        ? await elasticSearchRequest.get(
            `institutes/crud/degree/dept?${pageKey}${sortKey}${orderKey}${searchKey}${durationKey}${examTypeKey}${SpecialisationId}${degreeFilterKey}${skillListKey}${statusFilterKey}${degreeId}${streamId}${domainIdKey}`
          )
        : collegeID
        ? await elasticSearchRequest.get(
            `/institutes/instituteCampus/${collegeID}/courses?${pageKey}${sortKey}${orderKey}${searchKey}${durationKey}${examTypeKey}${degreeFilterKey}${skillListKey}${statusFilterKey}${domainIdKey}`
          )
        : null

      dispatch(setCoursesList(res?.data?.data))
      setLoading(false)
      return true
    } catch (error) {
      setLoading(false)
      console.log('Error in getting Single Student Data', error)
    }
  }

export const AddCourses = payload => async (dispatch, getState) => {
  const state = getState()
  const instituteCampusId = instituteSelector.getInstituteDetails(state).id
  try {
    const result = await instituteRequest.post(
      `/institutes/instituteCampus/${instituteCampusId}/course`,
      payload
    )
    function formatDate(date) {
      let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
    
      return [year, month, day].join('-');
    }

 
      let payloadData = {
        query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
      };

    await elasticSearchSyncRequest.post(`/sync/institute_campus_cources`,payloadData)
    SuccessMessage('Course Added Successfully')

    return result
  } catch (error) {
    if (
      error?.response?.data?.message ===
      'This degreeStreamMapId isAlready Exists'
    ) {
      ErrorMessage('This Degree And Department is Already Exist')
    } else {
      ErrorMessage(error?.response?.data?.message)
    }
    console.log('Error in Courses Added =>', error?.message)
    return error?.response
  }
}

export const getSkillList =
  (searchValue, pageNum = 0, pageLimit = 500) =>
  async (dispatch, getState) => {
    let pageNo = pageNum || 0
    let SearchKey = searchValue ? `&search=${searchValue}` : ''
    try {
      const result = await studentRequest.get(
        `students/search/skills?pageLimit=${pageLimit}&pageNo=${pageNo}${SearchKey}`
      )
      dispatch(setListOfSkill(result?.data?.data || {}))
      return result
    } catch (error) {
      console.log('Error in while getting skills', error)
    }
  }

export const getSkill = searchValue => async (dispatch, getState) => {
  let SearchKey = searchValue ? `&search=${searchValue}` : ''

  try {
    const result = await studentRequest.get(
      `students/search/skills?pageNo=0&pageLimit=50${SearchKey}`
    )

    dispatch(setListOfSkill(result?.data?.data))
    return result
  } catch (error) {
    console.log('Error in getting skills list =>', error?.message)
    ErrorMessage(error?.message)
    dispatch(setListOfSkill({}))
  }
}

export const getDegreeData =
  (searchValue, degreeType, domainId) => async (dispatch, getState) => {
    try {
      let search = searchValue ? `&search=${searchValue}` : ''

      let degree = degreeType ? `&degreeType=${degreeType}` : ''
      let domain = domainId ? `&domainId=${domainId}` : ''

      // let status = statusType ? `&status=${statusType}` : ''
      const data = await instituteRequest.get(
        `/institutes/degree?pageLimit=500&pageNo=0&status=1${search}${degree}${domain}`
      )

      // dispatch(setDegreeList(data?.data?.data))
      return data?.data?.data
    } catch (error) {
      console.log('Error in getting degree data', error)
      return error
    }
  }

export const DeleteCourses = (id, collegeID,setLoading) => async (dispatch, getState) => {
  try {
    setLoading(true)
    const result = await instituteRequest.delete(
      `/institutes/instituteCampus/${collegeID}/course/${id}`
    )
    let payloadDelete = {
      course_id:  id  
    };
    await elasticSearchSyncRequest.post(`/sync/del_index_institute_campus_cources`,payloadDelete)
    await delay(3000); // Wait for 5 seconds
    setLoading(false)
    SuccessMessage('Course Deleted Successfully')

    return result
  } catch (error) {
    setLoading(false)
    ErrorMessage(error?.message)
    console.log('Error in Courses Deleted =>', error?.message)
    return error?.response
  }
}

export const EditCourses =
  (id, payload, collegeID, isSuccess) => async (dispatch, getState) => {
    try {
      const result = await instituteRequest.put(
        `/institutes/instituteCampus/${collegeID}/course/${id}`,
        payload
      )
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }
  
   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };
  
      await elasticSearchSyncRequest.post(`/sync/institute_campus_cources`,payloadData)
      SuccessMessage('Course Edited Successfully')
      isSuccess(true)
      return result
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      isSuccess(false)
      console.log('Error in Courses Edited =>', error?.message)
      return error?.response
    }
  }

export const ActiveIsCourses = (id, payload) => async (dispatch, getState) => {
  try {
    const result = await instituteRequest.patch(
      `/institutes/course/${id}/status`,
      payload
    )
    function formatDate(date) {
      let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
    
      return [year, month, day].join('-');
    }

 
      let payloadData = {
        query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
      };

    await elasticSearchSyncRequest.post(`/sync/institute_campus_cources`,payloadData)
    SuccessMessage('Course Status Updated Successfully')

    return result
  } catch (error) {
    ErrorMessage(error?.response?.data?.message)
    console.log('Error in Courses Status Updated =>', error?.message)
    return error?.response
  }
}

export const DeleteSpecialization =
  (courseId, id) => async (dispatch, getState) => {
    const state = getState()
    const instituteCampusId = instituteSelector.getInstituteDetails(state).id
    try {
      const result = await instituteRequest.delete(
        `/institutes/instituteCampus/${instituteCampusId}/course/${courseId}/specialisation/${id}`
      )
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }
  
   
        let payload = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };
  
      await elasticSearchSyncRequest.post(`/sync/institute_campus_cources`,payload)
      return result
    } catch (error) {
      console.log('Error in Courses Deleted =>', error?.message)
      return error?.response
    }
  }

// Bulk Upload

export const AddMultiCourses =
  (payload, collegeID) => async (dispatch, getState) => {
    try {
      const result = await instituteRequest.post(
        `/institutes/instituteCampus/${collegeID}/courses`,
        payload
      )
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }
  
   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };
  
      await elasticSearchSyncRequest.post(`/sync/institute_campus_cources`,payloadData)
      if (result?.data?.error?.alreadyEXistRecords?.length > 0) {
        ErrorMessage('Course Already Exist')
      } else {
        SuccessMessage('Course Added Successfully')
      }

      // SuccessMessage('Course Added Successfully')

      return result
    } catch (error) {
      if (
        error?.response?.data?.message ===
        'This degreeStreamMapId isAlready Exists'
      ) {
        ErrorMessage('This Degree And Department is Already Exist')
      } else {
        ErrorMessage(error?.response?.data?.message)
      }
      console.log('Error in Courses Added =>', error?.message)
      return error?.response
    }
  }

export const getDegreeDataValue = collegeID => async (dispatch, getState) => {
  try {
    const res = await instituteRequest.get(
      `/institutes/instituteCampus/${collegeID}/courses?pageLimit=100&pageNo=0`
    )

    let courses = res?.data?.data?.courses

    courses = courses.map(val => {
      return {
        ...val?.degreeStreamMap?.degree,
        degreeId: val.id,
        stream: {
          ...val?.degreeStreamMap?.stream,
          specialisation: val?.courseSpecialisationMap,
          degreeStreamMapId: val?.degreeStreamMapId,
        },
        duration: val?.degreeStreamMap?.duration,
        examType: val?.degreeStreamMap?.examType,
        specialisation: val?.courseSpecialisationMap,
        degreeStreamMapId: val?.degreeStreamMapId,
      }
    })
    const newArray = []
    for (let i = 0; i < courses.length; i++) {
      const index = newArray.findIndex(val => val.id === courses[i].id)
      if (index > -1) {
        newArray[index].stream.push(courses[i].stream)
      } else {
        newArray.push({
          id: courses[i].id,
          degreeId: courses[i].degreeId,
          name: courses[i].name,
          status: courses[i].status,
          shortForm: courses[i].shortForm,
          type: courses[i].type,
          stream: [courses[i].stream],
          examType: courses[i].examType,
          duration: courses[i].duration,
          specialisation: courses[i].specialisation,
          degreeStreamMapId: courses[i].degreeStreamMapId,
        })
      }
    }
    dispatch(setStudentDegreeData(newArray))
    return true
  } catch (error) {
    console.log('Error in getting Single Student Data', error)
  }
}

export const AddCoursesInSysConfig =
  (payload, collegeID) => async (dispatch, getState) => {
    try {
      const result = await instituteRequest.post(
        `/institutes/crud/degree/dept`,
        payload
      )
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }
  
   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };
  
      await elasticSearchSyncRequest.post(`/sync/crud_degree_department`,payloadData)
      if (result?.data?.error?.alreadyEXistRecords?.length > 0) {
        ErrorMessage('Course Already Exist')
      } else {
        SuccessMessage('Course Added Successfully')
      }

      return result
    } catch (error) {
      if (
        error?.response?.data?.message ===
        'This degreeStreamMapId isAlready Exists'
      ) {
        ErrorMessage('This Degree And Department is Already Exist')
      } else {
        ErrorMessage(error?.response?.data?.message)
      }
      return error?.response
    }
  }

export const EditCoursesInSysConfig =
  (id, payload) => async (dispatch, getState) => {
    try {
      const result = await instituteRequest.put(
        `/institutes/crud/degree/dept/${id}`,
        payload
      )
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }
  
   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };
  
      await elasticSearchSyncRequest.post(`/sync/crud_degree_department`,payloadData)
      SuccessMessage('Course Edited Successfully')

      return result
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      console.log('Error in Courses Edited =>', error?.message)
      return error?.response
    }
  }

