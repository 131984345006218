import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import Flex from 'components/Flex'
import { FlexWrap, Form } from 'components/UIComponents/FormStyles'
import ErrorMessage from 'components/UIComponents/MessagePopup/ErrorMessage'
import AddUserForm from 'modules/Users/Partials/AddUserDrawer/Partials/AddUserForm'
import { Spinner } from 'modules/Users/Style/style'
import React, { useEffect, useState } from 'react'
import SuccessMessage from 'utils/SuccessMessage'

const AddUserDrawer = ({
  addUserDrawer,
  setAddUserDrawer,
  userRole,
  createUser,
  updateUser,
  singleUserData,
  userId,
  getUserData,
  form,
  updateLoading,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      email: singleUserData?.email,
      phoneNumber: singleUserData?.phone_number,
      roleId: singleUserData?.role_id,
      corporateId: singleUserData?.corporateId,
      firstName: singleUserData?.first_name,
      lastName: singleUserData?.last_name,
    })
  }, [singleUserData])

  const onCreateUser = async value => {
    let payLoadValue = {
      ...value,
      email: value?.email?.trim()
    }
    let isEdit = !!singleUserData?._id
    setLoading(true)
    let response = !isEdit
      ? await createUser({ payload: payLoadValue })
      : await updateUser({ userId, payload: payLoadValue })

    if (response?.status >= 200 && response?.status) {
      SuccessMessage(
        isEdit ? 'User updated successfully!' : 'User created successfully!'
      )
      setLoading(false)
      setAddUserDrawer(false)
      getUserData()
      form.resetFields()
    } else {
      ErrorMessage(response?.response?.data?.message)
      setLoading(false)
    }
  }

  const onClose = () => {
    setAddUserDrawer(false)
    form.resetFields()
  }

  const content = () => {
    return (
      <>
        {updateLoading && (
          <Flex center>
            <Spinner />
          </Flex>
        )}
        <Form
          scrollToFirstError
          id={'addUserForm'}
          form={form}
          layout="vertical"
          onFinish={onCreateUser}
        >
          <AddUserForm userRole={userRole} />
        </Form>
      </>
    )
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" height="38px" onClick={onClose} />
        <Button.Primary
          text={singleUserData?._id ? 'Update' : 'Add User'}
          htmlType="submit"
          form={'addUserForm'}
          height="38px"
          loading={loading}
        />
      </FlexWrap>
    )
  }

  return (
    <Drawer
      width="40%"
      title={singleUserData?._id ? 'Edit User' : 'Add New User'}
      placement="right"
      padding="30px"
      headerpadding="16px 30px"
      closable
      onClose={onClose}
      visible={addUserDrawer}
      content={content()}
      footer={footer()}
    />
  )
}

export default AddUserDrawer
