import { Divider, Tooltip } from 'antd'
import Button from 'components/Button/index'
import CloseFilter from 'components/icons/CloseFilter'
import SearchInput from 'components/SearchInputReport'
import { Wrapper } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import ReportSearchFilter from 'components/UIComponents/ReportSearchFilter'
import {
  Container,
  Content,
  FilterTextNew,
  Footer,
  MarginLeft,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import { arrayIntoString } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setStudentListForReport } from '../../../actions'
import CityFilter from '../../Filter/SingleFilter'
import DegreeAndDepartmentFilter from '../../Filter/TreeFilter'
const ListOfStudentFilter = ({
  searchValue,
  setSearchValue,
  tableData,
  degreeList,
  setDegreeValue,
  courseValue,
  setCourseValue,
  collegeValue,
  setCollegeValue,
  specialisationValue,
  setSpecialisationValue,
  setFilterData,
  currentPage,
  tooltip,
  universityData,
  UniversityUrl,
  universityLoader,
  setUniversityLoader,
  collegeData,
  CollegeUrl,
  collegeLoader,
  setCollegeLoader,
  degreeLoader,
  setDegreeList,
  degreeListData,
  searchQuery,
  setSearchDegree,
  setSearchQuery,
  page,
  count,
  loadMoreData,
  loadingDep,
  StateUrl,
  StudentCityUrl,
  studentStateData,
  studentCityData,
  setStateValue,
  setCityValue,
  cityValue,
  stateValue,
  stateLoader,
  cityLoader,
  setStateLoader,
  setCityLoader,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const [clearFlag, setClearFlag] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [location, setLocation] = useState('')
  const [college, setCollege] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [filterCancel, setFilterCancel] = useState(false)
  const submitFilter = () => {
    setIsMenuOpen(false)
    let college = arrayIntoString(collegeValue)
    let course = arrayIntoString(courseValue)
    let specialisation = arrayIntoString(specialisationValue)
    let degree = arrayIntoString(degreeList)
    let state = arrayIntoString(stateValue)
    let city = arrayIntoString(cityValue)
    setIsMenuOpen(false)
    setFilterData({
      college: college,
      course: course,
      specialisation: specialisation,
      degree: degree,
      state: state,
      city: city,
    })
  }

  const onCancelFilter = open => {
    setClearFlag(true)
    setIsMenuOpen(!isMenuOpen)
    setCollegeValue([])
    setDegreeValue([])
    setCourseValue([])
    setSpecialisationValue([])
    setFilterData({})
    setDegreeList([])
    setState('')
    setCity('')
    dispatch(setStudentListForReport([]))
    setSearchQuery('')
    setLocation('')
    setCollege('')
    setSearchValue('')
    setFilterCancel(true)
    StudentCityUrl()
    setCityValue([])
    setStateValue([])
  }
  const onDegreeFilterChange = data => {
    setDegreeList(data)
  }

  return (
    <Wrapper>
      <ReportSearchFilter
        setOpen={setOpen}
        open={open}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={true}
        totalCount={tableData?.count}
        currentCount={10 * (currentPage - 1) + tableData?.result?.length}
      />
      <Divider style={{ margin: '0' }} />
      <Content isOpen={open}>
        <div style={{ width: '100%', display: 'flex', height: '18rem' }}>
          <Wrapper style={{ marginLeft: '1rem' }}>
            <FilterTextNew>{'Degree & Department'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={searchQuery}
              setSearchValue={value => {
                setSearchQuery(value)
              }}
            />
            <Container>
              <DegreeAndDepartmentFilter
                degreeList={degreeListData}
                setDegreeList={onDegreeFilterChange}
                searchQuery={searchQuery}
                degree={degreeList}
                setSearchDegree={setSearchDegree}
                loading={degreeLoader}
                filterCancel={filterCancel}
                setFilterCancel={setFilterCancel}
                degreeLoader={loadingDep}
                page={page}
                count={count}
                loadMoreData={loadMoreData}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper>
            <FilterTextNew>{'Specialisation'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={location}
              setSearchValue={value => {
                setLocation(value)
              }}
            />
            <Container>
              <CityFilter
                key="1"
                value={specialisationValue}
                setValue={setSpecialisationValue}
                search={location}
                setSearch={setLocation}
                cityData={universityData}
                cityDetails={UniversityUrl}
                setLoading={setUniversityLoader}
                loading={universityLoader}
                valueParam={'specialisation'}
                labelParam={'specialisation'}
                flag={'LS'}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />

          <Wrapper>
            <FilterTextNew>{'College Name'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={college}
              setSearchValue={value => {
                setCollege(value)
              }}
            />
            <Container>
              <CityFilter
                key="2"
                search={college}
                setSearch={setCollege}
                value={collegeValue}
                setValue={setCollegeValue}
                cityData={collegeData}
                cityDetails={CollegeUrl}
                setLoading={setCollegeLoader}
                loading={collegeLoader}
                valueParam={'id'}
                labelParam={'campus_name'}
                flag={'LS'}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper>
            <FilterTextNew>{'State'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={state}
              setSearchValue={value => {
                setState(value)
              }}
            />
            <Container>
              <CityFilter
                key="12"
                search={state}
                setSearch={setState}
                value={stateValue}
                setValue={setStateValue}
                cityData={studentStateData}
                cityDetails={StateUrl}
                setLoading={setStateLoader}
                loading={stateLoader}
                valueParam={'corr_state'}
                labelParam={'corr_state'}
                flag={'LS'}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper>
            <FilterTextNew>{'City'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={city}
              setSearchValue={value => {
                setCity(value)
              }}
            />
            <Container>
              <CityFilter
                key="23"
                search={city}
                setSearch={setCity}
                value={cityValue}
                setValue={setCityValue}
                cityData={studentCityData}
                cityDetails={StudentCityUrl}
                setLoading={setCityLoader}
                loading={cityLoader}
                valueParam={'corr_city'}
                labelParam={'corr_city'}
                flag={'LS'}
              />
            </Container>
          </Wrapper>
        </div>
      </Content>
      <Divider style={{ margin: '0' }} />
      <Footer style={{ display: open ? '' : 'none' }}>
        <div
          style={{ marginRight: '180px' }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpen(false)
          }}
          onKeyDown={() => {}}
        >
          <CloseFilter style={{ marginBottom: '-20px', cursor: 'pointer' }} />
        </div>
        <div style={{ marginRight: '30px' }}>
          <Button.Secondary
            height="35px"
            htmlType="Clear"
            text={'Clear Filter'}
            width={'144px'}
            onClick={() => onCancelFilter()}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <MarginLeft>
            <Tooltip
              placement="topLeft"
              title={
                !tooltip
                  ? 'Kindly Select Filter Option Before Submitting the Data'
                  : ''
              }
            >
              <Button.Primary
                height="35px"
                htmlType="Save Filter"
                text={'Apply'}
                width={'144px'}
                onClick={() => submitFilter()}
              />
            </Tooltip>
          </MarginLeft>
        </div>
      </Footer>
      <Divider style={{ margin: '0' }} />
    </Wrapper>
  )
}

export default ListOfStudentFilter
