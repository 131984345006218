import { Empty, Skeleton, Spin } from 'antd'
import { ScrollBar } from 'components/CommonStyles'
import SearchInput from 'components/SearchInput'
import FilterCheckBoxComp from 'components/UIComponents/FilterCheckBoxComp'
import { getListOfCorporateLocations } from 'modules/Onboarding/actions.js'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { EmptyWrapper } from '../../../../../../../Styles/style'
import { FlexWrapper } from 'components/Pagination'

const Wrapper = styled.div`
  padding: 1rem 0.5rem;
`
const LocationFilter = ({
  setLocation,
  location,
  search,
  setSearch,
  loading,
  setLoading,
}) => {
  const [citiesData, setCitiesData] = useState([])
  const [citiesTotalLength, setCitiesTotalLength] = useState(null)
  const [pageNum, setPageNum] = useState(1)
  const [debounced, setDebounced] = useState(() => search)
  let groupBy = 'city'

  useEffect(() => {
    const findCorporateCityList = async () => {
      setLoading(true)
      try {
        const response = await getListOfCorporateLocations(
          groupBy,
          pageNum,
          search
        )
        setCitiesData(response)
        setCitiesTotalLength(response.length)
      } catch (error) {
        console.error('Error fetching corporate city list:', error)
      }
    }

    findCorporateCityList()
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(search)
      getCities(search)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const getCities = async (search, pageNo = 0) => {
    const response = await getListOfCorporateLocations(groupBy, pageNo, search)
    setCitiesData(response)
    setLoading(false)
  }

  const onChange = checkedvalue => {
    let locationCopy = location ? [...location] : []
    let indexToRemove = locationCopy.indexOf(...checkedvalue)

    // Check if the element exists in the array
    if (indexToRemove !== -1) {
      // Remove the element by shifting all the elements after it one position to the left
      locationCopy.splice(indexToRemove, 1)
      setLocation([...locationCopy])
    } else {
      setLocation([...locationCopy, ...checkedvalue])
    }
  }
  const loadMore = async () => {
    if (search) {
      setLoading(true)
      const pageNumber = pageNum + 1
      const data = await getCities(search, pageNumber)
      let newList = data
      setCitiesData(newList)
      setPageNum(prev => prev + 1)
    } else {
      const pageNumber = pageNum + 1
      const data = await getCities('', pageNumber)
      let newList = data
      setCitiesData(newList)
      setPageNum(prev => prev + 1)
    }
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }
  return (
    <Wrapper>
      <SearchInput
        width={'90%'}
        placeholder="Search by keyword"
        bordered={true}
        searchValue={search}
        // setSearchValue={value => {
        //   locationSearch(value)
        // }}
        searchData={true}
        handleSearch={handleSearch}
      />

      <ScrollBar id="scrollableDiv" height={'28vh'}>
        <InfiniteScroll
          dataLength={citiesData?.length}
          next={citiesData.length > 0 ? loadMore : ''}
          hasMore={citiesData?.length < citiesTotalLength}
          loader={
            citiesData.length > 0 ? (
              <Skeleton avatar paragraph={{ rows: 1 }} active />
            ) : (
              <br />
            )
          }
          scrollableTarget="scrollableDiv"
        >
          <FilterCheckBoxComp
            options={citiesData || []}
            value={location}
            onChangeCheck={e => onChange([e.target.value])}
            valueParam="city"
            labelParam="city"
          />
          <br />
          {loading && !citiesData.length ? (
            <FlexWrapper center alignCenter>
              <Spin />
            </FlexWrapper>
          ) : (
            citiesData &&
            !citiesData.length && (
              <EmptyWrapper center>
                <Empty description={`No data Found`} />
              </EmptyWrapper>
            )
          )}
        </InfiniteScroll>
      </ScrollBar>
    </Wrapper>
  )
}

export default LocationFilter
