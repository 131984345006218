import styled from 'styled-components'
import { FONT } from 'theme/font'
import { COLORS } from 'theme'
import Flex from 'components/Flex'

export const RoleColHead = styled.h1`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_BASE};
  line-height: 1.4em;
  color: ${COLORS.GREY_T_20};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const RoleColComment = styled.p`
  margin-bottom: 0;
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_60};
  text-transform: capitalize;
`
export const RowNumberData = styled.div`
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  text-transform: capitalize;
  display: block; /* Change it as per your requirement. */
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // width: 100px;
`
export const RowNumberEmailData = styled.div`
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  display: block; /* Change it as per your requirement. */
  overflow: hidden;
`

export const RowNumberDataCompany = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  text-transform: capitalize;
  display: block; /* Change it as per your requirement. */
  width: 300px;
`

export const RowNumberWebsiteData = styled.div`
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  display: block;
  overflow: hidden;
  text-transform: lowercase;
`
export const RowNumberDataReportAlignCenter = styled(Flex)`
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  align-items: center;
  justify-content: center;
  display: flex;
  word-break: break-word;
`

export const RowNumberDataReport = styled(Flex)`
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  text-transform: capitalize;
  word-break: break-word;
`

export const SpanTag = styled.span`
 width:200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`


export const RowNumberDataWithoutCapitalise = styled.div`
  font-weight: ${FONT.FW_400};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  display: block; /* Change it as per your requirement. */
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // width: 100px;
`
export const UpTag = styled(Flex)`
  padding: 5px 10px;
  margin-left: 10px;
  gap: 5px;
  border-radius: 20px;
  border: 1px solid ${COLORS.GREY_T_90};
  background-color: ${COLORS.PRIMARY_GREY_T_98};
`
export const DivWrapper = styled.div`
  max-width: ${props => (props?.maxwidth ? props?.maxwidth : '100%')};
`
export const Wrapper = styled.div`
  //padding-bottom: 100px;
`
export const FlexWrap = styled(Flex)`
  gap: ${props => (props?.gap ? props?.gap : '0')};
`

export const FilterWrapper = styled.div`
  //padding-bottom: 100px;
`

export const RowNumberDataReportAlignCenterDegreeFilter = styled(Flex)`
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  align-items: center;
  justify-content: center !important;
  display: flex;
  margin-top: 5rem;
`
export const TreeMargin = styled.div`
  margin-top: 1em;
`

export const Capitalize = styled.div`
  text-transform: capitalize;
`

export const CenterAlign = styled.div`
  display: flex;
  margin-left: 20%;
  margin-top: 20%;
`
