import RadioButton from 'components/Form/RadioButton'
import styled from 'styled-components'
import { COLORS } from 'theme/index'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`

export const DivPadding = styled.div`
  color: ${COLORS.GREY_T_35};
  padding-left: 22px;
`
export const SpanPadding = styled.span`
  vertical-align: middle;
`
export const IconAlign = styled.span`
  vertical-align: middle;
`

export const DivPaddingExtra = styled.div`
  padding-top: 10px;
  font-size: 12px;
  color: ${COLORS.GREY_T_20};
  line-height: 2;
  padding-left: 45px;
`

export const ColorSpan = styled.span`
  color: ${COLORS.GREY_T_35};
`

export const StyledRadioPlan = styled(RadioButton)`
  font-size: 20px !important;
  font-weight: 600;
  margin-top: 5px;
`
export const SpanWord = styled.span`
  font-size: 20px !important;
  font-weight: 600;
`
export const PlanStyle = styled.div`
  padding: 20px;
  width: ${props => (props.width ? props.width : '500px')};

  height: 'auto';
  border-radius: 10px;
  border: 1px solid ${COLORS.GREY_T_75};
`
export const StatusSelected = styled.div`
  padding-bottom: 10px;
`
