import React from 'react'

const SubSkills = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.0758 5.1693H22.4528C23.3073 5.1693 24 4.33518 24 3.30633C24 2.27747 23.3073 1.44336 22.4528 1.44336H10.0758C9.22139 1.44336 8.52869 2.27747 8.52869 3.30633C8.52869 4.33518 9.22128 5.1693 10.0758 5.1693Z"
        fill="#3249D7"
      />
      <path
        d="M22.4528 10.1377H10.0758C9.22139 10.1377 8.52869 10.9718 8.52869 12.0007C8.52869 13.0295 9.22139 13.8636 10.0758 13.8636H22.4528C23.3073 13.8636 24 13.0295 24 12.0007C24 10.9718 23.3073 10.1377 22.4528 10.1377Z"
        fill="#3249D7"
      />
      <path
        d="M22.4528 18.8311H10.0758C9.22139 18.8311 8.52869 19.6652 8.52869 20.694C8.52869 21.7229 9.22139 22.557 10.0758 22.557H22.4528C23.3073 22.557 24 21.7229 24 20.694C24 19.6652 23.3073 18.8311 22.4528 18.8311Z"
        fill="#3249D7"
      />
      <path
        d="M5.0319 10.5172L4.00161 10.3369L3.54088 9.21263C3.41051 8.89468 3.14172 8.69336 2.84714 8.69336C2.55278 8.69336 2.28378 8.89468 2.15341 9.21263L1.69257 10.3369L0.662393 10.5172C0.371017 10.5681 0.12884 10.8139 0.0378689 11.1511C-0.0531022 11.4883 0.0228102 11.8584 0.233529 12.1059L0.979039 12.9809L0.802975 14.2166C0.753158 14.5661 0.872493 14.9193 1.11065 15.1277C1.24545 15.2457 1.40491 15.3057 1.5654 15.3057C1.68845 15.3057 1.81212 15.2703 1.92526 15.1987L2.84694 14.6152L3.76861 15.1987C3.88186 15.2704 4.00543 15.3057 4.12848 15.3057C4.1292 15.3057 4.13002 15.3057 4.13064 15.3057C4.55786 15.3055 4.9041 14.8886 4.9041 14.3742C4.9041 14.2961 4.89616 14.22 4.8811 14.1475L4.71484 12.9809L5.46035 12.1059C5.67127 11.8584 5.74708 11.4883 5.65611 11.1511C5.56545 10.8139 5.32327 10.5681 5.0319 10.5172Z"
        fill="#3249D7"
      />
      <path
        d="M5.0319 1.82385L4.00161 1.64351L3.54088 0.519271C3.41051 0.201325 3.14172 0 2.84714 0C2.55278 0 2.28378 0.201325 2.15341 0.519271L1.69257 1.64351L0.662393 1.82385C0.371017 1.87477 0.12884 2.12056 0.0378689 2.45775C-0.0531022 2.79495 0.0228102 3.16506 0.233529 3.41259L0.979039 4.28756L0.802975 5.5232C0.753158 5.8727 0.872493 6.22592 1.11065 6.43432C1.24545 6.55231 1.40491 6.6123 1.5654 6.6123C1.68845 6.6123 1.81212 6.5769 1.92526 6.50536L2.84694 5.92188L3.76861 6.50536C3.88186 6.57702 4.00543 6.6123 4.12848 6.6123C4.1292 6.6123 4.13002 6.6123 4.13064 6.6123C4.55786 6.6123 4.9041 6.19524 4.9041 5.68081C4.9041 5.60269 4.89616 5.52668 4.8811 5.45415L4.71484 4.28756L5.46035 3.41259C5.67127 3.16506 5.74708 2.79495 5.65611 2.45775C5.56545 2.12056 5.32327 1.87477 5.0319 1.82385Z"
        fill="#3249D7"
      />
      <path
        d="M5.0319 19.2115L4.00161 19.0312L3.54088 17.907C3.41051 17.589 3.14172 17.3877 2.84714 17.3877C2.55278 17.3877 2.28378 17.589 2.15341 17.907L1.69257 19.0312L0.662393 19.2115C0.371017 19.2625 0.12884 19.5082 0.0378689 19.8454C-0.0531022 20.1826 0.0228102 20.5528 0.233529 20.8003L0.979039 21.6753L0.802975 22.9109C0.753158 23.2604 0.872493 23.6136 1.11065 23.822C1.24545 23.94 1.40491 24 1.5654 24C1.68845 24 1.81212 23.9646 1.92526 23.8931L2.84694 23.3096L3.76861 23.8931C3.88186 23.9647 4.00543 24 4.12848 24C4.1292 24 4.13002 24 4.13064 24C4.55786 23.9999 4.9041 23.5829 4.9041 23.0685C4.9041 22.9904 4.89616 22.9144 4.8811 22.8418L4.71484 21.6753L5.46035 20.8003C5.67127 20.5528 5.74708 20.1826 5.65611 19.8454C5.56545 19.5082 5.32327 19.2625 5.0319 19.2115Z"
        fill="#3249D7"
      />
    </svg>
  )
}
export default SubSkills
