import React from 'react'

import {
  SysSingleCard,
  SysCardText,
  SysCardValue,
} from 'components/SysConfigCards/style'
import { COLORS } from 'theme'

const SysConfigCards = ({
  background = COLORS.BLUE_T_95,
  padding = '15px 15px',
  width = 'auto',
  icon,
  text,
  value,
  height,
  state,
  current,
  onClick,
}) => {
  return (
    <SysSingleCard
      onClick={onClick}
      background={current && state == current ? COLORS.BLUE_T_80 : background}
      padding={padding}
      width={width}
      column
      flexStart
      height={height}
      spaceBetween
    >
      {icon}
      <div>
        <SysCardText>{text}</SysCardText>
        <SysCardValue>{value}</SysCardValue>
      </div>
    </SysSingleCard>
  )
}

export default SysConfigCards
