import Flex from 'components/Flex'
import Tree from 'components/Tree'
import React, { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled(Flex)`
  padding: 20px 20px 20px 0;
  gap: 5px;
`

const DegreeAndDepartmentFilter = ({ degreeList, setDegreeList }) => {
  const [checkedKeys, setCheckedKeys] = useState([])
  const onCheck = (activeKeys, info) => {
    setCheckedKeys(activeKeys)
    let activeIds = info?.checkedNodes
      ?.filter(item => Object.keys(item)?.length <= 2)
      ?.map(id => id?.value)

    setDegreeList(activeIds)
  }

  return (
    <>
      <br></br>
      {degreeList.length > 0 ? (
        <Tree
          treeData={degreeList}
          checkedKeys={checkedKeys}
          onCheck={onCheck}
        />
      ) : (
        <div>There is no Degree and Department mapped in this Institute</div>
      )}
    </>
  )
}

export default DegreeAndDepartmentFilter
