import axios from 'axios'
const { SEARCH_API_URL } = process.env
const { ADMIN_API_KEY_TOKEN } = process.env

const elasticSearchSyncRequest = axios.create({
  baseURL: SEARCH_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'api-key': ADMIN_API_KEY_TOKEN,
  },
})

export default elasticSearchSyncRequest
