/** customizable metrics -
 * 1. color
 * 2. font color
 * 3. font weight
 */

import styled from 'styled-components'

const Label = styled.span`
  text-align: left;
  font-family: 'Plus Jakarta Sans';
  color: ${props => (props.color ? props.color : '#4d566e')};
  font-size: ${props => (props.size ? props.size : '14px')};
  font-weight: ${props => (props.weight ? props.weight : '500')};
  margin-bottom ${props => (props.marginBottom ? props.marginBottom : '8px')};
  margin-top ${props => (props.marginTop ? props.marginTop : '0px')};
  white-space: nowrap;
`

export default Label
