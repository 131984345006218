import {
  ActionMenu,
  ButtonText,
  ItemText,
  Main,
  StyledDropDownButton,
} from 'components/ActionStyles/ActionStyles'
import DownArrow from 'components/icons/DownArrow'
import { reInviteInstitute } from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/actions'
import React from 'react'
import { useDispatch } from 'react-redux'
import SuccessMessage from 'utils/SuccessMessage'

const { ADMIN_URL } = process.env

const ActionDropdown = ({
  rowData,
  getSingleInstituteData,
  setViewInstituteDrawer,
  setVerifyInstituteDrawer,
}) => {
  const dispatch = useDispatch()

  const callBackError = msg => {
    ErrorMessage(msg || 'No Institute Found')
    navigate('/onboarding')
  }

  const onClickView = () => {
    setViewInstituteDrawer(true)
    dispatch(getSingleInstituteData(rowData?.id, callBackError))
  }

  const onClickVerifyApprove = () => {
    dispatch(getSingleInstituteData(rowData?.id, callBackError))
  }

  const onClickLink = () => {
    navigator.clipboard.writeText(
      `${ADMIN_URL}onboarding/institute/${rowData?.id}`
    )
    SuccessMessage('Link Coppied!')
  }

  const handleInvite = rowData => {
    dispatch(reInviteInstitute(rowData?.instituteCampus?.[0]?.contactEmail))
  }

  const menu = (
    <ActionMenu
      width={'200px'}
      items={[
        {
          key: 'inactive',
          label: (
            <ItemText
              onClick={() => {
                onClickVerifyApprove()
                setVerifyInstituteDrawer(true)
              }}
            >
              Verify & Approve
            </ItemText>
          ),
        },
        {
          key: 'active',
          label: (
            <ItemText onClick={onClickLink}>Copy Onboarding Link</ItemText>
          ),
        },
        {
          key: 'invite',
          label: (
            <ItemText onClick={() => handleInvite(rowData)}>
              Resend Invite
            </ItemText>
          ),
        },
      ]}
    />
  )

  return (
    <Main id="filterDropdown">
      <StyledDropDownButton
        overlay={menu}
        shape="round"
        trigger={['click']}
        icon={<DownArrow />}
        getPopupContainer={() => document.getElementById('filterDropdown')}
      >
        <ButtonText onClick={() => onClickView()}>View</ButtonText>
      </StyledDropDownButton>
    </Main>
  )
}

export default ActionDropdown
