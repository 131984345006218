import { Form,FooterFlex} from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import DocumentDetails from 'modules/Onboarding/Partials/Corporates/Partials/UploadDocumentDrawer/Partials/DocumentDetails'
import React from 'react'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
//import { FooterFlex } from 'modules/Onboarding/Partials/Corporates/Partials/AddNewCorporateDrawer/Style/style'
import { saveFormData } from 'modules/Onboarding/Partials/Corporates/actions'
import { useDispatch, useSelector } from 'react-redux'

const UploadDocumentDrawer = ({
  form,
  setNewPlanDrawer,
  corporateClear,
  setNewPreviewDrawer,
  title = 'Upload Document',
  sendButtonText = 'Preview',
  newUploadDocumentDrawer,
  setNewUploadDocumentDrawer,
}) => {
  const formDetail = useSelector(state => state?.formData?.formData)

  const dispatch = useDispatch()

  const onClearAll = () => {
    form.resetFields()
    setNewUploadDocumentDrawer(false)
    corporateClear()
  }

  const onClear = () => {
    setNewUploadDocumentDrawer(false)
    setNewPlanDrawer(true)
  }

  const onSubmitFormData = async values => {
    const previewData = { ...formDetail, ...values }
    dispatch(saveFormData(previewData))
    setNewUploadDocumentDrawer(false)
    setNewPreviewDrawer(true)
  }

  const content = () => {
    return (
      <div>
        <DocumentDetails form={form} />
      </div>
    )
  }

  const footer = () => {
    return (
      <FooterFlex right>
        <Button.Secondary text="Back" onClick={onClear} />
        <Button.Primary
          htmlType="submit"
          form={'editForm'}
          text={sendButtonText}
        />
      </FooterFlex>
    )
  }
  return (
    <Form
      scrollToFirstError
      onFinish={onSubmitFormData}
      id={'editForm'}
      form={form}
    >
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={onClearAll}
        visible={newUploadDocumentDrawer}
        content={content()}
        footer={footer()}
      />
    </Form>
  )
}

export default UploadDocumentDrawer
