import { Col, Form, Menu, Row } from 'antd'
import Button from 'components/Button/index'
import Switch from 'components/Form/Switch'
import 'draft-js/dist/Draft.css'
import { WORK_EXP_MENU } from 'modules/RankingAlgorithm/Data.js'
import CommonIndex from 'modules/RankingAlgorithm/Partials/Project/Common/CommonIndex.js'
import 'modules/RankingAlgorithm/style.css'
import {
  ActiveHeader,
  EventHeader,
  FooterDiv,
  FooterFlex,
  RowStyling,
  SectionWrapperToggle,
  StyledMenu,
  TopHeader2,
} from 'modules/RankingAlgorithm/Styles/RankingAlgorithmStyles.js'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
export default function WorkExperienceIndex({
  academicDataConfig,
  updateAcademictData,
  handleChange,
}) {
  const [form] = Form.useForm()
  const [defaultInternship, setDefaultInternship] = useState('Mandatory Skills')
  const [data, setData] = useState({})
  const [academicDataAlone, setAcademicDataAlone] = useState([])
  const [academicToggle, setAcademicToggle] = useState(false)
  const [academicValidation, setAcademicValidation] = useState(false)
  const [singleDurationData, setSingleDurationData] = useState([])
  const [domainData, setDomainData] = useState({})
  const [current, setCurrent] = useState('left')
  const [dataMoved, setDataMoved] = useState(false)
  const academicListOnClick = event => {
    setDefaultInternship(event)
    setCurrent('left')
    if (event === 'Duration') {
      setAcademicToggle(data?.duration?.isActive)
    } else if (event === 'Mandatory Skills') {
      setAcademicToggle(data?.mandatorySkills?.isActive)
    } else {
      setAcademicToggle(data?.domain?.isActive)
    }
  }
  const setToggle = checked => {
    setAcademicToggle(checked)
    setData(prevData => {
      let newData = prevData

      newData[
        defaultInternship == 'Duration'
          ? 'duration'
          : defaultInternship == 'Domain'
          ? 'domain'
          : 'mandatorySkills'
      ].isActive = checked
      return newData
    })
  }
  useEffect(() => {
    if (data) {
      if (defaultInternship) {
        setSingleDurationData(data?.duration?.config || [])
      }
    }
  }, [defaultInternship, dataMoved])
  useEffect(() => {
    setDataMoved(false)
    if (academicDataConfig) {
      setData(academicDataConfig)
      if (defaultInternship === 'Duration') {
        setAcademicToggle(academicDataConfig?.duration?.isActive)
      } else if (defaultInternship === 'Mandatory Skills') {
        setAcademicToggle(academicDataConfig?.mandatorySkills?.isActive)
      } else {
        setAcademicToggle(academicDataConfig?.domain?.isActive)
      }
      setDataMoved(true)
      setDomainData(academicDataConfig?.domain)
    }
  }, [academicDataConfig])
  function deepClone(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }

    if (Array.isArray(obj)) {
      return obj.map(item => deepClone(item))
    }

    const clonedObj = {}
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        clonedObj[key] = deepClone(obj[key])
      }
    }

    return clonedObj
  }
  useEffect(() => {
    updateScoreConfigForUG()
  }, [singleDurationData, domainData])

  const updateScoreConfigForUG = () => {
    setData(prevData => {
      const newData = { ...prevData }
      const clonedDATAs = deepClone(newData)

      if (defaultInternship == 'Duration') {
        clonedDATAs?.duration?.config?.splice(
          0,
          clonedDATAs?.duration?.config?.length,
          ...singleDurationData
        )
      } else {
        clonedDATAs.domain = domainData
      }
      return clonedDATAs
    })
  }
  const onsubmit = () => {
    let mainbase = 'WORK_EXPERIENCE'
    let payload = data
    updateAcademictData({ mainbase, payload })
  }
  useEffect(() => {
    setDefaultInternship('Mandatory Skills')
  }, [])

  return (
    <Form form={form} onFinish={onsubmit}>
      <SectionWrapperToggle column>
        <RowStyling>
          <Col span={6}>
            {WORK_EXP_MENU?.map(item => (
              <StyledMenu
                key={item.name}
                selectedKeys={defaultInternship}
                defaultSelectedKeys={defaultInternship}
              >
                <Menu.Item
                  key={item?.name}
                  onClick={() => academicListOnClick(item.name)}
                >
                  {item?.name}
                </Menu.Item>
              </StyledMenu>
            ))}
          </Col>
          <Col span={18}>
            <Row>
              <TopHeader2 row spaceBetween alignCenter>
                <Col span={15}>
                  <EventHeader>{defaultInternship}</EventHeader>
                </Col>
                <Col span={6}>
                  <ActiveHeader>
                    {' '}
                    {academicToggle ? 'Active' : 'Inactive'}
                  </ActiveHeader>
                </Col>
                <Col span={3}>
                  <Switch
                    checked={academicToggle}
                    onChange={checked => setToggle(checked)}
                  />
                </Col>
              </TopHeader2>
            </Row>
            <Col span={18} style={{ maxWidth: '100%' }}>
              <CommonIndex
                keys={academicValidation}
                singleDurationData={singleDurationData}
                setSingleDurationData={setSingleDurationData}
                setAcademicDataAlone={setAcademicDataAlone}
                academicDataAlone={academicDataAlone}
                setAcademicValidation={setAcademicValidation}
                academicValidation={academicValidation}
                academicName={defaultInternship}
                updateScoreConfigForUG={updateScoreConfigForUG}
                current={current}
                setCurrent={setCurrent}
                domainData={domainData}
                setDomainData={setDomainData}
                form={form}
              />
            </Col>
          </Col>
          <FooterDiv>
            <FooterFlex right>
              <Button.Secondary text="Cancel" onClick={handleChange} />
              <Button.Primary text={'Save and Update'} htmlType={'submit'} />
            </FooterFlex>
          </FooterDiv>
        </RowStyling>
      </SectionWrapperToggle>
    </Form>
  )
}
