import Control from 'components/Control/Control'
import Label from 'components/Label/Label'
import { FormItem } from 'modules/Onboarding/Partials/Corporates/Register/CorporateInfo/style'
import React from 'react'

import AntdInput from 'components/Form/Input'

import Uploadloader from 'components/Uploader/Uploader'

import FileReader from 'components/Uploader/FileReader'
import CONSTANTS from 'utils/constants'

const TaxDetails = ({
  form,
  onChangeAuthorizedSignatory,
  authorisedSignatory,
}) => {
  return (
    <Control flexStart row width="100%" spaceBetween>
      <Control flexStart width="67%" position="static" gap="30px" column>
        <Control column flexStart>
          <Label>PAN Number</Label>
          <AntdInput
            placeholder="Eg:ABCTY1234D"
            name={'pan'}
            rules={[
              {
                required: false,
                message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('PAN number'),
              },
              {
                pattern: CONSTANTS.REGEX.PAN,
                message: CONSTANTS.ERROR_MESSAGE.VALID('PAN number'),
              },
            ]}
          />
        </Control>

        <Control flexStart column>
          <Label>GST Number</Label>
          <AntdInput
            placeholder="Eg:22AAAAA0000A1Z5"
            name={'gst'}
            rules={[
              {
                required: false,
                message: CONSTANTS.ERROR_MESSAGE.REQ_INPUT('GST number'),
              },
              {
                pattern: CONSTANTS.REGEX.GST,
                message: CONSTANTS.ERROR_MESSAGE.VALID('GST number'),
              },
            ]}
          />
        </Control>
        <Control flexStart column width="552px">
          <FormItem
            name={'authorisedSignatory'}
            rules={[
              {
                required: false,
                message: 'Please upload Contract!',
              },
            ]}
          >
            <Label>Authorized Signatory</Label>
            {authorisedSignatory ? (
              <Control column top="10px" center>
                <FileReader
                  file={authorisedSignatory}
                  setFile={onChangeAuthorizedSignatory}
                />
              </Control>
            ) : (
              <Control column top="10px" center>
                <Uploadloader
                  setFile={onChangeAuthorizedSignatory}
                  isDefault={true}
                  fileName={'document'}
                />
              </Control>
            )}
          </FormItem>
        </Control>
      </Control>
    </Control>
  )
}

export default TaxDetails
