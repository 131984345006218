import { SET_USER_INFO } from '../../../Auth/actions'
import {
  SET_CORPORATES_VIEW,
  SET_INSTITUTE_VIEW,
  SET_CORPORATE_JOURNEY,
  SET_INSTITUTE_JOURNEY,
} from './actions'

const initialState = {
  corporateViewData: {},
  instituteViewData: {},
  corporatejourneyData: {},
  institutejourneyData: {},
  userData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CORPORATES_VIEW:
      return {
        ...state,
        corporateViewData: action.data,
      }
    case SET_INSTITUTE_VIEW:
      return {
        ...state,
        instituteViewData: action.data,
      }
    case SET_CORPORATE_JOURNEY:
      return {
        ...state,
        corporatejourneyData: action.data,
      }
    case SET_INSTITUTE_JOURNEY:
      return {
        ...state,
        institutejourneyData: action.data,
      }
    case SET_USER_INFO:
      return {
        ...state,
        userData: action.data,
      }
    default:
      return state
  }
}
