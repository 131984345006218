/* eslint-disable react/prop-types */
import React from 'react'
//import ErrorMessage from 'utils/ErrorMessage'
import Flex from 'components/Flex'
import DeleteIcon from 'components/icons/Delete'
import PDFIcon from 'components/icons/PDFIcon'
import JPGIcon from 'components/icons/JPGIcon'
import { deleteFile } from 'modules/Onboarding/actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import SuccessMessage from 'utils/SuccessMessage'
import {
  StyledCardDiv,
  StyledImg,
  StyledH2,
  StyledP,
  StyledDelete,
  AdjustIcon,
  SmallCirlcle,
  SpanText,
} from 'components/Uploader/Styles'
import ErrorMessage from '../UIComponents/MessagePopup/ErrorMessage'

const FileReader = ({ file, setFile }) => {
  const dispatch = new useDispatch()
  let size = (file?.size / 1024 / 1024)?.toFixed(2) + ' MB'

  const handleRemove = async () => {
    let response = await dispatch(deleteFile(file?.url))
    if (response) {
      setFile(null)
      return SuccessMessage('File deleted successfully!')
    } else {
      return ErrorMessage('Failed to delete the file!')
    }
  }

  const parts = file?.url?.split('.')
  const fileExtension = parts?.[parts?.length - 1];



  return (
    <StyledCardDiv spaceBetween center alignCenter>
      <StyledImg>
        <AdjustIcon margin>
          {file?.type || fileExtension === 'pdf' ? <PDFIcon /> : <JPGIcon />}
        </AdjustIcon>
        <Flex column>
          <StyledH2>{file?.name || `document.${fileExtension}`}</StyledH2>
          <StyledP alignCenter>
            <SpanText>
              {moment(file?.date)?.format('Do MMM, YYYY') +
                ' at ' +
                moment(file?.date)?.format('h:mm')}
            </SpanText>
            <SmallCirlcle />
            <SpanText>{file.size ? size : ''}</SpanText>
          </StyledP>
        </Flex>
      </StyledImg>

      <Flex alignCenter>
        <StyledDelete onClick={() => handleRemove()}>
          <DeleteIcon />
        </StyledDelete>
      </Flex>
    </StyledCardDiv>
  )
}

export default FileReader
