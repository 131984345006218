import React from 'react'

const CheckIconWhite = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.95827 14.6458C7.84716 14.6458 7.74299 14.6283 7.64577 14.5933C7.54855 14.5589 7.45827 14.5 7.37494 14.4167L3.79161 10.8333C3.63883 10.6806 3.56577 10.4825 3.57244 10.2392C3.57966 9.9964 3.65966 9.79862 3.81244 9.64584C3.96522 9.49306 4.15966 9.41668 4.39577 9.41668C4.63188 9.41668 4.82633 9.49306 4.97911 9.64584L7.95827 12.625L15.0208 5.56251C15.1735 5.40973 15.3716 5.33334 15.6149 5.33334C15.8577 5.33334 16.0555 5.40973 16.2083 5.56251C16.3611 5.71529 16.4374 5.91307 16.4374 6.15584C16.4374 6.39918 16.3611 6.59723 16.2083 6.75001L8.54161 14.4167C8.45827 14.5 8.36799 14.5589 8.27077 14.5933C8.17355 14.6283 8.06938 14.6458 7.95827 14.6458Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckIconWhite

//url(#mask0_207_2634)
