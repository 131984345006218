import { connect } from 'react-redux'
import Top10Index from '../../Corporates/Partials/Top10CorporatesTable/Top10CorTable/Top10Index'
import {
  getCorporateListForReport,
  getSectorList,
  getCityList,
  getIndustryList,
  getStateList,
  getEmpanelledCorporateListForReport,
  getTopBottomCorporateDetails,
  IndustrySectorUrl,
  getSingleSectorList,
  getSingleIndustryList,
} from 'modules/Reports/actions'
import {getUserInfo } from '../../../../Auth/actions'
import selector from '../../../../Auth/selectors'
import selectors from 'modules/Reports/selectors'

const mapStateToProps = state => ({
  corporateListDataForReport: selectors?.corporateListReportData(state),
  sectorList: selectors?.sectorListData(state),
  cityList: selectors?.cityListData(state),
  stateList: selectors?.stateListData(state),
  industryList: selectors?.industryListData(state),
  empanelledCorporateList: selectors?.corporateEmpanelledListReportData(state),
  topOrBottomCorporateList: selectors?.topBottomCorporateDetails(state),
  excelDataForReport: selectors?.excelData(state),
  industrySectorData: selectors?.industrySectorData(state),

  singleSectorData: selectors?.singleSectorData(state),
  singleIndustryData: selectors?.singleIndustryData(state),

  userData: selector?.getUserData(state),

})

const mapDispatchToProps = {
  getCorporateListForReport,
  getSectorList,
  getCityList,
  getIndustryList,
  getStateList,
  getEmpanelledCorporateListForReport,
  getTopBottomCorporateDetails,
  IndustrySectorUrl,

  getSingleSectorList,
  getSingleIndustryList,

  getUserInfo

}

export default connect(mapStateToProps, mapDispatchToProps)(Top10Index)
