/* eslint-disable react/no-unknown-property */

import React from 'react'

const ExitIcon = ({ color = '#999FAC' }) => (
  <svg
    fill="#fc8383"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="22px"
    viewBox="0 0 471.85 471.85"
    stroke="#f74f5a"
    transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#CCCCCC"
      strokeWidth="0.943702"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <g>
          {' '}
          <path d="M340.109,355.875c-5.707,0-11.023,2.153-14.578,5.926c-3.438,3.646-5.094,8.516-4.764,14.062 c1.087,18.758,1.366,37.374,1.438,54.903c-24.923,0.365-53.802,0.558-83.906,0.558c-56.414,0.006-107.818-0.665-145.585-1.878 c-0.317-75.389-2.133-151.893-3.89-225.927c-1.246-52.654-2.541-107.049-3.285-160.649c29.66-1.623,68.789-2.381,122.435-2.381 c26.509,0,52.722,0.183,76.279,0.348c9.282,0.068,18.159,0.124,26.481,0.178c0.544,11.656,1.468,23.237,2.519,35.878 c0.036,0.421,0.102,0.815,0.193,1.3c-0.137,0.937-0.208,1.871-0.208,2.798v12.022c0,11.154,9.074,20.225,20.23,20.225 s20.23-9.071,20.23-20.225V80.989c0-0.317-0.021-0.63-0.061-0.932c0.137-1.34,0.152-2.656,0.04-4.009 c-1.411-16.955-2.874-34.489-2.985-52.206c-0.03-4.522-1.407-8.653-3.977-11.989c-3.184-7.021-9.76-11.192-17.742-11.212 c-15.335-0.031-32.275-0.15-50.16-0.287C255.363,0.183,230.286,0,205.056,0C143.074,0,98.469,1.166,64.68,3.662 c-6.807,0.505-12.454,3.89-15.942,9.551c-2.61,3.385-3.963,7.607-3.905,12.226c0.686,59.694,2.143,120.355,3.552,179.026 c1.902,79.232,3.867,161.16,3.966,241.737c0.013,8.196,4.296,14.817,11.535,17.936c3.468,3.271,7.939,5.093,13.004,5.281 c41.172,1.569,97.814,2.432,159.484,2.432c37.234,0,74.959-0.319,106.219-0.919c8.709-0.162,15.757-5.312,18.474-13.456 c1.102-2.514,1.655-5.302,1.655-8.277c-0.005-26.329-0.116-50.069-1.508-73.945C360.462,362.527,350.032,355.875,340.109,355.875z "></path>{' '}
          <path d="M406.383,142.679h-117.84c-0.152-16.618-0.645-33.215-2.356-49.777c-0.091-0.942-0.33-1.78-0.533-2.643 c-0.797-14.117-18.54-26.015-30.554-12.659c-41.36,45.956-82.726,91.911-124.083,137.867c-7,3.146-12.299,10.836-11.832,18.943 c-0.467,8.104,4.832,15.797,11.832,18.94c41.357,45.956,82.723,91.911,124.083,137.872c12.014,13.351,29.757,1.447,30.554-12.659 c0.203-0.863,0.442-1.706,0.533-2.646c1.712-16.56,2.204-33.159,2.356-49.779h117.84c8.805,0,14.31-5.113,16.508-11.518 c2.504-2.858,4.129-6.672,4.129-11.552v-62.048v-13.23v-62.045c0-4.888-1.625-8.694-4.124-11.547 C420.692,147.797,415.188,142.679,406.383,142.679z M390.6,227.796v13.226v48.697H275.264c-1.721,0-3.265,0.244-4.737,0.6 c-9.146-0.051-18.332,5.814-18.337,17.61c0,8.49-0.056,16.98-0.198,25.477c-29.693-33.002-59.389-65.999-89.09-98.995 c29.696-33,59.392-65.996,89.09-98.995c0.138,8.487,0.198,16.978,0.198,25.479c0,11.793,9.191,17.661,18.337,17.608 c1.468,0.358,3.017,0.602,4.737,0.602H390.6V227.796z"></path>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
  //   <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADO0lEQVR4nO2ZS0hVURSGv5taaWUZRFb0oKKRA4noKU2i6P0g8EKCZWDDZkHDKCxoUEhZQdSoSZlOapRSBAVFRM9B16QyEyK0d6ZJ3tiwhMO+++h1333OuTf8Yc32evxnr7P2XmvDGP5fzAAqgTNAC/Ae+AEkRb4DXUCrrNkjOlmBQmAvcBv46wk6XVE694EaYFJUBA4BHy2C95Me4HCYhLYCbxwS0KUL2BkkgYnAhWECGAQeAHXAdmAJMNmjPwVYDOwAjgEPR0jHK0HszkzgsY/DLxL8Qgu7C4RUt4/tJ8B8VySUs3aDk37gBFDswMdUIdRv8NMJLHKxE68Nxl8CZbhHmeyC7k+V8tm2RicAjwxGm4AigkMRcM3g97StwbMGY5eBPILHOOCS5rvextAGA4nmkEh4ydTJ+XIPmIVFSr3SSLRJ+cwpHNRIDADl5BjGS4XwEjkVUSx5QMxWuVIjoW6v0wkfNcBP4BOwy8bAzSzYjTy58ntTOz4aA+pn/qMRCeLQS6da9Rj+07TJbDac3lFhtwRvRabOxQHkEHFbMs2aUhXRI25D5oWmsIzsQHy0ZD5oi61vm1LGXbbBSYMMSJOWgm/awuIMqo5fo+RaOkwBfNUWqWbHBrEQdiMpohq+FHRqi+Zgj02Gi6dr6QPWmZw/0xauIDtQK4MNncQWP4VGbXE1OUhC4aimcI4cJKGwXlNKEB2qbEkMNf19mvJSokGPLYkhNGkGGogG3ZmQQE5KL5FeoDSYWEeM452UcGOJTaepadPIqHlvTqJWI6IGzavIQeQDzzUyb4GSCGOK2SpWGEb+LTJlCQsFcpYNSouhhulWOG6431wPiUyB+HJSQVWK3TGQaQ04zUqAWwa/JzMxOs1wmUxKaVyDe1T4vMUkXLwAl/qQUf/QRZvhsgHKxnmfp7hEht1qys6Y0kzJbwnCpscvl9z/5WP7bhBv8fkyMhruAbNdDtB9wEpgruS8+hDzgOUyDm3wSaGkZ7frgy4sq4GnAXZ+CWAtIUHtzn6f90VbUa32ASm9oUPdzbbJm1+vRfDq/7ohl0T1cbIChXJTPQJclfRT45rP8hbfIVefRulGNwb8qDoGosQ/71ZLR0PsvK0AAAAASUVORK5CYII="></img>
)

export default ExitIcon
