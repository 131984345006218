import styled from 'styled-components'
import Flex from 'components/Flex'
import { COLORS } from 'theme'
import { StyledTabs } from 'components/TabsPaneStyles'
import { Form as AntdForm } from 'antd'

export const NavWrapper = styled(Flex)`
  padding-bottom: 50px;
  // overflow-x:overlay;
`
export const TableTop = styled(Flex)`
  padding: 5px 20px 0px 20px;
  border-top: 1px solid #E6E7EA;
`

export const StyledP = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.GREY_T_20};
  ${props => props.textType && `text-transform: ${props.textType};`}
  span {
    text-transform: capitalize;
  }
`
export const StyledTab = styled(StyledTabs)`
  .ant-tabs-content {
    height: 270px !important;

    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const SubmitFlex = styled(Flex)`
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${COLORS.GREY_T_90};
`
export const MenuFlex = styled(Flex)`
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
`
export const OverlayFlex = styled(Flex)`
  padding: 10px 10px 0 10px;
  gap: 5px;
  ul {
    padding-left: 30px;
  }
`
export const BlacklistHeading = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.PRIMARY_GREY_T_98};
  margin-bottom: 0;
`
export const StyledLi = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GREY_T_80};
  margin-bottom: 5px;
  :last-child {
    margin-bottom: 0px;
  }
`
export const Form = styled(AntdForm)`
  padding: 1rem;
`
export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0px !important;
`
