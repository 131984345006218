import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import authRequest from 'utils/authRequest'
import corporateRequest from 'utils/corporateRequest'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'
export const SET_CORPORATES_LIST = 'SET_CORPORATES_LIST'
export const SET_SINGLE_CORPORATES_DATA = 'SET_SINGLE_CORPORATES_DATA'
export const SET_CORPORATES_METRICS_DATA = 'SET_CORPORATES_METRICS_DATA'
export const SET_GENERATED_LINK = 'SET_GENERATED_LINK'

export const setCorporatesList = data => {
  return {
    type: SET_CORPORATES_LIST,
    data,
  }
}

export const setSingleCorporateData = data => {
  return {
    type: SET_SINGLE_CORPORATES_DATA,
    data,
  }
}

export const setCorporateMetricsData = data => {
  return {
    type: SET_CORPORATES_METRICS_DATA,
    data,
  }
}

export const setGeneratedLink = data => {
  return {
    type: SET_GENERATED_LINK,
    data,
  }
}

export const editCorporate =
  (corporate_id, data, setPrevDrawer, onClearAll) =>
  async (dispatch, getState) => {
    try {
      await corporateRequest.put(`/corporates`, data)
      dispatch(getCorporatesListData({}))
      onClearAll()
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }

   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };

      await elasticSearchSyncRequest.post(`/sync/corporate_list`,payloadData)
      await elasticSearchSyncRequest.post(`/sync/corporate_locations`,payloadData)

      SuccessMessage('Corporate created successfully.')
      setPrevDrawer(false)
    } catch (error) {
      ErrorMessage(error?.message)
    }
  }

export const getCorporatesListData =
  ({ currentPage, searchBy, filterData, sortBy, setLoading }) =>
  async (dispatch, getState) => {
    if (setLoading) {
      setLoading(true)
    }
    const current = currentPage
      ? `pageLimit=10&currentPage=${currentPage}`
      : `pageLimit=10&currentPage=0`
    const search = searchBy ? `&search=${searchBy}` : ''
    const statusCorporate = filterData?.status
      ? `&status=${filterData?.status}`
      : ''
    const locationFilter = filterData?.location
      ? `&location=${filterData?.location}`
      : ''
    const sort = sortBy ? `&sort=name&orderBy=${sortBy}` : ''

    try {
      const data = await elasticSearchRequest.get(
        `/corporates/list?${current}${search}${statusCorporate}${locationFilter}${sort}`
      )
      dispatch(setCorporatesList(data?.data?.data || []))
      if (setLoading) setLoading(false)
    } catch (error) {
      if (setLoading) setLoading(false)
    }
  }

export const reInviteCorporate =
  (email, callBack = '') =>
  async dispatch => {
    try {
      const data = await authRequest.get(`/users/resentCredentials/${email}`)
      if (data.status === 200) {
        if (callBack) {
          callBack()
        } else {
          SuccessMessage('Resend Invite Successfully')
        }
      }
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
    }
  }

export const corporatesMetricsData = () => async (dispatch, getState) => {
  try {
    const res = await corporateRequest.get(`/corporates/list`)
    dispatch(setCorporateMetricsData(res?.data?.data || {}))
  } catch (error) {}
}

export const createCorporate =
  (data, callBackSuccess, callBackError) => async (dispatch, getState) => {
    try {
      const result = await corporateRequest.post(`/corporates`, data)
      dispatch(getCorporatesListData({}))
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }

   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };

       await elasticSearchSyncRequest.post(`/sync/corporate_list`,payloadData)
       await elasticSearchSyncRequest.post(`/sync/corporate_locations`,payloadData)

      SuccessMessage('Corporate Added successfully.')
      dispatch(
        setGeneratedLink({
          generatedLink: result?.data?.data?.generatedLink,
          id: result?.data?.data?.id,
        })
      )
      if (callBackSuccess) {
        callBackSuccess()
      }
    } catch (error) {
      if (callBackError) {
        callBackError()
      }
      ErrorMessage(error?.response?.data?.message)
    }
  }

export const CorporateRequestforChange =
  (corporateId, payLoad, callBack) => async dispatch => {
    try {
      let response = await authRequest.post(
        `/users/requestForChange/${corporateId} `,
        payLoad
      )
      function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }

   
        let payloadData = {
          query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
        };
      await elasticSearchSyncRequest.post(`/sync/corporate_list`,payloadData)
      callBack()
      SuccessMessage('Request Sent Successfully')
      return response?.data?.message === 'Success'
    } catch (error) {
      ErrorMessage(error?.response?.data?.message)
      return error
    }
  }
