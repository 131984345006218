import React from 'react'
import CustomTag from 'components/CustomTag'

const CorporateStatus = ({ status }) => {
  let colorType
  let tagMessage
  if (status == '0') {
    colorType = 'red'
    tagMessage = 'InActive'
  } else if (status == '1') {
    colorType = 'green'
    tagMessage = 'Active'
  } else if (status == '2') {
    colorType = 'orange'
    tagMessage = 'Expired'
  }
  return (
    <CustomTag colorType={colorType} message={tagMessage} bordered={false} />
  )
}

export default CorporateStatus
