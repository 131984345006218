import React from 'react'

import { Main } from 'components/ActionStyles/ActionStyles'
import { useDispatch } from 'react-redux'
import ErrorMessage from 'utils/ErrorMessage'
import { StyledButton } from '../../../../../components/ActionStyles/ActionStyles'
import { getSingleCorporateData } from '../../../actions'

const ActionDropdown = ({ rowData, setViewCorporateDrawer }) => {
  const dispatch = useDispatch()

  const callBackError = msg => {
    ErrorMessage(msg || 'No Corporate Found')
  }

  const onClickView = () => {
    dispatch(
      getSingleCorporateData(rowData?.id, callBackError, setViewCorporateDrawer)
    )
  }

  return (
    <Main>
      <StyledButton onClick={() => onClickView()}>View</StyledButton>
    </Main>
  )
}

export default ActionDropdown
