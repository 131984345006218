import { Divider, Tooltip } from 'antd'
import Button from 'components/Button/index'
import SearchInput from 'components/SearchInputReport'
import { Wrapper } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import ReportSearchFilter from 'components/UIComponents/ReportSearchFilter'
import {
  Container,
  Content,
  FilterTextNew,
  Footer,
  MarginLeft,
} from 'components/UIComponents/ReportSearchFilter/Style/style'
import CloseFilter from 'components/icons/CloseFilter'
import { arrayIntoString } from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setStudentCourseWiseDetails } from '../../../actions'
import CityFilter from '../../Filter/SingleFilter'
import DegreeAndDepartmentFilter from '../../Filter/TreeFilter'
const ListOfStudentCourseFilter = ({
  searchValue,
  setSearchValue,
  tableData,
  courseValue,
  pageNum,
  collegeValue,
  specialisationValue,
  setFilterData,
  nameValue,
  setSpecialisationValue,
  tierValue,
  setTierValue,
  setKeyTier,
  keyTier,
  universityValue,
  setUniversityValue,
  setKeyUniversity,
  keyUniversity,
  cityValue,
  setCityValue,
  skillValue,
  tooltip,
  TierUrl,
  UniversityUrl,
  SpecialisationUrl,
  tierData,
  universityData,
  specialisationData,
  tierLoader,
  universityLoader,
  cityLoader,
  setUniversityLoader,
  setTierLoader,
  setCityLoader,
  CityUrl,
  cityData,
  degreeListData,
  setDegreeList,
  degreeList,
  setSearchDegree,
  degreeLoader,
  searchQuery,
  setSearchQuery,
  page,
  count,
  loadMoreData,
  loadingDep,
  specialisationLoader,
  setSpecialisationLoader,
}) => {
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(true)
  const [clearFlag, setClearFlag] = useState(false)
  const [special, setSpecial] = useState('')
  const [searchCity, setSearchCity] = useState('')
  const [filterCancel, setFilterCancel] = useState(false)
  const submitFilter = () => {
    setIsMenuOpen(false)
    let college = arrayIntoString(collegeValue)
    let course = arrayIntoString(courseValue)
    let specialisation = arrayIntoString(specialisationValue)
    let degree = arrayIntoString(degreeList)
    let corporate = arrayIntoString(nameValue)
    let skill = arrayIntoString(skillValue)
    let tier = arrayIntoString(tierValue)
    let university = arrayIntoString(universityValue)
    let city = arrayIntoString(cityValue)
    setIsMenuOpen(false)
    setFilterData({
      college: college,
      course: course,
      specialisation: specialisation,
      degree: degree,
      corporate: corporate,
      skill: skill,
      tier: tier,
      university: university.replace(',', '$'),
      city: city,
    })
  }

  const onCancelFilter = open => {
    setClearFlag(true)
    setIsMenuOpen(!isMenuOpen)
    setCityValue([])
    setTierValue([])
    setUniversityValue([])
    setSpecialisationValue([])
    setDegreeList([])
    dispatch(setStudentCourseWiseDetails([]))
    setKeyTier('')
    setSearch('')
    setSpecial('')
    setKeyUniversity('')
    setSearchValue('')
    setSearchCity('')
    setSearchQuery('')
    setFilterCancel(true)
  }
  useEffect(() => {
    dispatch(setStudentCourseWiseDetails([]))
  }, [])

  const onDegreeFilterChange = data => {
    setDegreeList(data)
  }
  return (
    <Wrapper>
      <ReportSearchFilter
        setOpen={setOpen}
        open={open}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isReport={true}
        totalCount={tableData?.count}
        currentCount={10 * (pageNum - 1) + tableData?.result?.length}
      />
      <Divider style={{ margin: '0' }} />
      <Content isOpen={open}>
        <div style={{ width: '100%', display: 'flex', height: '18rem' }}>
          <Wrapper style={{ marginLeft: '1rem' }}>
            <FilterTextNew>{'Degree & Department'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={searchQuery}
              setSearchValue={value => {
                setSearchQuery(value)
              }}
            />
            <Container>
              <DegreeAndDepartmentFilter
                degreeList={degreeListData}
                setDegreeList={onDegreeFilterChange}
                searchQuery={searchQuery}
                degree={degreeList}
                setSearchDegree={setSearchDegree}
                loading={degreeLoader}
                filterCancel={filterCancel}
                setFilterCancel={setFilterCancel}
                degreeLoader={loadingDep}
                page={page}
                count={count}
                loadMoreData={loadMoreData}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />

          <Wrapper>
            <FilterTextNew>{'Specialisation'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={special}
              setSearchValue={value => {
                setSpecial(value)
              }}
            />
            <Container>
              <CityFilter
                value={specialisationValue}
                setValue={setSpecialisationValue}
                search={special}
                setSearch={setSpecial}
                cityData={universityData}
                cityDetails={UniversityUrl}
                setLoading={setSpecialisationLoader}
                loading={specialisationLoader}
                valueParam={'specialisation'}
                labelParam={'specialisation'}
                flag={'SCW'}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper>
            <FilterTextNew>{'University'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={keyUniversity}
              setSearchValue={value => {
                setKeyUniversity(value)
              }}
            />
            <Container>
              <CityFilter
                search={keyUniversity}
                setSearch={setKeyUniversity}
                value={universityValue}
                setValue={setUniversityValue}
                cityData={specialisationData}
                cityDetails={SpecialisationUrl}
                setLoading={setUniversityLoader}
                loading={universityLoader}
                valueParam={'university'}
                labelParam={'university'}
                flag={'SCW'}
              />
            </Container>
          </Wrapper>

          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper>
            <FilterTextNew>{'Tier'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={keyTier}
              setSearchValue={value => {
                setKeyTier(value)
              }}
            />
            <Container>
              <CityFilter
                value={tierValue}
                setValue={setTierValue}
                search={keyTier}
                setSearch={setKeyTier}
                cityData={tierData}
                cityDetails={TierUrl}
                setLoading={setTierLoader}
                loading={tierLoader}
                valueParam={'tier'}
                labelParam={'tier'}
                flag={'SCW'}
              />
            </Container>
          </Wrapper>
          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
          <Wrapper>
            <FilterTextNew>{'Location'}</FilterTextNew>
            <SearchInput
              clearFlag={clearFlag}
              setClearFlag={setClearFlag}
              width={'90%'}
              placeholder="Search by keyword"
              bordered={true}
              searchValue={searchCity}
              setSearchValue={value => {
                setSearchCity(value)
              }}
            />
            <Container>
              <CityFilter
                search={searchCity}
                setSearch={setSearchCity}
                value={cityValue}
                setValue={setCityValue}
                cityData={cityData}
                cityDetails={CityUrl}
                setLoading={setCityLoader}
                loading={cityLoader}
                valueParam={'corr_city'}
                labelParam={'corr_city'}
                flag={'SCW'}
                clearFlag={clearFlag}
                setClearFlag={setClearFlag}
              />
            </Container>
          </Wrapper>

          <Divider
            type="vertical"
            style={{
              height: '100%',
              width: '2px',
              borderTop: '18rem solid #e8e8e8',
            }}
          />
        </div>
      </Content>
      <Divider style={{ margin: '0' }} />
      <Footer style={{ display: open ? '' : 'none' }}>
        <div
          style={{ marginRight: '180px' }}
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpen(false)
          }}
          onKeyDown={() => {}}
        >
          <CloseFilter style={{ marginBottom: '-20px', cursor: 'pointer' }} />
        </div>
        <div style={{ marginRight: '30px' }}>
          <Button.Secondary
            height="35px"
            htmlType="Clear"
            text={'Clear Filter'}
            width={'144px'}
            onClick={() => onCancelFilter()}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <MarginLeft>
            <Tooltip
              placement="topLeft"
              title={
                !tooltip
                  ? 'Kindly Select Filter Option Before Submitting the Data'
                  : ''
              }
            >
              <Button.Primary
                disabled={!tooltip}
                height="35px"
                htmlType="Save Filter"
                text={'Apply'}
                width={'144px'}
                onClick={() => submitFilter()}
              />
            </Tooltip>
          </MarginLeft>
        </div>
      </Footer>
      <Divider style={{ margin: '0' }} />
    </Wrapper>
  )
}

export default ListOfStudentCourseFilter
