import React from 'react'

const EmptyCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#C4C4C4" />
      </mask>
      <path
        d="M7.9018 2.32796L7.90201 2.32787C9.18395 1.77506 10.5516 1.5 12 1.5C13.4484 1.5 14.816 1.77506 16.098 2.32787L16.0982 2.32796C17.3704 2.87728 18.4821 3.62496 19.4286 4.57145C20.3751 5.51799 21.1225 6.6297 21.6711 7.90201C22.2246 9.18394 22.5 10.5516 22.5 12C22.5 13.4484 22.2246 14.8161 21.6711 16.098C21.1225 17.3703 20.3751 18.482 19.4286 19.4286C18.482 20.3751 17.3703 21.1225 16.098 21.6711C14.8161 22.2246 13.4484 22.5 12 22.5C10.5516 22.5 9.18394 22.2246 7.90201 21.6711C6.6297 21.1225 5.51799 20.3751 4.57145 19.4286C3.62491 18.482 2.87753 17.3703 2.32887 16.098C1.77539 14.8161 1.5 13.4484 1.5 12C1.5 10.5516 1.77539 9.18394 2.32887 7.90201L7.9018 2.32796ZM7.9018 2.32796C6.62958 2.87728 5.51794 3.62496 4.57145 4.57145C3.62496 5.51793 2.87762 6.62958 2.32896 7.9018L7.9018 2.32796Z"
        fill="#F9FAFE"
        stroke="#D6DBF7"
      />
    </svg>
  )
}

export default EmptyCircle
