const initialState = {
  coursesList: [],
  skillList: [],
  degreeList: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COURSES_LIST':
      return {
        ...state,
        coursesList: action.data,
      }
    case 'SET_LIST_OF_SKILL':
      return {
        ...state,
        skillList: action?.list,
      }
    case 'SET_STUDENTS_DEGREE_LIST':
        return {
          ...state,
          degreeList: action.data,
        }  
    default:
      return state
  }
}
