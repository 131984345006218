import { Form } from 'antd'
import Button from 'components/Button/index'
import InfoCards from 'components/InfoCards'
import PlusIcon from 'components/icons/PlusIcon'
import React, { useEffect, useMemo, useState } from 'react'
import AddRoleDrawer from './Partials/AddRoleDrawer'
import AddUserDrawer from './Partials/AddUserDrawer'
import RolesTable from './Partials/RoleTable'
import RolesFilter from './Partials/RolesFilter'
import UsersFilter from './Partials/UsersFilter'
import UserTabs from './Partials/UsersTab'
import UsersTable from './Partials/UsersTable'
import { getCardData } from './Partials/constant'
import {
  CardRow,
  PaddedDiv,
  SectionWrapper,
  TopHeader,
  TopHeading,
} from './Style/style'

const tabs = { user: 'Users', role: 'Roles and Permission' }

const Users = ({
  getUserMetrics,
  getUserList,
  userMetrics,
  userList,
  roleList,
  roles,
  getEvent,
  getRoles,
  eventList,
  getRoleList = 'CORPORATE',
  getUserRoles,
  userRole,
  roleFilter,
  createUser,
  getSingleRole,
  singleRoleData,
  createRoles,
  updateRoles,
  updateUser,
  getSingleUser,
  singleUserData,
  updateUserStatus,
  deleteUser,
  setSingleUser,
  setSingleRole,
  getListOfCountries,
  deleteRolesAndPermission,
}) => {
  const [form] = Form.useForm()
  const [userData, setUserData] = useState([])
  const [state, setState] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const [usersId] = useState('')
  const [addUserDrawer, setAddUserDrawer] = useState(false)
  const [addRoleDrawer, setAddRoleDrawer] = useState(false)
  const [pageNum, setPageNum] = useState(0)
  const [page, setPage] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [loading, setLoading] = useState(false)
  const [userRoleValue, setUserRoleValue] = useState('')
  const [roleJourney, setRoleJourney] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [userId, setUserId] = useState('')
  const [roleId, setRoleId] = useState('')
  const [journey, setJourney] = useState('Internal')
  const [updateLoading, setUpdateLoading] = useState(false)
  const [subEventsId, setSubEventsId] = useState([])
  const [eventsId, setEventsId] = useState([])
  const [currentSingleData, setCurrentSingleData] = useState()
  const [view, setView] = useState(false)
  const [formattedInternalData, setFormattedInternalData] = useState([])
  const [formattedCorporateData, setFormattedCorporateData] = useState([])
  const [formattedInstituteData, setFormattedInstituteData] = useState([])
  const [activerRoleList, setActiverRoleList] = useState([])
  const [selectionType, setSelectionType] = useState('user')
  const [internalEventsList, setInternalEventsList] = useState([])
  const [corporateEventsList, setCorporateEventsList] = useState([])
  const [instituteEventsList, setInstituteEventsList] = useState([])
  const [filterData, setFilterData] = useState({})

  const onCardClick = (key, state) => {
    setFilterData({})
    let currentState = ''
    if (state === 'active') {
      currentState = '0'
    } else if (state === 'inactive') {
      currentState = '1'
    }
    setState(key)
    setUserStatus(currentState)
    setUserRoleValue('')
    setRoleJourney('')
    setPageNum(0)
  }

  const getUserData = async () => {
    setLoading(true)
    await getUserMetrics({
      searchValue,
      sortBy,
      userRoleValue,
      userStatus,
      pageNum,
    })
    clearLoading()
  }
  const clearLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }
  const getUserDataWithoutPage = async () => {
    let pageNum = 0
    setLoading(true)
    await getUserMetrics({
      searchValue,
      sortBy,
      userRoleValue,
      userStatus,
      pageNum,
    })
    clearLoading()
  }
  const getRolesData = async () => {
    setLoading(true)
    await getRoleList({
      searchValue,
      sortBy,
      userRoleValue,
      userStatus,
      pageNum: page,
      roleJourney,
    })
    clearLoading()
  }
  const getRolesDataWithoutPagination = async () => {
    setLoading(true)
    await getRoleList({
      searchValue,
      sortBy,
      userRoleValue,
      userStatus,
      pageNum: 0,
      roleJourney,
    })
    clearLoading()
  }
  const getSingleUserInfo = async userId => {
    setUpdateLoading(true)
    await getSingleUser({ userId })
    setUpdateLoading(false)
  }
  const getSingleRoleInfo = async roleId => {
    setUpdateLoading(true)
    let roleresponse = await getSingleRole({ roleId })
    setCurrentSingleData(roleresponse)

    setUpdateLoading(false)
  }

  const onAddUser = () => {
    form.resetFields()
    setAddUserDrawer(true)
    setSingleUser({})
  }
  const fetchAllEventsList = async () => {
    const internalEvents = await getEvent('INTERNAL')
    setInternalEventsList(internalEvents)
    const corporateEvents = await getEvent('CORPORATE')
    setCorporateEventsList(corporateEvents)

    const instituteEvents = await getEvent('INSTITUTE')
    setInstituteEventsList(instituteEvents)
  }

  const onAddRole = () => {
    form.resetFields()
    setAddRoleDrawer(true)
    fetchAllEventsList()
    setFormattedInternalData([])
    setFormattedCorporateData([])
    setFormattedInstituteData([])
    setSingleRole({})
  }
  const onEditRole = () => {
    setAddRoleDrawer(true)
    fetchAllEventsList()
  }
  const getFiltersRoleList = async () => {
    const roleData = await getRoles('Internal', true, true)
    setActiverRoleList(roleData?.data?.data)
  }

  const onPageChange = key => {
    if (selectionType === 'user') {
      setPageNum(key - 1)
    } else {
      setPage(key - 1)
    }
  }

  const changeState = async type => {
    setFilterData({})
    setUserRoleValue('')
    setRoleJourney('')
    setUserStatus('')
    setPageNum(0)

    setSelectionType(type)
    if (type === 'user') {
      const roleData = await getRoles('Internal', true, true)
      setActiverRoleList(roleData?.data?.data)
      getRoles('Internal')
      setPage(0)
    } else {
      setPageNum(0)
      setSearchValue('')
    }
  }

  useEffect(() => {
    getUserList(searchValue, sortBy, userRoleValue, userStatus, pageNum)
    getFiltersRoleList()
  }, [!userList])

  useEffect(() => {
    getRoleList(searchValue, sortBy, roleJourney, userStatus, page)
  }, [!userRole])

  //User & Role Data Fetching
  useEffect(() => {
    setLoading(true)
    if (selectionType === 'user') {
      getUserData()
      getFiltersRoleList()
    } else {
      getRolesData()
    }
  }, [pageNum, page])

  //User & Role Data Fetching
  useEffect(() => {
    if (selectionType === 'user') {
      getUserDataWithoutPage()
    } else {
      getRolesDataWithoutPagination()
    }
  }, [
    selectionType,
    searchValue,
    sortBy,
    userStatus,
    userRoleValue,
    state,
    roleJourney,
  ])

  useEffect(() => {
    if (userMetrics && Object.keys(userMetrics).length > 0) {
      let response = getCardData(userMetrics)
      setUserData(response)
    }
  }, [userMetrics])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageNum, searchValue, sortBy, page])

  useMemo(() => getRoles('INTERNAL'), [])

  useEffect(() => {
    getEvent(singleRoleData?.permissions?.journey?.toUpperCase())
  }, [singleRoleData])

  return (
    <SectionWrapper column>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>{'User Management'}</TopHeading>
        </TopHeader>

        <UserTabs
          selected={selectionType}
          selectedType={type => {
            changeState(type)
          }}
        />

        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>{tabs[`${selectionType}`]}</TopHeading>
          <Button.Primary
            height={'38px'}
            text={selectionType === 'user' ? 'New User' : 'New Role'}
            icon={<PlusIcon />}
            onClick={selectionType === 'user' ? onAddUser : onAddRole}
          />
        </TopHeader>
        {selectionType === 'user' && (
          <CardRow>
            {userData?.map(card => (
              <InfoCards
                key={card?.key}
                width={'25%'}
                height="130px"
                icon={card?.icon}
                text={card?.text}
                value={card?.value}
                state={card?.key}
                current={state}
                onClick={() => onCardClick(card?.key, card?.state)}
              />
            ))}
          </CardRow>
        )}
      </PaddedDiv>
      {selectionType === 'user' ? (
        <>
          <UsersFilter
            searchValue={searchValue}
            currentPage={currentPage}
            setSearchValue={setSearchValue}
            userRole={activerRoleList || []}
            userRoleValue={userRoleValue}
            setUserRoleValue={setUserRoleValue}
            userStatus={userStatus}
            setUserStatus={setUserStatus}
            getUserData={getUserData}
            setState={setState}
            userMetrics={userMetrics}
            setPageNum={setPageNum}
            pageNum={pageNum}
            setCurrentPage={setPageNum}
            filterData={filterData}
            setFilterData={setFilterData}
          />
          <UsersTable
            userMetrics={userMetrics}
            userRole={userRole}
            setSortBy={setSortBy}
            sortBy={sortBy}
            loading={loading}
            getSingleUserInfo={getSingleUserInfo}
            singleUserData={singleUserData}
            setAddUserDrawer={setAddUserDrawer}
            setUserId={setUserId}
            updateUserStatus={updateUserStatus}
            deleteUser={deleteUser}
            setPageNum={setPageNum}
            getUserData={getUserData}
            pageNum={pageNum + 1}
            onPageChange={onPageChange}
            updateLoading={updateLoading}
            usersId={usersId}
            getUserMetrics={getUserMetrics}
            getSingleUser={getSingleUser}
            updateUser={updateUser}
          />
          <AddUserDrawer
            roleList={roles}
            addUserDrawer={addUserDrawer}
            setAddUserDrawer={setAddUserDrawer}
            userRole={userRole}
            createUser={createUser}
            updateUser={updateUser}
            singleUserData={singleUserData}
            userId={userId}
            getUserData={getUserData}
            getFiltersRoleList={getFiltersRoleList}
            updateLoading={updateLoading}
            getListOfCountries={getListOfCountries}
            form={form}
            getUserMetrics={getUserMetrics}
            getSingleUser={getSingleUser}
          />
        </>
      ) : (
        <>
          <RolesFilter
            searchValue={searchValue}
            currentPage={page}
            setSearchValue={setSearchValue}
            roleFilter={roleFilter}
            roleJourney={roleJourney}
            setRoleJourney={setRoleJourney}
            userStatus={userStatus}
            setUserStatus={setUserStatus}
            getRolesData={getRolesData}
            setState={setState}
            roleList={roleList}
            setCurrentPage={setPage}
            filterData={filterData}
            setFilterData={setFilterData}
          />
          <RolesTable
            roleList={roleList}
            eventList={eventList}
            roleFilter={roleFilter}
            setSortBy={setSortBy}
            loading={loading}
            setLoading={setLoading}
            getSingleRoleInfo={getSingleRoleInfo}
            singleRoleData={singleRoleData}
            setAddRoleDrawer={onEditRole}
            setRoleId={setRoleId}
            updateUserStatus={updateUserStatus}
            deleteRolesAndPermission={deleteRolesAndPermission}
            setPageNum={setPage}
            getUserData={getUserData}
            pageNum={page + 1}
            onPageChange={onPageChange}
            updateLoading={updateLoading}
            getRolesData={getRolesData}
            getRoleList={getRoleList}
            setView={setView}
          />
          {!loading && !updateLoading && (
            <AddRoleDrawer
              setView={setView}
              view={view}
              addRoleDrawer={addRoleDrawer}
              setSubEventsId={setSubEventsId}
              setEventsId={setEventsId}
              eventsId={eventsId}
              subEventsId={subEventsId}
              setAddRoleDrawer={setAddRoleDrawer}
              roleFilter={roleFilter}
              eventList={eventList}
              createRoles={createRoles}
              updateRoles={updateRoles}
              updateUser={updateUser}
              setCurrentSingleData={setCurrentSingleData}
              singleUserData={currentSingleData}
              roleId={roleId}
              getRoleList={getRolesData}
              updateLoading={updateLoading}
              form={form}
              roleJourney={roleJourney}
              setRoleJourney={setRoleJourney}
              setFormattedInternalData={setFormattedInternalData}
              formattedInternalData={formattedInternalData}
              setFormattedCorporateData={setFormattedCorporateData}
              formattedCorporateData={formattedCorporateData}
              setFormattedInstituteData={setFormattedInstituteData}
              formattedInstituteData={formattedInstituteData}
              singleRoleData={singleRoleData?.permissions}
              setJourney={setJourney}
              journey={journey}
              getEvent={getEvent}
              internalEventsList={internalEventsList}
              corporateEventsList={corporateEventsList}
              instituteEventsList={instituteEventsList}
            />
          )}
        </>
      )}
    </SectionWrapper>
  )
}

export default Users
