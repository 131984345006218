import React, { useState, useEffect } from 'react'

import { Container, Left, Right } from 'components/UIComponents/Toggle/style'
import { COLORS } from 'theme'

const Toggle = (
  { left, right,top,bottom, width, onClick, initialValue = 'CORPORATE', disabled = false },
  props
) => {
  const [current, setCurrent] = useState()

  useEffect(() => {
    setCurrent(initialValue)
  }, [initialValue])

  const handleClick = curr => {
    if (curr === 'CORPORATE') {
      setCurrent('CORPORATE')
      onClick('CORPORATE')
    } else if (curr === 'INSTITUTE') {
      setCurrent('INSTITUTE')
      onClick('INSTITUTE')
    } else if (curr === 'INTERNAL') {
      setCurrent('INTERNAL')
      onClick('INTERNAL')
    } else if (curr === 'STUDENT') {
      setCurrent('STUDENT')
      onClick('STUDENT')
    }
  }
  return (
    <Container disabled={disabled}>
      <Left
        color={current == 'CORPORATE' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'CORPORATE' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('CORPORATE')}
        disabled={disabled}
      >
        Corporates
      </Left>
      <Right
        color={current == 'INSTITUTE' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'INSTITUTE' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('INSTITUTE')}
        disabled={disabled}
      >
       Institute
      </Right>
      {/* <Right
        color={current == 'INTERNAL' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'INTERNAL' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('INTERNAL')}
        disabled={true}
      >
        Admin
      </Right> */}
      <Right
        color={current == 'STUDENT' ? COLORS.PRIMARY : COLORS.GREY_T_80}
        background={current == 'STUDENT' ? COLORS.BLUE_T_90 : COLORS.WHITE}
        onClick={() => handleClick('STUDENT')}
        disabled={disabled}
      >
        Student
      </Right>
    </Container>
  )
}

export default Toggle
