import styled from 'styled-components'
import Flex from 'components/Flex'
import Drawer from 'components/Drawer'
import { Form as AntdForm } from 'antd'

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const FooterFlex = styled(Flex)`
  gap: 20px;
`
export const FormItem = styled(AntdForm.Item)`
  margin: 1rem !important;

  .ant-form-item-control-input {
    min-height: 0px;
  }
`
export const Form = styled(AntdForm)`
  padding-left: 1rem;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  margin: 0.6rem !important;
`
export const CommonForm = styled(AntdForm)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
`
//If we want the content inside the academic view to scroll
export const InnerContextScrollForAcademic = styled.div`
  /* max-width: 100%;
  overflow-y: scroll;
  height: 18rem; */
`
export const PaddingContextScrollForAcademic = styled.div`
  padding-bottom: 1rem;
`
