import ExportButton from 'components/ExportButton/ExportButton.js'
import {
  arrayIntoString,
  filterData,
} from 'modules/Reports/Components/CommonFunction/CommonFunctionIndex'
import StudentCourseWiseTable from 'modules/Reports/Partials/Students/Partials/StudentCourseWise/StudentCourseTable/index.js'
import ListOfStudentCourseFilter from 'modules/Reports/Partials/Students/Partials/StudentCourseWise/StudentFilter/index.js'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import WarningMessage from 'utils/WarningMessage'
import { setStudentCourseWiseDetails } from '../../actions'
import { degreeListConvertionPart } from '../Function/Function'
import { PaddedDiv, SectionWrapper, TopHeader, TopHeading } from './Style/style'
const StudentsCourseWiseTable = ({
  getStudentCourseWiseDetails,
  studentListDataForReport,
  collegeList,
  specialisationList,
  courseList,
  getUniversityList,
  tierList,
  universityList,
  cityList,
  skillList,
  excelDataForReport,
  TierUrl,
  UniversityUrl,
  CollegeUrl,
  DegreeUrl,
  SpecialisationUrl,
  tierData,
  universityData,
  collegeData,
  degreeData,
  specialisationData,
  CityUrl,
  cityData,
  userData,
}) => {
  const dispatch = useDispatch()
  const [sortBy, setSortBy] = useState('')
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loadingDep, setLoadingDep] = useState(false)
  const [degreeList, setDegreeList] = useState([])
  const [degreeListData, setDegreeListData] = useState([])
  const [degreeLoader, setDegreeLoader] = useState(false)
  const [tierLoader, setTierLoader] = useState(false)
  const [universityLoader, setUniversityLoader] = useState(false)
  const [specialisationLoader, setSpecialisationLoader] = useState(false)
  const [cityLoader, setCityLoader] = useState(false)
  const [collegeLoader, setCollegeLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tierLoading, setTierLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [filterDatas, setFilterData] = useState({})
  const [keyCourse, setKeyCourse] = useState('')
  const [keySpecialisation, setKeySpecialisation] = useState('')
  const [keyDegree, setKeyDegree] = useState('')
  const [keyCollege, setKeyCollege] = useState('')
  const [courseValue, setCourseValue] = useState([])
  const [specialisationValue, setSpecialisationValue] = useState([])
  const [degreeValue, setDegreeValue] = useState([])
  const [collegeValue, setCollegeValue] = useState([])
  const [tierValue, setTierValue] = useState([])
  const [keyTier, setKeyTier] = useState('')
  const [universityValue, setUniversityValue] = useState([])
  const [keyUniversity, setKeyUniversity] = useState('')
  const [skillValue, setSkillValue] = useState([])
  const [keySkill, setKeySkill] = useState('')
  const [cityValue, setCityValue] = useState([])
  const [keyCity, setKeyCity] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [debouncedDegree, setDebouncedDegree] = useState(() => keyDegree)
  const [tooltip, setToolTip] = useState(false)
  const [searchDegree, setSearchDegree] = useState('')
  function hasData(obj) {
    for (let key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
        return true
      }
    }
    return false
  }
  function toCamelCase(str) {
    const words = str.split(' ')
    const camelCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    return camelCaseWords.join(' ')
  }
  useEffect(() => {
    const timeout = setTimeout(() => {}, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDegree(keyDegree)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [keyDegree])
  useEffect(() => {
    if (hasData(filterDatas)) {
      if (pageNum) {
        if (hasData(filterDatas)) {
          getStudentCourseWiseDetails({
            pageSize: 10,
            pageNumber: pageNum - 1,
            setLoading,
            searchValue,
            filterDatas,
            sortBy,
          })
        }
      }
    }
  }, [pageNum])

  useEffect(() => {
    if (hasData(filterDatas)) {
      getStudentCourseWiseDetails({
        pageSize: 10,
        pageNumber: 0,
        setLoading,
        searchValue,
        filterDatas,
        sortBy,
      })
      setPageNum(1)
    }
  }, [searchValue, filterDatas, sortBy])
  useEffect(() => {
    const modifiedNames = universityValue.map(name => name?.replace(',', '$'))
    let tier = arrayIntoString(tierValue)
    let college = arrayIntoString(collegeValue)
    let course = arrayIntoString(courseValue)
    let specialisation = arrayIntoString(specialisationValue)
    let degree = arrayIntoString(degreeList)
    let city = arrayIntoString(cityValue)
    let skill = arrayIntoString(skillValue)
    let university = arrayIntoString(modifiedNames)
    let filterDatas = {
      tier: tier,
      college: college,
      course: course,
      specialisation: specialisation,
      degree: degree,
      city: city,
      skill: skill,
      university: university,
    }
    setToolTip(hasData(filterDatas))
  }, [
    collegeValue,
    courseValue,
    specialisationValue,
    degreeList,
    tierValue,
    universityValue,
    cityValue,
  ])
  useEffect(() => {
    dispatch(setStudentCourseWiseDetails([]))
  }, [])

  const title = [
    { title: 'ID' },
    {
      title: 'College Name',
    },
    { title: 'University', width: { wch: 40 }, padding: { ppx: 100 } },
    { title: 'Tier', width: { wch: 30 } },
    { title: 'Deg-Dept  (Spec.)', width: { wch: 30 } },
    { title: 'No. of Stud. Placed', width: { wch: 20 } },
    { title: 'Location', width: { wch: 20 } },
  ]

  const tableData = excelDataForReport?.result?.map(item => [
    { value: item.prefix_id ? item.prefix_id : '-' },
    {
      value: item.campus_name ? item.campus_name : '-',
    },
    { value: item.university ? item.university : '-' },
    { value: item.tier?.toString() ? item.tier : '-' },
    {
      value:
        item.department && item.specialisation && item.degree
          ? item.short_form +
            '-' +
            toCamelCase(item.department) +
            ' (' +
            toCamelCase(item.specialisation) +
            ')'
          : item.degree && item.department && !item.specialisation
          ? item.short_form + '-' + toCamelCase(item.department)
          : '-',
    },

    { value: item.studentCount ? item.studentCount : '-' },
    { value: item.corr_city ? item.corr_city : '-' },
  ])
  const excelData = [{ columns: title, data: tableData }]
  const [isExcel, setIsExcel] = useState('')
  const [excel, setExcel] = useState([])
  const clickedExcel = data => {
    let excel = true
    getStudentCourseWiseDetails({
      pageSize: 5000,
      pageNumber: 0,
      setLoading,
      searchValue,
      filterDatas,
      sortBy,
      excel,
    })
    setIsExcel(data)
  }
  useEffect(() => {
    if (
      (excelDataForReport?.result && tableData && isExcel == 'excel') ||
      (excelDataForReport?.result && tableData && isExcel == 'pdf')
    ) {
      if (loading) {
        setExcel([])
      } else {
        setExcel(excelData)
      }
    }
  }, [excelDataForReport?.result, loading])
  const handleHover = () => {
    if (studentListDataForReport?.count > 5000) {
      WarningMessage(
        'Note : The student count should not exceed 5,000 for data export.  Apply filters if necessary'
      )
    }
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDegree(searchDegree)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchDegree])

  const handleSearch = e => {
    const query = e.target.value
    setSearchQuery(query)
  }
  useEffect(() => {
    const updatedData = degreeListConvertionPart({
      listOfDegree: degreeData?.result,
      selectedIds: [],
      isEditActiveRole: [],
    })
    setCount(degreeData?.count)
    setPage(degreeData?.page)
    setDegreeListData(updatedData)
  }, [degreeData])
  useEffect(() => {
    let flag = 'LSP'
    DegreeUrl(flag, setLoadingDep, searchDegree, 0, setDegreeLoader)
  }, [debouncedDegree])
  const loadMoreData = async () => {
    let flag = 'SCW'
    DegreeUrl(
      flag,
      setLoadingDep,
      searchDegree,
      degreeData?.page + 1,
      setDegreeLoader
    )
    setPage(page + 1)
  }
  const columnWidths = [17, 55, 60, 15, 60, 45, 35, 25, 25] // Array of column widths
  const HAlign = [
    'center',
    'left',
    'left',
    'center',
    'left',
    'center',
    'left',
    'center',
    'left',
  ]
  return (
    <SectionWrapper column>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <TopHeading flexStart>Stud. Placed-Course-Wise</TopHeading>
          <TopHeading flexEnd style={{ display: 'flex', gap: 10 }}>
            <div role="button" tabIndex="0" onMouseEnter={handleHover}>
              <ExportButton
                title={'Stud Placed-Course-Wise'}
                dataset={excel}
                loading={loading}
                clickedExcel={clickedExcel}
                data={isExcel}
                setIsExcel={setIsExcel}
                count={studentListDataForReport?.count || 0}
                userData={userData}
                columnWidth={columnWidths} // Array of column widths
                columnFontSize={10} // Font size for the column data
                horizontalAlignment={HAlign}
              />
            </div>
          </TopHeading>
        </TopHeader>
      </PaddedDiv>
      <ListOfStudentCourseFilter
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        degreeList={degreeList}
        setSearchDegree={setSearchDegree}
        degreeListData={degreeListData}
        degreeLoader={degreeLoader}
        setDegreeLoader={setDegreeLoader}
        cityData={cityData}
        tooltip={tooltip}
        CityUrl={CityUrl}
        setDegreeList={setDegreeList}
        setTierLoader={setTierLoader}
        setUniversityLoader={setUniversityLoader}
        setCollegeLoader={setCollegeLoader}
        setSpecialisationLoader={setSpecialisationLoader}
        setCityLoader={setCityLoader}
        cityLoader={cityLoader}
        tierLoader={tierLoader}
        universityLoader={universityLoader}
        specialisationLoader={specialisationLoader}
        collegeLoader={collegeLoader}
        tierData={tierData}
        universityData={universityData}
        collegeData={collegeData}
        degreeData={degreeData}
        specialisationData={specialisationData}
        TierUrl={TierUrl}
        UniversityUrl={UniversityUrl}
        CollegeUrl={CollegeUrl}
        DegreeUrl={DegreeUrl}
        SpecialisationUrl={SpecialisationUrl}
        loading={loading}
        setLoading={setLoading}
        setTierLoading={setTierLoading}
        tierLoading={tierLoading}
        tableData={studentListDataForReport}
        setPageNum={setPageNum}
        pageNum={pageNum}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        collegeList={filterData(collegeList?.result, 'campus_name')}
        courseList={filterData(courseList?.result, 'name')}
        specialisationList={filterData(specialisationList?.result, 'name')}
        degreeValue={degreeValue}
        courseValue={courseValue}
        specialisationValue={specialisationValue}
        collegeValue={collegeValue}
        keyCollege={keyCollege}
        keyCourse={keyCourse}
        keySpecialisation={keySpecialisation}
        keyDegree={keyDegree}
        setDegreeValue={setDegreeValue}
        setCollegeValue={setCollegeValue}
        setCourseValue={setCourseValue}
        setSpecialisationValue={setSpecialisationValue}
        setKeyCollege={setKeyCollege}
        setKeyCourse={setKeyCourse}
        setKeyDegree={setKeyDegree}
        setKeySpecialisation={setKeySpecialisation}
        isList={false}
        filterData={filterDatas}
        setFilterData={setFilterData}
        tierValue={tierValue}
        setTierValue={setTierValue}
        keyTier={keyTier}
        setKeyTier={setKeyTier}
        keyCity={keyCity}
        setKeyCity={setKeyCity}
        keySkill={keySkill}
        setKeySkill={setKeySkill}
        cityValue={cityValue}
        setCityValue={setCityValue}
        universityValue={universityValue}
        setUniversityValue={setUniversityValue}
        skillValue={skillValue}
        setSkillValue={setSkillValue}
        keyUniversity={keyUniversity}
        setKeyUniversity={setKeyUniversity}
        tierList={filterData(tierList?.result, 'tier')}
        universityList={filterData(universityList?.result, 'university')}
        cityList={filterData(cityList?.result, 'city')}
        skillList={filterData(skillList?.result, 'name')}
        getUniversityList={getUniversityList}
        page={page}
        count={count}
        setLoadingDep={setLoadingDep}
        loadMoreData={loadMoreData}
        loadingDep={loadingDep}
      />

      <StudentCourseWiseTable
        hasProp={hasData(filterDatas)}
        setSortBy={setSortBy}
        tableData={studentListDataForReport}
        loading={loading}
        isEmpanelled={false}
        setPageNum={setPageNum}
        pageNum={pageNum}
      />
    </SectionWrapper>
  )
}
export default StudentsCourseWiseTable
