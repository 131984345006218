import { Col, Form, Row, Skeleton } from 'antd'
import AntdAvatar from 'components/Avatar'
import RoutePath from 'components/RoutePath'
import LeftArrow from 'components/icons/LeftArrow'
import CallIcon from 'components/icons/call.svg'
import MailIcon from 'components/icons/mail.svg'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { StyledDivider } from '../ViewUserRoleDrawer/Style/style'
import AddUserDrawer from './AddUserDrawer'
import Corporate from './Corporate'
import Institutes from './Institute'
import {
  BorderedDiv,
  DividerCode,
  LeftSection,
  PaddedDiv,
  RoleDiv,
  RoleDivider,
  RoleNameDiv,
  RoleNameFlex,
  RoleNames,
  RolesDiv,
  SectionName,
  StartDiv,
  StatusDiv,
  TextAlign,
  TopHeader,
  UserInfoBottom,
  UserInfoDiv,
  UserInfoHeading,
  UserInfoImgSpan,
  UserInfoSubDiv,
  UserInfoSubHeading,
  UserInfoSubSpan,
} from './Style/style'
import UserStatus from './UserStatus'

const active = {
  active: true,
}

const UserView = ({
  getListOfState,
  corporateViewData,
  FunctionTableList,
  getCorporateMasterData,
  getCorporateUniversityData,
  getCorporatesView,
  getInstitute,
  getCorporate,
  getInstituteView,
  deleteUser,
  instituteViewData,
  createCorporate,
  createInstitute,
  corporatejourneyData,
  institutejourneyData,
  userData,
  updateCorporate,
  getRoles,
  singleUserData,
  getSingleUser,
  getListOfCity,
  roles,
  updateUser,
  updateUsersRole,
  getSingleRole,
}) => {
  const [form] = Form.useForm()
  const [addUserDrawer, setAddUserDrawer] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState('')
  const [industry, setIndustry] = useState('')

  const [current, setCurrent] = useState('Corporates')
  const loc = useLocation()
  const onClick = target => {
    if (target) {
      setCurrent(target)
    }
  }

  const onClickEdit = rowData => {
    setAddUserDrawer(true)
  }
  const params = useParams()
  let userId = params.id
  useEffect(() => {
    if (!singleUserData?.userType) {
      getSingleUser({userId})
    }
   },[])
  return (
    <>
      <PaddedDiv column>
        <TopHeader row spaceBetween alignCenter>
          <LeftSection centerVertically>
            <Link to="/users">
              <LeftArrow />
            </Link>
            <SectionName column>
              <RoutePath
                from="User Management"
                on={'User Info'}
                path="/users"
              />
              <RoleNameFlex alignCenter>
                <RoleNameDiv>{'User Info'}</RoleNameDiv>
              </RoleNameFlex>
            </SectionName>
          </LeftSection>
        </TopHeader>
      </PaddedDiv>
      <PaddedDiv column>
        {singleUserData?.userType ? (
          <BorderedDiv>
            <StartDiv>
              <UserInfoDiv>
                <div>
                  <AntdAvatar
                    size={100}
                    IconName={singleUserData?.firstName?.charAt(0)}
                    font={18}
                  />
                </div>
                <UserInfoBottom>
                  <Row>
                    <UserInfoHeading>
                      {singleUserData?.firstName} {singleUserData?.lastName}
                    </UserInfoHeading>
                    <StatusDiv margin={'0 1px 3px 10px'}>
                      <UserStatus status={singleUserData?.status} />
                    </StatusDiv>
                  </Row>
                  <UserInfoSubHeading>
                    Role : &nbsp;
                    <UserInfoSubSpan>
                      {singleUserData?.userType}
                    </UserInfoSubSpan>{' '}
                  </UserInfoSubHeading>
                  <UserInfoSubDiv>
                    <>
                      <img src={MailIcon} alt='mail'/> &nbsp;
                      <UserInfoImgSpan>
                        {' '}
                        {singleUserData?.userEmail}
                      </UserInfoImgSpan>
                    </>
                    <DividerCode />
                    <>
                      <img src={CallIcon} alt='call' /> &nbsp;{' '}
                      <UserInfoImgSpan>
                        {' '}
                        +{singleUserData?.countryCode}-
                        {singleUserData?.userMobile}
                      </UserInfoImgSpan>
                    </>
                  </UserInfoSubDiv>
                </UserInfoBottom>
              </UserInfoDiv>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <TextAlign onClick={() => onClickEdit()}>{'Edit'}</TextAlign>
                </div>
              </div>
            </StartDiv>
          </BorderedDiv>
        ) : (
          <Skeleton avatar paragraph={{ rows: 2 }} active />
        )}
      </PaddedDiv>
      <Row justify={'center'}>
        <Col span={3}>
          {/* {singleUserData?.roles?.journeys?.includes('CORPORATE') && ( */}
            <RolesDiv
              active={current === 'Corporates'}
              onClick={e => onClick('Corporates')}
            >
              <RoleNames>{'Corporates'}</RoleNames>
            </RolesDiv>
          {/* )} */}
          {/* {singleUserData?.roles?.journeys?.includes('INSTITUTE') && ( */}
            <>
              <StyledDivider />
              <RolesDiv
                active={current === 'Insititute'}
                onClick={e => onClick('Insititute')}
              >
                <RoleNames>{'Insititute'}</RoleNames>
              </RolesDiv>
            </>
          {/* )} */}
        </Col>
        <Col span={0.5} style={{ marginLeft: '10px' }}>
          <RoleDivider />
        </Col>
        <Col span={20}>
          <RoleDiv>
            {current === 'Corporates'  && (
              <Corporate
                corporateViewData={corporateViewData}
                deleteUser={deleteUser}
                loc={loc}
                getCorporatesView={getCorporatesView}
                createCorporate={createCorporate}
                corporatejourneyData={corporatejourneyData}
                getListOfState={getListOfState}
                updateCorporate={updateCorporate}
                getCorporate={getCorporate}
                FunctionTableList={FunctionTableList}
                getCorporateMasterData={getCorporateMasterData}
                userData={userData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                sortBy={sortBy}
                setSortBy={setSortBy}
                location={location}
                industry={industry}
                setLocation={setLocation}
                setIndustry={setIndustry}
                loading={loading}
                setLoading={setLoading}
                getRoles={getRoles}
                getListOfCity={getListOfCity}
                getSingleUser={getSingleUser}
                roleList={roles}
                isCorpAccess={singleUserData?.roles?.journeys?.includes(
                  'CORPORATE'
                )}
                singleUserData={singleUserData}
                updateUser={updateUser}
                updateUsersRole={updateUsersRole}
                getSingleRole={getSingleRole}
              />
            )}
            {current === 'Insititute' && (
              <Institutes
                instituteViewData={instituteViewData}
                deleteUser={deleteUser}
                institutejourneyData={institutejourneyData}
                createCorporate={createCorporate}
                createInstitute={createInstitute}
                loc={loc}
                userData={userData}
                FunctionTableList={FunctionTableList}
                getCorporateUniversityData={getCorporateUniversityData}
                searchValue={searchValue}
                getListOfState={getListOfState}
                setSearchValue={setSearchValue}
                getInstitute={getInstitute}
                setSortBy={setSortBy}
                location={location}
                industry={industry}
                setLocation={setLocation}
                setIndustry={setIndustry}
                sortBy={sortBy}
                loading={loading}
                setLoading={setLoading}
                getRoles={getRoles}
                getListOfCity={getListOfCity}
                getInstituteView={getInstituteView}
                getSingleUser={getSingleUser}
                roleList={roles}
                isInstAccess={singleUserData?.roles?.journeys?.includes(
                  'INSTITUTE'
                )}
                singleUserData={singleUserData}
                updateUser={updateUser}
                updateUsersRole={updateUsersRole}
                getSingleRole={getSingleRole}
              />
            )}
          </RoleDiv>
        </Col>
        <Col span={0.5} style={{ marginLeft: '10px' }}>
          <RoleDivider />
        </Col>
      </Row>
      <AddUserDrawer
        roleList={roles}
        corporateViewData={corporateViewData}
        singleUserData={singleUserData}
        addUserDrawer={addUserDrawer}
        setAddUserDrawer={setAddUserDrawer}
        createCorporate={createCorporate}
        form={form}
        updateCorporate={updateCorporate}
        getSingleUser={getSingleUser}
      />
    </>
  )
}

export default UserView
