import axios from 'axios'
const { SEARCH_API_URL } = process.env
const { SEARCH_API_KEY } = process.env

const elasticSearchRequest = axios.create({
  baseURL: SEARCH_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'api-key': SEARCH_API_KEY,
  },
})

export default elasticSearchRequest
