import { Select } from 'antd'
import Label from 'components/Form/Label'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import CONSTANTS from 'utils/constants'

const StyledSelect = styled(Select)`
  width: 250px;
  height: 35px;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 47px !important;
    // background-color: #fafafb !important;
    border-radius: 8px !important;
    border-color: #e9e9e9 !important;
    box-shadow: none !important;
  }
  && {
    .ant-select-selector .ant-select-selection-item {
      flex: 1;
      overflow: hidden;
      // color: #bfbfbf;
      font-size: 14.5px;
      white-space: nowrap;
      margin-top: 5px;
      text-overflow: ellipsis;
    }
  }

  && {
    .ant-select-arrow {
      margin-top: 0 !important;
    }
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CourseCityStateDetails = ({
  cityList,
  stateList,
  onStateChange,
  onCityChange,
  form,
  countryId,
  stateId,
  getStateData,
  getCityData,
  placeholder,
  setPlaceholder,
  setCityPlaceholder,
  cityPlaceholder,
  loader,
  noContent,
  setLoader,
}) => {
  const delayStateToDb = useCallback(
    debounce((data1, data2, data3) => {
      setLoader(true)
      getStateData(data1, data2, data3)
    }, 500),
    []
  )

  const delayCitiesToDb = useCallback(
    debounce((data1, data2, data3, data4) => {
      setLoader(true)
      getCityData(data1, data2, data3, data4)
    }, 500),
    []
  )

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }

  return (
    <>
      <Container>
        <Label htmlFor="state">State *</Label>
        <StyledSelect
          allowClear={true}
          label="State *"
          optional="*"
          name="state"
          filterOption={false}
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('State'),
            },
          ]}
          listHeight={160}
          OptionData={stateList}
          valueParam="value"
          nameParam="name"
          onChange={onStateChange}
          onSearch={data => delayStateToDb(countryId, false, data)}
         
          value={form.getFieldValue('state')}
          showSearch={true}
          getPopupContainer={trigger => trigger.parentNode}
          placeholder={
            stateList.length === 0 ? 'No data found' : 'Select a State'
          }
          notFoundContent={noContent(loader)}
        >
          {stateList.length === 0 && (
            <Select.Option value="" disabled>
              {stateList.length && placeholder === 0
                ? 'No data found'
                : 'Select a State'}
            </Select.Option>
          )}
          {stateList.map(state => (
            <Select.Option key={state._id} value={state._id} name={state.name}>
              {state.name}
            </Select.Option>
          ))}
        </StyledSelect>
      </Container>
      <Container>
        <Label htmlFor="state">City *</Label>

        <StyledSelect
          allowClear={true}
          label="City *"
          optional="*"
          name="city"
          rules={[
            {
              required: true,
              message: CONSTANTS.ERROR_MESSAGE.REQ_SELECT('City'),
            },
          ]}
          listHeight={160}
          getPopupContainer={trigger => trigger.parentNode}
          valueParam="value"
          nameParam="name"
          onChange={onCityChange}
          showSearch={true}
          onSearch={data => delayCitiesToDb(countryId, stateId, true, data)}
          filterOption={false}
          loading={loader}
          notFoundContent={noContent(loader)}
          value={form.getFieldValue('city')}
          disabled={!form.getFieldValue('state')}
          placeholder={
            cityList.length === 0 ? 'No data found' : 'Select a City'
          }
        >
          {cityList?.length === 0 && (
            <Select.Option value="" disabled>
              {cityList?.length && cityPlaceholder === 0
                ? 'No data found'
                : 'Select a City'}
            </Select.Option>
          )}
          {cityList?.map(city => (
            <Select.Option key={city._id} value={city._id} name={city.name}>
              {city.name}
            </Select.Option>
          ))}
        </StyledSelect>
      </Container>
    </>
  )
}
export default CourseCityStateDetails
