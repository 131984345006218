import { Empty, Spin } from 'antd'
import Pagination from 'components/Pagination'
import ProgressCircle from 'components/ProgressCircle'
import {
  RowNumberData,
  Wrapper,
} from 'components/TableStyles/TableContentStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { EmptyWrapper } from 'modules/Dashboard/Styles/style'
import ChangeInstituteDetails from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/ChangeInstituteDetails'
import ActionDropdown from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/InstituteTable/ActionDropdown'
import RequestSentDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/RequestSentDrawer'
import VerifyInstituteDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/VerifyInstituteDrawer'
import ViewInstituteDrawer from 'modules/Onboarding/Partials/Institutes/Partials/InstituteInfoTable/ViewInstituteDrawer'
import React, { useState } from 'react'
import { toCamelCase } from '../../../../../../../utils/camelCase'
import { fixedSorterColumn } from '../../../../../../../utils/constants'
import InstituteStatus from '../InstituteStatus'

const InstituteTable = ({
  instituteList,
  getSingleInstituteData,
  setSortBy,
  loading,
  setCurrentPage,
  currentPage,
  updateLoading,
  getInstitutesListData,
  sortBy,
  getInstituteData,
  setClearInput,
}) => {
  const [viewInstituteDrawer, setViewInstituteDrawer] = useState(false)
  const [verifyInstituteDrawer, setVerifyInstituteDrawer] = useState(false)
  const [changeInstituteDetails, setChangeInstituteDetails] = useState(false)
  const [requestSentDrawer, setRequestSentDrawer] = useState(false)

  const onSorting = (pagination, row, sorter) => {
    let order = ''
    if (sorter.order === 'descend') {
      order = 'desc'
    } else if (sorter.order === 'ascend') {
      order = 'asc'
    }
    setSortBy(order)
    setCurrentPage(0)
  }

  const onPageChange = num => {
    setCurrentPage(num - 1)
    if (getInstituteData) {
      getInstituteData(num - 1, sortBy) // Replace with your data-fetching function
    }
  }

  const columns = [
    {
      title: 'INSTITUTE NAME',
      dataIndex: 'name',
      key: 'institute_name',
      sorter: true,
      showSorterTooltip: fixedSorterColumn(sortBy),
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.name ? toCamelCase(rowData?.name) : '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'UNIVERSITY',
      dataIndex: 'name',
      key: 'university',
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.universities?.name
            ? toCamelCase(rowData?.universities?.name)
            : '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'role_id',
      key: 'role_id',
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.instituteCampus?.[0]?.city
            ? toCamelCase(rowData?.instituteCampus?.[0]?.city)
            : '-'}
        </RowNumberData>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'email',
      key: 'email',
      render: (_, rowData) => (
        <RowNumberData>
          <InstituteStatus status={rowData?.status} />
        </RowNumberData>
      ),
    },
    {
      title: 'COMP.RATE',
      dataIndex: 'status',
      key: 'status',
      render: (_, rowData) => (
        <RowNumberData>
          <ProgressCircle
            progressRate={rowData?.profileCompletionPerc}
            width="30px"
          />
        </RowNumberData>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 150,
      render: (id, rowData) => (
        <ActionDropdown
          rowData={rowData}
          getSingleInstituteData={getSingleInstituteData}
          setViewInstituteDrawer={setViewInstituteDrawer}
          verifyInstituteDrawer={verifyInstituteDrawer}
          setVerifyInstituteDrawer={setVerifyInstituteDrawer}
        />
      ),
    },
  ]

  return (
    <>
      <Wrapper>
        <StyledTable
          columns={columns}
          dataSource={instituteList.result}
          pagination={false}
          onChange={onSorting}
          locale={{
            emptyText: (
              <EmptyWrapper center isSpin={loading}>
                {!loading ? (
                  <Empty description={'No institute found!'} />
                ) : (
                  <Spin />
                )}
              </EmptyWrapper>
            ),
          }}
        />

        <Pagination
          onChange={onPageChange}
          totalPages={instituteList?.count}
          pageSize={10}
          current={currentPage}
        />
      </Wrapper>

      <ViewInstituteDrawer
        updateLoading={updateLoading}
        viewInstituteDrawer={viewInstituteDrawer}
        setViewInstituteDrawer={setViewInstituteDrawer}
        setVerifyInstituteDrawer={setVerifyInstituteDrawer}
      />
      <VerifyInstituteDrawer
        verifyInstituteDrawer={verifyInstituteDrawer}
        setVerifyInstituteDrawer={setVerifyInstituteDrawer}
        changeInstituteDetails={changeInstituteDetails}
        setChangeInstituteDetails={setChangeInstituteDetails}
        setClearInput={setClearInput}
      />

      <ChangeInstituteDetails
        changeInstituteDetails={changeInstituteDetails}
        setChangeInstituteDetails={setChangeInstituteDetails}
        requestSentDrawer={requestSentDrawer}
        setRequestSentDrawer={setRequestSentDrawer}
      />

      <RequestSentDrawer
        requestSentDrawer={requestSentDrawer}
        setRequestSentDrawer={setRequestSentDrawer}
        getInstitutesListData={getInstitutesListData}
        currentPage={currentPage}
      />
    </>
  )
}

export default InstituteTable
