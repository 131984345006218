import { Menu, Space } from 'antd'
import styled from 'styled-components'
import { COLORS } from 'theme'

export const SpaceItem = styled.div`
  color: #999fac;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SubMenuItem = styled.div`
  width: 200px !important;
  top: 0;
  bottom: 0;
  display: Flex;
  gap: 30px;
  padding-left: 10px;

  left: 200px;
  z-index: 20;
  flex-direction: column;
  color: #999fac;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  width: max-content;
  padding-left: 30px !important;
  padding-bottom: 10px;
`
export const SubLabel = styled.div`
  color: ${props => (props?.color ? props?.color : '')} !important;
  padding-top: 35px;
  display: block;
  font-size: 12px;
  text-align: left;
`
export const MenuStyle = styled(Menu)`
  .ant-menu-item {
    border-left: 5px solid transparent;
  }
  .ant-menu-item-selected {
    background-color: ${COLORS.GREY_T_10} !important;
    border-left: 5px solid #20308d;
  }
  .ant-dropdown-menu-item ant-dropdown-menu-item-only-child {
    color: #ffffff !important;
  }
  .ant-dropdown-menu-title-content {
    color: #ffffff !important;
    font-size: 18px !important;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #888;
  }
`
export const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-item {
    color: #ffffff !important;
  }
`

export const MeneTitle = styled(Space)`
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: ${props => props.color};
  padding-left: 13px;
  line-height: 18px;
`
