import { connect } from 'react-redux'

import Nav from 'modules/Nav/index'
import authSelector from 'modules/Auth/selectors'

const mapStateToProps = state => ({
  permissions: authSelector.getUserPermissions(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
