import Corporates from '..'

import corporateSelector from '../selectors'

import { connect } from 'react-redux'
import { getUserCorporatePortalsList } from '../actions'
import { getListOfState, getListOfCity } from 'modules/Onboarding/actions'

const mapStateToProps = state => ({
  corporateList: corporateSelector.getUserCorporatesLists(state),
})
const mapDispatchToProps = {
  getUserCorporatePortalsList,
  getListOfState,
  getListOfCity,
}

export default connect(mapStateToProps, mapDispatchToProps)(Corporates)
