import Button from 'components/Button/index'
import Drawer from 'components/Drawer'
import { FlexWrap } from 'components/UIComponents/FormStyles'
import React, { useEffect, useState } from 'react'
import Content from './Content'
import { setUserCorporatesList } from '../../../../Corporates/actions'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'antd'

const ScreenDrawer = ({
  setMappingCorporate,
  mappingCorporate,
  title = 'Select Corporate',
  sendButtonText = 'Update',
  getUserCorporatePortalsList,
  getActiveCorporatePortalsList,
  corporateList,
  activeCorporateList,
  updateRankingCorporate,
}) => {
  const dispatch = new useDispatch()
  const [setLoading] = useState(false)
  const [response, setResponse] = useState([])
  const [radioValue, setRadioValue] = useState(true)
  const [activeCorporateData, setActiveCorporateData] = useState([])
  const [selectedCorporateData, setSelectedCorporateData] = useState([])
  const [viewSelectedCorporates, setViewSelectedCorporates] = useState(false)
  const [corporateListData, setCorporateListData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [clearInput, setClearInput] = useState(false)
  const [payload, setPayload] = useState({
    isAllCorporate: true,
    selectedCorporates: [],
    unSelectedCorporates: [],
  })
  const [filterOpen, setFilterOpen] = useState(false)

  const [id, setId] = useState({ state: [], city: [] })
  const [clear, setClear] = useState({ state: false, city: false })
  const [stateId, setStateId] = useState([])
  const [stateList, setStateList] = useState([])
  const [stateNames, setStateNames] = useState([])
  const [stateSearch, setStateSearch] = useState('')
  const [stateLoading, setStateLoading] = useState(false)
  const [citySearch, setCitySearch] = useState('')
  const [cityLoading, setCityLoading] = useState([])
  const [cityId, setPermCityId] = useState('')
  const [cityList, setCityList] = useState([])
  const [cityName, setCityName] = useState([])

  const content = () => {
    return (
      <div>
        <Content
          id={id}
          setId={setId}
          clear={clear}
          setClear={setClear}
          stateId={stateId}
          setStateId={setStateId}
          stateList={stateList}
          setStateList={setStateList}
          stateNames={stateNames}
          setStateNames={setStateNames}
          stateSearch={stateSearch}
          setStateSearch={setStateSearch}
          stateLoading={stateLoading}
          setStateLoading={setStateLoading}
          citySearch={citySearch}
          setCitySearch={setCitySearch}
          cityLoading={cityLoading}
          setCityLoading={setCityLoading}
          cityId={cityId}
          setPermCityId={setPermCityId}
          cityList={cityList}
          setCityList={setCityList}
          cityName={cityName}
          setCityName={setCityName}
          clearInput={clearInput}
          setClearInput={setClearInput}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          getActiveCorporatePortalsList={getActiveCorporatePortalsList}
          getUserCorporatePortalsList={getUserCorporatePortalsList}
          corporateList={corporateList}
          activeCorporateList={activeCorporateList}
          setPayload={setPayload}
          payload={payload}
          loading={response}
          setRadioValue={setRadioValue}
          radioValue={radioValue}
          activeCorporateData={activeCorporateData}
          setActiveCorporateData={setActiveCorporateData}
          selectedCorporateData={selectedCorporateData}
          setSelectedCorporateData={setSelectedCorporateData}
          viewSelectedCorporates={viewSelectedCorporates}
          setViewSelectedCorporates={setViewSelectedCorporates}
          corporateListData={corporateListData}
          setCorporateListData={setCorporateListData}
          mappingCorporate={mappingCorporate}
        />
      </div>
    )
  }
  const clearAll = () => {
    setMappingCorporate({ ...mappingCorporate, open: false })
    setRadioValue(true)
    setSelectedCorporateData([])
    setActiveCorporateData([])
    setViewSelectedCorporates(false)
    setSearchValue('')
    setClearInput(true)
    setCitySearch('')
    setClear({ state: true, city: true })
    setStateSearch('')
    setCityList([])
    setCorporateListData([])
    setViewSelectedCorporates(false)
    setPermCityId([])
    setStateId([])
    setStateList([])
    setTimeout(() => {
      setClearInput(false)
      setClear({ state: false, city: false })
    }, 500)
    dispatch(setUserCorporatesList([]))
    setPayload({
      isAllCorporate: true,
      selectedCorporates: [],
      unSelectedCorporates: [],
    })
  }
  const onClear = () => {
    clearAll()
  }

  const onSubmitFormData = value => {
    updateRankingCorporate({ setLoading, payload, setResponse })
    clearAll()
  }

  const footer = () => {
    return (
      <FlexWrap gap="20px" right>
        <Button.Secondary text="Cancel" onClick={onClear} />
        <Tooltip
          title={
            !radioValue &&
            payload?.selectedCorporates?.length <= 0 &&
            !radioValue &&
            payload?.unSelectedCorporates?.length <= 0
              ? 'Kindly select or deselect the corporate to add'
              : ''
          }
          placement="topLeft"
        >
          <div>
            <Button.Primary
              htmlType="submit"
              text={sendButtonText}
              onClick={onSubmitFormData}
              disabled={
                !radioValue &&
                payload?.selectedCorporates?.length <= 0 &&
                !radioValue &&
                payload?.unSelectedCorporates?.length <= 0
              }
            />
          </div>
        </Tooltip>
      </FlexWrap>
    )
  }
  useEffect(() => {
    dispatch(setUserCorporatesList([]))
    getUserCorporatePortalsList({
      pageLimit: '10',
      isRankingEnabled: true,
    })
    getActiveCorporatePortalsList({
      pageLimit: '1000',
    })
  }, [radioValue])
  return (
    <Drawer
      padding={'0px 30px 30px 30px'}
      title={title}
      placement="right"
      closable={true}
      onClose={onClear}
      visible={mappingCorporate?.open}
      content={content()}
      footer={footer()}
    />
  )
}

export default ScreenDrawer
