import React from 'react'

const InfoIcon = ({marginTop}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 24 24"
      // style={{marginTop:marginTop?marginTop:""}}
    >
      <circle cx="12" cy="12" r="10" fill={'#9AC5F4'} />
      <path fill="#FFFFFF" d="M11 7h2v2h-2zM11 11h2v6h-2z" />
      <circle cx="12" cy="16.5" r="1" fill="#FFFFFF" />
    </svg>
  )
}

export default InfoIcon
