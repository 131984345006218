import React, { useState } from 'react'
//antDesign
import { Empty, Spin } from 'antd'
//imported
import Pagination from 'components/Pagination'
import {
  RowNumberData,
  Wrapper,
} from 'components/TableStyles/TableContentStyles'
import { StyledTable } from 'components/TableStyles/TableStyles'
import { FlexWrap } from '../../../../components/TableStyles/TableContentStyles'
import { EmptyWrapper } from '../../Style/style'
import ViewUserRoleDrawer from '../ViewUserRoleDrawer'
import ActionDropdown from './ActionDropdown'
import { toCamelCase } from '../../../../utils/camelCase'

// Styled Components
import { CenterAlignedText } from './style'
import { commaSeparatedString } from '../../../../utils/helpers'

const RolesTable = ({
  roleList,
  getRoleList,
  roleFilter,
  setView,
  eventList,
  formattedData,
  setFormattedData,
  setSortBy,
  onPageChange,
  loading,
  setLoading,
  getSingleRoleInfo,
  singleRoleData,
  setAddUserDrawer,
  setAddRoleDrawer,
  setRoleId,
  updateUserStatus,
  setPageNum,
  getUserData,
  pageNum,
  updateLoading,
  deleteRolesAndPermission,
  getRolesData,
}) => {
  const [viewRoleDrawer, setViewRoleDrawer] = useState(false)

  const getRoleName = id => {
    let roleName = roleFilter?.filter(data => data?._id === id)[0]?.name
    return roleName || '-'
  }

  const onSorting = (pagination, row, sorter) => {
    setSortBy(sorter)
  }

  const onDelete = async rowData => {
    let response = await deleteRolesAndPermission({ roleId: rowData?.id })
    if (response?.status >= 200 && response?.status <= 300) {
      await getRolesData()
    }
  }

  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'role_name',
      key: 'name',
      sorter: true,
      render: (_, rowData) => (
        <FlexWrap>
          <RowNumberData>{toCamelCase(rowData?.name) || '-'}</RowNumberData>
        </FlexWrap>
      ),
    },
    {
      title: 'Journey ',
      dataIndex: 'journey',
      key: 'journey',
      sorter: true,
      render: (_, rowData) => (
        <RowNumberData>
          {rowData?.journeys
            ? commaSeparatedString(rowData?.journeys) ??
              toCamelCase(rowData?.journey)
            : '-'}
        </RowNumberData>
      ),
    },
    {
      title: <CenterAlignedText>Users Mapped</CenterAlignedText>,
      dataIndex: 'usersMapped',
      key: 'usersMapped',
      sorter: false,
      render: (_, rowData) => (
        <CenterAlignedText>{rowData?.usersMapped}</CenterAlignedText>
      ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      width: 150,
      render: (status, rowData) => (
        <ActionDropdown
          rowData={rowData}
          setView={setView}
          setViewRoleDrawer={setViewRoleDrawer}
          getSingleRoleInfo={getSingleRoleInfo}
          singleRoleData={singleRoleData}
          setAddRoleDrawer={setAddRoleDrawer}
          setRoleId={setRoleId}
          updateUserStatus={updateUserStatus}
          deleteRolesAndPermission={deleteRolesAndPermission}
          getUserData={getUserData}
          setFormattedData={setFormattedData}
          onDelete={onDelete}
        />
      ),
    },
  ]

  return (
    <>
      <Wrapper>
        <StyledTable
          columns={columns}
          pagination={false}
          dataSource={roleList?.data?.roles}
          onChange={onSorting}
          loading={loading}
          locale={{
            emptyText: (
              <EmptyWrapper center isSpin={loading}>
                {!loading ? <Empty description={'No role found!'} /> : <Spin />}
              </EmptyWrapper>
            ),
          }}
        />
        {!!roleList?.data?.count && (
          <Pagination
            onChange={onPageChange}
            totalPages={roleList?.data?.count}
            pageSize={roleList?.data?.limit}
            current={pageNum}
          />
        )}
      </Wrapper>

      <ViewUserRoleDrawer
        formattedData={formattedData}
        viewRoleDrawer={viewRoleDrawer}
        eventList={eventList}
        setViewRoleDrawer={setViewRoleDrawer}
        setFormattedData={setFormattedData}
        singleRoleData={singleRoleData}
        getRoleName={getRoleName}
        updateLoading={updateLoading}
      />
    </>
  )
}

export default RolesTable
