import { RowNumberData } from 'components/TableStyles/TableContentStyles/TableContentStyles'
import React from 'react'

export const BillingMenuData = [
  {
    id: 1,
    text: 'Bank',
  },
  {
    id: 2,
    text: 'Rate Card',
  },

]

export const BankMasterData = [
  {
    title: 'Bank',
    dataIndex: 'bank',
    key: 'bank',
    render: (_, rowData) => <RowNumberData>HDFC Bank</RowNumberData>,
  },

]

export const RateCardMasterData = [
  {
    title: 'Rate Card',
    dataIndex: 'rate_card',
    key: 'rate_card',
    render: (_, rowData) => <RowNumberData>Infosys Limited India</RowNumberData>,
  },

]


export const DummyData = [
  {
    degree: 'Bachelor of Technology',
    type: 'UG',
    shortForm: 'B.Tech',
    status: '3',
  },
  {
    degree: 'Master of Technology',
    type: 'PG',
    shortForm: 'M.Tech',
    status: '4',
  },
]

export const AddBankContent = [
  {
    id: '1',
    name: 'Bank',
    label: 'Bank',
    placeholder: 'Eg. HDFC Bank',
    required: true,
    type: 'text',
  }
]

export const AddRateCardContent = [
  {
    id: '1',
    name: 'RateCard',
    label: 'Rate Card',
    placeholder: 'Eg. Rate Card',
    required: true,
    type: 'text',
  }
]


