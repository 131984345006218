import ErrorMessage from 'utils/ErrorMessage'
import SuccessMessage from 'utils/SuccessMessage'
import corporateRequest from 'utils/corporateRequest'
import instituteRequest from 'utils/instituteRequest'
import { getCorporatesListData } from '../Partials/CorporateInfoTable/actions'
import elasticSearchRequest from 'utils/elasticSearchRequest'
import elasticSearchSyncRequest from 'utils/elasticSearchSyncRequest'

export const SET_SINGLE_CORPORATES_DATA = 'SET_SINGLE_CORPORATE_DATA'

export const SET_CORPORATE_INFO = 'SET_CORPORATE_INFO'

export const SET_INSTITUTE_DATA = 'SET_INSTITUTE_DATA'

export const SET_INDUSTRY_DATA = 'SET_INDUSTRY_DATA'

export const setSingleCorporateData = data => {
  return {
    type: SET_SINGLE_CORPORATES_DATA,
    data,
  }
}

export const setAddInstitute = data => {
  return {
    type: SET_INSTITUTE_DATA,
    data,
  }
}
export const setIndustryData = data => {
  return {
    type: SET_INDUSTRY_DATA,
    data,
  }
}
export const editCorporate =
  (corporateId, data, callBack, message = 'Corporate created successfully.', isCallBack = false) =>
    async (dispatch, getState) => {
      try {
        console.log(isCallBack,message,'data')
        await corporateRequest.put(`/corporates/${corporateId}`, data)

        function formatDate(date) {
          let d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
        
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
        
          return [year, month, day].join('-');
        }
  
     
          let payloadData = {
            query_date: formatDate(new Date())  // This will format today's date as "yyyy-mm-dd"
          };
  
         await elasticSearchSyncRequest.post(`/sync/corporate_list`,payloadData)
         await elasticSearchSyncRequest.post(`/sync/corporate_locations`,payloadData)

        dispatch(getCorporatesListData({}))
        SuccessMessage(message)
        if(isCallBack)
        callBack()
      } catch (error) {
        ErrorMessage(error?.message)
      }
    }

export const getSingleCorporateData =
  (corporateId, callBack) => async (dispatch, getState) => {
    try {
      const res = await corporateRequest.get(`/corporates/${corporateId}`)
      dispatch(setSingleCorporateData(res?.data?.data || {}))
      return true
    } catch (error) {
      if (callBack) {
        callBack()
      }
    }
  }

export const getaddInstituteDrawer =
  (payload, corporateId, roleId) => async (dispatch, getState) => {
    try {
      const response = await instituteRequest.post(
        `/institutes/instituteCampus/list`,
        {}
      )
      dispatch(setAddInstitute(response?.data?.data))
      return true
    } catch (error) {
      // resolve(error)
      ErrorMessage(error?.response?.data?.message)
    }
    // })
  }

export const setCorporateFormInfo = data => {
  return {
    type: SET_CORPORATE_INFO,
    data,
  }
}

export const getSectorBasedIndustry =
  ({ sectorId, pageLimit = '10', pageNo = '0', search = '', callBack }) =>
    async (dispatch, getState) => {
      try {
        const res = await corporateRequest.get(
          `corporate/sectorindustrymaster/${sectorId}?pageLimit=${pageLimit}&pageNo=${pageNo}&isActive=true&search=${search}`
        )
        dispatch(setIndustryData(res?.data?.data || {}))
        return true
      } catch (error) {
        if (callBack) {
          callBack()
        }
      }
    }
